import React from "react";
import { MenuItem, TextField } from "@mui/material";
import { unitTypes } from "reducers/products";
import { LocalizeText } from "components/localizer";

interface UnitSelectionProps {
    value: string;
    onChange: (newValue: string) => void;
}
const UnitSelection: React.FC<UnitSelectionProps> = ({ value, onChange }) => {
    return (
        <TextField onChange={(e) => onChange(e.target.value)} fullWidth value={value} select>
            {unitTypes.map((unit) => {
                return (
                    <MenuItem key={unit} value={unit}>
                        <LocalizeText tag={unit} />
                    </MenuItem>
                );
            })}
        </TextField>
    );
};
export default UnitSelection;
