import React, { FC, useState } from "react";
import { LocalizeText } from "components/localizer";
import ConfirmDialog from "components/confirmDialog";
import { ISubscription } from "reducers/subscriptions";
import { EditSubscriptionStartDatePicker } from "./editSubscriptionStartDatePicker";

interface IProps {
    onSubmitUpdate: (params: Partial<ISubscription>) => void;
    dateStart: string;
    dateEnd: string;
}

export const EditSubscriptionStartDate: FC<IProps> = ({ onSubmitUpdate, dateStart, dateEnd }) => {
    const [startDate, setStartDate] = useState(dateStart);
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

    const onSubmitStartDate = async (newDate: string): Promise<void> => {
        setStartDate(newDate);
        setOpenConfirmDialog(true);
    };

    return (
        <>
            <EditSubscriptionStartDatePicker
                onSubmit={(date: string) => onSubmitStartDate(date)}
                dateStart={dateStart}
                dateEnd={dateEnd}
            />

            <ConfirmDialog
                open={openConfirmDialog}
                title={<LocalizeText tag="editActivationStartDate" />}
                description={<LocalizeText tag="confirmEditActivationDate" />}
                onReject={() => setOpenConfirmDialog(false)}
                onAccept={() => onSubmitUpdate({ start_date: startDate })}
            />
        </>
    );
};
