import React, { FC, useContext, useState } from "react";
import { format, isFuture, isToday, isValid, min, parseISO } from "date-fns";
import VKButton from "components/vkButton";
import { dateFormats } from "utils/formats";
import { LocalizeText } from "components/localizer";
import { TFormEvent } from "utils/types";
import { ListItemText, List, ListItem, Box } from "@mui/material";
import { ISubscription } from "reducers/subscriptions";
import SubscriptionsContext from "contexts/subscriptions";
import { DatePicker } from "components/datePicker";

interface IProps {
    onSubmitUpdate: (params: Partial<ISubscription>) => void;
    subscription: ISubscription;
}

export const EditSubscriptionTrialPeriod: FC<IProps> = ({ onSubmitUpdate, subscription }) => {
    const { isUpdatingSubscription } = useContext(SubscriptionsContext);
    const currentTrialPeriod = parseISO(subscription.trial_period_until ?? "");
    const [date, setDate] = useState(min([new Date(), currentTrialPeriod]));

    const handleSubmit = (event: TFormEvent): void => {
        event.preventDefault();
        onSubmitUpdate({ trial_period_until: format(date, dateFormats.WEBDATE) });
    };

    const dateIsValid = !isValid(date) || isToday(date) || isFuture(date);

    return (
        <Box>
            <form onSubmit={handleSubmit}>
                <List>
                    <ListItem>
                        <ListItemText
                            primary={
                                <>
                                    <LocalizeText tag="currentTrialPeriod" />:{" "}
                                    {format(currentTrialPeriod, dateFormats.WEBDATE)}
                                </>
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <DatePicker
                            minDate={new Date()}
                            format={dateFormats.WEBDATE}
                            label={<LocalizeText tag="newTrialPeriod" />}
                            value={date}
                            slotProps={{ textField: { fullWidth: true, error: !dateIsValid } }}
                            onChange={(newDate: Date) => setDate(newDate as Date)}
                        />
                    </ListItem>
                    <ListItem>
                        <VKButton
                            type="submit"
                            tag="confirm"
                            template="primary"
                            isLoading={isUpdatingSubscription}
                            disabled={!dateIsValid}
                        />
                    </ListItem>
                </List>
            </form>
        </Box>
    );
};
