import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { LocalizeText } from "components/localizer";
import { THeadCell } from "utils/types";
import TableComponent, { IRow } from "components/tableComponent";
import { ModalContext } from "contexts/modals";
import { IMemberSubscriptionAssociation } from "reducers/members";
import { MemberInfo } from "components/drawers/members/memberInfo";
import { formatPossibleNullDate, getMemberStatusText } from "utils/helpers";
import { dateFormats } from "utils/formats";
import { usePermission } from "utils/hooks/usePermission";
import { isAfter, isPast, parseISO } from "date-fns";
import { IUsePaginationStore } from "utils/usePaginationStore";
import VKButton from "components/vkButton";
import { ISubscription } from "reducers/subscriptions";
import { EditMember } from "components/drawers/members/editMember";
import { MembersContext } from "contexts/members";
import { DeleteOutline } from "@mui/icons-material";
import colors from "styles/colors";
import { IconButton } from "@mui/material";

const headCells = (): THeadCell[] => {
    return [
        { id: "email", label: <LocalizeText tag="email" /> },
        { id: "name", label: <LocalizeText tag="name" /> },
        { id: "added", label: <LocalizeText tag="invitedToSpaceflow" /> },
        { id: "activation", label: <LocalizeText tag="statusInSpaceflow" /> },
        { id: "deactivation", label: <LocalizeText tag="terminatedInSpaceflow" /> },
        { id: "", label: "" },
    ];
};

interface IProps {
    pagination: IUsePaginationStore<"member-subscription-associations">;
    subscription: ISubscription;
    updateSubscription: () => Promise<void>;
}

export const SubscriptionMembersTable: React.FC<IProps> = ({ pagination, subscription, updateSubscription }) => {
    const { openDrawer } = React.useContext(ModalContext);
    const { hasPermissions } = usePermission();
    const { removeAssociation } = useContext(MembersContext);

    useEffect(() => {
        (async (): Promise<void> => {
            await pagination.query.refetch();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showEditButton = useCallback(
        (association: IMemberSubscriptionAssociation) => {
            return (
                hasPermissions(["change_membersubscriptionassociation"]) &&
                !isPast(parseISO(association.deactivates ?? ""))
            );
        },
        [hasPermissions]
    );

    const showClearButton = useCallback((association: IMemberSubscriptionAssociation) => {
        return isAfter(parseISO(association.activates ?? ""), parseISO(association.deactivates ?? ""));
    }, []);

    const onClear = useCallback(
        async (association: IMemberSubscriptionAssociation): Promise<void> => {
            await removeAssociation(association.uuid);
            pagination.query.refetch();
        },
        [pagination.query, removeAssociation]
    );

    const getButton = useCallback(
        (association: IMemberSubscriptionAssociation) => {
            if (showClearButton(association)) {
                return (
                    <IconButton
                        key={`association_row_${association.uuid}`}
                        onClick={() => onClear(association)}
                        sx={{
                            marginLeft: "17px",
                            color: "black",
                            ":hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                        }}
                    >
                        <DeleteOutline />
                    </IconButton>
                );
            }
            if (showEditButton(association)) {
                return (
                    <VKButton
                        key={`association_row_${association.uuid}`}
                        template="primary"
                        tag="edit"
                        size="small"
                        onClick={() =>
                            openDrawer(
                                EditMember,
                                {
                                    association,
                                    subscription,
                                    pagination: pagination,
                                    updateSubscription,
                                    onConfirmRemove: () => {
                                        pagination.query.refetch();
                                    },
                                },
                                "editMember"
                            )
                        }
                    />
                );
            }
            return null;
        },
        [onClear, openDrawer, pagination, showClearButton, showEditButton, subscription, updateSubscription]
    );

    const rows = useMemo((): IRow[] => {
        return pagination.results.map((association) => ({
            onClick: () =>
                openDrawer(
                    MemberInfo,
                    { member: association.member, subscriptionUuid: subscription.uuid },
                    "memberInformation"
                ),
            disabled:
                isPast(parseISO(association.deactivates ?? "")) ||
                isAfter(parseISO(association.activates ?? ""), parseISO(association.deactivates ?? "")),
            data: [
                association.member.email,
                association.member.first_name
                    ? `${association.member.first_name} ${association.member.last_name}`
                    : "-",
                formatPossibleNullDate(association.activates, dateFormats.WEBDATE),
                isAfter(parseISO(association.activates ?? ""), new Date())
                    ? ""
                    : getMemberStatusText(association.member, true),

                formatPossibleNullDate(association.deactivates, dateFormats.WEBDATE) || "-",
                getButton(association),
            ],

            tooltipText: isAfter(parseISO(association.activates ?? ""), parseISO(association.deactivates ?? "")) ? (
                <LocalizeText tag="invalidDatesInfo" />
            ) : (
                ""
            ),
        }));
    }, [pagination.results, getButton, openDrawer, subscription.uuid]);

    return <TableComponent headCells={headCells()} skeletonVariant="options" pagination={pagination} rows={rows} />;
};
