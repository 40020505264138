import React, { FC, useContext, useEffect, useMemo } from "react";
import CommunityContext from "../../../contexts/community";
import { usePermission } from "utils/hooks/usePermission";
import { ListItem, ListItemText } from "@mui/material";
import { LocalizeText } from "../../localizer";
import ListInlineEditWrapper from "../listComponents/listInlineEditWrapper";
import { IProduct } from "reducers/products";
import { MultiSelectDropdown } from "components/selectDropdown";

interface IProps {
    product: IProduct;
    onCommunitiesChange: (ids: string[]) => void;
    selectedCommunities: string[];
    onSubmitUpdate: (props: Record<string, unknown>) => void;
}

const NationalCommunitiesField: FC<IProps> = ({
    product,
    onCommunitiesChange,
    selectedCommunities,
    onSubmitUpdate,
}) => {
    const { productCommunitiesPagination } = useContext(CommunityContext);
    const { hasPermission } = usePermission();

    const items = useMemo(() => {
        const temp: { id: string; primary: string }[] = [];

        productCommunitiesPagination.results.map((community) => {
            if (community.uuid !== product.community) {
                temp.push({ id: community.uuid, primary: community.title });
            }
        });

        return temp;
    }, [productCommunitiesPagination.results, product]);

    const text = useMemo(() => {
        return selectedCommunities.length > 0 && items.length > 0
            ? selectedCommunities.map((id) => items.find((item) => item.id === id)?.primary).join(", ")
            : "-";
    }, [items, selectedCommunities]);

    useEffect(() => {
        productCommunitiesPagination.getInitial();
    }, [productCommunitiesPagination]);

    return (
        <ListInlineEditWrapper
            onSubmit={() => onSubmitUpdate({ communities: selectedCommunities })}
            onChange={(e) => {}}
            label={<LocalizeText tag="nationalCommunities" />}
            renderInput={(props) => (
                <>
                    {productCommunitiesPagination.results.length > 0 && (
                        <ListItem>
                            <MultiSelectDropdown
                                size="medium"
                                fullWidth
                                width="100%"
                                noSelectionTag="nationalCommunities"
                                items={items}
                                selectedIds={selectedCommunities}
                                onToggle={onCommunitiesChange}
                            />
                        </ListItem>
                    )}
                </>
            )}
            inputIsValid={true}
            renderView={(props) => (
                <ListItem>
                    <ListItemText primary={<LocalizeText tag="nationalCommunities" />} secondary={text} />
                </ListItem>
            )}
            editDisabled={!product.national_access || !hasPermission("change_product")}
        />
    );
};

export default NationalCommunitiesField;
