import { createTheme } from "@mui/material/styles";
import { svSE } from "@mui/material/locale";
import colors from "styles/colors";

const defaultTheme = createTheme(
    {
        palette: {
            background: {
                default: colors.background,
            },
            primary: {
                main: colors.vkBlue,
            },
            secondary: {
                main: colors.vkOrange,
            },
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: 0,
                        textTransform: "none",
                        padding: "6px 26px",
                        fontSize: "1.25rem",
                    },
                    outlinedSizeSmall: {
                        padding: "0px 10px",
                        fontSize: "1rem",
                    },
                    outlined: {
                        borderWidth: "2px",
                        borderColor: colors.vkBlue,
                        color: colors.vkBlue,
                        "&:hover": {
                            borderWidth: "2px",
                            color: colors.white,
                            textDecoration: "underline",
                            backgroundColor: colors.buttonHover,
                        },
                    },
                    containedSizeSmall: {
                        padding: "0px 10px",
                        fontSize: "1rem",
                    },
                    contained: {
                        boxShadow: "none",
                        border: "none",
                    },
                    textSizeSmall: {
                        padding: "0px 10px",
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        textTransform: "none",
                        color: colors.black,
                        "&.Mui-selected": {
                            color: colors.black,
                            opacity: 1,
                            backgroundColor: colors.white,
                        },
                        "&.MuiTab-textColorInherit": {
                            opacity: 1,
                        },
                    },
                },
            },
            MuiTabs: {
                styleOverrides: {
                    indicator: {
                        backgroundColor: colors.vkOrange,
                        height: 4,
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        borderRadius: "0 !important",
                        borderColor: `${colors.vkBlue}`,

                        "& fieldset": {
                            borderColor: `${colors.vkBlue}`,
                            borderWidth: "2px",
                        },
                        "& svg": {
                            color: colors.vkBlue,
                        },
                        "&.Mui-focused fieldset": {
                            borderColor: `${colors.vkBlue} !important`,
                        },
                        "&.Mui-error fieldset": {
                            borderColor: `${colors.errorRed} !important`,
                        },
                    },
                },
            },
            MuiCircularProgress: {
                styleOverrides: {
                    colorPrimary: {
                        color: colors.black,
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        borderRadius: 0,
                        border: "none",
                    },
                    rounded: {
                        borderRadius: 0,
                        border: "none",
                    },
                },
            },
            MuiCssBaseline: {
                // See https://github.com/mui/material-ui/issues/24966
                styleOverrides: {
                    "@font-face": {
                        fontFamily: "Vasakronan",
                        fontStyle: "normal",
                        fontDisplay: "swap",
                        fontWeight: "400",
                        src: `
                    local('Vasakronan'),
                    local('Vasakronan-Serif'),
                    url(${`${process.env.PUBLIC_URL}/assets/fonts/vasakronan_serif_rubrik.woff`}) format('woff')
                    `,
                        unicodeRange:
                            "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
                    },
                    fallbacks: [
                        {
                            "@font-face": {
                                fontFamily: "Apercu",
                                fontStyle: "normal",
                                fontDisplay: "swap",
                                fontWeight: "400",
                                src: `
                            local('Apercu'),
                            local('Apercu-Regular'),
                            url(${`${process.env.PUBLIC_URL}/assets/fonts/apercu_regular.woff`}) format('woff')
                            `,
                                unicodeRange:
                                    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
                            },
                        },
                    ],
                },
            },
        },
        typography: {
            fontFamily: ["Apercu", "Calibri"].join(","),
            fontSize: 16,
        },
    },
    svSE
);

export default defaultTheme;
