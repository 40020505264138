import React from "react";
import { LocalizeText } from "components/localizer";
import { InputAdornment, ListItem, ListItemText, TextField } from "@mui/material";
import ListInlineEditWrapper from "../listComponents/listInlineEditWrapper";
import { IProduct, IProductPrice } from "reducers/products";
import PrettifyPrice from "components/prettifyPrice";
import { TLanguageTag } from "contexts/language";

interface IProps {
    tag: TLanguageTag;
    product: IProduct;
    prop: keyof IProductPrice;
    onSubmitUpdate: (props: Record<string, unknown>) => void;
    canEdit?: boolean;
}

const PriceField: React.FC<IProps> = ({ tag, product, prop, onSubmitUpdate, canEdit = true }) => {
    const [text, setText] = React.useState<string>((product.price?.[prop] as string) || "");

    const isMoney = prop === "amount_excl_vat";

    const amount = parseFloat(product.price?.[prop] || "0");

    const secondary = isMoney ? (
        amount === 0 ? (
            <LocalizeText tag="free2" />
        ) : (
            <PrettifyPrice amount={product.price?.[prop] as unknown as number} currency="SEK" />
        )
    ) : (
        `${amount} %`
    );

    if (!canEdit) {
        return (
            <ListItem divider>
                <ListItemText primary={<LocalizeText tag={tag} />} secondary={secondary} />
            </ListItem>
        );
    }

    return (
        <ListInlineEditWrapper
            onChange={(e) => setText(e.target.value)}
            onSubmit={() => onSubmitUpdate({ price: { [prop]: text } })}
            onCancel={() => setText((product.price?.[prop] as string) || "")}
            label={<LocalizeText tag={tag} />}
            renderInput={(props) => (
                <TextField
                    {...props}
                    value={parseFloat(text)}
                    type="number"
                    inputProps={{ min: 0 }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{isMoney ? "kr" : "%"}</InputAdornment>,
                    }}
                />
            )}
            renderView={(props) => <ListItemText {...props} secondary={secondary} />}
            inputIsValid={text.length > 0}
        />
    );
};

export default PriceField;
