import React from "react";
import { MenuItem, OutlinedTextFieldProps, TextField } from "@mui/material";
import { LocalizeText } from "components/localizer";

export const SelectSubQuestionType: React.FC<OutlinedTextFieldProps> = (props) => {
    return (
        <TextField
            {...props}
            select
            fullWidth
            label={<LocalizeText tag="questionSubTypeSelect" />}>
            <MenuItem key="generalInvoiceQuestion" value={"GENERAL_INVOICE_QUESTION"}>
                <LocalizeText tag="generalInvoiceQuestion" />
            </MenuItem>
            <MenuItem key="indexInvoiceQuestion" value={"INDEX_INVOICE_QUESTION"}>
                <LocalizeText tag="indexInvoiceQuestion" />
            </MenuItem>
        </TextField>
    );
};


const SelectQuestionType: React.FC<OutlinedTextFieldProps> = (props) => {
    return (
        <TextField
            {...props}
            select
            fullWidth
            label={<LocalizeText tag="questionTypeSelect" />}>
            <MenuItem key="leaseInvoiceQuestion" value={"LEASE_INVOICE_QUESTION"}>
                <LocalizeText tag="leaseInvoiceQuestion" />
            </MenuItem>
            <MenuItem key="changeCustomerLedger" value={"CHANGE_CUSTOMER_LEDGER"}>
                <LocalizeText tag="changeCustomerLedger" />
            </MenuItem>
            <MenuItem key="leaseContractQuestion" value={"LEASE_CONTRACT_QUESTION"}>
                <LocalizeText tag="leaseContractQuestion" />
            </MenuItem>
            <MenuItem key="generalQuestionContract" value={"GENERAL_QUESTION_CONTRACT"}>
                <LocalizeText tag="generalQuestionContract" />
            </MenuItem>
            <MenuItem key="errorReport" value={"ERROR_REPORT"}>
                <LocalizeText tag="errorReport" />
            </MenuItem>
        </TextField>
    );
};

export default SelectQuestionType;
