import React, { ReactNode } from "react";
import { LocalizeText } from "components/localizer";
import { format, parseISO, isFuture, isToday } from "date-fns";
import { dateFormats } from "utils/formats";
import PrettifyPrice from "components/prettifyPrice";
import { ISubscription } from "reducers/subscriptions";
import { TLanguageTag } from "contexts/language";

export type TCommunity = {
    uuid: string;
    title: string;
    address: string;
    city: string;
    community_type: TCommunityType;
};

export type TProductCommunity = {
    uuid: string;
    title: string;
    address: string;
    city: string;
    community_type: TCommunityType;
}

export type TCommunityType = "arena" | "conference";

export const renderPriceElement = (
    amount: number,
    currency: TLanguageTag,
    includeTaxText: boolean,
    decimalCount: number,
    period?: TLanguageTag
): JSX.Element | string => {
    return amount > 0 ? (
        <>
            <PrettifyPrice amount={amount} currency={currency} period={period} decimals={decimalCount} />
            {includeTaxText && <LocalizeText tag="excludingVat" spaceBefore />}
        </>
    ) : (
        "0 kr"
    );
};

export const getExpireDate = (endDate?: string | null): string | React.ReactNode => {
    return endDate ? format(parseISO(endDate ?? ""), dateFormats.WEBDATE) : <LocalizeText tag="untilFurtherNotice" />;
};

export const getLeaseValidToDate = (date: string | null): string | React.ReactNode => {
    if (date) {
        if (date === "2100-12-31") {
            return <LocalizeText tag="untilFurtherNotice" />;
        }
        const dateParsed = parseISO(date ?? "");
        return format(dateParsed, dateFormats.WEBDATE);
    }
    return <LocalizeText tag="untilFurtherNotice" />;
};

export const formatTrialPeriod = (trailPeriodEnd: string, isLargeScreen: boolean): ReactNode => {
    const trailPeriodEndDate = parseISO(trailPeriodEnd ?? "");
    if (!isFuture(trailPeriodEndDate) && !isToday(trailPeriodEndDate)) {
        return "-";
    }
    return (
        <span>
            {<LocalizeText tag={isLargeScreen ? "validUntilCapital" : "validUntilCapitalShort"} />}
            {"."} {format(trailPeriodEndDate, dateFormats.WEBDATE)} {isLargeScreen ? "(" : ""}
            {isLargeScreen ? <LocalizeText tag="free" /> : ""}
            {isLargeScreen ? ")" : ""}
        </span>
    );
};

export const formatBindingPeriod = (bindingPeriodEnd: string): ReactNode => {
    const bindingPeriodEndDate = parseISO(bindingPeriodEnd ?? "");

    if (isFuture(bindingPeriodEndDate)) {
        return (
            <span>
                <LocalizeText tag="validUntilCapital" />
                {"."} {format(bindingPeriodEndDate, dateFormats.WEBDATE)}
            </span>
        );
    }
    return "-";
};

export const getSubscriptionPeriod = (subscription: ISubscription): ReactNode => {
    if (!subscription.end_date) {
        return <LocalizeText tag="untilFurtherNotice" />;
    }
    return (
        <span>
            <LocalizeText tag="until" />
            {". "} {getExpireDate(subscription.end_date)}
        </span>
    );
};
