import PaginationStore, { TPaginationActionData } from "utils/paginationStore";
import { IUser } from "./profile";

export interface IPermission {
    id: number;
    name: string;
    codename: string;
}

export interface IRole {
    id: number;
    name: string;
    display_name: string;
}

export interface IGroup {
    id: number;
    name: string;
    users: IUser[];
}

export interface IDomainUser {
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
    roles: string[];
}

export enum DomainContentType {
    Community = "community.community",
    Lease = "vk_data.lease",
    Membership = "membership.membership",
    OrganizationCollection = "organization.organizationcollection",
}

export interface IRoleManagement {
    rolesPagination: PaginationStore<IRole>;
    groupsPagination: PaginationStore<IGroup>;
    usersPagination: PaginationStore<IDomainUser>;
    isFetchingRoles: boolean;
    isFetchingGroups: boolean;
    isFetchingDomainUsers: boolean;
    fetchRolesOfDomain: (
        contentType: DomainContentType,
        params: Record<string, unknown>
    ) => Promise<TPaginationActionData<IRole>>;
    fetchGroupsOfDomain: (
        contentType: DomainContentType,
        params: Record<string, unknown>
    ) => Promise<TPaginationActionData<IGroup>>;
    fetchUsersOfDomain: (
        contentType: DomainContentType,
        params: Record<string, unknown>
    ) => Promise<TPaginationActionData<IDomainUser>>;
}

export const initialState: IRoleManagement = {
    rolesPagination: new PaginationStore<IRole>(),
    groupsPagination: new PaginationStore<IGroup>(),
    usersPagination: new PaginationStore<IDomainUser>(),
    isFetchingRoles: false,
    isFetchingGroups: false,
    isFetchingDomainUsers: false,
    fetchRolesOfDomain: async (): Promise<TPaginationActionData<IRole>> => ({} as TPaginationActionData<IRole>),
    fetchGroupsOfDomain: async (): Promise<TPaginationActionData<IGroup>> => ({} as TPaginationActionData<IGroup>),
    fetchUsersOfDomain: async (): Promise<TPaginationActionData<IDomainUser>> =>
        ({} as TPaginationActionData<IDomainUser>),
};

export type IAction =
    | { type: "FETCH_ROLES" }
    | { type: "FETCH_ROLES_SUCCESS"; data: TPaginationActionData<IRole> }
    | { type: "FETCH_ROLES_FAILURE" }
    | { type: "FETCH_GROUPS" }
    | { type: "FETCH_GROUPS_SUCCESS"; data: TPaginationActionData<IGroup> }
    | { type: "FETCH_GROUPS_FAILURE" }
    | { type: "FETCH_USERS" }
    | { type: "FETCH_USERS_SUCCESS"; data: TPaginationActionData<IDomainUser> }
    | { type: "FETCH_USERS_FAILURE" };

function reducer(state: IRoleManagement, action: IAction): IRoleManagement {
    switch (action.type) {
        case "FETCH_ROLES":
            return { ...state, isFetchingRoles: true };
        case "FETCH_ROLES_SUCCESS":
        case "FETCH_ROLES_FAILURE":
            return { ...state, isFetchingRoles: false };

        case "FETCH_GROUPS":
            return { ...state, isFetchingGroups: true };
        case "FETCH_GROUPS_SUCCESS":
        case "FETCH_GROUPS_FAILURE":
            return { ...state, isFetchingGroups: false };

        case "FETCH_USERS":
            return { ...state, isFetchingDomainUsers: true };
        case "FETCH_USERS_SUCCESS":
        case "FETCH_USERS_FAILURE":
            return { ...state, isFetchingDomainUsers: false };

        default:
            return { ...state };
    }
}

export default reducer;
