import { FeatureFlagContext } from "contexts/featureFlag";
import React from "react";

export const useActiveFeatureMessage = (): (id: string) => string => {
    const { features } = React.useContext(FeatureFlagContext);

    const featureMessage = (id: string): string => {
        const feature = features.find(fe => fe.feature_id === id);
        return feature && feature.active ? feature.message : "";
    };

    return featureMessage;
};
