import webAPIRequest from "api";
import { TCommunity } from "utils/ecommerseTypes";
import { TPaginationActionData } from "utils/paginationStore";

export interface IPublicEventSubscription {
    id: number;
    created: string;
    public_event_type: TPublicEventType;
}

export interface IPublicEvent {
    id: number;
    created: string;
    context_content_type: {
        id: number;
        name: string;
        app_label: string;
    };
    context_object_id: number;
    community: TCommunity;
    public_event_type: TPublicEventType;
    description: string;
}

export interface IPublicEventsQueryParams {
    public_event_type?: string;
    period_before?: string;
    period_after?: string;
    domain_object_id?: string;
    ordering?: string;
}

export const TPublicEventTypes = [
    "subscription_terminated",
    "office_terminated",
    "subscription_updated",
    "office_updated",
    "membership_changes",
    "order_error",
    "unit4_error",
    "unit4_uploaded",
] as const;
export type TPublicEventType = typeof TPublicEventTypes[number];

export const fetchPublicEventSubscriptions = async (): Promise<IPublicEventSubscription[]> => {
    try {
        const returnData = await webAPIRequest("get", "/events/public-event-subscriptions/", {
            params: { page_size: 100, default_page_size: 100 },
        });
        return (returnData.data as TPaginationActionData<IPublicEventSubscription>).results;
    } catch (error) {
        return [];
    }
};

export const createPublicEventSubscription = async (type: TPublicEventType): Promise<IPublicEventSubscription> => {
    try {
        const returnData = await webAPIRequest("post", "/events/public-event-subscriptions/", {
            data: { public_event_type: type },
        });
        return returnData.data;
    } catch (error) {
        return {} as IPublicEventSubscription;
    }
};

export const deletePublicEventSubscription = async (id: number): Promise<void> => {
    try {
        const returnData = await webAPIRequest("delete", `/events/public-event-subscriptions/${id}/`);
        return returnData.data;
    } catch (error) {
        return;
    }
};

export const fetchPublicEventTypes = async (): Promise<TPublicEventType[]> => {
    try {
        const returnData = await webAPIRequest("get", "/events/public-events/public-event-types/");
        return returnData.data["public_event_types"];
    } catch (error) {
        return [];
    }
};
