import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { LocalizeText } from "components/localizer";
import Banner from "components/banner";
import { usePaginationStore } from "utils/usePaginationStore";
import { StaffSubscriptionsTable } from "components/tableComponents/staffSubscriptionsTable";
import TopBar from "components/tableComponents/topBar";
import SubscriptionFilterInput from "components/searchFilterComponents/subscriptionFilter";
import SearchFilterField from "components/searchFilterField";

const StaffSubscriptionsView: FC = () => {
    const { communityId } = useParams<{ communityId: string }>();

    const pagination = usePaginationStore("subscriptions", undefined, {
        active: true,
        community_id: communityId,
        page_size: 10,
        default_page_size: 10,
        search_by: "staff",
    });

    return (
        <div className="contentWrapper">
            <Banner
                title="subscriptions"
                subTitle="bannerStaffSubscriptions"
                image={`${process.env.PUBLIC_URL}/assets/images/1024x683/subscriptions-1024x683.jpg`}
            />
            <TopBar
                searchField={
                    <SearchFilterField
                        pagination={pagination}
                        label={<LocalizeText tag="searchSubscriptions" />}
                        filterFields={<SubscriptionFilterInput subscriptionPagination={pagination} />}
                    />
                }
            />

            <StaffSubscriptionsTable pagination={pagination} loading={pagination.query.isFetching} />
            {pagination.results.length === 0 && !pagination.query.isFetching && (
                <LocalizeText tag="noSubscriptionLeftInfo" styling={{ padding: "10px" }} />
            )}
        </div>
    );
};
export default StaffSubscriptionsView;
