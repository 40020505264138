import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Grid, { GridSize } from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import VKButton from "components/vkButton";
import { LocalizeText } from "components/localizer";
import { LanguageContext, TLanguageTag } from "contexts/language";
import colors from "styles/colors";

interface IProps {
    children?: React.ReactNode;
    testid?: string;
    inputXs?: GridSize;
    inputMd?: GridSize;
    inputLg?: GridSize;
    title?: TLanguageTag;
}
const LoginWrapper: React.FC<IProps> = ({ children, testid, inputXs = 12, inputMd = 5, inputLg = 3, title }) => {
    const { currentLanguage, changeLanguage } = React.useContext(LanguageContext);

    const switchLanguage = (): void => {
        if (currentLanguage === "sv") {
            changeLanguage("en");
        } else {
            changeLanguage("sv");
        }
    };

    const fillGrid = (inputSize: GridSize): GridSize => {
        return (12 - Number(inputSize)) as GridSize;
    };

    return (
        <Grid data-testid={testid} container sx={{ minHeight: "100vh", backgroundColor: colors.white }}>
            <Grid
                item
                xs={inputXs}
                md={inputMd}
                lg={inputLg}
                sx={{
                    padding: "2rem",
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "auto",
                    maxHeight: "100vh",
                }}
            >
                <CssBaseline />
                <Grid container>
                    <Grid item>
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/images/vasakronan_logo_black.png`}
                            style={{ width: "100%", maxWidth: "14rem" }}
                            alt="Vasakronan logo"
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" mt={"3rem"}>
                            <LocalizeText tag={title ?? "vkkMangerTitle"} />
                        </Typography>
                    </Grid>
                </Grid>
                {children}
                <Grid container direction="column" height={"100%"} justifyContent="flex-end">
                    <VKButton
                        tag="langSelector"
                        variant="outlined"
                        onClick={() => switchLanguage()}
                        size="small"
                        sx={{
                            alignSelf: "flex-start",
                            marginBottom: "1rem",
                        }}
                    />
                    <Grid>
                        <Typography variant="body2">
                            <LocalizeText tag="needHelp" />
                        </Typography>
                        <a href="mailto:kundservice@vasakronan.se">kundservice@vasakronan.se</a>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                xs={true}
                md={fillGrid(inputMd)}
                lg={fillGrid(inputLg)}
                sx={{
                    overflow: "hidden",
                    height: "100vh",
                }}
            >
                <img
                    src={`${process.env.PUBLIC_URL}/assets/images/1024x683/login-1024x683.jpg`}
                    style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                    }}
                    alt="Background"
                />
            </Grid>
        </Grid>
    );
};

export default LoginWrapper;
