import React from "react";
import { IContactPerson } from "reducers/memberships";
import RequestAccessBase from "./requestAccessBase";
import PaginationStore, { TPaginationActionData, emptyPaginationActionData } from "utils/paginationStore";
import { IRole } from "reducers/roleManagement";
import { ILease } from "reducers/leases";
import { fetchLeasesContactsAdapter } from "adapters/leasesAdapter";
import { useIsLoading } from "utils/hooks/useLoading";
import { NotificationContext } from "contexts/notification";
import { ModalContext } from "contexts/modals";

interface IProps {
    onSubmit: (lease_uuid: string, email: string, role_id?: number) => void;
    lease: ILease;
}
const RequestAccessToLease: React.FC<IProps> = ({ onSubmit, lease }) => {
    const { enqueNotification } = React.useContext(NotificationContext);
    const [existing, setExisting] = React.useState<IContactPerson[]>([]);
    const [isLoading, setIsLoading] = useIsLoading(true);
    const { closeDrawers } = React.useContext(ModalContext);

    const [contactPersonPagination] = React.useState<PaginationStore<IContactPerson>>(
        new PaginationStore<IContactPerson>()
    );
    React.useEffect(() => {
        contactPersonPagination.initialize(paginationCallback);
        contactPersonPagination.setParam("pass_manager_if_empty", true);
        contactPersonPagination.setParam("page_size", 20);
        contactPersonPagination.appendResults = true;
        contactPersonPagination.getInitial();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lease]);

    const paginationCallback = async (
        params: Record<string, unknown>
    ): Promise<TPaginationActionData<IContactPerson>> => {
        setIsLoading(true);
        try {
            const entries = await fetchLeasesContactsAdapter(lease.lease_uuid, params);
            setExisting(entries.data.results);
            setIsLoading(false);
            return entries.data;
        } catch (error) {
            enqueNotification("error_fetchContactPersons", error);
            setIsLoading(false);
            return emptyPaginationActionData;
        }
    };

    const onSubmitNewMembershipContactPersons = (requestedContactPersonEmail: string, role?: IRole): void => {
        setIsLoading(true);
        onSubmit(lease.lease_uuid, requestedContactPersonEmail, role?.id);
        setIsLoading(false);
        closeDrawers();
    };

    const onLoadMore = async (): Promise<void> => {
        if (contactPersonPagination.next) {
            await contactPersonPagination.nextPage();
        }
    };

    return (
        <RequestAccessBase
            existing={existing}
            loadMoreContacts={onLoadMore}
            isLoading={isLoading}
            onSubmit={onSubmitNewMembershipContactPersons}
            titleTag="lease"
            contractData={`${lease.agreement_number} - ${lease.popular_name_leasee}`}
            helperTextTag="requestAccessToLeaseHelpText"
        />
    );
};

export default RequestAccessToLease;
