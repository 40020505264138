import webAPIRequest from "api";
import { AxiosResponse } from "axios";

interface IUnsubscribe {
    encoded_email: string;
    token: string;
}

export const unsubscribeFromNewsMailList = async (data: IUnsubscribe): Promise<AxiosResponse> => {
    return webAPIRequest("POST", "/news/unsubscribe/", { data });
};