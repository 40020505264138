import { FeatureFlagContext } from "contexts/featureFlag";
import React from "react";

export const useFeatureToggle = (): (id: string) => boolean => {
    const { features } = React.useContext(FeatureFlagContext);

    const isActive = (id: string): boolean => {
        const feature = features.find(fe => fe.feature_id === id);
        return feature ? feature.active : false;
    };

    return isActive;
};
