import { Grid, CircularProgress } from "@mui/material";
import React from "react";
import colors from "styles/colors";

type ICenteredLoaderProps = {
    size: number | string;
    color?: string;
}
export const CenteredLoader: React.FC<ICenteredLoaderProps> = ({ size, color }) => {
    return (
        <Grid container justifyContent="center" alignContent="center" style={{ height: "100%" }}>
            <CircularProgress data-testid="centered-loader" size={size} style={{ color: color || colors.vkBlue }} />
        </Grid>
    );
};