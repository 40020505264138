import React, { FC, useContext } from "react";
import TableComponent, { IRow } from "components/tableComponent";
import { prettifyOrgNumber } from "utils/helpers";
import PaginationStore from "utils/paginationStore";
import { IMembership } from "reducers/memberships";
import { format, parseISO } from "date-fns";
import { getExpireDate } from "utils/ecommerseTypes";
import { dateFormats } from "utils/formats";
import { MembershipsContext } from "contexts/memberships";
import { THeadCell } from "utils/types";
import { LocalizeText } from "components/localizer";

const headCells: THeadCell[] = [
    {
        id: "name",
        sortId: "organization__name",
        label: <LocalizeText tag="commercialCompanyNameShort" />,
    },
    {
        id: "org_nr",
        sortId: "organization__org_nr",
        label: <LocalizeText tag="orgNumberShort" />,
    },
    {
        id: "agreement_nr",
        sortId: "agreement_nr",
        label: <LocalizeText tag="customerNumberShort" />,
    },
    { id: "start_date", sortId: "start_date", label: <LocalizeText tag="from" /> },
    { id: "end_date", sortId: "end_date", label: <LocalizeText tag="contractDuration" /> },
    { id: "", label: "", alignCell: "right" },
];

interface IMembershipTableProps {
    pagination: PaginationStore<IMembership>;
    onClick?: (membership: IMembership) => void;
}

const MembershipTable: FC<IMembershipTableProps> = ({ pagination, onClick }) => {
    const { isFetchingMemberships } = useContext(MembershipsContext);

    const mapRows = (memberships: IMembership[]): IRow[] =>
        memberships.map((membership, index) => {
            const { organization, agreement_nr, start_date, end_date } = membership;
            const { name, org_nr } = organization;
            return {
                onClick: !!onClick ? () => onClick(membership) : undefined,
                data: [
                    name || "-",
                    org_nr ? prettifyOrgNumber(org_nr) : "-",
                    agreement_nr || "-",
                    start_date ? format(parseISO(start_date ?? ""), dateFormats.WEBDATE) : "",
                    getExpireDate(end_date),
                ],
            };
        });

    const rows = mapRows(pagination.results);

    return (
        <TableComponent
            headCells={headCells}
            isLoading={isFetchingMemberships}
            rows={rows}
            skeletonVariant="options"
            pagination={pagination}
        />
    );
};

export default MembershipTable;
