import React, { FC, useContext } from "react";
import Banner from "components/banner";
import { MembersTable } from "components/tableComponents/membersTable";
import TopBar from "components/tableComponents/topBar";
import SearchField from "components/tableComponents/paginationSearchField";
import { ProfileContext } from "contexts/profile";
import { usePaginationStore } from "utils/usePaginationStore";

export const MembersView: FC = () => {
    const { selectedOrganization } = useContext(ProfileContext);
    const memberPagination = usePaginationStore("members", undefined, { org_nr: selectedOrganization.org_nr });

    return (
        <div className="contentWrapper">
            <Banner
                title="members"
                subTitle="bannerMembers"
                image={`${process.env.PUBLIC_URL}/assets/images/1024x683/subscriptions-1024x683.jpg`}
            />
            <TopBar searchField={<SearchField pagination={memberPagination} tag={"searchMembers"} />} />
            <MembersTable pagination={memberPagination} />
        </div>
    );
};
