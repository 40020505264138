import React, { FC } from "react";
import { ListItem, ListItemText } from "@mui/material";
import { LocalizeText } from "components/localizer";
import { prettifyPostcode } from "utils/prettifiers";

interface IProps {
    data: {
        postal_address1: string;
        postal_address2: string;
        postal_city: string;
        postal_postcode: string;
        selected_invoicing_method: string;
        pdf_address: string;
        peppol_address: string;
        user_invoice_reference: string;
    };
    strikeThrough?: boolean;
}
export const InvoicingInfo: FC<IProps> = ({ data, strikeThrough = false }) => {
    const {
        postal_address1,
        postal_address2,
        postal_postcode,
        postal_city,
        selected_invoicing_method,
        pdf_address,
        peppol_address,
        user_invoice_reference,
    } = data;

    return (
        <>
            <ListItem divider>
                <ListItemText
                    primary={<LocalizeText tag="invoiceAddress" />}
                    secondary={
                        <span style={{ display: "flex", flexDirection: "column" }}>
                            <span>
                                <LocalizeText tag="addressLine1" />: {postal_address1}
                            </span>
                            <span>
                                <LocalizeText tag="addressLine2" />: {postal_address2 || "-"}
                            </span>
                            <span>
                                <LocalizeText tag="zipCode" />: {prettifyPostcode(postal_postcode)}
                            </span>
                            <span>
                                <LocalizeText tag="town" />: {postal_city}
                            </span>
                        </span>
                    }
                    secondaryTypographyProps={{ sx: { textDecoration: strikeThrough ? "line-through" : "none" } }}
                />
            </ListItem>
            <ListItem divider>
                <ListItemText
                    primary={<LocalizeText tag="paymentMethod" />}
                    secondary={
                        <span style={{ display: "flex", flexDirection: "column" }}>
                            <span>
                                <LocalizeText tag="paymentMethod" />: {selected_invoicing_method}
                            </span>
                            {selected_invoicing_method === "pdf" ? (
                                <span>
                                    <LocalizeText tag="paymentMethodPDF" />: {pdf_address || "-"}
                                </span>
                            ) : (
                                <span>
                                    <LocalizeText tag="paymentMethodPeppol" />: {peppol_address || "-"}
                                </span>
                            )}
                        </span>
                    }
                    secondaryTypographyProps={{ sx: { textDecoration: strikeThrough ? "line-through" : "none" } }}
                />
            </ListItem>
            <ListItem divider>
                <ListItemText
                    primary={<LocalizeText tag="invoiceReference" />}
                    secondary={user_invoice_reference}
                    secondaryTypographyProps={{ sx: { textDecoration: strikeThrough ? "line-through" : "none" } }}
                />
            </ListItem>
        </>
    );
};
