import React, { FC, useState } from "react";
import { format, isValid, parseISO } from "date-fns";
import VKButton from "components/vkButton";
import { dateFormats } from "utils/formats";
import { LocalizeText } from "components/localizer";
import { TFormEvent } from "utils/types";
import { ListItemText, List, ListItem, Box } from "@mui/material";
import { DatePicker } from "components/datePicker";

interface IProps {
    onSubmit: (date: string) => void;
    dateStart: string;
    dateEnd: string;
}

export const EditSubscriptionStartDatePicker: FC<IProps> = ({ onSubmit, dateStart, dateEnd }) => {
    const [startDate, setStartDate] = useState(parseISO(dateStart ?? ""));

    const handleSubmit = (event: TFormEvent): void => {
        event.preventDefault();
        if (startDate) {
            onSubmit(format(startDate, dateFormats.WEBDATE));
        }
    };

    return (
        <Box>
            <form onSubmit={handleSubmit}>
                <List>
                    <ListItem>
                        <ListItemText
                            primary={
                                <>
                                    <LocalizeText tag="currentActivationPeriod" />: {dateStart}
                                </>
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <DatePicker
                            maxDate={parseISO(dateEnd ?? "")}
                            format={dateFormats.WEBDATE}
                            label={<LocalizeText tag="newActivationDate" />}
                            value={startDate}
                            slotProps={{ textField: { fullWidth: true, error: !isValid(startDate) } }}
                            onChange={(newDate) => setStartDate(newDate as Date)}
                        />
                    </ListItem>
                    <ListItem>
                        <VKButton type="submit" tag="confirm" disabled={!isValid(startDate)} template="primary" />
                    </ListItem>
                </List>
            </form>
        </Box>
    );
};
