import styled from "@emotion/styled";
import { Box } from "@mui/material";
import colors from "styles/colors";

const StyledSwitchContainer = styled(Box)(({ theme }) => ({
    width: "100%",
    background: colors.white,
    "& .MuiFormControlLabel-root": {
        padding: "10px 20px",
    },
    "& .MuiSwitch-colorPrimary.Mui-checked": {
        color: colors.vkBlue,
    },
}));

export default StyledSwitchContainer;
