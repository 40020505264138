import React from "react";
import { LocalizeText } from "components/localizer";
import { TLanguageTag } from "contexts/language";

export const prettifyPriceText = (amount: string | number, decimals = 2): string => {
    const value: number = typeof amount === "number" ? amount : parseFloat(amount);
    if (isNaN(value)) {
        return "0";
    }
    const rounded = value.toFixed(decimals);
    const values = rounded.toString().split(".");
    return values[0].replace(/(?<=\d)(?=(\d\d\d)+(?!\d))/g, "$& ") + (values.length === 2 ? "." + values[1] : "");
};

interface IPrettify {
    amount: string | number;
    currency?: TLanguageTag;
    period?: TLanguageTag;
    decimals?: number;
}
const PrettifyPrice: React.FC<IPrettify> = ({ amount, currency = "SEK", period, decimals }) => {
    const price = prettifyPriceText(amount, decimals);

    return (
        <span>
            {price}{" "}
            {!period ? (
                <LocalizeText tag={currency} />
            ) : (
                <>
                    <LocalizeText tag={currency} />/
                    <LocalizeText tag={period} />
                </>
            )}
        </span>
    );
};

export default PrettifyPrice;
