import React, { FC, useMemo } from "react";
import { Grid } from "@mui/material";
import { LocalizeText } from "components/localizer";
import PrettifyPrice from "components/prettifyPrice";
import { ICustomerLedger } from "reducers/leases";
import TableComponent, { IRow } from "components/tableComponent";
import { THeadCell } from "utils/types";
import { usePaginationStore } from "utils/usePaginationStore";

const headCells: THeadCell[] = [
    { id: "feeType", label: <LocalizeText tag="feeType" /> },
    { id: "debitPeriodFrom", label: <LocalizeText tag="debitPeriodFrom" /> },
    { id: "debitPeriodTo", label: <LocalizeText tag="debitPeriodTo" /> },
    { id: "debitAmount", label: <LocalizeText tag="debitAmount" />, alignCell: "right" },
    { id: "vat", label: <LocalizeText tag="vat" />, alignCell: "right" },
    { id: "total", label: <LocalizeText tag="total" />, alignCell: "right" },
];

interface CollapsibleCustomerLedgerProps {
    customerLedger: ICustomerLedger;
}
const CollapsibleCustomerLedgerTable: FC<CollapsibleCustomerLedgerProps> = ({ customerLedger }) => {
    const pagination = usePaginationStore("customer-ledger-entries", undefined, {
        customer_ledger_uuid: customerLedger.customer_ledger_uuid,
        ordering: "debit_type_value",
    });

    const rows = useMemo((): IRow[] => {
        return pagination.results.map((entry) => ({
            data: [
                entry.debit_type_name,
                entry.debit_start_date,
                entry.debit_stop_date,
                <PrettifyPrice key={entry.amount_gross} amount={entry.amount_gross} />,
                <PrettifyPrice key={entry.amount_vat} amount={entry.amount_vat} />,
                <PrettifyPrice key={entry.amount_total} amount={entry.amount_total} />,
            ],
        }));
    }, [pagination.results]);

    return (
        <Grid container item xs={12}>
            <TableComponent
                tableSize="small"
                headCells={headCells}
                isLoading={pagination.query.isFetching}
                rows={rows}
                skeletonVariant="options"
                pagination={pagination}
            />
        </Grid>
    );
};
export default CollapsibleCustomerLedgerTable;
