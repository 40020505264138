import React, { useCallback, useMemo } from "react";
import webAPIRequest from "api";
import reducer, {
    IAction,
    ICommunityStaff,
    ICommunityStaffInvitation,
    ICommunityState,
    IEditCommunityStaff,
    ICostCalculationRun,
    INewCommunityStaff,
    initialState,
    ICreateCostCalculationRun,
    IRepresentative,
    IRepresentativeInvitation,
} from "reducers/community";
import { emptyPaginationActionData, TPaginationActionData } from "utils/paginationStore";
import { TCommunity, TProductCommunity } from "utils/ecommerseTypes";
import qs from "qs";
import { NotificationContext } from "./notification";

export const CommunityContext = React.createContext({
    ...initialState,
});

export const CommunityProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const { ...notification } = React.useContext(NotificationContext);
    const [currentState, dispatch] = React.useReducer<React.Reducer<ICommunityState, IAction>>(reducer, initialState);

    const fetchCommunityStaff = useCallback(
        async (params: Record<string, unknown>): Promise<TPaginationActionData<ICommunityStaff>> => {
            try {
                dispatch({ type: "FETCH_COMMUNITY_STAFF" });
                const returnData = await webAPIRequest("get", "/communities/staff-users/", { params });
                dispatch({
                    type: "FETCH_COMMUNITY_STAFF_SUCCESS",
                    data: returnData.data,
                });
                return returnData.data;
            } catch (error) {
                dispatch({ type: "FETCH_COMMUNITY_STAFF_FAILURE" });
                return emptyPaginationActionData;
            }
        },
        []
    );

    const fetchCommunityStaffInvitations = useCallback(
        async (params: Record<string, unknown>): Promise<TPaginationActionData<ICommunityStaffInvitation>> => {
            try {
                dispatch({ type: "FETCH_COMMUNITY_STAFF_INVITATIONS" });
                const returnData = await webAPIRequest("get", "/communities/staff-invitations/", { params });
                dispatch({
                    type: "FETCH_COMMUNITY_STAFF_INVITATIONS_SUCCESS",
                    data: returnData.data,
                });
                return returnData.data;
            } catch (error) {
                dispatch({ type: "FETCH_COMMUNITY_STAFF_INVITATIONS_FAILURE" });
                return emptyPaginationActionData;
            }
        },
        []
    );

    const fetchCommunity = useCallback(async (uuid: string): Promise<TCommunity> => {
        try {
            dispatch({ type: "FETCH_COMMUNITY" });
            const returnData = await webAPIRequest("get", `/communities/${uuid}/`);
            dispatch({
                type: "FETCH_COMMUNITY_SUCCESS",
            });
            return returnData.data;
        } catch (error) {
            dispatch({ type: "FETCH_COMMUNITY_FAILURE" });
            return {} as TCommunity;
        }
    }, []);

    const fetchCommunities = useCallback(
        async (params: Record<string, unknown>): Promise<TPaginationActionData<TCommunity>> => {
            try {
                dispatch({ type: "FETCH_COMMUNITIES" });
                const returnData = await webAPIRequest("get", "/communities/", { params });
                dispatch({
                    type: "FETCH_COMMUNITIES_SUCCESS",
                    data: returnData.data,
                });
                return returnData.data;
            } catch (error) {
                dispatch({ type: "FETCH_COMMUNITIES_FAILURE" });
                return emptyPaginationActionData;
            }
        },
        []
    );

    const fetchProductCommunities = useCallback(
        async (params: Record<string, unknown>): Promise<TPaginationActionData<TProductCommunity>> => {
            try {
                dispatch({ type: "FETCH_PRODUCT_COMMUNITIES" });
                const returnData = await webAPIRequest("get", "/communities/product-communities/", { params });
                dispatch({
                    type: "FETCH_PRODUCT_COMMUNITIES_SUCCESS",
                    data: returnData.data,
                });
                return returnData.data;
            } catch (error) {
                dispatch({ type: "FETCH_PRODUCT_COMMUNITIES_FAILURE" });
                return emptyPaginationActionData;
            }
        },
        []
    );

    const fetchCostCalculationRuns = useCallback(
        async (params: Record<string, unknown>): Promise<TPaginationActionData<ICostCalculationRun>> => {
            try {
                dispatch({ type: "FETCH_COST_CALCULATIONS" });
                const returnData = await webAPIRequest("get", "/billings/cost-calculations/", { params });
                dispatch({
                    type: "FETCH_COST_CALCULATIONS_SUCCESS",
                    data: returnData.data,
                });
                return returnData.data;
            } catch (error) {
                dispatch({ type: "FETCH_COST_CALCULATIONS_FAILURE" });
                return emptyPaginationActionData;
            }
        },
        []
    );

    const addCommunityStaff = useCallback(async (communityStaff: INewCommunityStaff): Promise<boolean> => {
        try {
            dispatch({ type: "ADD_COMMUNITY_STAFF" });
            const data = {
                communities: communityStaff.communities,
                email: communityStaff.email,
                first_name: communityStaff.firstName,
                last_name: communityStaff.lastName,
                main_community: communityStaff.mainCommunity,
                community_staff_role: communityStaff.communityStaffRole,
                phone: communityStaff.phone,
            };

            await webAPIRequest("post", "/communities/staff-invitations/", {
                data: data,
                paramsSerializer: (obj: typeof communityStaff) => qs.stringify(obj, { encode: false }),
            });
            dispatch({ type: "ADD_COMMUNITY_STAFF_SUCCESS" });
            return true;
        } catch (error) {
            dispatch({ type: "ADD_COMMUNITY_STAFF_FAILURE" });
            return false;
        }
    }, []);

    const editCommunityStaff = useCallback(async (userId: number, communityStaff: IEditCommunityStaff): Promise<boolean> => {
        try {
            dispatch({ type: "EDIT_COMMUNITY_STAFF" });

            const data = {
                communities: communityStaff.communities,
                main_community: communityStaff.mainCommunity,
                role: communityStaff.role,
                community_staff_role: communityStaff.communityStaffRole
            };

            await webAPIRequest("PUT", `/communities/staff-users/${userId}/`, {
                data: data,
                paramsSerializer: (obj: typeof data) => qs.stringify(obj, { encode: false }),
            });
            dispatch({ type: "EDIT_COMMUNITY_STAFF_SUCCESS" });
            return true;
        } catch (error) {
            dispatch({ type: "EDIT_COMMUNITY_STAFF_FAILURE" });
            return false;
        }
    }, []);

    const deactivateCommunityStaff = useCallback(async (userId: number): Promise<void> => {
        try {
            dispatch({ type: "DEACTIVATE_COMMUNITY_STAFF" });

            await webAPIRequest("patch", `/communities/staff-users/${userId}/deactivate/`, {});
            dispatch({ type: "DEACTIVATE_COMMUNITY_STAFF_SUCCESS" });
        } catch (error) {
            dispatch({ type: "DEACTIVATE_COMMUNITY_STAFF_FAILURE" });
            return;
        }
    }, []);

    const addCostCalculation = useCallback(
        async (data: ICreateCostCalculationRun): Promise<Blob | null> => {
            try {
                dispatch({ type: "ADD_COST_CALCULATION" });
                const response = await webAPIRequest("POST", "/billings/cost-calculations/", {
                    data: data,
                    responseType: "blob",
                    paramsSerializer: (obj: typeof data) => qs.stringify(obj, { encode: false }),
                });
                dispatch({ type: "ADD_COST_CALCULATION_SUCCESS" });
                return response.data;
            } catch (error) {
                dispatch({ type: "ADD_COST_CALCULATION_FAILURE" });
                notification.enqueNotification("error_addCostCalculation", error);
                return null;
            }
        },
        [notification]
    );

    const fetchRepresentatives = useCallback(
        async (params: Record<string, unknown>): Promise<TPaginationActionData<IRepresentative>> => {
            try {
                dispatch({ type: "FETCH_REPRESENTATIVE" });
                const returnData = await webAPIRequest("get", "/communities/representatives/", { params });
                dispatch({
                    type: "FETCH_REPRESENTATIVE_SUCCESS",
                    data: returnData.data,
                });
                return returnData.data;
            } catch (error) {
                dispatch({ type: "FETCH_REPRESENTATIVE_FAILURE" });
                return emptyPaginationActionData;
            }
        },
        []
    );

    const fetchRepresentativeInvitations = useCallback(
        async (params: Record<string, unknown>): Promise<TPaginationActionData<IRepresentativeInvitation>> => {
            try {
                dispatch({ type: "FETCH_REPRESENTATIVE_INVITATION" });
                const returnData = await webAPIRequest("get", "/communities/representative-invitations/", { params });
                dispatch({
                    type: "FETCH_REPRESENTATIVE_INVITATION_SUCCESS",
                    data: returnData.data,
                });
                return returnData.data;
            } catch (error) {
                dispatch({ type: "FETCH_REPRESENTATIVE_INVITATION_FAILURE" });
                return emptyPaginationActionData;
            }
        },
        []
    );

    React.useEffect(() => {
        if (!initialState.communityStaffPagination.isInitialized()) {
            initialState.communityStaffPagination.initialize(fetchCommunityStaff);
        }
        if (!initialState.communityStaffInvitationPagination.isInitialized()) {
            initialState.communityStaffInvitationPagination.initialize(fetchCommunityStaffInvitations);
        }
        if (!initialState.communitiesPagination.isInitialized()) {
            initialState.communitiesPagination.initialize(fetchCommunities);
        }

        if (!initialState.productCommunitiesPagination.isInitialized()) {
            initialState.productCommunitiesPagination.initialize(fetchProductCommunities);
        }

        if (!initialState.costCalculationPagination.isInitialized()) {
            initialState.costCalculationPagination.initialize(fetchCostCalculationRuns);
        }
        if (!initialState.representativePagination.isInitialized()) {
            initialState.representativePagination.initialize(fetchRepresentatives);
        }
        if (!initialState.representativeInvitationPagination.isInitialized()) {
            initialState.representativeInvitationPagination.initialize(fetchRepresentativeInvitations);
        }
    }, [
        fetchCommunityStaff,
        fetchCommunityStaffInvitations,
        fetchCommunities,
        fetchProductCommunities,
        fetchCostCalculationRuns,
        fetchRepresentatives,
        fetchRepresentativeInvitations,
    ]);

    const value = useMemo(() => {
        return {
            ...currentState,
            addCommunityStaff,
            editCommunityStaff,
            deactivateCommunityStaff,
            addCostCalculation,
            fetchCommunity,
            fetchProductCommunities,
        };
    }, [
        currentState,
        addCommunityStaff,
        editCommunityStaff,
        deactivateCommunityStaff,
        addCostCalculation,
        fetchCommunity,
        fetchProductCommunities,
    ]);

    return <CommunityContext.Provider value={value}>{children}</CommunityContext.Provider>;
};

export default CommunityContext;
