import React, { FC } from "react";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Grid, Button } from "@mui/material";
import { addMonths, isAfter, isBefore } from "date-fns";
import { LocalizeText } from "./localizer";
import { DatePicker } from "./datePicker";

interface IProps {
    currentDate: Date;
    onChange: (newDate: Date) => void;
    minDate?: Date;
    maxDate?: Date;
}
export const MonthSelector: FC<IProps> = ({ currentDate, onChange, minDate, maxDate }) => {
    return (
        <Grid container alignItems="center" gap="10px" width="fit-content" wrap="nowrap">
            <Grid item>
                <Button
                    variant="contained"
                    disabled={minDate ? isBefore(addMonths(currentDate, -1), minDate) : false}
                    onClick={() => onChange(addMonths(currentDate, -1))}
                >
                    <ChevronLeft />
                </Button>
            </Grid>
            <Grid item>
                <DatePicker
                    label={<LocalizeText tag="month" />}
                    views={["month", "year"]}
                    value={currentDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    slotProps={{ textField: { size: "small"} }}
                    onChange={(newDate: Date) => onChange(newDate as Date)}
                />
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    disabled={maxDate ? isAfter(addMonths(currentDate, 1), maxDate) : false}
                    onClick={() => onChange(addMonths(currentDate, 1))}
                >
                    <ChevronRight />
                </Button>
            </Grid>
        </Grid>
    );
};
