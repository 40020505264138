import PaginationStore, { TPaginationActionData } from "utils/paginationStore";
import { IBuilding } from "./realestates";

export interface INewsArticle {
    news_uuid: string;
    start_date: string;
    stop_date: string;
    created_time: string;
    updated_time: string;
    published_time: string;
    is_important: boolean;
    title: string;
    header: string;
    content: string;
    created_by: string;
    buildings: IBuilding[];
    updated_by: string;
    published_by: string;
    type_name: string;
    type_value: string;
}

export interface INewsArticles {
    isFetchingNews: boolean;
    defaultArticle: INewsArticle;
    newsListPagination: PaginationStore<INewsArticle>;
    fetchNews: (params: Record<string, unknown>) => Promise<TPaginationActionData<INewsArticle>>;
}

export const initialState: INewsArticles = {
    newsListPagination: new PaginationStore<INewsArticle>(),
    isFetchingNews: false,
    defaultArticle: {
        title: "Inga nya nyhetsuppdateringar?",
        header: `På Vasakronans hemsida har vi samlat
            ett axplock av vad som händer i vårt
            bolag och runt omkring oss. En blandning
            av sådant som vi tycker är viktigt och
            spännande. Men också djupdykningar
            och nörderi. Trevlig läsning.`,
        content: `På Vasakronans hemsida har vi samlat
            ett axplock av vad som händer i vårt
            bolag och runt omkring oss. En blandning
            av sådant som vi tycker är viktigt och
            spännande. Men också djupdykningar
            och nörderi. Trevlig läsning.
            <br>
            <br>
            <a href="https://vasakronan.se/aktuellt/" target="_blank">Aktuellt - Vasakronan</a>`,
        news_uuid: "",
        start_date: "",
        stop_date: "",
        created_time: "",
        updated_time: "",
        published_time: "",
        is_important: false,
        created_by: "",
        buildings: [],
        updated_by: "",
        published_by: "",
        type_name: "",
        type_value: "",
    } as INewsArticle,
    fetchNews: async () => ({} as TPaginationActionData<INewsArticle>),
};

export type IAction =
    | { type: "FETCH_NEWS_LIST" }
    | { type: "FETCH_NEWS_LIST_SUCCESS"; data: TPaginationActionData<INewsArticle> }
    | { type: "FETCH_NEWS_LIST_FAILURE" };

function reducer(state: INewsArticles, action: IAction): INewsArticles {
    switch (action.type) {
        case "FETCH_NEWS_LIST":
            return { ...state, isFetchingNews: true };
        case "FETCH_NEWS_LIST_SUCCESS":
        case "FETCH_NEWS_LIST_FAILURE":
            return { ...state, isFetchingNews: false };

        default:
            return { ...state };
    }
}

export default reducer;
