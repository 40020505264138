import React, { ChangeEvent, FC, ReactNode, useState } from "react";
import { Divider, Grid, ListItem, ListItemTextProps, TextFieldProps } from "@mui/material";
import VKButton from "components/vkButton";

interface IProps {
    renderInput: (props: TextFieldProps) => ReactNode;
    renderView: (props: ListItemTextProps) => ReactNode;
    onSubmit: () => void;
    onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    label: ReactNode;
    inputIsValid: boolean;
    onCancel?: () => void;
    editDisabled?: boolean;
}

const ListInlineEditWrapper: FC<IProps> = ({
    renderInput,
    renderView,
    onSubmit,
    onChange,
    label,
    inputIsValid,
    onCancel,
    editDisabled,
}) => {
    const [isEditing, setIsEditing] = useState(false);

    const onClickSave = (): void => {
        onSubmit();
        setIsEditing(false);
    };

    return (
        <>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={isEditing ? 12 : 8}>
                    <ListItem sx={{ gap: "5px" }}>
                        {isEditing ? (
                            <>
                                {renderInput({
                                    fullWidth: true,
                                    label: label,
                                    onSubmit: onSubmit,
                                    onChange: onChange,
                                })}
                            </>
                        ) : (
                            <>
                                {renderView({
                                    primary: label,
                                })}
                            </>
                        )}
                    </ListItem>
                </Grid>
                {typeof editDisabled !== "undefined" && editDisabled ? null : (
                    <Grid item>
                        {isEditing ? (
                            <ListItem sx={{ gap: "5px", paddingTop: 0 }}>
                                <VKButton
                                    template="save"
                                    tag="save"
                                    size="small"
                                    disabled={!inputIsValid}
                                    onClick={onClickSave}
                                    type="submit"
                                />
                                <VKButton
                                    template="cancel"
                                    tag="cancel"
                                    size="small"
                                    onClick={() => {
                                        setIsEditing(false);
                                        onCancel?.();
                                    }}
                                />
                            </ListItem>
                        ) : (
                            <VKButton size="small" tag="edit" onClick={() => setIsEditing(true)} template="primary" />
                        )}
                    </Grid>
                )}
            </Grid>
            <Divider />
        </>
    );
};

export default ListInlineEditWrapper;
