import { Grid, Typography } from "@mui/material";
import { LocalizeText } from "components/localizer";
import React from "react";
import colors from "styles/colors";


const PageNotFound: React.FC = () => {
    return (
        <Grid
            container
            spacing={2}
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{
                marginTop: "2rem",
                backgroundColor: colors.white,
                minHeight: "70%",
            }}>
            <Grid item lg={10} md={12}>
                <Typography 
                    variant="h4"
                    sx={{
                        fontFamily: "Vasakronan",
                        textAlign: "center",
                        marginBottom: "2rem",
                        color: colors.vkBlue
                    }}
                >
                    <LocalizeText tag="pageNotFoundTitle" />
                </Typography>
                <Typography variant="body1">
                    <LocalizeText tag="pageNotFoundDetail" />
                </Typography>
            </Grid>
        </Grid>
    );
};

export default PageNotFound;
