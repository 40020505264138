import { UseQueryResult, useQuery } from "@tanstack/react-query";
import webAPIRequest from "api";
import { TNotificationTag } from "contexts/language";
import { NotificationContext } from "contexts/notification";
import { useCallback, useContext, useMemo, useState } from "react";
import qs from "qs";
import { IPaginationStore, TPaginationActionData, TParamValue } from "utils/paginationStore";
import { IPublicEvent, IPublicEventsQueryParams } from "adapters/eventsAdapter";
import {
    ICustomerLedger,
    ICustomerLedgerEntriesQueryParams,
    ICustomerLedgerEntry,
    ICustomerLedgersQueryParams,
    ILease,
    ILeaseContactPersonsQueryParams,
    ILeaseInvitation,
    ILeaseInvitationsQueryParams,
    ILeasesQueryParams,
} from "reducers/leases";
import { IExternalMembership, IExternalMembershipQueryParams } from "reducers/externalMemberships";
import { IProduct, IProductsQueryParams } from "reducers/products";
import { IInvoiceEvent, IInvoiceEventsQueryParams, IInvoiceOrder, IInvoiceOrdersQueryParams } from "reducers/invoices";
import { IContactPerson, IMembership, IMembershipsQueryParams } from "reducers/memberships";
import { IArena, IArenaBuilding, IArenasQueryParams } from "reducers/arenas";
import {
    IAssociationsQueryParams,
    IMember,
    IMemberSubscriptionAssociation,
    IMembersQueryParams,
} from "reducers/members";
import { ISubscription, ISubscriptionsQueryParams } from "reducers/subscriptions";
import {
    IBuilding,
    IBuildingsQueryParams,
    IPremise,
    IPremisesQueryParams,
    ITask,
    ITasksQueryParams,
} from "reducers/realestates";

interface IApiRoutes {
    "arena-buildings": { params: never; result: IArenaBuilding; routeParams: undefined };
    arenas: { params: IArenasQueryParams; result: IArena; routeParams: undefined };
    buildings: { params: IBuildingsQueryParams; result: IBuilding; routeParams: undefined };
    "customer-ledger-entries": {
        params: ICustomerLedgerEntriesQueryParams;
        result: ICustomerLedgerEntry;
        routeParams: undefined;
    };
    "customer-ledgers": { params: ICustomerLedgersQueryParams; result: ICustomerLedger; routeParams: undefined };
    "external-memberships": {
        params: IExternalMembershipQueryParams;
        result: IExternalMembership;
        routeParams: undefined;
    };
    "invoice-events": { params: IInvoiceEventsQueryParams; result: IInvoiceEvent; routeParams: undefined };
    "invoice-orders": { params: IInvoiceOrdersQueryParams; result: IInvoiceOrder; routeParams: undefined };
    "lease-contact-persons": {
        params: ILeaseContactPersonsQueryParams;
        result: IContactPerson;
        routeParams: { lease_uuid: string };
    };
    "lease-invitations": { params: ILeaseInvitationsQueryParams; result: ILeaseInvitation; routeParams: undefined };
    leases: { params: ILeasesQueryParams; result: ILease; routeParams: undefined };
    "member-subscription-associations": {
        params: IAssociationsQueryParams;
        result: IMemberSubscriptionAssociation;
        routeParams: undefined;
    };
    members: { params: IMembersQueryParams; result: IMember; routeParams: undefined };
    memberships: { params: IMembershipsQueryParams; result: IMembership; routeParams: undefined };
    premises: { params: IPremisesQueryParams; result: IPremise; routeParams: undefined };
    products: { params: IProductsQueryParams; result: IProduct; routeParams: undefined };
    "public-events": { params: IPublicEventsQueryParams; result: IPublicEvent; routeParams: undefined };
    subscriptions: { params: ISubscriptionsQueryParams; result: ISubscription; routeParams: undefined };
    tasks: { params: ITasksQueryParams; result: ITask; routeParams: undefined };
}

type TRouteKey = keyof IApiRoutes;

const ROUTE_DATA: Record<TRouteKey, { route: string; errorTag: TNotificationTag; disableEncoding?: boolean }> = {
    "arena-buildings": {
        route: "/memberships/buildings/",
        errorTag: "error_fetchBuildings",
    },
    arenas: { route: "/offices/", errorTag: "error_fetchArenas" },
    buildings: { route: "/vk_data/buildings/", errorTag: "error_fetchBuildings" },
    "customer-ledger-entries": {
        route: "/vk_data/customer-ledger-entries/",
        errorTag: "error_fetchCustomerLedgerEntries",
    },
    "customer-ledgers": { route: "/vk_data/customer-ledgers/", errorTag: "error_fetchCustomerLedgers" },
    "external-memberships": { route: "/memberships/externals/", errorTag: "error_fetchMemberships" },
    "invoice-events": { route: "/invoices/events/", errorTag: "error_fetchInvoiceEvents" },
    "invoice-orders": { route: "/invoices/orders/", errorTag: "error_fetchInvoiceOrders" },
    "lease-contact-persons": {
        route: "/vk_data/leases/{lease_uuid}/contact-persons/",
        errorTag: "error_fetchContactPersons",
    },
    "lease-invitations": { route: "/vk_data/lease-invitations/", errorTag: "error_fetchLeaseInvitation" },
    leases: { route: "/vk_data/leases/", errorTag: "error_fetchLeases" },
    "member-subscription-associations": { route: "/members/associations/", errorTag: "error_fetchAssociations" },
    members: { route: "/members/", errorTag: "error_fetchMembers" },
    memberships: { route: "/memberships/", errorTag: "error_fetchMemberships" },
    premises: { route: "/vk_data/premises/", errorTag: "error_fetchPremises" },
    products: { route: "/products/", errorTag: "error_fetchProducts" },
    "public-events": { route: "/events/public-events/", errorTag: "error_fetchPublicEvents" },
    subscriptions: { route: "/subscriptions/", errorTag: "error_fetchSubscriptions" },
    tasks: { route: "/vk_data/tasks/", errorTag: "error_fetchTasks", disableEncoding: true },
};

interface IBaseParams {
    page: number;
    page_size: number;
    default_page_size: number,
}

type IParams<T extends TRouteKey> = IBaseParams & IApiRoutes[T]["params"];
type IParamsPartial<T extends TRouteKey> = Partial<IBaseParams> & IApiRoutes[T]["params"];

export interface IUsePaginationStore<T extends TRouteKey> extends IPaginationStore<IApiRoutes[T]["result"]> {
    query: UseQueryResult<TPaginationActionData<IApiRoutes[T]["result"]>>;
    nextPage: () => void;
}

export const usePaginationStore = <T extends TRouteKey>(
    routeKey: T,
    routeParams: IApiRoutes[T]["routeParams"],
    params?: IParamsPartial<T>,
    options?: {
        appendResults?: boolean;
    }
): IUsePaginationStore<T> => {
    const [queryParams, setQueryParams] = useState<IParams<T>>({
        page: 0,
        page_size: 5,
        default_page_size: 5,
        ...params,
    } as IParams<T>);
    const [results, setResults] = useState<IApiRoutes[T]["result"][]>([]);

    const onFetch = useCallback(
        (newResults: IApiRoutes[T]["result"][]) => {
            if (options?.appendResults) {
                setResults((r) => {
                    return [...r, ...newResults];
                });
            } else {
                setResults(newResults);
            }
        },
        [options?.appendResults]
    );

    const paginationQueryResult = usePaginationQuery(
        routeKey,
        routeParams,
        {
            ...queryParams,
            page: queryParams.page + 1,
        },
        onFetch
    );

    const setParam = useCallback(
        (key: string, value: TParamValue) => {
            const newParams = { ...queryParams };
            if (key !== "page") {
                newParams.page = 0;
            }
            if (value === null) {
                delete newParams[key as keyof IBaseParams];
                setQueryParams(newParams);
            } else {
                setQueryParams({ ...newParams, [key]: value });
            }
        },
        [queryParams]
    );

    return {
        results,
        page: queryParams.page,
        changePage: (newPage: number) => setQueryParams({ ...queryParams, page: newPage }),
        nextPage: () => setQueryParams({ ...queryParams, page: queryParams.page + 1 }),
        getInitial: () => { },
        setParam,
        getParam: (key: string) => queryParams[key as keyof IBaseParams],
        totalCount: paginationQueryResult.data?.count ?? 0,
        query: paginationQueryResult,
    };
};

export const usePaginationQuery = <T extends TRouteKey>(
    routeKey: T,
    routeParams: IApiRoutes[T]["routeParams"],
    params: IParams<T>,
    onFetch: (newResults: IApiRoutes[T]["result"][]) => void
): UseQueryResult<TPaginationActionData<IApiRoutes[T]["result"]>> => {
    const { enqueNotification } = useContext(NotificationContext);

    const route = useMemo(() => {
        let route = ROUTE_DATA[routeKey].route;
        if (!routeParams) {
            return route;
        }

        Object.entries(routeParams).forEach(([key, value]) => {
            route = route.replace(`{${key}}`, value);
        });
        return route;
    }, [routeKey, routeParams]);

    return useQuery({
        queryKey: [routeKey, routeParams, params],
        queryFn: () =>
            webAPIRequest("get", route, {
                params,
                paramsSerializer: (obj) => qs.stringify(obj, { encode: !ROUTE_DATA[routeKey].disableEncoding }),
            })
                .then((response) => {
                    onFetch(response.data?.results ?? []);
                    return response.data;
                })
                .catch((err) => {
                    const errorTag = ROUTE_DATA[routeKey].errorTag;
                    if (errorTag) {
                        enqueNotification(errorTag, err);
                    }
                    throw err;
                }),
    });
};
