import React, { FC, useCallback, useEffect, useState } from "react";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import {
    IPublicEventSubscription,
    TPublicEventType,
    fetchPublicEventSubscriptions,
    fetchPublicEventTypes,
    createPublicEventSubscription,
    deletePublicEventSubscription,
} from "adapters/eventsAdapter";
import { CenteredLoader } from "components/centeredLoader";
import { LocalizeText } from "components/localizer";
import { TLanguageTag } from "contexts/language";

export const EventSubscriptionsSettings: FC = () => {
    const [eventTypes, setEventTypes] = useState<TPublicEventType[]>([]);
    const [currentSubscriptions, setCurrentSubscriptions] = useState<IPublicEventSubscription[]>([]);
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        (async () => {
            const types = await fetchPublicEventTypes();
            setEventTypes(types);
            const subscriptions = await fetchPublicEventSubscriptions();
            setCurrentSubscriptions(subscriptions);
            setInitialized(true);
        })();
    }, []);

    const onChange = useCallback(async (type: TPublicEventType, id?: number) => {
        if (!id) {
            setCurrentSubscriptions((list) => {
                const newList = [...list, { id: -1, created: "", public_event_type: type }];
                return newList;
            });
            const newSub = await createPublicEventSubscription(type);
            setCurrentSubscriptions((list) => {
                const newList = [...list];
                const index = newList.findIndex((s) => s.public_event_type === type);
                newList[index].id = newSub.id;
                return newList;
            });
        } else {
            deletePublicEventSubscription(id);
            setCurrentSubscriptions((list) => {
                const newList = [...list];
                const index = newList.findIndex((s) => s.public_event_type === type);
                newList.splice(index, 1);
                return newList;
            });
        }
    }, []);

    if (!initialized) {
        return <CenteredLoader size="40px" />;
    }

    return (
        <Grid container direction="column">
            {eventTypes.map((type) => {
                const subscription = currentSubscriptions.find((s) => s.public_event_type === type);
                const isCreating = subscription?.id === -1;
                return (
                    <Grid key={type} item>
                        <FormControlLabel
                            label={<LocalizeText tag={`eventType_${type}` as TLanguageTag} />}
                            disabled={isCreating}
                            control={
                                <Checkbox checked={!!subscription} onChange={() => onChange(type, subscription?.id)} />
                            }
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
};
