import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box, Grid, List, ListItem, MenuItem, Skeleton, TextField, Typography } from "@mui/material";
import VKButton from "components/vkButton";
import ConfirmDialog from "components/confirmDialog";
import FileUploadField from "components/fileUpload";
import { LocalizeText } from "components/localizer";
import CommunityContext from "contexts/community";
import { InvoicesContext } from "contexts/invoices";
import { LanguageContext, TLanguageTag } from "contexts/language";
import { addMonths, format, lastDayOfMonth } from "date-fns";
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { IInvoiceEvent, IInvoiceEventCreationErrors } from "reducers/invoices";
import { TCommunity } from "utils/ecommerseTypes";
import { dateFormats } from "utils/formats";
import { getMonth } from "utils/helpers";

export interface FileUploadFormData {
    community: TCommunity | undefined;
    period: Date;
    files: File[];
}

interface IProps {
    uploadData: FileUploadFormData;
    setUploadData: (data: FileUploadFormData) => void;
    onUpload: (result: IInvoiceEvent | IInvoiceEventCreationErrors) => void;
    onCancel: () => void;
}

const UploadFileform: FC<IProps> = ({ uploadData, setUploadData, onUpload, onCancel }) => {
    const { createInvoiceEvent, isCreatingInvoiceEvent, validateInvoiceEvent, isValidatingInvoiceEvent } =
        useContext(InvoicesContext);
    const { currentLanguage } = useContext(LanguageContext);
    const [isNewCommunityPeriodCombo, setIsNewCommunityPeriodCombo] = useState<boolean>(true);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

    const { periodStart, periodStop } = useMemo((): { periodStart: Date; periodStop: Date } => {
        const start = new Date(uploadData.period);
        start.setDate(1);
        const stop = lastDayOfMonth(start);
        return { periodStart: start, periodStop: stop };
    }, [uploadData.period]);

    const extraPeriodTag = useMemo((): TLanguageTag | undefined => {
        const monthDiff = uploadData.period.getMonth() - new Date().getMonth();
        switch (monthDiff) {
            case -1:
                return "previousMonth";
            case 0:
                return "currentMonth";
            case 1:
                return "nextMonth";

            default:
                return undefined;
        }
    }, [uploadData.period]);

    useEffect(() => {
        async function checkValidity(): Promise<void> {
            if (!uploadData.community) {
                setIsNewCommunityPeriodCombo(true);
                return;
            }
            const alreadyUsed = await validateInvoiceEvent({
                community: uploadData.community?.uuid || "",
                period_start_date: format(periodStart, dateFormats.WEBDATE),
                period_stop_date: format(periodStop, dateFormats.WEBDATE),
            });
            setIsNewCommunityPeriodCombo(alreadyUsed);
        }

        checkValidity();
    }, [validateInvoiceEvent, setIsNewCommunityPeriodCombo, uploadData.community, periodStart, periodStop]);

    const startUpload = useCallback(async () => {
        setShowConfirmationDialog(false);

        const formData = new FormData();
        formData.append("community", uploadData.community?.uuid ?? "");
        formData.append("period_start_date", format(periodStart, dateFormats.WEBDATE));
        formData.append("period_stop_date", format(periodStop, dateFormats.WEBDATE));
        formData.append("file", uploadData.files[0]);

        const result = await createInvoiceEvent(formData);
        onUpload(result);
    }, [createInvoiceEvent, uploadData.community, periodStart, periodStop, uploadData.files, onUpload]);

    const onContinue = useCallback(() => {
        if (!isNewCommunityPeriodCombo) {
            setShowConfirmationDialog(true);
        } else {
            startUpload();
        }
    }, [isNewCommunityPeriodCombo, setShowConfirmationDialog, startUpload]);

    return (
        <List>
            <ListItem>
                <Typography variant="h3" mb="30px">
                    <LocalizeText tag="uploadFile" />
                </Typography>
            </ListItem>
            <CommunitiesSelection
                community={uploadData.community?.uuid ?? ""}
                onChange={(newCommunity) => setUploadData({ ...uploadData, community: newCommunity })}
            />
            <ListItem>
                <VKButton
                    variant="outlined"
                    size="large"
                    onClick={() => setUploadData({ ...uploadData, period: addMonths(uploadData.period, -1) })}
                >
                    <ChevronLeft />
                </VKButton>
                <Grid container flexDirection="column">
                    <Grid item>
                        <Typography margin="auto" textAlign="center">
                            {getMonth(periodStart.getMonth(), currentLanguage)}
                        </Typography>
                    </Grid>
                    {extraPeriodTag && (
                        <Grid item>
                            <Typography margin="auto" textAlign="center">
                                <LocalizeText tag={extraPeriodTag} />
                            </Typography>
                        </Grid>
                    )}
                </Grid>
                <VKButton
                    variant="outlined"
                    size="large"
                    onClick={() => setUploadData({ ...uploadData, period: addMonths(uploadData.period, 1) })}
                >
                    <ChevronRight />
                </VKButton>
            </ListItem>
            <ListItem>
                <FileUploadField
                    accept=".xls, .xlsx"
                    files={uploadData.files}
                    onFileUploadChange={(files) => setUploadData({ ...uploadData, files })}
                    singleFile={true}
                    tag="uploadFile"
                    vertical
                />
            </ListItem>
            {uploadData.files.length > 1 && (
                <ListItem>
                    <LocalizeText tag="errorOnlyOneFileAllowed" />
                </ListItem>
            )}
            {!isNewCommunityPeriodCombo && !isCreatingInvoiceEvent && (
                <ListItem>
                    <LocalizeText tag="usedCommunityPeriodCombo" />
                </ListItem>
            )}
            <ListItem>
                <Box sx={{ display: "flex", marginLeft: "auto", gap: "10px", marginTop: "30px" }}>
                    <VKButton tag="cancel" template="cancel" onClick={onCancel} />
                    <VKButton
                        tag="continue"
                        template="save"
                        disabled={!uploadData.community || uploadData.files.length !== 1 || isValidatingInvoiceEvent}
                        isLoading={isCreatingInvoiceEvent}
                        onClick={onContinue}
                    />
                </Box>
            </ListItem>

            <ConfirmDialog
                open={showConfirmationDialog}
                title={<LocalizeText tag="usedCommunityPeriodCombo" />}
                description={<LocalizeText tag="uploadInvoiceEventFileSure" />}
                onReject={() => setShowConfirmationDialog(false)}
                onAccept={startUpload}
            />
        </List>
    );
};

interface CommunitiesSelectionProps {
    community: string;
    onChange: (newCommunity: TCommunity | undefined) => void;
}

const CommunitiesSelection: FC<CommunitiesSelectionProps> = ({ community, onChange }) => {
    const { communitiesPagination, isFetchingCommunities } = useContext(CommunityContext);

    useEffect(() => {
        communitiesPagination.getInitial();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isFetchingCommunities) {
        return (
            <ListItem>
                <Skeleton variant="rectangular" width="100%" height="60px" />
            </ListItem>
        );
    }

    return (
        <ListItem>
            <TextField
                label={<LocalizeText tag="community" />}
                onChange={(e) => onChange(communitiesPagination.results.find((c) => c.uuid === e.target.value))}
                select
                fullWidth
                required
                value={community}
            >
                {communitiesPagination.results.map((community) => (
                    <MenuItem key={community.uuid} value={community.uuid}>
                        {community.title}
                    </MenuItem>
                ))}
            </TextField>
        </ListItem>
    );
};

export default UploadFileform;
