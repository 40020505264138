import React, { ChangeEvent, FC, ReactElement, useCallback, useContext, useMemo, useState } from "react";
import { parseISO, format } from "date-fns";
import { LocalizeText } from "components/localizer";
import { THeadCell } from "utils/types";
import { dateFormats } from "utils/formats";
import { getSubscriptionPeriod } from "utils/ecommerseTypes";
import TableComponent, { IRow } from "components/tableComponent";
import { Box, FormControlLabel, Switch } from "@mui/material";
import StyledSwitchContainer from "./switchContainer";
import { IPaginationStore } from "utils/paginationStore";
import { ISubscription } from "reducers/subscriptions";
import { QueryClientContext } from "@tanstack/react-query";
import { truncateText } from "utils/helpers";

interface IProps {
    pagination: IPaginationStore<ISubscription>;
    loading: boolean;
}

export const StaffSubscriptionsTable: FC<IProps> = ({ pagination, loading }) => {
    const [activeFilter, setActiveFilter] = useState<boolean>(false);

    const queryClient = useContext(QueryClientContext);

    const getDiscountText = (subscription: ISubscription): ReactElement => {
        const discountText: string = subscription.discounts_max_amount_excl_vat
            .map((num) => `${num} kr (${Math.round((num / subscription.base_cost_excl_vat) * 100)} %)`)
            .join(", ");

        return <span key={`${subscription.id}`}>{truncateText(discountText, 20)}</span>;
    };

    const getInternalNoteText = (subscription: ISubscription): ReactElement => {
        const internalNoteText: string = subscription.discount_internal_note.join(", ");

        return <span key={`${subscription.id}`}>{truncateText(internalNoteText, 20)}</span>;
    };

    const subscriptionsHeadCells = useCallback((): THeadCell[] => {
        return [
            {
                id: "identifier",
                sortId: "identifier",
                label: <LocalizeText tag="id" />,
            },
            {
                id: "productAndCommunity",
                sortId: "product__name",
                label: <LocalizeText tag="productAndCommunity" />,
            },
            {
                id: "company",
                sortId: "membership__organization__name",
                label: <LocalizeText tag="company" />,
            },
            {
                id: "binding_period",
                sortId: "end_date",
                label: <LocalizeText tag="subscriptionPeriod" />,
            },
            {
                id: "trialPeriod",
                sortId: "trial_period_until",
                label: <LocalizeText tag="trialPeriodShort" />,
            },
            {
                id: "discount",
                sortId: "discounts__amount_excl_vat",
                label: <LocalizeText tag="discount" />,
            },
            {
                id: "internalNote",
                sortId: "discounts__internal_note",
                label: <LocalizeText tag="internalNote" />,
            },
        ];
    }, []);

    const rows = useMemo((): IRow[] => {
        return pagination.results.map((sub) => ({
            data: [
                sub.identifier,
                sub.product_name,
                sub.organization_name,
                getSubscriptionPeriod(sub),
                sub.trial_period_until ? format(parseISO(sub.trial_period_until ?? ""), dateFormats.WEBDATE) : "",
                getDiscountText(sub),
                getInternalNoteText(sub),
            ],
        }));
    }, [pagination.results]);

    const onIncludeInactiveChange = (event: ChangeEvent<HTMLInputElement>): void => {
        pagination.setParam("active", !event.target.checked ? true : null);
        queryClient?.invalidateQueries({ queryKey: ["subscriptions"] });
        setActiveFilter(event.target.checked);
    };

    return (
        <Box>
            <StyledSwitchContainer>
                <FormControlLabel
                    control={<Switch checked={activeFilter} onChange={onIncludeInactiveChange} color="primary" />}
                    label={<LocalizeText tag="includeInactiveSubscriptions" />}
                />
            </StyledSwitchContainer>
            <TableComponent
                headCells={subscriptionsHeadCells()}
                isLoading={loading}
                skeletonVariant="options"
                pagination={pagination}
                rows={rows}
            />
        </Box>
    );
};
