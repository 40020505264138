import React, { FC, useContext, useState } from "react";
import List from "@mui/material/List";
import { LocalizeText } from "components/localizer";
import { Box, ListItem, ListItemText } from "@mui/material";
import { CenteredLoader } from "components/centeredLoader";
import { ModalContext } from "contexts/modals";
import { IProduct } from "reducers/products";
import { ProductsContext } from "contexts/products";
import EditableTextField from "./editableTextField";
import EditableRuleField from "./product/editableRuleField";
import PriceField from "./product/priceField";
import ServiceSectionField from "./product/serviceSectionField";
import { usePermission } from "utils/hooks/usePermission";
import { IUsePaginationStore } from "utils/usePaginationStore";
import NationalCommunitiesField from "components/drawers/product/NationalCommunitiesField";

interface IProps {
    product: IProduct;
    pagination?: IUsePaginationStore<"products">;
}

const ProductInformation: FC<IProps> = ({ product, pagination }) => {
    const { updateProduct, isUpdatingProduct } = useContext(ProductsContext);
    const { updateDrawer } = useContext(ModalContext);
    const { hasPermissions } = usePermission();
    const canEdit = hasPermissions(["change_product"]);
    const [communities, setCommunities] = useState(product.communities);

    const onSubmitUpdate = async (params: Record<string, unknown>): Promise<void> => {
        if (!pagination) {
            return;
        }

        const updated = await updateProduct(product.product_key, params);
        if (updated && Object.keys(updated).length) {
            await pagination.query.refetch();
            updateDrawer<typeof ProductInformation>({ product: updated, pagination });
        }
    };

    const onCommunitiesChange = (ids: string[]): void => {
        setCommunities(ids);
    };

    if (pagination?.query.isFetching || isUpdatingProduct) {
        return <CenteredLoader size={40} />;
    }

    if (!product) {
        return (
            <List>
                <ListItemText secondary={<LocalizeText tag="noDataAvailable" />} style={{ textAlign: "center" }} />
            </List>
        );
    }

    const { rules } = product;

    return (
        <Box
            sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <List>
                <ListItem divider>
                    <ListItemText primary={<LocalizeText tag="product" />} secondary={product.product_key} />
                </ListItem>
                <EditableTextField
                    obj={product}
                    tag="name"
                    prop="name"
                    onSubmitUpdate={onSubmitUpdate}
                    canEdit={canEdit}
                />
                <EditableTextField
                    obj={product}
                    tag="description"
                    prop="description"
                    onSubmitUpdate={onSubmitUpdate}
                    canEdit={canEdit}
                />
                <ListItem divider>
                    <ListItemText
                        primary={<LocalizeText tag="community" />}
                        secondary={product.community_title ?? "-"}
                    />
                </ListItem>
                <ListItem divider>
                    <ListItemText
                        primary={<LocalizeText tag="nationalAccess" />}
                        secondary={product.national_access ? <LocalizeText tag="yes" /> : <LocalizeText tag="no" />}
                    />
                </ListItem>
                {product.national_access && (
                    <NationalCommunitiesField
                        product={product}
                        onSubmitUpdate={onSubmitUpdate}
                        onCommunitiesChange={onCommunitiesChange}
                        selectedCommunities={communities}
                    />
                )}
                <ListItem divider>
                    <ListItemText primary={<LocalizeText tag="category" />} secondary={product.category} />
                </ListItem>
                {product.category === "arena_office" && (
                    <ListItem divider>
                        <ListItemText primary={<LocalizeText tag="slots" />} secondary={product.slots} />
                    </ListItem>
                )}
                <PriceField
                    product={product}
                    tag="priceExclVat"
                    prop="amount_excl_vat"
                    onSubmitUpdate={onSubmitUpdate}
                    canEdit={canEdit}
                />
                <PriceField
                    product={product}
                    tag="vat"
                    prop="vat_proportion"
                    onSubmitUpdate={onSubmitUpdate}
                    canEdit={canEdit}
                />

                <EditableRuleField
                    product={product}
                    tag="noticePeriod"
                    numberProp="notice_period"
                    unitProp="notice_period_value_type"
                    onSubmitUpdate={onSubmitUpdate}
                    canEdit={canEdit}
                />
                <EditableRuleField
                    product={product}
                    tag="bindingPeriod"
                    numberProp="binding_period"
                    unitProp="binding_period_value_type"
                    onSubmitUpdate={onSubmitUpdate}
                    canEdit={canEdit}
                />
                <ListItem divider>
                    <ListItemText
                        primary={<LocalizeText tag="trialPeriod" />}
                        secondary={
                            rules?.trial_period ? (
                                <>
                                    {rules.trial_period} <LocalizeText tag={rules.trial_period_value_type} />
                                </>
                            ) : (
                                "-"
                            )
                        }
                    />
                </ListItem>
                <ListItem divider>
                    <ListItemText primary={<LocalizeText tag="costCenter" />} secondary={product.cost_center || "-"} />
                </ListItem>
                <ListItem divider>
                    <ListItemText
                        primary={<LocalizeText tag="revenueAccount" />}
                        secondary={product.revenue_account_code}
                    />
                </ListItem>
                <ListItem divider>
                    <ListItemText primary={<LocalizeText tag="vatAccount" />} secondary={product.vat_account_code} />
                </ListItem>
                <ServiceSectionField product={product} onSubmitUpdate={onSubmitUpdate} canEdit={canEdit} />
            </List>
        </Box>
    );
};

export default ProductInformation;
