import React, { FC, SyntheticEvent, useContext, useEffect, useState } from "react";
import { ModalContext } from "contexts/modals";
import { Grid, List, ListItem, MenuItem, Skeleton, TextField } from "@mui/material";
import { LocalizeText } from "components/localizer";
import validator from "utils/validator";
import { ListTextField } from "../drawerComponents";
import { sanitizePhoneNumber } from "utils/helpers";
import { LanguageContext } from "contexts/language";
import CommunityContext from "contexts/community";
import VKButton from "components/vkButton";
import { CommunityStaffRoles, INewCommunityStaff } from "reducers/community";

const AddCommunityStaff: FC = () => {
    const { closeDrawers } = useContext(ModalContext);
    const { localize } = useContext(LanguageContext);
    const { communitiesPagination, isFetchingCommunities, addCommunityStaff, isAddingCommunityStaff } =
        useContext(CommunityContext);

    const stateInitValue: INewCommunityStaff = {
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        //role: -1,
        communities: [],
        mainCommunity: "",
        communityStaffRole: "",
    };

    const [newContact, setNewContact] = useState<INewCommunityStaff>(stateInitValue);

    useEffect(() => {
        communitiesPagination.getInitial();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (communitiesPagination.totalCount === 1) {
            setNewContact({
                ...newContact,
                communities: communitiesPagination.results.flatMap((community) => community.uuid),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [communitiesPagination.results]);

    const handleSubmit = async (event: SyntheticEvent): Promise<void> => {
        event.preventDefault();
        const parsedPhoneNumber = sanitizePhoneNumber(newContact.phone);
        const success = await addCommunityStaff({ ...newContact, phone: parsedPhoneNumber });
        if (success) {
            closeDrawers();
        }
    };

    const onChange = (type: keyof INewCommunityStaff, value: string[] | string | number): void => {
        setNewContact((prevState) => {
            const { communities, mainCommunity } = prevState;

            let updatedList: string[] = communities;
            let mainCommunityValue = mainCommunity;

            if (type === "mainCommunity") {
                if (!updatedList.includes(value as string)) {
                    updatedList.push(value as string);
                }

                mainCommunityValue = value as string;
            }

            if (type === "communities") {
                if (!(value as string[]).includes(mainCommunity)) {
                    mainCommunityValue = "";
                }

                updatedList = value as string[];
            }

            return {
                ...prevState,
                [type]: value,
                mainCommunity: mainCommunityValue,
                communities: updatedList,
            };
        });
    };

    const validateData = (): boolean => {
        return (
            validator.email(newContact.email) &&
            validator.phoneNumber(newContact.phone) &&
            !!newContact.communities.length
        );
    };

    if (isFetchingCommunities) {
        return (
            <Grid container justifyContent="center" marginTop={4} gap={2}>
                {Array.from(new Array(7)).map((item) => (
                    <Skeleton key={`skeleton-${item}`} variant="rectangular" width="100%" height={60} />
                ))}
            </Grid>
        );
    }

    return (
        <div data-testid="addContactPerson-root">
            <List>
                <ListItem>
                    <LocalizeText tag={"addNewCommunityStaffHelperText"} />
                </ListItem>
                <ListItem>
                    <LocalizeText tag={"addNewCommunityStaffHelperText2"} />
                </ListItem>
                <form onSubmit={handleSubmit}>
                    <ListTextField
                        isEditing
                        testid="addContactPerson-firstName"
                        label={<LocalizeText tag="firstName" />}
                        data={newContact.firstName}
                        onChange={(e) => onChange("firstName", e.target.value)}
                    />
                    <ListTextField
                        isEditing
                        testid="addContactPerson-lastName"
                        label={<LocalizeText tag="lastName" />}
                        data={newContact.lastName}
                        onChange={(e) => onChange("lastName", e.target.value)}
                    />
                    <ListTextField
                        testid="addContactPerson-email"
                        notValid={!validator.email(newContact.email, true)}
                        label={<LocalizeText tag="email" />}
                        errorText={<LocalizeText tag="errorEmail" />}
                        data={newContact.email}
                        onChange={(e) => onChange("email", e.target.value)}
                        isEditing
                    />
                    <ListTextField
                        isEditing
                        testid="addContactPerson-phone"
                        notValid={!validator.phoneNumber(newContact.phone, true)}
                        label={<LocalizeText tag="phoneNumber" />}
                        data={newContact.phone}
                        onChange={(e) => onChange("phone", e.target.value)}
                        errorText={<LocalizeText tag="errorMobile" />}
                        placeholder={localize("mobileNumberHelperText")}
                    />

                    <ListItem>
                        <TextField
                            select
                            label={<LocalizeText tag="role" />}
                            value={newContact.communityStaffRole}
                            onChange={(event) =>
                                onChange("communityStaffRole", event.target.value as CommunityStaffRoles)
                            }
                            fullWidth
                        >
                            <MenuItem value="Arenapersonal">Arenapersonal</MenuItem>
                            <MenuItem value="Sitemanager">Sitemanager</MenuItem>
                        </TextField>
                    </ListItem>
                    <ListItem>
                        <TextField
                            select
                            label={<LocalizeText tag="mainArena" />}
                            value={newContact.mainCommunity}
                            required
                            onChange={(event) => onChange("mainCommunity", event.target.value)}
                            fullWidth
                        >
                            {communitiesPagination.results.map((item) => (
                                <MenuItem key={item.uuid} value={item.uuid}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </TextField>
                    </ListItem>
                    <ListItem>
                        <TextField
                            select
                            label={<LocalizeText tag="showOtherArenas" />}
                            value={newContact.communities}
                            required
                            onChange={(event) => onChange("communities", event.target.value)}
                            fullWidth
                            SelectProps={{
                                multiple: true,
                            }}
                            InputLabelProps={{ shrink: newContact.communities.length > 0 }}
                        >
                            {communitiesPagination.results.map((item) => (
                                <MenuItem key={item.uuid} value={item.uuid}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </TextField>
                    </ListItem>
                    <ListItem>
                        <VKButton
                            isLoading={isAddingCommunityStaff}
                            disabled={!validateData()}
                            data-testid="addContactPerson-submitButton"
                            id="button"
                            tag="save"
                            type="submit"
                            template="save"
                        />
                    </ListItem>
                </form>
            </List>
        </div>
    );
};

export default AddCommunityStaff;
