import React from "react";
import { Box, Grid, MenuItem, TextField, Typography } from "@mui/material";
import VKButton from "components/vkButton";
import FileUploadField from "components/fileUpload";
import { LocalizeText } from "components/localizer";
import colors from "styles/colors";
import { MembershipDocumentTypes, membershipDocumentTypesArr } from "adapters/documents";

interface UploadNewFileProps {
    onUpload: (file: File, type: MembershipDocumentTypes) => void;
    onCancel: () => void;
}

export const DocumentUploadWizard: React.FC<UploadNewFileProps> = ({ onUpload, onCancel }) => {
    const [files, setFiles] = React.useState<File[]>([]);
    const [type, setType] = React.useState<MembershipDocumentTypes | "">();

    return (
        <Box bgcolor={colors.background} padding="20px" borderRadius="5px">
            <Typography variant="h6">
                <LocalizeText tag="newDocument" />
            </Typography>

            <Typography variant="body2" fontStyle="italic" marginBottom="20px">
                <LocalizeText tag="documentsUnremovable" />
            </Typography>

            <FileUploadField
                accept=".pdf, .docx"
                files={files}
                onFileUploadChange={(newFiles) => setFiles(newFiles)}
                singleFile={true}
                tag="uploadFile"
                vertical
            />

            <TextField
                label={<LocalizeText tag="type" />}
                select
                fullWidth
                value={type || ""}
                onChange={(e) => setType(e.target.value as MembershipDocumentTypes)}
                sx={{ marginTop: "20px" }}
            >
                {membershipDocumentTypesArr.map((type) => (
                    <MenuItem key={type} value={type}>
                        <LocalizeText tag={type} />
                    </MenuItem>
                ))}
            </TextField>

            <Grid container justifyContent="space-between" width="100%" marginTop="20px">
                <Grid item>
                    <VKButton tag="cancel" template="cancel" onClick={onCancel} />
                </Grid>
                <Grid item>
                    <VKButton
                        tag="continue"
                        template="primary"
                        disabled={files.length === 0 || !type}
                        onClick={() => onUpload(files[0], type as MembershipDocumentTypes)}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};
