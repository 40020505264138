import { RequiredPerm } from "./hooks/usePermission";

export const permission: Record<string, RequiredPerm> = Object.freeze({
    NONE: [],
    NEWS: ["view_newsarticle"],
    ARENA_STAFF_DASHBOARD: ["is_community_user"],
    ARENA_STAFF_STATISTICS: ["is_community_user"],
    ARENA_STAFF_EXTERNAL_MEMBERSHIPS: ["is_community_user", "view_external"],
    ARENA_STAFF_SERGEL_MEMBERSHIPS: ["is_community_user", "view_external"],
    ARENA_STAFF_PRODUCTS: ["is_community_user", "view_product"],
    ARENA_STAFF_INVOICES: ["is_community_user", "view_invoice"],
    ARENA_STAFF_MEMBERSHIPS: ["is_community_user", "manage_contract"],
    ARENA_STAFF_REPRESENTATIVES: ["is_community_user", "list_domain_user"],
    ARENA_STAFF_COSTS: ["is_community_user", "view_costcalculationrun"],
    ARENA_STAFF_MANAGEMENT: ["is_community_user", "add_communitystaffinvitation"],
    ARENA_STAFF_SUBSCRIPTION: ["is_community_user", "can_view_subscription_community_staff"],
    ARENA_STAFF_MEMBER: ["is_community_user", "can_view_member_community_staff"],
    ARENA_DASHBOARD: [["is_membership_user", "is_community_user"]],
    MEMBERSHIPS: ["manage_contract"],
    SUBSCRIPTIONS: ["view_subscription"],
    MEMBERS: ["view_member"],
    ARENA_OFFICE: ["view_officesubscription"],
    ARENA_BUILDINGINFO: [["is_membership_user", "is_community_user"], "view_building"],
    TENANT_DASHBOARD: ["is_lease_user"],
    TASKS: ["view_task"],
    SERVICES: ["view_service"],
    LEASES: ["manage_lease"],
    CUSTOMER_LEDGERS: ["manage_lease"],
    BUILDING_INFO: ["is_lease_user", "view_building"],
    REPRESENTATIVES: ["list_domain_user"],
    EVENTS: ["view_publicevent"],
});
