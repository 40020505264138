import React, { FC, ReactNode, Reducer, createContext, useCallback, useContext, useMemo, useReducer } from "react";
import webAPIRequest from "api";
import { TMembershipId } from "reducers/memberships";
import { NotificationContext } from "contexts/notification";
import reducer, {
    IAction,
    IExternalMembership,
    IExternalMemberships,
    initialState,
} from "reducers/externalMemberships";

export const ExternalMembershipsContext = createContext<IExternalMemberships>({
    ...initialState,
});

export const ExternalMembershipsProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const { ...notification } = useContext(NotificationContext);
    const [currentState, dispatch] = useReducer<Reducer<IExternalMemberships, IAction>>(reducer, initialState);

    const fetchMembership = useCallback(
        async (membershipId: TMembershipId): Promise<IExternalMembership> => {
            try {
                dispatch({ type: "FETCH_EXTERNAL_MEMBERSHIP" });
                const returnData = await webAPIRequest("get", `/memberships/externals/${membershipId}/`);
                dispatch({
                    type: "FETCH_EXTERNAL_MEMBERSHIP_SUCCESS",
                    membership: returnData.data,
                });
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_fetchMembership", error);
                dispatch({ type: "FETCH_EXTERNAL_MEMBERSHIP_FAILURE" });
                return {} as IExternalMembership;
            }
        },
        [notification]
    );

    const updateMembership = useCallback(
        async (membershipId: TMembershipId, data: Record<string, unknown>): Promise<IExternalMembership> => {
            try {
                dispatch({ type: "UPDATE_EXTERNAL_MEMBERSHIP" });
                const returnData = await webAPIRequest("patch", `/memberships/externals/${membershipId}/`, { data });
                dispatch({ type: "UPDATE_EXTERNAL_MEMBERSHIP_SUCCESS", data: returnData.data });
                notification.enqueNotification("success_updateMembership");
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_updateMembership", error);
                dispatch({ type: "UPDATE_EXTERNAL_MEMBERSHIP_FAILURE" });
                return {} as IExternalMembership;
            }
        },
        [notification]
    );

    const createMembership = useCallback(
        async (data: Record<string, unknown>): Promise<IExternalMembership> => {
            try {
                dispatch({ type: "CREATE_EXTERNAL_MEMBERSHIP" });
                const returnData = await webAPIRequest("post", "/memberships/externals/", { data });
                dispatch({ type: "CREATE_EXTERNAL_MEMBERSHIP_SUCCESS", membership: returnData.data });
                notification.enqueNotification("success_createExternalMembership");
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_createExternalMembership", error);
                dispatch({ type: "CREATE_EXTERNAL_MEMBERSHIP_FAILURE" });
                return {} as IExternalMembership;
            }
        },
        [notification]
    );

    const value = useMemo(() => {
        return {
            ...currentState,
            fetchMembership,
            updateMembership,
            createMembership,
        };
    }, [currentState, fetchMembership, updateMembership, createMembership]);

    return <ExternalMembershipsContext.Provider value={value}>{children}</ExternalMembershipsContext.Provider>;
};
