import webAPIRequest from "api";
import React, { useMemo } from "react";
import reducer, { IAction, IFeatureFlags, initialState } from "reducers/featureFlags";

export const FeatureFlagContext = React.createContext<IFeatureFlags>({
    ...initialState,
});

export const FeatureFlagProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const [currentState, dispatch] = React.useReducer<React.Reducer<IFeatureFlags, IAction>>(reducer, initialState);

    React.useEffect(() => {
        (async () => {
            try {
                dispatch({ type: "FETCH_FLAGS" });
                const returnData = await webAPIRequest("get", "/feature-flags/");
                dispatch({
                    type: "FETCH_FLAGS_SUCCESS",
                    features: returnData.data,
                });
            } catch (error) {
                dispatch({ type: "FETCH_FLAGS_FAILURE" });
            }
        })();
    }, []);

    const value = useMemo(() => {
        return {
            ...currentState,
        };
    }, [currentState]);

    return <FeatureFlagContext.Provider value={value}>{children}</FeatureFlagContext.Provider>;
};
