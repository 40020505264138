import React, { FC, useContext, useEffect, useState } from "react";
import { DialogActions, DialogContent, DialogTitle, Divider, MenuItem, TextField } from "@mui/material";
import VKButton from "components/vkButton";
import { LocalizeText } from "components/localizer";
import { ILease } from "reducers/leases";
import PaginationStore, { TPaginationActionData, emptyPaginationActionData } from "utils/paginationStore";
import { fetchLeases } from "adapters/leasesAdapter";
import { ProfileContext } from "contexts/profile";
import { useIsLoading } from "utils/hooks/useLoading";
import { CenteredLoader } from "components/centeredLoader";
import { NotificationContext } from "contexts/notification";

interface IDialogProps {
    onSubmit: (lease_uuid: string) => void;
}

const SelectLeaseDialog: FC<IDialogProps> = ({ onSubmit }) => {
    const { enqueNotification } = useContext(NotificationContext);
    const { selectedOrganization } = useContext(ProfileContext);

    const [isLoading, setIsLoading] = useIsLoading(true);
    const [leasesPagination] = useState(new PaginationStore<ILease>());
    const [selectedLease, setSelectedLease] = useState("");

    useEffect(() => {
        leasesPagination.initialize(fetchAllLeases);
        leasesPagination.setParam("org_nr", selectedOrganization.org_nr);
        leasesPagination.setParam("has_manage", true);
        leasesPagination.getInitial();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchAllLeases = async (params: Record<string, unknown>): Promise<TPaginationActionData<ILease>> => {
        try {
            setIsLoading(true);
            const res = await fetchLeases(params);
            if (res.data.count) {
                setSelectedLease(res.data.results[0].lease_uuid);
            }
            setIsLoading(false);
            return res.data;
        } catch (error) {
            setIsLoading(false);
            enqueNotification("error_fetchLeases", error);
            return emptyPaginationActionData;
        }
    };

    const mapLeases = (): JSX.Element[] => {
        return leasesPagination.results.map((lease) => {
            return (
                <MenuItem key={lease.lease_uuid} value={lease.lease_uuid}>
                    {`${lease.address} (${lease.agreement_number})`}
                </MenuItem>
            );
        });
    };

    return (
        <>
            <DialogTitle>
                <LocalizeText tag={"contactVasakronan"} />
            </DialogTitle>
            <Divider />
            <DialogContent style={{ minHeight: "5em", minWidth: "400px" }}>
                {isLoading ? (
                    <CenteredLoader size={24} />
                ) : (
                    <TextField
                        select
                        fullWidth
                        onChange={(event) => setSelectedLease(event.target.value)}
                        variant="outlined"
                        value={selectedLease}
                        label={<LocalizeText tag="selectLease" />}
                    >
                        {mapLeases()}
                    </TextField>
                )}
            </DialogContent>
            <DialogActions>
                <VKButton
                    tag="proceed"
                    template="primary"
                    disabled={!selectedLease}
                    onClick={() => onSubmit(selectedLease)}
                />
            </DialogActions>
        </>
    );
};

export default SelectLeaseDialog;
