import React, { FC, useContext, useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import VKButton from "components/vkButton";
import validator from "utils/validator";
import { TFormEvent } from "utils/types";
import { ProfileContext } from "contexts/profile";
import If from "components/__helpers__/if";
import { ListTextField, SelectBox, TItem } from "components/drawers/drawerComponents";
import { IContactPerson, IMembership } from "reducers/memberships";
import Divider from "@mui/material/Divider";
import { sanitizePhoneNumber } from "utils/helpers";
import { LanguageContext } from "contexts/language";
import { LocalizeText } from "components/localizer";
import { IRole, DomainContentType } from "reducers/roleManagement";
import { RoleManagementContext } from "contexts/roleManagement";
import { TPaginationActionData } from "utils/paginationStore";
import { ModalContext } from "contexts/modals";

interface IEditContactObject {
    firstName: string;
    lastName: string;
    phone: string;
    roleId: number;
}

interface IProps {
    person: IContactPerson;
    onSubmit: (
        membershipId: number,
        uuid: string,
        firstName: string,
        lastName: string,
        phone: string,
        role: number
    ) => Promise<boolean>;
    isLoading: boolean;
    membership: IMembership;
}
const EditMembershipContactPerson: FC<IProps> = ({ person, onSubmit, isLoading, membership }) => {
    const { rolesPagination, fetchRolesOfDomain, isFetchingRoles } = useContext(RoleManagementContext);
    const { fetchUserProfile, user } = useContext(ProfileContext);
    const { closeDrawers } = useContext(ModalContext);
    const { localize } = useContext(LanguageContext);

    const loading = isLoading || isFetchingRoles;
    const initialState: IEditContactObject = {
        firstName: person.first_name,
        lastName: person.last_name,
        phone: person.phone ? person.phone : "",
        roleId: person.role ? person.role.id : -1,
    };
    const [state, setState] = useState(initialState);
    const rolesPaginationCallback = async (params: Record<string, unknown>): Promise<TPaginationActionData<IRole>> => {
        return fetchRolesOfDomain(DomainContentType.Membership, params);
    };
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        rolesPagination.initialize(rolesPaginationCallback);
        rolesPagination.appendResults = true;
        rolesPagination.setParam("domain_object_id", membership.id);
        rolesPagination.getInitial();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChange = (type: keyof IEditContactObject, value: string): void => {
        setState({ ...state, [type]: value });
    };

    const handleSubmit = async (event: TFormEvent): Promise<void> => {
        event.preventDefault();
        const success = await onSubmit(
            membership.id,
            person.uuid,
            state.firstName,
            state.lastName,
            sanitizePhoneNumber(state.phone),
            state.roleId
        );
        if (success) {
            fetchUserProfile();
            setIsEditing(false);
            closeDrawers();
        }
    };

    const onStartEdit = (): void => {
        setIsEditing(true);
    };

    const onCancelEdit = (): void => {
        setIsEditing(false);
        setState(initialState);
    };

    const validateData = (): boolean => {
        return !!validator.phoneNumber(state.phone) && state.roleId > 0;
    };

    const mapRoles = (): TItem[] => {
        return rolesPagination.results.reduce((list: TItem[], role) => {
            list.push({
                name: role.display_name,
                value: role.id,
            });
            return list;
        }, []);
    };

    return (
        <div data-testid="editContactPerson-root">
            <List>
                <form onSubmit={handleSubmit}>
                    <ListTextField
                        testid="editContactPerson-membership"
                        hasData
                        label={<LocalizeText tag="membership" />}
                        data={`${membership.agreement_nr} - ${
                            membership.organization.name || membership.organization.company_name
                        }`}
                    />
                    <Divider />

                    <If truthy={isEditing}>
                        <ListTextField label={<LocalizeText tag="name" />} />
                        <ListTextField
                            testid="editContactPerson-firstName"
                            label={<LocalizeText tag="firstName" />}
                            data={state.firstName}
                            onChange={(e) => onChange("firstName", e.target.value)}
                            isLoading={loading}
                            isEditing
                            hasData
                        />
                        <ListTextField
                            testid="editContactPerson-lastName"
                            label={<LocalizeText tag="lastName" />}
                            data={state.lastName}
                            onChange={(e) => onChange("lastName", e.target.value)}
                            isLoading={loading}
                            isEditing
                            hasData
                        />
                    </If>
                    <If falsy={isEditing}>
                        <ListTextField
                            testid="editContactPerson-name"
                            label={<LocalizeText tag="name" />}
                            data={`${state.firstName} ${state.lastName}`}
                            isLoading={loading}
                            hasData
                        />
                    </If>

                    <Divider />
                    <ListTextField
                        testid="editContactPerson-email"
                        hasData
                        label={<LocalizeText tag="email" />}
                        data={person.email}
                        type="email"
                    />

                    <Divider />
                    <If truthy={isEditing}>
                        <ListTextField label={<LocalizeText tag="phoneNumber" />} />
                    </If>
                    <ListTextField
                        notValid={!validator.phoneNumber(state.phone, true)}
                        testid="editContactPerson-phone"
                        label={<LocalizeText tag="phoneNumber" />}
                        data={state.phone}
                        onChange={(e) => onChange("phone", e.target.value)}
                        errorText={<LocalizeText tag="errorMobile" />}
                        placeholder={localize("mobileNumberHelperText")}
                        isLoading={loading}
                        isEditing={isEditing}
                        hasData
                        type="phone"
                    />
                    <Divider />

                    <If truthy={isEditing}>
                        <ListTextField label={<LocalizeText tag="role" />} />
                        {rolesPagination && rolesPagination.totalCount === 1 ? (
                            <ListTextField
                                label={<LocalizeText tag="role" />}
                                hasData
                                isEditing={isEditing}
                                disabled
                                data={rolesPagination.results[0].display_name}
                                isLoading={loading}
                            />
                        ) : (
                            <SelectBox
                                label={<LocalizeText tag="role" />}
                                data={state.roleId > 0 ? String(state.roleId) : ""}
                                onEdit={(value: string) => setState({ ...state, roleId: Number(value) })}
                                items={mapRoles()}
                            />
                        )}
                    </If>
                    <If falsy={isEditing}>
                        <ListTextField
                            testid="editContactPerson-role"
                            label={<LocalizeText tag="role" />}
                            data={person.role ? person.role.display_name : "-"}
                            isLoading={loading}
                            hasData
                        />
                    </If>
                    <Divider />

                    <ListItem
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "8px",
                        }}
                    >
                        <If truthy={!isEditing && (user?.is_staff || user?.email === person.email)}>
                            <VKButton
                                data-testid="editContactPerson-editButton"
                                tag="editInformation"
                                onClick={onStartEdit}
                                template="primary"
                            />
                        </If>
                        <If truthy={isEditing}>
                            <VKButton
                                disabled={!validateData()}
                                data-testid="editContactPerson-submitButton"
                                template="save"
                                type="submit"
                                tag="save"
                                isLoading={loading}
                            />
                            <VKButton template="cancel" type="button" tag="cancel" onClick={onCancelEdit} />
                        </If>
                    </ListItem>
                </form>
            </List>
        </div>
    );
};

export default EditMembershipContactPerson;
