import React from "react";
import VKButton from "components/vkButton";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import WarningIcon from "@mui/icons-material/Warning";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton } from "@mui/material";
import colors from "styles/colors";
import { TLanguageTag } from "contexts/language";

interface IConfirmDialog {
    title: string | React.ReactNode;
    description: string | React.ReactNode;
    onReject: () => void;
    onClose?: () => void;
    onAccept: () => void;
    open: boolean;
    isLoading?: boolean;
    icon?: React.ReactNode;
    hideIcon?: boolean;
    acceptTag?: TLanguageTag;
    rejectTag?: TLanguageTag;
    hideRejectButton?: boolean;
}

const ConfirmDialog: React.FC<IConfirmDialog> = ({
    title,
    description,
    onReject,
    onClose,
    onAccept,
    open,
    isLoading,
    icon = (
        <Box sx={{ color: colors.warning, marginRight: "10px" }}>
            <WarningIcon />
        </Box>
    ),
    hideIcon,
    acceptTag,
    rejectTag,
    hideRejectButton,
}) => {
    const closeDialog = (): void => {
        return onClose ? onClose() : onReject();
    };

    return (
        <Dialog
            data-testid="confirmDialog-root"
            onClose={closeDialog}
            open={open}
            sx={{ minWidth: "600px" }}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle
                sx={{
                    display: "flex",
                    "& .MuiTypography-root": {
                        alignItems: "center",
                    },
                }}
            >
                {!hideIcon && icon}
                {title}
                <IconButton
                    aria-label="close"
                    sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                    }}
                    onClick={closeDialog}
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>{description}</DialogContent>
            <DialogActions>
                <VKButton
                    data-testid="confirmDialog-accept"
                    tag={acceptTag ?? "yes"}
                    template="save"
                    onClick={() => onAccept()}
                    isLoading={isLoading}
                />
                {!hideRejectButton && (
                    <VKButton
                        data-testid="confirmDialog-reject"
                        tag={rejectTag ?? "no"}
                        type="submit"
                        template="cancel"
                        onClick={() => onReject()}
                    />
                )}
            </DialogActions>
        </Dialog>
    );
};

interface IInfoDialog {
    title: string | React.ReactNode;
    description: string | React.ReactNode;
    onClose: () => void;
    open: boolean;
}

const InfoDialogComponent: React.FC<IInfoDialog> = ({ title, description, onClose, open }) => {
    return (
        <Dialog
            data-testid="infoDialog-root"
            onClose={() => onClose()}
            open={open}
            sx={{ minWidth: "600px" }}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle
                sx={{
                    display: "flex",
                    "& .MuiTypography-root": {
                        alignItems: "center",
                    },
                }}
            >
                {title}
            </DialogTitle>
            <Divider />
            <DialogContent>{description}</DialogContent>
            <DialogActions>
                <VKButton data-testid="infoDialog-ok" tag="close" template="primary" onClick={() => onClose()} />
            </DialogActions>
        </Dialog>
    );
};

const InfoDialog = InfoDialogComponent;
export { InfoDialog };
export default ConfirmDialog;
