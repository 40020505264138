import React from "react";
import SnackbarContent from "@mui/material/Snackbar";
import DoneIcon from "@mui/icons-material/Done";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import Grid from "@mui/material/Grid";
import { LocalizeNotification, fontType } from "components/localizer";
import { NotificationContext } from "contexts/notification";
import { delay } from "utils/helpers";
import { Box, styled } from "@mui/material";
import colors from "styles/colors";

type NotificationSnackbarProps = {
    type: string;
};

const NotificationSnackbar = styled(SnackbarContent)<NotificationSnackbarProps>(({ type }) => {
    let bgColor = colors.errorRed;

    switch (type) {
        case "success":
            bgColor = colors.successGreen;
            break;
        case "warning":
            bgColor = colors.warning;
            break;
    }
    return {
        boxShadow:
            "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
        ".MuiSnackbarContent-root": {
            color: colors.text,
            backgroundColor: bgColor,
        },
        ".MuiSnackbarContent-message": {
            padding: 0,
        },
    };
});

const Notification: React.FC = () => {
    const { notifications, dequeNotification } = React.useContext(NotificationContext);
    const dequeList: number[] = [];

    const movePosition = async (uuid: number): Promise<void> => {
        dequeList.push(uuid);
        await delay(5000); // 5 seconds
        dequeNotification(uuid);
    };

    const getIcon = (type: string): React.ReactNode => {
        switch (type) {
            case "success":
                return <DoneIcon sx={{ fontSize: "3em" }} />;
            case "warning":
                return <WarningIcon sx={{ fontSize: "3em" }} />;
            default:
                return <ErrorIcon sx={{ fontSize: "3em" }} />;
        }
    };

    const components = notifications.map((notification, index) => {
        if (!dequeList.includes(notification.uuid)) {
            movePosition(notification.uuid);
        }
        const type = notification.tag.split("_")[0];
        const icon = getIcon(type);
        const position = index * 90;
        const showVerbose = type === "success";

        return (
            <NotificationSnackbar
                type={type}
                data-testid={`notifications-${type}`}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                key={index}
                style={{ top: position }}
                open={notifications.length > 0}
                message={
                    <Grid container width={"40vw"} alignItems="center" justifyContent="flex-end">
                        <Grid item sm={2} lg={2} sx={{ alignSelf: "flex-start" }}>
                            {icon}
                        </Grid>
                        <Grid container item sm={10} lg={10}>
                            <Grid item sm={10}>
                                <LocalizeNotification tag={notification.tag} type={fontType.BOLD} />
                            </Grid>
                            <Grid item sm={10}>
                                <Box sx={{ fontSize: "0.9em", fontStyle: "italic" }}>
                                    {showVerbose ? (
                                        <p> {notification.messages} </p>
                                    ) : (
                                        <LocalizeNotification tag="general_error" type={fontType.BOLD} />
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            />
        );
    });

    return <>{components}</>;
};

export default Notification;
