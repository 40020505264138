import React, { FC, useContext, useState } from "react";
import { format, isFuture, isToday, isValid, parseISO } from "date-fns";
import VKButton from "components/vkButton";
import { dateFormats } from "utils/formats";
import { LocalizeText } from "components/localizer";
import { TFormEvent } from "utils/types";
import { ListItemText, List, ListItem, Box } from "@mui/material";
import { ISubscription } from "reducers/subscriptions";
import SubscriptionsContext from "contexts/subscriptions";
import { LanguageContext } from "contexts/language";
import { DatePicker } from "components/datePicker";

interface IProps {
    onSubmitUpdate: (params: Partial<ISubscription>) => void;
    subscription: ISubscription;
}

export const EditSubscriptionBindingPeriod: FC<IProps> = ({ onSubmitUpdate, subscription }) => {
    const { isUpdatingSubscription } = useContext(SubscriptionsContext);
    const { localize } = useContext(LanguageContext);
    const currentBindingPeriod = subscription.binding_period_until ? parseISO(subscription.binding_period_until) : null;
    const [date, setDate] = useState(
        currentBindingPeriod && currentBindingPeriod > new Date() ? currentBindingPeriod : new Date()
    );

    const handleSubmit = (event: TFormEvent): void => {
        event.preventDefault();
        onSubmitUpdate({ binding_period_until: format(date, dateFormats.WEBDATE) });
    };

    let currentInfo: string;
    if (!currentBindingPeriod) {
        currentInfo = localize("notSpecified");
    } else if (currentBindingPeriod < new Date()) {
        currentInfo = `${localize("passed")} (${format(currentBindingPeriod, dateFormats.WEBDATE)})`;
    } else {
        currentInfo = format(currentBindingPeriod, dateFormats.WEBDATE);
    }

    const dateIsValid = !isValid(date) || isToday(date) || isFuture(date);

    return (
        <Box>
            <form onSubmit={handleSubmit}>
                <List>
                    <ListItem>
                        <ListItemText
                            primary={
                                <>
                                    <LocalizeText tag="currentBindingPeriod" />: {currentInfo}
                                </>
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <DatePicker
                            minDate={new Date()}
                            format={dateFormats.WEBDATE}
                            label={<LocalizeText tag="newBindingPeriod" />}
                            value={date}
                            slotProps={{ textField: { fullWidth: true, error: !dateIsValid} }}
                            onChange={(newDate: Date) => setDate(newDate as Date)}
                        />
                    </ListItem>
                    <ListItem>
                        <VKButton
                            type="submit"
                            tag="confirm"
                            template="primary"
                            isLoading={isUpdatingSubscription}
                            disabled={!dateIsValid}
                        />
                    </ListItem>
                </List>
            </form>
        </Box>
    );
};
