import React, {
    FC,
    ReactNode,
    Reducer,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useReducer,
} from "react";
import webAPIRequest from "api";
import reducer, {
    IProfile,
    IAction,
    initialState,
    IPremiseContract,
    IMembershipKey,
    TUpdateProfileFields,
    TChangePasswordFields,
    IOrganizationCollection,
} from "reducers/profile";
import { NotificationContext } from "contexts/notification";
import { AuthContext } from "contexts/auth";
import { IOrganization } from "reducers/memberships";
import { emptyPaginationActionData, TPaginationActionData } from "utils/paginationStore";
import { ILeaseRequest } from "reducers/leases";
import useQueryParams from "utils/hooks/useQueryParams";

export const ProfileContext = createContext<IProfile>({
    ...initialState,
});

export type FetchUserProfileResponse = {
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
    token: string;
    expires: string;
    token_expires: string;
    base_url: string;
    is_staff: boolean;
    memberships: IMembershipKey[];
    has_community_landing_page: boolean;
};

export const ProfileProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const queryParams = useQueryParams();
    const { ...notification } = useContext(NotificationContext);
    const { logout } = useContext(AuthContext);
    const [currentState, dispatch] = useReducer<Reducer<IProfile, IAction>>(reducer, initialState);

    const fetchOrganizationPermissions = useCallback(async (org_nr: string): Promise<string[]> => {
        try {
            dispatch({ type: "FETCH_PERMISSIONS" });
            const url = `/organization/${org_nr}/permissions/`;
            const returnData = await webAPIRequest("get", url);
            dispatch({
                type: "FETCH_PERMISSIONS_SUCCESS",
                permissions: returnData.data,
            });
            return returnData.data;
        } catch (error) {
            dispatch({ type: "FETCH_PERMISSIONS_FAILURE" });
            return [];
        }
    }, []);

    const fetchProfileNotifications = useCallback(async (org_nr: string): Promise<void> => {
        try {
            dispatch({ type: "FETCH_NOTIFICATIONS" });

            const params = {
                org_nr: org_nr,
            };
            const returnData = await webAPIRequest("get", "/notifications/", { params });

            dispatch({
                type: "FETCH_NOTIFICATIONS_SUCCESS",
                notifications: returnData.data["notifications"],
                counted: returnData.data["counted"],
            });
        } catch (error) {
            dispatch({ type: "FETCH_NOTIFICATIONS_FAILURE" });
        }
    }, []);

    const fetchUserProfile = useCallback(async (): Promise<FetchUserProfileResponse> => {
        try {
            dispatch({ type: "FETCH_PROFILE" });
            const returnData = await webAPIRequest("get", "/users/");
            dispatch({
                type: "FETCH_PROFILE_SUCCESS",
                user: returnData.data,
            });
            return returnData.data;
        } catch (error) {
            // If user cannot fetch profile, user should be logged out
            logout();
            notification.enqueNotification("error_fetchProfile", error);
            dispatch({ type: "FETCH_PROFILE_FAILURE" });
            return {} as FetchUserProfileResponse;
        }
    }, [notification, logout]);

    const updateUserProfile = useCallback(
        async (data: TUpdateProfileFields): Promise<FetchUserProfileResponse> => {
            try {
                dispatch({ type: "UPDATE_PROFILE" });
                const returnData = await webAPIRequest("patch", "/users/", { data });
                dispatch({
                    type: "UPDATE_PROFILE_SUCCESS",
                    user: returnData.data,
                });
                notification.enqueNotification("success_updateProfile");
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_updateProfile", error);
                dispatch({ type: "UPDATE_PROFILE_FAILURE" });
                return {} as FetchUserProfileResponse;
            }
        },
        [notification]
    );

    const changeUserPassword = useCallback(
        async (data: TChangePasswordFields): Promise<boolean> => {
            try {
                dispatch({ type: "CHANGE_PASSWORD" });
                await webAPIRequest("patch", "/users/change-password/", { data });
                dispatch({ type: "CHANGE_PASSWORD_SUCCESS" });
                notification.enqueNotification("success_resetPassword");
                return true;
            } catch (error) {
                notification.enqueNotification("error_resetPassword", error);
                dispatch({ type: "CHANGE_PASSWORD_FAILURE" });
                return false;
            }
        },
        [notification]
    );

    const fetchPremiseContracts = useCallback(
        async (params?: Record<string, unknown>): Promise<TPaginationActionData<IPremiseContract>> => {
            try {
                dispatch({ type: "FETCH_PREMISE_CONTRACTS" });
                const returnData = await webAPIRequest("get", "/premise-contracts/", { params });
                dispatch({ type: "FETCH_PREMISE_CONTRACTS_SUCCESS", data: returnData.data });
                return returnData.data;
            } catch (error) {
                dispatch({ type: "FETCH_PREMISE_CONTRACTS_FAILURE" });
                return emptyPaginationActionData;
            }
        },
        []
    );

    const setSelectedOrganization = useCallback((org: IOrganizationCollection): void => {
        window.localStorage.setItem("currentOrg", JSON.stringify(org));
        dispatch({ type: "SET_ORGANIZATION", org: org });
    }, []);

    const clearSelectedOrganization = useCallback((): void => {
        window.localStorage.removeItem("currentOrg");
        dispatch({ type: "CLEAR_ORGANIZATION" });
    }, []);

    const getSelectedOrDefaultOrganization = useCallback(
        (allOrganizations: IOrganization[]): IOrganization => {
            const queryOrg = queryParams.get("org");
            if (queryOrg) {
                const orgFromQuery = allOrganizations.find((org) => org.org_nr === queryOrg);
                if (orgFromQuery) {
                    return orgFromQuery;
                }
            }

            const storedOrganization = window.localStorage.getItem("currentOrg");
            if (storedOrganization) {
                const parsed = JSON.parse(storedOrganization) as IOrganization;
                if (!parsed.hasOwnProperty("reference") && allOrganizations.some((org) => org.id === parsed.id)) {
                    return parsed;
                } else {
                    window.localStorage.removeItem("currentOrg");
                }
            }
            if (allOrganizations.length > 0) {
                return allOrganizations[0];
            }

            return {} as IOrganization;
        },
        [queryParams]
    );

    const fetchOrganizations = useCallback(async (): Promise<IOrganizationCollection[]> => {
        try {
            dispatch({ type: "FETCH_ORGANIZATIONS" });
            const returnData = await webAPIRequest("get", "/organization/");
            const selected = getSelectedOrDefaultOrganization(returnData.data);
            setSelectedOrganization(selected);
            dispatch({
                type: "FETCH_ORGANIZATIONS_SUCCESS",
                organizations: returnData.data,
            });
            return returnData.data;
        } catch (error) {
            notification.enqueNotification("error_fetch_organizations");
            dispatch({ type: "FETCH_ORGANIZATIONS_FAILURE" });
            return [];
        }
    }, [getSelectedOrDefaultOrganization, notification, setSelectedOrganization]);

    const fetchPendingLeaseRequests = useCallback(
        async (params?: Record<string, unknown>): Promise<TPaginationActionData<ILeaseRequest>> => {
            try {
                dispatch({ type: "FETCH_PENDING_LEASE_REQUESTS" });
                const returnData = await webAPIRequest("get", "/vk_data/lease-invitations/pending/", { params });
                dispatch({ type: "FETCH_PENDING_LEASE_REQUESTS_SUCCESS", data: returnData.data });
                return returnData.data;
            } catch (error) {
                dispatch({ type: "FETCH_PENDING_LEASE_REQUESTS_FAILURE" });
                return emptyPaginationActionData;
            }
        },
        []
    );

    const clearProfileStorage = useCallback((): void => {
        dispatch({ type: "CLEAR_STORAGE" });
    }, []);

    useEffect(() => {
        (async () => {
            if (currentState.selectedOrganization.org_nr) {
                await fetchOrganizationPermissions(currentState.selectedOrganization.org_nr);
                await fetchProfileNotifications(currentState.selectedOrganization.org_nr);
            }
        })();
    }, [currentState.selectedOrganization, fetchOrganizationPermissions, fetchProfileNotifications]);

    const value = useMemo(() => {
        return {
            ...currentState,
            fetchUserProfile,
            updateUserProfile,
            clearProfileStorage,
            changeUserPassword,
            fetchPremiseContracts,
            setSelectedOrganization,
            clearSelectedOrganization,
            fetchOrganizations,
            fetchPendingLeaseRequests,
            fetchProfileNotifications,
        };
    }, [
        currentState,
        fetchUserProfile,
        updateUserProfile,
        clearProfileStorage,
        changeUserPassword,
        fetchPremiseContracts,
        setSelectedOrganization,
        clearSelectedOrganization,
        fetchOrganizations,
        fetchPendingLeaseRequests,
        fetchProfileNotifications,
    ]);

    return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};
