import React from "react";
import { LocalizeText } from "components/localizer";
import Grid from "@mui/material/Grid";
import { Hidden, styled } from "@mui/material";
import colors from "styles/colors";
import { TLanguageTag } from "contexts/language";

const BannerRoot = styled(Grid)(() => ({
    flexWrap: "nowrap",
    width: "100%",
    flexDirection: "row",
    display: "flex",
    backgroundColor: colors.vkBlue,
    overflowX: "hidden",
    position: "relative",
}));

const BannerImage = styled("img")(({ theme }) => ({
    display: "flex",
    flexGrow: 2,
    objectFit: "cover",
    [theme.breakpoints.down("md")]: {
        display: "none",
    },
}));

const BannerText = styled("div")(({ theme }) => ({
    width: 450,
    padding: "30px 40px",
    height: "100%",
    color: colors.white,

    "& h2": {
        margin: 0,
        marginBottom: 15,
        fontSize: "26px",
        fontWeight: "normal",
    },

    "& h1": {
        margin: 0,
        fontSize: "50px",
        fontWeight: "normal",
        fontFamily: "Vasakronan",
        lineHeight: 1,
        marginBottom: 30,
        [theme.breakpoints.up("lg")]: {
            overflowWrap: "anywhere",
            wordBreak: "normal",
        },
        [theme.breakpoints.down("md")]: {
            "& span": {
                whiteSpace: "nowrap",
            },
        },
    },

    "& h3": {
        margin: 0,
        fontSize: "18px",
        fontWeight: "lighter",
        lineHeight: 1.1,
    },
}));

interface IProps {
    title?: TLanguageTag;
    subTitle?: TLanguageTag;
    image?: string;
    customContent?: React.ReactElement;
    imageStyle?: React.CSSProperties;
}
const Banner: React.FC<IProps> = ({ title, subTitle, image, customContent, imageStyle }) => {
    return (
        <BannerRoot item>
            <BannerText sx={{ width: !image ? "100%" : 450 }}>
                {title && (
                    <h1>
                        <LocalizeText tag={title} />
                    </h1>
                )}
                {subTitle && (
                    <h3>
                        <LocalizeText tag={subTitle} />
                    </h3>
                )}
                {customContent && <Hidden lgDown>{customContent}</Hidden>}
            </BannerText>
            {image && <BannerImage src={image} style={imageStyle} height="300px" width="354px" alt="Page banner" />}
        </BannerRoot>
    );
};

export default Banner;
