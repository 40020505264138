import React from "react";
import {
    IState,
    LanguageConsumer,
    TLanguageTag,
    TNotificationTag,
    getTranslation,
    replaceHashtags,
} from "contexts/language";
import If from "./__helpers__/if";
import { styled, SxProps, Theme } from "@mui/material";

export enum fontType {
    NORMAL = "normal",
    BOLD = "bold",
    ITALIC = "italic",
}

type StyledTextProps = {
    type: fontType;
};

const StyledText = styled("span")<StyledTextProps>(({ type }) => {
    if (type === "bold") {
        return { fontWeight: "bold" };
    }
    if (type === "italic") {
        return { fontStyle: "italic" };
    }
    return { fontWeight: "inherit" };
});

interface IProps {
    tag: TLanguageTag;
    styling?: SxProps<Theme>;
    type?: fontType;
    replace?: Record<string, string>; // An object for replacing instances of # in the language string.
    spaceBefore?: boolean;
}

const Text: React.FC<IProps> = ({ tag, styling, type = fontType.NORMAL, replace, spaceBefore = false }) => {
    return (
        <LanguageConsumer>
            {(state) => {
                return (
                    <StyledText
                        type={type}
                        sx={{
                            whiteSpace: "pre-line",
                            ...styling,
                        }}
                    >
                        <If truthy={spaceBefore}>
                            <> </>
                        </If>
                        {replaceHashtags(getTranslation(state.definition, tag), replace)}
                    </StyledText>
                );
            }}
        </LanguageConsumer>
    );
};

interface INotificationProps extends Omit<IProps, "tag"> {
    tag: TNotificationTag;
}
const Notification: React.FC<INotificationProps> = ({ tag, styling, type = fontType.NORMAL }) => {
    // Gets the correct translation based on the given tag
    const getText = (state: IState): string => {
        const translation = state.notificationDefinition[tag];
        if (!state?.notificationDefinition[tag]) {
            return `[${tag}]`;
        }

        return translation;
    };

    return (
        <LanguageConsumer>
            {(state) => {
                return (
                    <StyledText
                        type={type}
                        sx={{
                            whiteSpace: "pre-line",
                            ...(Array.isArray(styling) ? styling : [styling]),
                        }}
                    >
                        {getText(state)}
                    </StyledText>
                );
            }}
        </LanguageConsumer>
    );
};

const LocalizeText = Text;
const LocalizeNotification = Notification;

export { LocalizeText, LocalizeNotification };
