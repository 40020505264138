import React, { FC, useEffect, useMemo, useState } from "react";
import TableComponent, { IRow } from "components/tableComponent";
import { useNavigate } from "react-router-dom";
import { LocalizeText } from "components/localizer";
import { InvoiceOrderStatus } from "components/invoices/invoiceOrderStatus";
import { THeadCell } from "utils/types";
import { TLanguageTag } from "contexts/language";
import { InvoicesTable } from "./invoicesTable";
import { format, parseISO } from "date-fns";
import { dateFormats } from "utils/formats";
import { IUsePaginationStore } from "utils/usePaginationStore";
import { IInvoice } from "reducers/invoices";

const invoiceOrdersHeadCells: THeadCell[] = [
    {
        id: "created",
        label: <LocalizeText tag="created" />,
    },
    { id: "payment_status", label: <LocalizeText tag="status" /> },
];

interface IProps {
    pagination: IUsePaginationStore<"invoice-orders">;
}
export const MembershipInvoiceOrdersTable: FC<IProps> = ({ pagination }) => {
    const navigate = useNavigate();
    const [allInvoices, setAllInvoices] = useState<IInvoice[]>([]);

    useEffect(() => {
        if (pagination.results && !(allInvoices.length > 0)) {
            pagination.results.map(({ invoices, internal_status }) => {
                const temp = allInvoices;

                if (invoices.length > 0 && internal_status) {
                    invoices.map((invoice) => {
                        temp.push(invoice);
                    });
                }

                setAllInvoices(temp);
            });
        }
    }, [pagination]);

    const rows = useMemo<IRow[]>(
        () =>
            pagination.results.map((invoiceOrder) => {
                const { uuid, created, currency_code, invoices } = invoiceOrder;

                const onClick = invoices.length === 0 ? () => navigate(`/staff-invoices/${uuid}`) : undefined;

                return {
                    onClick,
                    data: [
                        format(parseISO(created ?? ""), dateFormats.WEBDATE),
                        <InvoiceOrderStatus key={`status_${uuid}`} order={invoiceOrder} shortVersion={true} />,
                    ],
                    collapsibleContent:
                        invoices.length > 0 ? (
                            <InvoicesTable
                                key={`invoices_${uuid}`}
                                orderUuid={uuid}
                                invoices={invoices.slice().sort((a, b) => a.type.localeCompare(b.type))}
                                currency={currency_code as TLanguageTag}
                            />
                        ) : undefined,
                };
            }),
        [pagination.results, navigate]
    );

    return (
        <TableComponent
            headCells={invoiceOrdersHeadCells}
            isLoading={pagination.query.isFetching}
            rows={rows}
            skeletonVariant="options"
            pagination={pagination}
            collapsible
        />
    );
};
