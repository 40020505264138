import React, { FC, useContext, useState } from "react";
import { LocalizeText } from "components/localizer";
import ConfirmDialog from "components/confirmDialog";
import SubscriptionsContext from "contexts/subscriptions";
import { CancelSubscriptionDatePicker } from "./cancelSubscriptionDatePicker";
import { ISubscription } from "reducers/subscriptions";

interface IProps {
    onSubmitUpdate: (params: Partial<ISubscription>) => void;
    subscription: ISubscription;
}

export const CancelSubscription: FC<IProps> = ({ subscription, onSubmitUpdate }) => {
    const { isCancelingSubscription, cancelSubscription } = useContext(SubscriptionsContext);

    const [removeDate, setRemoveDate] = useState<Date | null>(null);

    const onAcceptRemoveSubscription = async (): Promise<void> => {
        const success = await cancelSubscription(subscription.uuid, removeDate);
        if (success) {
            onSubmitUpdate({});
        }
    };

    const handleSubmit = (date: Date | null): void => {
        if (date) {
            setRemoveDate(date);
        } else {
            onAcceptRemoveSubscription();
        }
    };

    return (
        <>
            <CancelSubscriptionDatePicker onSubmit={(date: Date | null) => handleSubmit(date)} subscription={subscription} />

            <ConfirmDialog
                open={!!removeDate}
                title={<LocalizeText tag="editFinish" />}
                description={<LocalizeText tag="editFinishDescription" />}
                onReject={() => setRemoveDate(null)}
                onAccept={() => onAcceptRemoveSubscription()}
                isLoading={isCancelingSubscription}
            />
        </>
    );
};
