import React, { FC, ReactNode, Reducer, createContext, useCallback, useContext, useMemo, useReducer } from "react";
import { addSeconds } from "date-fns";
import webAPIRequest from "api";
import reducer, {
    IAuth,
    IAction,
    IBackupCode,
    ISmsDevice,
    ITotpDevice,
    initialState,
    IAuthorization,
    removeLandingPageStorage,
} from "reducers/auth";
import { NotificationContext } from "contexts/notification";
import { AxiosError } from "axios";

export const LOGOUT_DELAY = 30 * 60 * 1000; // 30 minutes

export const AuthContext = createContext<IAuth>({
    ...initialState,
});

export const AuthProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const { ...notification } = useContext(NotificationContext);

    const [currentState, dispatch] = useReducer<Reducer<IAuth, IAction>>(reducer, initialState);

    const login = useCallback(
        async (username: string, password: string): Promise<IAuthorization> => {
            try {
                dispatch({ type: "FETCH_CREDENTIALS" });
                const data = {
                    username,
                    password,
                };
                const returnData = await webAPIRequest("post", "/auth/login/", { data });
                const parsedData = {
                    otp_required: returnData.data.otp_required,
                    expires: addSeconds(new Date(), returnData.data.expires_in).toISOString(),
                };
                dispatch({
                    type: "FETCH_CREDENTIALS_SUCCESS",
                    sessionStore: parsedData,
                });
                removeLandingPageStorage();
                return returnData.data;
            } catch (e) {
                const error = e as AxiosError;
                if (error.response) {
                    if (error.response.status === 400) {
                        notification.enqueNotification("error_login_400");
                    } else if (error.response.status === 403) {
                        notification.enqueNotification("error_login_403");
                    }
                } else {
                    notification.enqueNotification("error_login_no_response");
                }

                dispatch({ type: "FETCH_CREDENTIALS_FAILURE" });
                return {} as IAuthorization;
            }
        },
        [notification]
    );

    const logout = useCallback(async (): Promise<boolean> => {
        if (currentState.isLoggingOut) {
            return true;
        }
        try {
            dispatch({ type: "LOGOUT" });
            await webAPIRequest("get", "/auth/logout/");
            dispatch({ type: "LOGOUT_SUCCESS" });
            dispatch({ type: "CLEAR_CREDENTIALS" });
            return true;
        } catch (error) {
            dispatch({ type: "LOGOUT_FAILURE" });
            dispatch({ type: "CLEAR_CREDENTIALS" });
            return false;
        }
    }, [currentState.isLoggingOut]);

    const setPassword = useCallback(
        async (
            password: string,
            rePassword: string,
            token: string,
            sendNewsByMail: boolean
        ): Promise<Record<string, unknown> | boolean> => {
            try {
                dispatch({ type: "SET_PASSWORD" });
                const data = {
                    password,
                    re_password: rePassword,
                    token,
                    send_news_by_mail: sendNewsByMail,
                };
                const returnData = await webAPIRequest("post", "/contact-persons/register/", { data });
                dispatch({ type: "SET_PASSWORD_SUCCESS" });
                notification.enqueNotification("success_saveNewPassword");
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_saveNewPassword", error);
                dispatch({ type: "SET_PASSWORD_FAILURE" });
                return false;
            }
        },
        [notification]
    );

    const forgotPassword = useCallback(
        async (email: string): Promise<boolean> => {
            try {
                dispatch({ type: "FORGOT_PASSWORD" });
                const data = {
                    email,
                };
                await webAPIRequest("post", "/auth/reset-password/", { data });
                notification.enqueNotification("success_sentResetPassword");
                dispatch({ type: "FORGOT_PASSWORD_SUCCESS" });
                return true;
            } catch (error) {
                notification.enqueNotification("error_sendPasswordLink", error);
                dispatch({ type: "FORGOT_PASSWORD_FAILURE" });
                return false;
            }
        },
        [notification]
    );

    const resetPassword = useCallback(
        async (new_password: string, uuid: string): Promise<number> => {
            try {
                dispatch({ type: "RESET_PASSWORD" });
                const data = {
                    new_password,
                    uuid,
                };
                await webAPIRequest("post", "/auth/new-password/", { data });
                dispatch({ type: "RESET_PASSWORD_SUCCESS" });
                notification.enqueNotification("success_resetPassword");
                return 200;
            } catch (error) {
                const e = error as AxiosError;
                const response_status = e.response ? e.response.status : 0;
                if (response_status !== 412) {
                    notification.enqueNotification("error_resetPassword", error);
                }

                dispatch({ type: "RESET_PASSWORD_FAILURE" });
                return response_status;
            }
        },
        [notification]
    );

    const fetchBackupCodes = useCallback(async (): Promise<IBackupCode[] | boolean> => {
        try {
            dispatch({ type: "FETCH_BACKUP_CODES" });
            const allResults: IBackupCode[] = [];
            let url = "/auth/otp/backup-codes/";
            do {
                const returnData = await webAPIRequest("get", url);
                const { results, next } = returnData.data;
                url = next;
                allResults.push(...results);
            } while (url !== null);
            dispatch({
                type: "FETCH_BACKUP_CODES_SUCCESS",
                codes: allResults,
            });
            return allResults;
        } catch (error) {
            notification.enqueNotification("error_fetchBackupCodes", error);
            dispatch({ type: "FETCH_BACKUP_CODES_FAILURE" });
            return false;
        }
    }, [notification]);

    const generateBackupCodes = useCallback(async (): Promise<boolean> => {
        try {
            dispatch({ type: "GENERATE_BACKUP_CODES" });
            const returnData = await webAPIRequest("post", "/auth/otp/backup-codes/");
            dispatch({ type: "GENERATE_BACKUP_CODES_SUCCESS", codes: returnData.data });
            return true;
        } catch (error) {
            notification.enqueNotification("error_generateBackupCodes", error);
            dispatch({ type: "GENERATE_BACKUP_CODES_FAILURE" });
            return false;
        }
    }, [notification]);

    const fetchTotpDevices = useCallback(async (): Promise<boolean> => {
        try {
            dispatch({ type: "FETCH_TOTP_DEVICES" });
            const allResults: ITotpDevice[] = [];
            let url = "/auth/otp/totp/devices/";
            do {
                const returnData = await webAPIRequest("get", url);
                const { results, next } = returnData.data;
                url = next;
                allResults.push(...results);
            } while (url !== null);
            dispatch({
                type: "FETCH_TOTP_DEVICES_SUCCESS",
                devices: allResults,
            });
            return true;
        } catch (error) {
            notification.enqueNotification("error_fetchTotpDevices", error);
            dispatch({ type: "FETCH_TOTP_DEVICES_FAILURE" });
            return false;
        }
    }, [notification]);

    const createTotpDevice = useCallback(
        async (name: string, key?: string): Promise<boolean> => {
            try {
                dispatch({ type: "CREATE_TOTP_DEVICE" });
                const data = {
                    name: name,
                    key: key,
                };
                const returnData = await webAPIRequest("post", "/auth/otp/totp/devices/", {
                    data,
                });
                dispatch({ type: "CREATE_TOTP_DEVICE_SUCCESS", device: returnData.data });
                return true;
            } catch (error) {
                notification.enqueNotification("error_createTotpDevice", error);
                dispatch({ type: "CREATE_TOTP_DEVICE_FAILURE" });
                return false;
            }
        },
        [notification]
    );

    const updateTotpDevice = useCallback(
        async (id: number, name: string): Promise<boolean> => {
            try {
                dispatch({ type: "UPDATE_TOTP_DEVICE" });
                const data = {
                    name: name,
                };
                const returnData = await webAPIRequest("patch", `/auth/otp/totp/devices/${id}/`, {
                    data,
                });
                dispatch({ type: "UPDATE_TOTP_DEVICE_SUCCESS", id: id, device: returnData.data });
                return true;
            } catch (error) {
                notification.enqueNotification("error_updateTotpDevice", error);
                dispatch({ type: "UPDATE_TOTP_DEVICE_FAILURE" });
                return false;
            }
        },
        [notification]
    );

    const deleteTotpDevice = useCallback(
        async (id: number): Promise<boolean> => {
            try {
                dispatch({ type: "DELETE_TOTP_DEVICE" });
                await webAPIRequest("delete", `/auth/otp/totp/devices/${id}/`);
                dispatch({ type: "DELETE_TOTP_DEVICE_SUCCESS", id: id });
                notification.enqueNotification("success_removeTotpDevice");
                return true;
            } catch (error) {
                notification.enqueNotification("error_removeTotpDevice", error);
                dispatch({ type: "DELETE_TOTP_DEVICE_FAILURE" });
                return false;
            }
        },
        [notification]
    );

    const fetchSmsDevices = useCallback(async (): Promise<boolean> => {
        try {
            dispatch({ type: "FETCH_SMS_DEVICES" });
            const allResults: ISmsDevice[] = [];
            let url = "/auth/otp/sms/devices/";
            do {
                const returnData = await webAPIRequest("get", url);
                const { results, next } = returnData.data;
                url = next;
                allResults.push(...results);
            } while (url !== null);
            dispatch({
                type: "FETCH_SMS_DEVICES_SUCCESS",
                devices: allResults,
            });
            return true;
        } catch (error) {
            notification.enqueNotification("error_fetchSmsDevices", error);
            dispatch({ type: "FETCH_SMS_DEVICES_FAILURE" });
            return false;
        }
    }, [notification]);

    const createSmsDevice = useCallback(
        async (name: string, number: string, key?: string): Promise<boolean> => {
            try {
                dispatch({ type: "CREATE_SMS_DEVICE" });
                const data = {
                    name: name,
                    number: number,
                    key: key,
                };
                const returnData = await webAPIRequest("post", "/auth/otp/sms/devices/", {
                    data,
                });
                dispatch({ type: "CREATE_SMS_DEVICE_SUCCESS", device: returnData.data });
                return true;
            } catch (error) {
                notification.enqueNotification("error_createSmsDevice", error);
                dispatch({ type: "CREATE_SMS_DEVICE_FAILURE" });
                return false;
            }
        },
        [notification]
    );

    const updateSmsDevice = useCallback(
        async (id: number, number: string, name?: string): Promise<boolean> => {
            try {
                dispatch({ type: "UPDATE_SMS_DEVICE" });
                const data = {
                    number: number,
                };
                if (number) {
                    data["name"] = name;
                }
                const returnData = await webAPIRequest("patch", `/auth/otp/sms/devices/${id}/`, {
                    data,
                });
                dispatch({ type: "UPDATE_SMS_DEVICE_SUCCESS", id: id, device: returnData.data });
                return true;
            } catch (error) {
                notification.enqueNotification("error_updateSmsDevice", error);
                dispatch({ type: "UPDATE_SMS_DEVICE_FAILURE" });
                return false;
            }
        },
        [notification]
    );

    const deleteSmsDevice = useCallback(
        async (id: number): Promise<boolean> => {
            try {
                dispatch({ type: "DELETE_SMS_DEVICE" });
                await webAPIRequest("delete", `/auth/otp/sms/devices/${id}/`);
                dispatch({ type: "DELETE_SMS_DEVICE_SUCCESS", id: id });
                notification.enqueNotification("success_removeSmsDevice");
                return true;
            } catch (error) {
                notification.enqueNotification("error_removeSmsDevice", error);
                dispatch({ type: "DELETE_SMS_DEVICE_FAILURE" });
                return false;
            }
        },
        [notification]
    );

    const sendAuthenticationSMS = useCallback(
        async (key?: string): Promise<string> => {
            try {
                dispatch({ type: "SEND_SMS" });
                const noticedNumber = await webAPIRequest("post", "/auth/otp/sms/send/", {
                    data: { key },
                });
                dispatch({ type: "SEND_SMS_SUCCESS" });
                return noticedNumber.data;
            } catch (error) {
                notification.enqueNotification("error_sendSms", error);
                dispatch({ type: "SEND_SMS_FAILURE" });
                return "";
            }
        },
        [notification]
    );

    const clearAuthState = useCallback((): void => {
        dispatch({ type: "CLEAR_CREDENTIALS" });
    }, []);

    const verifyOtpCode = useCallback(async (data: { token: string; key?: string }): Promise<boolean> => {
        try {
            let parsedData = {};
            dispatch({ type: "VERIFY_OTP_CODE" });
            const response = await webAPIRequest("post", "/auth/otp/verify/", { data });
            if (response.data.expires_in) {
                parsedData = {
                    expires: addSeconds(new Date(), response.data.expires_in).toISOString(),
                };
            }

            dispatch({ type: "VERIFY_OTP_CODE_SUCCESS", sessionStore: parsedData });
            return true;
        } catch (error) {
            dispatch({ type: "VERIFY_OTP_CODE_FAILURE" });
            return false;
        }
    }, []);

    const refreshCredentials = useCallback(async (): Promise<boolean> => {
        try {
            dispatch({ type: "REFRESH_CREDENTIALS" });
            const returnData = await webAPIRequest("post", "/auth/refresh-token/");
            if (!returnData.data.expires_in) {
                throw new Error("Could not update expiry time of session");
            }
            const parsedData = {
                expires: addSeconds(new Date(), returnData.data.expires_in).toISOString(),
            };

            dispatch({ type: "REFRESH_CREDENTIALS_SUCCESS", sessionStore: parsedData });
            return true;
        } catch (error) {
            dispatch({ type: "REFRESH_CREDENTIALS_FAILURE" });
            logout();
            return false;
        }
    }, [logout]);

    const value = useMemo(() => {
        return {
            ...currentState,
            login,
            logout,
            setPassword,
            forgotPassword,
            resetPassword,
            fetchBackupCodes,
            generateBackupCodes,
            fetchSmsDevices,
            createSmsDevice,
            updateSmsDevice,
            deleteSmsDevice,
            sendAuthenticationSMS,
            fetchTotpDevices,
            createTotpDevice,
            updateTotpDevice,
            deleteTotpDevice,
            verifyOtpCode,
            clearAuthState,
            refreshCredentials,
        };
    }, [
        currentState,
        login,
        logout,
        setPassword,
        forgotPassword,
        resetPassword,
        fetchBackupCodes,
        generateBackupCodes,
        fetchSmsDevices,
        createSmsDevice,
        updateSmsDevice,
        deleteSmsDevice,
        sendAuthenticationSMS,
        fetchTotpDevices,
        createTotpDevice,
        updateTotpDevice,
        deleteTotpDevice,
        verifyOtpCode,
        clearAuthState,
        refreshCredentials,
    ]);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

interface IConsumer {
    component: FC<{ auth: IAuth }>;
    props?: Record<string, unknown>;
}
export const AuthConsumer: FC<IConsumer> = ({ component, props = {} }) => {
    return (
        <AuthContext.Consumer>
            {(auth) => {
                return <>{component({ auth: { ...props, ...auth } })}</>;
            }}
        </AuthContext.Consumer>
    );
};
