import React, { useCallback } from "react";
import { ProductsContext } from "contexts/products";

interface UseProductKey {
    productKeyIsValid: boolean;
    onProductKeyChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const useProductKeyValidation = (onChange: (newValue: string) => void): UseProductKey => {
    const { fetchProduct } = React.useContext(ProductsContext);
    const [timeoutHandle, setTimeoutHandle] = React.useState<NodeJS.Timeout | null>(null);
    const [productKeyIsValid, setProductKeyIsValid] = React.useState<boolean>(true);

    const performSearch = useCallback(
        (newValue: string): void => {
            if (timeoutHandle) {
                clearTimeout(timeoutHandle);
            }
            setTimeoutHandle(
                setTimeout(async () => {
                    if (newValue === "") {
                        setProductKeyIsValid(false);
                        return;
                    }
                    const existingProduct = await fetchProduct(newValue, true);
                    setProductKeyIsValid(existingProduct.id === undefined);
                }, 600)
            );
        },
        [fetchProduct, timeoutHandle]
    );

    const onProductKeyChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>): void => {
            const value = e.target.value;
            onChange(value);
            performSearch(value);
        },
        [performSearch, onChange]
    );

    return {
        productKeyIsValid,
        onProductKeyChange,
    };
};
export default useProductKeyValidation;
