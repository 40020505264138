import { Link, List, ListItem, ListItemText } from "@mui/material";
import { format, parseISO } from "date-fns";
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { getExpireDate } from "utils/ecommerseTypes";
import { dateFormats } from "utils/formats";
import { prettifyOrgNumber } from "utils/helpers";
import EditableTextField from "./drawers/editableTextField";
import CountryField from "./drawers/membershipInformation/countryField";
import DocumentsField from "./drawers/membershipInformation/documentsField";
import FreeFromTextField from "./drawers/membershipInformation/freeFormTextField";
import InvoiceAddressFields from "./drawers/membershipInformation/invoiceAddresFields";
import InvoiceMethodFields from "./drawers/membershipInformation/invoiceMethodFields";
import OrganizationNameField from "./drawers/membershipInformation/organizationNameField";
import { LocalizeText } from "./localizer";
import { IAlternativeInvoiceRecipient, IMembership } from "reducers/memberships";
import HasPermission from "./__helpers__/hasPermission";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { MembershipsContext } from "contexts/memberships";
import { usePermission } from "utils/hooks/usePermission";
import { IButtonProps, ListItemWithButton } from "./drawers/subscription/listItemWithButton";
import { ModalContext } from "contexts/modals";
import SetAlternativeRecipientDrawer from "./drawers/setAlternativeRecipientDrawer";
import ExistingRecipientDrawer from "./drawers/existingRecipientDrawer";
import { InvoicingInfo } from "./drawers/invoicingInfo";
import { LanguageContext } from "contexts/language";
import ReferencePersonFields from "./drawers/membershipInformation/referencePersonFields";

interface IProps {
    membership: IMembership;
    onSubmitUpdate: (params: Partial<IMembership>) => Promise<void>;
}
export const MembershipInfoColumn: FC<IProps> = ({ membership, onSubmitUpdate }) => {
    const { fetchTac, fetchAlternativeRecipient, isFetchingAlternativeRecipient } = useContext(MembershipsContext);
    const { hasPermission, hasPermissions } = usePermission();
    const { localize } = useContext(LanguageContext);
    const { openDrawer, closeDrawers } = useContext(ModalContext);
    const [alternativeRecipient, setAlternativeRecipient] = useState<IAlternativeInvoiceRecipient | undefined>(
        undefined
    );

    useEffect(() => {
        if (alternativeRecipient || !hasPermission("view_alternativeinvoicerecipient")) {
            return;
        }

        (async () => {
            if (!membership.alternative_invoice_recipient) {
                return;
            }
            const recipient = await fetchAlternativeRecipient(membership.alternative_invoice_recipient);
            setAlternativeRecipient(recipient);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [membership.alternative_invoice_recipient]);

    const showArena = "is_community_user";

    const onClickTac = useCallback(async (): Promise<void> => {
        const tacDocuments = await fetchTac({ membership_id: membership.id });
        if (tacDocuments) {
            tacDocuments.forEach((tac) => {
                if (tac.file.length > 0) {
                    window.open(tac.file);
                }
            });
        }
    }, [fetchTac, membership.id]);

    const onSelectRecipient = useCallback(
        async (recipient: IAlternativeInvoiceRecipient) => {
            await onSubmitUpdate({ alternative_invoice_recipient: recipient.uuid });
            closeDrawers();
        },
        [onSubmitUpdate, closeDrawers]
    );

    const recipientButton = useMemo(() => {
        if (!hasPermission("view_alternativeinvoicerecipient") || !!membership.end_date) {
            return undefined;
        }

        if (alternativeRecipient) {
            return {
                tag: "show",
                show: true,
                onClick: () =>
                    openDrawer(
                        ExistingRecipientDrawer,
                        { recipient: alternativeRecipient },
                        "alternativeInvoiceRecipient"
                    ),
            } as IButtonProps;
        }
        return {
            tag: "edit",
            show:
                !isFetchingAlternativeRecipient &&
                hasPermissions(["change_membership", "change_alternative_invoice_recipient"]),
            onClick: () =>
                openDrawer(
                    SetAlternativeRecipientDrawer,
                    {
                        onSelect: onSelectRecipient,
                    },
                    "alternativeInvoiceRecipient"
                ),
        } as IButtonProps;
    }, [
        membership.end_date,
        alternativeRecipient,
        hasPermission,
        hasPermissions,
        isFetchingAlternativeRecipient,
        onSelectRecipient,
        openDrawer,
    ]);

    const alternativeRecipientText = useMemo(() => {
        if (!membership.alternative_invoice_recipient) {
            return "-";
        }

        if (alternativeRecipient) {
            return `${alternativeRecipient.organization.name} (${prettifyOrgNumber(
                alternativeRecipient.organization.org_nr
            )})`;
        }

        return localize("assigned");
    }, [membership.alternative_invoice_recipient, alternativeRecipient, localize]);

    return (
        <>
            <List>
                <OrganizationNameField onSubmitUpdate={onSubmitUpdate} membership={membership} />
                <ListItem divider>
                    <ListItemText
                        primary={<LocalizeText tag="legalCompanyName" />}
                        secondary={membership?.organization.company_name}
                    />
                </ListItem>
                <CountryField membership={membership} onSubmitUpdate={onSubmitUpdate} />
                <ListItem divider>
                    <ListItemText
                        primary={<LocalizeText tag="orgNumber" />}
                        secondary={
                            membership?.organization.org_nr ? prettifyOrgNumber(membership?.organization.org_nr) : "-"
                        }
                    />
                </ListItem>
                {membership.alternative_invoice_recipient ? (
                    <InvoicingInfo data={membership} strikeThrough={true} />
                ) : (
                    <>
                        <InvoiceAddressFields membership={membership} onSubmitUpdate={onSubmitUpdate} />
                        <InvoiceMethodFields membership={membership} onSubmitUpdate={onSubmitUpdate} />
                        <EditableTextField
                            obj={membership}
                            tag="invoiceReference"
                            prop="user_invoice_reference"
                            onSubmitUpdate={onSubmitUpdate}
                            canEdit={hasPermission("change_membership") && !membership.end_date}
                        />
                    </>
                )}
                <ListItemWithButton
                    primary={<LocalizeText tag="alternativeInvoiceRecipient" />}
                    secondary={alternativeRecipientText}
                    button={recipientButton}
                />
                <ListItem divider>
                    <ListItemText primary={<LocalizeText tag="customerNumber" />} secondary={membership.agreement_nr} />
                </ListItem>

                <ReferencePersonFields membership={membership} onSubmitUpdate={onSubmitUpdate} />

                <ListItem divider>
                    <ListItemText
                        primary={<LocalizeText tag="branchCode" />}
                        secondary={membership.branch_code || "-"}
                    />
                </ListItem>
                <ListItem divider>
                    <ListItemText
                        primary={<LocalizeText tag="from" />}
                        secondary={
                            membership.start_date
                                ? format(parseISO(membership.start_date ?? ""), dateFormats.WEBDATE)
                                : ""
                        }
                    />
                </ListItem>
                <ListItem divider>
                    <ListItemText
                        primary={<LocalizeText tag="contractDuration" />}
                        secondary={getExpireDate(membership.end_date)}
                    />
                </ListItem>
                <ListItem divider>
                    <ListItemText
                        primary={<LocalizeText tag="contactPersons" />}
                        secondary={
                            <span style={{ display: "flex", flexDirection: "column" }}>
                                <span>
                                    <LocalizeText tag="activePlural" />: {membership.contact_persons_count}
                                </span>
                                <span>
                                    <LocalizeText tag="invitations" />: {membership.invitations_count}
                                </span>
                            </span>
                        }
                    />
                </ListItem>
                <ListItem divider>
                    <ListItemText
                        primary={<LocalizeText tag="subscriptions" />}
                        secondary={membership.subscriptions_count}
                    />
                </ListItem>
                <HasPermission requiredPerm={showArena}>
                    <FreeFromTextField membership={membership} onSubmitUpdate={onSubmitUpdate} />
                </HasPermission>
                <DocumentsField membership={membership} />
            </List>
            <div className="BottomRow">
                <Link
                    sx={{
                        fontSize: "1rem",
                        marginLeft: "auto",
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={onClickTac}
                    underline="hover"
                >
                    <LocalizeText tag="tac" />
                    <OpenInNewIcon sx={{ marginLeft: "5px", fontSize: "1rem" }} />
                </Link>
            </div>
        </>
    );
};
