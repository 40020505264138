import React from "react";
import { LocalizeText } from "components/localizer";
import { IconButton } from "@mui/material";
import { THeadCell } from "utils/types";
import { IContactPerson } from "reducers/memberships";
import TableComponent, { IRow } from "components/tableComponent";
import { prettifyPhoneNumber } from "utils/helpers";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ConfirmDialog from "components/confirmDialog";
import HasPermission from "components/__helpers__/hasPermission";
import { IPaginationStore } from "utils/paginationStore";

const userHeadCells: THeadCell[] = [
    { id: "name", sortId: "first_name", label: <LocalizeText tag="name" /> },
    { id: "email", sortId: "email", label: <LocalizeText tag="email" /> },
    { id: "phone", sortId: "phone", label: <LocalizeText tag="phoneNumber" /> },
    { id: "role", label: <LocalizeText tag="role" /> },
    { id: "", label: "", padding: "none" },
];

interface IContactPersonTableProps {
    loading: boolean;
    pagination: IPaginationStore<IContactPerson>;
    handleRemove: (contact: IContactPerson) => void;
    openEditDrawer: (contact: IContactPerson) => void;
    permissions: Record<string, string>;
}

interface IBaseState {
    selectedPerson?: IContactPerson;
    isConfirmOpen: boolean;
}

const ContactPersonTable: React.FC<IContactPersonTableProps> = ({
    loading,
    pagination,
    handleRemove,
    openEditDrawer,
    permissions,
}) => {
    const [state, setState] = React.useState<IBaseState>({
        isConfirmOpen: false,
    });

    const mapRows = (): IRow[] => {
        return pagination.results.map((person) => ({
            key: person.email,
            data: [
                `${person.first_name} ${person.last_name}`,
                person.email,
                person.phone ? prettifyPhoneNumber(person.phone) : "-",
                person.role ? person.role.display_name : "-",
                person.can_manage && (
                    <>
                        <HasPermission requiredPerm={permissions["patch"]}>
                            <IconButton
                                size="small"
                                data-testid={`edit_contact_person_${person.email}`}
                                key={`edit_button_${person.email}`}
                                onClick={() => openEditDrawer(person)}
                            >
                                <EditOutlinedIcon />
                            </IconButton>
                        </HasPermission>
                        <HasPermission requiredPerm={permissions["delete"]}>
                            <IconButton
                                size="small"
                                data-testid={`delete_contact_person_${person.email}`}
                                key={`delete_button_${person.email}`}
                                onClick={() =>
                                    setState({
                                        ...state,
                                        selectedPerson: person,
                                        isConfirmOpen: true,
                                    })
                                }
                            >
                                <DeleteOutlineIcon />
                            </IconButton>
                        </HasPermission>
                    </>
                ),
            ],
        }));
    };

    const onAcceptRemoveContact = (): void => {
        if (state.selectedPerson) {
            handleRemove(state.selectedPerson);
            setState({ ...state, isConfirmOpen: false, selectedPerson: undefined });
        }
    };

    return (
        <>
            <TableComponent headCells={userHeadCells} isLoading={loading} rows={mapRows()} pagination={pagination} />
            <ConfirmDialog
                open={state.isConfirmOpen}
                title={<LocalizeText tag={"removeContactPerson"} />}
                description={<LocalizeText tag={"removeContactPersonDescription"} />}
                onReject={() => setState({ ...state, isConfirmOpen: false, selectedPerson: undefined })}
                onAccept={onAcceptRemoveContact}
            />
        </>
    );
};

export default ContactPersonTable;
