import React, { FC, useContext, useEffect, useState } from "react";
import { LocalizeText } from "components/localizer";
import { IMembership } from "reducers/memberships";
import { ListItemText, MenuItem, TextField } from "@mui/material";
import ListInlineEditWrapper from "../listComponents/listInlineEditWrapper";
import { MembershipsContext } from "contexts/memberships";
import { LanguageContext } from "contexts/language";
import { getCountryCodeString } from "utils/countryCodes";
import { usePermission } from "utils/hooks/usePermission";

interface IProps {
    membership: IMembership;
    onSubmitUpdate: (props: Record<string, unknown>) => void;
}

const CountryField: FC<IProps> = ({ membership, onSubmitUpdate }) => {
    const { country_code } = membership.organization;

    const [countryCodeId, setCountryCodeId] = useState(membership.organization.country_code?.uuid ?? "");
    const { fetchCountries, countries, isFetchingCountries } = useContext(MembershipsContext);
    const { currentLanguage } = useContext(LanguageContext);
    const { hasPermission } = usePermission();

    useEffect(() => {
        if (countries?.length === 0 && !isFetchingCountries) {
            fetchCountries();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setCountryCodeId(membership.organization.country_code?.uuid ?? "");
    }, [membership]);

    return (
        <ListInlineEditWrapper
            onChange={(e) => {
                setCountryCodeId(e.target.value);
            }}
            onSubmit={() =>
                onSubmitUpdate({
                    organization: { country_code: countryCodeId },
                })
            }
            label={<LocalizeText tag="country" />}
            renderInput={(props) => (
                <TextField
                    {...props}
                    value={countryCodeId}
                    fullWidth
                    select
                    required
                    helperText={<LocalizeText tag="partOfOrgInfo" />}
                >
                    {countries?.map((country) => (
                        <MenuItem key={country.uuid} value={country.uuid}>
                            {getCountryCodeString(country, currentLanguage)}
                        </MenuItem>
                    ))}
                </TextField>
            )}
            inputIsValid={true}
            renderView={(props) => (
                <ListItemText
                    {...props}
                    secondary={country_code ? getCountryCodeString(country_code, currentLanguage) : "-"}
                />
            )}
            editDisabled={!hasPermission("change_membership") || !!membership.end_date || membership.is_archived}
        />
    );
};

export default CountryField;
