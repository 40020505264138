import webAPIRequest from "api";
import { AxiosResponse } from "axios";
import { format } from "date-fns";
import { IAvailability, TMembershipId } from "reducers/memberships";
import { dateFormats } from "utils/formats";

export const fetchMembershipContactsAdapter = async (
    membershipId: TMembershipId,
    params: Record<string, unknown>
): Promise<AxiosResponse> => {
    return webAPIRequest("get", 
    `/memberships/${membershipId}/contact-persons/`
    ,{ params });
};

export const fetchProductsAvailability = async (
    membershipId: number,
    startDate: Date
): Promise<AxiosResponse<IAvailability>> => {
    const params = { start_date: format(startDate, dateFormats.APIDATE) };

    return webAPIRequest("get", `/memberships/${membershipId}/availability/`, { params });
};