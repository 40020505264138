import React from "react";
import { LocalizeText } from "components/localizer";
import { Grid, MenuItem, TextField } from "@mui/material";
import { DatePicker } from "components/datePicker";
import { dateFormats } from "utils/formats";
import CommunityContext from "contexts/community";
import { format, isValid as isValidDate, parseISO } from "date-fns";
import { IUsePaginationStore } from "utils/usePaginationStore";

interface ISubscriptionFilterInputProps {
    subscriptionPagination: IUsePaginationStore<"subscriptions">;
}

interface IState {
    start: Date | null;
    end: Date | null;
    communities: string[];
}

const filterParamMapping: Record<keyof IState, string> = {
    start: "end_date__gte",
    end: "end_date__lte",
    communities: "communities",
};

const SubscriptionFilterInput: React.FC<ISubscriptionFilterInputProps> = ({ subscriptionPagination }) => {
    const { communitiesPagination } = React.useContext(CommunityContext);

    const [state, setState] = React.useState<IState>({
        start: null,
        end: null,
        communities: [],
    });

    React.useEffect(() => {
        const start = subscriptionPagination.getParam("end_date__gte");
        const end = subscriptionPagination.getParam("end_date__lte");
        const communities = subscriptionPagination.getParam("communities");

        setState({
            start: start == null ? null : parseISO(start as string),
            end: end == null ? null : parseISO(end as string),
            communities: communities == null ? [] : (communities as string).split(","),
        });
        communitiesPagination.getInitial();
        // eslint-disable-next-line
    }, []);

    const onChange = (type: keyof IState, value: string | Date): void => {
        if (value) {
            setState({ ...state, [type]: value });
            if (value instanceof Date) {
                if (!isValidDate(value)) {
                    return;
                }
                value = format(value, dateFormats.APIDATE);
            }
            subscriptionPagination.setParam(filterParamMapping[type], value.toString());
            subscriptionPagination.getInitial();
        } else {
            subscriptionPagination.setParam(filterParamMapping[type], null);
        }
    };

    return (
        <Grid container justifyContent={"flex-end"} alignItems={"baseline"} rowSpacing={4}>
            <Grid item xs={4}>
                <LocalizeText tag="subscriptionPeriod" />
            </Grid>
            <Grid container item xs={8} alignItems={"baseline"} flexWrap={"nowrap"}>
                <DatePicker
                    format={dateFormats.WEBDATE}
                    label={<LocalizeText tag={"from"} />}
                    value={state.start}
                    slotProps={{
                        textField: {
                            sx: { paddingRight: "1em" },
                            fullWidth: true,
                            variant: "standard",
                            size: "small",
                        },
                    }}
                    onChange={(value: Date) => onChange("start", value as Date)}
                />
                <DatePicker
                    format={dateFormats.WEBDATE}
                    label={<LocalizeText tag={"to"} />}
                    value={state.end}
                    slotProps={{ textField: { variant: "standard", fullWidth: true, size: "small" } }}
                    onChange={(value: Date) => onChange("end", value as Date)}
                />
            </Grid>
            <Grid item xs={4}>
                <LocalizeText tag="arenas" />
            </Grid>
            <Grid container item xs={8} alignItems={"baseline"} flexWrap={"nowrap"}>
                <TextField
                    select
                    variant="standard"
                    fullWidth
                    SelectProps={{
                        multiple: true,
                    }}
                    value={state.communities}
                    onChange={(event) => onChange("communities", event.target.value)}
                >
                    {communitiesPagination.results.map((item) => (
                        <MenuItem key={item.uuid} value={item.uuid}>
                            {item.title}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
        </Grid>
    );
};

export default SubscriptionFilterInput;
