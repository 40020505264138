export type TParamValue = string | string[] | number | boolean | null;

export type TPaginationActionData<T> = {
    count: number;
    next: string | null;
    previous: string | null;
    results: T[];
};

export const emptyPaginationActionData = {
    count: 0,
    next: null,
    previous: null,
    results: [],
};

export interface IPaginationStore<T = unknown> {
    results: T[];
    page: number;
    totalCount: number;
    setParam: (key: string, value: TParamValue) => void;
    getParam: (key: string) => TParamValue;
    getInitial: () => void; // Only used by (old) PagionationStore class
    changePage: (newPage: number) => void;
}

export default class PaginationStore<T> implements IPaginationStore<T> {
    results: T[] = [];
    page = 0;
    next: string | null = null; // url
    previous: string | null = null; // url
    totalCount = 0;
    fetchData?: (params: Record<string, unknown>) => Promise<TPaginationActionData<T>>;
    defaultParams = { page_size: 20, page: 1, default_page_size: 20 };
    params = { ...this.defaultParams };
    appendResults = false;

    constructor(
        params?: Record<string, unknown>,
        fetchData?: (params: Record<string, unknown>) => Promise<TPaginationActionData<T>>
    ) {
        if (params) {
            this.params = { ...this.defaultParams, ...params };
        }
        if (fetchData) {
            this.fetchData = fetchData;
        }
    }

    setParam = (key: string, value: TParamValue): void => {
        if (value === null) {
            delete this.params[key];
        } else {
            this.params[key] = value;
        }
    };

    setParams = (params: Record<string, TParamValue>): void => {
        this.params = { ...this.params, ...params };
    };

    getParam = (key: string): TParamValue => {
        return this.params[key];
    };

    initialize = (fetchData: (params: Record<string, unknown>) => Promise<TPaginationActionData<T>>): void => {
        this.fetchData = fetchData;
        this.results = [];
        this.totalCount = 0;
        this.next = null;
        this.previous = null;
        this.params = { ...this.defaultParams };
    };

    isInitialized = (): boolean => {
        return !!this.fetchData;
    };

    getInitial = async (): Promise<void> => {
        if (!this.fetchData) {
            return;
        }

        this.page = 0;
        this.params.page = 1;
        this.update(await this.fetchData(this.params));
    };

    changePage = async (newPage: number): Promise<void> => {
        if (!this.fetchData || Math.abs(newPage - this.page) > 1) {
            return;
        }

        if (newPage > this.page) {
            await this.nextPage();
        } else {
            await this.previousPage();
        }
    };

    nextPage = async (): Promise<void> => {
        if (!this.fetchData) {
            return;
        }

        this.params.page += 1;
        this.page += 1;
        this.update(await this.fetchData(this.params));
    };

    previousPage = async (): Promise<void> => {
        if (!this.fetchData) {
            return;
        }

        this.page -= 1;
        this.params.page -= 1;
        this.update(await this.fetchData(this.params));
    };

    reloadPage = async (): Promise<void> => {
        if (!this.fetchData) {
            return;
        }

        if (this.page === 0) {
            this.update(await this.fetchData(this.params));
        } else {
            this.update(await this.fetchData({ ...this.params, page: this.page }));
        }
    };

    update = (data: TPaginationActionData<T>): void => {
        this.totalCount = data.count;
        this.next = data.next;
        this.previous = data.previous;
        if (this.results.length && this.appendResults) {
            this.results = this.results.concat(data.results);
        } else {
            this.results = data.results;
        }
    };
}
