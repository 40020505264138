import React from "react";
import Grid from "@mui/material/Grid";
import Dropdown, { TDropdownItem } from "components/dropdown";
import AppBar from "@mui/material/AppBar";
import { styled } from "@mui/material";
import colors from "styles/colors";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    backgroundColor: "transparent",
    boxShadow: "none",
    color: colors.black,
    "& button": {
        opacity: 1,
    },
    "& .MuiTabs-root > div > span": {
        backgroundColor: colors.vkOrange,
        height: 4,
    },
}));

interface IProps {
    searchField?: React.ReactNode;
    tabs?: React.ReactNode;
    menuItems?: TDropdownItem[];
    singleItem?: React.ReactNode;
    buttons?: React.ReactNode;
}
const TopBar: React.FC<IProps> = ({ searchField, tabs, menuItems, singleItem, buttons }) => (
    <Grid container sx={{ height: "3em" }} justifyContent={"space-between"}>
        <Grid container item xs={8}>
            {searchField && searchField}
            {buttons && buttons}
            {tabs && <StyledAppBar position="static">{tabs}</StyledAppBar>}
            {!searchField && !tabs && <Grid container item xs={9} alignItems={"center"} />}
        </Grid>

        <Grid
            item
            xs={4}
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "1em",
            }}
        >
            {singleItem}
            {menuItems && menuItems.length > 0 && <Dropdown items={menuItems} />}
        </Grid>
    </Grid>
);

export default TopBar;
