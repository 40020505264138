import React, { FC, ReactNode, useCallback } from "react";
import {
    Checkbox,
    FormControl,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    styled,
    Typography,
} from "@mui/material";
import colors from "styles/colors";
import { LocalizeText } from "./localizer";
import { TLanguageTag } from "contexts/language";

interface BaseProps {
    items: DropdownItem[];
    noSelectionTag?: TLanguageTag;
    disabled?: boolean;
    width?: string;
    size?: "small" | "medium" | undefined;
    fullWidth?: boolean;
}
interface SingleSelectProps extends BaseProps {
    onSelect: (id: string) => void;
    selectedId: string;
}
interface MultiSelectProps extends BaseProps {
    onToggle: (ids: string[]) => void;
    selectedIds: string[];
}

export interface DropdownItem {
    id: string;
    primary: string | ReactNode;
    secondary?: string | ReactNode;
    disabled?: boolean;
}

export const SelectDropdown: FC<SingleSelectProps> = ({
    noSelectionTag,
    selectedId,
    items,
    onSelect,
    disabled,
    width = "250px",
}) => {
    const handleChange = useCallback(
        (event: SelectChangeEvent<string>) => {
            const {
                target: { value },
            } = event;
            onSelect(value);
        },
        [onSelect]
    );

    return (
        <FormControl sx={{ width }}>
            <Select<string>
                size="small"
                sx={{
                    width,
                }}
                disabled={disabled}
                value={selectedId}
                onChange={handleChange}
                input={<StyledInput size="small" />}
                renderValue={(selected) => {
                    if (!selected && noSelectionTag) {
                        return <LocalizeText tag={noSelectionTag} />;
                    }
                    return items.find((item) => item.id === selected)?.primary;
                }}
                MenuProps={MenuProps}
                displayEmpty
            >
                {renderItems(items, selectedId)}
            </Select>
        </FormControl>
    );
};

export const MultiSelectDropdown: FC<MultiSelectProps> = ({
    noSelectionTag,
    selectedIds,
    items,
    onToggle,
    disabled,
    width = "250px",
    size = "small",
    fullWidth = false
}) => {
    const handleChange = useCallback(
        (event: SelectChangeEvent<string[]>) => {
            const {
                target: { value },
            } = event;
            onToggle(typeof value === "string" ? value.split(",") : value);
        },
        [onToggle]
    );

    return (
        <FormControl fullWidth={fullWidth}>
            <Select<string[]>
                size={size}
                multiple
                sx={{
                    width,
                }}
                disabled={disabled}
                value={selectedIds}
                onChange={handleChange}
                renderValue={(selected) => {
                    if (selected.length === 0 && noSelectionTag) {
                        return <LocalizeText tag={noSelectionTag} />;
                    }
                    return selected.map((id) => items.find((item) => item.id === id)?.primary).join(", ");
                }}
                MenuProps={MenuProps}
                displayEmpty
            >
                {renderItems(items, selectedIds)}
            </Select>
        </FormControl>
    );
};

const renderItems = (items: DropdownItem[], selectedId: string | string[]): ReactNode => {
    return items.map(({ id, primary: title, secondary, disabled }) => (
        <MenuItem key={id} value={id} disabled={disabled} sx={{ height: ITEM_HEIGHT, overflow: "hidden" }}>
            {Array.isArray(selectedId) ? <Checkbox checked={selectedId.includes(id)} /> : null}
            <Typography variant="body1" >
                {title}
            </Typography>
            {typeof secondary !== "undefined" ? (
                <Typography marginLeft="10px" >
                    {secondary}
                </Typography>
            ) : null}
        </MenuItem>
    ));
};

const ITEM_HEIGHT = 38;

const MenuProps = {
    PaperProps: {
        style: {
            marginTop: -2,
            maxHeight: ITEM_HEIGHT * 8,
            width: 250,
            top: 0,
            boxShadow: `0 0 0 2px ${colors.vkBlue} inset`,
        },
    },
};

const StyledInput = styled(OutlinedInput)(`
  overflow: hidden;
  color: ${colors.vkBlue};
  
  fieldset {
    border-color: ${colors.vkBlue} !important;
  }
`);
