import React from "react";
import { LocalizeText } from "components/localizer";
import { THeadCell } from "utils/types";
import TableComponent, { IRow } from "components/tableComponent";
import PaginationStore from "utils/paginationStore";
import { ICostCalculationRun } from "reducers/community";
import { IconButton } from "@mui/material";
import { Download, Done, Close } from "@mui/icons-material/";
import { format, parseISO } from "date-fns";
import { dateFormats } from "utils/formats";

interface IProps {
    pagination: PaginationStore<ICostCalculationRun>;
    loading: boolean;
}

export const costCalculationHeadcells: THeadCell[] = [
    { id: "id", sortId: "id", label: <LocalizeText tag="id" /> },
    { id: "created", sortId: "created", label: <LocalizeText tag="created" /> },
    { id: "offset", sortId: "offset", label: <LocalizeText tag="offset" />, alignCell: "center" },
    { id: "start_date", sortId: "start_date", label: <LocalizeText tag="startDate" /> },
    {
        id: "changed_last_invoice_dates",
        sortId: "changed_last_invoice_dates",
        label: <LocalizeText tag="invoiceDateChanged" />,
        alignCell: "center",
    },
    { id: "", label: "" },
];

const CostCalculationTable: React.FC<IProps> = ({ pagination, loading }) => {
    const mapRows = (runs: ICostCalculationRun[]): IRow[] => {
        return (
            runs &&
            runs.map((run) => ({
                data: [
                    run.id,
                    `${format(parseISO(run.created ?? ""), dateFormats.WEBDATETIME)}`,
                    run.offset,
                    run.start_date,
                    run.changed_last_invoice_dates ? <Done color="primary" /> : <Close color="warning" />,
                    run.file ? (
                        <IconButton size="small" key={`dowload_${run.uuid}`} onClick={() => window.open(run.file)}>
                            <Download color="primary" />
                        </IconButton>
                    ) : (
                        ""
                    ),
                ],
            }))
        );
    };

    return (
        <TableComponent
            headCells={costCalculationHeadcells}
            isLoading={loading}
            skeletonVariant="options"
            pagination={pagination}
            rows={mapRows(pagination.results)}
        />
    );
};

export default CostCalculationTable;
