import React from "react";
import RoleManagementLeaseTable from "../tables/roleManagementLeaseTable";
import { ProfileContext } from "contexts/profile";
import { LocalizeText } from "components/localizer";
import { TParamValue } from "utils/paginationStore";

const ContractsWithinOrganizationTab: React.FC = () => {
    const { selectedOrganization, user } = React.useContext(ProfileContext);

    const leaseParams: Record<string, TParamValue> = {
        org_nr: selectedOrganization.org_nr,
        by_not_user_email: user?.email ?? "",
    };
    return (
        <>
            <LocalizeText tag="leasesDescription" />
            <RoleManagementLeaseTable showSearch={false} paginationParams={leaseParams} hideRole={true} />
        </>
    );
};

export default ContractsWithinOrganizationTab;
