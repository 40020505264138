import React from "react";
import { LocalizeText } from "components/localizer";
import Banner from "components/banner";
import { Badge, Box, Tab, Tabs } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { MyContractsTab, RequestsTab, UsersWithinOrgTab, ContractsWithinOrgTab } from "./tabs";
import colors from "styles/colors";
import { usePermission } from "utils/hooks/usePermission";
import { useFeatureToggle } from "utils/hooks/useFeatureToggle";
import { useProfileNotifications } from "utils/hooks/useProfileNotifications";
import { permission } from "utils/permissions";

type TTabState = "MINE" | "REQUESTS" | "USERS" | "CONTRACTS";

const RoleUserManagementView: React.FC = () => {
    const search = useLocation().search;
    const navigate = useNavigate();
    const { hasPermission, hasPermissions } = usePermission();
    const isActive = useFeatureToggle();
    const { getTargetNotificationCount } = useProfileNotifications();
    const [tab, setTab] = React.useState<TTabState>("MINE");

    React.useEffect(() => {
        const queryTab = new URLSearchParams(search).get("tab");
        if (queryTab) {
            setTab(queryTab as TTabState);
        } else {
            navigate({
                pathname: "/representatives",
                search: "?tab=MINE",
            }, { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const onTabChange = (newTab: TTabState): void => {
        if (newTab !== tab) {
            navigate({
                pathname: "/representatives",
                search: `?tab=${newTab}`,
            }, { replace: true });
        }
    };

    const renderSelectedTab = (): React.ReactNode => {
        const showArena = hasPermissions(permission.ARENA_DASHBOARD);
        const showTenant = hasPermissions(permission.TENANT_DASHBOARD);
        switch (tab) {
            case "MINE":
                return <MyContractsTab showTenant={showTenant} showArenaMember={showArena} />;
            case "REQUESTS":
                return <RequestsTab />;
            case "USERS":
                return <UsersWithinOrgTab showTenant={showTenant} showArenaMember={showArena} />;
            case "CONTRACTS":
                return <ContractsWithinOrgTab />;
            default:
                return "";
        }
    };

    return (
        <div className="contentWrapper">
            <Banner
                title="representative"
                subTitle="bannerRepresentatives"
                image={`${process.env.PUBLIC_URL}/assets/images/1024x683/contactPersons-1024x683.jpg`}
                imageStyle={{ objectPosition: "50% 0%" }}
            />
            <Box style={{ marginTop: "60px" }}>
                <Tabs value={tab} onChange={(_, value: TTabState) => onTabChange(value)}>
                    <Tab label={<LocalizeText tag="myContracts" />} value="MINE" />
                    {hasPermission("is_lease_user") && (
                        <Tab label={<LocalizeText tag="contractsWithinOrganization" />} value="CONTRACTS" />
                    )}
                    {hasPermissions(["request_leaseinvitation", "is_lease_user"]) && (
                        <Tab
                            label={
                                <Badge
                                    max={9}
                                    badgeContent={getTargetNotificationCount("leaseinvitation")}
                                    color="primary"
                                    sx={{ paddingRight: "8px" }}
                                >
                                    <LocalizeText tag="requests" />
                                </Badge>
                            }
                            value="REQUESTS"
                        />
                    )}
                    {isActive("show_org_representatives") && (
                        <Tab label={<LocalizeText tag="representativesWithinOrganization" />} value="USERS" />
                    )}
                </Tabs>
                <Box
                    sx={{
                        minHeight: "25vh",
                        backgroundColor: colors.grey,
                        padding: "20px",
                    }}
                >
                    {renderSelectedTab()}
                </Box>
            </Box>
        </div>
    );
};

export default RoleUserManagementView;
