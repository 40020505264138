import React from "react";
import { AppBar, Drawer as MaterialDrawer, IconButton, styled, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import { ProfileContext } from "contexts/profile";
import { IAuth } from "reducers/auth";
import UserMenu from "components/navigation/userMenu";
import Items from "components/navigation/items";
import { LOGOUT_DELAY } from "contexts/auth";
import MenuIcon from "@mui/icons-material/Menu";
import { differenceInMilliseconds } from "date-fns";
import OrganizationMenu from "components/navigation/organizationMenu";
import { IMembership } from "reducers/memberships";
import { CenteredLoader } from "components/centeredLoader";
import colors from "styles/colors";

const drawerWidth = "19rem";

const NavigationDrawer = styled("nav")(({ theme }) => ({
    [theme.breakpoints.up("lg")]: {
        width: drawerWidth,
        flexShrink: 0,
    },
}));

const NavigationRoot = styled("div")(({ theme }) => ({
    height: "100%",
    display: "flex",
    "& .contentWrapper": {
        padding: "40px 60px",
        display: "flex",
        flexDirection: "column",
        gap: "2em",

        [theme.breakpoints.down(1350)]: {
            padding: "40px 5px 5px 5px",
        },

        [theme.breakpoints.down("lg")]: {
            padding: "64px 5px 5px 5px",
        },
    },
}));

const AppBarRoot = styled(AppBar)(({ theme }) => ({
    [theme.breakpoints.up("lg")]: {
        display: "none",
    },
    backgroundColor: "transparent",
    boxShadow: "none",
    color: colors.black,
}));

const OpenDrawerButton = styled(IconButton)(({ theme }) => ({
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
        display: "none",
    },
}));

const UserMenuContainer = styled(List)(() => ({
    margin: "0 16px",
    display: "flex",
    flexDirection: "column",
    flexGrow: 2,
    justifyContent: "space-between",
}));

interface IDrawerSelector {
    urlPath: string;
    logout: () => void;
    setDrawerOpen: (open: boolean) => void;
    isDrawerOpen: boolean;
}
const DrawerSelector: React.FC<IDrawerSelector> = ({ urlPath, logout, setDrawerOpen, isDrawerOpen }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("lg"));
    const { landingPage } = React.useContext(ProfileContext);

    return (
        <NavigationDrawer>
            <MaterialDrawer
                variant={matches ? "permanent" : "temporary"}
                anchor="left"
                open={matches ? true : isDrawerOpen}
                onClose={() => setDrawerOpen(false)}
                PaperProps={{
                    sx: {
                        overflowY: "overlay",
                        width: drawerWidth,
                        paddingLeft: "24px",
                        paddingRight: "24px",
                        border: "none",
                        backgroundColor: colors.vkOrange,
                    },
                }}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
            >
                <List
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}
                >
                    <img
                        style={{
                            width: "14rem",
                            margin: "2rem 0 2rem 0",
                        }}
                        src={`${process.env.PUBLIC_URL}/assets/images/vasakronan_logo_white.png`}
                        alt="Vasakronan logo"
                    />
                    <div>
                        <Items urlPath={urlPath} />
                    </div>
                </List>
                {landingPage === "default" && (
                    <List sx={{ margin: "0 16px" }}>
                        <OrganizationMenu />
                    </List>
                )}
                <UserMenuContainer>
                    <UserMenu logout={() => logout()} />
                </UserMenuContainer>
            </MaterialDrawer>
        </NavigationDrawer>
    );
};

interface IState {
    isDrawerOpen: boolean;
}

interface IProps {
    urlPath: string;
    member?: IMembership;
    auth: IAuth;
    clearMembershipStorage: () => void;
    children: React.ReactNode;
}
class Navigation extends React.Component<IProps> {
    context!: React.ContextType<typeof ProfileContext>;
    logoutTimer: NodeJS.Timeout | null = null;
    interactTimer: NodeJS.Timeout | null = null;
    sleepLogoutTimer: NodeJS.Timeout | null = null;
    state: IState = {
        isDrawerOpen: false,
    };


    resetTimers = (): void => {
        if (this.logoutTimer) {
            clearTimeout(this.logoutTimer);
        }
        if (this.interactTimer) {
            clearTimeout(this.interactTimer);
        }
        if (this.sleepLogoutTimer) {
            clearInterval(this.sleepLogoutTimer);
        }
    };

    setTimers = (): void => {
        const currentDate = new Date();
        this.sleepLogoutTimer = setInterval(() => {
            const now = new Date();
            const diffInMilli = differenceInMilliseconds(now, currentDate);
            if (diffInMilli > LOGOUT_DELAY) {
                //30 min
                this.onLogout();
            }
        }, 5000);
        this.logoutTimer = setTimeout(this.onLogout, LOGOUT_DELAY);
    };

    addEventTriggers = (): void => {
        window.addEventListener("click", this.onAction);
        window.addEventListener("mousemove", this.onAction);
    };

    removeEventTriggers = (): void => {
        window.removeEventListener("click", this.onAction);
        window.removeEventListener("mousemove", this.onAction);
    };

    componentDidMount = (): void => {
        const { user, isFetchingProfile, organizations, isFetchingOrgs } = this.context;
        if (!user && !isFetchingProfile) {
            this.context.fetchUserProfile();
        }
        if (!organizations && !isFetchingOrgs) {
            this.context.fetchOrganizations();
        }
        this.addEventTriggers();
        this.setTimers();
    };

    componentWillUnmount = (): void => {
        this.resetTimers();
        this.removeEventTriggers();
    };

    onAction = (): void => {
        this.resetTimers();
        this.interactTimer = setTimeout(() => {
            this.setTimers();
        }, 1000);
    };

    onLogout = async (): Promise<void> => {
        const { auth, clearMembershipStorage } = this.props;

        const { clearProfileStorage } = this.context;

        auth.logout();
        clearMembershipStorage();
        clearProfileStorage();
        window.sessionStorage.clear();
    };

    render(): React.ReactNode {
        const { isDrawerOpen } = this.state;
        const { children, urlPath } = this.props;
        const { user, organizations, permissions } = this.context;

        return (
            <NavigationRoot data-testid="navigation-root">
                <CssBaseline />
                <AppBarRoot position="absolute">
                    <Toolbar>
                        <OpenDrawerButton
                            data-testid="navigation-navMenuBtn"
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={() =>
                                this.setState(isDrawerOpen ? { isDrawerOpen: false } : { isDrawerOpen: true })
                            }
                            size="large"
                        >
                            <MenuIcon />
                        </OpenDrawerButton>
                    </Toolbar>
                </AppBarRoot>
                <DrawerSelector
                    urlPath={urlPath}
                    logout={this.onLogout}
                    setDrawerOpen={(open: boolean) => this.setState({ isDrawerOpen: open })}
                    isDrawerOpen={isDrawerOpen}
                />
                {!user || !organizations || !permissions ? (
                    <CenteredLoader size={30} />
                ) : (
                    <Grid item xs={12} sx={{ flexBasis: "100%", maxWidth: "100%" }}>
                        {children}
                    </Grid>
                )}
            </NavigationRoot>
        );
    }
}

Navigation.contextType = ProfileContext;
export default Navigation;
