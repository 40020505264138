import React, { FC, ReactElement, useMemo } from "react";
import { LocalizeText } from "components/localizer";
import { THeadCell } from "utils/types";
import TableComponent, { IRow } from "components/tableComponent";
import { getMemberStatusText, truncateText } from "utils/helpers";
import { IUsePaginationStore } from "utils/usePaginationStore";
import { IMember } from "reducers/members";
import { format } from "date-fns";
import { dateFormats } from "utils/formats";

const headCells = (): THeadCell[] => {
    return [
        { id: "email", sortId: "email", label: <LocalizeText tag="email" /> },
        { id: "name", sortId: "first_name", label: <LocalizeText tag="name" /> },
        { id: "id", label: <LocalizeText tag="id" /> },
        { id: "community", label: <LocalizeText tag="community" /> },
        { id: "company", label: <LocalizeText tag="company" /> },
        { id: "invitedToSpaceflow", label: <LocalizeText tag="invitedToSpaceflow" /> },
        { id: "status", sortId: "status", label: <LocalizeText tag="status" /> },
        { id: "terminatedInSpaceflow", label: <LocalizeText tag="terminatedInSpaceflow" /> },
    ];
};

interface IProps {
    pagination: IUsePaginationStore<"members">;
}

export const StaffMembersTable: FC<IProps> = ({ pagination }) => {
    const getSubscriptionIds = (member: IMember): ReactElement => {
        const subscriptionIds: string = member.subscription_ids.join(", ");
        return <span key={`${member.uuid}`}>{truncateText(subscriptionIds, 20)}</span>;
    };

    const getCommunities = (member: IMember): ReactElement => {
        const communities: string = member.communities.join(", ");
        return <span key={`${member.uuid}`}>{truncateText(communities, 20)}</span>;
    };

    const getCompanies = (member: IMember): ReactElement => {
        const companies: string = member.organization_names.join(", ");
        return <span key={`${member.uuid}`}>{truncateText(companies, 20)}</span>;
    };

    const getActivatedInSpaceflow = (member: IMember): ReactElement => {
        const invitedToSpaceflow = member.activated_in_spaceflow
            .map((entry) => format(entry, dateFormats.WEBDATE))
            .join(", ");
        return <span key={`${member.uuid}`}>{truncateText(invitedToSpaceflow, 20)}</span>;
    };

    const getDeactivatedInSpaceflow = (member: IMember): ReactElement => {
        const invitedToSpaceflow = member.activated_in_spaceflow
            .map((entry) => format(entry, dateFormats.WEBDATE))
            .join(", ");
        return <span key={`${member.uuid}`}>{truncateText(invitedToSpaceflow, 20)}</span>;
    };

    const rows = useMemo((): IRow[] => {
        return pagination.results.map((member) => ({
            data: [
                member.email,
                member.first_name ? `${member.first_name} ${member.last_name}` : "-",
                getSubscriptionIds(member),
                getCommunities(member),
                getCompanies(member),
                getActivatedInSpaceflow(member),
                getMemberStatusText(member, true),
                getDeactivatedInSpaceflow(member),
            ],
        }));
    }, [pagination.results]);

    return (
        <TableComponent
            headCells={headCells()}
            isLoading={pagination.query.isFetching}
            skeletonVariant="options"
            pagination={pagination}
            rows={rows}
        />
    );
};
