import React, { FC, useContext } from "react";

import { ModalContext } from "contexts/modals";

import SubscriptionsContext from "contexts/subscriptions";

import HasPermission from "components/__helpers__/hasPermission";
import SearchField from "components/tableComponents/paginationSearchField";
import { SubscriptionMembersTable } from "components/tableComponents/subscriptionMembersTable";
import TopBar from "components/tableComponents/topBar";
import VKButton from "components/vkButton";
import { usePaginationStore } from "utils/usePaginationStore";
import { AddMember } from "./addMember";
import { LocalizeText } from "components/localizer";
import { ISubscription } from "reducers/subscriptions";

interface IProps {
    subscription: ISubscription;
    setSubscription: (data: ISubscription) => void;
}

export const MemberInfoColumn: FC<IProps> = ({ subscription, setSubscription }) => {
    const { fetchSubscription } = useContext(SubscriptionsContext);
    const { openDrawer } = useContext(ModalContext);

    const associationPagination = usePaginationStore("member-subscription-associations", undefined, {
        subscription_uuid: subscription.uuid,
    });

    return (
        <>
            <TopBar
                searchField={<SearchField pagination={associationPagination} tag="searchMembers" />}
                singleItem={
                    <HasPermission requiredPerm="add_membersubscriptionassociation">
                        <VKButton
                            size="small"
                            variant="outlined"
                            sx={{ width: "max-content" }}
                            onClick={() =>
                                openDrawer(
                                    AddMember,
                                    {
                                        subscription,
                                        pagination: associationPagination,
                                        updateSubscription: async () => {
                                            setSubscription(await fetchSubscription(subscription.uuid));
                                        },
                                    },
                                    "addMember"
                                )
                            }
                            disabled={!subscription.can_add_associations}
                        >
                            <LocalizeText tag="addMember" />
                        </VKButton>
                    </HasPermission>
                }
            />
            <SubscriptionMembersTable
                pagination={associationPagination}
                subscription={subscription}
                updateSubscription={async () => {
                    setSubscription(await fetchSubscription(subscription.uuid));
                }}
            />
        </>
    );
};
