import {
    IBuilding,
    IBuildingImage,
    IBuildingResponsibility,
    IBuildingsText,
} from "reducers/realestates";

export const getCorrectBuildingImage = (building: IBuilding): IBuildingImage => {
    if (building.building_images.length) {
        const mainImage = building.building_images.find(
            (image) => image.type_value === "ExternalWebMainImage"
        );
        return mainImage ? mainImage : building.building_images[0];
    }
    return { url: "" } as IBuildingImage;
};

export const getContactPerson = (building: IBuilding, isArenaBuildings=false): IBuildingResponsibility => {
    if (building.building_responsibility.length) {
        if (building.is_arena && isArenaBuildings) {
            const propertyManager = building.building_responsibility.find(
                (responsible) => responsible.role_value === "ArenaManager"
            );
            if (propertyManager) {
                return propertyManager;
            }
        }
        const responsible = building.building_responsibility.find(
            (responsible) =>
                responsible.agent_person &&
                responsible.agent_person.full_name === building.manager_agent
        );
        return responsible ? responsible : building.building_responsibility.sort((br1: IBuildingResponsibility, br2:IBuildingResponsibility) => {
            return br1.sort_order > br2.sort_order ? 1 : -1;
        })[0];
    }
    return {} as IBuildingResponsibility;
};

export const sortBuildingTexts = (buildingTexts: IBuildingsText[], parent_type_value: string): IBuildingsText[] => {
    return buildingTexts.filter(t => t.parent_type_value === parent_type_value).sort((textA: IBuildingsText, textB: IBuildingsText) => {
        return textA.sort_order > textB.sort_order ? 1 : -1;
    });
};
