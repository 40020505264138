import { TLanguageDefinition } from "contexts/language";

export const en: TLanguageDefinition = {
    langSelector: "På svenska",
    login: "Log in",
    forgotPassword: "Forgot password",
    forgotPasswordTitle: "Reset password",
    forgotPasswordHelpText:
        "Enter your registered e-mail address and we'll send you instructions for how to reset your password.",
    alreadyHaveAccount: "I already have an account. Log in",
    vkkMangerTitle:
        "Welcome to our Customer Portal (Kundportalen). An administrative tool for our Vasakronan customers",
    needHelp: "Do you need help?",
    send: "Send",
    save: "Save",
    buy: "Purchase",
    proceed: "Next",
    continue: "Continue",
    confirm: "Confirm",
    ok: "OK",
    next: "Next",
    back: "Back",
    cancel: "Cancel",
    terminate: "Close",
    "2fa": "Two-factor authentication (2FA)",
    add2FA: "Add a 2FA option",
    add2FASubtitle: [
        "You'll need to verify your identity for future logins to the Customer Portal (Kundportalen). ",
        "Choose one of the following verification methods.",
    ],
    authenticationApp: "Authenticator app",
    authenticationAppInfo:
        "Open the authenticator app during login to send your verification code to the Customer Portal (Kundportalen).",
    fastest: "Fastest",
    wifi: "Wifi",
    sms: "SMS",
    smsInfo: "An SMS with a verification code is sent to your registered phone number when you log in.",
    requiresPhoneAndReception: "Requires phone and reception",
    install: "Installation",
    installApp: "Install an authenticator app",
    installAppInfo: [
        "Below are popular authenticator apps. For easy logins ",
        "install the app on a device you always have access to. ",
    ],
    register: "Registration",
    registerApp: "Registration in authenticator app",
    registerAppInfo: [
        "The authenticator app has a scanning function. Please note! ",
        "You can't use your phone's built-in QR scanner or another scanner app besides the authenticator app. ",
    ],
    registerAppBottom:
        " After the above code is scanned, you'll see verification codes, with new ones generated regularly.",
    verify: "Verification",
    verifyAppRegistration: "Verify registration",
    verifyAppRegistrationInfo: [
        "Enter the verification code displayed in the authenticator app into the Customer Portal (Kundportalen). ",
        "If it changes before you finish, try again with the next code.",
    ],
    registerMobile: "Register your phone number",
    registerMobileInfo: "Enter the phone number you'd like to use to log in to the Customer Portal (Kundportalen).",
    verifyMobile: "Verify your phone number",
    verifyMobileInfo: "We've sent an SMS with a verification code. Enter the code below.",
    backup: "Backup",
    backupWithCodes: "One-time passcode (OTP) for backups",
    backupWithCodesInfo: [
        "Below are OTPs that can be used instead of an authenticator app or SMS. ",
        "Save and keep them in a safe place. If you run out of OTPs you can generate new ones ",
        "on your profile page in the Customer Portal (Kundportalen).  Note! All of the old OTPs will stop working when new ones are generated.",
    ],
    appLoginVerify: "Enter the code displayed in your authenticator app",
    smsLoginVerify: "Enter the code from the SMS sent to the number #number",
    verifyWithSms: "Verify with SMS instead",
    verifyWithApp: "Verify with an app instead",
    canUseOneTimeCode: "(Can use backup/OTP)",
    choosePasswordDescription: "Choose a password that meets the following criteria:",
    passwordCriteria1: "Has at least 8 characters (letters and numbers)",
    passwordCriteria2: "Avoid typical passwords such as 1234abcd, password123",
    passwordCriteria3:
        "Do not use a password that relates to your personal information, i.e. your name, username, social security number, initials ",
    passwordDeadline: "OBS! You have one hour from when you recived the mail to change the password.",
    passwordChangeFail:
        "Unfortunately, the password is not accepted. Please note that the new password must meet the criteria above and be chosen within one hour after receiving the email to change the password. Please try again.",
    close: "Close",
    clear: "Clear",
    firstName: "First name",
    lastName: "Last name",
    email: "E-mail",
    sendEmail: "Send welcome email Arena from Vasakronan",
    logout: "Log out",
    lease: "Rental contract",
    allLeases: "All rental contracts",
    tasks: "Tasks",
    includeCompletedTasks: "Inkl completed tasks",
    invoicePeriod: "Payment period",
    invoiceArena: "Offices",
    billingInformation: "Invoice details",
    invoiceSpecification: "Invoice specification",
    city: "City",
    arena: "Arena",
    arenas: "Arenas",
    mainArena: "Main arena",
    showOtherArenas: "Show other arenas",
    arenaOffice: "Arena offices",
    cancelArena: "Terminate rental contract",
    noUserResults: "No users found",
    addArenaContactHelpText: [
        "Enter the e-mail addresses for the users with memberships who will be using the Arena office. Users will be given a digital key ",
        "to the Arena office and an e-mail with instructions.  The Arena office has its own secure network. ",
        "Users will also receive an invitation to Netgraph’s service portal for administration of network devices.",
    ],
    selectEndDateArena: "Enter the termination date for the Arena office.",
    nationalAccess: "Nationwide supplement",
    startDate: "Start date",
    endDate: "End date",
    user: "User",
    userLower: "user",
    addUser: "Add user",
    userSubscriptions: "Membership agreement",
    addUserHelpText: [
        "Enter the e-mail address for the user who has access to the membership. This user will be given a digital key to Arena office ",
        "and an e-mail with instructions as well as a link to Vasakronan's app.",
    ],
    changeUser: "Change user",
    changeUserHelpTextDate: "Enter the date when the user will change.",
    changeUserHelpTextEmail: "Enter the e-mail address for the user who will be given access to the membership.",
    changeUserHelpTextInstant:
        "When the subscription has a forthcoming start date, the user change will be in effect immediately",
    showInformation: "Show information",
    showExistingContactPerson: "Show existing contract representative",
    yes: "Yes",
    no: "No",
    information: "Information",
    currentUser: "Current user",
    nextUser: "Next user",
    remove: "Remove",
    status: "Status",
    noUser: "No active users",
    noUsers: "No active users",
    subscriptionInformation: "Subscription\u00ADinformation",
    editFinish: "Edit finish",
    editFinishDescription: "Are you sure? The membership will be removed on the date you selected.",
    selectEndDateSubscription: "Enter the date of the membership's termination.",
    removeFromSpaceflowInfo: "When you terminate the subscription, the user is automatically removed from Spaceflow.",
    earliestSubscriptionEndDate: "Earliest end date (based on binding, trial and notice period)",
    warningEarlierThanOfficial: "You are about to put an earlier end date than the above calculated one.",
    subscriptionCancelled: "Subscription cancelled",
    includeInactiveSubscriptions: "Incl. terminated subscriptions",
    includeTerminatedMemberships: "Incl. terminated memberships",
    includeArchivedCustomers: "Incl. archived customers",
    removeUser: "Remove user",
    removeStaff: "Remove staff",
    more: "More",
    requestNewSubscriptions: "Apply for membership",
    company: "Company",
    addNationalAccess: "Add nationwide supplement",
    cancelNationalAccess: "Remove nationwide supplement",
    removeSure: "Are you sure?",
    selectStartDateNationalAccess: "Nationwide supplement",
    selectNationalAccessHelpText: [
        "The nationwide supplement applies to the membership for all of Vasakronan's Arenas. Users can switch ",
        "between Arenas in the Vasakronan app. Provide the activation date for the supplement.",
    ],
    selectEndDateNationalAccess: "Choose the end date for the supplement",
    nationalAccessEndsDescription: [
        "If the User has bookings that occur in other Arenas after the end date of the national supplement, these will remain and will be charged. ",
        "Ensure that the User has cancelled all purchases that should not remain on other Arenas ",
        "after the end date of the national access before removing the national access.",
    ],
    cantBuyNationalAccess: "No supplements may be purchased for this membership!",
    noNationalAccess: "None",
    edit: "Change",
    add: "Add",
    customerService: "Customer Service",
    contacts: "Customer Service",
    contactUs: "Contact us",
    privacyPolicy: "Privacy policy",
    arenaOffices: "Arena offices",
    arenaCategory: "Arena contracts",
    subscriptionCategory: "Subscriptions",
    realestatesCategory: "Properties",
    leasesCategory: "Rental contract",
    tenantCategory: "Tenant",
    arenaMember: "Arena member",
    dayPassesCategory: "Visitor passes",
    dashboardCategory: "Start page",
    name: "Name",
    invitations: "Invitations",
    contactPersons: "Contract representatives",
    contactPersonHelpText: [
        "A contract representative is a contact person who represents the tenant and who has the right to administrate and act on behalf of the tenant ",
        "for contract-related issues.  The contract representative may appoint a replacement who has all of the same representative rights, ",
        "including the ability to appoint more contract representatives. ",
        "\n\nThe contract representative has the right to act on all agreements connected to this contract.",
    ],
    contactPersonInfo: "Contract representative information",
    editInformation: "Change information",
    addContactPerson: "Add information",
    addCommunityStaff: "Add Arena staff",
    removeContactPerson: "Remove contract representative",
    removeContactPersonDescription:
        "Do you want to delete the user? This means that they will not be able to manage this agreement.",
    perMonth: "/month",
    total: "Total",
    paymentMethodPDF: "PDF invoice",
    invoiceAddress: "Invoicing address",
    invoiceReference: "Invoice reference",
    addressLine1: "Address line 1",
    addressLine2: "Address line 2",
    zipCode: "Postcode",
    paymentMethodPeppol: "E-invoice (PEPPOL)",
    peppolID: "PEPPOL ID",
    errorPeppol: "GLN-ID (0088:XXXXXXXXXXXXX) or PEPPOL-ID (0007:XXXXXXXXXX)",
    town: "Town",
    number: "Number",
    phone: "Telephone",
    phoneNumber: "Phone number",
    mobileNumber: "Mobile number",
    mobileNumberHelperText: "+46XXXXXXXXX",
    invoice: "Invoice",
    invoices: "Invoices",
    invoiceExpiringDate: "Due date",
    lastPayDate: "Last payment date",
    invoiceHistory: "Invoiced",
    currentAnnualAmount: "Current annual amount",
    office: "Arena offices",
    officeSpaces: "Office capacity",
    membership: "Membership agreement",
    memberships: "Membership agreements",
    externalMembership: "External customer",
    externalMemberships: "External customers",
    subscriptionNumber: "Membership number",
    subscriptionNumberShort: "Membership nr.",
    subscription: "Subscription",
    subscriptions: "Subscriptions",
    product: "Product",
    products: "Products",
    organizationInvoice: "Company invoice",
    price: "Price",
    priceExclVat: "Price excl. VAT",
    priceAmount: "Sum",
    errorEmail: "Invalid email address format",
    errorDate: "Invalid date format",
    errorMinDate: "Date should not be before minimal date",
    errorMaxDate: "Date should not be after maximum date",
    errorFutureAdditional: "The date cannot be empty when an additional user exists in the future",
    errorActivatsDeactivatesDate: "Inactivation date should not be before activation date",
    removeInvitation: "Remove invitation",
    showInvitation: "Show invitation",
    sendInvitation: "Send invitation",
    resendInvitation: "Resend invitation email",
    sendInvitationInactive: "The community must be active before sending the invitation",
    removeArenaMailPreview: [
        "Terminating rental contract due to move",
        "\n\n",
        "#org hereby cancels rental contract number #agreementNr for Arena office ",
        "#arenaOfficeName at #arena in #city due to a move, with an end date of #endDate.",
        "\n\n",
        "#cancelCity #cancelDate",
        "\n#org",
        "\nthrough \n #contactPerson",
    ],
    tooShortPassword: "Password must be at least 8 characters",
    notOnlyNumbers: "Password cannot be only numbers",
    passwordsDontMatch: "Passwords don't match",
    removeSubscription: "Remove membership",
    expired: "Expired",
    activates: "Activates",
    activation: "Activation",
    invitedToSpaceflow: "Invitation to Spaceflow",
    statusInSpaceflow: "Status in Spaceflow",
    terminatedInSpaceflow: "Terminates i Spaceflow",
    removingUser: "The user will be removed",
    changingUser: "Change user",
    weekly: "per week",
    monthly: "month",
    paid: "Paid",
    openContract: "Show contract",
    contractNumber: "Contract number",
    customerNumber: "Customer number",
    customerNumberShort: "Customer nr.",
    passwordSuccess: "Your password has been changed.",
    amount: "Contracts",
    frequency: "Frequency",
    contractperiod: "Contract length",
    supplier: "Suppliers",
    manager: "Manager",
    object: "Object",
    building: "Property",
    contract: "Contract",
    pendingContactPerson: "Waiting for the contract representative to accept the invitation",
    errorMobile: "The number can only be 6 to 15 digits and must start with a valid country code",
    from: "From",
    fromAlt: "From",
    until: "Until",
    contractDuration: "Contract duration",
    validUntilCapital: "Applies until",
    validUntilCapitalShort: "Until",
    validUntil: "applies until",
    paymentMethod: "Payment method",
    password: "Password",
    currentPassword: "Current password",
    newPassword: "Your new password",
    newPasswordAgain: "Your new password again",
    changePassword: "Change password",
    editProfile: "Edit contact information",
    administrate: "Administer",
    dayPass: "Visitor passes",
    dayPasses: "Visitor passes",
    date: "Date",
    ordered: "Ordered",
    contactPerson: "Contract representatives",
    orderNumber: "Order number",
    reference: "Reference",
    discount: "Discount",
    discountOffer: "Discount offer",
    discountOffers: "Discount offers",
    cost: "Cost",
    rentPerMonth: "Rent/month",
    fee: "Fee",
    feeType: "Fee type",
    outstandingCustomerLedger: "Outstanding customer ledgers",
    debitAdress: "Debit adress",
    debitPeriod: "Debit period",
    debitPeriodFrom: "Debit period from",
    debitPeriodTo: "Debit period to",
    leasePeriod: "Lease period",
    realestateAndContractTitle: "Property designation and address",
    debitAmount: "Debit amount",
    feeSpecification: "Specification",
    ofWhichIndex: "Of which indexed",
    baseAmount: "Base amount",
    baseMonth: "Base month",
    baseValue: "Base value",
    enumerationMonth: "Enumeration month",
    enumeratedValue: "Enumerated value",
    vat: "VAT",
    vatAmount: "VAT amount (#percentage)",
    leaseExpandHelpText: "Please contact contract responsible for questions concerning the lease",
    amountSubjectToVAT: "Amount subject to VAT",
    printOrSave: "Print/Save",
    currentDebits: "Current debits",
    earlierCustomerLedgers: "Earlier customer ledgers",
    allCustomerLedgers: "All customer ledgers",
    perSubscriptionAndMonth: "/subscription and calendar month",
    untilFurtherNotice: "Until further notice",
    untilFurtherNoticeLower: "until further notice",
    rentsArenaOffice: "as long as the member rents an Arena office",
    emailDaypassUser: "Visitor e-mail address",
    book: "Book",
    tac: " General terms and conditions for membership agreements",
    dueDate: "Due date",
    unpaid: "Unpaid",
    overdueUnpaid: "Past due",
    bindingPeriod: "Binding period",
    subscriptionPeriod: "Subscription period",
    subscriptionFee: "Ordinary subscription fee/month",
    rentalPeriod: "Rental period",
    activatesDate: "Activation date",
    moveInDate: "Occupancy date",
    SEK: "kr",
    excludingVat: "excl. VAT",
    includingVat: "incl. VAT",
    totalIncludingVat: "Total incl. VAT",
    totalExcludingVat: "Total excl. VAT",
    community: "Community",
    nationalCommunities: "Arenas",
    productAndCommunity: "Product and Community",
    property: "Property",
    managerAgent: "Contract responsible",
    userAlreadyExist: "User already exists.",
    noDataAvailable: "No data is currently available.",
    tasksLoading: "Just submitted a task? As soon as the case is received, you can see it in the table below!",
    noOutstandingCustomerLedgers: "No outstanding customer ledgers",
    buildings: "Buildings",
    address: "Address",
    propertyManager: "Property Manager",
    squareMeters: "Sq. m.",
    commonName: "Common name",
    noContactPersons: "No contract representative. Contact Vasakronan.",
    contactVasakronan: "Contact Vasakronan",
    host: "Host",
    quantity: "Quantity",
    purchase: "Purchase",
    unitPrice: "Unit price",
    pendingEmail: "Sent invitations",
    show: "Show",
    showLease: "Show rental contract",
    showSubscription: "Show subscription",
    showArenaOffice: "Show Arena offices",
    showMembership: "Show membership",
    showExternalMembership: "Show external customer",
    showSergelMembership: "Show Sergel conference customer",
    showMembershipAndCustomerDetails: "Show membership agreement and customer details",
    showPdf: "Show PDF",
    noPdfUrl: "PDF-invoice is being created",
    removePendingUserDescription: "The new user has not accepted the invitation and will be removed immediately.",
    noRealEstateInfoFound: "No information available about the property.",
    realestateInformation: "Property information",
    noData: "No data",
    activePlural: "Active",
    active: "Active",
    history: "History",
    invoiceQuestions: "Invoice questions?",
    noCommunities: "No communities to choose from",
    noHosts: "No hosts to choose from",
    requestNewArenas: "Apply for an Arena office",
    noToken: "This link is no longer valid.",
    customerLedgers: "Customer ledgers",
    ocr: "Invoice no./OCR",
    paidAmount: "Amount paid",
    usedAmount: "Used amount",
    remainingAmount: "Amount remaining",
    overdueAmount: "Amount due",
    rent: "Rent",
    rentPerSquareMeter: "Rent per sq. m.",
    reservation: "Reservation",
    comment: "Comments",
    comments: "Comments",
    commentOptional: "Comments (voluntary)",
    legalCompanyName: "Legal name of the organization",
    commercialCompanyName: "Commercial name of the organization",
    commercialCompanyNameShort: "Commercial Org name",
    orgNumber: "Corp. Reg. No.",
    orgNumberShort: "Corp. Reg. No",
    branchCode: "Industry",
    dayPassPaymentInfo: "The cost of the visitor pass will be included in the company invoice.",
    in: "In:",
    out: "Out:",
    trialPeriod: "Trial period",
    trialPeriodShort: "Trial period",
    free: "free of charge",
    free2: "Free",
    userBeingRemoved: "A new user can access the membership as of",
    noSubscriptionLeftInfo: [
        "There are no subscriptions connected to this membership agreement. It's easy to apply for a new subscription if you ",
        "want to through the Customer Portal (Kundportalen). The membership agreement remain dormant for one year from last ",
        "subscription ends, after which it will be terminated automatically. To terminate the membership agreement immediately, ",
        "contact Vasakronan (see Customer Service for contact information). ",
    ],
    search: "Search",
    searchMemberships: "Search membership agreements",
    searchExternalMemberships: "Search external customers",
    searchSergelMemberships: "Search Sergel customers",
    searchCommunityStaff: "Search among Arena Staff",
    searchRepresentatives: "Search representatives",
    searchUser: "Search user",
    searchInvites: "Search invitations",
    searchLease: "Search lease",
    searchCustomerLedgers: "Search customer ledgers",
    searchSubscriptions: "Search subscriptions",
    searchRepresentative: "Search representative",
    searchProducts: "Search products",
    searchInvoiceOrders: "Search invoices",
    searchMembers: "Search members",
    download: "Download",
    downloadEmails: "Download email addresses",
    bannerMemberships: [
        "Here you can see your membership agreements and customer details. ",
        "View and manage a membership, read current General Terms, and more.",
    ],
    bannerExternalMemberships: "Here you can see external customers and their details.",
    bannerSergel: "Here you can see customers at Sergel conference and their details. ",
    bannerSubscriptions: [
        "Here you can see all the subscriptions of your membership agreement.​",
        "\n\nTo apply for a new subscription, click on ”More”.​",
    ],
    bannerStaffSubscriptions: "Here you can see information about all subscriptions.",
    bannerArenaOffices: [
        "Here you can apply for an office at the Arena, click on ”More” to go to the application.",
        "\n\nYou will also get an overview of your Arena office agreements. ",
        "Under the three dots you can see more information about the office and also cancel it",
    ],
    bannerDayPasses:
        "Here you get an overview of your upcoming and past visitor passes and can easily book new visitor passes by clicking on ”More”.",
    bannerInvoices: [
        "Here you can find a summary of your business invoice purchases for the current month and access your future and historical invoices.",
        "\n\nUnder ”More” you can manage your billing details, change your payment method and see your current purchase limit.",
    ],
    bannerContactPersons: [
        "Here you can see the contracts your organization has with Vasakronan. ",
        "As a contract representative, you can apply for access to more contracts ",
        "within your organization.",
    ],
    bannerRepresentatives: "Here you can see the agreements you represent in your organization.",
    bannerCommunityStaff: "Here you can see all the staff for the arenas you have access to.",
    bannerCostCalculations: "Here you can see all generated cost calculations.",
    bannerCustomerService: "Contact information for Vasakronan's Customer Service is available here.",
    bannerRealestates:
        "Here you will find the most requested information and also your personal contacts for each property.​",
    bannerRealestatesTitle: "Property information",
    bannerRepresentativesList: "Here you can see all representatives in Customer Portal (Kundportalen)",
    bannerArenaInformation: "Here you will find information and contact persons for Vasakronans Arena offices.​",
    bannerArenaInformationTitle: "Arena information​",
    bannerCostCalculationsTitle: "Cost calculations",
    bannerLeases:
        "Here you can see all the rental contracts for the selected property as well as detailed information about your rental invoices.​",
    bannerCustomerLedgers: "Display an overview of all of your customer ledgers.",
    bannerTasks: [
        "Here you can see all reported tasks related to your properties. You can easily create a new task by clicking on ”Create task”.",
        "\n\nAll tasks within your organization and realestate-wide tasks are available.",
    ],
    bannerServices: [
        "Here you can find more information about our products and services.\n",
        "We offer several service offers on our properties and ",
        "gladly use our knowledge to help simplify your everyday life at the office.",
        "Read more here or contact your business associate for more ",
        "information regarding which services are available in your building.",
    ],
    bannerProducts: "Here you can see all products for all the arenas you have access to.",
    bannerInvoiceEvents: "Here you can see all invoice events for the arenas you have access too.",
    bannerMembers: "Here you can see all persons that have been added to your subscriptions.",
    bannerStaffMembers: "Here you will find information about all users connected to Subscriptions.",
    bannerNews: "Here you can see all news associated with your premises and affected buildings.",
    profile: "Profile",
    contactInformation: "Contact information",
    "2faRequired": " You need to have at least one 2FA method registered, preferably both.",
    methodAppInfo: [
        "Devices registered: #count \n",
        "A login code was sent to the authenticator app on your phone. Up to 3 different devices can be registered. We recommend this authentication method.",
    ],
    addMethodApp: "Add app verification",
    editAppDevices: "Edit devices",
    totpDevices: [
        "This is your registered device for app verification. You can have up to 3 of these simultaneously. ",
        "Changing name is only for your own use. The last device can only be removed if the SMS method is activated.",
    ],
    removeAppDevice: "Remove device",
    addDevice: "Add device",
    addDeviceInfo: "Enter the name of the new device.",
    methodSMSInfo: [
        "Registered number: #number \n",
        "An SMS with a login code has been sent to the number you provided. Only one telephone number can be registered at a time. ",
        "If you would like to change the telephone number used for 2FA via SMS, you need to first use the button on the right to remove the SMS verification method.  ",
        "Then add SMS verification again via the same button and enter your new telephone number.",
        "\n \n",
        "If you’ve already registered both authentication methods (authenticator app and SMS verification), the authenticator app will be the preferred login choice.",
    ],
    addMethodSMS: "Add SMS verification",
    removeMethodSMS: "Remove SMS verification",
    updateMethodSMS: "Change SMS number",
    methodBackupCodesInfo: [
        "OTPs remaining: #remaining \n",
        "OTPs can be used instead of an authenticator app or SMS. The code is used in the verification field when logging into the Customer Portal (Kundportalen). ",
        "OTPs should be stored physically in a secure place. If you run out of OTPs you can generate new ones on your profile page in the Customer Portal (Kundportalen). ",
        "Note! All of the old OTPs will stop working when new ones are generated.",
    ],
    showBackupCodes: "Display OTPs",
    backupCodes: "OTPs",
    backupCodesInfo:
        "Below are your remaining OTPs. You can either click on them individually or all at once to copy and paste them.",
    generateCodesWarning:
        " You can generate new codes whenever you like, but remember that this will invalidate all of your remaining codes.",
    generateCodes: "Generate new codes",
    dashboardTitle: "Hi #firstName #lastName!",
    dashboardBreadcrumbs: [
        "Welcome to our customer portal - a convenient and easy-to-use administrative tool for our Vasakronan customers.",
        "Here you can for instance see your lease and membership contracts, review your invoices and administer your contract representatives here",
        "Here we handle personal data in accordance with GDPR, more information can be found at ",
    ],
    teaserArenaMember:
        "You can become Arena member by applying for a membership. Further information about the application here:",
    dialogTitleArenaMember: "Apply for Arena member",
    teaserTenant: "Are you interested in renting a premise from us? Check out our offering of premises:",
    dialogTitleTenant: "Apply for tenant",
    availablePremises: "Available premises",
    arenaCoworking: "Arena coworking",
    news: "News",
    showAll: "Show all",
    articleFooterCreated: "Created: #timestamp",
    articleFooterLastUpdated: "Last updated: #timestamp",
    IENotSupported: " Internet Explorer is not supported. We recommend Chrome, Firefox, Safari or Edge.",
    category: "Category",
    description: "Description",
    reportedBy: "Reported by",
    reportedByAtDate: "Reported by #name on #date at #time",
    lastModified: "Last modified",
    realEstate: "Real estate",
    taskNumber: "Errand no.",
    myTasks: "Mine",
    allTasksInOrg: "My organization",
    buildingTasks: "Realestates common",
    createTask: "Create new task",
    createTaskAction: "Add new comment",
    currentOrganization: "Current organization",
    generalError:
        "An unexpected error occured, Please try again later. If the problem remains, please contact Vasakronan's customer service.",
    generalErrorTitle: "An error occured",
    developerDialogTitle: "Error message for testers & developers",
    developerCopyError: "Copy error message",
    goToStartPage: "Click here to go to the start page",
    pageNotFoundTitle: "404 - Page not found",
    pageNotFoundDetail:
        "The page you are looking for could not be found. This may be due to a typo, that the page has been moved or that it is no longer available.",
    pageNotImplementTitle: "501 - Page not implemented",
    pageNotmplementDetail: "The page you are looking for could is not implemented. Please try again later",
    addresses: "Addresses",
    builtAndRebuilt: "Built/rebuilt",
    environmentalCertification: "Environmental certification",
    buildingOffice: "Office",
    buildingStore: "Store",
    architect: "Architect",
    buildingFacts: "Facts about the property",
    howToInProperty: "How we do it in the property",
    buildingContactPerson: "Contact person",
    buildingContactPersons: "Contact persons",
    loadMoreResults: "Load more results",
    showPremiseContractAgreement: "Show premise contract agreement",
    arenaInformation: "Arena information",
    resetPasswordTitle: "Reset your password to log in to the Customer Portal (Kundportalen)",
    resetPasswordExpiredLinkTitle: "The link to reset your password is too old",
    resetPasswordExpiredLinkText:
        "Unfortunately, this link is too old to use. Go to the login page and select “forgot password” and a new link will be sent to you. ",
    unsubscribeFromNewsByMailTitle: "Unsubscribe from newsletter?",
    confirmUnsubscribeFromNews: "Please confirm that you wish to unsubscribe from the newsletter",
    newsletter: "Newsletter",
    newsletterYes: "Yes, I want to subscribe to the newsletter",
    goToLoginPage: "Click here to log in instead",
    leaseInvitationAccepted: "#user now has access to the lease with agreement number: #agreementNumber.",
    leaseInvitationConfirm: "Confirm request for access to lease.",
    requestAccess: "Request access",
    accessToLeaseAgreement: "Request access to lease agreement",
    sendRequest: "Send request",
    requestAccessToLeaseHelpText:
        "An email will be sent to the contact person with a request of granting you access to the lease agreement.",
    clickToReadMore: "Click to read more",
    showMoreCustomerLedgers: "Show more customer ledgers",
    noAccessToLease:
        "You do not have access to manage this rental contract. You can request access by clicking on the three dots located in the right hand corner of the row",
    activeLease: "The rental contract is active and has one or more contact persons linked to it.",
    sumOfAboveRows: "Sum of the above lines:",
    pageOfTotal: "Page #page of #total",
    taskTypeSelect: "Which type of task?",
    describeTask: "Describe the task",
    describeTaskExplanation:
        "Please avoid sensitive personal information (meaning ethnical background, political views, religious or philosophical beliefs, membership in a union, health, a person's sex life or sexual orientation) in this textfield.",
    newActionDescription: "Add a comment..",
    premise: "Premise",
    whichAddress: "Which address does the task concern?",
    numberOfMaxChars: "#number of #max characters",
    taskCreated: "Task created",
    taskActionCreated: "Comment submitted",
    taskCreatedDescription:
        "Thank you for contacting us. We are working to get your task resolved as quickly as possible. After the task has been received, you can view it under 'My tasks'",
    taskActionCreatedDescription:
        "Thank you for your comment. We are working to get your task resolved as quickly as possible. After the comment has been received, you can see it under 'My tasks'.",
    invoiceDate: "Invoice date",
    timeReported: "Date reported",
    sortDateAsc: "Oldest first",
    sortDateDesc: "Newest first",
    invoiceNumber: "Invoice number",
    invoiceOCR: "OCR",
    bankgiroNumber: "Bankgiro",
    openFile: "Open file",
    readMore: "Read more",
    readMoreHere: "Read more here",
    generalNews: "General news",
    arenaInfo: "Info about Arena",
    appendices: "Appendices",
    contactDuringPeriod: "Current contract period",
    to: "to",
    invoiceIsSentTo: "Invoice is sent to",
    myContracts: "My contracts",
    requests: "Requests",
    representativesWithinOrganization: "Users within the organization",
    contractsWithinOrganization: "Other contracts within the organization",
    organization: "Organization",
    role: "Role",
    requestedBy: "Requested by",
    handleRequest: "Manage request",
    handleRequestTitle: "Manage the request for access to rental agreements.",
    acceptDescription: " the request to provide access to the agreement.",
    denyDescription: " the request to not give access to the agreement.",
    handleAction: "Handle",
    showRepresentatives: "Show users",
    addNewRepresentative: "Add new user",
    editRepresentative: "Edit user",
    editCommunityStaffUser: "Edit user",
    accept: "Accept",
    deny: "Deny",
    services: "Services and information",
    representative: "Representative",
    leasesDescription:
        "As a representative, you can apply for permission to manage more agreements within your organization by clicking on the three dots",
    noPremiseContract: "The agreement is not connected to a premise contract agreement",
    addNewRepresentativeHelperText: [
        "Add an additional representative within your organization and set the permission level. ",
        "The representative is then assigned the right to administer matters on behalf of the organization.",
    ],
    addNewCommunityStaffHelperText:
        "Under Main Arena, you select the Arena the person should work on. Under Show other Arenas, you select the additional Arenas the person should have access to in the Kundportalen.",
    addNewCommunityStaffHelperText2:
        "When you click Save, an invitation is sent to the person's email for registration.",
    leaseInvoiceQuestion: "Invoice inquiry",
    invoiceQuestionHelpText: [
        "Please state your question and give as much detail as possible about what your question is about; ",
        "e.g. invoice number, current fees or similar.",
    ],
    invoiceQuestionWithNumberHelpText:
        "Please state your question and give as much detail as possible about what your question is about.",
    changeCustomerLedger: "Change of billing address",
    changeCustomerLedgerHelpText: "Specify if you want to change your billing address or billing method",
    leaseContractQuestion: "Contract question",
    leaseContractQuestionHelpText: [
        "Please state your question and give as much detail as possible about what your question is about; ",
        "e.g. rental period, current fees or similar",
    ],
    generalQuestionContract: "Contact my contract manager",
    generalInvoiceQuestion: "General invoice question",
    indexInvoiceQuestion: "Question about index",
    errorReport: "Error report",
    describeQuestion: "Describe your question in as much detail as possible",
    questionTypeSelect: "Select type of question",
    questionSubTypeSelect: "Please indicate which of the following applies to your question:",
    attachmentUpload: "Attach attachments",
    recipient: "Recipient",
    noFileSelected: "No file selected",
    noFilesSelected: "No files selected",
    selectLease: "Please select a lease",
    showInvoice: "Show invoice",
    invoiceBasis: "Invoice basis",
    showInvoiceBasis: "Show invoice basis",
    sendCopyToMail: "Send copy to my e-mail address",
    questionsAboutTheInvoice: "Questions about your invoice?",
    recentlyAddedLease: "The agreement was recently added to Kundportalen",
    notEnoughSubscriptionsError:
        "The same number of subscriptions is required as there are office spaces linked to the membership. First cancel an office or try another date.",
    notEnoughSubscriptionsTrialError:
        "The same number of subscriptions is required as there are office spaces linked to the membership. First cancel an office.",
    communityStaff: "Arena staff",
    customerPortalAsRepresentative: "Kundportalen Representative",
    showAsRepresentative: "View as representative",
    terminateMembershipAgreement: "Terminate membership agreement",
    inactivateMembership: "Disable customer",
    activateMembership: "Enable customer",
    showAsCommunityStaff: "View as Arena staff",
    communitiesAndRoles: "Arenas and roles",
    created: "Created",
    id: "ID",
    offset: "Offset",
    invoiceDateChanged: "Invoice date changed",
    numberOfSubscriptions: "Number of subscriptions",
    offsetExplaination:
        "Controls how many periods in the future the calculation should include. Select 0 to show only the current month.",
    newCostCalculation: "New cost calculation",
    createAndDownload: "Save and download",
    referencePerson: "Contact person",
    createNew: "Create new",
    createNewExternalMembership: "Create new external customer",
    createNewSergelMembership: "Create new Sergel customer",
    freeTextInternal: "Free form text (internal)",
    paymentInformation: "Payment information",
    errorEmptyFields: "Some required fields are empty or incorrectly filled out",
    sergel: "Sergel conference",
    amountDueIncludingVat: "Amount due incl. VAT",
    toReceiveIncludingVat: "To receive excl. VAT",
    createdFrom: "Created from",
    createdBy: "Created by",
    invoiceEvent: "Invoice\u00ADevent",
    invoiceEvents: "Invoice\u00ADevents",
    invoiceOrders: "Invoice orders",
    period: "Period",
    periods: "Periods",
    ourReference: "Our reference",
    automaticRun: "Automatic run",
    type: "Type",
    paymentStatusUNPAID: "Unpaid",
    paymentStatusRESERVED: "Reserved",
    paymentStatusPARTIALLY_PAID: "Partially paid",
    paymentStatusPAID: "Paid",
    paymentStatusUNUSED: "Unused",
    paymentStatusPARTIALLY_USED: "Partially unused",
    paymentStatusUSED: "Used",
    orderStatus_created: "Order created internally",
    orderStatus_order_sent: "Order created in Payer",
    orderStatus_order_failed: "Invoice failed to be created in Payer",
    orderStatus_order_approved: "Order approved in Payer",
    orderStatus_order_internal_failed: "Order created in payer but an internal error was raised",
    orderStatus_order_zero: "Order is not sent to Payer since amount is 0",
    orderStatus_order_partially_credited: "Order partially credited",
    orderStatus_order_entirely_credited: "Order fully credited",
    orderStatus_order_not_valid: "Invoice could not be created: Missing information required in the customer portal.",
    orderStatus_invoice_sent: "Invoice(s) created in Payer",
    orderStatus_invoice_internal_failed: "Invoice(s) created in payer but an internal error was raised",
    orderStatus_invoice_failed: "Invoice failed to be created in Payer",
    orderStatus_invoice_paid: "Invoice(s) fully paid",
    orderStatusShort_created: "Basic created",
    orderStatusShort_order_sent: "Order created",
    orderStatusShort_order_failed: "Order failed",
    orderStatusShort_order_approved: "Order approved",
    orderStatusShort_order_internal_failed: "Order issue",
    orderStatusShort_order_zero: "Zero order",
    orderStatusShort_order_partially_credited: "Partially credited",
    orderStatusShort_order_entirely_credited: "Crediterad",
    orderStatusShort_order_not_valid: "Invalid order",
    orderStatusShort_invoice_sent: "Invoice created",
    orderStatusShort_invoice_internal_failed: "Invoice issue",
    orderStatusShort_invoice_failed: "Invoice failed",
    orderStatusShort_invoice_paid: "Invoice paid",
    prepayment: "Prepaid",
    normal: "Normal",
    credit: "Credit",
    creditInvoice: "Credit invoice",
    downloadPdf: "Download PDF",
    paymentTerms: "Payment terms",
    invoiceBasisId: "Invoice basis ID",
    late: "Late",
    showProductInfo: "Show product information",
    productInfo: "Product information",
    createProduct: "Create new product",
    accounts: "Accounts",
    revenueAccount: "Revenue account",
    vatAccount: "VAT account",
    costCenter: "Cost center",
    noticePeriod: "Notice period",
    productKey: "Product key",
    errorAlreadyUsed: "Already used",
    unit: "Unit",
    hours: "Hours",
    days: "Days",
    month: "Month",
    months: "Months",
    errorAtLeastZero: "Must be greater than 0",
    slots: "Slots",
    baseInformation: "Base information",
    serviceSection: "Spaceflow category",
    mustBeUnique: "Only one product per arena can have each Spaceflow category",
    file: "File",
    fileName: "File name",
    upload: "Upload",
    uploadFile: "Upload file",
    appPurchases: "App purchases",
    previousMonth: "Previous month",
    currentMonth: "Current month",
    nextMonth: "Next month",
    fileRow: "File row",
    columnName: "Column name",
    errorMessage: "Error message",
    errorMessages: "Error messages",
    errorsWithoutRow: "Errors that do not belong to specific rows",
    notSentOrders: "Orders not sent",
    errorOnlyOneFileAllowed: "Only one file allowed",
    usedCommunityPeriodCombo: "A file has already been uploaded for this community and period",
    uploadInvoiceEventFileSure:
        "Are you sure you want to upload the file despite the fact that the chosen combination of community and period already has a file uploaded?",
    removeInvoiceEventSure:
        "Are you sure you want to remove the invoice event? You will have to upload the file again to be able to invoice the customer.",
    doInvoiceNow: "Invoice now",
    doInvoiceNowSure:
        "When you approve the invoice basis the invoice will be sent to all relevant customers according to the basis.",
    exportErrors: "Export errors",
    invoiceOrdersAmount: "Invoice orders amount",
    result: "Result",
    ordersSentToPayer: "The invoicing is done, and sent invoices can be seen in the customer portal.",
    someOrdersFailed: "Note that one or more invoices failed to be sent.",
    country: "Country",
    partOfOrgInfo: "Part of organization information",
    documents: "Documents",
    newDocument: "New document",
    documentsUnremovable: "NOTE: It's not possible to remove uploaded documents",
    agreement: "Agreement",
    other: "Other",
    documentNr: "Documentnr",
    uploaded: "Uploaded",
    noDocuments: "No documents uploaded",
    members: "Users",
    subscriptionMembers: "Subscription\u00ADusers",
    memberInformation: "Userinformation",
    added: "Added",
    invited: "Invited (#date)",
    invitedNoDate: "Invited",
    deactivated: "Deactivated",
    deactivation: "deactivation",
    deactivationDate: "Deactivation date",
    manageUsers: "Manage users",
    addMember: "Add user",
    editMember: "Edit user",
    addMemberInfo:
        "The user will receive an invitation email from Spaceflow on the selected activation date, unless they already have an account on the selected Arena.",
    activatesDateInfo: "The user will be added to Spaceflow on the selected activation date (in the morning).",
    deactivationDateInfo: "The user will be deactivated in Spaceflow on the selected date (at end of day).",
    deactivateMember: "Deactivate user",
    deactivateMemberNow: "Deactivate user now",
    deactivateMemberInfo: "The person will be deactivated from the subscription on the chosen date (at end of day).",
    deactivateMemberSure: "Are you sure you want to deactivate the user with the email #email on #date?",
    deactivateMemberNowSure:
        "Are you sure you want to deactivate the user with email #email? The user will be removed immediately. \n\nNote that it may take a few minutes before the user is removed from Spaceflow.",
    deactivateNow: "Deactivate now",
    deactivateNowDisabled: "Unable to deactivate user right now, please try later",
    removeUserSure: "Are you sure? The user is removed from this Subscription.",
    removeStaffSure: "Are you sure? The user is removed from Customer Portal (Kundportalen).",
    invalidDatesInfo:
        "The user is invalid due to incorrect dates. In order for the user to be activated in Spaceflow, you need to delete this user and add a new one with correct dates.​",
    subscriptionEndedInfo:
        "Note that this subscription has an end date #end_date, and at the time of subscription end, all active users will be terminated in Spaceflow, regardless of the entered deactivation date on users.",
    inXDays: "In #days days",
    today: "Today",
    bound: "Personal",
    boundPlural: "Personal",
    unbound: "Non-personal",
    unboundPlural: "Non-personal",
    overusage: "Overusage",
    overused: "Overused",
    statistics: "Statistics",
    subscriptionsCount: "Subscription count",
    expectedRevenue: "Expected subscription revenue",
    invoiced: "Invoiced",
    credited: "Credited",
    discounted: "Discounted",
    selectArena: "Select arena",
    futureGraphData: "Approximation based on current information",
    notInvoicedYet: "Not invoiced yet",
    invoicedGraphTitle: "Invoiced amount per month",
    expectedRevenueGraphTitle: "Subscription revenue per accounting period",
    invoicedGraphExplanation:
        "Everything that's been invoiced and credited (excl. VAT) per arena per invoice period. Subscriptions are invoiced in advance and app purchases afterwards.",
    expectedRevenueGraphExplanation: "Calculated revenue and discounts (excl. VAT) per subscription per arena.",
    subscriptionsCountGraphExplanation: "Number of subscriptions per arena.",
    subscriptionUsageGraphTitle: "Subscription usage",
    subscriptionUsageGraphExplanation: "How many persons that use the subscriptions.",
    details: "Details",
    editBindingPeriod: "Edit binding period",
    editActivationStartDate: "Edit activation date",
    editOccupancyStartDate: "Change occupancy date",
    confirmEditActivationDate:
        "Are you sure? The subscription will be activated on the date you selected. Note that if there are users on the subscription, these may be affected and need to be managed. Also note that any trial period is not automatically changed.",
    confirmEdittOccupancyDate: "Are you sure? The office occupancy date is set to the one you selected.",
    editDiscount: "Edit discount",
    editTrialPeriod: "Edit trial period",
    currentBindingPeriod: "Current binding period",
    currentActivationPeriod: "Current activation date",
    currentOccupancyPeriod: "Current occupancy date",
    currentTrialPeriod: "Current trial period",
    newBindingPeriod: "New binding period",
    newActivationDate: "New activation date",
    newOccupancyDate: "New occupancy date",
    newTrialPeriod: "New trial period end date",
    passed: "Passed",
    notSpecified: "Not specified",
    startOfDay: "Start of day",
    endOfDay: "End of day",
    fromOneYearAndForward: "From 1 year ago and forward",
    createDiscount: "Create discount",
    invoicing: "Invoicing",
    membershipInformation: "Membership information",
    subscriptionUsage: "Subscription usage",
    filterBy: "Filter by",
    retry: "Retry",
    adjustments: "Adjustments",
    sendAdjustmentsExplanation: [
        "Here is the possibility to send an extra invoice for adjustments made on the chosen membership. ",
        "The new invoice is put as a compliment to the latest invoice for the membership.",
        "\n\nThe new invoice can become a credit invoice if the customer should get money back and a normal ",
        "invoice if the customer should pay more. The difference compared to a 'normal' invoice run is that ",
        "with this adjustment only an invoice for the adjustment is created. The normal invoice for the next ",
        "period compensates for the adjustment together with the new period charge.",
    ],
    retries: "Retries",
    oneTimeAmount: "One time amount",
    monthlyAmount: "Monthly amount",
    monthlyAmountExplanation:
        "An ongoing discount valid for a fixed period, where the actual monthly amount is calculated automatically. Indicated discount corresponds to one (1) full month's discount. If the customer only has a discount for half the month (or if the agreement is terminated in the middle of the month), the customer receives half the discount amount.",
    oneTimeAmountExplanation:
        "A discount on a fixed amount that is added to a specific invoice. The customer always receives the full amount of the discount, as long as it is not a higher amount than what the customer has to pay. A discount can never generate a credit",
    discountDescription: "Description (shown on the invoice)",
    discountDescriptionText: 'Text appears on the invoice. Example "Agreed discount"',
    amountIs: "Amount is ",
    ofMaxAmount: " of max amount ",
    internalNote: "Internal note",
    internalNoteText: "Text is only visible to Arena staff, not to customers",
    deleteDiscount: "Delete discount",
    deleteDiscountDescription: "Are you sure? The discount will be completely removed.",
    alternativeRecipient: "Alternative recipient",
    alternativeInvoiceRecipient: "Alternative invoice recipient",
    selectExisting: "Select existing",
    infoAboutExistingRecipient: "",
    alternativeRecipientWarning: [
        "Note that alternative recipient should only be used in rare cases, e.g. when Vasakronan ",
        "should be recipient of a customer invoice as compensation for disturbances in their own ",
        "office through access to an Arena. Contact your arena administrator if you are unsure ",
        "if you should use alternative recipient.",
    ],
    confirmAlternativeRecipient: "Confirm alternative recipient",
    confirmAlternativeRecipientDescription: [
        "Are you sure you want to assign the selected alternative invoice recipient to the selected membership?",
        "\nPlease note that it's not possible to edit alternative invoice recipient information, nor change/delete alternative invoice recipient for the ",
        "selected membership once specified.",
    ],
    confirmMembershipTermination: "Confirm membership agreement termination",
    confirmMembershipTerminationDescription: [
        "Are you sure you want to terminate this membership agreement?",
        "\n\nNote that if you choose to terminate, the agreement will be deactivated and cannot be edited. If you need a new membership agreement for this organization in the future, you must create a new one.",
    ],
    confirmArchiveMember: "Confirm archiving of the customer",
    confirmArchiveMemberDescription: [
        "Are you sure you want to archive this customer?",
        '\n\nPlease note that if you choose to archive the customer, it will be removed from the list. You can view archived customers by clicking on "Include archived customers". Once identified, you also have the option to restore the customer as needed..',
    ],
    confirmUnArchiveMember: "Confirm restoration of customer",
    confirmUnArchiveMemberDescription: [
        "Are you sure you want to restore this customer?",
        "\n\nPlease note that if you choose to restore the customer, it will again appear in the table among other active customers. If necessary, you can archive the customer again in the same way as before.",
    ],
    confirmMembershipDate:
        "Set a date when the membership agreement is to end. If a date is not chosen, the agreement is terminated today.",
    inactivated: "Inactivated",
    assigned: "Assigned",
    events: "Notifications",
    eventNotificationsExplanation: "I want to be informed via email at the following events",
    eventType_subscription_terminated: "Subscription cancelled",
    eventType_office_terminated: "Office cancelled",
    eventType_subscription_updated: "Subscription updated",
    eventType_office_updated: "Office updated",
    eventType_membership_changes: "Membership updated",
    eventType_order_error: "Invoice order error",
    eventType_unit4_error: "Unit4 file error",
    eventType_unit4_uploaded: "Unit4 file uploaded",
    typeFiltering: "Type filtering",
    reset: "Reset",
};
