import React, { useEffect } from "react";
import { LocalizeText } from "components/localizer";
import { IMembership } from "reducers/memberships";
import { ListItemText, TextField } from "@mui/material";
import ListInlineEditWrapper from "../listComponents/listInlineEditWrapper";
import { IExternalMembership } from "reducers/externalMemberships";
import { usePermission } from "utils/hooks/usePermission";

interface IProps {
    membership: IMembership | IExternalMembership;
    onSubmitUpdate: (props: Record<string, unknown>) => void;
}

const FreeFromTextField: React.FC<IProps> = ({ membership, onSubmitUpdate }) => {
    const { hasPermission } = usePermission();
    const [text, setText] = React.useState(membership.free_form_text || "");

    useEffect(() => {
        setText(membership.free_form_text || "");
    }, [membership]);

    return (
        <ListInlineEditWrapper
            onChange={(e) => setText(e.target.value)}
            onSubmit={() => onSubmitUpdate({ free_form_text: text })}
            label={<LocalizeText tag="freeTextInternal" />}
            renderInput={(props) => (
                <TextField {...props} value={text} multiline rows={5} inputProps={{ maxLength: 2000 }} />
            )}
            renderView={(props) => <ListItemText {...props} secondary={membership.free_form_text || "-"} />}
            inputIsValid={text.length < 2000}
            editDisabled={!hasPermission("change_membership") || !!membership.end_date || membership.is_archived}
        />
    );
};

export default FreeFromTextField;
