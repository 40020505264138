import React from "react";
import axios, { AxiosError } from "axios";
import { AuthContext } from "contexts/auth";
import { getAuthStorage } from "reducers/auth";
import { isBefore, subMinutes } from "date-fns";
import { NotificationContext } from "contexts/notification";

const excludedUrls = [
    "/auth/login/",
    "/auth/new-password/",
    "/auth/otp/sms/send/",
    "/auth/otp/verify/",
    "/auth/reset-password/",
    "/contact-persons/register/",
    "/auth/refresh-token/",
    "/auth/otp/totp/devices/",
    "/auth/otp/sms/devices/",
    "/auth/logout/",
    "/feature-flags/",
    "/auth/otp/backup-codes/",
    "/news/unsubscribe/",
];

interface IProps {
    children: JSX.Element;
}

const AxiosInterceptor: React.FC<IProps> = ({ children }) => {
    const { logout, refreshCredentials, isLoggedIn, clearAuthState } = React.useContext(AuthContext);
    const { ...notification } = React.useContext(NotificationContext);
    React.useEffect(() => {
        const requestInterceptor = axios.interceptors.request.use(
            (config) => {
                if (config.url && !excludedUrls.includes(config.url)) {
                    const now = new Date();
                    const session = getAuthStorage();
                    const expires = session?.expires ? new Date(session?.expires) : undefined;
    
                    if ((!expires || isBefore(expires, now)) && isLoggedIn) {
                        if (isLoggedIn) {
                            notification.enqueNotification("warning_sessionEnded");
                        }

                        try {
                            logout();
                        } catch (error) {
                            return Promise.reject(error);
                        }

                        return Promise.reject(new Error("Session has expired"));
                    }

                    if (expires && now > subMinutes(expires, 45)) {
                        refreshCredentials();
                    }
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
        const responseInterceptor = axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error: AxiosError) => {
                if (error.response && error.response.status === 403) {
                    clearAuthState();
                } else if (
                    error.response &&
                    (error.response.status === 401 || error.response.statusText === "Unauthorized")
                ) {
                    logout();
                }
                return Promise.reject(error);
            }
        );
        return () => {
            axios.interceptors.request.eject(requestInterceptor);
            axios.interceptors.response.eject(responseInterceptor);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logout, isLoggedIn]);

    return children;
};

export default AxiosInterceptor;
