import { TPaginationActionData } from "utils/paginationStore";

export interface IService {
    uuid: string;
    created: string;
    updated: string;
    title: string;
    external_link: string;
    file: string;
    photo: string;
    body: string;
    type: string;
}

export interface IBuildingsText {
    text_uuid: string;
    type_id: string;
    content: string;
    is_published: boolean;
    type_name: string;
    type_value: string;
    sort_order: number;
    parent_sort_order: number;
    parent_type_value: string;
    parent_type_name: string;
    building_text_documents: IBuildingsTextDocument[];
}

export interface IBuildingsTextDocument {
    link_uuid: string;
    name: string;
    url: string;
    extension: string;
    sort_order: number;
}

export interface IBuildingImage {
    image_uuid: string;
    building_uuid: string;
    extension: string;
    mime_type: string;
    type_name: string;
    type_value: string;
    url: string;
    mean_hex: string;
}

export interface IBuilding {
    building_uuid: string;
    city: string;
    visiting_address: string;
    cadastral_name: string;
    popular_name_tenant: string;
    num_of_contracts: number;
    manager_agent?: string;
    year_built?: number;
    year_rebuilt?: string;
    architect?: string;
    office_size?: string;
    store_size?: string;
    building_responsibility: IBuildingResponsibility[];
    building_images: IBuildingImage[];
    building_environment_certification?: string;
    dedu_structure_id?: number;
    is_arena?: boolean;
    arena_name?: string;
}

export interface IAgent {
    agent_person_uuid: string;
    full_name: string;
    email?: string;
    mobile_phone?: string;
    direct_phone?: string;
    image_url?: string;
    title?: string;
}

export interface IBuildingResponsibility {
    role_value?: string;
    role_name?: string;
    parent_role_value?: string;
    parent_role_name?: string;
    sort_order: number;
    agent_person: IAgent;
}

export interface ITask {
    task_uuid: string;
    premises: IPremise;
    status: string;
    status_name: string;
    task_type: number;
    task_name: string;
    category: string;
    category_name: string;
    categort_value: string;
    reported_by: string;
    description: string;
    performer_name: string;
    registration_time: string;
    task_updated_time: string;
    cadastral_name: string;
    task_actions?: ITaskAction[];
    task_buildings?: ITaskBuilding[];
    task_contacts?: ITaskContact[];
    task_files?: ITaskFile[];
    task_number: string;
}

export interface ITaskAction {
    registration_time: string;
    type_value?: number;
    type_name: string;
    signature_name: string;
    message: string;
}

export interface ITaskFile {
    number: number;
    size: number;
    registration_time: string;
    name: string;
    type: string;
    path: string;
}

export interface ITaskContact {
    agent_person: IAgent;
}

export interface ITaskBuilding {
    task_building_uuid: string;
    building: IBuilding;
    maintenanced_time: string;
    maintenanced_by: string;
}

export interface INewTask {
    structure_id: number;
    description: string;
    popular_name_building: string;
    cadastral_name: string;
    city: string;
    address: string;
    leasee_name: string;
    organization_number: string;
    agreement_nr?: string;
    send_copy?: boolean;
    files: File[];
}

export interface IPremise {
    premise_uuid: string;
    real_estate_uuid: string;
    cadastral_name: string;
    building_uuid: string;
    popular_name_tenant: string;
    premise_group_name: string;
    address: string;
    zip_code: string;
    city: string;
    agreement_number: string;
    dedu_structure_id?: number;
}

export interface IBuildingsQueryParams {
    org_nr?: string;
    is_published?: boolean;
}

export interface IPremisesQueryParams {
    org_nr?: string;
    building_uuid?: string;
}

export interface ITasksQueryParams {
    exclude_completed?: boolean;
    filter?: string;
    org_nr?: string;
    ordering?: string;
}

export interface IRealestates {
    buildingInfo?: IBuildingsText[];
    buildingImages?: IBuildingImage[];
    building?: IBuilding;
    isFetchingBuilding: boolean;
    isFetchingBuildingInfo: boolean;
    isFetchingBuildingImages: boolean;
    isSubmitingTask: boolean;
    fetchBuilding: (buildingUuid: string) => void;
    fetchBuildingInfo: (buildingUuid: string) => void;
    fetchBuildingImages: (buildingUuid: string) => void;
    submitNewTask: (newTask: FormData) => Promise<boolean>;
}

export const initialState: IRealestates = {
    isFetchingBuildingInfo: false,
    isFetchingBuildingImages: false,
    isFetchingBuilding: false,
    isSubmitingTask: false,
    fetchBuilding: async () => ({} as IBuilding),
    fetchBuildingInfo: async () => false,
    fetchBuildingImages: async () => ({} as TPaginationActionData<IBuildingImage>),
    submitNewTask: async (newTask: FormData) => true,
};

export type IAction =
    | { type: "FETCH_BUILDING" }
    | {
          type: "FETCH_BUILDING_SUCCESS";
          building: IBuilding;
      }
    | { type: "FETCH_BUILDING_FAILURE" }
    | { type: "FETCH_BUILDING_INFO" }
    | { type: "FETCH_BUILDING_INFO_SUCCESS"; buildingInfo: IBuildingsText[] }
    | { type: "FETCH_BUILDING_INFO_FAILURE" }
    | { type: "FETCH_BUILDING_IMAGES" }
    | { type: "FETCH_BUILDING_IMAGES_SUCCESS"; buildingImages: IBuildingImage[] }
    | { type: "FETCH_BUILDING_IMAGES_FAILURE" }
    | { type: "SUBMIT_TASK" }
    | { type: "SUBMIT_TASK_SUCCESS" }
    | { type: "SUBMIT_TASK_FAILURE" }
    | { type: "FETCH_BUILDING_IMAGE_RGB_START" }
    | { type: "FETCH_BUILDING_IMAGE_RGB_END" };

function reducer(state: IRealestates, action: IAction): IRealestates {
    switch (action.type) {
        case "FETCH_BUILDING":
            return { ...state, isFetchingBuilding: true };
        case "FETCH_BUILDING_SUCCESS":
            return { ...state, isFetchingBuilding: false, building: action.building };
        case "FETCH_BUILDING_FAILURE":
            return { ...state, isFetchingBuilding: false };

        case "FETCH_BUILDING_INFO":
            return { ...state, isFetchingBuildingInfo: true };
        case "FETCH_BUILDING_INFO_SUCCESS":
            return {
                ...state,
                buildingInfo: action.buildingInfo,
                isFetchingBuildingInfo: false,
            };
        case "FETCH_BUILDING_INFO_FAILURE":
            return { ...state, isFetchingBuildingInfo: false };

        case "FETCH_BUILDING_IMAGES":
            return { ...state, isFetchingBuildingImages: true };
        case "FETCH_BUILDING_IMAGES_SUCCESS":
            return {
                ...state,
                buildingImages: action.buildingImages,
                isFetchingBuildingImages: false,
            };
        case "FETCH_BUILDING_IMAGES_FAILURE":
            return { ...state, isFetchingBuildingImages: false };

        case "SUBMIT_TASK":
            return { ...state, isSubmitingTask: true };
        case "SUBMIT_TASK_SUCCESS":
        case "SUBMIT_TASK_FAILURE":
            return { ...state, isSubmitingTask: false };

        default:
            return state;
    }
}

export default reducer;
