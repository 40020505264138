import React from "react";
import Wizard, { WizardPage } from "./wizard";
import TextField from "@mui/material/TextField";
import validator from "utils/validator";
import { LocalizeText } from "components/localizer";
import BackupCodes from "./backupCodes";
import { IAuthorization } from "reducers/auth";
import { AuthContext } from "contexts/auth";
import { SmsIcon } from "./icons";
import InnerPage from "./innerPage";
import VKButton from "components/vkButton";
import { findUnconfirmedSms } from "./utils";
import { sanitizePhoneNumber } from "utils/helpers";
import { LanguageContext } from "contexts/language";

interface IProps {
    onCancel: () => void;
    onFinish?: () => void;
    authorizationObject?: IAuthorization;
}
const RegisterSmsWizard: React.FC<IProps> = ({ onCancel, onFinish, authorizationObject }) => {
    const {
        smsDevices,
        updateSmsDevice,
        sendAuthenticationSMS,
        verifyOtpCode,
        fetchBackupCodes,
        isFetchingBackupCodes,
        generateBackupCodes,
        backupCodes,
        createSmsDevice,
    } = React.useContext(AuthContext);
    const { localize } = React.useContext(LanguageContext);

    const [activeStep, setActiveStep] = React.useState<number>(0);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const [number, setNumber] = React.useState<string>("");
    const [code, setCode] = React.useState<string>("");

    const device = findUnconfirmedSms(smsDevices);

    React.useEffect(() => {
        if (device) {
            setNumber(device.number);
        }
        // eslint-disable-next-line
    }, []);

    const validNumber = validator.phoneNumber(number, true);

    const onSubmitNumber = async (): Promise<void> => {
        setIsLoading(true);
        let validResponse;
        if (!device) {
            validResponse = await createSmsDevice(
                "My new device",
                sanitizePhoneNumber(number),
                authorizationObject?.key
            );
        } else {
            validResponse = await updateSmsDevice(device.id, sanitizePhoneNumber(number));
        }
        if (!validResponse) {
            setIsLoading(false);
            return;
        }
        const smsSent = await sendAuthenticationSMS(authorizationObject?.key);
        if (smsSent) {
            setActiveStep(activeStep + 1);
        }
        setIsLoading(false);
    };

    const onChangeVerificationCode = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
        const value = e.target.value;
        setCode(value);
        value.length === 6 && validate(value);
    };

    const validate = async (token: string): Promise<void> => {
        const success = await verifyOtpCode({ token, key: authorizationObject?.key });
        if (success) {
            if (!backupCodes && !isFetchingBackupCodes) {
                setIsLoading(true);
                await fetchBackupCodes();
            }
            if (backupCodes && backupCodes?.length === 0) {
                setIsLoading(true);
                await generateBackupCodes();
            }
            setIsLoading(false);
            setActiveStep(activeStep + 1);
        }
    };

    const onClickFinish = (): void => {
        if (onFinish) {
            onFinish();
        } else {
            onCancel();
        }
    };

    return (
        <Wizard
            steps={["mobileNumber", "verify", "backup"]}
            step={activeStep}
            pages={[
                <WizardPage
                    key="pageMobile"
                    onBack={onCancel}
                    onNext={onSubmitNumber}
                    buttonNext={
                        <VKButton
                            tag="next"
                            type="submit"
                            isLoading={isLoading}
                            disabled={!validNumber || number === ""}
                            template="primary"
                        />
                    }
                >
                    <InnerPage
                        page={{
                            icon: <SmsIcon />,
                            title: "registerMobile",
                            info: "registerMobileInfo",
                            customContent: (
                                <TextField
                                    variant="outlined"
                                    name="mobileNumber"
                                    margin="dense"
                                    sx={{
                                        width: "280px",
                                        height: "40px",
                                    }}
                                    value={number}
                                    autoFocus
                                    onChange={(e) => setNumber(e.target.value)}
                                    helperText={!validNumber && <LocalizeText tag="errorMobile" />}
                                    error={!validNumber}
                                    placeholder={localize("mobileNumberHelperText")}
                                />
                            ),
                        }}
                    />
                </WizardPage>,
                <WizardPage key="pageVerify" onBack={() => setActiveStep(activeStep - 1)}>
                    <InnerPage
                        page={{
                            icon: <SmsIcon />,
                            title: "verifyMobile",
                            info: "verifyMobileInfo",
                            customContent: (
                                <TextField
                                    variant="outlined"
                                    name="code"
                                    margin="dense"
                                    sx={{
                                        width: "280px",
                                        height: "40px",
                                    }}
                                    value={code}
                                    autoFocus
                                    onChange={onChangeVerificationCode}
                                />
                            ),
                        }}
                    />
                </WizardPage>,
                <WizardPage
                    key="pageBackup"
                    onNext={onClickFinish}
                    buttonNext={<VKButton tag="proceed" type="submit" template="primary" />}
                >
                    <InnerPage
                        page={{
                            icon: <SmsIcon />,
                            title: "backupWithCodes",
                            info: "backupWithCodesInfo",
                            customContent: <BackupCodes />,
                        }}
                    />
                </WizardPage>,
            ]}
        />
    );
};

export default RegisterSmsWizard;
