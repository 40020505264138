import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import VKButton from "components/vkButton";
import { LocalizeText } from "components/localizer";
import validator from "utils/validator";
import { TFormEvent } from "utils/types";
import { ListTextField, SelectBox, TItem } from "components/drawers/drawerComponents";
import { IContactPerson } from "reducers/memberships";
import ArrowIcon from "@mui/icons-material/NavigateNextOutlined";
import { sanitizePhoneNumber } from "utils/helpers";
import { LanguageContext, TLanguageTag } from "contexts/language";
import { ListItemIcon, ListItemText, Typography } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import { IRole } from "reducers/roleManagement";
import PaginationStore from "utils/paginationStore";
import { useIsLoading } from "utils/hooks/useLoading";
import { NotificationContext } from "contexts/notification";

export interface INewContactObject {
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    roleId: number;
}

interface IProps {
    onSubmit: (newContact: INewContactObject) => Promise<void>;
    fetchExisting: () => Promise<IContactPerson[]>;
    onOpenEdit?: (person: IContactPerson) => void;
    titleTag: TLanguageTag;
    contractData: string;
    availableRoles: PaginationStore<IRole>;
}
const AddContactPersonBase: React.FC<IProps> = ({
    onSubmit,
    fetchExisting,
    onOpenEdit,
    titleTag,
    contractData,
    availableRoles,
}) => {
    const stateInitValue = {
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        roleId: -1,
    };
    const { enqueNotification } = React.useContext(NotificationContext);

    const [isLoading, setIsLoading] = useIsLoading(true);

    const [newContact, setNewContact] = React.useState<INewContactObject>(stateInitValue);
    const [existing, setExisting] = React.useState<IContactPerson[]>([]);

    React.useEffect(() => {
        (async () => {
            setIsLoading(true);
            try {
                const entries = await fetchExisting();
                setExisting(entries);
            } catch (error) {
                enqueNotification("error_fetchContactPersons", error);
            }
            setIsLoading(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (availableRoles.totalCount === 1) {
            setNewContact({ ...newContact, roleId: availableRoles.results[0].id });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableRoles.results]);

    const onChange = (type: keyof INewContactObject, value: string | number): void => {
        setNewContact({ ...newContact, [type]: value });
    };

    const handleSubmit = async (event: TFormEvent): Promise<void> => {
        setIsLoading(true);
        event.preventDefault();
        const parsedPhoneNumber = sanitizePhoneNumber(newContact.phone);
        await onSubmit({ ...newContact, phone: parsedPhoneNumber });
        setIsLoading(false);
    };

    const validateData = (): boolean => {
        return !!(validator.email(newContact.email) && validator.phoneNumber(newContact.phone));
    };
    const { localize } = React.useContext(LanguageContext);
    const existingPerson = existing ? existing.find((el) => el.email === newContact.email) : false;

    const loadMoreRoles = (event: React.BaseSyntheticEvent): void => {
        const scrollLength = event.target.scrollHeight - Math.abs(event.target.scrollTop);
        if (scrollLength === event.target.clientHeight) {
            availableRoles.nextPage();
        }
    };

    const renderSubmitButton = (): JSX.Element => {
        if (!existingPerson) {
            return (
                <ListItem>
                    <VKButton
                        data-testid="addContactPerson-submitButton"
                        id="button"
                        tag="save"
                        type="submit"
                        template="save"
                        disabled={!validateData()}
                        isLoading={isLoading}
                    />
                </ListItem>
            );
        }
        if (onOpenEdit) {
            return (
                <ListItem>
                    <VKButton
                        tag="showExistingContactPerson"
                        rightAddon={<ArrowIcon />}
                        onClick={() => {
                            onOpenEdit(existingPerson);
                        }}
                        template="primary"
                    />
                </ListItem>
            );
        } else {
            return (
                <ListItem>
                    <ListItemIcon>
                        <WarningIcon color="error" />
                    </ListItemIcon>
                    <Typography variant="subtitle1" color="error">
                        <LocalizeText tag="userAlreadyExist" />
                    </Typography>
                </ListItem>
            );
        }
    };

    const mapRoles = (): TItem[] => {
        return availableRoles.results.reduce((list: TItem[], role) => {
            list.push({
                name: role.display_name,
                value: role.id,
            });
            return list;
        }, []);
    };

    return (
        <div data-testid="addContactPerson-root">
            <List>
                <ListItem>
                    <ListItemText
                        data-testid={"addContactPerson-header"}
                        primary={<LocalizeText tag={titleTag} />}
                        secondary={contractData}
                    />
                </ListItem>
                <ListItem>
                    <LocalizeText tag={"addNewRepresentativeHelperText"} />
                </ListItem>
                <form onSubmit={handleSubmit}>
                    <ListTextField
                        isEditing
                        testid="addContactPerson-firstName"
                        label={<LocalizeText tag="firstName" />}
                        data={newContact.firstName}
                        onChange={(e) => onChange("firstName", e.target.value)}
                    />
                    <ListTextField
                        isEditing
                        testid="addContactPerson-lastName"
                        label={<LocalizeText tag="lastName" />}
                        data={newContact.lastName}
                        onChange={(e) => onChange("lastName", e.target.value)}
                    />
                    <ListTextField
                        testid="addContactPerson-email"
                        notValid={!validator.email(newContact.email, true)}
                        label={<LocalizeText tag="email" />}
                        errorText={<LocalizeText tag="errorEmail" />}
                        data={newContact.email}
                        onChange={(e) => onChange("email", e.target.value)}
                        isEditing
                    />
                    <ListTextField
                        isEditing
                        testid="addContactPerson-phone"
                        notValid={!validator.phoneNumber(newContact.phone, true)}
                        label={<LocalizeText tag="phoneNumber" />}
                        data={newContact.phone}
                        onChange={(e) => onChange("phone", e.target.value)}
                        errorText={<LocalizeText tag="errorMobile" />}
                        placeholder={localize("mobileNumberHelperText")}
                    />
                    {availableRoles && availableRoles.totalCount === 1 ? (
                        <ListTextField
                            label={<LocalizeText tag="role" />}
                            hasData
                            isEditing
                            disabled
                            data={availableRoles.results[0].display_name}
                        />
                    ) : (
                        <SelectBox
                            onScroll={loadMoreRoles}
                            label={<LocalizeText tag="role" />}
                            data={newContact.roleId > 0 ? String(newContact.roleId) : ""}
                            onEdit={(value: string) => onChange("roleId", Number(value))}
                            items={mapRoles()}
                        />
                    )}
                    {renderSubmitButton()}
                </form>
            </List>
        </div>
    );
};

export default AddContactPersonBase;
