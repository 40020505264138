import React, { FC, useContext, useEffect, useMemo } from "react";
import { LocalizeText } from "components/localizer";
import { IInvoiceLine } from "reducers/invoices";
import { InvoicesContext } from "contexts/invoices";
import { useNavigate, useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import { prettifyPostcode } from "utils/prettifiers";
import { formatPossibleNullDate } from "utils/helpers";
import { dateFormats } from "utils/formats";
import { CenteredLoader } from "components/centeredLoader";
import colors from "styles/colors";
import { ModalContext } from "contexts/modals";
import ProductInformation from "components/drawers/productInformation";
import { ProductsContext } from "contexts/products";
import { InvoiceOrderStatus } from "components/invoices/invoiceOrderStatus";
import { LanguageContext, TLanguageTag } from "contexts/language";
import { IInvoiceContentButton, IInvoiceLineItem, InvoiceContent } from "components/invoices/invoiceContent";
import { ITagValueListEntries } from "components/tagValueList";
import styled from "@emotion/styled";

const InvoiceView: FC = () => {
    const {
        fetchInvoiceOrder,
        isFetchingInvoiceOrder,
        invoiceOrder,
        fetchInvoiceOrderLines,
        isFetchingInvoiceOrderLines,
        invoiceOrderLines,
        fetchInvoiceLines,
        isFetchingInvoiceLines,
        invoiceLines,
        retryInvoiceOrder,
        isRetryingInvoiceOrder,
    } = useContext(InvoicesContext);
    const params = useParams<{ orderUuid: string; invoiceUuid: string }>();
    const { openDrawer } = useContext(ModalContext);
    const { fetchProduct } = useContext(ProductsContext);
    const { currentLanguage } = useContext(LanguageContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (params.orderUuid) {
            fetchInvoiceOrder(params.orderUuid);
        }

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!invoiceOrder) {
            return;
        }

        if (invoiceOrder.invoices.length === 0 && params.orderUuid) {
            fetchInvoiceOrderLines(params.orderUuid);
        }
        if (params.invoiceUuid) {
            fetchInvoiceLines(params.invoiceUuid);
        }
        // eslint-disable-next-line
    }, [invoiceOrder]);

    const invoice = invoiceOrder?.invoices?.find((invoice) => invoice.uuid === params.invoiceUuid);
    const currency = (invoiceOrder?.currency_code as TLanguageTag) ?? "SEK";

    const items = useMemo((): IInvoiceLineItem[] => {
        if (!invoiceOrder?.invoices) {
            return [];
        }

        const lines: Line[] = (invoiceOrder?.invoices?.length > 0 ? invoiceLines : invoiceOrderLines) ?? [];

        const mergedLines: Line[] = [];
        lines.forEach((line) => {
            if (line.item_type === "INFOLINE") {
                mergedLines[mergedLines.length - 1].description += "\n" + line.description;
                return;
            }

            mergedLines.push({ ...line });
        });

        return mergedLines.map((orderLine) => {
            const { article_number, description, subtotal_price, subtotal_vat_amount } = orderLine;
            const row: IInvoiceLineItem = {
                onClick: async () => {
                    const product = await fetchProduct(orderLine.article_number);
                    if (product.id !== undefined) {
                        openDrawer(ProductInformation, { product }, "productInfo");
                    }
                },
                name: article_number,
                description,
                amount: subtotal_price - subtotal_vat_amount,
            };
            return row;
        });
    }, [invoiceOrderLines, fetchProduct, openDrawer, invoiceLines, invoiceOrder]);

    if (isFetchingInvoiceOrder || isFetchingInvoiceOrderLines || isFetchingInvoiceLines) {
        return (
            <div className="contentWrapper">
                <CenteredLoader size="40px" />
            </div>
        );
    }

    if (!invoiceOrder) {
        return null;
    }

    let paymentType: TLanguageTag | undefined = undefined;
    switch (invoice?.type) {
        case "PREPAYMENT":
            paymentType = "prepayment";
            break;
        case "NORMAL":
            paymentType = "normal";
            break;
        case "CREDIT":
            paymentType = "credit";
            break;
    }
    const leftColumn: ITagValueListEntries[] = [
        {
            tag: "dueDate",
            value: (
                <>
                    {formatPossibleNullDate(invoice?.due_date ?? null, dateFormats.WEBDATE) || "-"}
                    {invoice?.is_overdue && parseFloat(invoice?.balance || "") > 0 && (
                        <Typography color={colors.errorRed} component="span">
                            <LocalizeText tag="late" spaceBefore />
                        </Typography>
                    )}
                </>
            ),
            align: "left",
        },
        {
            tag: "invoiceReference",
            value: invoiceOrder?.your_reference || "-",
            align: "left",
        },
        {
            tag: "ourReference",
            value: invoiceOrder?.our_reference || "-",
            align: "left",
        },
        {
            tag: "paymentTerms",
            value: paymentType ? <LocalizeText tag={paymentType} /> : "-",
            align: "left",
        },
        {
            tag: "invoiceNumber",
            value: invoice?.invoice_number ?? "-",
            align: "left",
        },
        {
            tag: "invoiceBasisId",
            value: invoiceOrder?.uuid || "-",
            align: "left",
        },
    ];

    const rightColumn: ITagValueListEntries[] = [
        {
            tag: "company",
            value: `${invoiceOrder.company_name} (${invoiceOrder.reg_number})`,
            align: "left",
        },
        {
            tag: "invoiceAddress",
            value: (
                <SpanColumn>
                    <span>{invoiceOrder.street_address1 || "-"}</span>
                    <span>{prettifyPostcode(invoiceOrder.zip_code || "-")}</span>
                    <span>{invoiceOrder.city || "-"}</span>
                    <span>{currentLanguage === "sv" ? invoiceOrder.verbose_sv : invoiceOrder.verbose_en || "-"}</span>
                </SpanColumn>
            ),
            align: "left",
        },
        {
            tag: "paymentMethod",
            value:
                invoiceOrder.delivery_type === "EMAIL" ? (
                    <SpanColumn>
                        <span>
                            <LocalizeText tag="paymentMethodPDF" />
                        </span>
                        <span>{invoiceOrder.email_address}</span>
                    </SpanColumn>
                ) : (
                    <SpanColumn>
                        <span>
                            <LocalizeText tag="paymentMethodPeppol" />
                        </span>
                        <span>{invoiceOrder.gln}</span>
                    </SpanColumn>
                ),
            align: "left",
        },
        {
            tag: "status",
            value: <InvoiceOrderStatus order={invoiceOrder} />,
            align: "left",
        },
    ];

    const buttons: IInvoiceContentButton[] = [
        {
            tag: "invoiceEvent",
            onClick: () => navigate(`/staff-invoicing/events/${invoiceOrder.event}`),
        },
    ];
    if (invoiceOrder?.membership !== null) {
        buttons.push({
            tag: "showMembership",
            onClick: () => navigate(`/staff-memberships/${invoiceOrder.membership}/details`),
        });
    }
    if (invoice?.invoice_pdf_url) {
        buttons.push({
            tag: "downloadPdf",
            onClick: () => window.open(invoice.invoice_pdf_url),
        });
    }
    if (
        !invoice &&
        new Date(invoiceOrder.valid_until) > new Date() &&
        ["created", "order_not_valid", "order_failed", "invoice_failed"].includes(invoiceOrder.internal_status)
    ) {
        rightColumn.push({
            tag: "retries",
            value: invoiceOrder.retries,
        });
        buttons.push({
            tag: invoiceOrder.retries === 0 ? "send" : "retry",
            onClick: async () => {
                if (params.orderUuid){
                    await retryInvoiceOrder(params.orderUuid);
                    window.location.reload();
                }
            },
            isLoading: isRetryingInvoiceOrder,
        });
    }

    return (
        <div className="contentWrapper">
            <InvoiceContent
                titleTag="invoiceBasis"
                amount={invoice?.amount_to_pay}
                remaining={invoice?.balance}
                items={items}
                infoColumns={[leftColumn, rightColumn]}
                buttons={buttons}
                currency={currency}
            />
        </div>
    );
};

type Line = Omit<IInvoiceLine, "invoice">;

const SpanColumn = styled("span")`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export default InvoiceView;
