import webAPIRequest from "api";
import { AxiosResponse } from "axios";

export const renewRepresentitiveInvitationAdapter = async (
    id: number,
): Promise<AxiosResponse> => {
    return webAPIRequest("get", `/communities/representative-invitations/${id}/renew/`);
};

export const deleteRepresentitiveInvitationAdapter = async (
    id: number,
): Promise<AxiosResponse> => {
    return webAPIRequest("delete", `/communities/representative-invitations/${id}/`);
};