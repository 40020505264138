export const sv = {
    error_login_400: "Tomt fält användarnamn eller lösenord.",
    error_login_403: "Felaktigt användarnamn eller lösenord.",
    error_login_no_response: "Kunde inte upprätta kontakt med servern.",
    error_saveNewPassword: "Kunde inte spara nytt lösenord.",
    error_sendPasswordLink: "Kunde inte skicka återställningslänk för lösenord.",
    error_addUser: "Kunde inte koppla användare till abonnemanget.",
    error_removeUser: "Kunde inte ta bort användare.",
    error_fetchMembershipInvitations: "Kunde inte hämta inbjudningar till medlemskapet.",
    error_fetchContactPersons: "Kunde inte hämta användare.",
    error_addContactPerson: "Kunde inte lägga till användare.",
    error_editContactPerson: "Kunde inte ändra användaren.",
    error_removeContactPerson: "Kunde inte ta bort användaren.",
    error_contactPersonExist: "Användaren finns redan.",
    error_removeInvitation: "Kunde inte ta bort inbjudan.",
    error_fetchSubscription: "Kunde inte hämta abonnemanget.",
    error_fetchSubscriptions: "Kunde inte hämta abonnemangen.",
    error_updateSubscription: "Kunde inte uppdatera abonnemanget.",
    error_cancelSubscription: "Kunde inte avsluta abonnemanget.",
    error_fetchSubscriptionsStatisticsCounts: "Kunde inte hämta abonnemangsantalsstatistik",
    error_fetchMembership: "Kunde inte hämta information om medlemsavtal.",
    error_terminateMembership: "Kunde inte avsluta medlemsavtalet",
    error_fetchMemberships: "Kunde inte hämta medlemsavtal.",
    error_updateMembership: "Kunde inte uppdatera medlemsavtalsinformationen.",
    error_fetchCountryCodes: "Kunde inte hämta landslista.",
    error_fetchProfile: "Kunde inte hämta profil.",
    error_saveProfile: "Kunde inte spara profil.",
    error_fetchArenas: "Kunde inte hämta arenor.",
    error_cancelArena: "Kunde inte ta bort arenakontor.",
    error_updateArena: "Kunde inte uppdatera arenakontoret.",
    error_refreshCredentials: "Kunde inte uppdatera loginuppgifterna.",
    error_fetchFolios: "Kunde inte hämta folio.",
    error_setNationalAccess: "Kunde inte sätta nationellt tillägg.",
    error_getNationalAccess: "Kunde inte hämta produkten för nationellt tillägg.",
    error_removeNationalAccess: "Kunde inte ta bort nationellt tillägg.",
    error_openBariumForm: "Kunde inte öppna formulär.",
    error_server: "Något gick fel, vänligen försök igen eller vänta ett tag.",
    error_fetchGuests: "Kunde inte hämta gäster.",
    error_fetchDayPasses: "Kunde inte hämta dagspass.",
    error_bookDayPass: "Kunde inte boka dagspass.",
    error_removeDayPass: "Kunde inte ta bort dagspass.",
    error_fetchDayPassLimits: "Kunde inte hämta dagspassbegränsing.",
    error_saveDayPassLimits: "Kunde inte spara dagspassbegränsning.",
    error_fetchInvoiceMethods: "Kunde inte hämta inställningar för betalmetod.",
    error_fetchEmailsCSV: "Kunde inte hämta fil med CSV-data.",
    error_createExternalMembership: "Kunde inte skapa extern kund.",
    error_bills: "Kunde inte hämta räkningar.",
    error_fetchHosts: "Kunde inte hämta värdar för dagspass.",
    error_saveInvoiceMethods: "Kunde inte spara val av betalsätt.",
    error_fetchFolioSettings: "Kunde inte hämta maximalt tillåten kredit.",
    error_saveFolioSettings: "Kunde inte spara maximalt tillåten kredit.",
    error_saveBillingAddress: "Kunde inte spara faktureringsadress.",
    error_fetchBillingAddress: "Kunde inte hämta faktureringsadress.",
    error_fetchLeases: "Kunde inte hämta hyresavtal.",
    error_fetchCustomerLedgers: "Kunde inte hämta hyresavier.",
    error_fetchCustomerLedgerEntries: "Kunde inte hämta reskontra för hyresavi.",
    error_fetchBuildings: "Kunde inte hämta byggnader.",
    error_fetchLeasesInfo: "Kunde inte hämta information om hyresavtal.",
    error_fetchBuildingInfo: "Kunde inte hämta praktisk information om byggnad.",
    error_fetchBuildingImages: "Kunde inte hämta bilder på byggnaden.",
    error_fetchCommunities: "Kunde inte hämta communities.",
    error_fetchAvailableDates: "Kunde inte hämta tillgängliga datum.",
    error_fetchTac: "Kunde inte hämta Allmänna villkoren.",
    error_fetchBackupCodes: "Kunde inte hämta engångskoder.",
    error_generateBackupCodes: "Kunde inte generera nya engångskoder.",
    error_fetchTotpDevices: "Kunde inte hämta enheterna.",
    error_createTotpDevice: "Kunde inte skapa ny enhet.",
    error_updateTotpDevice: "Kunde inte uppdatera enheten.",
    error_removeTotpDevice: "Kunde inte ta bort enheten.",
    error_fetchSmsDevices: "Kunde inte hämta enheterna.",
    error_createSmsDevice: "Kunde inte skapa ny enhet.",
    error_updateSmsDevice: "Kunde inte uppdatera enheten.",
    error_removeSmsDevice: "Kunde inte ta bort enheten.",
    error_sendSms: "Kunde inte skicka SMS.",
    error_fetchNewsList: "Kunde inte hämta nyheter.",
    error_fetchCommunitySettings: "Kunde inte hämta inställningar för community.",
    error_fetchMember: "Kunde inte hämta användaren.",
    error_fetchMembers: "Kunde inte hämta användarna.",
    error_fetchAssociation: "Kunde inte hämta associationen.",
    error_fetchAssociations: "Kunde inte hämta associationerna.",
    error_createAssociation: "Kunde inte skapa till association.",
    error_deactivateAssociation: "Kunde inte inaktivera associationen.",
    error_removeAssociation: "Kunde inte ta bort associationen.",
    error_updateAssociation: "Kunde inte uppdatera associationen.",
    error_resendInvation: "Kunde inte skicka inbjudan igen.",
    error_removeMemberInvitation: "Kunde inte ta bort användare.",
    error_resetPassword: "Ditt lösenord kunde inte uppdateras.",
    error_fetchLeaseInvitation: "Kunde inte hämta inbjudningar till hyresavtal.",
    error_acceptLeaseInvitation: "Kunde inte acceptera inbjudan till hyresavtalet.",
    error_open_agreement_document_file: "Kunde inte öppna filen med avtalet.",
    error_premise_contract_document_file_not_found: "Det finns inget dokument kopplat till detta avtal.",
    error_requestingAccessToLease: "Kunde inte begära om att få tillgånt till hyresavtalet.",
    error_fetch_organizations: "Kunde inte hämta organisationer.",
    error_fetchTasks: "Kunde inte hämta ärenden.",
    error_fetchPremises: "Kunde inte hämta lokaler.",
    error_submitNewTask: "Kunde inte skapa nytt ärende.",
    error_submitTaskAction: "Kunde inte skapa en ny kommentar.",
    error_updateProfile: "Kunde inte uppdatera profilen.",
    error_sendQuestion: "Meddelandet kunde inte skickas.",
    error_updateNewsViaMailPreferences: "Kunde inte ändra inställningarna för uppdateringar via mail.",
    error_addCostCalculation: "Kunde inte skapa en ny kostnadsberäkning.",
    error_fetchInvoiceEvent: "Kunde inte hämta fakturakörningen.",
    error_fetchInvoiceEvents: "Kunde inte hämta fakturakörningarna.",
    error_fetchInvoiceOrder: "Kunde inte hämta fakturaunderlaget.",
    error_fetchInvoiceOrders: "Kunde inte hämta fakturaunderlagen.",
    error_fetchInvoiceOrderLines: "Kunde inte hämta fakturaunderlagsraderna.",
    error_fetchInvoiceLines: "Kunde inte hämta fakturaraderna.",
    error_someOrdersFailed: "En eller flera ordrar misslyckades.",
    error_createFromMembership: "Det fanns inga justeringar att göra.",
    error_approveInvoiceEvent: "Kunde inte skicka fakturakörning.",
    error_deleteInvoiceEvent: "Kunde inte ta bort fakturakörning.",
    error_fetchProduct: "Kunde inte hämta produkten.",
    error_fetchProducts: "Kunde inte hämta produkterna.",
    error_updateProduct: "Kunde inte uppdatera produkten.",
    error_createProduct: "Kunde inte skapa produkten.",
    error_fetchBookkeepingAccounts: "Kunde inte hämta bokföringskonton.",
    error_fetchServiceAccounts: "Kunde inte hämta Spaceflow-kategorierna.",
    error_fetchServices: "Kunde inte hämta tjänster",
    error_fetchDebits: "Kunde inte hämta avgifter",
    error_fetchAlternativeRecipient: "Kunde inte hämta alternativ fakturamottagare.",
    error_fetchAlternativeRecipients: "Kunde inte hämta alternativa fakturamottagare.",
    error_updateAlternativeRecipient: "Kunde inte uppdatera alternativ fakturamottagare.",
    error_createAlternativeRecipient: "Kunde inte skapa alternativ fakturamottagare.",
    error_fetchPublicEvents: "Kunde inte hämta händelser.",
    warning_notImplemented: "Denna funktion är inte implementerad.",
    warning_sessionEnded: "Du blev utloggad på grund av inaktivitet.",
    success_createTask: "Ärendet är skapat, så fort det mottagits kan du se det i tabellen nedan.",
    success_cancelArena: "Avslutade kontraktet.",
    success_updateArena: "Ändrade information på arenakontoret.",
    success_updateSubscription: "Ändrade information på abonnemanget.",
    success_addUserSubscription: "Användare tilldelad abonnemang.",
    success_addUserArena: "Användare tilldelad access till arenakontoret.",
    success_removeUser: "Tog bort användare.",
    success_saveNewPassword: "Registrering lyckades.",
    success_sentResetPassword: "Begäran om återställning av lösenord skickad.",
    success_bookDayPass: "Bokade dagspass.",
    success_removeDayPass: "Tog bort dagspasset.",
    success_saveDayPassLimits: "Sparade dagspassbegränsning.",
    success_addContactPerson: "Skapade användare.",
    success_editContactPerson: "Ändrade information på användaren.",
    success_removeContactPerson: "Tog bort användaren.",
    success_removeInvitation: "Tog bort inbjudan.",
    success_cancelSubscription: "Abonnemang avbrutet.",
    success_setNationalAccess: "Startade nationellt tillägg.",
    success_removeNationalAccess: "Tog bort nationellt tillägg.",
    success_saveInvoiceInfo: "Sparade inställningar för faktureringsauppgifter.",
    success_updateMembership: "Ändrade information på medlemsavtalet.",
    success_terminateMembership: "Medlemsavtalet är avslutat.",
    success_createExternalMembership: "Skapade ny extern kund.",
    success_generateBackupCodes: "Generarade nya engångskoder.",
    success_removeTotpDevice: "Tog bort enheten.",
    success_removeSmsDevice: "Tog bort enheten.",
    success_copyOnetimeCode: "Kopierade engångskoden.",
    success_removeMemberInvitation: "Tog bort medlem.",
    success_resetPassword: "Ditt lösenord har uppdaterats.",
    success_updateNewsViaMailPreferences:
        "Dina uppgifter har ändrats. Du kommer inte längre få uppdateringar om nya nyheter via mail.",
    success_acceptLeaseInvitation: "Inbjudan till hyresavtal accepterad.",
    success_requestingAccessToLease: "Begäran om att få tillgång till hyresavtalet skickat.",
    success_updateProfile: "Profil uppdaterad.",
    success_denyLeaseInvitation: "Begäran om att få tillgång till hyresavtal avslagen.",
    success_sendQuestion: "Meddelandet skickat.",
    success_updateProduct: "Produktinfo uppdaterad.",
    success_createProduct: "Produkt skapad.",
    success_createInvoiceEvent: "Fakturakörning skapad från fil.",
    success_approveInvoiceEvent:
        "Fakturakörning skickad till Payer. Du kan behöva ladda om sidan flera gånger för att se resultaten.",
    success_createAssociation: "Medlem tillagd på abonnemanget",
    success_updateAssociation: "Medlem uppdaterad på abonnemanget",
    success_resendInvitation: "Inbjudan skickad igen.",
    success_createFromMembership: "Skapade ny order och körning för medlemskapet.",
    success_createAlternativeRecipient: "Ny alternativ fakturamottagare skapad.",
    success_fileDownload: "Fil nerladdad.",
    general_error: "Ett fel inträffade, vänligen försök igen. Om felet kvarstår kontakta Vasakronans Kundservice.",
};
