import React, { FC, ReactNode, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import VKButton from "components/vkButton";
import AddLeaseContactPerson from "components/drawers/contactPersons/addLeaseContact";
import RequestAccessToLease from "components/drawers/contactPersons/requestAccessToLease";
import BaseAgreementDetailsView from "./agreementDetailView";
import EditLeaseContactPerson from "components/drawers/contactPersons/editLeaseContactPerson";
import { IContactPerson } from "reducers/memberships";
import { usePermission } from "utils/hooks/usePermission";
import { ProfileContext } from "contexts/profile";
import ContactPersonTable from "components/tableComponents/contactPersonTable";
import InvitationsTable from "components/tableComponents/invitationsTable";
import LeasesContext from "contexts/leases";
import { ModalContext } from "contexts/modals";
import { usePaginationStore } from "utils/usePaginationStore";

const LeaseUserDetailsView: FC = () => {
    const {
        isFetchingLease,
        fetchLease,
        lease,
        requestAccessToLease,
        removeContactPersonAccess,
        editContactPerson,
        isEditingContactPerson,
        deleteInvitation,
    } = useContext(LeasesContext);
    const { user } = useContext(ProfileContext);
    const { openDrawer } = useContext(ModalContext);

    const params = useParams<{ leaseUuid: string }>();
    const { hasPermission } = usePermission();

    const leaseInvitationsPagination = usePaginationStore("lease-invitations", undefined);
    const leaseContactPersonPagination = usePaginationStore("lease-contact-persons", { lease_uuid: params.leaseUuid ?? ""});

    useEffect(() => {
        if ((!lease || lease.lease_uuid !== params.leaseUuid) && !isFetchingLease && params.leaseUuid) {
            fetchLease(params.leaseUuid);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getActionButton = (): ReactNode => {
        if (lease?.has_access_permission && !user?.is_staff && hasPermission("add_leaseinvitation")) {
            return (
                <VKButton
                    template="primary"
                    size="small"
                    tag="addNewRepresentative"
                    onClick={() =>
                        openDrawer(
                            AddLeaseContactPerson,
                            {
                                lease,
                                onAdd: () => {
                                    leaseInvitationsPagination.query.refetch();
                                    leaseContactPersonPagination.query.refetch();
                                },
                            },
                            "addNewRepresentative"
                        )
                    }
                />
            );
        } else if (!user?.is_staff && hasPermission("request_leaseinvitation") && lease) {
            return (
                <VKButton
                    template="primary"
                    size="small"
                    tag="requestAccess"
                    onClick={() =>
                        openDrawer(
                            RequestAccessToLease,
                            {
                                onSubmit: requestAccessToLease,
                                lease,
                            },
                            "requestAccess"
                        )
                    }
                />
            );
        }
        return null;
    };

    const handleRemove = async (contact: IContactPerson): Promise<void> => {
        if (contact.id) {
            await removeContactPersonAccess(params.leaseUuid as string, contact.id);
            leaseInvitationsPagination.query.refetch();
            leaseContactPersonPagination.query.refetch();
        }
    };

    const onOpenEdit = (person: IContactPerson): void => {
        if (lease && person) {
            openDrawer(
                EditLeaseContactPerson,
                {
                    person,
                    lease,
                    onSubmit: async (role_id: number): Promise<boolean> => {
                        const success = await editContactPerson(lease.lease_uuid, person.id, { role: role_id });
                        leaseContactPersonPagination.query.refetch();
                        return success;
                    },
                    isLoading: isEditingContactPerson,
                },
                "editRepresentative"
            );
        }
    };

    return (
        <BaseAgreementDetailsView
            loadingText={isFetchingLease}
            title={lease?.address}
            subtitle={`${lease?.agreement_number}, ${Number(lease?.active_lease_contract?.area).toFixed(0)}㎡, ${
                lease?.active_lease_contract?.premise_group_name
            }`}
            pagination={leaseContactPersonPagination}
            actionButton={getActionButton()}
        >
            <ContactPersonTable
                pagination={leaseContactPersonPagination}
                loading={leaseContactPersonPagination.query.isFetching || isFetchingLease}
                handleRemove={handleRemove}
                openEditDrawer={onOpenEdit}
                permissions={{ delete: "removecontact_lease", patch: "patchcontact_lease" }}
            />
            {leaseInvitationsPagination && (
                <InvitationsTable
                    loading={leaseInvitationsPagination.query.isFetching}
                    pagination={leaseInvitationsPagination}
                    handleRemove={async (invite) => await deleteInvitation(invite.uuid)}
                    permissions={{ delete: "delete_leaseinvitation" }}
                />
            )}
        </BaseAgreementDetailsView>
    );
};

export default LeaseUserDetailsView;
