import React, { FC } from "react";
import Banner from "components/banner";
import { usePaginationStore } from "utils/usePaginationStore";
import TopBar from "components/tableComponents/topBar";
import { StaffMembersTable } from "components/tableComponents/staffMembersTable";
import SearchField from "components/tableComponents/paginationSearchField";

const StaffMembersView: FC = () => {
    const memberPagination = usePaginationStore("members", undefined, {
        page_size: 10,
        default_page_size: 10,
        search_by: "staff",
    });

    return (
        <div className="contentWrapper">
            <Banner
                title="members"
                subTitle="bannerStaffMembers"
                image={`${process.env.PUBLIC_URL}/assets/images/1024x683/subscriptions-1024x683.jpg`}
            />
            <TopBar searchField={<SearchField pagination={memberPagination} tag={"searchMembers"} />} />
            <StaffMembersTable pagination={memberPagination} />
        </div>
    );
};
export default StaffMembersView;
