import React, { FC, ReactNode, Reducer, createContext, useCallback, useContext, useMemo, useReducer } from "react";
import webAPIRequest from "api";
import { NotificationContext } from "contexts/notification";
import reducer, {
    IAction,
    IBookkeepingAccount,
    initialState,
    IProduct,
    IProducts,
    IServiceSection,
} from "reducers/products";

export const ProductsContext = createContext<IProducts>({
    ...initialState,
});

export const ProductsProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const { ...notification } = useContext(NotificationContext);
    const [currentState, dispatch] = useReducer<Reducer<IProducts, IAction>>(reducer, initialState);

    const fetchProduct = useCallback(
        async (productKey: string, skipErrorNotification = false): Promise<IProduct> => {
            try {
                dispatch({ type: "FETCH_PRODUCT" });
                const returnData = await webAPIRequest("get", `/products/${productKey}/`);
                dispatch({
                    type: "FETCH_PRODUCT_SUCCESS",
                    product: returnData.data,
                });
                return returnData.data;
            } catch (error) {
                if (!skipErrorNotification) {
                    notification.enqueNotification("error_fetchProduct", error);
                }
                dispatch({ type: "FETCH_PRODUCT_FAILURE" });
                return {} as IProduct;
            }
        },
        [notification]
    );

    const updateProduct = useCallback(
        async (productKey: string, data: Record<string, unknown>): Promise<IProduct> => {
            try {
                dispatch({ type: "UPDATE_PRODUCT" });
                const returnData = await webAPIRequest("patch", `/products/${productKey}/`, { data });
                dispatch({ type: "UPDATE_PRODUCT_SUCCESS", product: returnData.data });
                notification.enqueNotification("success_updateProduct");
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_updateProduct", error);
                dispatch({ type: "UPDATE_PRODUCT_FAILURE" });
                return {} as IProduct;
            }
        },
        [notification]
    );

    const createProduct = useCallback(
        async (data: Record<string, unknown>): Promise<IProduct> => {
            try {
                dispatch({ type: "CREATE_PRODUCT" });
                const returnData = await webAPIRequest("post", "/products/", { data });
                dispatch({ type: "CREATE_PRODUCT_SUCCESS", product: returnData.data });
                notification.enqueNotification("success_createProduct");
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_createProduct", error);
                dispatch({ type: "CREATE_PRODUCT_FAILURE" });
                return {} as IProduct;
            }
        },
        [notification]
    );

    const fetchAccounts = useCallback(
        async (params?: Record<string, unknown>): Promise<IBookkeepingAccount[]> => {
            try {
                dispatch({ type: "FETCH_ACCOUNTS" });
                const returnData = await webAPIRequest("get", "/products/accounts/", {
                    params,
                });
                dispatch({
                    type: "FETCH_ACCOUNTS_SUCCESS",
                    accounts: returnData.data,
                });
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_fetchBookkeepingAccounts");
                dispatch({ type: "FETCH_ACCOUNTS_FAILURE" });
                return [] as IBookkeepingAccount[];
            }
        },
        [notification]
    );

    const fetchServiceSections = useCallback(
        async (params?: Record<string, unknown>): Promise<IServiceSection[]> => {
            try {
                dispatch({ type: "FETCH_SERVICE_SECTIONS" });
                const returnData = await webAPIRequest("get", "/products/service-sections/", {
                    params,
                });
                dispatch({
                    type: "FETCH_SERVICE_SECTIONS_SUCCESS",
                    serviceSections: returnData.data,
                });
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_fetchServiceAccounts");
                dispatch({ type: "FETCH_SERVICE_SECTIONS_FAILURE" });
                return [] as IServiceSection[];
            }
        },
        [notification]
    );

    const value = useMemo(() => {
        return {
            ...currentState,
            fetchProduct,
            updateProduct,
            createProduct,
            fetchAccounts,
            fetchServiceSections,
        };
    }, [currentState, fetchProduct, updateProduct, createProduct, fetchAccounts, fetchServiceSections]);

    return <ProductsContext.Provider value={value}>{children}</ProductsContext.Provider>;
};
