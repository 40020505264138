import React, { FC, useContext } from "react";
import { LanguageContext } from "contexts/language";
import { DesktopDatePickerProps } from "@mui/x-date-pickers";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { sv, enGB } from "date-fns/locale";


export const DatePicker: FC<DesktopDatePickerProps<any>> = ({ ...props }) => {
    const { currentLanguage } = useContext(LanguageContext);
    const locale = currentLanguage === "sv" ? sv : enGB;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
            <DesktopDatePicker {...props} />
        </LocalizationProvider>
    );
};
