import React from "react";
import MaterialDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ListItemText from "@mui/material/ListItemText";
import { LocalizeText } from "components/localizer";
import { Box, styled } from "@mui/material";
import colors from "styles/colors";

const width = 500;

const DrawerHeader = styled("div")(() => ({
    toolbar: {
        minHeight: 56,
    },
    display: "flex",
    backgroundColor: colors.vkBlue,
    color: colors.white,
    minHeight: "130px !important",
    maxHeight: "130px !important",
    paddingLeft: 30,
    position: "relative",
    overflow: "hidden",

    "& h2": {
        fontFamily: "Vasakronan",
        fontWeight: "normal",
        alignSelf: "center",
    },
}));

const DrawerContent = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    padding: "0 30px",
    height: "100%",

    "& .MuiListItem-gutters": {
        paddingLeft: 0,
        paddingRight: 0,
    },
    "& hr": {
        margin: "3px 0",
    },
    "& .MuiTypography-body1": {
        color: colors.black,
    },
    "& .MuiTypography-body2": {
        color: colors.black,
        lineHeight: 1.2,
    },
    "& .BottomRow": {
        display: "flex",
        minHeight: 100,
        marginTop: "auto",
        alignItems: "center",
        justifyContent: "flex-end",

        "& button": {
            width: "100%",
            maxWidth: 250,
            margin: "0 20px 0 0",
        },
    },
}));


const CloseButton = styled(IconButton)(() => ({
    color: colors.white,
    position: "absolute",
    top: 0,
    left: 0,
    width: 30,
    height: 30,
    padding: 0,

    "& svg": {
        width: 20,
        height: 20,
    },
}));


const HeaderSvg = styled("img")(() => ({
    height: "100%",
    objectFit: "cover",
    marginLeft: "auto",
}));


interface IChangeUser {
    isOpen: boolean;
    onClose: () => void;
    title: string | React.ReactNode;
    noData?: boolean;
    children?: React.ReactNode;
}
const Drawer: React.FC<IChangeUser> = ({ isOpen, onClose, children, title, noData }) => (
    <Box minWidth={width}>
        <MaterialDrawer
            sx={{
                width: width,
                flexShrink: 0,
            }}
            PaperProps={{
                sx: {
                    width: width
                }
            }}
            anchor="right"
            open={isOpen}
            onClose={() => onClose()}
        >
            <DrawerHeader>
                <CloseButton
                    data-testid="drawer-closeButton"
                    onClick={() => onClose()}
                    size="large">
                    <CloseIcon />
                </CloseButton>
                <h2 data-testid="drawer-title" >{title}</h2>
                <HeaderSvg
                    src={`${process.env.PUBLIC_URL}/assets/svg/drawer_header.svg`}
                    alt="Illustration"
                />
            </DrawerHeader>
            {!noData ? (
                <DrawerContent>{children}</DrawerContent>
            ) : (
                <ListItemText
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "50px"
                    }}
                    secondary={<LocalizeText tag="noDataAvailable" />}
                />
            )}
        </MaterialDrawer>
    </Box>
);

export default Drawer;
