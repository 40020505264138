import { FetchUserProfileResponse } from "contexts/profile";
import PaginationStore, { TPaginationActionData } from "utils/paginationStore";
import { IOrganization } from "./memberships";
import { ILeaseRequest } from "./leases";

export type UserLandingPage = "default" | "community";

export interface IMembershipKey {
    id: number;
}

export interface IProfileNotification {
    uuid: string;
    target: string;
    timestamp: string;
}

export interface IProfileNotificationCount {
    target: string;
    amount: number;
}

export interface IOrganizationOption {
    name: string;
    value: string;
    id: number;
}

export interface IOrganizationCollection {
    id: number;
    content_type_id: number;
    org_nr: string;
    name: string;
}

export interface IUser {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    token: string;
    expires: string;
    base_url: string;
    memberships: IMembershipKey[];
    is_staff: boolean;
    has_community_landing_page: boolean;
    send_news_by_mail: boolean;
}

export type IPremiseContract = {
    id: number;
    uuid: string;
    leases: string[];
    organization: IOrganization;
    agreement_nr: string;
    agreement_document_nr: string;
    agreement_document_file: string;
    start_date: string;
    end_date: string;
    created: string;
    updated: string;
};

export type TUpdateProfileFields = {
    first_name?: string;
    last_name?: string;
    phone?: string;
    send_news_by_mail?: boolean;
};

export type TChangePasswordFields = {
    old_password?: string;
    new_password?: string;
    new_password_again?: string;
};

export interface IProfile {
    user?: IUser;
    selectedOrganization: IOrganizationCollection;
    permissions: string[];
    membershipIds?: number[];
    organizations?: IOrganizationCollection[];
    premiseContractsPagination: PaginationStore<IPremiseContract>;
    pendingLeaseRequestsPagination: PaginationStore<ILeaseRequest>;
    notificationCounts: IProfileNotificationCount[];
    notifications: IProfileNotification[];
    landingPage: UserLandingPage;
    isInitiated: boolean;
    isFetchingProfile: boolean;
    isUpdatingProfile: boolean;
    isFetchingPremiseContracts: boolean;
    isFetchingOrgs: boolean;
    isUpdatingPassword: boolean;
    isFetchingPendingLeaseRequests: boolean;
    isFetchingOrgPermissions: boolean;
    isFetchingNotifications: boolean;
    fetchUserProfile: () => Promise<FetchUserProfileResponse>;
    updateUserProfile: (data: TUpdateProfileFields) => Promise<FetchUserProfileResponse>;
    clearProfileStorage: () => void;
    changeUserPassword: (data: TChangePasswordFields) => Promise<boolean>;
    fetchPremiseContracts: (params?: Record<string, unknown>) => Promise<TPaginationActionData<IPremiseContract>>;
    setSelectedOrganization: (org: IOrganizationCollection) => void;
    clearSelectedOrganization: () => void;
    fetchOrganizations: () => Promise<IOrganizationCollection[]>;
    fetchPendingLeaseRequests: (params?: Record<string, unknown>) => Promise<TPaginationActionData<ILeaseRequest>>;
    fetchOrganizationPermissions: (org_nr: string) => Promise<string[]>;
    fetchProfileNotifications: (org_nr: string) => Promise<void>;
}

const loadLandingPage = (has_community_landing_page: boolean): UserLandingPage => {
    if (has_community_landing_page) {
        const landingPage = window.localStorage.getItem("landingPage");
        if (landingPage && ["community", "default"].includes(landingPage)) {
            return landingPage as UserLandingPage;
        }
        return "community";
    }
    return "default";
};

export const initialState: IProfile = {
    user: undefined,
    organizations: undefined,
    premiseContractsPagination: new PaginationStore<IPremiseContract>(),
    pendingLeaseRequestsPagination: new PaginationStore<ILeaseRequest>(),
    permissions: [],
    notificationCounts: [],
    notifications: [],
    landingPage: "default" as UserLandingPage,
    isInitiated: false,
    isFetchingProfile: false,
    isUpdatingProfile: false,
    isFetchingPremiseContracts: false,
    isFetchingOrgs: false,
    isUpdatingPassword: false,
    isFetchingPendingLeaseRequests: false,
    isFetchingOrgPermissions: false,
    isFetchingNotifications: false,
    selectedOrganization: {} as IOrganizationCollection,
    fetchOrganizationPermissions: async (): Promise<string[]> => [],
    fetchUserProfile: async (): Promise<FetchUserProfileResponse> => ({} as FetchUserProfileResponse),
    updateUserProfile: async (): Promise<FetchUserProfileResponse> => ({} as FetchUserProfileResponse),
    clearProfileStorage: (): void => {},
    changeUserPassword: async (): Promise<boolean> => false,
    fetchPremiseContracts: async (): Promise<TPaginationActionData<IPremiseContract>> =>
        ({} as TPaginationActionData<IPremiseContract>),
    setSelectedOrganization: (org: IOrganizationCollection): void => {},
    clearSelectedOrganization: (): void => {},
    fetchOrganizations: async (): Promise<IOrganizationCollection[]> => [],
    fetchPendingLeaseRequests: async (): Promise<TPaginationActionData<ILeaseRequest>> =>
        ({} as TPaginationActionData<ILeaseRequest>),
    fetchProfileNotifications: async (): Promise<void> => {},
};

export type IAction =
    | { type: "FETCH_PROFILE" }
    | { type: "FETCH_PROFILE_SUCCESS"; user: IUser }
    | { type: "FETCH_PROFILE_FAILURE" }
    | { type: "UPDATE_PROFILE" }
    | { type: "UPDATE_PROFILE_SUCCESS"; user: IUser }
    | { type: "UPDATE_PROFILE_FAILURE" }
    | { type: "CHANGE_PASSWORD" }
    | { type: "CHANGE_PASSWORD_SUCCESS" }
    | { type: "CHANGE_PASSWORD_FAILURE" }
    | { type: "SET_ORGANIZATION"; org: IOrganizationCollection }
    | { type: "CLEAR_ORGANIZATION" }
    | { type: "CLEAR_STORAGE" }
    | { type: "FETCH_PREMISE_CONTRACTS" }
    | { type: "FETCH_PREMISE_CONTRACTS_SUCCESS"; data: TPaginationActionData<IPremiseContract> }
    | { type: "FETCH_PREMISE_CONTRACTS_FAILURE" }
    | { type: "FETCH_ORGANIZATIONS" }
    | { type: "FETCH_ORGANIZATIONS_SUCCESS"; organizations: IOrganization[] }
    | { type: "FETCH_ORGANIZATIONS_FAILURE" }
    | { type: "FETCH_PENDING_LEASE_REQUESTS" }
    | { type: "FETCH_PENDING_LEASE_REQUESTS_SUCCESS"; data: TPaginationActionData<ILeaseRequest> }
    | { type: "FETCH_PENDING_LEASE_REQUESTS_FAILURE" }
    | { type: "FETCH_PERMISSIONS" }
    | { type: "FETCH_PERMISSIONS_SUCCESS"; permissions: string[] }
    | { type: "FETCH_PERMISSIONS_FAILURE" }
    | { type: "FETCH_NOTIFICATIONS" }
    | {
          type: "FETCH_NOTIFICATIONS_SUCCESS";
          counted: IProfileNotificationCount[];
          notifications: IProfileNotification[];
      }
    | { type: "CHANGE_LANDING_PAGE"; landingPage: UserLandingPage }
    | { type: "FETCH_NOTIFICATIONS_FAILURE" };

function reducer(state: IProfile, action: IAction): IProfile {
    switch (action.type) {
        case "FETCH_PROFILE":
            return { ...state, isFetchingProfile: true };
        case "FETCH_PROFILE_SUCCESS": {
            const landingPage = loadLandingPage(action.user.has_community_landing_page);
            window.localStorage.setItem("landingPage", landingPage);
            return {
                ...state,
                user: action.user,
                isFetchingProfile: false,
                isInitiated: true,
                landingPage: landingPage,
                membershipIds: action.user.memberships.map((membership) => membership.id),
            };
        }
        case "FETCH_PROFILE_FAILURE":
            return { ...state, isFetchingProfile: false };

        case "UPDATE_PROFILE":
            return { ...state, isUpdatingProfile: true };
        case "UPDATE_PROFILE_SUCCESS":
            return {
                ...state,
                user: action.user,
                isUpdatingProfile: false,
                membershipIds: action.user.memberships.map((membership) => membership.id),
            };
        case "UPDATE_PROFILE_FAILURE":
            return { ...state, isUpdatingProfile: false };

        case "CHANGE_PASSWORD":
            return { ...state, isUpdatingPassword: true };
        case "CHANGE_PASSWORD_SUCCESS":
        case "CHANGE_PASSWORD_FAILURE":
            return { ...state, isUpdatingPassword: false };

        case "FETCH_PREMISE_CONTRACTS":
            return { ...state, isFetchingPremiseContracts: true };
        case "FETCH_PREMISE_CONTRACTS_SUCCESS":
        case "FETCH_PREMISE_CONTRACTS_FAILURE":
            return { ...state, isFetchingPremiseContracts: false };

        case "SET_ORGANIZATION":
            return {
                ...state,
                selectedOrganization: action.org || ({} as IOrganizationCollection),
            };

        case "CLEAR_ORGANIZATION":
            return {
                ...state,
                permissions: [],
            };

        case "FETCH_ORGANIZATIONS":
            return { ...state, isFetchingOrgs: true };
        case "FETCH_ORGANIZATIONS_SUCCESS":
            return {
                ...state,
                isFetchingOrgs: false,
                organizations: action.organizations,
            };
        case "FETCH_ORGANIZATIONS_FAILURE":
            return { ...state, isFetchingOrgs: false };

        case "FETCH_PENDING_LEASE_REQUESTS":
            return { ...state, isFetchingPendingLeaseRequests: true };
        case "FETCH_PENDING_LEASE_REQUESTS_SUCCESS":
        case "FETCH_PENDING_LEASE_REQUESTS_FAILURE":
            return { ...state, isFetchingPendingLeaseRequests: false };

        case "FETCH_PERMISSIONS":
            return { ...state, isFetchingOrgPermissions: true };
        case "FETCH_PERMISSIONS_SUCCESS":
            return {
                ...state,
                isFetchingOrgPermissions: false,
                permissions: action.permissions,
            };
        case "FETCH_PERMISSIONS_FAILURE":
            return { ...state, isFetchingOrgPermissions: false };

        case "FETCH_NOTIFICATIONS":
            return { ...state, isFetchingNotifications: true };
        case "FETCH_NOTIFICATIONS_SUCCESS":
            return {
                ...state,
                isFetchingNotifications: false,
                notifications: action.notifications,
                notificationCounts: action.counted,
            };
        case "FETCH_NOTIFICATIONS_FAILURE":
            return { ...state, isFetchingNotifications: false };

        case "CHANGE_LANDING_PAGE":
            return { ...state, landingPage: action.landingPage };

        case "CLEAR_STORAGE":
            return {
                ...initialState,
                premiseContractsPagination: new PaginationStore<IPremiseContract>(),
                pendingLeaseRequestsPagination: new PaginationStore<ILeaseRequest>(),
            };
        default:
            return { ...state };
    }
}

export default reducer;
