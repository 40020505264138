import React from "react";
import { LocalizeText } from "components/localizer";
import { THeadCell } from "utils/types";
import { RoleManagementContext } from "contexts/roleManagement";
import { DomainContentType, IDomainUser } from "reducers/roleManagement";
import TableComponent, { IRow } from "components/tableComponent";
import { ProfileContext } from "contexts/profile";
import { IOrganizationCollection } from "reducers/profile";
import RoleManagementLeaseTable from "../tables/roleManagementLeaseTable";
import RoleManagementMembershipTable from "../tables/roleManagementMembershipTable";
import { Grid, Typography } from "@mui/material";
import SearchField from "components/tableComponents/paginationSearchField";
import { TPaginationActionData, TParamValue } from "utils/paginationStore";

interface DomainUserCollapseProps {
    user: IDomainUser;
    organization: IOrganizationCollection;
    showTenant: boolean;
    showArenaMember: boolean;
}

const DomainUserCollapse: React.FC<DomainUserCollapseProps> = ({ user, organization, showArenaMember, showTenant }) => {
    const leaseParams: Record<string, TParamValue> = {
        org_nr: organization.org_nr,
        by_user_email: user.email,
    };

    const membershipParams: Record<string, TParamValue> = {
        search: organization.org_nr,
        by_user_email: user.email,
    };

    return (
        <div>
            {showArenaMember && <RoleManagementMembershipTable paginationParams={membershipParams} />}
            {showTenant && <RoleManagementLeaseTable showSearch={false} paginationParams={leaseParams} />}
        </div>
    );
};

interface IUsersWithhinOrgTabProps {
    showTenant: boolean;
    showArenaMember: boolean;
}

const UsersWithinOrganizationTab: React.FC<IUsersWithhinOrgTabProps> = () => {
    const { fetchUsersOfDomain, isFetchingDomainUsers, usersPagination } = React.useContext(RoleManagementContext);
    const { selectedOrganization } = React.useContext(ProfileContext);

    React.useEffect(() => {
        usersPagination.initialize(paginationCallback);
        usersPagination.setParam("domain_object_id", selectedOrganization.id);
        usersPagination.getInitial();
        // eslint-disable-next-line
    }, []);

    const paginationCallback = async (params: Record<string, unknown>): Promise<TPaginationActionData<IDomainUser>> => {
        return await fetchUsersOfDomain(DomainContentType.OrganizationCollection, params);
    };

    const domainUserHeadCells: THeadCell[] = [
        { id: "name", label: <LocalizeText tag="name" /> },
        { id: "email", label: <LocalizeText tag="email" /> },
        { id: "phone", label: <LocalizeText tag="phone" /> },
    ];
    const mapRows = (users: IDomainUser[]): IRow[] => {
        return users.map((user: IDomainUser) => ({
            data: [`${user.first_name} ${user.last_name}`, user.email, user.phone],
            collapsibleContent: (
                <DomainUserCollapse user={user} showArenaMember showTenant organization={selectedOrganization} />
            ),
        }));
    };

    return (
        <>
            <Grid container alignItems="center" style={{ marginBottom: "1em" }}>
                <Grid item xs={6}>
                    <Typography variant="h6">
                        <LocalizeText tag="organization" />
                    </Typography>
                </Grid>
                <Grid container item xs={6} justifyContent="flex-end">
                    <SearchField pagination={usersPagination} tag="searchUser" />
                </Grid>
            </Grid>
            <TableComponent
                headCells={domainUserHeadCells}
                isLoading={isFetchingDomainUsers}
                rows={mapRows(usersPagination.results)}
                skeletonVariant="options"
                pagination={usersPagination}
                collapsible
            />
        </>
    );
};

export default UsersWithinOrganizationTab;
