import React from "react";
import { LocalizeText } from "components/localizer";
import { THeadCell } from "utils/types";
import TopBar from "components/tableComponents/topBar";
import Banner from "components/banner";
import CommunityContext from "contexts/community";
import TableComponent, { IRow } from "components/tableComponent";
import { Box, Tab, Tabs } from "@mui/material";
import SearchField from "components/tableComponents/paginationSearchField";
import Dropdown from "components/dropdown";
import { ModalContext } from "contexts/modals";
import UpdateContactPersonInvitation from "components/drawers/updateContactPersonInvite";
import { TLanguageTag } from "contexts/language";

const representativeHeadCells: THeadCell[] = [
    { id: "name", sortId: "first_name", label: <LocalizeText tag="name" /> },
    { id: "email", sortId: "email", label: <LocalizeText tag="email" /> },
    { id: "communities", label: <LocalizeText tag="organization" /> },
    { id: "", label: "", alignCell: "right" },
];

const representativeInvitationsHeadCells: THeadCell[] = [
    { id: "name", sortId: "first_name", label: <LocalizeText tag="name" /> },
    { id: "email", sortId: "email", label: <LocalizeText tag="email" /> },
    { id: "communities", label: <LocalizeText tag="organization" /> },
    { id: "", label: "", alignCell: "right" },
];

type RepresentativesTab = "USERS" | "INVITATIONS";

const StaffRepresentativesView: React.FC = () => {
    const {
        representativeInvitationPagination,
        isFetchingRepresentativeInvitations,
        isFetchingRepresentatives,
        representativePagination,
    } = React.useContext(CommunityContext);
    const { openDrawer } = React.useContext(ModalContext);
    const [selectedTab, setTab] = React.useState<RepresentativesTab>("USERS");

    React.useEffect(() => {
        representativePagination.getInitial();
        representativeInvitationPagination.getInitial();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const mapRepresentativeStaffRows = (): IRow[] =>
        representativePagination.results.map((person, index) => {
            return {
                data: [
                    person.full_name,
                    person.email,
                    person.memberships ? person.memberships.flatMap((memberships) => memberships.name).join(", ") : "-",
                ],
            };
        });

    const mapRepresentativeInvitationsRows = (): IRow[] =>
        representativeInvitationPagination.results.map((invite, index) => {
            return {
                data: [
                    invite.full_name,
                    invite.email,
                    invite.organization ? invite.organization.name : "-",
                    <Dropdown
                        inRow
                        key={invite.uuid}
                        items={[
                            {
                                onClick: () =>
                                    openDrawer(UpdateContactPersonInvitation, { user: invite }, "invitations"),
                                label: <LocalizeText tag="showInvitation" />,
                            },
                        ]}
                    />,
                ],
            };
        });

    /* eslint-disable indent */
    const searchFieldProps =
        selectedTab === "USERS"
            ? {
                  pagination: representativePagination,
                  tag: "searchRepresentatives" as TLanguageTag,
              }
            : {
                  pagination: representativeInvitationPagination,
                  tag: "searchInvites" as TLanguageTag,
              };
    /* eslint-enable indent */

    return (
        <div data-testid="representative-root" className="contentWrapper">
            <Banner
                title="representative"
                subTitle="bannerRepresentativesList"
                image={`${process.env.PUBLIC_URL}/assets/images/1024x683/memberships-1024x683.jpg`}
            />
            <TopBar searchField={<SearchField {...searchFieldProps} />} />
            <Box>
                <Tabs value={selectedTab} onChange={(event, value) => setTab(value)}>
                    <Tab label={<LocalizeText tag="representative" />} value="USERS" />
                    <Tab label={<LocalizeText tag="invitations" />} value="INVITATIONS" />
                </Tabs>
                {selectedTab === "USERS" && (
                    <TableComponent
                        headCells={representativeHeadCells}
                        isLoading={isFetchingRepresentatives}
                        rows={mapRepresentativeStaffRows()}
                        pagination={representativePagination}
                    />
                )}
                {selectedTab === "INVITATIONS" && (
                    <TableComponent
                        headCells={representativeInvitationsHeadCells}
                        isLoading={isFetchingRepresentativeInvitations}
                        rows={mapRepresentativeInvitationsRows()}
                        pagination={representativeInvitationPagination}
                    />
                )}
            </Box>
        </div>
    );
};

export default StaffRepresentativesView;
