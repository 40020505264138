import React, { RefObject, useRef }  from "react";

export const useIsObservable = <T extends Element>(settings: IntersectionObserverInit): [RefObject<T>, boolean] => {
    const container = useRef<T>(null);
    const [isObservable, setIsObservable] = React.useState<boolean>(false);
    
    const callback = (entries: IntersectionObserverEntry[]): void => {
        setIsObservable(entries[0].isIntersecting);
    };
    
    React.useEffect(() => {
        const containerRef = container.current;
        if (!containerRef) {
            return;
        }
        
        const observer = new IntersectionObserver(callback, settings);
        observer.observe(containerRef);

        return () => {
            if (containerRef) {
                observer.unobserve(containerRef);
            }
        };
    }, [container, settings]);

    return [container, isObservable];

};
