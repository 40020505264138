import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { LocalizeText } from "components/localizer";
import { Grid, Typography } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import VKButton from "components/vkButton";
import { LanguageContext } from "contexts/language";
import CommunityContext from "contexts/community";
import { DropdownItem, MultiSelectDropdown, SelectDropdown } from "components/selectDropdown";
import { TCommunity } from "utils/ecommerseTypes";
import { StatsBarGraph } from "components/statistics/statsBarGraph";
import {
    TCommunityUuid,
    FetchedMonth,
    TMonthKey,
    calcKeysAndLabels,
    calcMonthMissingCommunities,
    getMonetaryStats,
    getMonthlyMonetaryStats,
    getSubscriptionCountStats,
    getSubscriptionUsageStats,
    parseMoneyData,
    getDatasets,
    tooltipCallback,
} from "components/statistics/statsUtils";
import { IStatExpectedRevenue, IStatTotalInvoiced } from "adapters/statsAdapter";
import { prettifyPriceText } from "components/prettifyPrice";
import { TMembershipId } from "reducers/memberships";
import { OverusageList } from "components/statistics/overusageList";
import colors from "styles/colors";
import HasPermission from "components/__helpers__/hasPermission";

const STORAGE_KEY = "stats_selected_communities";

export const StatisticsView: FC = () => {
    const { localize, currentLanguage } = useContext(LanguageContext);
    const { communitiesPagination } = useContext(CommunityContext);
    const [initialized, setInitialized] = useState(false);
    const [selectedCommunities, setSelectedCommunities] = useState<{ uuid: string; title: string }[]>([]);
    const [fetchedMonths, setFetchedMonths] = useState<Record<TMonthKey, Record<TCommunityUuid, FetchedMonth>>>({});
    const [isFetchingMonths, setIsFetchingMonths] = useState<Record<TMonthKey, TCommunityUuid[]>>({});
    const [monthOffset, setMonthOffset] = useState(0);
    const [visibleMonths, setVisibleMonths] = useState(3);
    const [monthKeys, setMonthKeys] = useState<TMonthKey[]>([]);
    const [labels, setLabels] = useState<string[]>([]);
    const [overusage, setOverusage] = useState<Record<TMonthKey, TMembershipId[]>>({});

    const fetchMissingMonthsData = useCallback(
        async (communities: TCommunity[], monthKeys: TMonthKey[]) => {
            if (communities.length === 0) {
                return;
            }

            const missingCommunities = calcMonthMissingCommunities(
                monthKeys,
                communities,
                fetchedMonths,
                isFetchingMonths
            );

            setIsFetchingMonths((fetching) => {
                Object.entries(missingCommunities).forEach(([monthKey, communities]) => {
                    fetching[monthKey] = [...(fetching[monthKey] || []), ...communities];
                });
                return fetching;
            });

            const revenueData: IStatExpectedRevenue[] = [];
            const invoicedData: IStatTotalInvoiced[] = [];
            if (!initialized) {
                await getMonetaryStats(
                    monthKeys[0],
                    monthKeys[monthKeys.length - 1],
                    communities.map((c) => c.uuid).join(),
                    revenueData,
                    invoicedData
                );
            } else {
                await getMonthlyMonetaryStats(missingCommunities, revenueData, invoicedData);
            }

            const newData = parseMoneyData(
                monthKeys,
                communities.map((c) => c.uuid),
                revenueData,
                invoicedData
            );

            const newOverusage: Record<TMonthKey, TMembershipId[]> = {};
            await getSubscriptionCountStats(missingCommunities, newData);
            await getSubscriptionUsageStats(missingCommunities, newData, newOverusage);
            setOverusage((previous) => {
                return { ...previous, ...newOverusage };
            });

            setIsFetchingMonths((fetching) => {
                const result = { ...fetching };
                Object.entries(missingCommunities).forEach(([monthKey, communities]) => {
                    const remaining = result[monthKey].filter((uuid) => !communities.includes(uuid));
                    result[monthKey] = remaining;
                });
                return result;
            });

            setFetchedMonths((fetched) => {
                const result = { ...fetched };
                Object.entries(missingCommunities).forEach(([monthKey, uuids]) => {
                    result[monthKey] = result[monthKey] || {};
                    uuids.forEach((uuid) => {
                        result[monthKey][uuid] = newData[monthKey][uuid];
                    });
                });
                return result;
            });
        },
        [fetchedMonths, isFetchingMonths, initialized]
    );

    const setSelectedCommunitiesSorted = useCallback(
        (communities: TCommunity[]) => {
            setSelectedCommunities(
                communities
                    .map(({ uuid, title }) => ({
                        uuid,
                        title,
                    }))
                    .sort((a, b) => a.title.localeCompare(b.title))
            );
        },
        [setSelectedCommunities]
    );

    useEffect(() => {
        communitiesPagination.getInitial();
        const { monthKeys, labels } = calcKeysAndLabels(monthOffset, visibleMonths, currentLanguage);
        setMonthKeys(monthKeys);
        setLabels(labels);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (communitiesPagination.results.length === 0) {
            return;
        }

        const str = localStorage.getItem(STORAGE_KEY);
        let communities: TCommunity[] = [];
        if (!str) {
            communities = communitiesPagination.results;
        } else {
            const uuids: TCommunityUuid[] = JSON.parse(str);
            uuids.forEach((uuid) => {
                const c = communitiesPagination.results.find((c) => c.uuid === uuid);
                if (c) {
                    communities.push(c);
                }
            });
        }
        (async () => {
            const { monthKeys } = calcKeysAndLabels(monthOffset, visibleMonths, currentLanguage);
            await fetchMissingMonthsData(communities, monthKeys);
            setSelectedCommunitiesSorted(communities);
            setInitialized(true);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [communitiesPagination.results]);

    const datasets = useMemo(() => {
        return getDatasets(monthKeys, fetchedMonths, selectedCommunities, localize);
    }, [fetchedMonths, monthKeys, localize, selectedCommunities]);

    const updateData = useCallback(
        (monthOffset: number, visibleMonths: number) => {
            const { monthKeys, labels } = calcKeysAndLabels(monthOffset, visibleMonths, currentLanguage);
            setMonthKeys(monthKeys);
            setLabels(labels);
            const communities = selectedCommunities.map(({ uuid }) =>
                communitiesPagination.results.find((c) => c.uuid === uuid)
            ) as TCommunity[];
            fetchMissingMonthsData(communities, monthKeys);
        },
        [fetchMissingMonthsData, selectedCommunities, communitiesPagination.results, currentLanguage]
    );

    const onChangeOffset = useCallback(
        async (dir: number): Promise<void> => {
            const newOffset = monthOffset + dir;
            setMonthOffset(newOffset);
            updateData(newOffset, visibleMonths);
        },
        [setMonthOffset, monthOffset, visibleMonths, updateData]
    );

    const dropdownItems = useMemo((): DropdownItem[] => {
        return communitiesPagination.results
            .map((c) => ({
                id: c.uuid,
                primary: c.title,
            }))
            .sort((a, b) => a.primary.localeCompare(b.primary));
    }, [communitiesPagination.results]);

    const labelsWithoutStars = useMemo(() => {
        return labels.map((l) => l.replace("*", ""));
    }, [labels]);

    return (
        <div className="contentWrapper">
            <Typography variant="h4">
                <LocalizeText tag="statistics" />
            </Typography>
            <Grid container direction="column" gap="20px">
                <Grid item container justifyContent="space-between">
                    <VKButton variant="outlined" size="small" onClick={() => onChangeOffset(-1)}>
                        <ChevronLeft />
                        <LocalizeText tag="previousMonth" />
                    </VKButton>

                    {communitiesPagination.results.length > 1 ? (
                        <MultiSelectDropdown
                            selectedIds={selectedCommunities.map(({ uuid }) => uuid)}
                            onToggle={(ids) => {
                                const communities = ids.map((uuid) =>
                                    communitiesPagination.results.find((c) => c.uuid === uuid)
                                ) as TCommunity[];
                                fetchMissingMonthsData(communities, monthKeys);
                                setSelectedCommunitiesSorted(communities);
                                localStorage.setItem(STORAGE_KEY, JSON.stringify(ids));
                            }}
                            items={dropdownItems}
                            noSelectionTag="selectArena"
                        />
                    ) : null}

                    <SelectDropdown
                        selectedId={visibleMonths.toString()}
                        items={[3, 6, 9, 12].map((count) => ({
                            id: count.toString(),
                            primary: (
                                <>
                                    {count.toString()} <LocalizeText tag="months" />
                                </>
                            ),
                        }))}
                        onSelect={(count) => {
                            const newVisibleMonths = parseInt(count);
                            setVisibleMonths(newVisibleMonths);
                            updateData(monthOffset, newVisibleMonths);
                        }}
                        width="150px"
                    />

                    <VKButton variant="outlined" size="small" onClick={() => onChangeOffset(1)}>
                        <LocalizeText tag="nextMonth" />
                        <ChevronRight />
                    </VKButton>
                </Grid>
                <Grid item>
                    <StatsBarGraph
                        titleTag="invoicedGraphTitle"
                        infoText="invoicedGraphExplanation"
                        data={{ datasets: datasets.invoiced, labels }}
                        tooltipCallback={(item) =>
                            tooltipCallback(
                                item,
                                datasets.invoiced.length / selectedCommunities.length,
                                "money",
                                localize
                            )
                        }
                        yAxisCallback={(value) => `${prettifyPriceText(value, 0)} kr`}
                        showSkeleton={!initialized}
                        description={
                            <Typography variant="body2" textAlign="center">
                                * = <LocalizeText tag="notInvoicedYet" />
                            </Typography>
                        }
                    />
                </Grid>
                <Grid item>
                    <StatsBarGraph
                        titleTag="expectedRevenueGraphTitle"
                        infoText="expectedRevenueGraphExplanation"
                        data={{ datasets: datasets.revenue, labels: labelsWithoutStars }}
                        tooltipCallback={(item) =>
                            tooltipCallback(
                                item,
                                datasets.revenue.length / selectedCommunities.length,
                                "money",
                                localize
                            )
                        }
                        yAxisCallback={(value) => `${prettifyPriceText(value, 0)} kr`}
                        showSkeleton={!initialized}
                    />
                </Grid>
                <HasPermission requiredPerm="view_accessystatistics">
                    <Grid item bgcolor={colors.white}>
                        <StatsBarGraph
                            titleTag="subscriptionUsageGraphTitle"
                            infoText="subscriptionUsageGraphExplanation"
                            data={{ datasets: datasets.usage, labels: labelsWithoutStars }}
                            tooltipCallback={(item) =>
                                tooltipCallback(
                                    item,
                                    datasets.usage.length / selectedCommunities.length,
                                    "count",
                                    localize
                                )
                            }
                            showSkeleton={!initialized}
                        />
                        <OverusageList overusage={overusage} monthKeys={monthKeys} />
                    </Grid>
                </HasPermission>
                <Grid item>
                    <StatsBarGraph
                        titleTag="subscriptionsCount"
                        infoText="subscriptionsCountGraphExplanation"
                        data={{ datasets: datasets.count, labels: labelsWithoutStars }}
                        tooltipCallback={(item) =>
                            tooltipCallback(item, datasets.count.length / selectedCommunities.length, "count", localize)
                        }
                        showSkeleton={!initialized}
                    />
                </Grid>
            </Grid>
        </div>
    );
};
