import React, { FC, useContext, useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import VKButton from "components/vkButton";
import { TFormEvent } from "utils/types";
import { ProfileContext } from "contexts/profile";
import If from "components/__helpers__/if";
import { ListTextField, SelectBox, TItem } from "components/drawers/drawerComponents";
import { IContactPerson } from "reducers/memberships";
import Divider from "@mui/material/Divider";
import { LocalizeText } from "components/localizer";
import { DomainContentType, IRole } from "reducers/roleManagement";
import { RoleManagementContext } from "contexts/roleManagement";
import { TPaginationActionData } from "utils/paginationStore";
import { ILease } from "reducers/leases";
import { ModalContext } from "contexts/modals";

interface IEditContactObject {
    roleId: number;
}
interface IProps {
    person: IContactPerson;
    lease: ILease;
    onSubmit: (role_id: number) => Promise<boolean>;
    isLoading: boolean;
}
const EditLeaseContactPerson: FC<IProps> = ({ lease, person, onSubmit, isLoading }) => {
    const { rolesPagination, fetchRolesOfDomain, isFetchingRoles } = useContext(RoleManagementContext);
    const { fetchUserProfile } = useContext(ProfileContext);
    const { closeDrawers } = useContext(ModalContext);

    const initialState: IEditContactObject = {
        roleId: person.role ? person.role.id : -1,
    };
    const [state, setState] = useState(initialState);
    const [isEditing, setIsEditing] = useState(false);
    const loading = isLoading || isFetchingRoles;

    useEffect(() => {
        rolesPagination.initialize(rolesPaginationCallback);
        rolesPagination.appendResults = true;
        rolesPagination.setParam("domain_object_id", lease.id);
        rolesPagination.getInitial();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const rolesPaginationCallback = async (params: Record<string, unknown>): Promise<TPaginationActionData<IRole>> => {
        return fetchRolesOfDomain(DomainContentType.Lease, params);
    };

    const handleSubmit = async (event: TFormEvent): Promise<void> => {
        event.preventDefault();
        const success = await onSubmit(state.roleId);
        if (success) {
            fetchUserProfile();
            setIsEditing(false);
            closeDrawers();
        }
    };

    const onStartEdit = (): void => {
        setIsEditing(true);
    };

    const onCancelEdit = (): void => {
        setIsEditing(false);
        setState(initialState);
    };

    const mapRoles = (): TItem[] => {
        return rolesPagination.results.reduce((list: TItem[], role) => {
            list.push({
                name: role.display_name,
                value: role.id,
            });
            return list;
        }, []);
    };

    const canEdit = (): boolean => {
        return rolesPagination.totalCount ? rolesPagination.totalCount > 1 : false;
    };

    return (
        <div data-testid="editContactPerson-root">
            <List>
                <form onSubmit={handleSubmit}>
                    <ListTextField
                        testid="editContactPerson-header"
                        hasData
                        label={<LocalizeText tag="lease" />}
                        data={`${lease.agreement_number} - ${lease.popular_name_leasee}`}
                    />

                    <Divider />
                    <ListTextField
                        testid="editContactPerson-name"
                        label={<LocalizeText tag="name" />}
                        data={`${person.first_name} ${person.last_name}`}
                        hasData
                    />

                    <Divider />
                    <ListTextField
                        testid="editContactPerson-email"
                        hasData
                        label={<LocalizeText tag="email" />}
                        data={person.email}
                        type="email"
                    />

                    <Divider />
                    <ListTextField
                        testid="editContactPerson-phone"
                        hasData
                        label={<LocalizeText tag="phone" />}
                        data={person.phone}
                        type="phone"
                    />

                    <Divider />
                    <If truthy={isEditing}>
                        <ListTextField label={<LocalizeText tag="role" />} />
                        {rolesPagination && rolesPagination.totalCount === 1 ? (
                            <ListTextField
                                label={<LocalizeText tag="role" />}
                                hasData
                                isEditing={isEditing}
                                disabled
                                data={rolesPagination.results[0].display_name}
                                isLoading={loading}
                            />
                        ) : (
                            <SelectBox
                                label={<LocalizeText tag="role" />}
                                data={state.roleId > 0 ? String(state.roleId) : ""}
                                onEdit={(value: string) => setState({ ...state, roleId: Number(value) })}
                                items={mapRoles()}
                            />
                        )}
                    </If>
                    <If falsy={isEditing}>
                        <ListTextField
                            testid="editContactPerson-role"
                            label={<LocalizeText tag="role" />}
                            data={person.role ? person.role.display_name : "-"}
                            isLoading={loading}
                            hasData
                        />
                    </If>
                    <Divider />

                    <ListItem
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "8px",
                        }}
                    >
                        <If truthy={!isEditing}>
                            <VKButton
                                data-testid="editContactPerson-editButton"
                                tag="editInformation"
                                onClick={onStartEdit}
                                template="primary"
                                disabled={!canEdit()}
                            />
                        </If>
                        <If truthy={isEditing}>
                            <VKButton
                                data-testid="editContactPerson-submitButton"
                                template="save"
                                type="submit"
                                tag="save"
                                isLoading={loading}
                            />
                            <VKButton template="cancel" tag="cancel" onClick={onCancelEdit} />
                        </If>
                    </ListItem>
                </form>
            </List>
        </div>
    );
};

export default EditLeaseContactPerson;
