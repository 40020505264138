/* eslint-disable @typescript-eslint/no-explicit-any */
import Cookies from "js-cookie";
import axios, { Method, AxiosRequestConfig, AxiosResponse } from "axios";
import qs from "qs";
import config from "config";
import { delay } from "utils/helpers";

const webAPIRequest = async (
    method: Method,
    url: string,
    opts?: AxiosRequestConfig,
    mock?: boolean
): Promise<AxiosResponse> => {
    const apiPrefix = process.env.API_PREFIX ?? "";

    let language = localStorage.getItem("lang");
    if (!language) {
        language = navigator.language || "sv";
    }
    if (!config.mock && !mock) {
        const csrftoken = Cookies.get("csrftoken");
        axios.defaults.xsrfCookieName = "csrftoken";
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
        axios.defaults.withCredentials = true;
        const axiosConfig = {
            baseURL: `${config.apiURL}${apiPrefix}`,
            url,
            method,
            headers: {
                "Content-Type": "application/json",
                "Accept-Language": language,
                "X-CSRFTOKEN": csrftoken,
            },
            paramsSerializer: qs.stringify,
            ...opts,
        };
        try {
            return await axios.request(axiosConfig);
        } catch (error) {
            throw error;
        }
    }

    // Speed up test cases if not used
    if (config.useTimeout) {
        await delay(1000);
    }
    return {
        data: null,
        status: method === "get" ? 200 : 201,
        statusText: method,
        headers: {
            "Content-Type": "application/json",
        },
        config: opts ?? {},
    };
};

export default webAPIRequest;
