import { TNotificationDefinition } from "contexts/language";

export const en: TNotificationDefinition = {
    error_login_400: "Empty field username or password.",
    error_login_403: "Wrong username or password.",
    error_login_no_response: "Could not establish connection to server.",
    error_saveNewPassword: "Could not save new password.",
    error_sendPasswordLink: "Could not send password reset link.",
    error_addUser: "Could not connect users to subscription.",
    error_removeUser: "Could not delete user.",
    error_fetchMembershipInvitations: "Failed to fetch membership invitations.",
    error_fetchContactPersons: "Failed to fetch contacts.",
    error_addContactPerson: "Could not add contact person.",
    error_editContactPerson: "Could not edit contact person.",
    error_removeContactPerson: "Could not remove contact person.",
    error_contactPersonExist: "Contact person already exist.",
    error_removeInvitation: "Could not remove invitation.",
    error_fetchSubscription: "Could not retrieve subscription.",
    error_fetchSubscriptions: "Could not retrieve subscriptions.",
    error_updateSubscription: "Could not update subscription.",
    error_cancelSubscription: "Could not cancel subscription.",
    error_fetchSubscriptionsStatisticsCounts: "Could not retrieve subscriptions statistics counts.",
    error_fetchMembership: "Could not retrieve information about membership.",
    error_terminateMembership: "Could not terminate membership",
    error_fetchMemberships: "Could not retrieve memberships.",
    error_updateMembership: "Could not update membership information.",
    error_fetchCountryCodes: "Could not retrieve countries list.",
    error_fetchProfile: "Could not get profile",
    error_saveProfile: "Could not save profile.",
    error_fetchArenas: "Could not get arenas.",
    error_cancelArena: "Could not remove arena office.",
    error_updateArena: "Could not update arena office.",
    error_refreshCredentials: "Failed to update login details.",
    error_fetchFolios: "Could not retrieve folio.",
    error_setNationalAccess: "Could not set national access.",
    error_getNationalAccess: "Could not retrieve national access product.",
    error_removeNationalAccess: "Could not remove national access.",
    error_openBariumForm: "Could not open form.",
    error_server: "Something went wrong, please try again or wait a while.",
    error_fetchGuests: "Could not fetch guests.",
    error_fetchDayPasses: "Could not fetch day passes.",
    error_bookDayPass: "Could not book day pass.",
    error_removeDayPass: "Could not remove day pass.",
    error_fetchDayPassLimits: "Could not retrieve day pass limit.",
    error_saveDayPassLimits: "Could not save day pass limit.",
    error_fetchInvoiceMethods: "Could not retrieve settings for folio.",
    error_fetchEmailsCSV: "Could not retrieve file with CSV data.",
    error_createExternalMembership: "Could not create external customer.",
    error_bills: "Could not fetch bills.",
    error_fetchHosts: "Could not retrieve hosts to day passes.",
    error_saveInvoiceMethods: "Could not save payment method.",
    error_fetchFolioSettings: "Could not retrieve maximum allowed credit.",
    error_saveFolioSettings: "Could not save maximum allowed credit.",
    error_saveBillingAddress: "Could not save billing address.",
    error_fetchBillingAddress: "Could not fetch billing address.",
    error_fetchLeases: "Could not retrieve leases.",
    error_fetchCustomerLedgers: "Could not retrieve ledgers.",
    error_fetchCustomerLedgerEntries: "Could not retrieve detailed ledger information.",
    error_fetchBuildings: "Could not retrieve buildings.",
    error_fetchLeasesInfo: "Could not retrieve information about lease.",
    error_fetchBuildingInfo: "Could not retrieve practical information about the building.",
    error_fetchBuildingImages: "Could not retrieve images on building.",
    error_fetchCommunities: "Could not retrieve communities.",
    error_fetchAvailableDates: "Could not retrieve available dates.",
    error_fetchTac: "Could not retrieve Terms and conditions",
    error_fetchBackupCodes: "Could not retrieve one-time codes.",
    error_generateBackupCodes: "Could not generate new one-time codes.",
    error_fetchTotpDevices: "Could not retrieve devices.",
    error_createTotpDevice: "Could not create new device.",
    error_updateTotpDevice: "Could not update the device.",
    error_removeTotpDevice: "Could not delete the device.",
    error_fetchSmsDevices: "Could not retrieve devices.",
    error_createSmsDevice: "Could not create new device.",
    error_updateSmsDevice: "Could not update the device.",
    error_removeSmsDevice: "Could not delete the device.",
    error_sendSms: "Could not send SMS.",
    error_fetchNewsList: "Could not fetch news list.",
    error_fetchCommunitySettings: "Could not retrieve settings for community.",
    error_fetchMember: "Could not retrieve user.",
    error_fetchMembers: "Could not retrieve users.",
    error_fetchAssociation: "Could not retrieve association.",
    error_fetchAssociations: "Could not retrieve associations.",
    error_createAssociation: "Could not create association.",
    error_deactivateAssociation: "Could not deactivate associations.",
    error_removeAssociation: "Could not delete associations.",
    error_updateAssociation: "Could not update associations.",
    error_resendInvation: "Could not resend invitation",
    error_removeMemberInvitation: "Could not remove user.",
    error_open_agreement_document_file: "Could not open the agreement document file.",
    error_premise_contract_document_file_not_found: "No agreement document file could be found.",
    error_resetPassword: "Your password could not be updated.",
    error_fetchLeaseInvitation: "Could not retrieve lease invitations.",
    error_acceptLeaseInvitation: "Could not accept lease invitation.",
    error_requestingAccessToLease: "Could not request access to lease.",
    error_fetch_organizations: "Could not retrieve organizations.",
    error_fetchTasks: "Could not retrieve tasks.",
    error_fetchPremises: "Could not retrieve premises.",
    error_submitNewTask: "Could not submit new task.",
    error_submitTaskAction: "Could not submit the comment.",
    error_updateProfile: "Could not update the profile.",
    error_sendQuestion: "Message could note be sent.",
    error_updateNewsViaMailPreferences: "Could not change the settings for email updates.",
    error_addCostCalculation: "Could not create new cost calculation.",
    error_fetchInvoiceEvent: "Failed to fetch the invoice event.",
    error_fetchInvoiceEvents: "Failed to fetch invoice events.",
    error_fetchInvoiceOrder: "Failed to fetch the invoice order.",
    error_fetchInvoiceOrders: "Failed to fetch invoice orders.",
    error_fetchInvoiceOrderLines: "Failed to fetch invoice order lines.",
    error_fetchInvoiceLines: "Failed to fetch invoice lines.",
    error_someOrdersFailed: "One or more orders failed.",
    error_createFromMembership: "There were no any adjustments to make.",
    error_approveInvoiceEvent: "Failed to send the invoice event.",
    error_deleteInvoiceEvent: "Failed to delete the invoice event.",
    error_fetchProduct: "Failed to fetch the product.",
    error_fetchProducts: "Failed to fetch products.",
    error_updateProduct: "Failed to update the product.",
    error_createProduct: "Failed to create product.",
    error_fetchBookkeepingAccounts: "Failed to fetch bookkeeping accounts.",
    error_fetchServiceAccounts: "Failed to fetch Spaceflow categories",
    error_fetchServices: "Failed to fetch services.",
    error_fetchDebits: "Failed to fetch debits.",
    error_fetchAlternativeRecipient: "Failed to fetch alternative invoice recipient.",
    error_fetchAlternativeRecipients: "Failed to fetch alternative invoice recipients.",
    error_updateAlternativeRecipient: "Failed to update alternative invoice recipient.",
    error_createAlternativeRecipient: "Failed to create alternative invoice recipient.",
    error_fetchPublicEvents: "Failed to fetch events.",
    warning_notImplemented: "This function is not implemented yet.",
    warning_sessionEnded: "You got logged out due to inactivity",
    success_createTask: "Issue successfully created, as soon as it is received you can see it in the table below.",
    success_cancelArena: "Terminated the contract.",
    success_updateArena: "Changed information on arena office.",
    success_updateSubscription: "Changed information on subscription.",
    success_addUserSubscription: "User gained access to subscription.",
    success_addUserArena: "User gained access to the arena office.",
    success_removeUser: "Removed user.",
    success_saveNewPassword: "Registration successful.",
    success_sentResetPassword: "Request to reset password sent.",
    success_bookDayPass: "Booked day pass.",
    success_removeDayPass: "Removed day pass.",
    success_saveDayPassLimits: "Saved day pass limit.",
    success_addContactPerson: "Created contact person.",
    success_editContactPerson: "Changed information on contact person.",
    success_removeContactPerson: "Removed contact person.",
    success_removeInvitation: "Removed invitation",
    success_cancelSubscription: "Subscription cancelled.",
    success_setNationalAccess: "Started national access.",
    success_removeNationalAccess: "Removed national access.",
    success_saveInvoiceInfo: "Saved settings for invoice information.",
    success_updateMembership: "Changed information for the membership.",
    success_terminateMembership: "Membership is terminated.",
    success_createExternalMembership: "Created new external customer.",
    success_generateBackupCodes: "Generated new one-time codes.",
    success_removeTotpDevice: "Removed the device.",
    success_removeSmsDevice: "Removed the device.",
    success_copyOnetimeCode: "Copied one-time code.",
    success_removeMemberInvitation: "Removed user.",
    success_resetPassword: "Your password has been updated.",
    success_updateNewsViaMailPreferences:
        "Your details have changed. You will no longer receive updates on news updates by email.",
    success_acceptLeaseInvitation: "Lease invitation accepted.",
    success_requestingAccessToLease: "Request to access lease sent.",
    success_updateProfile: "Profile uppdated.",
    success_denyLeaseInvitation: "Request for access to agreement refused.",
    success_sendQuestion: "Message sent.",
    success_updateProduct: "Product updated.",
    success_createProduct: "Product created.",
    success_createInvoiceEvent: "Invoice event created from file.",
    success_approveInvoiceEvent:
        "Invoice event sent to Payer. You may need to reload the page several times to see the results.",
    success_createAssociation: "User added to subscription.",
    success_updateAssociation: "User updated on subscription",
    success_resendInvitation: "Invitation resent.",
    success_createFromMembership: "Created new order and invoice event for the membership.",
    success_createAlternativeRecipient: "Created new alternative invoice recipient.",
    success_fileDownload: "File downloaded.",
    general_error:
        "An error occured, Please try again later. If the problem remains, please contact Vasakronan's customer service.",
};
