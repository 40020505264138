import React, { FC, useContext, useEffect, useState } from "react";
import List from "@mui/material/List";
import VKButton from "components/vkButton";
import { LocalizeText } from "components/localizer";
import { Box, Grid, ListItem, ListItemText, MenuItem, TextField } from "@mui/material";
import { ExternalMembershipsContext } from "contexts/externalMemberships";
import CommunityContext from "contexts/community";
import validator from "utils/validator";
import colors from "styles/colors";
import { ModalContext } from "contexts/modals";
import ExternalMembershipInformation from "./externalMembershipInformation";
import { TCommunityType } from "utils/ecommerseTypes";
import { MembershipsContext } from "contexts/memberships";
import { getCountryCodeString } from "utils/countryCodes";
import { LanguageContext } from "contexts/language";
import { MembershipDocumentTypes, uploadDocument } from "adapters/documents";
import { UploadFileOutlined } from "@mui/icons-material";
import { DocumentButton } from "./membershipInformation/documentButton";
import { DocumentUploadWizard } from "./membershipInformation/documentUploadWizard";
import { usePaginationStore } from "utils/usePaginationStore";

interface IProps {
    community_type: TCommunityType;
}

const CreateExternalMembership: FC<IProps> = ({ community_type }) => {
    const pagination = usePaginationStore("external-memberships", undefined, { agreement_type: "external_member" });
    const { createMembership, isCreatingMembership } = useContext(ExternalMembershipsContext);
    const { communitiesPagination } = useContext(CommunityContext);
    const { openDrawer, closeDrawers } = useContext(ModalContext);
    const { fetchCountries, countries, isFetchingCountries } = useContext(MembershipsContext);
    const { currentLanguage } = useContext(LanguageContext);
    const [fileData, setFileData] = useState<{ file: File; type: MembershipDocumentTypes }[]>([]);
    const [isUploading, setIsUploading] = useState(false);

    const [fields, setFields] = useState({
        org_name: "",
        company_name: "",
        country_code: "",
        org_nr: "",
        postal_address1: "",
        postal_address2: "",
        postal_postcode: "",
        postal_city: "",
        selected_invoicing_method: "pdf",
        pdf_address: "",
        peppol_address: "",
        user_invoice_reference: "",
        default_community: "",
        contract_contact_name: "",
        contract_contact_phone: "",
        contract_contact_email: "",
        free_form_text: "",
    });

    useEffect(() => {
        if (community_type === "conference") {
            communitiesPagination.setParam("community_type", "conference");
        } else {
            communitiesPagination.setParam("community_type", "");
        }
        communitiesPagination.getInitial();

        if (countries?.length === 0 && !isFetchingCountries) {
            fetchCountries();
        }

        return () => {
            communitiesPagination.setParam("community_type", "");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setField = (prop: string, value: string): void => {
        setFields({
            ...fields,
            [prop]: value,
        });
    };

    useEffect(() => {
        if (communitiesPagination.results.length > 0 && fields.default_community === "") {
            setField("default_community", communitiesPagination.results[0].uuid);
        }
        // eslint-disable-next-line
    }, [communitiesPagination.results]);

    useEffect(() => {
        if (countries.length > 0 && fields.country_code === "") {
            setField("country_code", countries.find((c) => c.code === "SE")?.uuid ?? countries[0].uuid);
        }
        // eslint-disable-next-line
    }, [countries]);

    const pdfEmailIsValid = validator.email(fields.pdf_address, true);
    const peppolIsValid = validator.peppol(fields.peppol_address, true);
    const phoneIsValid = validator.phoneNumber(fields.contract_contact_phone, true);
    const emailIsValid = validator.email(fields.contract_contact_email, true);

    const missingData = Object.entries(fields).some(([prop, value]) => {
        switch (prop) {
            case "postal_address2":
            case "free_form_text":
            case "pdf_address":
            case "peppol_address":
                return false;

            case "selected_invoicing_method":
                return value === "pdf"
                    ? !validator.email(fields.pdf_address)
                    : !validator.peppol(fields.peppol_address);
            case "contract_contact_email":
                return !validator.email(value);
            case "contract_contact_phone":
                return !validator.phoneNumber(value);

            default:
                return !validator.textFieldNotEmpty(value);
        }
    });

    const onCreate = async (): Promise<void> => {
        const {
            pdf_address,
            peppol_address,
            selected_invoicing_method,
            org_name,
            company_name,
            org_nr,
            country_code,
            default_community,
            ...restFields
        } = fields;
        const data = {
            ...restFields,
            selected_invoicing_method,
            organization: { name: org_name, company_name, org_nr, country_code },
            default_community,
            agreement_type: community_type === "conference" ? "conference_member" : "external_member",
        };
        if (selected_invoicing_method === "pdf") {
            data["pdf_address"] = pdf_address;
        } else {
            data["peppol_address"] = peppol_address;
        }
        const newMembership = await createMembership(data);
        if (!newMembership?.created) {
            return;
        }

        await Promise.all(
            fileData.map(({ file, type }) => {
                const formData = new FormData();
                formData.append("document_file", file);
                formData.append("document_type", type);
                formData.append("membership", newMembership.id.toString());

                return uploadDocument("memberships", formData);
            })
        );

        closeDrawers();
        openDrawer(ExternalMembershipInformation, { membership: newMembership, pagination }, "showExternalMembership");
        pagination.query.refetch();
    };

    return (
        <Box
            sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <List>
                <ListItem>
                    <ListItemText primary={<LocalizeText tag="organization" />} />
                </ListItem>
                <ListItem>
                    <TextField
                        label={<LocalizeText tag="commercialCompanyName" />}
                        value={fields.org_name}
                        onChange={(e) => setField("org_name", e.target.value)}
                        fullWidth
                        required
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        label={<LocalizeText tag="legalCompanyName" />}
                        value={fields.company_name}
                        onChange={(e) => setField("company_name", e.target.value)}
                        fullWidth
                        required
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        label={<LocalizeText tag="country" />}
                        onChange={(e) => setField("country_code", e.target.value)}
                        fullWidth
                        select
                        required
                        value={fields.country_code}
                    >
                        {countries?.map((country) => (
                            <MenuItem key={country.uuid} value={country.uuid}>
                                {getCountryCodeString(country, currentLanguage)}
                            </MenuItem>
                        ))}
                    </TextField>
                </ListItem>
                <ListItem>
                    <TextField
                        label={<LocalizeText tag="orgNumber" />}
                        value={fields.org_nr}
                        onChange={(e) => setField("org_nr", e.target.value)}
                        fullWidth
                        required
                    />
                </ListItem>

                <ListItem>
                    <ListItemText primary={<LocalizeText tag="invoiceAddress" />} />
                </ListItem>
                <ListItem>
                    <TextField
                        label={<LocalizeText tag="addressLine1" />}
                        value={fields.postal_address1}
                        onChange={(e) => setField("postal_address1", e.target.value)}
                        fullWidth
                        required
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        label={<LocalizeText tag="addressLine2" />}
                        value={fields.postal_address2}
                        onChange={(e) => setField("postal_address2", e.target.value)}
                        fullWidth
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        label={<LocalizeText tag="zipCode" />}
                        value={fields.postal_postcode}
                        onChange={(e) => setField("postal_postcode", e.target.value)}
                        fullWidth
                        required
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        label={<LocalizeText tag="town" />}
                        value={fields.postal_city}
                        onChange={(e) => setField("postal_city", e.target.value)}
                        fullWidth
                        required
                    />
                </ListItem>

                <ListItem>
                    <ListItemText primary={<LocalizeText tag="paymentInformation" />} />
                </ListItem>
                <ListItem>
                    <TextField
                        label={<LocalizeText tag="paymentMethod" />}
                        onChange={(e) => setField("selected_invoicing_method", e.target.value)}
                        select
                        fullWidth
                        value={fields.selected_invoicing_method}
                    >
                        <MenuItem value="peppol">
                            <LocalizeText tag="paymentMethodPeppol" />
                        </MenuItem>
                        <MenuItem value="pdf">
                            <LocalizeText tag="paymentMethodPDF" />
                        </MenuItem>
                    </TextField>
                </ListItem>
                <ListItem>
                    {fields.selected_invoicing_method === "pdf" ? (
                        <TextField
                            label={<LocalizeText tag="email" />}
                            value={fields.pdf_address}
                            onChange={(e) => setField("pdf_address", e.target.value)}
                            fullWidth
                            required
                            error={!pdfEmailIsValid}
                            helperText={!pdfEmailIsValid && <LocalizeText tag="errorEmail" />}
                        />
                    ) : (
                        <TextField
                            label={<LocalizeText tag="peppolID" />}
                            value={fields.peppol_address}
                            onChange={(e) => setField("peppol_address", e.target.value)}
                            fullWidth
                            required
                            error={!peppolIsValid}
                            helperText={!peppolIsValid && <LocalizeText tag="errorPeppol" />}
                        />
                    )}
                </ListItem>
                <ListItem>
                    <TextField
                        label={<LocalizeText tag="invoiceReference" />}
                        value={fields.user_invoice_reference}
                        onChange={(e) => setField("user_invoice_reference", e.target.value)}
                        inputProps={{
                            maxLength: 48,
                        }}
                        fullWidth
                        required
                    />
                </ListItem>

                <ListItem>
                    <ListItemText primary={<LocalizeText tag="community" />} />
                </ListItem>
                {communitiesPagination.results.length > 0 && (
                    <ListItem>
                        <TextField
                            label={<LocalizeText tag="community" />}
                            onChange={(e) => setField("default_community", e.target.value)}
                            select
                            fullWidth
                            required
                            value={fields.default_community}
                        >
                            {communitiesPagination.results.map((community) => (
                                <MenuItem key={community.uuid} value={community.uuid}>
                                    {community.title}
                                </MenuItem>
                            ))}
                        </TextField>
                    </ListItem>
                )}

                <ListItem>
                    <ListItemText primary={<LocalizeText tag="referencePerson" />} />
                </ListItem>
                <ListItem>
                    <Grid container gap="16px">
                        <TextField
                            label={<LocalizeText tag="name" />}
                            value={fields.contract_contact_name}
                            onChange={(e) => setField("contract_contact_name", e.target.value)}
                            fullWidth
                            required
                        />
                        <TextField
                            label={<LocalizeText tag="phoneNumber" />}
                            value={fields.contract_contact_phone}
                            onChange={(e) => setField("contract_contact_phone", e.target.value)}
                            fullWidth
                            required
                            error={!phoneIsValid}
                            helperText={!phoneIsValid && <LocalizeText tag="errorMobile" />}
                        />
                        <TextField
                            label={<LocalizeText tag="email" />}
                            value={fields.contract_contact_email}
                            onChange={(e) => setField("contract_contact_email", e.target.value)}
                            type="email"
                            fullWidth
                            required
                            error={!emailIsValid}
                            helperText={!emailIsValid && <LocalizeText tag="errorEmail" />}
                        />
                    </Grid>
                </ListItem>

                <ListItem>
                    <ListItemText primary={<LocalizeText tag="documents" />} />
                </ListItem>
                <ListItem>
                    <Grid container direction="column" gap="10px">
                        {!isUploading ? (
                            <>
                                {fileData.length > 0 ? (
                                    <Grid item container direction="column" gap="5px">
                                        {fileData.map(({ file, type }, index) => (
                                            <DocumentButton
                                                key={file.name}
                                                document_file={file.name}
                                                document_type={type}
                                                onDelete={() =>
                                                    setFileData([...fileData.filter((_, i) => i !== index)])
                                                }
                                            />
                                        ))}
                                    </Grid>
                                ) : null}

                                <Grid item>
                                    <VKButton
                                        tag="newDocument"
                                        startIcon={<UploadFileOutlined />}
                                        sx={{ fontSize: "1rem" }}
                                        variant="outlined"
                                        fullWidth
                                        onClick={() => setIsUploading(true)}
                                    />
                                </Grid>
                            </>
                        ) : (
                            <DocumentUploadWizard
                                onUpload={(file, type) => {
                                    setFileData([...fileData, { file, type }]);
                                    setIsUploading(false);
                                }}
                                onCancel={() => setIsUploading(false)}
                            />
                        )}
                    </Grid>
                </ListItem>

                <ListItem>
                    <ListItemText primary={<LocalizeText tag="freeTextInternal" />} />
                </ListItem>
                <ListItem>
                    <TextField
                        value={fields.free_form_text}
                        onChange={(e) => setField("free_form_text", e.target.value)}
                        fullWidth
                        multiline
                        rows={5}
                    />
                </ListItem>

                <ListItem>
                    <Grid container flexDirection="column" gap="5px">
                        {missingData ? (
                            <LocalizeText tag="errorEmptyFields" styling={{ color: colors.errorRed }} />
                        ) : null}
                        <VKButton
                            tag="save"
                            variant="contained"
                            disabled={missingData}
                            onClick={onCreate}
                            isLoading={isCreatingMembership}
                        />
                    </Grid>
                </ListItem>
            </List>
        </Box>
    );
};

export default CreateExternalMembership;
