import React, { ImgHTMLAttributes } from "react";


const NotFoundImage: React.FC<ImgHTMLAttributes<HTMLImageElement>> = ({style}) => {
    return <img
        style={style}
        src={`${process.env.PUBLIC_URL}/assets/images/vasakronan_symbol_orange.png`}
        alt="Default"
    />;
};


interface IProps extends ImgHTMLAttributes<HTMLImageElement> {
    defaultNode?: React.ReactElement;
    url?: string;
}
const ExternalImageWithDefault: React.FC<IProps> = ({
    style,
    defaultNode=<NotFoundImage style={style} />,
    url,
    ...imgProps
}) => {

    const [imgUrl, setImgUrl] = React.useState(url || "");

    if (imgUrl) {
        return (<img
            {...imgProps}
            style={style}
            alt={imgProps.alt || ""}
            src={imgUrl}
            onError={() => {
                setImgUrl("");
            }}
        />);
    }
    return defaultNode;
};

export default ExternalImageWithDefault;
