import React, { FC, useContext, useEffect, useState } from "react";
import Drawer from "components/drawer";
import { IArena } from "reducers/arenas";
import { LocalizeText } from "components/localizer";
import { TDrawer } from "components/tableComponents/arenaTable";
import ConfirmDialog from "components/confirmDialog";
import { GeneralDatePicker } from "components/drawers/generalDatePicker";
import ArenaInformation from "components/drawers/arenaInformation";
import { ProfileContext } from "contexts/profile";
import { MembershipsContext } from "contexts/memberships";
import { dateFormats } from "utils/formats";
import { format, parseISO } from "date-fns";
import { Box, List, ListItemText } from "@mui/material";
import ArenasContext from "contexts/arenas";
import { IUsePaginationStore } from "utils/usePaginationStore";
import { ModalContext } from "contexts/modals";

interface IProps {
    drawerType: TDrawer;
    pagination: IUsePaginationStore<"arenas">;
    arena: IArena;
    onDrawerClose: () => void;
    membershipId: number;
}

const ArenaDrawerGroup: FC<IProps> = ({ drawerType, onDrawerClose, arena, pagination, membershipId }) => {
    const { membership, isFetchingMemberships, isFetchingMembership, fetchMembership } = useContext(MembershipsContext);
    const { cancelArena, updateArena } = useContext(ArenasContext);
    const { closeDrawers } = useContext(ModalContext);
    const { user } = useContext(ProfileContext);
    const [removeDate, setRemoveDate] = useState<Date | null>(null);
    useEffect(() => {
        if (!membership && !isFetchingMemberships) {
            fetchMembership(membershipId);
        }
        // eslint-disable-next-line
    }, []);

    const reloadArenas = async (): Promise<void> => {
        await pagination.query.refetch();
    };

    const onAcceptRemoveArena = async (): Promise<void> => {
        if (removeDate) {
            await cancelArena(arena.uuid, removeDate);
            setRemoveDate(null);
            onDrawerClose();
            reloadArenas();
        }
    };
    const onSubmitUpdate = async (newData: Partial<IArena>): Promise<void> => {
        await updateArena(arena.uuid, newData);
        closeDrawers();
        reloadArenas();
    };

    const desc = (
        <List>
            <ListItemText
                primary={
                    <LocalizeText
                        tag="removeArenaMailPreview"
                        replace={{
                            org: `${membership?.organization.company_name || membership?.organization.name}`,
                            agreementNr: `${membership?.agreement_nr}`,
                            arenaOfficeName: arena.office_name,
                            arena: arena.community_title,
                            city: arena.community_city,
                            endDate: removeDate ? format(removeDate, dateFormats.WEBDATE) : "",
                            cancelCity: arena.community_city,
                            cancelDate: format(new Date(), dateFormats.WEBDATE),
                            contactPerson: `${user?.first_name} ${user?.last_name} (${user?.email})`,
                        }}
                    />
                }
            ></ListItemText>
        </List>
    );

    return (
        <Box
            sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
            }}
            data-testid="arenaDrawerGroup-root"
        >
            <Drawer
                isOpen={drawerType === "SHOW_INFORMATION"}
                onClose={onDrawerClose}
                title={<LocalizeText tag="showArenaOffice" />}
                noData={!arena || !membership}
            >
                {arena && membership && <ArenaInformation arena={arena} onSubmitUpdate={onSubmitUpdate} />}
            </Drawer>
            <Drawer
                isOpen={drawerType === "CANCEL_ARENA"}
                onClose={onDrawerClose}
                title={<LocalizeText tag="cancelArena" />}
            >
                <GeneralDatePicker
                    titleTag="selectEndDateArena"
                    onSubmit={(date: Date) => setRemoveDate(date)}
                    earliestEndDate={parseISO(arena.earliest_cancellation_date ?? "")}
                    type="proceed"
                    disabled={!membership}
                    isLoading={!membership && isFetchingMembership}
                />
            </Drawer>
            <ConfirmDialog
                open={!!removeDate && drawerType === "CANCEL_ARENA"}
                title={<LocalizeText tag="cancelArena" />}
                description={desc}
                onReject={() => setRemoveDate(null)}
                onAccept={onAcceptRemoveArena}
                isLoading={pagination.query.isFetching}
            />
        </Box>
    );
};

export default ArenaDrawerGroup;
