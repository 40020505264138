import React, { FC } from "react";
import { IInvoiceOrder } from "reducers/invoices";
import colors from "styles/colors";
import { LocalizeText } from "../localizer";
import { Tooltip, Typography } from "@mui/material";
import { TLanguageTag } from "contexts/language";

interface IProps {
    order: IInvoiceOrder;
    shortVersion?: boolean;
}

export const InvoiceOrderStatus: FC<IProps> = ({ order, shortVersion }) => {
    const { internal_status, payer_errors } = order;
    let color: string | undefined = "inherit";

    const tag = internal_status;
    switch (internal_status) {
        case "order_partially_credited":
            color = colors.warning;
            break;
        case "invoice_failed":
        case "invoice_internal_failed":
        case "order_failed":
        case "order_internal_failed":
        case "order_not_valid":
            color = colors.errorRed;
            break;
    }
    if (shortVersion) {
        return (
            <Tooltip
                title={
                    <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                        <LocalizeText tag={`orderStatus_${tag}` as TLanguageTag} />
                        {payer_errors?.length > 0 ? (
                            <>
                                {payer_errors.map((error) => (
                                    <span key={error.uuid}>{error.message}</span>
                                ))}
                            </>
                        ) : null}
                    </div>
                }
                placement="top"
            >
                <span>
                    <LocalizeText styling={{ color: color }} tag={`orderStatusShort_${tag}` as TLanguageTag} />
                </span>
            </Tooltip>
        );
    }
    return (
        <>
            <LocalizeText styling={{ color: color }} tag={`orderStatus_${tag}` as TLanguageTag} />
            {payer_errors?.length > 0 ? (
                <>
                    {payer_errors.map((error) => (
                        <Typography key={error.uuid} color={color} variant="body2">
                            {error.message}
                        </Typography>
                    ))}
                </>
            ) : null}
        </>
    );
};
