import React from "react";
import { LocalizeText } from "components/localizer";
import { ListItem, ListItemText, MenuItem, TextField } from "@mui/material";
import ListInlineEditWrapper from "../listComponents/listInlineEditWrapper";
import { IProduct } from "reducers/products";
import { ProductsContext } from "contexts/products";

interface IProps {
    product: IProduct;
    onSubmitUpdate: (props: Record<string, unknown>) => void;
    canEdit?: boolean;
}

const ServiceSectionField: React.FC<IProps> = ({ product, onSubmitUpdate, canEdit = true }) => {
    const [serviceSection, setServiceSection] = React.useState<string>(product.service_section);
    const { fetchServiceSections, isFetchingServiceSections, serviceSections } = React.useContext(ProductsContext);

    React.useEffect(() => {
        if ((!serviceSections || serviceSections.length === 0) && !isFetchingServiceSections) {
            fetchServiceSections();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!canEdit) {
        return (
            <ListItem divider>
                <ListItemText
                    primary={<LocalizeText tag="serviceSection" />}
                    secondary={product.service_section_name}
                />
            </ListItem>
        );
    }

    return (
        <ListInlineEditWrapper
            onChange={(e) => setServiceSection(e.target.value)}
            onSubmit={() => onSubmitUpdate({ service_section: serviceSection })}
            onCancel={() => {
                setServiceSection("");
            }}
            label={<LocalizeText tag="serviceSection" />}
            renderInput={(props) => (
                <>
                    {serviceSections && serviceSections.length > 0 && (
                        <TextField
                            {...props}
                            label={<LocalizeText tag="serviceSection" />}
                            value={serviceSection || ""}
                            onChange={(e) => setServiceSection(e.target.value)}
                            fullWidth
                            required
                            select
                            helperText={<LocalizeText tag="mustBeUnique" />}
                        >
                            {serviceSections.map((serviceSection) => {
                                return (
                                    <MenuItem key={serviceSection.uuid} value={serviceSection.uuid}>
                                        {serviceSection.name}
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                    )}
                </>
            )}
            renderView={(props) => (
                <ListItemText
                    {...props}
                    primary={<LocalizeText tag="serviceSection" />}
                    secondary={product.service_section_name}
                />
            )}
            inputIsValid={true}
        />
    );
};

export default ServiceSectionField;
