import webAPIRequest from "api";
import { AxiosResponse } from "axios";

export const sendTaskAction = async (taskNumber: number, message: string): Promise<AxiosResponse> => {
    const data = {
        task_number: taskNumber,
        message: message,
    };
    return webAPIRequest("post", "/vk_data/tasks/actions/", { data });
};

export const checkTaskStatus = async (uuid: string): Promise<AxiosResponse> => {
    return webAPIRequest("get", `/configurations/task-results/${uuid}`);
};
