import "react-app-polyfill/ie11";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom/client";

import SiteRoutes from "routes";
import { LocalizeText } from "components/localizer";

// Block all IE browsers
const userAgent = window.navigator.userAgent;
const isIE = /MSIE|Trident/.test(userAgent);
const container = document.getElementById("root");
const root = ReactDOM.createRoot(container as Element);

if (isIE) {
    root.render(<LocalizeText tag="IENotSupported" />);
} else {
    root.render(<SiteRoutes />);
}
