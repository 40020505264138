import React, { FC, useContext, useEffect } from "react";
import { IMembership } from "reducers/memberships";
import { MembershipsContext } from "contexts/memberships";
import { LocalizeText } from "components/localizer";
import { TDropdownItem } from "components/dropdown";
import { useNavigate, useLocation } from "react-router-dom";
import TopBar from "components/tableComponents/topBar";
import { ProfileContext } from "contexts/profile";
import Banner from "components/banner";
import SearchField from "components/tableComponents/paginationSearchField";
import MembershipTable from "components/tableComponents/membershipTable";

const MembershipsView: FC = () => {
    const { fetchMemberships, pagination, isFetchingEmailsCSV, getEmailsCSV, setMembership } =
        useContext(MembershipsContext);
    const { user, selectedOrganization } = useContext(ProfileContext);
    const navigate = useNavigate();
    const location = useLocation();

    const paths = location.pathname.split("/").slice(1);
    const noSelectedMembership = paths[0] !== "memberships";

    const singleAccessUser = pagination.totalCount === 1;

    const selectMembership = (membership: IMembership, isSingleMembership: boolean): void => {
        setMembership(membership, isSingleMembership);
        navigate(`${location.pathname}/${membership.id}`);
    };

    useEffect(() => {
        pagination.initialize(fetchMemberships);
        pagination.setParam("ordering", "organization__name");
        pagination.setParam("search", selectedOrganization.org_nr);
        const isSingleMembership = async (): Promise<void> => {
            await pagination.getInitial();
            if (noSelectedMembership && singleAccessUser && pagination.results.length > 0) {
                selectMembership(pagination.results[0], true);
            }
        };
        isSingleMembership();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (noSelectedMembership && singleAccessUser && pagination.results.length > 0) {
            selectMembership(pagination.results[0], true);
        }
        // eslint-disable-next-line
    }, [pagination.results, location]);

    const topMenuItems: TDropdownItem[] = [];
    if (user?.is_staff) {
        topMenuItems.push({
            onClick: () => getEmailsCSV(),
            label: <LocalizeText tag="downloadEmails" />,
            disabled: isFetchingEmailsCSV,
        });
    }

    return (
        <div data-testid="memberships-root" className="contentWrapper">
            <Banner
                title="memberships"
                subTitle="bannerMemberships"
                image={`${process.env.PUBLIC_URL}/assets/images/1024x683/memberships-1024x683.jpg`}
            />
            <TopBar
                searchField={
                    singleAccessUser && (
                        <SearchField pagination={pagination} tag="searchMemberships" validator="membership" />
                    )
                }
                menuItems={topMenuItems}
            />
            <MembershipTable
                pagination={pagination}
                onClick={
                    noSelectedMembership
                        ? (membership) => selectMembership(membership, false)
                        : (membership) => navigate(`/staff-memberships/${membership.id}/details`)
                }
            />
        </div>
    );
};

export default MembershipsView;
