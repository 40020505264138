import React from "react";
import VKButton from "components/vkButton";
import { LocalizeText } from "components/localizer";
import { Box, Grid, styled } from "@mui/material";
import colors from "styles/colors";
import InnerPage, { TInnerPage } from "./innerPage";
import RegisterAppWizard from "./registerAppWizard";
import RegisterSmsWizard from "./registerSmsWizard";
import { IAuthorization } from "reducers/auth";
import { AuthContext } from "contexts/auth";
import { AppIcon, SmsIcon } from "./icons";
import { TLanguageTag } from "contexts/language";

interface SelectProps {
    onSubmit: (selectedMethod: string) => void;
}

const TagsContainer = styled(Grid)(() => ({
    textAlign: "center",
    color: colors.vkBlue,
    marginTop: "auto",

    "& div:not(:last-child)": {
        marginRight: "20px",
    },
}));

const TwoFAMethodTitle = styled("h1")(() => ({
    marginBottom: "20px",
    marginTop: "0px",
    fontSize: "2.2em",
    fontWeight: "bold",
    fontFamily: "Vasakronan",
    maxWidth: 600,
    textAlign: "center",
}));

const TwoFAWindow = styled(Box)(({ theme }) => ({
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
        "& div:first-child": {
            overflow: "auto",
        },
    },
}));

const Select2FAMethod: React.FC<SelectProps> = ({ onSubmit }) => {
    const { isFetchingTotpDevices, isCreatingTotpDevice, isFetchingSmsDevices, isCreatingSmsDevice } =
        React.useContext(AuthContext);
    const [selectedMethod, setSelectedMethod] = React.useState<string>("app");

    const isLoading = isFetchingTotpDevices || isCreatingTotpDevice || isFetchingSmsDevices || isCreatingSmsDevice;

    const methods: Record<string, TInnerPage> = {
        app: {
            icon: <AppIcon />,
            title: "authenticationApp",
            info: "authenticationAppInfo",
            customContent: (
                <TagsContainer container justifyContent="center">
                    {(["fastest", "wifi"] as TLanguageTag[]).map((tag) => (
                        <Grid key={tag} item>
                            <LocalizeText tag={tag} />
                        </Grid>
                    ))}
                </TagsContainer>
            ),
        },
        sms: {
            icon: <SmsIcon />,
            title: "sms",
            info: "smsInfo",
            customContent: (
                <TagsContainer container justifyContent="center">
                    {(["requiresPhoneAndReception"] as TLanguageTag[]).map((tag) => (
                        <Grid key={tag} item>
                            <LocalizeText tag={tag} />
                        </Grid>
                    ))}
                </TagsContainer>
            ),
        },
    };

    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            wrap="nowrap"
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                padding: "2rem 4rem",
                "@media (max-height:820px), (max-width:1024px)": {
                    padding: "2rem 2rem",
                },
                backgroundColor: colors.background,
            }}
        >
            <Grid item>
                <TwoFAMethodTitle>
                    <LocalizeText tag="add2FA" />
                </TwoFAMethodTitle>
            </Grid>
            <Grid
                item
                sx={{
                    fontSize: "1.1rem",
                    maxWidth: "450px",
                    textAlign: "center",
                    marginBottom: "50px",
                }}
            >
                <LocalizeText tag="add2FASubtitle" />
            </Grid>
            <Grid container justifyContent="space-between" wrap="nowrap">
                {Object.entries(methods).map(([methodName, method]) => {
                    const selected = methodName === selectedMethod;
                    return (
                        <Grid
                            key={methodName}
                            item
                            md={5}
                            sm={5}
                            sx={{
                                boxShadow: `0 0 0 2px ${selected ? colors.vkBlue : "transparent"}`,
                                cursor: !selected ? "pointer" : "auto",
                                marginBottom: "30px",
                                transition: "all 0.2s ease-in",
                                width: "100%",

                                "&:not(:first-child)": {
                                    marginLeft: "20px",
                                },
                                "&:not(:last-child)": {
                                    marginRight: "20px",
                                },
                                "& svg": {
                                    fill: selected ? colors.vkBlue : "",
                                },
                            }}
                            onClick={() => !isLoading && setSelectedMethod(methodName)}
                        >
                            <InnerPage page={method} />
                        </Grid>
                    );
                })}
            </Grid>
            <Grid container justifyContent="flex-end">
                <Grid item>
                    <VKButton
                        type="submit"
                        tag="next"
                        onClick={() => onSubmit(selectedMethod)}
                        isLoading={isLoading}
                        template="primary"
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

interface RegisterProps {
    onFinish: () => void;
    authorizationObject: IAuthorization;
}

const Register2FAMethod: React.FC<RegisterProps> = ({ onFinish, authorizationObject }) => {
    const { createTotpDevice } = React.useContext(AuthContext);
    const [selectedMethod, setSelectedMethod] = React.useState<string>("");

    const handleSubmit = async (selectedMethod: string): Promise<void> => {
        if (selectedMethod === "app") {
            await createTotpDevice("My new device", authorizationObject.key);
        }
        setSelectedMethod(selectedMethod);
    };

    let content;
    switch (selectedMethod) {
        case "app":
            content = (
                <RegisterAppWizard
                    onCancel={() => setSelectedMethod("")}
                    onFinish={onFinish}
                    authorizationObject={authorizationObject}
                />
            );
            break;

        case "sms":
            content = (
                <RegisterSmsWizard
                    onCancel={() => setSelectedMethod("")}
                    onFinish={onFinish}
                    authorizationObject={authorizationObject}
                />
            );
            break;

        default:
            content = <Select2FAMethod onSubmit={handleSubmit} />;
            break;
    }

    return <TwoFAWindow>{content}</TwoFAWindow>;
};

export default Register2FAMethod;
