import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import { Divider, Grid, styled, Typography } from "@mui/material";
import { NewsArticleContext } from "contexts/newsArticles";
import { INewsArticle } from "reducers/newsArticles";
import Banner from "components/banner";
import { format, parseISO } from "date-fns";
import { dateFormats } from "utils/formats";
import { LocalizeText } from "components/localizer";
import HorizontalCard from "components/horizontalCard";
import { CenteredLoader } from "components/centeredLoader";
import VKButton from "components/vkButton";
import If from "components/__helpers__/if";
import BuildingCardImage from "components/buildings/buildingCardImage";
import { ProfileContext } from "contexts/profile";
import NewsDialog from "components/dialogs/newsDialog";
import { ModalContext } from "contexts/modals";
import colors from "styles/colors";
import styleConstants from "styles/styleConstants";
import { useProfileNotifications } from "utils/hooks/useProfileNotifications";
import { IBuilding } from "reducers/realestates";

export const getPopularNameOfBuilding = (building: IBuilding | undefined, isArena: boolean): ReactNode | string => {
    if (building) {
        if (isArena) {
            return building.arena_name;
        }
        return building.popular_name_tenant;
    }
    return <LocalizeText tag="generalNews" />;
};

export const getArticleBuilding = (article: INewsArticle): IBuilding | undefined => {
    if (article.buildings.length > 0) {
        if (article.type_value === "ArenaNews") {
            const arenaBuilding = article.buildings.find((b) => b.is_arena);
            if (arenaBuilding) {
                return arenaBuilding;
            }
        }
        return article.buildings[0];
    }
    return undefined;
};

const BannerItem = styled(Grid)(({ theme }) => ({
    position: "relative",
    padding: "1em 0",
    "&:nth-child(2n)": {
        [theme.breakpoints.up("lg")]: {
            paddingLeft: "1em",
        },
    },
}));

const NewsView: FC = () => {
    const { isFetchingNews, fetchNews, newsListPagination, defaultArticle } = useContext(NewsArticleContext);
    const { selectedOrganization } = useContext(ProfileContext);
    const { pushDialog } = useContext(ModalContext);

    const { markAllRead } = useProfileNotifications();
    const [infiniteScroll, setInfiniteScroll] = useState(false);

    useEffect(() => {
        markAllRead("news");
        newsListPagination.initialize(fetchNews);
        newsListPagination.appendResults = true;
        newsListPagination.setParam("org_nr", selectedOrganization.org_nr);
        newsListPagination.getInitial();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleInfiniteScroll = (): void => {
        if (newsListPagination.next) {
            setInfiniteScroll(true);
            newsListPagination.nextPage();
        }
    };

    const onClick = (article: INewsArticle): void => {
        pushDialog(NewsDialog, { newsArticle: article });
    };

    const renderArticles = (): JSX.Element => {
        if (isFetchingNews && !infiniteScroll) {
            return <CenteredLoader size={70} />;
        }

        const articles = newsListPagination.totalCount ? newsListPagination.results : [defaultArticle];

        return (
            <Grid container spacing={4}>
                {articles.map((article: INewsArticle) => {
                    return (
                        <BannerItem item lg={6} md={12} key={article.news_uuid}>
                            <HorizontalCard
                                elevation={0}
                                rounded={true}
                                imageBreakPoints={{
                                    xs: 4,
                                }}
                                contentBreakPoints={{
                                    xs: 8,
                                }}
                                image={<BuildingCardImage building={article.buildings[0]} />}
                                onCardClick={() => onClick(article)}
                                actions={
                                    <VKButton size="small" color="primary" onClick={() => onClick(article)}>
                                        <Typography variant="body2">
                                            <LocalizeText tag="readMore" />
                                        </Typography>
                                    </VKButton>
                                }
                            >
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography variant="body1">
                                            {getPopularNameOfBuilding(
                                                getArticleBuilding(article),
                                                article.type_value === "ArenaNews"
                                            )}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" sx={{ textAlign: "end" }}>
                                            {article.published_time &&
                                                format(parseISO(article.published_time ?? ""), dateFormats.WEBDATE)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                        <Typography
                                            sx={{
                                                color: colors.vkBlue,
                                                "& p": {
                                                    fontWeight: "bold",
                                                },
                                                textOverflow: "elipsis",
                                                overflow: "hidden",
                                                width: "100%",
                                                whiteSpace: "nowrap",
                                            }}
                                            variant="subtitle1"
                                        >
                                            {article.title}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography
                                            gutterBottom
                                            variant="body2"
                                            sx={{
                                                display: "-webkit-box",
                                                "-webkit-line-clamp": 3,
                                                "-webkit-box-orient": "vertical",
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                height: `${3 * styleConstants.muBody2LineHeight}em`,
                                            }}
                                        >
                                            {article.header}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </HorizontalCard>
                        </BannerItem>
                    );
                })}
                <If truthy={newsListPagination.next}>
                    <Grid container justifyContent="center">
                        <VKButton template="primary" isLoading={isFetchingNews} onClick={handleInfiniteScroll}>
                            <LocalizeText tag="loadMoreResults" />
                        </VKButton>
                    </Grid>
                </If>
            </Grid>
        );
    };
    return (
        <Grid data-testid="DashboardView-root" className="contentWrapper">
            <Banner
                title="news"
                subTitle="bannerNews"
                image={`${process.env.PUBLIC_URL}/assets/images/1024x683/news-1024x683.jpg`}
            />
            {renderArticles()}
        </Grid>
    );
};

export default NewsView;
