import React, { FC, useContext, useEffect } from "react";
import { IContactPerson } from "reducers/memberships";
import AddContactPersonBase, { INewContactObject } from "components/drawers/contactPersons/addContactPersonBase";
import { DomainContentType, IRole } from "reducers/roleManagement";
import { RoleManagementContext } from "contexts/roleManagement";
import { TPaginationActionData } from "utils/paginationStore";
import { ILease } from "reducers/leases";
import { fetchLeasesContactsAdapter } from "adapters/leasesAdapter";
import { NotificationContext } from "contexts/notification";
import { ModalContext } from "contexts/modals";
import LeasesContext from "contexts/leases";
import ConfirmNewContactDialog from "components/dialogs/confirmContactPersonDialog";
import { useFeatureToggle } from "utils/hooks/useFeatureToggle";

interface IProps {
    lease: ILease;
    onAdd?: () => void;
}
const AddLeaseContactPerson: FC<IProps> = ({ lease, onAdd }) => {
    const { rolesPagination, fetchRolesOfDomain } = useContext(RoleManagementContext);
    const { closeDrawers, pushDialog } = useContext(ModalContext);
    const { enqueNotification } = useContext(NotificationContext);
    const { addLeaseContactPerson } = useContext(LeasesContext);
    const isActive = useFeatureToggle();

    useEffect(() => {
        rolesPagination.initialize(rolesPaginationCallback);
        rolesPagination.setParam("domain_object_id", lease.id);
        rolesPagination.appendResults = true;
        rolesPagination.getInitial();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lease]);

    const rolesPaginationCallback = async (params: Record<string, unknown>): Promise<TPaginationActionData<IRole>> => {
        return fetchRolesOfDomain(DomainContentType.Lease, params);
    };

    const onSubmitDetails = async (contact: INewContactObject): Promise<void> => {
        if (!isActive("confirm_before_adding_contact")) {
            addLeaseContactPerson(lease.lease_uuid, contact);
            onAdd?.();
            return closeDrawers();
        }
        pushDialog(ConfirmNewContactDialog, {
            onAccept: async (): Promise<boolean> => {
                const success = await addLeaseContactPerson(lease.lease_uuid, contact);
                onAdd?.();
                return success;
            },
        });
    };

    const fetchExisting = async (): Promise<IContactPerson[]> => {
        try {
            const entries = await fetchLeasesContactsAdapter(lease.lease_uuid);
            return entries.data["results"];
        } catch (error) {
            enqueNotification("error_fetchContactPersons", error);
            closeDrawers();
        }
        return [];
    };

    return (
        <AddContactPersonBase
            onSubmit={onSubmitDetails}
            fetchExisting={fetchExisting}
            titleTag="lease"
            contractData={`${lease.agreement_number} - ${lease.popular_name_leasee}`}
            availableRoles={rolesPagination}
        />
    );
};

export default AddLeaseContactPerson;
