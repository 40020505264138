import * as React from "react";

interface IProps<T> {
    truthy?: T;
    falsy?: T;
    children:
        | ((props: IProps<T> & { subject?: T }) => React.ReactNode)
        | JSX.Element[]
        | JSX.Element;
}

export default class If<T> extends React.Component<IProps<T>> {
    private shouldRenderChild(): boolean {
        let condition = false;
        const prop = this.conditionalProp();
        const { falsy, truthy } = this.props;
        switch (prop) {
            case "truthy":
                condition = !!truthy;
                break;
            case "falsy":
                condition = !falsy;
                break;
            default:
                break;
        }
        return condition;
    }

    private conditionalProp(): string {
        if ("truthy" in this.props) {
            return "truthy";
        }
        if ("falsy" in this.props) {
            return "falsy";
        }
        throw new Error("Unrecognized condition prop");
    }

    render(): React.ReactNode {
        const { children, truthy, falsy } = this.props;
        if (this.shouldRenderChild()) {
            if (typeof children === "function") {
                let subject: T | undefined;
                if (this.conditionalProp() === "truthy") {
                    subject = truthy;
                }
                if (this.conditionalProp() === "falsy") {
                    subject = falsy;
                }
                return children({ ...this.props, subject });
            }
            return children;
        }
        return null;
    }
}
