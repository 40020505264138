import React from "react";
import { GlobalStyles } from "@mui/material";

const globalStyles = {
    "html, body, #root": {
        height: "100%",
        fontSize: "1rem"
    }
};

const customGlobalStyles = <GlobalStyles styles={globalStyles} />;
export default customGlobalStyles;