import React, { ReactNode, ElementType } from "react";
import MaterialButton, { ButtonProps } from "@mui/material/Button";
import { LocalizeText } from "components/localizer";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material";
import colors from "styles/colors";
import { TLanguageTag } from "contexts/language";

type ButtonStylingProps = {
    template?: "primary" | "save" | "cancel";
};

const StyledMuiButton = styled(MaterialButton)<ButtonStylingProps>(({ template }) => {
    const baseStyle = {
        height: "max-content",
    };
    switch (template) {
        case "cancel":
            return {
                ...baseStyle,
                color: colors.white,
                backgroundColor: colors.noButtonRegular,
                "&:hover": {
                    backgroundColor: colors.noButtonHover,
                },
                "&.Mui-disabled": {
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
            };
        case "primary":
        case "save":
            return {
                ...baseStyle,
                color: colors.white,
                backgroundColor: colors.buttonRegular,
                "&:hover": {
                    backgroundColor: colors.buttonHover,
                },
                "&.Mui-disabled": {
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
            };
        default:
            return baseStyle;
    }
});

const ButtonContent = styled("div")(() => ({
    display: "flex",
    alignItems: "center",
    width: "100%",
    paddingTop: "6px !important",
    paddingBottom: "6px !important",
}));

interface IProps extends ButtonProps {
    tag?: TLanguageTag;
    isLoading?: boolean;
    leftAddon?: ReactNode;
    rightAddon?: ReactNode;
    component?: ElementType;
    // Familjen currently thinks that save and primary buttons should be the same,
    // but just to be sure there is a template for SAVE (green contained button)
    template?: "primary" | "save" | "cancel";
}

const VKButton: React.FC<IProps> = ({ tag, isLoading, leftAddon, rightAddon, template, ...props }) => {
    let content;
    if (props.children) {
        content = props.children;
    } else {
        content = (
            <>
                {leftAddon}

                {tag && <LocalizeText styling={{ marginLeft: "5px", marginRight: "5px" }} tag={tag} />}

                {rightAddon}
            </>
        );
    }

    return (
        <StyledMuiButton
            {...props}
            type={props.type ?? "button"}
            template={template}
            disabled={isLoading || props.disabled} // Do not use "??" instead
        >
            <ButtonContent
                sx={{
                    opacity: isLoading ? "0" : "1",
                    justifyContent: leftAddon || rightAddon ? "space-between" : "center",
                }}
            >
                {content}
            </ButtonContent>
            {isLoading && <CircularProgress size={30} sx={{ position: "absolute" }} />}
        </StyledMuiButton>
    );
};

export default VKButton;
