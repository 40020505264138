import { TCommunity } from "utils/ecommerseTypes";
import { TMembershipId } from "./memberships";

// "Fakturakörning"
export interface IInvoiceEvent {
    uuid: string;
    created: string;
    updated: string;
    community: TCommunity;
    period_start_date: string;
    period_stop_date: string;
    created_by: string | null;
    created_from_action: "file" | "auto";
    file: string | null;
    orders_count: number;
    orders_not_invoiced_count: number;
    sum_invoiced_sek: IInvoiceOrderSum;
    approved: boolean;
}

export interface IInvoiceOrderSum {
    incl_vat: string;
    vat: string;
}

export interface IInvoiceOrder {
    uuid: string;
    created: string;
    updated: string;
    invoice_order: string;
    membership: TMembershipId | null;
    event: string;
    currency_code: string;
    store_id: string;
    our_reference: string;
    your_reference: string;
    payer_comment: string;
    city: string;
    company_name: string;
    email_address: string | null;
    street_address1: string;
    zip_code: string;
    gln: string | null;
    customer_number: string;
    reg_number: string;
    delivery_type: "EMAIL" | "INVOICE";
    invoices: IInvoice[];
    internal_status:
        | "created"
        | "order_sent"
        | "order_failed"
        | "order_approved"
        | "order_internal_failed"
        | "order_zero"
        | "order_partially_credited"
        | "order_entirely_credited"
        | "order_not_valid"
        | "invoice_sent"
        | "invoice_internal_failed"
        | "invoice_failed"
        | "invoice_paid";
    country_code: string;
    payer_errors: IPayerError[];
    retries: number;
    valid_until: string;
    verbose_sv: string;
    verbose_en: string;
}

export interface IInvoice {
    uuid: string;
    created: string;
    updated: string;
    invoice_order: string;
    payer_status: "NEW" | "CANCELLED";
    amount_to_pay: string;
    balance: string;
    payment_status: "UNPAID" | "RESERVED" | "PARTIALLY_PAID" | "PAID" | "UNUSED" | "PARTIALLY_USED" | "USED";
    invoice_number: number;
    invoice_pdf_url: string;
    public_invoice_url: string;
    due_date: string;
    is_overdue: boolean;
    type: TInvoiceType;
}

export type TInvoiceType = "PREPAYMENT" | "NORMAL" | "CREDIT";

export interface IInvoiceOrderLine {
    product_identifier: string;
    invoice_order: number;
    position: number;
    unit_price: number;
    unit_vat_amount: number;
    vat_percentage: number;
    subtotal_price: number;
    subtotal_vat_amount: number;
    article_number: string;
    quantity: number;
    cost_center: string;
    sales_account_code: number;
    vat_account_code: number;
    description: string;
    invoice_period_start: string;
    invoice_period_stop: string;
    item_type: "FREEFORM" | "INFOLINE";
    total_without_discount: number;
    subtotal_disc_amount: number;
}

export interface IInvoiceLine {
    invoice: number;
    position: number;
    unit_price: number;
    unit_vat_amount: number;
    vat_percentage: number;
    subtotal_price: number;
    subtotal_vat_amount: number;
    article_number: string;
    quantity: number;
    cost_center: string;
    sales_account_code: number;
    vat_account_code: number;
    description: string;
    item_type: "FREEFORM" | "INFOLINE";
}

export interface IPayerError {
    uuid: string;
    created: string;
    status: number;
    message: string;
    error: string;
    path: string;
    invoice_order: number;
}

export interface IInvoiceValidationData {
    community: string;
    period_start_date: string;
    period_stop_date: string;
}

export type IInvoiceEventCreationError = Record<string, string[]>;

export interface IInvoiceEventCreationErrors {
    file_errors?: IInvoiceEventCreationError[];
    data_errors?: IInvoiceEventCreationError[];
}

export interface IInvoiceEventsQueryParams {
    period_start_date__gte?: string;
    period_start_date__lte?: string;
    period_stop_date__gte?: string;
    period_stop_date__lte?: string;
    communities?: string;
}

export interface IInvoiceOrdersQueryParams {
    membership?: string;
    event__uuid?: string;
    has_been_invoiced?: string;
    event_period_month?: number;
    event_period_year?: number;
    search?: string;
    ordering?: string;
}

export interface IInvoices {
    invoiceEvent?: IInvoiceEvent;
    invoiceOrder?: IInvoiceOrder;
    invoiceOrderLines?: IInvoiceOrderLine[];
    invoiceLines?: IInvoiceLine[];
    isFetchingInvoiceEvent: boolean;
    isFetchingInvoiceOrder: boolean;
    isRetryingInvoiceOrder: boolean;
    isFetchingInvoiceOrderLines: boolean;
    isFetchingInvoiceLines: boolean;
    isValidatingInvoiceEvent: boolean;
    isCreatingInvoiceEvent: boolean;
    isApprovingInvoiceEvent: boolean;
    isDeletingInvoiceEvent: boolean;
    isCreatingFromMembership: boolean;
    fetchInvoiceEvent: (eventId: string) => Promise<IInvoiceEvent>;
    fetchInvoiceOrder: (orderId: string) => Promise<IInvoiceOrder>;
    retryInvoiceOrder: (uuid: string) => Promise<void>;
    fetchInvoiceOrderLines: (orderId: string) => Promise<IInvoiceOrderLine[]>;
    fetchInvoiceLines: (invoiceId: string) => Promise<IInvoiceLine[]>;
    validateInvoiceEvent: (data: IInvoiceValidationData) => Promise<boolean>;
    createInvoiceEvent: (formData: FormData) => Promise<IInvoiceEvent | IInvoiceEventCreationErrors>;
    approveInvoiceEvent: (eventId: string) => Promise<string>;
    deleteInvoiceEvent: (eventId: string) => Promise<void>;
    createFromMembership: (data: Record<string, unknown>) => Promise<IInvoiceOrder>;
}

export const initialState: IInvoices = {
    isFetchingInvoiceEvent: false,
    isFetchingInvoiceOrder: false,
    isRetryingInvoiceOrder: false,
    isFetchingInvoiceOrderLines: false,
    isFetchingInvoiceLines: false,
    isValidatingInvoiceEvent: false,
    isCreatingInvoiceEvent: false,
    isApprovingInvoiceEvent: false,
    isDeletingInvoiceEvent: false,
    isCreatingFromMembership: false,
    fetchInvoiceEvent: async () => ({} as IInvoiceEvent),
    fetchInvoiceOrder: async () => ({} as IInvoiceOrder),
    retryInvoiceOrder: async () => {},
    fetchInvoiceOrderLines: async () => ({} as IInvoiceOrderLine[]),
    fetchInvoiceLines: async () => ({} as IInvoiceLine[]),
    validateInvoiceEvent: async () => false,
    createInvoiceEvent: async () => ({} as IInvoiceEvent | IInvoiceEventCreationErrors),
    approveInvoiceEvent: async () => ({} as string),
    deleteInvoiceEvent: async () => {},
    createFromMembership: async () => ({} as IInvoiceOrder),
};

export type IAction =
    | { type: "FETCH_INVOICE_EVENT" }
    | { type: "FETCH_INVOICE_EVENT_SUCCESS"; invoiceEvent: IInvoiceEvent }
    | { type: "FETCH_INVOICE_EVENT_FAILURE" }
    | { type: "FETCH_INVOICE_ORDER" }
    | { type: "FETCH_INVOICE_ORDER_SUCCESS"; invoiceOrder: IInvoiceOrder }
    | { type: "FETCH_INVOICE_ORDER_FAILURE" }
    | { type: "RETRY_INVOICE_ORDER" }
    | { type: "RETRY_INVOICE_ORDER_SUCCESS" }
    | { type: "RETRY_INVOICE_ORDER_FAILURE" }
    | { type: "FETCH_INVOICE_ORDER_LINES" }
    | { type: "FETCH_INVOICE_ORDER_LINES_SUCCESS"; invoiceOrderLines: IInvoiceOrderLine[] }
    | { type: "FETCH_INVOICE_ORDER_LINES_FAILURE" }
    | { type: "FETCH_INVOICE_LINES" }
    | { type: "FETCH_INVOICE_LINES_SUCCESS"; invoiceLines: IInvoiceLine[] }
    | { type: "FETCH_INVOICE_LINES_FAILURE" }
    | { type: "VALIDATE_INVOICE_EVENT" }
    | { type: "VALIDATE_INVOICE_EVENT_SUCCESS" }
    | { type: "VALIDATE_INVOICE_EVENT_FAILURE" }
    | { type: "CREATE_INVOICE_EVENT" }
    | { type: "CREATE_INVOICE_EVENT_SUCCESS"; invoiceEvent: IInvoiceEvent }
    | { type: "CREATE_INVOICE_EVENT_FAILURE" }
    | { type: "APPROVE_INVOICE_EVENT" }
    | { type: "APPROVE_INVOICE_EVENT_SUCCESS" }
    | { type: "APPROVE_INVOICE_EVENT_FAILURE" }
    | { type: "DELETE_INVOICE_EVENT" }
    | { type: "DELETE_INVOICE_EVENT_SUCCESS" }
    | { type: "DELETE_INVOICE_EVENT_FAILURE" }
    | { type: "CREATE_FROM_MEMBERSHIP" }
    | { type: "CREATE_FROM_MEMBERSHIP_SUCCESS" }
    | { type: "CREATE_FROM_MEMBERSHIP_FAILURE" };

function reducer(state: IInvoices, action: IAction): IInvoices {
    switch (action.type) {
        case "FETCH_INVOICE_EVENT":
            return { ...state, isFetchingInvoiceEvent: true };
        case "FETCH_INVOICE_EVENT_SUCCESS":
            return { ...state, invoiceEvent: action.invoiceEvent, isFetchingInvoiceEvent: false };
        case "FETCH_INVOICE_EVENT_FAILURE":
            return { ...state, isFetchingInvoiceEvent: false };

        case "FETCH_INVOICE_ORDER":
            return { ...state, isFetchingInvoiceOrder: true };
        case "FETCH_INVOICE_ORDER_SUCCESS":
            return { ...state, invoiceOrder: action.invoiceOrder, isFetchingInvoiceOrder: false };
        case "FETCH_INVOICE_ORDER_FAILURE":
            return { ...state, isFetchingInvoiceOrder: false };

        case "RETRY_INVOICE_ORDER":
            return { ...state, isRetryingInvoiceOrder: true };
        case "RETRY_INVOICE_ORDER_SUCCESS":
        case "RETRY_INVOICE_ORDER_FAILURE":
            return { ...state, isRetryingInvoiceOrder: false };

        case "FETCH_INVOICE_ORDER_LINES":
            return { ...state, isFetchingInvoiceOrderLines: true };
        case "FETCH_INVOICE_ORDER_LINES_SUCCESS":
            return { ...state, invoiceOrderLines: action.invoiceOrderLines, isFetchingInvoiceOrderLines: false };
        case "FETCH_INVOICE_ORDER_LINES_FAILURE":
            return { ...state, isFetchingInvoiceOrderLines: false };

        case "FETCH_INVOICE_LINES":
            return { ...state, isFetchingInvoiceLines: true };
        case "FETCH_INVOICE_LINES_SUCCESS":
            return { ...state, invoiceLines: action.invoiceLines, isFetchingInvoiceLines: false };
        case "FETCH_INVOICE_LINES_FAILURE":
            return { ...state, isFetchingInvoiceLines: false };

        case "VALIDATE_INVOICE_EVENT":
            return { ...state, isValidatingInvoiceEvent: true };
        case "VALIDATE_INVOICE_EVENT_SUCCESS":
        case "VALIDATE_INVOICE_EVENT_FAILURE":
            return { ...state, isValidatingInvoiceEvent: false };

        case "CREATE_INVOICE_EVENT":
            return { ...state, isCreatingInvoiceEvent: true };
        case "CREATE_INVOICE_EVENT_SUCCESS":
            return { ...state, isCreatingInvoiceEvent: false, invoiceEvent: action.invoiceEvent };
        case "CREATE_INVOICE_EVENT_FAILURE":
            return { ...state, isCreatingInvoiceEvent: false };

        case "APPROVE_INVOICE_EVENT":
            return { ...state, isApprovingInvoiceEvent: true };
        case "APPROVE_INVOICE_EVENT_SUCCESS":
        case "APPROVE_INVOICE_EVENT_FAILURE":
            return { ...state, isApprovingInvoiceEvent: false };

        case "DELETE_INVOICE_EVENT":
            return { ...state, isDeletingInvoiceEvent: true };
        case "DELETE_INVOICE_EVENT_SUCCESS":
        case "DELETE_INVOICE_EVENT_FAILURE":
            return { ...state, isDeletingInvoiceEvent: false };

        case "CREATE_FROM_MEMBERSHIP":
            return { ...state, isCreatingFromMembership: true };
        case "CREATE_FROM_MEMBERSHIP_SUCCESS":
        case "CREATE_FROM_MEMBERSHIP_FAILURE":
            return { ...state, isCreatingFromMembership: false };

        default:
            return state;
    }
}

export default reducer;
