import React, { useMemo } from "react";
import Grid from "@mui/material/Grid";
import { Skeleton, Typography } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/NavigateNext";
import { INewsArticle } from "reducers/newsArticles";
import ExternalImageWithDefault from "./externalImageWithDefault";
import colors from "styles/colors";
import { IBuilding, IBuildingImage } from "reducers/realestates";
import { getPopularNameOfBuilding } from "views/newsView";
import { getHexBrightness } from "utils/helpers";
import { NewsArticleContext } from "contexts/newsArticles";

interface IProps extends INewsArticle {
    onClick: () => void;
    building?: IBuilding;
}

const getBuildingImage = (building?: IBuilding): IBuildingImage | undefined => {
    if (!building) {
        return undefined;
    }
    if (building.building_images.length) {
        return building.building_images[0];
    }
    return undefined;
};

const NewsBanner: React.FC<IProps> = ({ title, header, building, start_date, onClick, type_value }) => {
    const { isFetchingNews } = React.useContext(NewsArticleContext);

    const buildingImage = getBuildingImage(building);

    const imageTextColor = useMemo((): string => {
        if (!buildingImage?.mean_hex) {
            return colors.black;
        }
        return getHexBrightness(buildingImage.mean_hex) > 125 ? colors.black : colors.white;
    }, [buildingImage?.mean_hex]);

    if (isFetchingNews) {
        return (
            <Grid container direction="column" wrap="nowrap" marginBottom="1rem" bgcolor={colors.white}>
                <Grid item>
                    <Skeleton variant="rectangular" width="100%" height="10em" />
                </Grid>
                <Grid item>
                    <Skeleton variant="rectangular" width="90%" height="20px" sx={{ margin: "10px auto" }} />
                    <Skeleton variant="rectangular" width="90%" height="20px" sx={{ margin: "10px auto" }} />
                    <Skeleton variant="rectangular" width="90%" height="20px" sx={{ margin: "10px auto" }} />
                    <Skeleton variant="rectangular" width="90%" height="20px" sx={{ margin: "10px auto" }} />
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container item md={12} direction="column" wrap="nowrap" bgcolor={colors.white} marginBottom="1rem">
            <Grid item md={12} style={{ height: "9rem" }}>
                <Typography
                    variant="body1"
                    fontWeight="bold"
                    position="absolute"
                    sx={{
                        color: imageTextColor,
                        paddingLeft: "1rem",
                        paddingTop: "1rem",
                    }}
                >
                    {getPopularNameOfBuilding(building, type_value === "ArenaNews")}
                </Typography>
                <ExternalImageWithDefault
                    style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                    }}
                    alt="News article building"
                    url={buildingImage?.url}
                />
            </Grid>
            <Grid
                container
                item
                direction="column"
                paddingX="1rem"
                marginTop="1rem"
                flexWrap="nowrap"
                overflow="hidden"
                sx={{
                    wordWrap: "break-word",
                }}
            >
                <Typography variant="body1" fontWeight="bold" fontSize="1.2rem">
                    {title}
                </Typography>
                {header && (
                    <Grid item>
                        <Typography fontSize="0.9rem" marginTop="10px" overflow="hidden">
                            {header}
                        </Typography>
                    </Grid>
                )}
            </Grid>
            <Grid
                container
                item
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    "& p": {
                        marginLeft: "1.5em",
                    },
                }}
            >
                <Typography variant="body2" fontSize="1rem">
                    {start_date}
                </Typography>
                <ArrowRightIcon
                    sx={{
                        fontSize: "40px",
                        color: "white",
                        backgroundColor: colors.vkBlue,
                        cursor: "pointer",
                    }}
                    onClick={onClick}
                />
            </Grid>
        </Grid>
    );
};

export default NewsBanner;
