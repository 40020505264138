export enum dateFormats {
    WEBDATE = "yyyy-MM-dd",
    WEBTIME = "HH:mm",
    WEBDATETIME = "yyyy-MM-dd HH:mm",
    APIDATE = "yyyy-MM-dd",
    APIDATETIME = "yyyy-MM-dd HH:mm:ss",
    LOCALIZED_DATE = "PPP",
    YEAR_MONTH = "yyyy-MM",
}

export enum InvitationStatus {
    Accepted = "accepted",
    Pending = "pending",
    Expired = "expired",
}
