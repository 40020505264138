import React from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { LocalizeText } from "components/localizer";
import { InputBaseComponentProps, TextField } from "@mui/material";
import { TLanguageTag } from "contexts/language";

interface IPassword {
    password: string;
    username: string;
    showPassword: boolean;
}

interface IProps {
    onChange: (value: string) => void;
    tag?: TLanguageTag;
    fullWidth?: boolean;
    inputProps?: InputBaseComponentProps;
}

const Password: React.FC<IProps> = ({ onChange, tag, fullWidth, inputProps }) => {
    const [values, setValues] = React.useState<IPassword>({
        password: "",
        username: "",
        showPassword: false,
    });

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
    };

    const setPassword = (password: string): void => {
        setValues({ ...values, password });
        onChange(password);
    };

    const setShowPassword = (showPassword: boolean): void => {
        setValues({ ...values, showPassword });
    };

    return (
        <TextField
            variant="outlined"
            margin="dense"
            size="small"
            required
            sx={{
                marginTop: "16px",
                marginBottom: "8px",
                background: "#fff",
            }}
            label={<LocalizeText tag={`${tag || "password"}`} />}
            type={values.showPassword ? "text" : "password"}
            value={values.password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth={fullWidth}
            inputProps={{
                ...inputProps,
                "data-testid": "password-password",
                tabIndex: 2,
                sx: {
                    boxShadow: "200px 200px 100px white inset",
                },
                margin: "dense",
            }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            tabIndex={4}
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!values.showPassword)}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="small"
                        >
                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        ></TextField>
    );
};

export default Password;
