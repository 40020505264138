import React, { FC, ReactNode, Reducer, createContext, useCallback, useContext, useMemo, useReducer } from "react";
import webAPIRequest from "api";
import { NotificationContext } from "contexts/notification";
import reducer, { IAction, IMember, IMemberSubscriptionAssociation, IMembers, initialState } from "reducers/members";
import { format } from "date-fns";
import { dateFormats } from "utils/formats";

export const MembersContext = createContext<IMembers>({
    ...initialState,
});

export const MembersProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const { ...notification } = useContext(NotificationContext);
    const [currentState, dispatch] = useReducer<Reducer<IMembers, IAction>>(reducer, initialState);

    const fetchMember = useCallback(
        async (memberId: string): Promise<IMember> => {
            try {
                dispatch({ type: "FETCH_MEMBER" });
                const returnData = await webAPIRequest("get", `/members/${memberId}/`);
                dispatch({
                    type: "FETCH_MEMBER_SUCCESS",
                    member: returnData.data,
                });
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_fetchMember", error);
                dispatch({ type: "FETCH_MEMBER_FAILURE" });
                return {} as IMember;
            }
        },
        [notification]
    );

    const fetchAssociation = useCallback(
        async (associationId: string): Promise<IMemberSubscriptionAssociation> => {
            try {
                dispatch({ type: "FETCH_ASSOCIATION" });
                const returnData = await webAPIRequest("get", `/members/associations/${associationId}/`);
                dispatch({
                    type: "FETCH_ASSOCIATION_SUCCESS",
                    association: returnData.data,
                });
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_fetchAssociation", error);
                dispatch({ type: "FETCH_ASSOCIATION_FAILURE" });
                return {} as IMemberSubscriptionAssociation;
            }
        },
        [notification]
    );

    const createAssociation = useCallback(
        async (data: Record<string, unknown>): Promise<IMemberSubscriptionAssociation> => {
            try {
                dispatch({ type: "CREATE_ASSOCIATION" });
                const returnData = await webAPIRequest("post", "/members/associations/", { data });
                notification.enqueNotification("success_createAssociation");
                dispatch({ type: "CREATE_ASSOCIATION_SUCCESS", association: returnData.data });
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_createAssociation", error);
                dispatch({ type: "CREATE_ASSOCIATION_FAILURE" });
                return {} as IMemberSubscriptionAssociation;
            }
        },
        [notification]
    );

    const deactivateAssociation = useCallback(
        async (associationId: string, date: Date): Promise<void> => {
            try {
                dispatch({ type: "DEACTIVATE_ASSOCIATION" });
                const data = {
                    deactivates: format(date, dateFormats.WEBDATE),
                };
                await webAPIRequest("patch", `/members/associations/${associationId}/deactivate/`, {
                    data,
                });
                dispatch({ type: "DEACTIVATE_ASSOCIATION_SUCCESS" });
            } catch (error) {
                notification.enqueNotification("error_deactivateAssociation", error);
                dispatch({ type: "DEACTIVATE_ASSOCIATION_FAILURE" });
                return;
            }
        },
        [notification]
    );

    const removeAssociation = useCallback(
        async (associationId: string): Promise<boolean> => {
            try {
                dispatch({ type: "REMOVE_ASSOCIATION" });
                await webAPIRequest("delete", `/members/associations/${associationId}/`);
                dispatch({ type: "REMOVE_ASSOCIATION_SUCCESS" });
                return true;
            } catch (error) {
                notification.enqueNotification("error_removeAssociation", error);
                dispatch({ type: "REMOVE_ASSOCIATION_FAILURE" });
                return false;
            }
        },
        [notification]
    );

    const resendInvitation = useCallback(
        async (associationUuid: string): Promise<void> => {
            try {
                dispatch({ type: "RESEND_INVITATION" });
                await webAPIRequest("put", `/members/associations/${associationUuid}/resend/`);
                notification.enqueNotification("success_resendInvitation");
                dispatch({ type: "RESEND_INVITATION_SUCCESS" });
            } catch (error) {
                notification.enqueNotification("error_resendInvation", error);
                dispatch({ type: "RESEND_INVITATION_FAILURE" });
            }
        },
        [notification]
    );

    const updateAssociation = useCallback(
        async (associationId: string, data: Record<string, unknown>): Promise<IMemberSubscriptionAssociation> => {
            try {
                dispatch({ type: "UPDATE_ASSOCIATION" });
                const returnData = await webAPIRequest("patch", `/members/associations/${associationId}/`, { data });
                dispatch({ type: "UPDATE_ASSOCIATION_SUCCESS", association: returnData.data });
                notification.enqueNotification("success_updateAssociation");
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_updateAssociation", error);
                dispatch({ type: "UPDATE_ASSOCIATION_FAILURE" });
                return {} as IMemberSubscriptionAssociation;
            }
        },
        [notification]
    );

    const value = useMemo(() => {
        return {
            ...currentState,
            fetchMember,
            fetchAssociation,
            createAssociation,
            deactivateAssociation,
            removeAssociation,
            resendInvitation,
            updateAssociation,
        };
    }, [
        currentState,
        fetchMember,
        fetchAssociation,
        createAssociation,
        deactivateAssociation,
        removeAssociation,
        resendInvitation,
        updateAssociation,
    ]);

    return <MembersContext.Provider value={value}>{children}</MembersContext.Provider>;
};
