import React from "react";
import Dashboard, { DashboardRootWrapper } from "views/Dashboard";
import NewsSidebar from "components/newsComponents/NewsSidebar";
import { permission } from "utils/permissions";

const ArenaMemberDashboardView: React.FC = () => {
    return (
        <DashboardRootWrapper container wrap="nowrap">
            <Dashboard
                banners={[
                    {
                        title: "memberships",
                        breadcrumbs: "bannerMemberships",
                        image: `${process.env.PUBLIC_URL}/assets/images/1024x683/memberships-1024x683.jpg`,
                        route: "memberships",
                        requiredPermissions: permission.MEMBERSHIPS
                    },
                    {
                        title: "subscriptions",
                        breadcrumbs: "bannerSubscriptions",
                        image: `${process.env.PUBLIC_URL}/assets/images/1024x683/subscriptions-1024x683.jpg`,
                        route: "subscriptions",
                        requiredPermissions: permission.SUBSCRIPTIONS
                    },
                    {
                        title: "arenaOffices",
                        breadcrumbs: "bannerArenaOffices",
                        image: `${process.env.PUBLIC_URL}/assets/images/1024x683/arenaOffice-1024x683.jpg`,
                        route: "arena-offices",
                        requiredPermissions: permission.ARENA_OFFICE
                    },
                    {
                        title: "bannerArenaInformationTitle",
                        breadcrumbs: "bannerArenaInformation",
                        image:`${process.env.PUBLIC_URL}/assets/images/1024x683/memberships-1024x683.jpg`,
                        route: "arena-buildings",
                        requiredPermissions: permission.ARENA_BUILDINGINFO
                    }
                ]}
            />
            <NewsSidebar />
        </DashboardRootWrapper>
    );
};

export default ArenaMemberDashboardView;
