import React, { FC, useCallback, useContext, useMemo, useState } from "react";
import { Grid, Tooltip, Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import { dateFormats } from "utils/formats";
import { LocalizeText } from "components/localizer";
import TableComponent, { IRow } from "components/tableComponent";
import { THeadCell } from "utils/types";
import { LanguageContext, TLanguageTag } from "contexts/language";
import { IUsePaginationStore, usePaginationStore } from "utils/usePaginationStore";
import { DatePicker } from "components/datePicker";
import { MultiSelectDropdown } from "components/selectDropdown";
import { TPublicEventTypes } from "adapters/eventsAdapter";
import VKButton from "components/vkButton";

const headCells: THeadCell[] = [
    {
        id: "created",
        label: <LocalizeText tag="created" />,
    },
    { id: "community_title", label: <LocalizeText tag="arena" /> },
    { id: "type", label: <LocalizeText tag="type" /> },
    { id: "description", label: <LocalizeText tag="description" />, allowLineBreaks: true },
];

const EventsView: FC = () => {
    const { localize } = useContext(LanguageContext);
    const pagination = usePaginationStore("public-events", undefined, { ordering: "asc" });
    const [startDate, setStartDate] = useState<Date>(new Date("2023-10-01"));
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
    const [timeoutHandle, setTimeoutHandle] = useState<NodeJS.Timeout | null>(null);

    const onChangeStartDate = useCallback(
        (newDate: unknown) => {
            const date = newDate as Date;
            setStartDate(date);
            if (date > endDate) {
                setEndDate(date);
            }
            pagination.setParam("period_after", format(date, dateFormats.APIDATE));
        },
        [endDate, pagination]
    );

    const onChangeEndDate = useCallback(
        (newDate: unknown) => {
            const date = newDate as Date;
            setEndDate(date);
            if (date < startDate) {
                setStartDate(date);
            }
            pagination.setParam("period_before", format(date, dateFormats.APIDATE));
        },
        [startDate, pagination]
    );

    const onCommunitiesChange = useCallback(
        (ids: string[]) => {
            setSelectedTypes(ids);

            if (timeoutHandle) {
                clearTimeout(timeoutHandle);
            }
            setTimeoutHandle(
                setTimeout(() => {
                    pagination.setParam("public_event_type", ids.join(","));
                }, 500)
            );
        },
        [timeoutHandle, pagination]
    );

    return (
        <div className="contentWrapper">
            <Typography variant="h1" fontSize="2rem" fontFamily="vasakronan">
                <LocalizeText tag="events" />
            </Typography>

            <Grid container gap="20px">
                <Grid item>
                    <DatePicker
                        format={dateFormats.WEBDATE}
                        value={startDate}
                        label={<LocalizeText tag="from" />}
                        slotProps={{ textField: { size: "small" } }}
                        onChange={onChangeStartDate}
                    />
                </Grid>
                <Grid item>
                    <DatePicker
                        format={dateFormats.WEBDATE}
                        value={endDate}
                        label={<LocalizeText tag="until" />}
                        slotProps={{ textField: { size: "small" } }}
                        onChange={onChangeEndDate}
                    />
                </Grid>
                <Grid item>
                    <MultiSelectDropdown
                        selectedIds={selectedTypes}
                        onToggle={onCommunitiesChange}
                        noSelectionTag="typeFiltering"
                        width="400px"
                        items={TPublicEventTypes.map((type) => ({
                            id: type,
                            primary: localize(`eventType_${type}`),
                        }))}
                    />
                </Grid>
                <Grid item ml="auto">
                    <VKButton
                        tag="reset"
                        size="small"
                        onClick={() => {
                            const start = new Date("2023-10-01");
                            const end = new Date();
                            setStartDate(start);
                            setEndDate(end);
                            setSelectedTypes([]);
                            if (timeoutHandle) {
                                clearTimeout(timeoutHandle);
                            }
                            pagination.setParam("period_after", format(start, dateFormats.APIDATE));
                            pagination.setParam("period_before", format(end, dateFormats.APIDATE));
                            pagination.setParam("public_event_type", null);
                        }}
                    />
                </Grid>
            </Grid>

            <EventsTable pagination={pagination} />
        </div>
    );
};

interface EventsTableProps {
    pagination: IUsePaginationStore<"public-events">;
}
const EventsTable: FC<EventsTableProps> = ({ pagination }) => {
    const rows = useMemo((): IRow[] => {
        return pagination.results.map((event) => {
            const { id, created, community, public_event_type, description } = event;

            return {
                data: [
                    <Tooltip key={`created_${id}`} title={format(parseISO(created ?? ""), dateFormats.WEBDATETIME)}>
                        <span>{format(parseISO(created ?? ""), dateFormats.WEBDATE)}</span>
                    </Tooltip>,
                    community.title || "-",
                    <LocalizeText key={`type_${id}`} tag={`eventType_${public_event_type}` as TLanguageTag} />,
                    description || "-",
                ],
            };
        });
    }, [pagination.results]);

    return (
        <TableComponent
            headCells={headCells}
            rows={rows}
            pagination={pagination}
            isLoading={pagination.query.isFetching}
        />
    );
};

export default EventsView;
