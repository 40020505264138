import React, { FC, ReactNode, useEffect, useState } from "react";
import { LocalizeText } from "components/localizer";
import { IMembership } from "reducers/memberships";
import { Grid, ListItemText, MenuItem, TextField } from "@mui/material";
import ListInlineEditWrapper from "../listComponents/listInlineEditWrapper";
import validator from "utils/validator";
import { TLanguageTag } from "contexts/language";
import { usePermission } from "utils/hooks/usePermission";

interface IProps {
    membership: IMembership;
    onSubmitUpdate: (props: Record<string, unknown>) => void;
}

const InvoiceMethodFields: FC<IProps> = ({ membership, onSubmitUpdate }) => {
    const { hasPermission } = usePermission();
    const [invoiceMethodFields, setInvoiceMethodFields] = useState({
        method: membership.selected_invoicing_method || "",
        pdf_address: membership.pdf_address || "",
        peppol_address: membership.peppol_address || "",
    });

    useEffect(() => {
        setInvoiceMethodFields({
            method: membership.selected_invoicing_method || "",
            pdf_address: membership.pdf_address || "",
            peppol_address: membership.peppol_address || "",
        });
    }, [membership]);

    const renderPaymentMethodItems = (): ReactNode => {
        const paymentMethodList: { value: string; tag: TLanguageTag }[] = [
            {
                value: "peppol",
                tag: "paymentMethodPeppol",
            },
            {
                value: "pdf",
                tag: "paymentMethodPDF",
            },
        ];

        return paymentMethodList.map((method) => {
            return (
                <MenuItem key={method.value} value={method.value}>
                    <LocalizeText tag={method.tag} />
                </MenuItem>
            );
        });
    };

    const methodIsValid = ["pdf", "peppol"].includes(invoiceMethodFields.method);
    const pdfEmailIsValid = validator.email(invoiceMethodFields.pdf_address);
    const peppolIsValid = validator.peppol(invoiceMethodFields.peppol_address);

    const isValid = methodIsValid && (pdfEmailIsValid || peppolIsValid);

    return (
        <ListInlineEditWrapper
            onChange={(e) => {
                setInvoiceMethodFields({ ...invoiceMethodFields, [e.target.name]: e.target.value });
            }}
            onSubmit={() =>
                onSubmitUpdate({
                    selected_invoicing_method: invoiceMethodFields.method,
                    pdf_address: invoiceMethodFields.pdf_address,
                    peppol_address: invoiceMethodFields.peppol_address,
                })
            }
            label={<LocalizeText tag="paymentMethod" />}
            renderInput={(props) => (
                <Grid container gap={1}>
                    <TextField
                        error={!methodIsValid}
                        select
                        {...props}
                        name="method"
                        value={invoiceMethodFields.method}
                        label={<LocalizeText tag="paymentMethod" />}
                    >
                        {renderPaymentMethodItems()}
                    </TextField>
                    {invoiceMethodFields.method === "pdf" ? (
                        <TextField
                            {...props}
                            error={!pdfEmailIsValid}
                            name="pdf_address"
                            value={invoiceMethodFields.pdf_address}
                            label={<LocalizeText tag="email" />}
                            helperText={!pdfEmailIsValid && <LocalizeText tag="errorEmail" />}
                        />
                    ) : (
                        <TextField
                            {...props}
                            error={!peppolIsValid}
                            name="peppol_address"
                            value={invoiceMethodFields.peppol_address}
                            label={<LocalizeText tag="peppolID" />}
                            helperText={!peppolIsValid && <LocalizeText tag="errorPeppol" />}
                        />
                    )}
                </Grid>
            )}
            inputIsValid={isValid}
            renderView={(props) => (
                <ListItemText
                    {...props}
                    secondary={
                        <span style={{ display: "flex", flexDirection: "column" }}>
                            <span>
                                <LocalizeText tag="paymentMethod" />: {membership.selected_invoicing_method}
                            </span>
                            {invoiceMethodFields.method === "pdf" ? (
                                <span>
                                    <LocalizeText tag="paymentMethodPDF" />: {membership.pdf_address || "-"}
                                </span>
                            ) : (
                                <span>
                                    <LocalizeText tag="paymentMethodPeppol" />: {membership.peppol_address || "-"}
                                </span>
                            )}
                        </span>
                    }
                />
            )}
            editDisabled={!hasPermission("change_membership") || !!membership.end_date || membership.is_archived}
        />
    );
};

export default InvoiceMethodFields;
