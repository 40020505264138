import React from "react";
import { MembershipsContext } from "contexts/memberships";
import { ProfileContext } from "contexts/profile";
import { useNavigate } from "react-router-dom";
import { TextField, Typography, InputLabel, styled } from "@mui/material";
import Autocomplete, { AutocompleteRenderInputParams } from "@mui/material/Autocomplete";
import { LocalizeText } from "components/localizer";
import { IOrganizationOption } from "reducers/profile";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box } from "@mui/material";
import { Skeleton } from "@mui/material";
import colors from "styles/colors";

const TextArea = styled(TextField)(() => ({
    "&&&&& input": {
        "&:disabled": {
            textOverflow: "clip",
        },
        textTransform: "none",
        color: colors.black,
        marginLeft: "1rem",
    },
    "&&&&& label": {
        marginLeft: "1rem",
        textOverflow: "ellipsis",
    },
    "&&&&& svg": {
        color: colors.vkBlue,
    },
}));

const OrganizationMenu: React.FC = () => {
    const { clearStorage } = React.useContext(MembershipsContext);
    const { isFetchingOrgs, selectedOrganization, organizations, setSelectedOrganization } =
        React.useContext(ProfileContext);
    const navigate = useNavigate();

    const mappedOrganizations = React.useMemo(() => {
        return (organizations || [])
            .filter((org) => org.name !== "")
            .map((org) => {
                return {
                    name: org.name,
                    value: org.org_nr,
                    id: org.id,
                };
            });
    }, [organizations]);

    const [selected, setSelected] = React.useState<IOrganizationOption>({
        name: selectedOrganization.name,
        value: selectedOrganization.org_nr,
        id: selectedOrganization.id,
    });

    const onChangeOrganization = (changed: IOrganizationOption | null): void => {
        if (changed) {
            const newOrg = organizations?.find((org) => org.id === changed.id);
            if (newOrg) {
                clearStorage();
                setSelected(changed);
                setSelectedOrganization(newOrg);
                navigate("/dashboard", {replace: true});
            }
        }
    };

    interface FilterState {
        inputValue: string;
    }

    const customFilterOfOptions = (options: IOrganizationOption[], state: FilterState): IOrganizationOption[] => {
        return options.filter((option) => {
            return (
                option.name.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                option.value.toLowerCase().includes(state.inputValue.toLowerCase())
            );
        });
    };

    const customRenderInput = (params: AutocompleteRenderInputParams): React.ReactNode => {
        return (
            <TextArea
                {...params}
                InputLabelProps={{ shrink: true, ...params.InputLabelProps }}
                variant="standard"
                inputProps={{
                    "data-testid": "organization-textfield",
                    ...params.inputProps,
                }}
                sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                        color: colors.black,
                        WebkitTextFillColor: colors.black,
                    },
                }}
                label={<LocalizeText tag="currentOrganization" />}
            />
        );
    };

    if (isFetchingOrgs) {
        return <Skeleton variant="rectangular" />;
    }
    if (!isFetchingOrgs && !Object.keys(selected).length) {
        return (
            <Box sx={{ backgroundColor: colors.white }}>
                <InputLabel
                    shrink
                    sx={{
                        marginLeft: "1rem",
                        textAlign: "left",
                    }}
                >
                    <LocalizeText tag="currentOrganization" />
                </InputLabel>
                <Typography
                    variant="subtitle1"
                    sx={{
                        marginLeft: "1rem",
                        textAlign: "left",
                        "& span": {
                            width: "100%",
                            textOverflow: "ellipsis",
                            color: colors.greyText,
                        },
                    }}
                >
                    <LocalizeText tag="noData" />
                </Typography>
            </Box>
        );
    }

    return (
        <Autocomplete
            id="combo-box"
            data-testid="select-org"
            options={mappedOrganizations}
            value={selected}
            disabled={mappedOrganizations && mappedOrganizations.length <= 1}
            autoComplete
            autoHighlight
            disableClearable
            onChange={(event, org) => onChangeOrganization(org)}
            getOptionLabel={(orgs) => (orgs.name ? orgs.name : "")}
            isOptionEqualToValue={(option, current) => option.id === current.id}
            sx={{
                backgroundColor: colors.white,
                border: "none",
                color: colors.vkBlue,
            }}
            popupIcon={mappedOrganizations && mappedOrganizations.length > 1 ? <ArrowDropDownIcon /> : <></>}
            renderInput={customRenderInput}
            filterOptions={customFilterOfOptions}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={option.id}>
                        {option.name}
                    </li>
                );
            }}
        />
    );
};

export default OrganizationMenu;
