import React, { FC, useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Banner from "components/banner";
import { LocalizeText } from "components/localizer";
import { HorizontalCardSkeleton } from "components/horizontalCard";
import VKButton from "components/vkButton";
import If from "components/__helpers__/if";
import PaginationStore, { TPaginationActionData, TParamValue, emptyPaginationActionData } from "utils/paginationStore";
import { NotificationContext } from "contexts/notification";
import { fetchServicesAdapter } from "adapters/services";
import { IService } from "reducers/realestates";
import { useIsLoading } from "utils/hooks/useLoading";
import ServiceArticleCard from "components/serviceArticleCard";
import { usePermission } from "utils/hooks/usePermission";
import { permission } from "utils/permissions";

const ServicesView: FC = () => {
    const { enqueNotification } = useContext(NotificationContext);
    const [servicesPagination] = useState(new PaginationStore<IService>());
    const [infiniteScroll, setInfiniteScroll] = useState<boolean>(false);
    const [isFetchingServices, setIsLoading] = useIsLoading(false);
    const { hasPermissions } = usePermission();

    useEffect(() => {
        servicesPagination.setParam("type", getServiceType());
        servicesPagination.initialize(paginationCallback);
        servicesPagination.getInitial();
        servicesPagination.appendResults = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const paginationCallback = async (params: Record<string, unknown>): Promise<TPaginationActionData<IService>> => {
        setIsLoading(true);
        try {
            const entries = await fetchServicesAdapter(params);
            setIsLoading(false);
            return entries.data as TPaginationActionData<IService>;
        } catch (error) {
            enqueNotification("error_fetchServices", error);
            setIsLoading(false);
            return emptyPaginationActionData;
        }
    };

    const handleInfiniteScroll = (): void => {
        if (servicesPagination.next) {
            setInfiniteScroll(true);
            servicesPagination.nextPage();
        }
    };

    const getServiceType = (): TParamValue => {
        const showArena = hasPermissions(permission.ARENA_DASHBOARD);
        const showTenant = hasPermissions(permission.TENANT_DASHBOARD);

        if (showArena && !showTenant) {
            return "arena";
        } else if (showTenant && !showArena) {
            return "tenant";
        } else if (showArena && showTenant) {
            return "arena_and_tenant";
        }
        return null;
    };

    const renderArticles = (): JSX.Element => {
        if (isFetchingServices && !infiniteScroll) {
            return (
                <Grid container spacing={4}>
                    {generateSkeletonCardsList()}
                </Grid>
            );
        }

        return (
            <Grid container spacing={2}>
                {servicesPagination.results.map((service: IService) => {
                    return <ServiceArticleCard key={service.uuid} service={service} />;
                })}
                <If truthy={servicesPagination.next}>
                    <Grid container justifyContent="center">
                        <VKButton template="primary" isLoading={isFetchingServices} onClick={handleInfiniteScroll}>
                            <LocalizeText tag="loadMoreResults" />
                        </VKButton>
                    </Grid>
                </If>
            </Grid>
        );
    };

    const generateSkeletonCardsList = (): JSX.Element[] => {
        return Array.from(new Array(3)).map((_, index) => {
            return (
                <Grid item lg={12} md={12} key={index} data-testid={`skeleton-card-${index}`}>
                    <HorizontalCardSkeleton elevation={0} />
                </Grid>
            );
        });
    };

    return (
        <div className="contentWrapper">
            <Banner
                title="services"
                subTitle="bannerServices"
                image={`${process.env.PUBLIC_URL}/assets/images/1024x683/news-1024x683.jpg`}
            />
            {renderArticles()}
        </div>
    );
};

export default ServicesView;
