import React, { FC, ReactNode, useCallback } from "react";
import { Box, Grid, Skeleton, Typography, Tooltip as MuiTooltip } from "@mui/material";
import {
    BarElement,
    CategoryScale,
    Chart,
    ChartOptions,
    Legend,
    LinearScale,
    Title,
    Tooltip,
    TooltipItem,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Dataset } from "./statsUtils";
import colors from "styles/colors";
import { LocalizeText } from "components/localizer";
import { TLanguageTag } from "contexts/language";
import { InfoOutlined } from "@mui/icons-material";

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const GRAPH_HEIGHT = "500px";

interface Props {
    titleTag: TLanguageTag;
    subtitle?: ReactNode;
    infoText?: TLanguageTag;
    data: { labels: string[]; datasets: Dataset[] };
    tooltipCallback?: (item: TooltipItem<"bar">) => string | string[] | void;
    tooltipTitleCallback?: (items: TooltipItem<"bar">[]) => string | string[] | void;
    yAxisCallback?: (value: number | string) => string;
    showSkeleton?: boolean;
    description?: string | ReactNode;
}
export const StatsBarGraph: FC<Props> = ({
    titleTag,
    subtitle,
    infoText,
    data,
    tooltipCallback,
    tooltipTitleCallback,
    yAxisCallback,
    showSkeleton,
    description,
}) => {
    const defaultTitletooltip = useCallback((items: TooltipItem<"bar">[]): string | string[] | void => {
        return items[0].dataset.stack;
    }, []);

    if (showSkeleton) {
        return <Skeleton width="100%" height={GRAPH_HEIGHT} variant="rectangular" />;
    }

    const legendItems = data.datasets.reduce((items, { label, backgroundColor }) => {
        items[label] = backgroundColor;
        return items;
    }, {} as Record<string, string>);

    const options: ChartOptions<"bar"> = {
        animation: false,
        maintainAspectRatio: false,
        interaction: {
            intersect: false,
            mode: "index",
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: tooltipCallback,
                    title: tooltipTitleCallback || defaultTitletooltip,
                },
                mode: "x",
            },
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
                suggestedMax: 5,
            },
            y: {
                stacked: true,
                afterUpdate: (scaleInstance) => {
                    scaleInstance.width = 90;
                },
                ticks: {
                    callback: yAxisCallback,
                },
            },
        },
    };

    return (
        <Grid
            container
            direction="column"
            flexWrap="nowrap"
            height={GRAPH_HEIGHT}
            bgcolor={colors.white}
            padding="20px"
            gap="10px"
        >
            <Grid item>
                <Typography variant="h2" fontSize="1.5rem">
                    <LocalizeText tag={titleTag} />
                    {infoText ? (
                        <MuiTooltip title={<LocalizeText tag={infoText} />}>
                            <InfoOutlined fontSize="small" sx={{ marginLeft: "10px" }} />
                        </MuiTooltip>
                    ) : null}
                </Typography>
                {subtitle ? (
                    <Typography variant="h3" fontSize="1rem">
                        {subtitle}
                    </Typography>
                ) : null}
            </Grid>
            <Grid item flexGrow={1}>
                <Bar options={options} data={data} />
            </Grid>
            <Grid item container gap="30px" justifyContent="center">
                {Object.entries(legendItems).map(([label, backgroundColor]) => (
                    <Grid key={label} item display="flex" alignItems="center§" gap="5px">
                        <Box width="20px" height="20px" bgcolor={backgroundColor} />
                        <Typography variant="body2">{label}</Typography>
                    </Grid>
                ))}
            </Grid>
            {description ? <Grid item>{description}</Grid> : null}
        </Grid>
    );
};
