import webAPIRequest from "api";

interface DocumentTypes {
    memberships: MembershipDocument;
}

export interface MembershipDocument {
    uuid: string;
    created: string;
    document_nr: string;
    document_file: string;
    document_type: MembershipDocumentTypes;
    membership: number;
}
export const membershipDocumentTypesArr = ["agreement", "other"] as const;
export type MembershipDocumentTypes = typeof membershipDocumentTypesArr[number];

export const fetchDocuments = async <T extends keyof DocumentTypes>(
    type: T,
    id: string
): Promise<DocumentTypes[T][]> => {
    try {
        const returnData = await webAPIRequest("get", `/documents/${type}/`, { params: { membership_id: id } });
        return returnData.data;
    } catch (error) {
        return [] as DocumentTypes[T][];
    }
};

export const uploadDocument = async <T extends keyof DocumentTypes>(
    type: T,
    formData: FormData
): Promise<DocumentTypes[T]> => {
    try {
        const returnData = await webAPIRequest("post", `/documents/${type}/`, { data: formData });
        return returnData.data;
    } catch (error) {
        return {} as DocumentTypes[T];
    }
};
