import React from "react";
import { Grid, styled, Typography } from "@mui/material";
import { LocalizeText } from "components/localizer";
import SmallBanner from "components/smallBanner";
import { useNavigate } from "react-router-dom";
import { ProfileContext } from "contexts/profile";
import { RequiredPerm, usePermission } from "utils/hooks/usePermission";
import colors from "styles/colors";
import { TLanguageTag } from "contexts/language";

export type TSmallBanner = {
    title: TLanguageTag;
    requiredPermissions: RequiredPerm;
    image: string;
    breadcrumbs?: TLanguageTag;
    route?: string;
    inactive?: boolean;
};

export const DashboardRootWrapper = styled(Grid)(() => ({
    minHeight: "100%",
    backgroundColor: colors.white,
    flexWrap: "nowrap",
}));

interface DashboardProps {
    banners: TSmallBanner[];
}

const Dashboard: React.FC<DashboardProps> = ({ banners }) => {
    const navigate = useNavigate();
    const { hasPermissions } = usePermission();
    const { user } = React.useContext(ProfileContext);

    return (
        <Grid item md={9} lg={9} padding="40px 60px">
            <Grid container justifyContent="space-evenly">
                <Grid item md={12} lg={10}>
                    <Typography variant="h3" gutterBottom sx={{ fontFamily: "Vasakronan" }}>
                        <LocalizeText
                            tag="dashboardTitle"
                            replace={user && { firstName: user.first_name, lastName: user.last_name }}
                        />
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item md={12} justifyContent="space-evenly">
                {banners.map(
                    (banner) =>
                        hasPermissions(banner.requiredPermissions) && (
                            <Grid
                                item
                                lg={5}
                                md={10}
                                pt="2rem"
                                key={banner.title}
                                onClick={() => banner.route && navigate(`/${banner.route}`)}
                            >
                                <SmallBanner
                                    title={banner.title}
                                    breadcrumbs={banner.breadcrumbs}
                                    image={banner.image}
                                    inactive={banner.inactive}
                                />
                            </Grid>
                        )
                )}
            </Grid>
        </Grid>
    );
};

export default Dashboard;
