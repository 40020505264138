import React from "react";
import TableComponent, { IRow } from "components/tableComponent";
import {  useLocation, useNavigate } from "react-router-dom";
import Banner from "components/banner";
import { ProfileContext } from "contexts/profile";
import { deepGet } from "utils/helpers";
import RequestAccessToLease from "components/drawers/contactPersons/requestAccessToLease";
import { fontType, LocalizeText } from "components/localizer";
import Drawer from "components/drawer";
import Dropdown from "components/dropdown";
import { getLeaseValidToDate } from "utils/ecommerseTypes";
import { Grid, styled } from "@mui/material";
import EmailText from "components/emailText";
import PhoneText from "components/phoneText";
import LeasesContext from "contexts/leases";
import { ILease } from "reducers/leases";
import colors from "styles/colors";
import { headCells } from "views/lease/leaseViewV2";
import { usePaginationStore } from "utils/usePaginationStore";

interface IState {
    requestAccessDrawerOpen: boolean;
    selectedLease: ILease;
}

export const InvoiceQuestionsBox = styled(Grid)(({ theme }) => ({
    position: "absolute",
    bottom: "1em",
    "& p, a": {
        color: colors.white,
    },
}));

const LeaseSelectView: React.FC = () => {
    const { setLease, requestAccessToLease } = React.useContext(LeasesContext);
    const { selectedOrganization } = React.useContext(ProfileContext);
    const [state, setState] = React.useState<IState>({
        requestAccessDrawerOpen: false,
        selectedLease: {} as ILease,
    });
    const pagination = usePaginationStore("leases", undefined, { org_nr: selectedOrganization.org_nr });

    const navigate = useNavigate();
    const location = useLocation();

    const singleAccessUser = pagination.totalCount === 1 && !pagination.getParam("search");

    const selectLease = (lease: ILease): void => {
        setLease(lease);
        navigate(`${location.pathname}/${lease.lease_uuid}`);
    };

    React.useEffect(() => {
        if (singleAccessUser && pagination.results.length > 0) {
            selectLease(pagination.results[0]);
        }
        // eslint-disable-next-line
    }, [pagination.results, location]);

    const mapRows = (leases: ILease[]): IRow[] =>
        leases.map((lease) => {
            return {
                onClick: () => {
                    if (lease.has_manage_permission) {
                        selectLease(lease);
                    }
                },
                data: [
                    lease.address || "-",
                    lease.agreement_number ?? "-",
                    deepGet("active_lease_contract.premise_group_name", lease, "-"),
                    Number(lease.active_lease_contract?.area).toFixed(0) || "-",
                    getLeaseValidToDate(lease.valid_to_date),
                    lease.lease_manager?.full_name || "-",
                    !lease.has_manage_permission ? (
                        <Dropdown
                            inRow
                            key={lease.lease_uuid}
                            items={[
                                {
                                    onClick: () =>
                                        setState({ ...state, requestAccessDrawerOpen: true, selectedLease: lease }),
                                    label: <LocalizeText tag="requestAccess" />,
                                },
                            ]}
                        />
                    ) : (
                        ""
                    ),
                ],
                disabled: !lease.has_manage_permission,
            };
        });

    const onRequestAccess = async (lease_uuid: string, email: string): Promise<void> => {
        const success = await requestAccessToLease(lease_uuid, email);
        if (success) {
            setState({ ...state, requestAccessDrawerOpen: false });
        }
    };

    const rows = mapRows(pagination.results);

    return (
        <div data-testid="leaseSelectView-root" className="contentWrapper">
            <Banner
                title="customerLedgers"
                subTitle="bannerCustomerLedgers"
                image={`${process.env.PUBLIC_URL}/assets/images/1024x683/customerLedgers-1024x683.jpg`}
                customContent={
                    <InvoiceQuestionsBox container>
                        <Grid item xs={12}>
                            <LocalizeText type={fontType.BOLD} tag="invoiceQuestions" />
                        </Grid>
                        <Grid item xs={12}>
                            <EmailText email="ekonomi@vasakronan.se" />
                        </Grid>
                        <Grid item xs={12}>
                            <PhoneText phoneNr="08-56620500" />
                        </Grid>
                    </InvoiceQuestionsBox>
                }
            />
            <TableComponent
                headCells={headCells}
                isLoading={pagination.query.isFetching}
                rows={rows}
                skeletonVariant="options"
                pagination={pagination}
            />
            <Drawer
                isOpen={state.requestAccessDrawerOpen}
                onClose={() => setState({ ...state, requestAccessDrawerOpen: false })}
                title={<LocalizeText tag="accessToLeaseAgreement" />}
            >
                <RequestAccessToLease onSubmit={onRequestAccess} lease={state.selectedLease} />
            </Drawer>
        </div>
    );
};

export default LeaseSelectView;
