import { Grid, styled, Typography } from "@mui/material";
import { LocalizeText } from "components/localizer";
import colors from "styles/colors";
import React from "react";
import { INewsArticle } from "reducers/newsArticles";
import VKButton from "components/vkButton";
import NewsBanner from "components/newsBanner";
import { NewsArticleContext } from "contexts/newsArticles";
import { ProfileContext } from "contexts/profile";
import NewsDialog from "components/dialogs/newsDialog";
import { getArticleBuilding } from "views/newsView";
import { ModalContext } from "contexts/modals";
import { useNavigate } from "react-router-dom";

const NewsSidebarContainer = styled(Grid)(({ theme }) => ({
    backgroundColor: colors.background,
    padding: "2rem",
    flexFlow: "column",
    "& div": {
        flexBasis: "auto",
    },
    "& a": {
        textDecoration: "none",
        color: colors.vkBlue,
    },
    [theme.breakpoints.down("md")]: {
        display: "none",
    },
}));

const NewsSidebar: React.FC = () => {
    const navigate = useNavigate();
    const { selectedOrganization } = React.useContext(ProfileContext);
    const { fetchNews, newsListPagination, defaultArticle } = React.useContext(NewsArticleContext);
    const { pushDialog } = React.useContext(ModalContext);

    React.useEffect(() => {
        newsListPagination.initialize(fetchNews);
        newsListPagination.setParam("org_nr", selectedOrganization.org_nr);
        newsListPagination.getInitial();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOrganization]);

    const onClick = (article: INewsArticle): void => {
        pushDialog(NewsDialog, { newsArticle: article });
    };

    const renderNewsList = (): JSX.Element => {
        const newsList = newsListPagination.totalCount ? newsListPagination.results.slice(0, 2) : [defaultArticle];
        return (
            <>
                {newsList.map((article) => {
                    return (
                        <NewsBanner
                            building={getArticleBuilding(article)}
                            onClick={() => onClick(article)}
                            {...article}
                            key={article.title}
                        />
                    );
                })}
            </>
        );
    };
    return (
        <NewsSidebarContainer container item md={3}>
            <Grid container item md={12}>
                <Typography variant="h5" marginBottom={"1em"}>
                    <LocalizeText tag="news" />
                </Typography>
            </Grid>
            <Grid container item md={12}>
                {renderNewsList()}
                <Grid container justifyContent="flex-end">
                    <VKButton variant="outlined" color="primary" onClick={() => navigate("/news")} size="small">
                        <LocalizeText tag="showAll" />
                    </VKButton>
                </Grid>
            </Grid>
        </NewsSidebarContainer>
    );
};

export default NewsSidebar;
