import React, { FC } from "react";
import { IAlternativeInvoiceRecipient } from "reducers/memberships";
import { List } from "@mui/material";
import { ExistingRecipientInfo } from "./alternativeRecipient/existingRecipientInfo";

interface IProps {
    recipient: IAlternativeInvoiceRecipient;
}

const ExistingRecipientDrawer: FC<IProps> = ({ recipient }) => {
    return (
        <List>
            <ExistingRecipientInfo recipient={recipient} />
        </List>
    );
};

export default ExistingRecipientDrawer;
