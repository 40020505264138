import React, { FC, useEffect, useState } from "react";
import { LocalizeText } from "components/localizer";
import { IMembership } from "reducers/memberships";
import { Grid, ListItemText, TextField } from "@mui/material";
import ListInlineEditWrapper from "../listComponents/listInlineEditWrapper";
import { prettifyPostcode } from "utils/prettifiers";
import validator from "utils/validator";
import { usePermission } from "utils/hooks/usePermission";

interface IProps {
    membership: IMembership;
    onSubmitUpdate: (props: Record<string, unknown>) => void;
}

const InvoiceAddressFields: FC<IProps> = ({ membership, onSubmitUpdate }) => {
    const { hasPermission } = usePermission();
    const [invoiceAddressFields, setInvoiceAddressFields] = useState({
        address1: membership.postal_address1 || "",
        address2: membership.postal_address2 || "",
        zipCode: membership.postal_postcode || "",
        city: membership.postal_city || "",
    });

    const addressOneIsValid = validator.textFieldNotEmpty(invoiceAddressFields.address1);
    const zipCodeIsValid = validator.textFieldNotEmpty(invoiceAddressFields.zipCode);
    const cityIsValid = validator.textFieldNotEmpty(invoiceAddressFields.city);

    const isValid = addressOneIsValid && zipCodeIsValid && cityIsValid;

    useEffect(() => {
        setInvoiceAddressFields({
            address1: membership.postal_address1 || "",
            address2: membership.postal_address2 || "",
            zipCode: membership.postal_postcode || "",
            city: membership.postal_city || "",
        });
    }, [membership]);

    return (
        <ListInlineEditWrapper
            onChange={(e) => {
                setInvoiceAddressFields({ ...invoiceAddressFields, [e.target.name]: e.target.value });
            }}
            onSubmit={() =>
                onSubmitUpdate({
                    postal_address1: invoiceAddressFields.address1,
                    postal_address2: invoiceAddressFields.address2,
                    postal_city: invoiceAddressFields.city,
                    postal_postcode: invoiceAddressFields.zipCode,
                })
            }
            label={<LocalizeText tag="invoiceAddress" />}
            renderInput={(props) => (
                <Grid container gap={1}>
                    <TextField
                        {...props}
                        error={!addressOneIsValid}
                        name="address1"
                        value={invoiceAddressFields.address1}
                        label={<LocalizeText tag="addressLine1" />}
                    />
                    <TextField
                        {...props}
                        name="address2"
                        value={invoiceAddressFields.address2}
                        label={<LocalizeText tag="addressLine2" />}
                    />
                    <TextField
                        {...props}
                        error={!zipCodeIsValid}
                        name="zipCode"
                        value={invoiceAddressFields.zipCode}
                        label={<LocalizeText tag="zipCode" />}
                    />
                    <TextField
                        {...props}
                        error={!cityIsValid}
                        name="city"
                        value={invoiceAddressFields.city}
                        label={<LocalizeText tag="town" />}
                    />
                </Grid>
            )}
            inputIsValid={isValid}
            renderView={(props) => (
                <ListItemText
                    {...props}
                    secondary={
                        <span style={{ display: "flex", flexDirection: "column" }}>
                            <span>
                                <LocalizeText tag="addressLine1" />: {membership.postal_address1 || "-"}
                            </span>
                            <span>
                                <LocalizeText tag="addressLine2" />: {membership.postal_address2 || "-"}
                            </span>
                            <span>
                                <LocalizeText tag="zipCode" />: {prettifyPostcode(membership.postal_postcode || "-")}
                            </span>
                            <span>
                                <LocalizeText tag="town" />: {membership.postal_city || "-"}
                            </span>
                        </span>
                    }
                />
            )}
            editDisabled={!hasPermission("change_membership") || !!membership.end_date || membership.is_archived}
        />
    );
};

export default InvoiceAddressFields;
