import React, { useRef } from "react";

export const useIsLoading = (init: boolean): [boolean, (loading: boolean) => void] => {
    const [isLoadingState, setIsLoadingInState] = React.useState<boolean>(init);
    const mounted = useRef(false);
    React.useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    const setIsLoading = (loading: boolean): void => {
        if (mounted.current) {
            setIsLoadingInState(loading);
        }
    };

    return [isLoadingState, setIsLoading];
};
