/* eslint-disable @typescript-eslint/no-var-requires */
export interface IFeature {
    feature_id: string;
    active: boolean;
    message: string;
}

export interface IFeatureFlags {
    features: IFeature[];
    isFetchingFeatureFlags: boolean;
}

export const initialState: IFeatureFlags = {
    features: [],
    isFetchingFeatureFlags: false,
};

export type IAction =
    | { type: "FETCH_FLAGS" }
    | { type: "FETCH_FLAGS_SUCCESS"; features: IFeature[] }
    | { type: "FETCH_FLAGS_FAILURE" };

function reducer(state: IFeatureFlags, action: IAction): IFeatureFlags {
    switch (action.type) {
        case "FETCH_FLAGS":
            return { ...state, isFetchingFeatureFlags: true };
        case "FETCH_FLAGS_SUCCESS":
            return {
                ...state,
                isFetchingFeatureFlags: false,
                features: action.features,
            };
        case "FETCH_FLAGS_FAILURE":
            return { ...state, isFetchingFeatureFlags: false };

        default:
            return { ...state };
    }
}

export default reducer;
