import React, { FC, useMemo } from "react";
import { Typography } from "@mui/material";
import Dropdown, { TDropdownItem } from "components/dropdown";
import { InvoiceStatus } from "components/invoices/invoiceStatus";
import { LocalizeText } from "components/localizer";
import PrettifyPrice from "components/prettifyPrice";
import TableComponent, { IRow } from "components/tableComponent";
import { TLanguageTag } from "contexts/language";
import { MembershipsContext } from "contexts/memberships";
import { IInvoice } from "reducers/invoices";
import { dateFormats } from "utils/formats";
import { formatPossibleNullDate } from "utils/helpers";
import colors from "styles/colors";
import { THeadCell } from "utils/types";
import { useNavigate } from "react-router-dom";

const invoiceHeadCells: THeadCell[] = [
    { id: "type", label: <LocalizeText tag="type" /> },
    { id: "invoiceNumber", label: <LocalizeText tag="invoiceNumber" /> },
    { id: "due_date", label: <LocalizeText tag="dueDate" /> },
    {
        id: "amount_to_pay",
        label: (
            <>
                <LocalizeText tag="priceAmount" /> <LocalizeText tag="includingVat" />
            </>
        ),
    },
    { id: "payment_status", label: <LocalizeText tag="status" /> },
    { id: "", label: "", alignCell: "right" },
];

interface IInvoicesTableProps {
    orderUuid: string;
    invoices: IInvoice[];
    currency: TLanguageTag;
}
export const InvoicesTable: FC<IInvoicesTableProps> = ({ orderUuid, invoices, currency }) => {
    const navigate = useNavigate();

    const rows = useMemo(() => {
        return invoices.map((invoice): IRow => {
            const { uuid, invoice_number, amount_to_pay, due_date, is_overdue } = invoice;

            let type: TLanguageTag = "normal";
            switch (invoice.type) {
                case "PREPAYMENT":
                    type = "prepayment";
                    break;
                case "CREDIT":
                    type = "credit";
                    break;
            }

            return {
                onClick: () => navigate(`/staff-invoices/${orderUuid}/${uuid}`),
                data: [
                    <LocalizeText key={`type_${uuid}`} tag={type} />,
                    invoice_number,
                    <Typography
                        key={`due_date_${uuid}`}
                        color={is_overdue ? colors.errorRed : undefined}
                        variant="inherit"
                    >
                        {formatPossibleNullDate(due_date, dateFormats.WEBDATE) || "-"}
                    </Typography>,
                    <PrettifyPrice key={`amount_due_${uuid}`} amount={amount_to_pay} currency={currency} />,
                    <InvoiceStatus key={`status_${uuid}`} invoice={invoice} />,
                    <InvoiceDropdown key={`dropdown_${uuid}`} invoice={invoice} />,
                ],
            };
        });
    }, [invoices, currency, navigate, orderUuid]);

    return <TableComponent rows={rows} headCells={invoiceHeadCells} />;
};

interface IInvoiceDropdownProps {
    invoice: IInvoice;
    membershipId?: number;
}
const InvoiceDropdown: FC<IInvoiceDropdownProps> = ({ invoice, membershipId }) => {
    const { invoice_pdf_url } = invoice;
    const { fetchMembership } = React.useContext(MembershipsContext);
    const navigate = useNavigate();

    const dropdownItems: TDropdownItem[] = [];
    if (membershipId) {
        dropdownItems.push({
            onClick: async () => {
                const membership = await fetchMembership(membershipId);
                navigate(`/staff-memberships/${membership.id}/details`);
            },
            label: <LocalizeText tag="showMembership" />,
        });
    }
    if (invoice_pdf_url) {
        dropdownItems.push({
            onClick: () => window.open(invoice_pdf_url),
            label: <LocalizeText tag="downloadPdf" />,
        });
    }
    if (dropdownItems.length === 0) {
        return null;
    }
    return <Dropdown inRow items={dropdownItems} />;
};
