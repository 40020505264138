import React from "react";
import VKButton from "components/vkButton";
import { LocalizeText } from "components/localizer";
import { validatePasswordGetErrorTag } from "utils/helpers";
import Password from "components/password";
import { DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormHelperText, Input } from "@mui/material";
import { ProfileContext } from "contexts/profile";
import PasswordCriteria from "components/passwordCriteria";
import { ModalContext } from "contexts/modals";
import { TLanguageTag } from "contexts/language";

interface IPasswordState {
    oldPassword: string;
    password: string;
    rePassword: string;
}

const ChangePassWordFormDialog: React.FC = () => {
    const { isUpdatingPassword, changeUserPassword, user } = React.useContext(ProfileContext);
    const { popDialog } = React.useContext(ModalContext);

    const [values, setValues] = React.useState<IPasswordState>({
        oldPassword: "",
        password: "",
        rePassword: "",
    });

    const handleChangePassword = async (): Promise<void> => {
        const updated = await changeUserPassword({
            old_password: values.oldPassword,
            new_password: values.password,
            new_password_again: values.rePassword,
        });
        if (updated) {
            popDialog();
        }
    };

    const setValue = (type: string, value: string): void => {
        setValues({ ...values, [type]: value });
    };

    const errorTag = validatePasswordGetErrorTag(values.password, values.rePassword);
    const criteriaTags: TLanguageTag[] = ["passwordCriteria1", "passwordCriteria2", "passwordCriteria3"];

    return (
        <>
            <DialogTitle>
                <LocalizeText tag="changePassword" />
            </DialogTitle>
            <Divider />
            <DialogContent>
                <PasswordCriteria criteriaTags={criteriaTags} />
                <FormControl error sx={{ width: "100%" }}>
                    <Input disableUnderline autoComplete="email" type="hidden" value={user?.email} />
                    <Password
                        inputProps={{ autoComplete: "current-password" }}
                        fullWidth
                        tag="currentPassword"
                        onChange={(value: string) => setValue("oldPassword", value)}
                    />
                    <Password
                        inputProps={{ autoComplete: "new-password" }}
                        fullWidth
                        tag="newPassword"
                        onChange={(value: string) => setValue("password", value)}
                    />
                    <Password
                        tag="newPasswordAgain"
                        fullWidth
                        onChange={(value: string) => setValue("rePassword", value)}
                    />
                    {errorTag && (values.password || values.rePassword) && (
                        <FormHelperText>
                            <LocalizeText tag={errorTag as TLanguageTag} />
                        </FormHelperText>
                    )}
                </FormControl>
            </DialogContent>
            <DialogActions>
                <VKButton
                    tag="continue"
                    disabled={!!errorTag}
                    isLoading={isUpdatingPassword}
                    onClick={() => handleChangePassword()}
                    template="primary"
                    style={{ marginTop: "10px", fontSize: "1rem" }}
                />
            </DialogActions>
        </>
    );
};

export default ChangePassWordFormDialog;
