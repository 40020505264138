import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField, { OutlinedTextFieldProps } from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import MenuItem from "@mui/material/MenuItem";
import If from "components/__helpers__/if";

interface IListTextField extends Partial<OutlinedTextFieldProps> {
    testid?: string;
    notRequired?: boolean;
    data?: string | number | React.ReactNode;
    nonEditData?: string | number | React.ReactNode;
    notValid?: boolean;
    errorText?: React.ReactNode | string;
    isLoading?: boolean;
    helpText?: string | React.ReactNode;
    hasData?: boolean;
    isEditing?: boolean;
    editingLabel?: React.ReactNode;
    editingData?: React.ReactNode
}
export const ListTextField: React.FC<IListTextField> = ({
    testid,
    notRequired,
    data,
    nonEditData,
    notValid,
    errorText,
    onChange,
    isLoading,
    helpText,
    hasData,
    isEditing,
    label,
    editingLabel,
    editingData,
    ...props
}) => {
    let showData = hasData ? data || "-" : undefined;
    if (nonEditData && hasData) {
        showData = hasData ? nonEditData || "-" : undefined;
    }


    return (
        <ListItem>
            {!isLoading && isEditing ? (
                <>
                    <TextField
                        {...props}
                        inputProps={{ "data-testid": testid }}
                        required={!notRequired}
                        error={notValid !== undefined && notValid}
                        fullWidth
                        variant="outlined"
                        label={editingLabel || label}
                        value={editingData || data}
                        onChange={onChange}
                        helperText={notValid && errorText}
                    />
                </>
            ) : (
                <ListItemText
                    data-testid={testid}
                    primary={label}
                    secondary={
                        <>
                            <If truthy={helpText}>
                                <Typography component="span" variant="body2">
                                    {helpText}
                                </Typography>
                            </If>
                            {!isLoading ? <>{showData}</> : <Skeleton variant="rectangular" width={50} />}
                        </>
                    }
                />
            )}
        </ListItem>
    );
};

export type TItem = {
    name: string | number | React.ReactNode;
    value: string | number;
    disabled?: boolean;
};

const MENU_ITEM_HEIGHT = 39;

interface ISelectBox extends Partial<OutlinedTextFieldProps> {
    testid?: string;
    label: React.ReactNode | string;
    data: string;
    notValid?: boolean;
    errorText?: React.ReactNode | string;
    onEdit: (value: string) => void;
    isLoading?: boolean;
    items?: TItem[];
    emptyText?: React.ReactNode | string;
    menuItemAmount?: number;
    onScroll?: (event: React.BaseSyntheticEvent) => void;
}
const SelectBoxTemp: React.FC<ISelectBox> = ({
    label,
    data,
    onEdit,
    items,
    isLoading,
    notValid,
    errorText,
    testid,
    emptyText,
    menuItemAmount,
    onScroll,
    ...props
}) => {

    const onSelect = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        if (!isLoading) {
            onEdit(event.target.value);
        }
    };

    const renderHelpText = (): React.ReactNode => {
        if (notValid) {
            return errorText;
        }
        if (!isLoading && (!items || !items.length) && emptyText) {
            return emptyText;
        }
        return "";
    };

    return (
        <ListItem>
            <TextField
                {...props}
                select
                label={label}
                value={data}
                onChange={onSelect}
                helperText={renderHelpText()}
                inputProps={{ "data-testid": testid }}
                sx={{width: "100%"}}
                SelectProps={{
                    MenuProps: {
                        PaperProps: {
                            onScroll: onScroll,
                            sx: {
                                maxHeight: MENU_ITEM_HEIGHT * (menuItemAmount || 5),
                            }
                        },
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                        },
                    }
                }}
            >
                {!isLoading ? ( 
                    items && items.map((item, index) => (
                        <MenuItem key={index} value={item.value} disabled={item.disabled || props.disabled}>
                            {item.name}
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem>
                        <Skeleton variant="rectangular" width={50} />
                    </MenuItem>
                )}
            </TextField>
        </ListItem>
    );
};

export { SelectBoxTemp as SelectBox };
