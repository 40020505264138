import React from "react";
import Dashboard, { DashboardRootWrapper, TSmallBanner } from "views/Dashboard";
import NewsSidebar from "components/newsComponents/NewsSidebar";
import { permission } from "utils/permissions";

const TenantDashboardView: React.FC = () => {
    const banners: TSmallBanner[] = [
        {
            title: "tasks",
            breadcrumbs: "bannerTasks",
            image: `${process.env.PUBLIC_URL}/assets/images/1024x683/tasks-1024x683.jpg`,
            route: "tasks",
            requiredPermissions: permission.TASKS,
        },
        {
            title: "services",
            breadcrumbs: "bannerServices",
            image: `${process.env.PUBLIC_URL}/assets/images/1024x683/tasks-1024x683.jpg`,
            route: "services",
            requiredPermissions: permission.SERVICES,
        },
        {
            title: "lease",
            breadcrumbs: "bannerLeases",
            image: `${process.env.PUBLIC_URL}/assets/images/1024x683/leases-1024x683.jpg`,
            route: "leases",
            requiredPermissions: permission.LEASES,
        },
        {
            title: "realestateInformation",
            breadcrumbs: "bannerRealestates",
            image: `${process.env.PUBLIC_URL}/assets/images/1024x683/memberships-1024x683.jpg`,
            route: "realestates",
            requiredPermissions: permission.BUILDING_INFO,
        },
    ];

    return (
        <DashboardRootWrapper container>
            <Dashboard banners={banners} />
            <NewsSidebar />
        </DashboardRootWrapper>
    );
};

export default TenantDashboardView;
