import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import List from "@mui/material/List";
import { LocalizeText } from "components/localizer";
import { Box, CircularProgress, Grid, ListItem, ListItemText } from "@mui/material";
import { IMember, IMemberSubscriptionAssociation } from "reducers/members";
import { formatPossibleNullDate, getMemberStatusText } from "utils/helpers";
import { dateFormats } from "utils/formats";
import { MembersContext } from "contexts/members";
import { ISubscription } from "reducers/subscriptions";
import SubscriptionsContext from "contexts/subscriptions";
import { useNavigate } from "react-router-dom";
import { ArrowRightAltRounded } from "@mui/icons-material";
import { ModalContext } from "contexts/modals";
import VKButton from "components/vkButton";
import colors from "styles/colors";
import { IMembership } from "reducers/memberships";
import { MembershipsContext } from "contexts/memberships";
import { isAfter, parseISO } from "date-fns";
import { usePaginationStore } from "utils/usePaginationStore";

interface IProps {
    member: IMember;
    subscriptionUuid?: string;
}

export const MemberInfo: FC<IProps> = ({ member, subscriptionUuid }) => {
    const { isResendingInvitation, resendInvitation } = useContext(MembersContext);
    const pagination = usePaginationStore("member-subscription-associations", undefined, {
        member_uuid: member.uuid,
        page_size: 1000,
        default_page_size: 1000,
    });
    const currentAssociation = useMemo(() => {
        return pagination.results.find((association) => association.subscription === subscriptionUuid);
    }, [subscriptionUuid, pagination]);

    const associations = useMemo(() => {
        const temp = pagination.results;
        const sortedList = [];

        if (temp.length > 0) {
            const indexOfSelected = temp.findIndex((item) => item.subscription === subscriptionUuid);

            if (indexOfSelected >= 0) {
                sortedList.push(temp[indexOfSelected]);
            }

            temp.map((association) => {
                if (association.subscription !== subscriptionUuid) {
                    sortedList.push(association);
                }
            });
        }

        return sortedList;
    }, [pagination, subscriptionUuid]);

    return (
        <Box
            sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <List>
                <ListItem divider>
                    <ListItemText primary={<LocalizeText tag="email" />} secondary={member.email} />
                </ListItem>
                <ListItem divider>
                    <ListItemText
                        primary={<LocalizeText tag="name" />}
                        secondary={member.first_name ? `${member.first_name} ${member.last_name}` : "-"}
                    />
                </ListItem>
                <ListItem divider>
                    <ListItemText primary={<LocalizeText tag="status" />} secondary={getMemberStatusText(member)} />
                    {member.status === "INVITED" && pagination.results.length > 0 ? (
                        <VKButton
                            tag="resendInvitation"
                            variant="outlined"
                            size="small"
                            isLoading={isResendingInvitation}
                            onClick={() => resendInvitation(currentAssociation?.uuid ?? pagination.results[0].uuid)}
                        />
                    ) : null}
                </ListItem>
                <ListItem>
                    <Grid container flexDirection="column" gap="5px" width="100%">
                        <ListItemText primary={<LocalizeText tag="subscriptions" />} sx={{ width: "100%" }} />
                        {pagination.query.isFetching ? <CircularProgress size="30px" /> : null}
                        {associations.map((association) => (
                            <Association
                                key={association.uuid}
                                association={association}
                                subscriptionUuid={subscriptionUuid}
                            />
                        ))}
                    </Grid>
                </ListItem>
            </List>
        </Box>
    );
};

interface AssociationProps {
    association: IMemberSubscriptionAssociation;
    subscriptionUuid?: string;
}

const Association: FC<AssociationProps> = ({ association, subscriptionUuid }) => {
    const { fetchSubscription } = useContext(SubscriptionsContext);
    const { fetchMembership } = useContext(MembershipsContext);
    const { closeDrawers } = useContext(ModalContext);
    const navigate = useNavigate();
    const [subscription, setSubscription] = useState<ISubscription | null>(null);
    const [membership, setMembership] = useState<IMembership | null>(null);

    const selected = subscriptionUuid === association.subscription;
    const todayCloseToMidnight = new Date();
    todayCloseToMidnight.setHours(23);
    todayCloseToMidnight.setMinutes(59);
    const inactive = isAfter(todayCloseToMidnight, parseISO(association.deactivates ?? ""));

    useEffect(() => {
        (async (): Promise<void> => {
            const s = await fetchSubscription(association.subscription);
            setSubscription(s);
            const m = await fetchMembership(parseInt(s.membership_id));
            setMembership(m);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <VKButton
            variant="outlined"
            sx={{
                color: inactive ? colors.greyText : colors.black,
                fontSize: "1rem",
                borderWidth: selected ? "4px !important" : "",
                borderColor: !selected ? colors.grey : "",
            }}
            onClick={() => {
                closeDrawers();
                if (!selected) {
                    navigate(`/subscriptions/${subscription?.membership_id}/${subscription?.uuid}/details`);
                }
            }}
            isLoading={!subscription}
        >
            <Grid container direction="column">
                <Grid container justifyContent="space-between">
                    <Grid item textAlign="start">
                        {subscription?.identifier}
                    </Grid>
                    <Grid item>
                        <LocalizeText tag="customerNumber" />: {membership?.agreement_nr}
                    </Grid>
                </Grid>
                <Grid container justifyContent="space-between">
                    <Grid item display="flex">
                        {formatPossibleNullDate(association.activates, dateFormats.WEBDATE)}
                        <ArrowRightAltRounded sx={{ color: colors.greyText }} />
                        {formatPossibleNullDate(association.deactivates, dateFormats.WEBDATE) || (
                            <LocalizeText tag="untilFurtherNotice" />
                        )}
                    </Grid>
                    <Grid item>{subscription?.community_title}</Grid>
                </Grid>
            </Grid>
        </VKButton>
    );
};
