import React from "react";
import { ModalContext } from "contexts/modals";
import { List, ListItem, ListItemText, Tooltip } from "@mui/material";
import { LocalizeText } from "components/localizer";
import CommunityContext from "contexts/community";
import VKButton from "components/vkButton";
import { IRepresentativeInvitation } from "reducers/community";
import { renewRepresentitiveInvitationAdapter, deleteRepresentitiveInvitationAdapter } from "adapters/communityAdapter";
import { Check, Close } from "@mui/icons-material";
import { dateFormats } from "utils/formats";
import { format, parseISO } from "date-fns";
import { useIsLoading } from "utils/hooks/useLoading";

const UpdateContactPersonInvitation: React.FC<{ user: IRepresentativeInvitation }> = ({ user }) => {
    const { closeDrawers } = React.useContext(ModalContext);
    const { representativeInvitationPagination } = React.useContext(CommunityContext);
    const [isLoading, setIsLoading] = useIsLoading(false);
    const isActive = Date.parse(user.activates) < Date.now();

    const handleSubmitResend = async (event: React.SyntheticEvent): Promise<void> => {
        setIsLoading(true);
        event.preventDefault();
        const success = await renewRepresentitiveInvitationAdapter(user.id);
        if (success) {
            closeDrawers();
            representativeInvitationPagination.reloadPage();
        }
        setIsLoading(false);
    };

    const handleSubmitRemove = async (event: React.SyntheticEvent): Promise<void> => {
        setIsLoading(true);
        event.preventDefault();
        const success = await deleteRepresentitiveInvitationAdapter(user.id);
        if (success) {
            closeDrawers();
            representativeInvitationPagination.reloadPage();
        }
        setIsLoading(false);
    };

    return (
        <div data-testid="addContactPerson-root">
            <List>
                <ListItem divider>
                    <ListItemText primary={<LocalizeText tag="name" />} secondary={user.full_name} />
                </ListItem>
                <ListItem divider>
                    <ListItemText primary={<LocalizeText tag="email" />} secondary={user.email} />
                </ListItem>
                <ListItem divider>
                    <ListItemText
                        primary={<LocalizeText tag="created" />}
                        secondary={format(parseISO(user.created ?? ""), dateFormats.APIDATE)}
                    />
                </ListItem>
                <ListItem divider>
                    <ListItemText
                        primary={<LocalizeText tag="activates" />}
                        secondary={user.activates ? user.activates : "-"}
                    />
                </ListItem>
                <ListItem divider>
                    <ListItemText
                        primary={<LocalizeText tag="pendingEmail" />}
                        secondary={user.mail_sent ? <Check /> : <Close />}
                    />
                </ListItem>
                <ListItem>
                    <Tooltip title={!isActive ? <LocalizeText tag="sendInvitationInactive" /> : ""}>
                        <span>
                            <VKButton
                                onClick={handleSubmitResend}
                                disabled={!isActive}
                                isLoading={isLoading}
                                id="button-resend"
                                tag={user.mail_sent ? "resendInvitation" : "sendInvitation"}
                                template="save"
                            />
                        </span>
                    </Tooltip>
                </ListItem>
                <ListItem>
                    <VKButton
                        onClick={handleSubmitRemove}
                        isLoading={isLoading}
                        id="button-remove"
                        tag="removeInvitation"
                        template="cancel"
                    />
                </ListItem>
            </List>
        </div>
    );
};

export default UpdateContactPersonInvitation;
