import React from "react";
import { LocalizeText } from "components/localizer";
import { THeadCell } from "utils/types";
import TopBar from "components/tableComponents/topBar";
import Banner from "components/banner";
import CommunityContext from "contexts/community";
import TableComponent, { IRow } from "components/tableComponent";
import { Box, Tab, Tabs } from "@mui/material";
import { ModalContext } from "contexts/modals";
import AddCommunityStaff from "components/drawers/communityStaff/addCommunityStaff";
import Dropdown from "components/dropdown";
import UpdateCommunityStaff from "components/drawers/communityStaff/updateCommunityStaff";
import SearchField from "components/tableComponents/paginationSearchField";
import VKButton from "../../components/vkButton";

const communityStaffInvitationsHeadCells: THeadCell[] = [
    { id: "name", sortId: "first_name", label: <LocalizeText tag="name" /> },
    { id: "email", sortId: "email", label: <LocalizeText tag="email" /> },
    { id: "main_community", label: <LocalizeText tag="mainArena" /> },
    { id: "community_staff_role", label: <LocalizeText tag="role" /> },
];

const communityStaffHeadCells: THeadCell[] = [
    ...communityStaffInvitationsHeadCells,
    { id: "", label: "", alignCell: "right" },
];




type StaffManagementTab = "USERS" | "INVITATIONS";

const CommunityStaffManagementView: React.FC = () => {
    const {
        communityStaffPagination,
        isFetchingCommunityStaff,
        communityStaffInvitationPagination,
        isFetchingCommunityStaffInvitations,
    } = React.useContext(CommunityContext);
    const { openDrawer } = React.useContext(ModalContext);
    const [selectedTab, setTab] = React.useState<StaffManagementTab>("USERS");

    React.useEffect(() => {
        communityStaffPagination.getInitial();
        communityStaffInvitationPagination.getInitial();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const mapCommunityStaffRows = (): IRow[] =>
        communityStaffPagination.results.map((person) => {
            return {
                data: [
                    `${person.first_name} ${person.last_name}`,
                    person.email,
                    person.main_community?.title ?? "",
                    person.community_staff_role,
                    <Dropdown
                        inRow
                        key={person.uuid}
                        items={[
                            {
                                onClick: () =>
                                    openDrawer(
                                        UpdateCommunityStaff,
                                        {
                                            user: person,
                                            onConfirmRemove: () => {
                                                communityStaffPagination.reloadPage();
                                            },
                                        },
                                        "editCommunityStaffUser"
                                    ),
                                label: <LocalizeText tag="edit" />,
                            },
                        ]}
                    />,
                ],
            };
        });

    const mapCommunityStaffInvitationsRows = (): IRow[] =>
        communityStaffInvitationPagination.results.map((invite) => {
            return {
                data: [
                    `${invite.first_name} ${invite.last_name}`,
                    invite.email,
                    invite.main_community?.title ?? "",
                    invite.community_staff_role
                ],
            };
        });

    return (
        <div data-testid="communityStaff-root" className="contentWrapper">
            <Banner
                title="communityStaff"
                subTitle="bannerCommunityStaff"
                image={`${process.env.PUBLIC_URL}/assets/images/1024x683/memberships-1024x683.jpg`}
            />
            <TopBar
                searchField={
                    <SearchField
                        pagination={
                            selectedTab === "USERS" ? communityStaffPagination : communityStaffInvitationPagination
                        }
                        tag={selectedTab === "USERS" ? "searchCommunityStaff" : "searchInvites"}
                    />
                }

                singleItem={
                    <VKButton
                        tag="addCommunityStaff"
                        variant="outlined"
                        size="small"
                        onClick={() => openDrawer(AddCommunityStaff, {}, "addCommunityStaff")}
                    />
                }
            />
            <Box>
                <Tabs value={selectedTab} onChange={(event, value) => setTab(value)}>
                    <Tab label={<LocalizeText tag="communityStaff" />} value="USERS" />
                    <Tab label={<LocalizeText tag="invitations" />} value="INVITATIONS" />
                </Tabs>
                {selectedTab === "USERS" && (
                    <TableComponent
                        headCells={communityStaffHeadCells}
                        isLoading={isFetchingCommunityStaff}
                        rows={mapCommunityStaffRows()}
                        pagination={communityStaffPagination}
                    />
                )}
                {selectedTab === "INVITATIONS" && (
                    <TableComponent
                        headCells={communityStaffInvitationsHeadCells}
                        isLoading={isFetchingCommunityStaffInvitations}
                        rows={mapCommunityStaffInvitationsRows()}
                        pagination={communityStaffInvitationPagination}
                    />
                )}
            </Box>
        </div>
    );
};

export default CommunityStaffManagementView;
