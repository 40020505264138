import React, { FC, ReactNode, Reducer, createContext, useCallback, useContext, useMemo, useReducer } from "react";
import webAPIRequest from "api";
import { format } from "date-fns";
import reducer, { ISubscriptions, IAction, ISubscription, initialState } from "reducers/subscriptions";
import { NotificationContext } from "contexts/notification";
import { dateFormats } from "utils/formats";
import { TMembershipId } from "reducers/memberships";

export const SubscriptionsContext = createContext<ISubscriptions>({
    ...initialState,
});

export const SubscriptionsProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const { ...notification } = useContext(NotificationContext);
    const [currentState, dispatch] = useReducer<Reducer<ISubscriptions, IAction>>(reducer, initialState);

    const fetchSubscription = useCallback(
        async (uuid: string): Promise<ISubscription> => {
            try {
                dispatch({ type: "FETCH_SUBSCRIPTION" });
                const returnData = await webAPIRequest("get", `/subscriptions/${uuid}/`);
                dispatch({
                    type: "FETCH_SUBSCRIPTION_SUCCESS",
                });
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_fetchSubscription", error);
                dispatch({ type: "FETCH_SUBSCRIPTION_FAILURE" });
                return {} as ISubscription;
            }
        },
        [notification]
    );

    const updateSubscription = useCallback(
        async (uuid: string, data: Record<string, unknown>): Promise<ISubscription> => {
            try {
                dispatch({ type: "UPDATE_SUBSCRIPTION" });
                const returnData = await webAPIRequest("patch", `/subscriptions/${uuid}/`, { data });
                dispatch({
                    type: "UPDATE_SUBSCRIPTION_SUCCESS",
                });
                notification.enqueNotification("success_updateSubscription");
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_updateSubscription", error);
                dispatch({ type: "UPDATE_SUBSCRIPTION_FAILURE" });
                return {} as ISubscription;
            }
        },
        [notification]
    );

    const cancelSubscription = useCallback(
        async (subscription_uuid: string, expires: Date | null): Promise<boolean> => {
            try {
                dispatch({ type: "CANCEL_SUBSCRIPTION" });
                const data = {
                    end_date: expires ? format(expires, dateFormats.WEBDATE) : null,
                };
                await webAPIRequest("patch", `/subscriptions/${subscription_uuid}/cancel/`, { data });
                dispatch({ type: "CANCEL_SUBSCRIPTION_SUCCESS" });
                notification.enqueNotification("success_cancelSubscription");
                return true;
            } catch (error) {
                notification.enqueNotification("error_cancelSubscription", error);
                dispatch({ type: "CANCEL_SUBSCRIPTION_FAILURE" });
                return false;
            }
        },
        [notification]
    );

    const removeNationalAccess = useCallback(
        async (subscriptionId: number, expires: Date): Promise<boolean> => {
            try {
                dispatch({ type: "REMOVE_NATIONAL_ACCESS" });
                const data = {
                    subscription_id: subscriptionId,
                    end_date: format(expires, dateFormats.WEBDATE),
                };
                await webAPIRequest("post", "/subscriptions/national-access/cancel/", { data });
                dispatch({ type: "REMOVE_NATIONAL_ACCESS_SUCCESS" });
                notification.enqueNotification("success_removeNationalAccess");
                return true;
            } catch (error) {
                notification.enqueNotification("error_removeNationalAccess", error);
                dispatch({ type: "REMOVE_NATIONAL_ACCESS_FAILURE" });
                return false;
            }
        },
        [notification]
    );

    const setNationalAccess = useCallback(
        async (membershipId: TMembershipId, subscriptionId: number, startDate: Date): Promise<boolean> => {
            try {
                dispatch({ type: "SET_NATIONAL_ACCESS" });
                // We are only interested in the first stockrecord
                const nationalAccess =
                    currentState.nationalAccess && currentState.nationalAccess.length && currentState.nationalAccess[0];
                const stockrecord = nationalAccess && nationalAccess.stockrecords && nationalAccess.stockrecords;
                const stockrecordId = stockrecord && stockrecord.length ? stockrecord[0].id : -1;
                const data = {
                    membership_id: membershipId,
                    subscription_id: subscriptionId,
                    national_access_stock_record: stockrecordId,
                    start_date: format(startDate, dateFormats.APIDATE),
                };
                await webAPIRequest("post", "/subscriptions/national-access/", { data });
                dispatch({ type: "SET_NATIONAL_ACCESS_SUCCESS" });
                notification.enqueNotification("success_setNationalAccess");
                return true;
            } catch (error) {
                notification.enqueNotification("error_setNationalAccess", error);
                dispatch({ type: "SET_NATIONAL_ACCESS_FAILURE" });
                return false;
            }
        },
        [notification, currentState.nationalAccess]
    );

    const getNationalAccess = useCallback(
        async (productId: number): Promise<boolean> => {
            try {
                dispatch({ type: "GET_NATIONAL_ACCESS" });
                const params = {
                    product_id: productId,
                };
                const returndata = await webAPIRequest("get", "/subscriptions/national-access/", {
                    params,
                });
                dispatch({
                    type: "GET_NATIONAL_ACCESS_SUCCESS",
                    nationalAccess: returndata.data,
                });
                return true;
            } catch (error) {
                notification.enqueNotification("error_getNationalAccess", error);
                dispatch({ type: "GET_NATIONAL_ACCESS_FAILURE" });
                return false;
            }
        },
        [notification]
    );

    const getBariumRequestMoreMembershipURL = useCallback(
        async (membershipId: number): Promise<boolean> => {
            try {
                dispatch({ type: "SET_UP_BARIUM" });
                const params = {
                    membership_id: membershipId,
                };
                const returnData = await webAPIRequest("get", "/subscriptions/form-url/", { params });
                if (returnData?.data?.form_url) {
                    window.open(returnData.data.form_url);
                    dispatch({ type: "SET_UP_BARIUM_SUCCESS" });
                    return true;
                }
                throw new Error("No valid url to barium.");
            } catch (error) {
                notification.enqueNotification("error_openBariumForm", error);
                dispatch({ type: "SET_UP_BARIUM_FAILURE" });
                return false;
            }
        },
        [notification]
    );

    const value = useMemo(() => {
        return {
            ...currentState,
            fetchSubscription,
            updateSubscription,
            setNationalAccess,
            getNationalAccess,
            cancelSubscription,
            removeNationalAccess,
            getBariumRequestMoreMembershipURL,
        };
    }, [
        currentState,
        fetchSubscription,
        updateSubscription,
        setNationalAccess,
        getNationalAccess,
        cancelSubscription,
        removeNationalAccess,
        getBariumRequestMoreMembershipURL,
    ]);

    return <SubscriptionsContext.Provider value={value}>{children}</SubscriptionsContext.Provider>;
};

export default SubscriptionsContext;
