import React from "react";

interface IProps {
    className?: string;
}
export const SmsIcon: React.FC<IProps> = ({ className }) => {
    return (
        <svg className={className} width="190px" height="170px" viewBox="0 0 190 170" version="1.1">
            <path d="M0,0.000579545455 L0,130.203193 L50.2272727,130.203193 L50.2272727,169.227852 L95.625,130.203193 L189.318182,130.203193 L189.318182,0.000579545455 L0,0.000579545455 Z M5.98863636,5.98921591 L183.522727,5.98921591 L183.522727,124.216489 L183.329545,124.216489 L93.5,124.216489 L56.2159091,156.282739 L56.2159091,124.216489 L5.98863636,124.216489 L5.98863636,5.98921591 Z"></path>
            <polygon points="25.3068182 30.9380682 164.204545 30.9380682 164.204545 24.9494318 25.3068182 24.9494318"></polygon>
            <polygon points="25.3068182 56.2448864 164.204545 56.2448864 164.204545 50.25625 25.3068182 50.25625"></polygon>
        </svg>
    );
};

export const AppIcon: React.FC<IProps> = ({ className }) => {
    return (
        <svg className={className} width="110px" height="170px" viewBox="0 0 110 170" version="1.1">
            <polygon points="35.2927973 155.097711 75.5593523 155.097711 75.5593523 149.21273 35.2927973 149.21273"></polygon>
            <path d="M0.00170854271,0.000569514238 L0.00170854271,169.715812 L49.3577108,169.715812 L93.9715578,169.715812 L109.236438,169.715812 L109.236438,0.000569514238 L0.00170854271,0.000569514238 Z M5.886689,5.88554997 L103.541295,5.88554997 L103.541295,163.830832 L103.349559,163.830832 L91.8814405,163.830832 L55.2426912,163.830832 L5.886689,163.830832 L5.886689,5.88554997 Z"></path>
            <polygon points="15.3768844 21.2618649 94.0476829 21.2618649 94.0476829 15.3768844 15.3768844 15.3768844"></polygon>
            <polygon points="15.3768844 141.034506 94.0476829 141.034506 94.0476829 135.147627 15.3768844 135.147627"></polygon>
            <polygon points="15.3768844 141.032607 21.2618649 141.032607 21.2618649 15.3768844 15.3768844 15.3768844"></polygon>
            <polygon points="88.160804 141.032607 94.0476829 141.032607 94.0476829 15.3768844 88.160804 15.3768844"></polygon>
        </svg>
    );
};
