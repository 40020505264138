import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import TopBar from "components/tableComponents/topBar";
import Banner from "components/banner";
import SearchField from "components/tableComponents/paginationSearchField";
import ExternalMembershipTable from "components/tableComponents/externalMembershipTable";
import VKButton from "components/vkButton";
import { ModalContext } from "contexts/modals";
import CreateExternalMembership from "components/drawers/createExternalMembership";
import { MultiSelectDropdown } from "components/selectDropdown";
import CommunityContext from "contexts/community";
import { usePaginationStore } from "utils/usePaginationStore";

const ExternalMembershipsView: FC = () => {
    const pagination = usePaginationStore("external-memberships", undefined, {
        agreement_type: "external_member",
        page_size: 20,
        default_page_size: 20,
        is_archived: false,
    });

    const { openDrawer } = useContext(ModalContext);
    const { communitiesPagination } = useContext(CommunityContext);
    const [selectedCommunities, setSelectedCommunities] = useState<string[]>([]);
    const [timeoutHandle, setTimeoutHandle] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        communitiesPagination.getInitial();
        // eslint-disable-next-line
    }, []);

    const onCommunitiesChange = useCallback(
        (ids: string[]) => {
            setSelectedCommunities(ids);

            if (timeoutHandle) {
                clearTimeout(timeoutHandle);
            }
            setTimeoutHandle(
                setTimeout(() => {
                    pagination.setParam("communities", ids.join(","));
                    pagination.getInitial();
                }, 500)
            );
        },
        [timeoutHandle, pagination]
    );

    return (
        <div className="contentWrapper">
            <Banner
                title="externalMemberships"
                subTitle="bannerExternalMemberships"
                image={`${process.env.PUBLIC_URL}/assets/images/1024x683/memberships-1024x683.jpg`}
            />
            <TopBar
                searchField={
                    <SearchField pagination={pagination} tag="searchExternalMemberships" validator="membership" />
                }
                buttons={
                    <>
                        <div style={{ marginLeft: "20px" }} />
                        <MultiSelectDropdown
                            noSelectionTag="community"
                            items={communitiesPagination.results.map((c) => ({ id: c.uuid, primary: c.title }))}
                            selectedIds={selectedCommunities}
                            onToggle={onCommunitiesChange}
                        />
                    </>
                }
                singleItem={
                    <VKButton
                        tag="createNew"
                        variant="outlined"
                        size="small"
                        onClick={() =>
                            openDrawer(
                                CreateExternalMembership,
                                { community_type: "arena" },
                                "createNewExternalMembership"
                            )
                        }
                    />
                }
            />
            <ExternalMembershipTable pagination={pagination} agreement_type="external_member" />
        </div>
    );
};

export default ExternalMembershipsView;
