import React from "react";
import { Divider, Grid, styled, Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import { dateFormats } from "utils/formats";
import { LocalizeText } from "components/localizer";
import HorizontalCard from "components/horizontalCard";
import VKButton from "components/vkButton";
import { IService } from "reducers/realestates";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const CardGridItem = styled(Grid)(({ theme }) => ({
    position: "relative",
    padding: "1em 0",
    "&:nth-child(2n)": {
        [theme.breakpoints.up("lg")]: {
            paddingLeft: "1em",
        },
    },
}));

interface IServiceArticleCard {
    service: IService;
}

const ServiceArticleCard: React.FC<IServiceArticleCard> = ({ service }) => {
    const hasFile = service.file !== null;
    const buttonText = hasFile ? "openFile" : "readMoreHere";

    const onClick = (service: IService): void => {
        const target = hasFile ? service.file : service.external_link;
        window.open(target, "_blank");
    };

    return (
        <CardGridItem item xs={12} key={service.uuid}>
            <HorizontalCard
                elevation={0}
                rounded={true}
                imageBreakPoints={{
                    xs: 4,
                    lg: 3,
                    xl: 2,
                }}
                contentBreakPoints={{
                    xs: 8,
                    lg: 9,
                    xl: 10,
                }}
                image={
                    <img
                        src={service.photo}
                        alt={service.title}
                        style={{
                            width: "100%",
                            height: "100%",
                            aspectRatio: "3 / 2",
                        }}
                    />
                }
                onCardClick={() => onClick(service)}
                actions={
                    <VKButton size="small" color="primary" onClick={() => onClick(service)}>
                        <Grid container justifyContent="center" alignItems="center">
                            <Typography variant="body2">
                                <LocalizeText tag={buttonText} /> <OpenInNewIcon fontSize="inherit" />
                            </Typography>
                        </Grid>
                    </VKButton>
                }
            >
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="body1">{service.title}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" sx={{ textAlign: "end" }}>
                            {service.updated && format(parseISO(service.updated ?? ""), dateFormats.WEBDATE)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={10}>
                        <Typography gutterBottom variant="body2">
                            {service.body}
                        </Typography>
                    </Grid>
                </Grid>
            </HorizontalCard>
        </CardGridItem>
    );
};

export default ServiceArticleCard;
