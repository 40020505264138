import webAPIRequest from "api";
import { AxiosResponse } from "axios";

export interface IDiscount {
    id: number;
    uuid: string;
    created: string;
    amount_excl_vat: string;
    discount_from: string;
    discount_until: string | null;
    description: string;
    category: TDiscountCategory;
    internal_note: string;
}

export type TDiscountCategory = "monthly" | "one-time";

export type TDiscountType = "subscriptions" | "offices";

export const fetchDiscounts = async (type: TDiscountType, type_id: number): Promise<AxiosResponse<IDiscount[]>> => {
    return webAPIRequest("get", `/${type}/${type_id}/discounts/`);
};

export const createDiscount = async (
    type: TDiscountType,
    type_id: number,
    data: Partial<IDiscount>
): Promise<AxiosResponse<IDiscount>> => {
    return webAPIRequest("post", `/${type}/${type_id}/discounts/`, { data });
};

export const updateDiscount = async (
    type: TDiscountType,
    type_id: number,
    discount_id: number,
    data: Partial<IDiscount>
): Promise<AxiosResponse<IDiscount>> => {
    return webAPIRequest("patch", `/${type}/${type_id}/discounts/${discount_id}/`, { data });
};

export const deleteDiscount = async (
    type: TDiscountType,
    type_id: number,
    discount_id: number
): Promise<AxiosResponse<IDiscount>> => {
    return webAPIRequest("delete", `/${type}/${type_id}/discounts/${discount_id}/`);
};
