import React from "react";
import { LocalizeText } from "components/localizer";
import { Grid, styled, Typography } from "@mui/material";
import colors from "styles/colors";
import { TLanguageTag } from "contexts/language";

const PasswordUnorderedList = styled("ul")(() => ({
    fontSize: "16px",
    color: colors.greyText,
    fontWeight: "lighter",

    "& li": {
        listStyleType: "circle",
    },
}));

interface IProp {
    criteriaTags: TLanguageTag[];
}

const PasswordCriteria: React.FC<IProp> = ({ criteriaTags }) => {
    return (
        <Grid marginTop={2}>
            <Typography variant="body2">
                <LocalizeText tag="choosePasswordDescription" />
            </Typography>
            <PasswordUnorderedList>
                {criteriaTags.map((criteriaTag) => (
                    <li key={criteriaTag}>
                        <LocalizeText tag={criteriaTag} />
                    </li>
                ))}
            </PasswordUnorderedList>
        </Grid>
    );
};

export default PasswordCriteria;
