import { InvitationStatus } from "utils/formats";
import { IBuilding, IBuildingsText } from "./realestates";
import { ISubscription } from "./subscriptions";

export interface ICoworker {
    uuid: string;
    first_name: string;
    last_name: string;
    email: string;
    phone?: string;
    groups?: string[];
}
export interface IArenaInvitation {
    uuid: string;
    email: string;
    user: string;
    office_lease: string;
    status: InvitationStatus;
}

export interface IArenaBuilding extends IBuilding {
    building_texts: IBuildingsText[];
}

export interface IArena extends ISubscription {
    office_name: string;
    agreement_document_file: string;
    total_office_spaces: number;
}

export interface IArenasQueryParams {
    membership_id?: string;
    search?: string;
    ordering?: string;
}

export interface IArenas {
    isCancelingArena: boolean;
    isSettingUpMoreBarium: boolean;
    isFetchingSingleArenaBuilding: boolean;
    arenaBuilding?: IArenaBuilding;
    isUpdatingArena: boolean;
    cancelArena: (subscriptionId: string, endDate: Date) => Promise<boolean>;
    getBariumRequestMoreArenasURL: (membershipId: number) => Promise<boolean>;
    getArenaBuilding: (arenaBuildingUuid: string) => Promise<IArenaBuilding>;
    updateArena: (uuid: string, data: Record<string, unknown>) => Promise<IArena>;
}

export const initialState: IArenas = {
    isCancelingArena: false,
    isSettingUpMoreBarium: false,
    isFetchingSingleArenaBuilding: false,
    isUpdatingArena: false,
    cancelArena: async () => false,
    getBariumRequestMoreArenasURL: async () => false,
    getArenaBuilding: async () => ({} as IArenaBuilding),
    updateArena: async () => ({} as IArena),
};

export type IAction =
    | { type: "SET_CURRENT_PAGE"; newPage: number }
    | { type: "CANCEL_ARENA" }
    | { type: "CANCEL_ARENA_SUCCESS" }
    | { type: "CANCEL_ARENA_FAILURE" }
    | { type: "SET_UP_BARIUM" }
    | { type: "SET_UP_BARIUM_SUCCESS" }
    | { type: "SET_UP_BARIUM_FAILURE" }
    | { type: "FETCH_ARENA_BUILDING" }
    | {
          type: "FETCH_ARENA_BUILDING_SUCCESS";
          data: IArenaBuilding;
      }
    | { type: "FETCH_AREMA_BUILDING_FAILURE" }
    | { type: "UPDATE_ARENA" }
    | { type: "UPDATE_ARENA_SUCCESS" }
    | { type: "UPDATE_ARENA_FAILURE" };

function reducer(state: IArenas, action: IAction): IArenas {
    switch (action.type) {
        case "CANCEL_ARENA":
            return { ...state, isCancelingArena: true };
        case "CANCEL_ARENA_SUCCESS":
        case "CANCEL_ARENA_FAILURE":
            return { ...state, isCancelingArena: false };

        case "SET_UP_BARIUM":
            return { ...state, isSettingUpMoreBarium: true };
        case "SET_UP_BARIUM_SUCCESS":
        case "SET_UP_BARIUM_FAILURE":
            return { ...state, isSettingUpMoreBarium: false };

        case "FETCH_ARENA_BUILDING":
            return { ...state, isFetchingSingleArenaBuilding: true };
        case "FETCH_ARENA_BUILDING_SUCCESS":
            return { ...state, arenaBuilding: action.data, isFetchingSingleArenaBuilding: false };
        case "FETCH_AREMA_BUILDING_FAILURE":
            return { ...state, isFetchingSingleArenaBuilding: false };
        case "UPDATE_ARENA":
            return { ...state, isUpdatingArena: true };
        case "UPDATE_ARENA_SUCCESS":
        case "UPDATE_ARENA_FAILURE":
            return { ...state, isUpdatingArena: false };

        default:
            return state;
    }
}

export default reducer;
