import React from "react";
import { LocalizeText } from "components/localizer";
import Grid from "@mui/material/Grid";
import { ITaskAction } from "reducers/realestates";
import { ListItem, ListItemAvatar, Avatar, ListItemText, Typography, Tooltip } from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import DoneIcon from "@mui/icons-material/Done";
import colors from "styles/colors";
import { parseISO, format } from "date-fns";
import { dateFormats } from "utils/formats";

interface IProps {
    action: ITaskAction;
}
const TaskComment: React.FC<IProps> = ({ action }) => {
    return (
        <ListItem alignItems="flex-start">
            <ListItemAvatar>
                <Avatar sx={{ color: colors.vkBlue }}>
                    <Tooltip title={action.type_name || ""}>
                        {action.type_name === "Kvitterad" ? <DoneIcon /> : <MessageIcon />}
                    </Tooltip>
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={
                    <Grid container justifyContent="space-between">
                        <Typography variant="subtitle1">{action.signature_name}</Typography>
                        <Typography variant="subtitle1">
                            <LocalizeText
                                tag="articleFooterCreated"
                                replace={{
                                    timestamp: `${format(parseISO(action.registration_time ?? ""), dateFormats.WEBDATETIME)}`,
                                }}
                            />
                        </Typography>
                    </Grid>
                }
                secondary={
                    <Typography style={{ whiteSpace: "pre-line" }} variant="subtitle2">
                        {action.message}
                    </Typography>
                }
            />
        </ListItem>
    );
};

export default TaskComment;
