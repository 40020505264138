import React, { FC, useContext, useEffect } from "react";
import { TCommunityType } from "utils/ecommerseTypes";
import CommunityContext from "../../../contexts/community";
import { IExternalMembership } from "reducers/externalMemberships";
import { usePermission } from "utils/hooks/usePermission";
import { ListItem, ListItemText, MenuItem, TextField } from "@mui/material";
import { LocalizeText } from "../../localizer";
import ListInlineEditWrapper from "../listComponents/listInlineEditWrapper";

interface IProps {
    community_type?: TCommunityType;
    membership: IExternalMembership;
    onSubmitUpdate: (props: Record<string, unknown>) => void;
}

const CommunitiesField: FC<IProps> = ({ community_type, membership, onSubmitUpdate }) => {
    const { communitiesPagination } = useContext(CommunityContext);
    const [newCommunity, setNewCommunity] = React.useState(membership.default_community?.uuid || "-");
    const { hasPermission } = usePermission();

    useEffect(() => {
        if (community_type === "conference") {
            communitiesPagination.setParam("community_type", "conference");
        } else {
            communitiesPagination.setParam("community_type", "");
        }
        communitiesPagination.getInitial();

        return () => {
            communitiesPagination.setParam("community_type", "");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setNewCommunity(membership.default_community?.uuid || "-");
    }, [membership]);

    return (
        <ListInlineEditWrapper
            onChange={(e) => {
                setNewCommunity(e.target.value);
            }}
            onSubmit={() => onSubmitUpdate({ default_community: newCommunity })}
            label={<LocalizeText tag="community" />}
            renderInput={(props) => (
                <>
                    {communitiesPagination.results.length > 0 && (
                        <ListItem>
                            <TextField
                                label={<LocalizeText tag="community" />}
                                select
                                fullWidth
                                required
                                onChange={(e) => setNewCommunity(e.target.value)}
                                value={newCommunity}
                            >
                                {communitiesPagination.results.map((community) => (
                                    <MenuItem key={community.uuid} value={community.uuid}>
                                        {community.title}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </ListItem>
                    )}
                </>
            )}
            inputIsValid={true}
            renderView={(props) => (
                <ListItem>
                    <ListItemText
                        primary={<LocalizeText tag="community" />}
                        secondary={membership?.default_community?.title ?? ""}
                    />
                </ListItem>
            )}
            editDisabled={membership.is_archived || !hasPermission("change_membership")}
        />
    );
};

export default CommunitiesField;
