import React from "react";
import Banner from "components/banner";
import { Tab, Tabs, styled, Box } from "@mui/material";
import { RealestatesContext } from "contexts/realestates";
import { useParams } from "react-router-dom";
import If from "components/__helpers__/if";
import { LocalizeText } from "components/localizer";
import { CenteredLoader } from "components/centeredLoader";
import BuildingDetails from "components/buildings/buildingDetails";
import BuildingResponsible from "components/buildings/buildingResponsible";
import BuildingTexts from "components/buildings/buildingTexts";

type VKBuildingTextType = "TenantInfoInTheRealEstate" | "TenantInfoRealEstateData";

export const BuildingTabs = styled(Tabs)(() => ({
    marginTop: "4em",
}));

const BuildingInformation: React.FC = () => {
    const { buildingInfo, fetchBuildingInfo, isFetchingBuilding, isFetchingBuildingInfo, fetchBuilding, building } =
        React.useContext(RealestatesContext);
    const params = useParams<{ buildingUuid: string }>();
    const [currentTab, setCurrentTab] = React.useState<VKBuildingTextType>("TenantInfoRealEstateData");

    React.useEffect(() => {
        fetchBuilding(params.buildingUuid ?? "");
        fetchBuildingInfo(params.buildingUuid ?? "");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div data-testid="buildings-info-root" className="contentWrapper" style={{ paddingBottom: 0 }}>
                <Banner title="realestateInformation" />
                <If truthy={isFetchingBuilding}>
                    <Box sx={{ height: "70vh" }}>
                        <CenteredLoader size={30} />
                    </Box>
                </If>
                {!isFetchingBuilding && building && (
                    <>
                        <BuildingDetails
                            building={building}
                            contactPersons={<BuildingResponsible building={building} isArenaResponsible={false} />}
                        />
                        <BuildingTabs
                            value={currentTab}
                            onChange={(_, newTab) => setCurrentTab(newTab)}
                            aria-label="change information tab"
                            sx={{ marginTop: "4em" }}
                        >
                            <Tab label={<LocalizeText tag="buildingFacts" />} value="TenantInfoRealEstateData" />
                            <Tab label={<LocalizeText tag="howToInProperty" />} value="TenantInfoInTheRealEstate" />
                        </BuildingTabs>
                    </>
                )}
            </div>
            {!isFetchingBuildingInfo && buildingInfo && <BuildingTexts texts={buildingInfo} parentType={currentTab} />}
        </>
    );
};

export default BuildingInformation;
