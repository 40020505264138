import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { LocalizeText } from "components/localizer";
import { IAlternativeInvoiceRecipient } from "reducers/memberships";
import { List, ListItem } from "@mui/material";
import { MembershipsContext } from "contexts/memberships";
import PaginationStore from "utils/paginationStore";
import { prettifyOrgNumber } from "utils/helpers";
import { DropdownItem, SelectDropdown } from "components/selectDropdown";
import VKButton from "components/vkButton";
import HasPermission from "components/__helpers__/hasPermission";
import ConfirmDialog from "components/confirmDialog";
import { ExistingRecipientInfo } from "./alternativeRecipient/existingRecipientInfo";
import { CreateNewRecipient } from "./alternativeRecipient/createNewRecipient";
import { CenteredLoader } from "components/centeredLoader";

interface IProps {
    onSelect: (recipient: IAlternativeInvoiceRecipient) => void;
}

const SetAlternativeRecipientDrawer: FC<IProps> = ({ onSelect }) => {
    const {
        fetchCountries,
        countries,
        isFetchingCountries,
        fetchAlternativeRecipients,
        isFetchingAlternativeRecipients,
    } = useContext(MembershipsContext);

    const [recipientsPagination] = useState(new PaginationStore<IAlternativeInvoiceRecipient>());
    const [selectedRecipient, setSelectedRecipient] = useState("");
    const [isCreatingNew, setIsCreatingNew] = useState(false);
    const [confirmingChoice, setConfirmingChoice] = useState(false);

    useEffect(() => {
        if (countries?.length === 0 && !isFetchingCountries) {
            fetchCountries();
        }

        recipientsPagination.initialize(fetchAlternativeRecipients);
        recipientsPagination.setParam("page_size", 100);
        recipientsPagination.getInitial();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const recipientItems: DropdownItem[] = useMemo(() => {
        return recipientsPagination.results.map((recipient) => ({
            id: recipient.uuid,
            primary: recipient.organization.name,
            secondary: prettifyOrgNumber(recipient.organization.org_nr),
        }));
    }, [recipientsPagination.results]);

    if (isFetchingAlternativeRecipients) {
        return <CenteredLoader size={40} />;
    }

    if (isCreatingNew) {
        return (
            <List>
                <CreateNewRecipient
                    onCreate={(recipient) => onSelect(recipient)}
                    onCancel={() => setIsCreatingNew(false)}
                />
            </List>
        );
    }

    const recipient = recipientsPagination.results.find((r) => r.uuid === selectedRecipient);

    return (
        <>
            <List>
                <ListItem>
                    <LocalizeText tag="alternativeRecipientWarning" />
                </ListItem>
                <ListItem>
                    <LocalizeText tag="selectExisting" />
                </ListItem>
                <ListItem>
                    <SelectDropdown
                        noSelectionTag="alternativeRecipient"
                        width="100%"
                        disabled={recipientItems.length === 0 || isFetchingAlternativeRecipients}
                        items={recipientItems}
                        selectedId={selectedRecipient}
                        onSelect={setSelectedRecipient}
                    />
                </ListItem>

                {recipient ? <ExistingRecipientInfo recipient={recipient} /> : null}
            </List>
            <div
                style={{
                    display: "flex",
                    marginTop: "auto",
                    paddingBottom: "20px",
                }}
            >
                <VKButton
                    tag="selectExisting"
                    template="primary"
                    disabled={!recipient}
                    onClick={() => setConfirmingChoice(true)}
                />
                <HasPermission requiredPerm="add_alternativeinvoicerecipient">
                    <VKButton
                        tag="createNew"
                        template="primary"
                        sx={{ marginLeft: "auto" }}
                        onClick={() => setIsCreatingNew(true)}
                    />
                </HasPermission>
            </div>

            <ConfirmDialog
                open={confirmingChoice}
                title={<LocalizeText tag="confirmAlternativeRecipient" />}
                description={<LocalizeText tag="confirmAlternativeRecipientDescription" />}
                onReject={() => setConfirmingChoice(false)}
                onAccept={() => {
                    if (!recipient) {
                        return;
                    }
                    setConfirmingChoice(false);
                    onSelect(recipient);
                }}
            />
        </>
    );
};

export default SetAlternativeRecipientDrawer;
