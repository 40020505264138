const colors = {
    text: "#fff",
    dimmedText: "#9E9E9E",
    errorRed: "#f44336",
    successGreen: "#4caf50",
    buttonRegular: "#0014cc",
    buttonHover: "#1e34ff",
    saveButtonRegular: "#13955F",
    saveButtonHover: "#1dbf7c",
    noButtonRegular: "#ff5039",
    noButtonHover: "#ff4f38",
    grey: "#f3f3f3",
    lightGrey: "#fafafa",
    greyText: "#999",
    background: "#F5F7F7",
    vkBlue: "#1400D5",
    vkOrange: "#FF5039",
    vkBlueHover: "#301bff",
    black: "#000",
    white: "#fff",
    border: "#dddbdd",
    warning: "#FF9800",
    firstTable: "#fff",
    secondTable: "#f3f3f3",
    avatarBackground: "#eff2f2",
};

export default colors;
