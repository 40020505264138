import React, { FC, ReactNode, Reducer, createContext, useCallback, useContext, useMemo, useReducer } from "react";
import webAPIRequest from "api";
import reducer, {
    IRealestates,
    IAction,
    IBuildingImage,
    IBuilding,
    IBuildingsText,
    initialState,
} from "reducers/realestates";
import { NotificationContext } from "contexts/notification";

export const RealestatesContext = createContext<IRealestates>({
    ...initialState,
});

export const RealestatesProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const { ...notification } = useContext(NotificationContext);
    const [currentState, dispatch] = useReducer<Reducer<IRealestates, IAction>>(reducer, initialState);

    const fetchBuilding = useCallback(
        async (buildingUuid: string): Promise<IBuilding> => {
            try {
                dispatch({ type: "FETCH_BUILDING" });
                const url = `/vk_data/buildings/${buildingUuid}/`;
                const returnData = await webAPIRequest("get", url);
                dispatch({
                    type: "FETCH_BUILDING_SUCCESS",
                    building: returnData.data,
                });
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_fetchBuildings", error);
                dispatch({ type: "FETCH_BUILDING_FAILURE" });
                return {} as IBuilding;
            }
        },
        [notification]
    );

    const fetchBuildingInfo = useCallback(
        async (buildingUuid: string): Promise<boolean> => {
            try {
                dispatch({ type: "FETCH_BUILDING_INFO" });
                const allResults: IBuildingsText[] = [];
                let url = "/vk_data/buildings/texts/";
                do {
                    const params = {
                        building_uuid: buildingUuid,
                    };
                    const returnData = await webAPIRequest("get", url, {
                        params,
                    });
                    const { results, next } = returnData.data;
                    url = next;
                    allResults.push(...results);
                } while (url !== null);
                dispatch({
                    type: "FETCH_BUILDING_INFO_SUCCESS",
                    buildingInfo: allResults,
                });
                return true;
            } catch (error) {
                notification.enqueNotification("error_fetchBuildingInfo", error);
                dispatch({ type: "FETCH_BUILDING_INFO_FAILURE" });
                return false;
            }
        },
        [notification]
    );

    const fetchBuildingImages = useCallback(
        async (buildingUuid: string): Promise<boolean> => {
            try {
                dispatch({ type: "FETCH_BUILDING_IMAGES" });
                const allResults: IBuildingImage[] = [];
                let url = "/vk_data/buildings/images/";
                do {
                    const params = {
                        building_uuid: buildingUuid,
                    };
                    const returnData = await webAPIRequest("get", url, {
                        params,
                    });
                    const { results, next } = returnData.data;
                    url = next;
                    allResults.push(...results);
                } while (url !== null);
                dispatch({
                    type: "FETCH_BUILDING_IMAGES_SUCCESS",
                    buildingImages: allResults,
                });
                return true;
            } catch (error) {
                notification.enqueNotification("error_fetchBuildingImages", error);
                dispatch({ type: "FETCH_BUILDING_IMAGES_FAILURE" });
                return false;
            }
        },
        [notification]
    );

    const submitNewTask = useCallback(
        async (newTask: FormData): Promise<boolean> => {
            try {
                dispatch({ type: "SUBMIT_TASK" });
                await webAPIRequest("post", "/vk_data/tasks/add/", {
                    data: newTask,
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                dispatch({ type: "SUBMIT_TASK_SUCCESS" });
                notification.enqueNotification("success_createTask");
                return true;
            } catch (error) {
                notification.enqueNotification("error_submitNewTask");
                dispatch({ type: "SUBMIT_TASK_FAILURE" });
                return false;
            }
        },
        [notification]
    );

    const value = useMemo(() => {
        return {
            ...currentState,
            fetchBuilding,
            fetchBuildingInfo,
            fetchBuildingImages,
            submitNewTask,
        };
    }, [currentState, fetchBuilding, fetchBuildingInfo, fetchBuildingImages, submitNewTask]);

    return <RealestatesContext.Provider value={value}>{children}</RealestatesContext.Provider>;
};

export default RealestatesProvider;
