import React, { FC, useContext } from "react";
import { ListItem, ListItemText } from "@mui/material";
import { LocalizeText } from "components/localizer";
import { LanguageContext } from "contexts/language";
import { IAlternativeInvoiceRecipient } from "reducers/memberships";
import { getCountryCodeString } from "utils/countryCodes";
import { prettifyOrgNumber } from "utils/helpers";
import { InvoicingInfo } from "../invoicingInfo";

interface IProps {
    recipient: IAlternativeInvoiceRecipient;
}
export const ExistingRecipientInfo: FC<IProps> = ({ recipient }) => {
    const { currentLanguage } = useContext(LanguageContext);

    const { company_name, name, org_nr, country_code } = recipient.organization;

    return (
        <>
            <ListItem divider>
                <ListItemText primary={<LocalizeText tag="commercialCompanyName" />} secondary={name} />
            </ListItem>
            <ListItem divider>
                <ListItemText primary={<LocalizeText tag="legalCompanyName" />} secondary={company_name} />
            </ListItem>
            <ListItem divider>
                <ListItemText primary={<LocalizeText tag="orgNumber" />} secondary={prettifyOrgNumber(org_nr)} />
            </ListItem>
            <ListItem divider>
                <ListItemText
                    primary={<LocalizeText tag="country" />}
                    secondary={country_code ? getCountryCodeString(country_code, currentLanguage) : "-"}
                />
            </ListItem>
            <InvoicingInfo data={recipient} />
        </>
    );
};
