import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import VKButton from "components/vkButton";
import { LocalizeText } from "components/localizer";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import validator from "utils/validator";
import { AuthContext } from "contexts/auth";

const ForgotPassword: React.FC = () => {
    const { forgotPassword, isSendingForgot } = React.useContext(AuthContext);
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [email, setEmail] = React.useState<string>("");

    const onClose = (): void => {
        setIsOpen(false);
    };

    const onSubmit = async (event: React.SyntheticEvent): Promise<void> => {
        event.preventDefault();
        const success = await forgotPassword(email);
        if (success) {
            onClose();
        }
    };

    return (
        <Grid item xs={12} sx={{ flexBasis: "unset !important" }}>
            <Button
                sx={{
                    marginTop: "15px",
                    fontSize: "1rem",
                }}
                tabIndex={5}
                onClick={() => setIsOpen(true)}
                variant="outlined"
            >
                <LocalizeText tag="forgotPassword" />
            </Button>
            <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
                <form onSubmit={onSubmit}>
                    <DialogTitle id="form-dialog-title">
                        <LocalizeText tag="forgotPasswordTitle" />{" "}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <LocalizeText tag="forgotPasswordHelpText" />
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            variant="outlined"
                            label={<LocalizeText tag="email" />}
                            type="email"
                            fullWidth
                            onChange={(e) => setEmail(e.target.value)}
                            error={!validator.email(email, true)}
                            helperText={!validator.email(email, true) && <LocalizeText tag="errorEmail" />}
                        />
                    </DialogContent>
                    <DialogActions>
                        <VKButton
                            tag="send"
                            template="save"
                            type="submit"
                            onClick={(e) => onSubmit(e)}
                            value={email}
                            disabled={!validator.email(email)}
                            isLoading={isSendingForgot}
                        />
                        <VKButton tag="cancel" template="cancel" onClick={() => onClose()} />
                    </DialogActions>
                </form>
            </Dialog>
        </Grid>
    );
};

export default ForgotPassword;
