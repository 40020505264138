import React from "react";
import { Box, Typography } from "@mui/material";
import { LeaseQuestionType } from "utils/types";
import { LocalizeText, fontType } from "components/localizer";
import { TLanguageTag } from "contexts/language";
interface IDialogProps {
    questionType: LeaseQuestionType;
    invoiceNumber?: string;
}

const getHelpTextTag = (questionType: LeaseQuestionType, invoiceNumber?: string): TLanguageTag => {
    switch (questionType) {
        case "LEASE_INVOICE_QUESTION":
            if (invoiceNumber) {
                return "invoiceQuestionWithNumberHelpText";
            }
            return "invoiceQuestionHelpText";
        case "CHANGE_CUSTOMER_LEDGER":
            return "changeCustomerLedgerHelpText";
        case "LEASE_CONTRACT_QUESTION":
            return "leaseContractQuestionHelpText";
        default:
            return "invoiceQuestionHelpText";
    }
};

const getRecipient = (questionType: LeaseQuestionType): React.ReactNode => {
    switch (questionType) {
        case "LEASE_INVOICE_QUESTION":
        case "CHANGE_CUSTOMER_LEDGER":
            return "ekonomi@vasakronan.se";
        case "LEASE_CONTRACT_QUESTION":
        case "GENERAL_QUESTION_CONTRACT":
            return <LocalizeText tag={"managerAgent"} />;
        default:
            return "";
    }
};

const QuestionHelpText: React.FC<IDialogProps> = ({ questionType, invoiceNumber }) => {
    const helpTextTag = React.useMemo(() => {
        return getHelpTextTag(questionType, invoiceNumber);
    }, [questionType, invoiceNumber]);

    return (
        <Box margin={"4px 0"} padding={"4px 0"}>
            {invoiceNumber && questionType === "LEASE_INVOICE_QUESTION" && (
                <Typography variant="body2">
                    <LocalizeText type={fontType.BOLD} tag={"invoiceNumber"} /> : {invoiceNumber}
                </Typography>
            )}
            <Typography variant="body2">
                <LocalizeText type={fontType.BOLD} tag="recipient" /> : {getRecipient(questionType)}
            </Typography>
            {helpTextTag && (
                <Typography margin={"4px 0"} padding={"4px 0"}>
                    <LocalizeText tag={helpTextTag} />
                </Typography>
            )}
        </Box>
    );
};

export default QuestionHelpText;
