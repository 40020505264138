import React, { FC, ReactNode } from "react";
import { Divider, Grid, ListItem, ListItemText } from "@mui/material";
import { TLanguageTag } from "contexts/language";
import VKButton from "components/vkButton";

export interface IButtonProps {
    tag: TLanguageTag;
    onClick: () => void;
    show: boolean;
}

interface IProps {
    primary: ReactNode;
    secondary?: ReactNode;
    button?: IButtonProps;
}

export const ListItemWithButton: FC<IProps> = ({ primary, secondary, button }) => {
    return (
        <>
            <Grid container justifyContent="space-between">
                <Grid item>
                    <ListItem sx={{ flexDirection: "column", alignItems: "flex-start" }}>
                        <ListItemText primary={primary} secondary={secondary} />
                    </ListItem>
                </Grid>
                {button?.show ? (
                    <Grid item marginY="auto" marginRight="-16px">
                        <ListItem>
                            <VKButton tag={button.tag} template="primary" size="small" onClick={button.onClick} />
                        </ListItem>
                    </Grid>
                ) : null}
            </Grid>
            <Divider />
        </>
    );
};
