import React from "react";
import { Card, CardActionArea, CardActions, CardContent, Grid, Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { Box } from "@mui/material";
import { GridSizes } from "utils/types";
import colors from "styles/colors";

interface ISkeletonProps {
    elevation?: number;
}

const CardSkeleton: React.FC<ISkeletonProps> = ({
    elevation = 1,
}) => {
    return (
        <Grid container component={Card} elevation={elevation}>
            <Grid item xs={3}>
                <Skeleton variant="rectangular" width={"100%"} height={"100%"} >
                    <Box width={"100%"} />
                </Skeleton>
            </Grid>
            <Grid item xs={9}>
                <CardContent style={{ width: "100%" }}>
                    <Typography variant="h6">
                        <Skeleton variant="text" width={"100%"} />
                    </Typography>
                    <Typography variant="body2">
                        <Skeleton variant="text" width={"100%"} />
                    </Typography>
                    <Typography variant="body2">
                        <Skeleton variant="text" width={"100%"} />
                    </Typography>
                </CardContent>
            </Grid>
        </Grid>
    );
};

interface IProps {
    children?: React.ReactNode;
    imageBreakPoints: GridSizes;
    contentBreakPoints: GridSizes;
    image?: React.ReactNode;
    actions?: React.ReactNode;
    rounded?: boolean;
    elevation?: number;
    onCardClick?: () => void;
}
const HorizontalCard: React.FC<IProps> = ({
    children,
    imageBreakPoints,
    contentBreakPoints,
    image,
    actions,
    rounded = false,
    elevation = 1,
    onCardClick
}) => {
    return (
        <Grid 
            component={Card}
            justifyContent={"flex-start"}
            container 
            elevation={elevation}
            borderRadius={rounded ? "6px" : "0"}
            sx={{
                position: "relative",
                minHeight: "100px"
            }}>
            {image &&
                <Grid item {...imageBreakPoints}>
                    {image}
                </Grid>
            }
            <Grid
                item
                {...contentBreakPoints}
                component={CardActionArea}
                sx={{
                    "&:hover": {
                        background: colors.lightGrey,
                    }
                }}
                onClick={onCardClick && onCardClick}>
                <CardContent sx={{paddingTop: 0, paddingBottom: 0}}>
                    {children}
                </CardContent>
            </Grid >
            <CardActions sx={{ 
                position: "absolute",
                bottom: 0,
                right: 0,
                paddingTop: 0,
                paddingBottom: 0
            }}>
                {actions}
            </CardActions>

        </Grid >
    );
};

export const HorizontalCardSkeleton = CardSkeleton;
export default HorizontalCard;
