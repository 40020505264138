import webAPIRequest from "api";
import React, { useCallback, useMemo } from "react";
import reducer, {
    DomainContentType,
    IAction,
    IDomainUser,
    IGroup,
    initialState,
    IRole,
    IRoleManagement,
} from "reducers/roleManagement";
import { emptyPaginationActionData, TPaginationActionData } from "utils/paginationStore";

export const RoleManagementContext = React.createContext<IRoleManagement>({
    ...initialState,
});

export const RoleManagementProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const [currentState, dispatch] = React.useReducer<React.Reducer<IRoleManagement, IAction>>(reducer, initialState);

    const fetchRolesOfDomain = useCallback(
        async (
            contentType: DomainContentType,
            params: Record<string, unknown>
        ): Promise<TPaginationActionData<IRole>> => {
            try {
                dispatch({ type: "FETCH_ROLES" });
                const returnData = await webAPIRequest("get", `/domains/${contentType}/roles/`, { params });
                dispatch({
                    type: "FETCH_ROLES_SUCCESS",
                    data: returnData.data,
                });
                return returnData.data;
            } catch (error) {
                dispatch({ type: "FETCH_ROLES_FAILURE" });
                return emptyPaginationActionData;
            }
        },
        []
    );

    const fetchGroupsOfDomain = useCallback(
        async (
            contentType: DomainContentType,
            params: Record<string, unknown>
        ): Promise<TPaginationActionData<IGroup>> => {
            try {
                dispatch({ type: "FETCH_GROUPS" });
                const returnData = await webAPIRequest("get", `/domains/${contentType}/groups/`, { params });
                dispatch({
                    type: "FETCH_GROUPS_SUCCESS",
                    data: returnData.data,
                });
                return returnData.data;
            } catch (error) {
                dispatch({ type: "FETCH_GROUPS_FAILURE" });
                return emptyPaginationActionData;
            }
        },
        []
    );

    const fetchUsersOfDomain = useCallback(
        async (
            contentType: DomainContentType,
            params: Record<string, unknown>
        ): Promise<TPaginationActionData<IDomainUser>> => {
            try {
                dispatch({ type: "FETCH_USERS" });
                const returnData = await webAPIRequest("get", `/domains/${contentType}/users/`, { params });
                dispatch({
                    type: "FETCH_USERS_SUCCESS",
                    data: returnData.data,
                });
                return returnData.data;
            } catch (error) {
                dispatch({ type: "FETCH_USERS_FAILURE" });
                return emptyPaginationActionData;
            }
        },
        []
    );

    const value = useMemo(() => {
        return {
            ...currentState,
            fetchRolesOfDomain,
            fetchGroupsOfDomain,
            fetchUsersOfDomain,
        };
    }, [currentState, fetchRolesOfDomain, fetchGroupsOfDomain, fetchUsersOfDomain]);

    return <RoleManagementContext.Provider value={value}>{children}</RoleManagementContext.Provider>;
};
