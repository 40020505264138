import React, { ChangeEvent, FC, useCallback, useState } from "react";
import { IPaginationStore } from "utils/paginationStore";
import { LocalizeText } from "components/localizer";
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { sanitizeOrgNumber } from "utils/helpers";
import { TLanguageTag } from "contexts/language";

type SearchSanitizer = "membership";

interface ISearchFieldProps {
    pagination: IPaginationStore<unknown>;
    tag?: TLanguageTag;
    validator?: SearchSanitizer;
}

const SearchField: FC<ISearchFieldProps> = ({ pagination, tag = "search", validator }) => {
    const [timeoutHandle, setTimeoutHandle] = useState<NodeJS.Timeout | null>(null);
    const [searchString, setSearchString] = useState<string>("");

    const sanitizeValue = useCallback(
        (value: string): string => {
            if (validator === "membership") {
                return sanitizeOrgNumber(value);
            }

            return value;
        },
        [validator]
    );

    const performSearch = useCallback(
        (value: string): void => {
            if (timeoutHandle) {
                clearTimeout(timeoutHandle);
            }
            setTimeoutHandle(
                setTimeout(() => {
                    pagination.setParam("search", sanitizeValue(value));
                    pagination.getInitial();
                }, 400)
            );
        },
        [pagination, timeoutHandle, sanitizeValue]
    );

    const handleSearchInput = useCallback(
        (e: ChangeEvent<HTMLInputElement>): void => {
            const value = e.target.value;
            setSearchString(value);
            performSearch(value);
        },
        [performSearch]
    );

    return (
        <TextField
            type="text"
            label={<LocalizeText tag={tag} />}
            variant="outlined"
            value={searchString}
            size="small"
            sx={{ width: "265px" }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
            onChange={handleSearchInput}
        />
    );
};
export default SearchField;
