import React from "react";
import { Variant } from "@mui/material/styles/createTypography";
import { Typography } from "@mui/material";
import colors from "styles/colors";

interface IProps {
    phoneNr: string;
    spaceBefore?: boolean;
    variant?: Variant;
}
const PhoneText: React.FC<IProps> = ({ phoneNr, spaceBefore, variant = "body2" }) => {
    return (
        <Typography 
            variant={variant}
            component={"a"}
            href={`tel:${phoneNr}`}
            sx={{
                textDecoration: "underline",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                color: colors.black,
                ...spaceBefore ? {
                    marginLeft: "10px"
                } : {}
            }}>
            {phoneNr}
        </Typography>
    );
};

export default PhoneText;
