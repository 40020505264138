import React from "react";
import { IMembership, IContactPerson } from "reducers/memberships";
import AddContactPersonBase, { INewContactObject } from "components/drawers/contactPersons/addContactPersonBase";
import { DomainContentType, IRole } from "reducers/roleManagement";
import { RoleManagementContext } from "contexts/roleManagement";
import { ModalContext } from "contexts/modals";
import { TPaginationActionData } from "utils/paginationStore";
import { fetchMembershipContactsAdapter } from "adapters/membershipAdapter";
import ConfirmNewContactDialog from "components/dialogs/confirmContactPersonDialog";
import { MembershipsContext } from "contexts/memberships";
import { useFeatureToggle } from "utils/hooks/useFeatureToggle";

interface IAddMembershipContactProps {
    onOpenEdit?: (person: IContactPerson, membership: IMembership) => void;
    membership: IMembership;
}
const AddMembershipContactPerson: React.FC<IAddMembershipContactProps> = ({ onOpenEdit, membership }) => {
    const { rolesPagination, fetchRolesOfDomain } = React.useContext(RoleManagementContext);
    const { pushDialog, closeDrawers } = React.useContext(ModalContext);
    const { addContactPerson } = React.useContext(MembershipsContext);
    const isActive = useFeatureToggle();

    React.useEffect(() => {
        rolesPagination.initialize(rolesPaginationCallback);
        rolesPagination.setParam("domain_object_id", membership.id);
        rolesPagination.appendResults = true;
        rolesPagination.getInitial();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [membership]);

    const onSubmitDetails = async (contact: INewContactObject): Promise<void> => {
        if (!isActive("confirm_before_adding_contact")) {
            addContactPerson(membership.id, contact);
            return closeDrawers();
        }
        pushDialog(ConfirmNewContactDialog, {
            onAccept: () => addContactPerson(membership.id, contact),
        });
    };

    const rolesPaginationCallback = async (params: Record<string, unknown>): Promise<TPaginationActionData<IRole>> => {
        return fetchRolesOfDomain(DomainContentType.Membership, params);
    };

    const onOpenEditContactPerson = (person: IContactPerson): void => {
        if (onOpenEdit) {
            onOpenEdit(person, membership);
        }
    };

    const fetchExisting = async (): Promise<IContactPerson[]> => {
        const entries = await fetchMembershipContactsAdapter(membership.id, {});
        return entries.data["results"];
    };

    return (
        <AddContactPersonBase
            onSubmit={onSubmitDetails}
            fetchExisting={fetchExisting}
            onOpenEdit={onOpenEdit && onOpenEditContactPerson}
            titleTag="membership"
            contractData={`${membership.agreement_nr} - ${
                membership.organization.name || membership.organization.company_name
            }`}
            availableRoles={rolesPagination}
        />
    );
};

export default AddMembershipContactPerson;
