import React, { FC, ReactNode, Reducer, createContext, useCallback, useContext, useMemo, useReducer } from "react";
import webAPIRequest from "api";
import reducer, {
    IMemberships,
    IAction,
    initialState,
    IMembership,
    TMembershipId,
    IContactPerson,
    ITermsAndService,
    ICountryCode,
    IAlternativeInvoiceRecipient,
} from "reducers/memberships";
import { NotificationContext } from "contexts/notification";
import { emptyPaginationActionData, TPaginationActionData } from "utils/paginationStore";
import { IInvitation } from "reducers/leases";
import { fetchMembershipContactsAdapter } from "adapters/membershipAdapter";
import { INewContactObject } from "components/drawers/contactPersons/addContactPersonBase";

export const MembershipsContext = createContext<IMemberships>({
    ...initialState,
});

export const MembershipsProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const { ...notification } = useContext(NotificationContext);
    const [currentState, dispatch] = useReducer<Reducer<IMemberships, IAction>>(reducer, initialState);

    const setMembership = useCallback((membership: IMembership, isSingleMembership = false): void => {
        dispatch({
            type: "SET_MEMBERSHIP",
            membership: membership,
        });
        dispatch({
            type: "SET_SINGLE_MEMBERSHIP",
            isSingle: isSingleMembership,
        });
    }, []);

    const fetchMembership = useCallback(
        async (membershipId: TMembershipId): Promise<IMembership> => {
            try {
                dispatch({ type: "FETCH_MEMBERSHIP" });
                const returnData = await webAPIRequest("get", `/memberships/${membershipId}/`);
                dispatch({
                    type: "FETCH_MEMBERSHIP_SUCCESS",
                    membership: returnData.data,
                });
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_fetchMembership", error);
                dispatch({ type: "FETCH_MEMBERSHIP_FAILURE" });
                return {} as IMembership;
            }
        },
        [notification]
    );

    const fetchMemberships = useCallback(
        async (params: Record<string, unknown>): Promise<TPaginationActionData<IMembership>> => {
            try {
                dispatch({ type: "FETCH_MEMBERSHIPS" });
                const returnData = await webAPIRequest("get", "/memberships/", {
                    params,
                });
                dispatch({
                    type: "FETCH_MEMBERSHIPS_SUCCESS",
                    data: returnData.data,
                });
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_fetchMemberships");
                dispatch({ type: "FETCH_MEMBERSHIPS_FAILURE" });
                return emptyPaginationActionData;
            }
        },
        [notification]
    );

    const fetchContactPersons = useCallback(
        async (
            membershipId: TMembershipId,
            params: Record<string, unknown>
        ): Promise<TPaginationActionData<IContactPerson>> => {
            try {
                dispatch({ type: "FETCH_CONTACT_PERSONS" });
                const existing = await fetchMembershipContactsAdapter(membershipId, params);
                dispatch({
                    type: "FETCH_CONTACT_PERSONS_SUCCESS",
                    data: existing.data,
                });
                return existing.data;
            } catch (error) {
                notification.enqueNotification("error_fetchContactPersons", error);
                dispatch({ type: "FETCH_CONTACT_PERSONS_FAILURE" });
                return emptyPaginationActionData;
            }
        },
        [notification]
    );

    const fetchContactPersonsInvitations = useCallback(
        async (membershipId: TMembershipId): Promise<TPaginationActionData<IInvitation>> => {
            try {
                dispatch({ type: "FETCH_INVITATIONS" });
                const pending = await webAPIRequest("get", `/memberships/${membershipId}/contact-persons/invitations/`);

                dispatch({
                    type: "FETCH_INVITATIONS_SUCCESS",
                    data: pending.data,
                });
                return pending.data;
            } catch (error) {
                notification.enqueNotification("error_fetchContactPersons", error);
                dispatch({ type: "FETCH_INVITATIONS_FAILURE" });
                return emptyPaginationActionData;
            }
        },
        [notification]
    );

    const addContactPerson = useCallback(
        async (membershipId: TMembershipId, newContact: INewContactObject): Promise<boolean> => {
            try {
                dispatch({ type: "ADD_CONTACT_PERSON" });
                const data = {
                    email: newContact.email,
                    phone_nr: newContact.phone,
                    first_name: newContact.firstName,
                    last_name: newContact.lastName,
                    role_id: newContact.roleId,
                };
                const newContactPerson = await webAPIRequest("post", `/memberships/${membershipId}/contact-persons/`, {
                    data,
                });
                dispatch({ type: "ADD_CONTACT_PERSON_SUCCESS", newContactPerson: newContactPerson.data });
                notification.enqueNotification("success_addContactPerson");
                return true;
            } catch (error) {
                notification.enqueNotification("error_addContactPerson", error);
                dispatch({ type: "ADD_CONTACT_PERSON_FAILURE" });
                return false;
            }
        },
        [notification]
    );

    const editContactPerson = useCallback(
        async (
            membershipId: TMembershipId,
            uuid: string,
            firstName: string,
            lastName: string,
            phone: string,
            role?: number
        ): Promise<boolean> => {
            try {
                dispatch({ type: "EDIT_CONTACT_PERSON" });
                const data = {
                    first_name: firstName,
                    last_name: lastName,
                    phone_nr: phone,
                    role: role,
                };
                await webAPIRequest("patch", `/memberships/${membershipId}/contact-persons/${uuid}/`, {
                    data,
                });
                dispatch({ type: "EDIT_CONTACT_PERSON_SUCCESS" });
                notification.enqueNotification("success_editContactPerson");
                return true;
            } catch (error) {
                notification.enqueNotification("error_editContactPerson", error);
                dispatch({ type: "EDIT_CONTACT_PERSON_FAILURE" });
                return false;
            }
        },
        [notification]
    );

    const removeContactPerson = useCallback(
        async (membershipId: TMembershipId, uuid: string): Promise<boolean> => {
            try {
                dispatch({ type: "REMOVE_CONTACT_PERSON" });
                await webAPIRequest("delete", `/memberships/${membershipId}/contact-persons/${uuid}/`);
                dispatch({ type: "REMOVE_CONTACT_PERSON_SUCCESS" });
                notification.enqueNotification("success_removeContactPerson");
                return true;
            } catch (error) {
                notification.enqueNotification("error_removeContactPerson", error);
                dispatch({ type: "REMOVE_CONTACT_PERSON_FAILURE" });
                return false;
            }
        },
        [notification]
    );

    const removeInvitation = useCallback(
        async (membershipId: TMembershipId, uuid: string): Promise<boolean> => {
            try {
                dispatch({ type: "REMOVE_CONTACT_PERSON" });
                await webAPIRequest("delete", `/memberships/${membershipId}/contact-persons/invitations/${uuid}/`);
                dispatch({ type: "REMOVE_CONTACT_PERSON_SUCCESS" });
                notification.enqueNotification("success_removeInvitation");
                return true;
            } catch (error) {
                notification.enqueNotification("error_removeInvitation", error);
                dispatch({ type: "REMOVE_CONTACT_PERSON_FAILURE" });
                return false;
            }
        },
        [notification]
    );

    const clearStorage = useCallback((): void => {
        dispatch({ type: "CLEAR_STORAGE" });
    }, []);

    const getEmailsCSV = useCallback(async (): Promise<boolean> => {
        try {
            dispatch({ type: "FETCH_EMAILS_CSV" });
            const returnData = await webAPIRequest("get", "/contact-persons/export/");
            const url = window.URL.createObjectURL(new Blob([returnData.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "emails.csv");
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
            dispatch({ type: "FETCH_EMAILS_CSV_SUCCESS" });
            return true;
        } catch (error) {
            notification.enqueNotification("error_removeInvitation", error);
            dispatch({ type: "FETCH_EMAILS_CSV_FAILURE" });
            return false;
        }
    }, [notification]);

    const updateMembership = useCallback(
        async (membershipId: TMembershipId, data: Record<string, unknown>): Promise<IMembership> => {
            try {
                dispatch({ type: "UPDATE_MEMBERSHIP" });
                const returnData = await webAPIRequest("patch", `/memberships/${membershipId}/`, { data });
                dispatch({ type: "UPDATE_MEMBERSHIP_SUCCESS", data: returnData.data });
                notification.enqueNotification("success_updateMembership");
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_updateMembership", error);
                dispatch({ type: "UPDATE_MEMBERSHIP_FAILURE" });
                return {} as IMembership;
            }
        },
        [notification]
    );

    const terminateMembership = useCallback(
        async (membershipId: TMembershipId): Promise<IMembership> => {
            try {
                dispatch({ type: "TERMINATE_MEMBERSHIP" });
                const returnData = await webAPIRequest("patch", `/memberships/${membershipId}/inactivate/`);
                dispatch({ type: "TERMINATE_MEMBERSHIP_SUCCESS", data: returnData.data });
                notification.enqueNotification("success_terminateMembership");
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_terminateMembership", error);
                dispatch({ type: "TERMINATE_MEMBERSHIP_FAILURE" });
                return {} as IMembership;
            }
        },
        [notification]
    );

    const fetchTac = useCallback(
        async (params?: Record<string, unknown>): Promise<ITermsAndService[]> => {
            try {
                dispatch({ type: "FETCH_TAC" });
                const returnData = await webAPIRequest("get", "/general_terms_and_conditions/", { params: params });
                dispatch({ type: "FETCH_TAC_SUCCESS", data: returnData.data["results"] });
                return returnData.data["results"];
            } catch (error) {
                notification.enqueNotification("error_fetchTac", error);
                dispatch({ type: "FETCH_TAC_FAILURE" });
                return [];
            }
        },
        [notification]
    );

    const fetchCountries = useCallback(async (): Promise<ICountryCode[]> => {
        try {
            dispatch({ type: "FETCH_COUNTRY_CODES" });
            const returnData = await webAPIRequest("get", "/organization/country-codes/");
            dispatch({ type: "FETCH_COUNTRY_CODES_SUCCESS", countries: returnData.data });
            return returnData.data;
        } catch (error) {
            notification.enqueNotification("error_fetchCountryCodes", error);
            dispatch({ type: "FETCH_COUNTRY_CODES_FAILURE" });
            return [];
        }
    }, [notification]);

    const fetchAlternativeRecipient = useCallback(
        async (recipientUuid: string): Promise<IAlternativeInvoiceRecipient> => {
            try {
                dispatch({ type: "FETCH_ALTERNATIVE_RECIPIENT" });
                const returnData = await webAPIRequest(
                    "get",
                    `/memberships/alternative-invoice-recipients/${recipientUuid}/`
                );
                dispatch({
                    type: "FETCH_ALTERNATIVE_RECIPIENT_SUCCESS",
                });
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_fetchAlternativeRecipient", error);
                dispatch({ type: "FETCH_ALTERNATIVE_RECIPIENT_FAILURE" });
                return {} as IAlternativeInvoiceRecipient;
            }
        },
        [notification]
    );

    const fetchAlternativeRecipients = useCallback(
        async (params: Record<string, unknown>): Promise<TPaginationActionData<IAlternativeInvoiceRecipient>> => {
            try {
                dispatch({ type: "FETCH_ALTERNATIVE_RECIPIENTS" });
                const returnData = await webAPIRequest("get", "/memberships/alternative-invoice-recipients/", {
                    params,
                });
                dispatch({
                    type: "FETCH_ALTERNATIVE_RECIPIENTS_SUCCESS",
                });
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_fetchAlternativeRecipients", error);
                dispatch({ type: "FETCH_ALTERNATIVE_RECIPIENTS_FAILURE" });
                return emptyPaginationActionData;
            }
        },
        [notification]
    );

    const updateAlternativeRecipient = useCallback(
        async (recipientUuid: string, data: Record<string, unknown>): Promise<IAlternativeInvoiceRecipient> => {
            try {
                dispatch({ type: "UPDATE_ALTERNATIVE_RECIPIENT" });
                const returnData = await webAPIRequest(
                    "patch",
                    `/memberships/alternative-invoice-recipients/${recipientUuid}/`,
                    { data }
                );
                dispatch({ type: "UPDATE_ALTERNATIVE_RECIPIENT_SUCCESS" });
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_updateAlternativeRecipient", error);
                dispatch({ type: "UPDATE_ALTERNATIVE_RECIPIENT_FAILURE" });
                return {} as IAlternativeInvoiceRecipient;
            }
        },
        [notification]
    );

    const createAlternativeRecipient = useCallback(
        async (data: Record<string, unknown>): Promise<IAlternativeInvoiceRecipient> => {
            try {
                dispatch({ type: "CREATE_ALTERNATIVE_RECIPIENT" });
                const returnData = await webAPIRequest("post", "/memberships/alternative-invoice-recipients/", {
                    data,
                });
                dispatch({ type: "CREATE_ALTERNATIVE_RECIPIENT_SUCCESS" });
                notification.enqueNotification("success_createAlternativeRecipient");
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_updateAlternativeRecipient", error);
                dispatch({ type: "CREATE_ALTERNATIVE_RECIPIENT_FAILURE" });
                return {} as IAlternativeInvoiceRecipient;
            }
        },
        [notification]
    );

    const value = useMemo(() => {
        return {
            ...currentState,
            fetchMembership,
            fetchMemberships,
            fetchContactPersons,
            fetchContactPersonsInvitations,
            addContactPerson,
            editContactPerson,
            removeContactPerson,
            removeInvitation,
            clearStorage,
            getEmailsCSV,
            updateMembership,
            terminateMembership,
            fetchTac,
            setMembership,
            fetchCountries,
            fetchAlternativeRecipient,
            fetchAlternativeRecipients,
            updateAlternativeRecipient,
            createAlternativeRecipient,
        };
    }, [
        currentState,
        fetchMembership,
        fetchMemberships,
        fetchContactPersons,
        fetchContactPersonsInvitations,
        addContactPerson,
        editContactPerson,
        removeContactPerson,
        removeInvitation,
        clearStorage,
        getEmailsCSV,
        updateMembership,
        terminateMembership,
        fetchTac,
        setMembership,
        fetchCountries,
        fetchAlternativeRecipient,
        fetchAlternativeRecipients,
        updateAlternativeRecipient,
        createAlternativeRecipient,
    ]);

    return <MembershipsContext.Provider value={value}>{children}</MembershipsContext.Provider>;
};
