import React, { FC } from "react";
import { IInvoice } from "reducers/invoices";
import colors from "styles/colors";
import { LocalizeText } from "../localizer";

interface IProps {
    invoice: IInvoice;
}

export const InvoiceStatus: FC<IProps> = ({ invoice }) => {
    let color: string | undefined = "inherit";
    const { payment_status, is_overdue } = invoice;
    switch (payment_status) {
        case "UNPAID":
            if (is_overdue) {
                color = colors.errorRed;
            }
            break;
        case "PARTIALLY_PAID":
            if (is_overdue) {
                color = colors.errorRed;
            } else {
                color = colors.vkBlue;
            }
            break;
        case "PAID":
            color = colors.successGreen;
            break;
    }
    return <LocalizeText styling={{ color: color }} tag={`paymentStatus${payment_status}`} />;
};
