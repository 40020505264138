import React, { useRef } from "react";
import VKButton from "components/vkButton";
import { LocalizeText } from "components/localizer";
import { AuthContext } from "contexts/auth";
import { ISmsDevice } from "reducers/auth";
import { Collapse, Divider, List, ListItem, Modal, styled, TextField } from "@mui/material";
import If from "components/__helpers__/if";
import BackupCodes from "components/2FA/backupCodes";
import Drawer from "components/drawer";
import RegisterAppWizard from "components/2FA/registerAppWizard";
import RegisterSmsWizard from "components/2FA/registerSmsWizard";
import ConfirmDialog from "components/confirmDialog";
import { NotificationContext } from "contexts/notification";
import DataList from "components/dataList";
import { findUnconfirmedTotp, getConfirmedSmsDevice } from "components/2FA/utils";

export type TDrawer =
    | "ADD_TOTP_DEVICE"
    | "EDIT_TOTP_DEVICES"
    | "CONFIRM_REMOVE_SMS"
    | "ADD_SMS_DEVICE"
    | "BACKUP_CODES"
    | "";

export const TwoFABackground = styled("div")(() => ({
    alignItems: "center",
    margin: "auto",
    width: "75%",
    height: "90%",
    "@media (max-height:820px), (max-width:1024px)": {
        "& h2": {
            fontSize: "1rem",
        },
        "& h1, & span": {
            fontSize: "0.75rem",
        },
    },
    overflow: "hidden",
}));
interface IProps {
    drawerType: TDrawer;
    setDrawerState: (newState: TDrawer) => void;
    smsDevice: ISmsDevice | null;
}

const ProfileViewDrawerGroup: React.FC<IProps> = ({ drawerType, setDrawerState, smsDevice }) => {
    const {
        fetchTotpDevices,
        totpDevices,
        createTotpDevice,
        isCreatingTotpDevice,
        updateTotpDevice,
        isUpdatingTotpDevice,
        deleteTotpDevice,
        isDeletingTotpDevice,
        smsDevices,
        fetchSmsDevices,
        deleteSmsDevice,
        isDeletingSmsDevice,
        backupCodes,
        generateBackupCodes,
        isGeneratingBackupCodes,
    } = React.useContext(AuthContext);
    const { ...notification } = React.useContext(NotificationContext);
    const [removeId, setRemoveId] = React.useState<number>(-1);
    const [newDeviceName, setNewDeviceName] = React.useState<string>("");
    const deviceNameRef = useRef<HTMLInputElement>();

    const closeDrawer = (): void => {
        setDrawerState("");
    };

    const onCloseAppWizard = (): void => {
        fetchTotpDevices();
        closeDrawer();
    };

    const onCloseSmsWizard = (): void => {
        fetchSmsDevices();
        closeDrawer();
    };

    const onEditTextFieldOpen = (): void => {
        if (deviceNameRef?.current) {
            deviceNameRef.current.focus();
            deviceNameRef.current.select();
        }
    };

    const onAddTotpDevice = async (): Promise<void> => {
        let success = true;
        const unconfirmedDevice = findUnconfirmedTotp(totpDevices);
        if (unconfirmedDevice) {
            success = await updateTotpDevice(unconfirmedDevice.id, newDeviceName);
        } else {
            success = await createTotpDevice(newDeviceName);
        }
        success && setDrawerState("ADD_TOTP_DEVICE");
        setNewDeviceName("");
    };

    const onDeleteTotpDevice = async (): Promise<void> => {
        if (!totpDevices) {
            return;
        }
        const success = await deleteTotpDevice(totpDevices[removeId].id);
        if (success) {
            setRemoveId(-1);
            totpDevices.length === 0 && closeDrawer();
        }
    };

    const onDeleteSms = async (): Promise<void> => {
        if (smsDevice) {
            await deleteSmsDevice(smsDevice.id);
            closeDrawer();
        }
    };

    const onGenerateNewCodes = async (): Promise<void> => {
        const success = await generateBackupCodes();
        success && notification.enqueNotification("success_generateBackupCodes");
    };

    const confirmedTotpDevices: string[] = [];
    totpDevices?.forEach((d) => {
        if (d.confirmed) {
            confirmedTotpDevices.push(d.name);
        }
    });

    const totpDevicesProps = {
        items: confirmedTotpDevices,
        onSubmitEdit: (index: number, newName: string): void => {
            if (!totpDevices) {
                return;
            }
            const edited = totpDevices.find((d) => confirmedTotpDevices[index] === d.name);
            edited && updateTotpDevice(edited.id, newName);
        },
    };
    if (confirmedTotpDevices.length >= 2 || getConfirmedSmsDevice(smsDevices)) {
        totpDevicesProps["onRemove"] = (index: number) => setRemoveId(index);
    }

    return (
        <>
            <Drawer
                isOpen={drawerType === "EDIT_TOTP_DEVICES"}
                onClose={closeDrawer}
                title={<LocalizeText tag="editAppDevices" />}
            >
                <List>
                    <ListItem style={{ marginBottom: 20 }}>
                        <LocalizeText tag="totpDevices" />
                    </ListItem>
                    <DataList {...totpDevicesProps} />
                    <Collapse in={confirmedTotpDevices.length < 3 && newDeviceName === null}>
                        <ListItem style={{ marginTop: 20 }}>
                            <VKButton
                                tag="addDevice"
                                template="primary"
                                onClick={() => setNewDeviceName("My new device")}
                            />
                        </ListItem>
                    </Collapse>
                    <Collapse in={newDeviceName !== null} onEnter={onEditTextFieldOpen}>
                        <ListItem style={{ marginTop: 20 }}>
                            <LocalizeText tag="addDeviceInfo" />
                        </ListItem>
                        <ListItem>
                            <TextField
                                inputRef={deviceNameRef}
                                label={<LocalizeText tag="name" />}
                                value={newDeviceName || ""}
                                onChange={(e) => setNewDeviceName(e.target.value)}
                                margin="dense"
                                variant="outlined"
                                fullWidth
                            />
                        </ListItem>
                        <ListItem sx={{ gap: "8px" }}>
                            <VKButton
                                tag="add"
                                template="primary"
                                disabled={newDeviceName === "" || isUpdatingTotpDevice || isCreatingTotpDevice}
                                onClick={onAddTotpDevice}
                            />
                            <VKButton
                                tag="cancel"
                                template="cancel"
                                disabled={isUpdatingTotpDevice}
                                onClick={() => setNewDeviceName("")}
                            />
                        </ListItem>
                    </Collapse>
                </List>
            </Drawer>

            <Drawer
                isOpen={drawerType === "BACKUP_CODES"}
                onClose={closeDrawer}
                title={<LocalizeText tag="backupCodes" />}
            >
                <List>
                    <ListItem>
                        <LocalizeText tag="backupCodesInfo" />
                    </ListItem>
                    <ListItem>
                        <BackupCodes />
                    </ListItem>
                    <If truthy={backupCodes && backupCodes.length > 0}>
                        <Divider style={{ margin: "20px 0" }} />
                        <ListItem>
                            <LocalizeText tag="generateCodesWarning" />
                        </ListItem>
                        <ListItem>
                            <VKButton
                                tag="generateCodes"
                                template="primary"
                                isLoading={isGeneratingBackupCodes}
                                onClick={onGenerateNewCodes}
                                style={{ margin: "0 auto" }}
                            />
                        </ListItem>
                    </If>
                </List>
            </Drawer>

            <Modal
                open={drawerType === "ADD_TOTP_DEVICE"}
                onClose={onCloseAppWizard}
                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
                <TwoFABackground>
                    <RegisterAppWizard onCancel={onCloseAppWizard} />
                </TwoFABackground>
            </Modal>

            <Modal
                open={drawerType === "ADD_SMS_DEVICE"}
                onClose={onCloseSmsWizard}
                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
                <TwoFABackground>
                    <RegisterSmsWizard onCancel={onCloseSmsWizard} />
                </TwoFABackground>
            </Modal>

            <ConfirmDialog
                open={drawerType === "CONFIRM_REMOVE_SMS"}
                title={<LocalizeText tag="removeMethodSMS" />}
                description={<LocalizeText tag="removeSure" />}
                onReject={closeDrawer}
                onAccept={onDeleteSms}
                isLoading={isDeletingSmsDevice}
            />

            {totpDevices && (
                <ConfirmDialog
                    open={removeId !== -1}
                    title={<LocalizeText tag="removeAppDevice" />}
                    description={<LocalizeText tag="removeSure" />}
                    onReject={() => setRemoveId(-1)}
                    onAccept={onDeleteTotpDevice}
                    isLoading={isDeletingTotpDevice}
                />
            )}
        </>
    );
};

export default ProfileViewDrawerGroup;
