import React, { ReactNode } from "react";
import { LocalizeText } from "components/localizer";
import { Grid, styled } from "@mui/material";
import colors from "styles/colors";
import { TLanguageTag } from "contexts/language";

export type TInnerPage = {
    icon: ReactNode;
    title: TLanguageTag;
    info: TLanguageTag;
    infoReplace?: Record<string, string>;
    customContent: ReactNode;
    bottomText?: TLanguageTag;
};

const InnerPage = styled(Grid)(() => ({
    backgroundColor: colors.white,
    padding: "40px",
    width: "100%",
    height: "100%",
}));

const InnerPageImageContainer = styled(Grid)(() => ({
    "@media (max-height:820px), (max-width:1024px)": {
        height: 50,
    },
    height: 100,

    "& svg": {
        height: "100%",
    },
}));

const InnerPageTitle = styled("h2")(() => ({
    fontSize: "1.5rem",
    textAlign: "center",
    fontWeight: "bold",
}));

interface IProps {
    page: TInnerPage;
}
const Register2FA: React.FC<IProps> = ({ page: { icon, title, info, infoReplace, customContent, bottomText } }) => {
    return (
        <InnerPage container direction="column" alignItems="center" wrap="nowrap">
            <InnerPageImageContainer item>{icon}</InnerPageImageContainer>
            <Grid item>
                <InnerPageTitle>
                    <LocalizeText tag={title} />
                </InnerPageTitle>
            </Grid>
            <Grid
                item
                sx={{
                    marginBottom: "20px",
                    textAlign: "center",
                }}
            >
                <LocalizeText tag={info} replace={infoReplace} />
            </Grid>
            {customContent}
            {bottomText ? (
                <Grid
                    item
                    sx={{
                        marginTop: "40px",
                        textAlign: "center",
                        color: colors.dimmedText,
                        fontSize: "0.9rem",
                        maxWidth: "430px",
                    }}
                >
                    <LocalizeText tag={bottomText} />
                </Grid>
            ) : null}
        </InnerPage>
    );
};

export default Register2FA;
