import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import List from "@mui/material/List";
import VKButton from "components/vkButton";
import { LocalizeText } from "components/localizer";
import {
    Box,
    Checkbox,
    Collapse,
    FormControlLabel,
    Grid,
    InputAdornment,
    ListItem,
    ListItemText,
    MenuItem,
    TextField,
} from "@mui/material";
import validator from "utils/validator";
import colors from "styles/colors";
import { ModalContext } from "contexts/modals";
import { ProductsContext } from "contexts/products";
import ProductInformation from "./productInformation";
import CommunityContext from "contexts/community";
import { IBookkeepingAccount, productCategories } from "reducers/products";
import UnitSelection from "./product/unitSelection";
import useProductKeyValidation from "./product/useProductKeyValidation";
import { CenteredLoader } from "components/centeredLoader";
import { IUsePaginationStore } from "utils/usePaginationStore";
import { MultiSelectDropdown } from "components/selectDropdown";
import { TCommunity } from "utils/ecommerseTypes";

interface IProps {
    pagination: IUsePaginationStore<"products">;
}

const CreateProduct: FC<IProps> = ({ pagination }) => {
    const {
        createProduct,
        isCreatingProduct,
        isFetchingProduct,
        fetchAccounts,
        accounts,
        fetchServiceSections,
        serviceSections,
    } = useContext(ProductsContext);
    const {  productCommunitiesPagination } = useContext(CommunityContext);
    const { openDrawer, closeDrawers } = useContext(ModalContext);
    const [fields, setFields] = useState({
        product_key: "",
        name: "",
        description: "",
        community: "",
        category: productCategories[0] as string,
        slots: "0",
        price: "0",
        vat_proportion: "0",
        binding_period: "0",
        binding_period_value_type: "days",
        trial_period: "0",
        trial_period_value_type: "days",
        notice_period: "0",
        notice_period_value_type: "days",
        revenue_account: "",
        vat_account: "",
        service_section: "",
        national_access: false,
        communities: [],
    });

    useEffect(() => {
        productCommunitiesPagination.getInitial();
        fetchAccounts();
        fetchServiceSections();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setField = (prop: string, value: string | boolean | TCommunity[]): void => {
        setFields({
            ...fields,
            [prop]: value,
        });
    };

    const { productKeyIsValid, onProductKeyChange } = useProductKeyValidation((newValue) =>
        setField("product_key", newValue)
    );

    const { revenueAccounts, vatAccounts } = useMemo((): {
        revenueAccounts: IBookkeepingAccount[];
        vatAccounts: IBookkeepingAccount[];
    } => {
        return {
            revenueAccounts: accounts?.filter((a) => a.account_type === "revenue") ?? [],
            vatAccounts: accounts?.filter((a) => a.account_type === "vat") ?? [],
        };
    }, [accounts]);

    useEffect(() => {
        if (accounts && accounts.length > 0 && fields.revenue_account === "") {
            setFields({
                ...fields,
                revenue_account: revenueAccounts[0].uuid,
                vat_account: vatAccounts[0].uuid,
            });
        }
        // eslint-disable-next-line
    }, [revenueAccounts, vatAccounts]);

    useEffect(() => {
        if (productCommunitiesPagination.results.length > 0 && fields.community === "") {
            setField("community", productCommunitiesPagination.results[0].uuid);
        }
        // eslint-disable-next-line
    }, [productCommunitiesPagination.results]);

    const slotsIsValid = validator.numberLargerThanZero(parseInt(fields.slots));

    const missingData = Object.entries(fields).some(([prop, value]) => {
        switch (prop) {
            case "slots":
            case "description":
            case "national_access":
            case "communities":
            case "service_section":
                return false;

            case "category":
                return (value === "arena_office" && parseInt(fields.slots) <= 0);

            case "product_key":
                return !productKeyIsValid;

            default:
                return !validator.textFieldNotEmpty(value);
        }
    });

    const onCreate = async (): Promise<void> => {
        const {
            price,
            vat_proportion,
            binding_period,
            binding_period_value_type,
            trial_period,
            trial_period_value_type,
            notice_period,
            notice_period_value_type,
            ...restFields
        } = fields;
        const data = {
            ...restFields,
            price: {
                amount_excl_vat: price,
                vat_proportion,
            },
            rules: {
                binding_period,
                binding_period_value_type,
                trial_period,
                trial_period_value_type,
                notice_period,
                notice_period_value_type,
            },
        };
        const newProduct = await createProduct(data);
        if (!newProduct?.created) {
            return;
        }

        closeDrawers();
        openDrawer(ProductInformation, { product: newProduct, pagination }, "productInfo");
        pagination.query.refetch();
    };

    const onCommunitiesChange = (ids: string[]): void => {
        setField("communities", ids as unknown as TCommunity[]);
    };

    useEffect(() => {
        if (!fields.national_access || fields.community) {
            setField("communities", []);
        }
    }, [fields.community, fields.national_access]);

    const items = useMemo(() => {
        const temp: { id: string; primary: string }[] = [];

        productCommunitiesPagination.results.map((community) => {
            if (community.uuid !== fields.community) {
                temp.push({ id: community.uuid, primary: community.title });
            }
        });

        return temp;
    }, [productCommunitiesPagination.results, fields.community]);

    return (
        <Box
            sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <List>
                <ListItem>
                    <ListItemText primary={<LocalizeText tag="baseInformation" />} />
                </ListItem>
                <ListItem>
                    <TextField
                        label={<LocalizeText tag="productKey" />}
                        value={fields.product_key}
                        onChange={onProductKeyChange}
                        fullWidth
                        required
                        placeholder="T.ex. AS1"
                        error={fields.product_key !== "" && !productKeyIsValid}
                        helperText={
                            fields.product_key !== "" && !productKeyIsValid && <LocalizeText tag="errorAlreadyUsed" />
                        }
                        InputProps={{
                            endAdornment: isFetchingProduct ? (
                                <InputAdornment position="end">
                                    <CenteredLoader size="20px" />
                                </InputAdornment>
                            ) : undefined,
                        }}
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        label={<LocalizeText tag="name" />}
                        value={fields.name}
                        onChange={(e) => setField("name", e.target.value)}
                        fullWidth
                        required
                        placeholder="T.ex. Abonnemang Sergel"
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        label={<LocalizeText tag="description" />}
                        value={fields.description}
                        onChange={(e) => setField("description", e.target.value)}
                        fullWidth
                    />
                </ListItem>

                <ListItem>
                    <ListItemText primary={<LocalizeText tag="community" />} />
                </ListItem>
                {productCommunitiesPagination.results.length > 0 && (
                    <ListItem>
                        <TextField
                            label={<LocalizeText tag="community" />}
                            onChange={(e) => setField("community", e.target.value)}
                            select
                            fullWidth
                            required
                            value={fields.community}
                        >
                            {productCommunitiesPagination.results.map((community) => (
                                <MenuItem key={community.uuid} value={community.uuid}>
                                    {community.title}
                                </MenuItem>
                            ))}
                        </TextField>
                    </ListItem>
                )}

                <ListItem>
                    <TextField
                        label={<LocalizeText tag="category" />}
                        onChange={(e) => setField("category", e.target.value)}
                        select
                        fullWidth
                        required
                        value={fields.category}
                    >
                        {productCategories.map((category) => (
                            <MenuItem key={category} value={category}>
                                {category}
                            </MenuItem>
                        ))}
                    </TextField>
                </ListItem>

                <Collapse in={fields.category === "arena_office"}>
                    <ListItem>
                        <TextField
                            label={<LocalizeText tag="slots" />}
                            value={fields.slots}
                            onChange={(e) => setField("slots", e.target.value)}
                            fullWidth
                            type="number"
                            inputProps={{ min: 0 }}
                            required
                            error={!slotsIsValid}
                            helperText={!slotsIsValid && <LocalizeText tag="errorAtLeastZero" />}
                        />
                    </ListItem>
                </Collapse>
                <ListItem>
                    <FormControlLabel
                        label={<LocalizeText tag="nationalAccess" />}
                        control={
                            <Checkbox
                                checked={!!fields.national_access}
                                onChange={(e) => setField("national_access", e.target.checked)}
                            />
                        }
                    />
                </ListItem>

                {fields.national_access && productCommunitiesPagination.results.length > 0 && (
                    <ListItem>
                        <MultiSelectDropdown
                            size="medium"
                            fullWidth
                            width="100%"
                            noSelectionTag="nationalCommunities"
                            items={items}
                            selectedIds={fields.communities}
                            onToggle={onCommunitiesChange}
                        />
                    </ListItem>
                )}

                <ListItem>
                    <ListItemText primary={<LocalizeText tag="price" />} />
                </ListItem>
                <ListItem>
                    <TextField
                        label={
                            <>
                                <LocalizeText tag="price" /> <LocalizeText tag="excludingVat" />
                            </>
                        }
                        value={fields.price}
                        onChange={(e) => setField("price", e.target.value)}
                        fullWidth
                        type="number"
                        inputProps={{ min: 0 }}
                        InputProps={{ endAdornment: <InputAdornment position="end">kr</InputAdornment> }}
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        label={<LocalizeText tag="vat" />}
                        value={fields.vat_proportion}
                        onChange={(e) => setField("vat_proportion", e.target.value)}
                        fullWidth
                        type="number"
                        inputProps={{ min: 0 }}
                        InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                    />
                </ListItem>

                <ListItem>
                    <ListItemText primary={<LocalizeText tag="periods" />} />
                </ListItem>
                <ListItem sx={{ gap: "5px" }}>
                    <TextField
                        label={<LocalizeText tag="bindingPeriod" />}
                        value={fields.binding_period}
                        onChange={(e) => setField("binding_period", e.target.value)}
                        fullWidth
                        type="number"
                        inputProps={{ min: 0 }}
                    />
                    <UnitSelection
                        value={fields.binding_period_value_type}
                        onChange={(newValue) => setField("binding_period_value_type", newValue)}
                    />
                </ListItem>

                <ListItem sx={{ gap: "5px" }}>
                    <TextField
                        label={<LocalizeText tag="trialPeriod" />}
                        value={fields.trial_period}
                        onChange={(e) => setField("trial_period", e.target.value)}
                        fullWidth
                        type="number"
                        inputProps={{ min: 0 }}
                    />
                    <UnitSelection
                        value={fields.trial_period_value_type}
                        onChange={(newValue) => setField("trial_period_value_type", newValue)}
                    />
                </ListItem>

                <ListItem sx={{ gap: "5px" }}>
                    <TextField
                        label={<LocalizeText tag="noticePeriod" />}
                        value={fields.notice_period}
                        onChange={(e) => setField("notice_period", e.target.value)}
                        fullWidth
                        type="number"
                        inputProps={{ min: 0 }}
                    />
                    <UnitSelection
                        value={fields.notice_period_value_type}
                        onChange={(newValue) => setField("notice_period_value_type", newValue)}
                    />
                </ListItem>

                <ListItem>
                    <ListItemText primary={<LocalizeText tag="accounts" />} />
                </ListItem>
                {accounts && accounts.length > 0 ? (
                    <>
                        <ListItem>
                            <TextField
                                label={<LocalizeText tag="revenueAccount" />}
                                value={fields.revenue_account}
                                onChange={(e) => setField("revenue_account", e.target.value)}
                                fullWidth
                                required
                                select
                            >
                                {revenueAccounts?.map((account) => {
                                    return (
                                        <MenuItem key={account.uuid} value={account.uuid}>
                                            {account.account}: {account.description}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </ListItem>
                        <ListItem>
                            <TextField
                                label={<LocalizeText tag="vatAccount" />}
                                value={fields.vat_account}
                                onChange={(e) => setField("vat_account", e.target.value)}
                                fullWidth
                                required
                                select
                            >
                                {vatAccounts?.map((account) => {
                                    return (
                                        <MenuItem key={account.uuid} value={account.uuid}>
                                            {account.account}: {account.description}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </ListItem>
                    </>
                ) : null}

                {serviceSections && serviceSections.length > 0 && (
                    <ListItem>
                        <TextField
                            label={<LocalizeText tag="serviceSection" />}
                            value={fields.service_section}
                            onChange={(e) => setField("service_section", e.target.value)}
                            fullWidth
                            select
                            helperText={<LocalizeText tag="mustBeUnique" />}
                        >
                            {serviceSections.map((serviceSection) => {
                                return (
                                    <MenuItem key={serviceSection.uuid} value={serviceSection.uuid}>
                                        {serviceSection.name}
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                    </ListItem>
                )}

                <ListItem>
                    <Grid container flexDirection="column" gap="5px">
                        {missingData ? (
                            <LocalizeText tag="errorEmptyFields" styling={{ color: colors.errorRed }} />
                        ) : null}
                        <VKButton
                            tag="save"
                            variant="contained"
                            disabled={missingData || isFetchingProduct}
                            onClick={onCreate}
                            isLoading={isCreatingProduct}
                        />
                    </Grid>
                </ListItem>
            </List>
        </Box>
    );
};

export default CreateProduct;
