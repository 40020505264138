import React from "react";
import { Typography } from "@mui/material";
import { IBuildingResponsibility } from "reducers/realestates";
import { LocalizeText } from "./localizer";
import DimmedTypoGraphy from "./dimmedTypography";

interface IContactPersonProps {
    responsible: IBuildingResponsibility;
}

const ContactPerson: React.FC<IContactPersonProps> = ({ responsible }) => {
    if (responsible && !Object.keys(responsible).length) {
        return null;
    }
    return (
        <div>
            <Typography variant="body2">{responsible.role_name || <LocalizeText tag="propertyManager" />}</Typography>
            <DimmedTypoGraphy variant="body2">{responsible.agent_person.full_name}</DimmedTypoGraphy>
            {responsible.agent_person.direct_phone && <DimmedTypoGraphy variant="body2">{responsible.agent_person.direct_phone}</DimmedTypoGraphy>}
            {responsible.agent_person.email && <DimmedTypoGraphy variant="body2">{responsible.agent_person.email}</DimmedTypoGraphy>}
        </div>
    );
};

export default ContactPerson;
