import React from "react";
import TableComponent, { IRow } from "components/tableComponent";
import { THeadCell } from "utils/types";
import { LocalizeText } from "components/localizer";
import { prettifyPhoneNumber } from "utils/helpers";
import Banner from "components/banner";
import { Typography, ButtonBase } from "@mui/material";
import { Email } from "@mui/icons-material";
import SelectLeaseDialog from "components/dialogs/selectLeaseDialog";
import SendLeaseQuestion from "components/dialogs/sendLeaseQuestionDialog";
import { ModalContext } from "contexts/modals";
import TopBar from "components/tableComponents/topBar";
import { usePermission } from "utils/hooks/usePermission";

const headCells: THeadCell[] = [
    { id: "city", label: <LocalizeText tag="city" /> },
    { id: "phone", label: <LocalizeText tag="phoneNumber" /> },
    { id: "email", label: <LocalizeText tag="email" /> },
];

interface IContact {
    region: string;
    phone: string;
    email: string;
}

const ContactUsView: React.FC = () => {
    const { popDialog, pushDialog } = React.useContext(ModalContext);
    const { hasPermission } = usePermission();
    const data = [
        { region: "Göteborg", phone: "0317434252", email: "kundservice@vasakronan.se" },
        { region: "Malmö", phone: "020808182", email: "kundservice@vasakronan.se" },
        { region: "Stockholm", phone: "0856620600", email: "kundservice@vasakronan.se" },
        { region: "Uppsala", phone: "0184892040", email: "kundservice@vasakronan.se" },
    ];

    const mapRows = (contacts: IContact[]): IRow[] => {
        return contacts.map((contact) => ({
            data: [contact.region, prettifyPhoneNumber(contact.phone), contact.email],
        }));
    };

    const onSelectLease = (lease_uuid: string): void => {
        popDialog();
        pushDialog(SendLeaseQuestion, { lease_uuid: lease_uuid });
    };

    return (
        <div className="contentWrapper">
            <Banner
                title="customerService"
                subTitle="bannerCustomerService"
                image={`${process.env.PUBLIC_URL}/assets/images/1024x683/customerService-1024x683.jpg`}
            />
            <TopBar
                singleItem={
                    hasPermission("send_mail_question") && (
                        <Typography
                            variant="body2"
                            paragraph
                            component={ButtonBase}
                            onClick={() => pushDialog(SelectLeaseDialog, { onSubmit: onSelectLease })}
                        >
                            <Email color="primary" />
                            <LocalizeText tag="contactVasakronan" />
                        </Typography>
                    )
                }
            />
            <TableComponent headCells={headCells} rows={mapRows(data)} />
        </div>
    );
};

export default ContactUsView;
