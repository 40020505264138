import React, { useEffect } from "react";
import { LocalizeText } from "components/localizer";
import { IMembership } from "reducers/memberships";
import { ListItemText, TextField } from "@mui/material";
import ListInlineEditWrapper from "../listComponents/listInlineEditWrapper";
import { usePermission } from "utils/hooks/usePermission";

interface IProps {
    membership: IMembership;
    onSubmitUpdate: (props: Record<string, unknown>) => void;
    isLegalCompany?: boolean;
}

const OrganizationNameField: React.FC<IProps> = ({ membership, onSubmitUpdate, isLegalCompany = false }) => {
    const [editingName, setEditingName] = React.useState(
        isLegalCompany ? membership?.organization.company_name : membership?.organization.name ?? "-"
    );

    useEffect(() => {
        setEditingName(isLegalCompany ? membership?.organization.company_name : membership?.organization.name ?? "-");
    }, [isLegalCompany, membership]);

    const { hasPermission } = usePermission();

    return (
        <ListInlineEditWrapper
            onChange={(e) => setEditingName(e.target.value)}
            onSubmit={() =>
                onSubmitUpdate(
                    isLegalCompany
                        ? { organization: { company_name: editingName } }
                        : { organization: { name: editingName } }
                )
            }
            label={<LocalizeText tag={isLegalCompany ? "legalCompanyName" : "commercialCompanyName"} />}
            renderInput={(props) => <TextField {...props} value={editingName} />}
            renderView={(props) => (
                <ListItemText
                    {...props}
                    secondary={
                        isLegalCompany
                            ? membership?.organization.company_name ?? "-"
                            : membership?.organization.name ?? "-"
                    }
                />
            )}
            inputIsValid={editingName.length > 0}
            editDisabled={!hasPermission("change_membership") || !!membership.end_date || membership.is_archived}
        />
    );
};

export default OrganizationNameField;
