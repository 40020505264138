import React from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material";

const AbsoluteCloseButton = styled(IconButton)(() => ({
    position: "absolute",
    right: 0,
    top: 0,
    margin: "0.5rem",
}));

interface IDialogProps {
    handleClose: () => void;
    open: boolean;
    children?: React.ReactNode;
}

const DialogBase: React.FC<IDialogProps> = ({ handleClose, open, children }) => {
    return (
        <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth>
            <AbsoluteCloseButton onClick={handleClose} size="large">
                <CloseIcon />
            </AbsoluteCloseButton>
            {children}
        </Dialog>
    );
};

export default DialogBase;
