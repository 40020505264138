import React, { FC, useContext, useEffect, useState, useCallback } from "react";
import { Grid, Typography, ListItem, ListItemText, TextField, MenuItem } from "@mui/material";
import { LocalizeText } from "components/localizer";
import VKButton from "components/vkButton";
import { LanguageContext } from "contexts/language";
import { MembershipsContext } from "contexts/memberships";
import { IAlternativeInvoiceRecipient } from "reducers/memberships";
import { getCountryCodeString } from "utils/countryCodes";
import validator from "utils/validator";
import colors from "styles/colors";
import ConfirmDialog from "components/confirmDialog";

interface IProps {
    onCreate: (recipient: IAlternativeInvoiceRecipient) => void;
    onCancel: () => void;
}
export const CreateNewRecipient: FC<IProps> = ({ onCreate, onCancel }) => {
    const { currentLanguage } = useContext(LanguageContext);
    const [confirmingChoice, setConfirmingChoice] = useState(false);
    const {
        fetchCountries,
        countries,
        isFetchingCountries,
        createAlternativeRecipient,
        isCreatingAlternativeRecipient,
    } = useContext(MembershipsContext);

    useEffect(() => {
        if (countries?.length === 0 && !isFetchingCountries) {
            fetchCountries();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [fields, setFields] = useState({
        org_name: "",
        company_name: "",
        country_code: "",
        org_nr: "",
        postal_address1: "",
        postal_address2: "",
        postal_postcode: "",
        postal_city: "",
        selected_invoicing_method: "pdf",
        pdf_address: "",
        peppol_address: "",
        user_invoice_reference: "",
    });

    const setField = (prop: string, value: string): void => {
        setFields({
            ...fields,
            [prop]: value,
        });
    };

    const pdfEmailIsValid = validator.email(fields.pdf_address, true);
    const peppolIsValid = validator.peppol(fields.peppol_address, true);

    const missingData = Object.entries(fields).some(([prop, value]) => {
        switch (prop) {
            case "postal_address2":
            case "pdf_address":
            case "peppol_address":
                return false;

            case "selected_invoicing_method":
                return value === "pdf"
                    ? !validator.email(fields.pdf_address)
                    : !validator.peppol(fields.peppol_address);

            default:
                return !validator.textFieldNotEmpty(value);
        }
    });

    const onConfirm = useCallback(async () => {
        const {
            pdf_address,
            peppol_address,
            selected_invoicing_method,
            org_name,
            company_name,
            org_nr,
            country_code,
            ...restFields
        } = fields;
        const data = {
            ...restFields,
            selected_invoicing_method,
            organization: { name: org_name, company_name, org_nr, country_code },
        };
        if (selected_invoicing_method === "pdf") {
            data["pdf_address"] = pdf_address;
        } else {
            data["peppol_address"] = peppol_address;
        }

        const recipient = await createAlternativeRecipient(data);

        onCreate(recipient);
    }, [createAlternativeRecipient, fields, onCreate]);

    return (
        <>
            <Grid container justifyContent="space-between" alignItems="center">
                <Typography fontSize="1.5rem">
                    <LocalizeText tag="createNew" />
                </Typography>
                <VKButton tag="cancel" template="cancel" onClick={onCancel} />
            </Grid>
            <ListItem>
                <ListItemText primary={<LocalizeText tag="organization" />} />
            </ListItem>
            <ListItem>
                <TextField
                    label={<LocalizeText tag="commercialCompanyName" />}
                    value={fields.org_name}
                    onChange={(e) => setField("org_name", e.target.value)}
                    fullWidth
                    required
                />
            </ListItem>
            <ListItem>
                <TextField
                    label={<LocalizeText tag="legalCompanyName" />}
                    value={fields.company_name}
                    onChange={(e) => setField("company_name", e.target.value)}
                    fullWidth
                    required
                />
            </ListItem>
            <ListItem>
                <TextField
                    label={<LocalizeText tag="country" />}
                    onChange={(e) => setField("country_code", e.target.value)}
                    fullWidth
                    select
                    required
                    value={fields.country_code}
                >
                    {countries?.map((country) => (
                        <MenuItem key={country.uuid} value={country.uuid}>
                            {getCountryCodeString(country, currentLanguage)}
                        </MenuItem>
                    ))}
                </TextField>
            </ListItem>
            <ListItem>
                <TextField
                    label={<LocalizeText tag="orgNumber" />}
                    value={fields.org_nr}
                    onChange={(e) => setField("org_nr", e.target.value)}
                    fullWidth
                    required
                />
            </ListItem>

            <ListItem>
                <ListItemText primary={<LocalizeText tag="invoiceAddress" />} />
            </ListItem>
            <ListItem>
                <TextField
                    label={<LocalizeText tag="addressLine1" />}
                    value={fields.postal_address1}
                    onChange={(e) => setField("postal_address1", e.target.value)}
                    fullWidth
                    required
                />
            </ListItem>
            <ListItem>
                <TextField
                    label={<LocalizeText tag="addressLine2" />}
                    value={fields.postal_address2}
                    onChange={(e) => setField("postal_address2", e.target.value)}
                    fullWidth
                />
            </ListItem>
            <ListItem>
                <TextField
                    label={<LocalizeText tag="zipCode" />}
                    value={fields.postal_postcode}
                    onChange={(e) => setField("postal_postcode", e.target.value)}
                    fullWidth
                    required
                />
            </ListItem>
            <ListItem>
                <TextField
                    label={<LocalizeText tag="town" />}
                    value={fields.postal_city}
                    onChange={(e) => setField("postal_city", e.target.value)}
                    fullWidth
                    required
                />
            </ListItem>

            <ListItem>
                <ListItemText primary={<LocalizeText tag="paymentInformation" />} />
            </ListItem>
            <ListItem>
                <TextField
                    label={<LocalizeText tag="paymentMethod" />}
                    onChange={(e) => setField("selected_invoicing_method", e.target.value)}
                    select
                    fullWidth
                    value={fields.selected_invoicing_method}
                >
                    <MenuItem value="peppol">
                        <LocalizeText tag="paymentMethodPeppol" />
                    </MenuItem>
                    <MenuItem value="pdf">
                        <LocalizeText tag="paymentMethodPDF" />
                    </MenuItem>
                </TextField>
            </ListItem>
            <ListItem>
                {fields.selected_invoicing_method === "pdf" ? (
                    <TextField
                        label={<LocalizeText tag="email" />}
                        value={fields.pdf_address}
                        onChange={(e) => setField("pdf_address", e.target.value)}
                        fullWidth
                        required
                        error={!pdfEmailIsValid}
                        helperText={!pdfEmailIsValid && <LocalizeText tag="errorEmail" />}
                    />
                ) : (
                    <TextField
                        label={<LocalizeText tag="peppolID" />}
                        value={fields.peppol_address}
                        onChange={(e) => setField("peppol_address", e.target.value)}
                        fullWidth
                        required
                        error={!peppolIsValid}
                        helperText={!peppolIsValid && <LocalizeText tag="errorPeppol" />}
                    />
                )}
            </ListItem>
            <ListItem>
                <TextField
                    label={<LocalizeText tag="invoiceReference" />}
                    value={fields.user_invoice_reference}
                    onChange={(e) => setField("user_invoice_reference", e.target.value)}
                    fullWidth
                    required
                />
            </ListItem>
            <ListItem>
                <Grid container justifyContent="space-between" gap="5px">
                    {missingData ? <LocalizeText tag="errorEmptyFields" styling={{ color: colors.errorRed }} /> : null}
                    <VKButton
                        tag="confirm"
                        template="primary"
                        disabled={missingData}
                        onClick={() => setConfirmingChoice(true)}
                        isLoading={isCreatingAlternativeRecipient}
                    />
                    <VKButton tag="cancel" template="cancel" onClick={onCancel} />
                </Grid>
            </ListItem>

            <ConfirmDialog
                open={confirmingChoice}
                title={<LocalizeText tag="confirmAlternativeRecipient" />}
                description={<LocalizeText tag="confirmAlternativeRecipientDescription" />}
                onReject={() => setConfirmingChoice(false)}
                onAccept={onConfirm}
            />
        </>
    );
};
