import React, { ChangeEvent, FC, useContext, useState } from "react";
import { Grid } from "@mui/material";
import { IAuthorization } from "reducers/auth";
import { AuthContext } from "contexts/auth";
import VKButton from "components/vkButton";
import InnerPage from "./innerPage";
import { AppIcon, SmsIcon } from "./icons";
import TextField from "@mui/material/TextField";
import If from "components/__helpers__/if";
import colors from "styles/colors";
import { TLanguageTag } from "contexts/language";

enum AuthMethod {
    SMS = "smsDevice",
    TOTP = "appDevice",
}
interface IProps {
    onSuccess: () => void;
    authorizationObject?: IAuthorization;
    phoneNumber: string;
}

const VerifyLogin: FC<IProps> = ({ onSuccess, authorizationObject, phoneNumber }) => {
    const { verifyOtpCode, sendAuthenticationSMS } = useContext(AuthContext);
    const [code, setCode] = useState("");
    const [authMethod, setAuthMethod] = useState<AuthMethod>(AuthMethod.TOTP);
    const [currentPhoneNumber, setCurrentPhoneNumber] = useState(phoneNumber);
    const isAppMethod = authMethod === AuthMethod.TOTP && authorizationObject?.hasTotpDevices;

    const onTextFieldChange = async (e: ChangeEvent<HTMLTextAreaElement>): Promise<void> => {
        const value = e.target.value;
        setCode(value);
        if (value.length === 6 || value.length === 8) {
            if (authorizationObject) {
                const success = await verifyOtpCode({
                    key: authorizationObject.key,
                    token: value,
                });

                if (success) {
                    onSuccess();
                }
            }
        }
    };

    const createAuthMethodHandler = async (): Promise<void> => {
        if (isAppMethod) {
            const number = await sendAuthenticationSMS(authorizationObject?.key);
            if (number) {
                setCurrentPhoneNumber(number);
                setAuthMethod(AuthMethod.SMS);
            }
        } else {
            setAuthMethod(AuthMethod.TOTP);
        }
    };

    const appMethod = {
        icon: <AppIcon />,
        info: "appLoginVerify" as TLanguageTag,
    };
    const smsMethod = {
        icon: <SmsIcon />,
        info: "smsLoginVerify" as TLanguageTag,
        infoReplace: { number: currentPhoneNumber },
    };
    const authMethodProps = isAppMethod ? appMethod : smsMethod;

    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            wrap="nowrap"
            sx={{
                maxWidth: "440px",
                paddingTop: "30px",
                paddingBottom: "30px",
                backgroundColor: colors.white,
            }}
        >
            <Grid
                sx={{
                    width: "100%",
                    "& svg": {
                        fill: colors.vkBlue,
                    },
                }}
                item
            >
                <InnerPage
                    page={{
                        ...authMethodProps,
                        title: "2fa",
                        customContent: (
                            <TextField
                                value={code}
                                onChange={onTextFieldChange}
                                autoFocus
                                margin="dense"
                                variant="outlined"
                                sx={{ marginBottom: "-10px" }}
                            />
                        ),
                        bottomText: "canUseOneTimeCode",
                    }}
                />
            </Grid>
            <If truthy={authorizationObject?.hasTotpDevices && authorizationObject?.hasSMSDevices}>
                <Grid item>
                    <VKButton
                        variant="text"
                        tag={isAppMethod ? "verifyWithSms" : "verifyWithApp"}
                        onClick={createAuthMethodHandler}
                    />
                </Grid>
            </If>
        </Grid>
    );
};

export default VerifyLogin;
