import React, { ReactNode, useContext, useState } from "react";
import VKButton from "components/vkButton";
import ArrowIcon from "@mui/icons-material/NavigateNext";
import { IArena } from "reducers/arenas";
import { LocalizeText } from "components/localizer";
import { ListTextField } from "components/drawers/drawerComponents";
import { formatBindingPeriod, getExpireDate } from "utils/ecommerseTypes";
import { MembershipsContext } from "contexts/memberships";
import { List, ListItem, Divider } from "@mui/material";
import PrettifyPrice from "components/prettifyPrice";
import { ListItemDiscounts } from "./listItemDiscounts";
import { ListItemWithButton } from "./subscription/listItemWithButton";
import { EditArenaStartDate } from "./editArenaStartDate";
import { ModalContext } from "contexts/modals";
import { usePermission } from "utils/hooks/usePermission";

interface IProps {
    arena: IArena;
    onSubmitUpdate: (params: Partial<IArena>) => void;
}
const ArenaInformation: React.FC<IProps> = ({ arena, onSubmitUpdate }) => {
    const [startDate, setStartDate] = useState(arena.start_date);
    const { openDrawer } = useContext(ModalContext);
    const { hasPermission } = usePermission();

    const onHandleSubmit = (newDate: string): void => {
        setStartDate(newDate);
        onSubmitUpdate({ start_date: newDate });
    };

    const getRentalPeriod = (arena: IArena): ReactNode => {
        if (!arena.end_date) {
            return <LocalizeText tag="untilFurtherNotice" />;
        }
        return (
            <span>
                <LocalizeText tag="until" />
                {". "} {getExpireDate(arena.end_date)}
            </span>
        );
    };
    const { membership } = useContext(MembershipsContext);

    return (
        <>
            <List data-testid="arenainformation-root">
                <ListTextField
                    hasData
                    label={<LocalizeText tag="memberships" />}
                    data={
                        <span>
                            <LocalizeText tag="customerNumber" /> {membership?.agreement_nr}
                        </span>
                    }
                />
                <Divider />
                <ListTextField hasData label={<LocalizeText tag="city" />} data={arena.community_city} />
                <Divider />
                <ListTextField hasData label={<LocalizeText tag="arena" />} data={arena.community_title} />
                <Divider />
                <ListTextField hasData label={<LocalizeText tag="arenaOffice" />} data={arena.office_name} />
                <Divider />
                <ListTextField hasData label={<LocalizeText tag="officeSpaces" />} data={arena.total_office_spaces} />
                <Divider />
                <ListItemWithButton
                    primary={<LocalizeText tag="moveInDate" />}
                    secondary={startDate}
                    button={{
                        onClick: () =>
                            openDrawer(
                                EditArenaStartDate,
                                { dateStart: startDate, dateEnd: arena.end_date, onHandleSubmit },
                                "editOccupancyStartDate"
                            ),
                        tag: "edit",
                        show:
                            hasPermission("can_change_officesubscription_start_date") &&
                            !arena.final_payment_made &&
                            arena.not_invoiced,
                    }}
                />
                <ListTextField
                    hasData
                    label={<LocalizeText tag="bindingPeriod" />}
                    data={formatBindingPeriod(arena.binding_period_until)}
                />
                <Divider />
                <ListTextField hasData label={<LocalizeText tag="rentalPeriod" />} data={getRentalPeriod(arena)} />
                <Divider />
                <ListTextField
                    hasData
                    label={<LocalizeText tag="rentPerMonth" />}
                    data={
                        <>
                            <PrettifyPrice amount={arena.base_cost_excl_vat} decimals={0} />{" "}
                            <LocalizeText tag="excludingVat" />
                        </>
                    }
                />
                <Divider />
                <ListItemDiscounts
                    type="offices"
                    type_id={arena.id}
                    max_amount_excl_vat={arena.base_cost_excl_vat}
                    subscription={arena}
                />
            </List>
            <List>
                <ListItem>
                    <VKButton
                        tag="openContract"
                        template="primary"
                        data-testid="arenainformation-addButton"
                        disabled={!arena.agreement_document_file}
                        onClick={() => window.open(arena.agreement_document_file)}
                        rightAddon={<ArrowIcon />}
                    />
                </ListItem>
            </List>
        </>
    );
};

export default ArenaInformation;
