import React, { FC, useContext, useState } from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ArenaTable, { TDrawer } from "components/tableComponents/arenaTable";
import ArenaContext from "contexts/arenas";
import { IArena } from "reducers/arenas";
import ArenaDrawerGroup from "components/drawers/arenaDrawerGroup";
import TopBar from "components/tableComponents/topBar";
import { LocalizeText } from "components/localizer";
import Banner from "components/banner";
import { usePaginationStore } from "utils/usePaginationStore";
import { useParams } from "react-router-dom";

const ArenaView: FC = () => {
    const { membershipId } = useParams<{ membershipId: string }>();
    const { getBariumRequestMoreArenasURL } = useContext(ArenaContext);
    const pagination = usePaginationStore("arenas", undefined, { membership_id: membershipId });

    const [selectedArena, setSelectedArena] = useState<IArena | undefined>(undefined);
    const [drawerType, setDrawerType] = useState<TDrawer | "">("");

    return (
        <div data-testid="arena-root" className="contentWrapper">
            <Banner
                title="arenaOffices"
                subTitle="bannerArenaOffices"
                image={`${process.env.PUBLIC_URL}/assets/images/1024x683/arenaOffice-1024x683.jpg`}
            />
            <TopBar
                menuItems={[
                    {
                        onClick: () => getBariumRequestMoreArenasURL(Number(membershipId)),
                        canLoad: true,
                        iconRight: <OpenInNewIcon />,
                        label: <LocalizeText tag="requestNewArenas" />,
                    },
                ]}
            />
            <ArenaTable
                onDrawerOpen={(type, arena) => {
                    setDrawerType(type);
                    setSelectedArena(arena);
                }}
                pagination={pagination}
                loading={pagination.query.isFetching}
            />
            {selectedArena && (
                <ArenaDrawerGroup
                    membershipId={Number(membershipId)}
                    drawerType={drawerType}
                    onDrawerClose={() => {
                        setDrawerType("");
                        setSelectedArena(undefined);
                    }}
                    arena={selectedArena}
                    pagination={pagination}
                />
            )}
        </div>
    );
};

export default ArenaView;
