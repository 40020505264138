import React, { FC, ReactNode, Reducer, createContext, useCallback, useContext, useMemo, useReducer } from "react";
import webAPIRequest from "api";
import reducer, { ILeases, IAction, ILease, ILeaseInvitation, initialState } from "reducers/leases";
import { NotificationContext } from "contexts/notification";
import qs from "qs";
import { INewContactObject } from "components/drawers/contactPersons/addContactPersonBase";

export const LeasesContext = createContext<ILeases>({
    ...initialState,
});

export const LeasesProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const { ...notification } = useContext(NotificationContext);
    const [currentState, dispatch] = useReducer<Reducer<ILeases, IAction>>(reducer, initialState);

    const fetchLease = useCallback(
        async (leaseUuid: string): Promise<ILease> => {
            try {
                dispatch({ type: "FETCH_LEASE" });
                const url = `/vk_data/leases/${leaseUuid}/`;
                const returnData = await webAPIRequest("get", url);
                dispatch({
                    type: "FETCH_LEASE_SUCCESS",
                    lease: returnData.data,
                });
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_fetchLeasesInfo", error);
                dispatch({ type: "FETCH_LEASE_FAILURE" });
                return {} as ILease;
            }
        },
        [notification]
    );

    const addLeaseContactPerson = useCallback(
        async (lease_uuid: string, newContact: INewContactObject): Promise<boolean> => {
            try {
                dispatch({ type: "ADD_LEASE_CONTACT_PERSON" });
                const data = {
                    lease: lease_uuid,
                    email: newContact.email,
                    phone: newContact.phone,
                    first_name: newContact.firstName,
                    last_name: newContact.lastName,
                    role: newContact.roleId,
                };
                await webAPIRequest("post", "/vk_data/lease-invitations/", {
                    data,
                    paramsSerializer: (obj: typeof data) => qs.stringify(obj, { encode: false }),
                });
                dispatch({ type: "ADD_LEASE_CONTACT_PERSON_SUCCESS" });
                notification.enqueNotification("success_addContactPerson");
                return true;
            } catch (error) {
                notification.enqueNotification("error_addContactPerson", error);
                dispatch({ type: "ADD_LEASE_CONTACT_PERSON_FAILURE" });
                return false;
            }
        },
        [notification]
    );

    const requestAccessToLease = useCallback(
        async (lease_uuid: string, requested_contact_person_email: string): Promise<boolean> => {
            try {
                dispatch({ type: "REQUEST_ACCESS" });
                const data = {
                    lease: lease_uuid,
                    requested_contact_person: requested_contact_person_email,
                };
                await webAPIRequest("post", "vk_data/lease-invitations/request/", {
                    data,
                    paramsSerializer: (obj: typeof data) => qs.stringify(obj, { encode: false }),
                });
                dispatch({ type: "REQUEST_ACCESS_SUCCESS" });
                notification.enqueNotification("success_requestingAccessToLease");
                return true;
            } catch (error) {
                notification.enqueNotification("error_requestingAccessToLease", error);
                dispatch({ type: "REQUEST_ACCESS_FAILURE" });
                return false;
            }
        },
        [notification]
    );

    const removeContactPersonAccess = useCallback(
        async (lease_uuid: string, id: number): Promise<boolean> => {
            try {
                dispatch({ type: "REMOVE_CONTACT_PERSON" });
                await webAPIRequest("delete", `/vk_data/leases/${lease_uuid}/contact-persons/${id}/`);
                dispatch({ type: "REMOVE_CONTACT_PERSON_SUCCESS" });
                notification.enqueNotification("success_removeContactPerson");
                return true;
            } catch (error) {
                notification.enqueNotification("error_removeContactPerson", error);
                dispatch({ type: "REMOVE_CONTACT_PERSON_FAILURE" });
                return false;
            }
        },
        [notification]
    );

    const editContactPerson = useCallback(
        async (lease_uuid: string, contact_person_id: number, data: Record<string, unknown>): Promise<boolean> => {
            try {
                dispatch({ type: "EDIT_LEASE_CONTACT_PERSON" });
                await webAPIRequest("patch", `/vk_data/leases/${lease_uuid}/contact-persons/${contact_person_id}/`, {
                    data,
                });
                dispatch({ type: "EDIT_LEASE_CONTACT_PERSON_SUCCESS" });
                notification.enqueNotification("success_editContactPerson");
                return true;
            } catch (error) {
                notification.enqueNotification("error_editContactPerson", error);
                dispatch({ type: "EDIT_LEASE_CONTACT_PERSON_FAILURE" });
                return false;
            }
        },
        [notification]
    );

    const acceptInvitation = useCallback(async (invitationUuid: string): Promise<boolean> => {
        try {
            dispatch({ type: "HANDLE_INVITATION" });
            await webAPIRequest("post", `/vk_data/lease-invitations/${invitationUuid}/accept/`);
            dispatch({ type: "HANDLE_INVITATION_SUCCESS" });
            return true;
        } catch (error) {
            dispatch({ type: "HANDLE_INVITATION_FAILURE" });
            return false;
        }
    }, []);

    const denyInvitation = useCallback(
        async (invitationUuid: string): Promise<boolean> => {
            try {
                dispatch({ type: "HANDLE_INVITATION" });
                await webAPIRequest("post", `/vk_data/lease-invitations/${invitationUuid}/deny/`);
                dispatch({ type: "HANDLE_INVITATION_SUCCESS" });
                notification.enqueNotification("success_denyLeaseInvitation");
                return true;
            } catch (error) {
                notification.enqueNotification("general_error");
                dispatch({ type: "HANDLE_INVITATION_FAILURE" });
                return false;
            }
        },
        [notification]
    );

    const fetchLeaseInvitation = useCallback(
        async (invitationUuid: string): Promise<ILeaseInvitation> => {
            try {
                dispatch({ type: "FETCH_INVITATION" });
                const returnData = await webAPIRequest("get", `/vk_data/lease-invitations/${invitationUuid}/`);
                dispatch({ type: "FETCH_INVITATION_SUCCESS" });
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_fetchLeaseInvitation");
                dispatch({ type: "FETCH_INVITATION_FAILURE" });
                return {} as ILeaseInvitation;
            }
        },
        [notification]
    );

    const deleteInvitation = useCallback(async (invitationUuid: string): Promise<boolean> => {
        try {
            dispatch({ type: "HANDLE_INVITATION" });
            await webAPIRequest("delete", `/vk_data/lease-invitations/${invitationUuid}/`);
            dispatch({ type: "HANDLE_INVITATION_SUCCESS" });
            return true;
        } catch (error) {
            dispatch({ type: "HANDLE_INVITATION_FAILURE" });
            return false;
        }
    }, []);

    const value = useMemo(() => {
        return {
            ...currentState,
            requestAccessToLease,
            addLeaseContactPerson,
            removeContactPersonAccess,
            editContactPerson,
            fetchLease,
            acceptInvitation,
            fetchLeaseInvitation,
            denyInvitation,
            deleteInvitation,
        };
    }, [
        currentState,
        requestAccessToLease,
        addLeaseContactPerson,
        removeContactPersonAccess,
        editContactPerson,
        fetchLease,
        acceptInvitation,
        fetchLeaseInvitation,
        denyInvitation,
        deleteInvitation,
    ]);

    return <LeasesContext.Provider value={value}>{children}</LeasesContext.Provider>;
};

export default LeasesContext;
