import React from "react";
import { CircularProgress, Grid } from "@mui/material";
import { AuthContext } from "contexts/auth";
import colors from "styles/colors";
import VKButton from "components/vkButton";
import { NotificationContext } from "contexts/notification";
import If from "components/__helpers__/if";
import { downloadFile } from "utils/helpers";

interface IProps {
    onDownload?: () => void;
}
const BackupCodes: React.FC<IProps> = ({ onDownload }) => {
    const { fetchBackupCodes, isFetchingBackupCodes, backupCodes, generateBackupCodes, isGeneratingBackupCodes } =
        React.useContext(AuthContext);
    const { ...notification } = React.useContext(NotificationContext);

    React.useEffect(() => {
        if (!backupCodes && !isFetchingBackupCodes) {
            fetchBackupCodes();
        }
        // eslint-disable-next-line
    }, []);

    const downloadCodes = (): void => {
        if (!backupCodes) {
            return;
        }
        const data = backupCodes.map((c) => `${c.token},\n`);
        const url = window.URL.createObjectURL(new Blob(data));
        downloadFile("kp_onetime_codes.txt", url);

        if (onDownload) {
            onDownload();
        }
    };

    const copyOneTimeCode = (token: string): void => {
        navigator.clipboard.writeText(token);
        notification.enqueNotification("success_copyOnetimeCode");
    };

    const generateNewCodes = async (): Promise<void> => {
        const success = await generateBackupCodes();
        success && notification.enqueNotification("success_generateBackupCodes");
    };

    if (isGeneratingBackupCodes || isFetchingBackupCodes) {
        return <CircularProgress size={70} style={{ color: colors.vkBlue }} />;
    }

    return (
        <Grid container direction="column" alignItems="center" wrap="nowrap">
            <If truthy={backupCodes && backupCodes.length > 0}>
                <Grid container justifyContent="center" sx={{ padding: "0 10px", maxWidth: "400px" }}>
                    {backupCodes.map(({ token }) => (
                        <Grid key={token} item>
                            <VKButton
                                variant="outlined"
                                sx={{
                                    margin: "4px 6px",
                                    border: `2px solid ${colors.vkBlue}`,
                                    width: 80,
                                    fontSize: "0.9rem",
                                    fontFamily: "Courier New",
                                }}
                                onClick={() => copyOneTimeCode(token)}
                            >
                                {token}
                            </VKButton>
                        </Grid>
                    ))}
                </Grid>
                <Grid container justifyContent="center" mt={10}>
                    <VKButton tag="download" onClick={downloadCodes} template="primary" />
                </Grid>
            </If>
            <If truthy={backupCodes && backupCodes.length === 0}>
                <Grid container direction="column" alignItems="center">
                    <Grid item>
                        <VKButton
                            tag="generateCodes"
                            template="primary"
                            isLoading={isGeneratingBackupCodes}
                            onClick={generateNewCodes}
                            sx={{ margin: "0 auto" }}
                        />
                    </Grid>
                </Grid>
            </If>
        </Grid>
    );
};

export default BackupCodes;
