import React from "react";
import { IBuilding } from "reducers/realestates";
import { Divider, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import { fontType, LocalizeText } from "components/localizer";
import { getCorrectBuildingImage } from "utils/buildingHelpers";
import { TLanguageTag } from "contexts/language";

export interface IBuildinDetailListItem {
    tag: TLanguageTag;
    content: string | undefined;
    symbol?: React.ReactNode;
}

interface IProps {
    building: IBuilding;
    showArenaName?: boolean;
    contactPersons?: React.ReactNode;
}
const BuildingDetails: React.FC<IProps> = ({ building, showArenaName = false, contactPersons }) => {
    const buildingToListItems = (): IBuildinDetailListItem[] => {
        return [
            {
                tag: "addresses",
                content: building?.visiting_address,
            },
            {
                tag: "builtAndRebuilt",
                content: `${building?.year_built ? building.year_built + " / " : ""}${building?.year_rebuilt || ""}`,
            },
            {
                tag: "environmentalCertification",
                content: building?.building_environment_certification,
            },
            {
                tag: "buildingOffice",
                content: building?.office_size,
                symbol: <>&#13217;</>,
            },
            {
                tag: "buildingStore",
                content: building?.store_size,
                symbol: <>&#13217;</>,
            },
            {
                tag: "architect",
                content: building?.architect,
            },
        ];
    };

    return (
        <Grid container alignItems="center" style={{ marginTop: "1em" }} spacing={4}>
            <Grid item xs={6}>
                <img
                    style={{ objectFit: "cover" }}
                    width="100%"
                    src={getCorrectBuildingImage(building).url}
                    alt="Realestate"
                />
            </Grid>
            <Grid item xs={6} data-testid="building-info-main-list">
                <List dense>
                    <ListItem key="city">
                        <ListItemText disableTypography>
                            <Typography variant="h3" style={{ fontFamily: "Vasakronan" }}>
                                {building.city}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem key="name">
                        <ListItemText disableTypography>
                            <Typography variant="h6">
                                {building.is_arena && showArenaName
                                    ? building.arena_name
                                    : building.popular_name_tenant}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                    <Divider key="divider" variant="middle"></Divider>
                    {buildingToListItems().map((item) => (
                        <ListItem key={item.tag}>
                            <ListItemText>
                                <LocalizeText type={fontType.BOLD} tag={item.tag} />: {item.content} {item.symbol}
                            </ListItemText>
                        </ListItem>
                    ))}
                </List>
            </Grid>
            {contactPersons}
        </Grid>
    );
};

export default BuildingDetails;
