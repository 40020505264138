import React, { FC, ReactNode, Reducer, createContext, useCallback, useContext, useMemo, useReducer } from "react";
import webAPIRequest from "api";
import { NotificationContext } from "contexts/notification";
import reducer, {
    IAction,
    IInvoiceEvent,
    IInvoiceEventCreationErrors,
    IInvoiceOrderLine,
    IInvoiceOrder,
    IInvoiceValidationData,
    IInvoices,
    initialState,
    IInvoiceLine,
} from "reducers/invoices";

export const InvoicesContext = createContext<IInvoices>({
    ...initialState,
});

export const InvoicesProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const { ...notification } = useContext(NotificationContext);
    const [currentState, dispatch] = useReducer<Reducer<IInvoices, IAction>>(reducer, initialState);

    const fetchInvoiceEvent = useCallback(
        async (invoiceEventId: string): Promise<IInvoiceEvent> => {
            try {
                dispatch({ type: "FETCH_INVOICE_EVENT" });
                const returnData = await webAPIRequest("get", `/invoices/events/${invoiceEventId}/`);
                dispatch({
                    type: "FETCH_INVOICE_EVENT_SUCCESS",
                    invoiceEvent: returnData.data,
                });
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_fetchInvoiceEvent", error);
                dispatch({ type: "FETCH_INVOICE_EVENT_FAILURE" });
                return {} as IInvoiceEvent;
            }
        },
        [notification]
    );

    const fetchInvoiceOrder = useCallback(
        async (invoiceOrderId: string): Promise<IInvoiceOrder> => {
            try {
                dispatch({ type: "FETCH_INVOICE_ORDER" });
                const returnData = await webAPIRequest("get", `/invoices/orders/${invoiceOrderId}/`);
                dispatch({
                    type: "FETCH_INVOICE_ORDER_SUCCESS",
                    invoiceOrder: returnData.data,
                });
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_fetchInvoiceOrder", error);
                dispatch({ type: "FETCH_INVOICE_ORDER_FAILURE" });
                return {} as IInvoiceOrder;
            }
        },
        [notification]
    );

    const retryInvoiceOrder = useCallback(async (uuid: string): Promise<void> => {
        try {
            dispatch({ type: "RETRY_INVOICE_ORDER" });
            await webAPIRequest("put", `/invoices/orders/${uuid}/retry/`);
            dispatch({
                type: "RETRY_INVOICE_ORDER_SUCCESS",
            });
            return;
        } catch (error) {
            dispatch({ type: "RETRY_INVOICE_ORDER_FAILURE" });
            return;
        }
    }, []);

    const fetchInvoiceOrderLines = useCallback(
        async (invoiceOrderId: string): Promise<IInvoiceOrderLine[]> => {
            try {
                dispatch({ type: "FETCH_INVOICE_ORDER_LINES" });
                const returnData = await webAPIRequest("get", `/invoices/orders/${invoiceOrderId}/order-lines`);
                dispatch({
                    type: "FETCH_INVOICE_ORDER_LINES_SUCCESS",
                    invoiceOrderLines: returnData.data,
                });
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_fetchInvoiceOrderLines", error);
                dispatch({ type: "FETCH_INVOICE_ORDER_LINES_FAILURE" });
                return [] as IInvoiceOrderLine[];
            }
        },
        [notification]
    );

    const fetchInvoiceLines = useCallback(
        async (invoiceId: string): Promise<IInvoiceLine[]> => {
            try {
                dispatch({ type: "FETCH_INVOICE_LINES" });
                const returnData = await webAPIRequest("get", `/invoices/${invoiceId}/lines`);
                dispatch({
                    type: "FETCH_INVOICE_LINES_SUCCESS",
                    invoiceLines: returnData.data,
                });
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_fetchInvoiceLines", error);
                dispatch({ type: "FETCH_INVOICE_LINES_FAILURE" });
                return [] as IInvoiceLine[];
            }
        },
        [notification]
    );

    const validateInvoiceEvent = useCallback(async (data: IInvoiceValidationData): Promise<boolean> => {
        try {
            dispatch({ type: "VALIDATE_INVOICE_EVENT" });
            await webAPIRequest("post", "/invoices/events/validate/", { data });
            dispatch({ type: "VALIDATE_INVOICE_EVENT_SUCCESS" });
            return true;
        } catch (error) {
            dispatch({ type: "VALIDATE_INVOICE_EVENT_FAILURE" });
            return false;
        }
    }, []);

    const createInvoiceEvent = useCallback(
        async (formData: FormData): Promise<IInvoiceEvent | IInvoiceEventCreationErrors> => {
            try {
                dispatch({ type: "CREATE_INVOICE_EVENT" });
                const returnData = await webAPIRequest("post", "/invoices/events/upload/", { data: formData });
                notification.enqueNotification("success_createInvoiceEvent");
                dispatch({ type: "CREATE_INVOICE_EVENT_SUCCESS", invoiceEvent: returnData.data });
                return returnData.data;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                dispatch({ type: "CREATE_INVOICE_EVENT_FAILURE" });
                return error.response.data as IInvoiceEventCreationErrors;
            }
        },
        [notification]
    );

    const approveInvoiceEvent = useCallback(
        async (invoiceEventId: string): Promise<string> => {
            try {
                dispatch({ type: "APPROVE_INVOICE_EVENT" });
                const returnData = await webAPIRequest("post", `/invoices/events/${invoiceEventId}/approve/`);
                notification.enqueNotification("success_approveInvoiceEvent");
                dispatch({ type: "APPROVE_INVOICE_EVENT_SUCCESS" });
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_approveInvoiceEvent", error);
                dispatch({ type: "APPROVE_INVOICE_EVENT_FAILURE" });
                return "";
            }
        },
        [notification]
    );

    const deleteInvoiceEvent = useCallback(
        async (invoiceEventId: string): Promise<void> => {
            try {
                dispatch({ type: "DELETE_INVOICE_EVENT" });
                await webAPIRequest("delete", `/invoices/events/${invoiceEventId}/`);
                dispatch({ type: "DELETE_INVOICE_EVENT_SUCCESS" });
            } catch (error) {
                notification.enqueNotification("error_deleteInvoiceEvent", error);
                dispatch({ type: "DELETE_INVOICE_EVENT_FAILURE" });
                return;
            }
        },
        [notification]
    );

    const createFromMembership = useCallback(
        async (data: Record<string, unknown>): Promise<IInvoiceOrder> => {
            try {
                dispatch({ type: "CREATE_FROM_MEMBERSHIP" });
                const returnData = await webAPIRequest("post", "/invoices/orders/from-membership/", { data });
                notification.enqueNotification("success_createFromMembership");
                dispatch({ type: "CREATE_FROM_MEMBERSHIP_SUCCESS" });
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_createFromMembership", error);
                dispatch({ type: "CREATE_FROM_MEMBERSHIP_FAILURE" });
                return {} as IInvoiceOrder;
            }
        },
        [notification]
    );

    const value = useMemo(() => {
        return {
            ...currentState,
            fetchInvoiceEvent,
            fetchInvoiceOrder,
            retryInvoiceOrder,
            fetchInvoiceOrderLines,
            fetchInvoiceLines,
            validateInvoiceEvent,
            createInvoiceEvent,
            approveInvoiceEvent,
            deleteInvoiceEvent,
            createFromMembership,
        };
    }, [
        currentState,
        fetchInvoiceEvent,
        fetchInvoiceOrder,
        retryInvoiceOrder,
        fetchInvoiceOrderLines,
        fetchInvoiceLines,
        validateInvoiceEvent,
        createInvoiceEvent,
        approveInvoiceEvent,
        deleteInvoiceEvent,
        createFromMembership,
    ]);

    return <InvoicesContext.Provider value={value}>{children}</InvoicesContext.Provider>;
};
