import React from "react";
import { DialogContent, Divider, Grid, Typography } from "@mui/material";
import { INewsArticle } from "reducers/newsArticles";
import { format, parseISO } from "date-fns";
import { dateFormats } from "utils/formats";
import { LocalizeText } from "components/localizer";
import { getArticleBuilding, getPopularNameOfBuilding } from "views/newsView";
import colors from "styles/colors";

interface INewsDialogProps {
    newsArticle: INewsArticle;
}

const NewsDialog: React.FC<INewsDialogProps> = ({ newsArticle }) => {
    const building = getArticleBuilding(newsArticle);
    return (
        <DialogContent
            sx={{
                padding: "2rem",
                "& p": {
                    marginTop: "1rem",
                },
            }}
        >
            <Typography margin={"0 !important"} variant="body1">
                {getPopularNameOfBuilding(building, newsArticle.type_value === "ArenaNews")}
            </Typography>
            <Divider />
            <Typography variant="h4"> {newsArticle.title} </Typography>
            <Typography variant="body2" dangerouslySetInnerHTML={{ __html: newsArticle.content }} />
            <Grid container justifyContent="space-between" sx={{ color: colors.vkBlue }}>
                <Typography>
                    {newsArticle.published_time && (
                        <LocalizeText
                            tag="articleFooterCreated"
                            replace={{
                                timestamp: format(parseISO(newsArticle.published_time ?? ""), dateFormats.WEBDATE),
                            }}
                        />
                    )}
                </Typography>
                <Typography>
                    {newsArticle.updated_time && (
                        <LocalizeText
                            tag="articleFooterLastUpdated"
                            replace={{
                                timestamp: format(parseISO(newsArticle.updated_time ?? ""), dateFormats.WEBDATE),
                            }}
                        />
                    )}
                </Typography>
            </Grid>
        </DialogContent>
    );
};

export default NewsDialog;
