import React from "react";
import { parseISO, format } from "date-fns";
import TableComponent, { IRow } from "components/tableComponent";
import { THeadCell } from "utils/types";
import { fontType, LocalizeText } from "components/localizer";
import { dateFormats } from "utils/formats";
import PrettifyPrice from "components/prettifyPrice";
import Banner from "components/banner";
import TopBar from "components/tableComponents/topBar";
import { Grid } from "@mui/material";
import VKButton from "components/vkButton";
import { useNavigate, useParams } from "react-router-dom";
import CollapsibleCustomerLedgerTable from "components/tableComponents/collapsibleCustomerLedgerTable";
import EmailText from "components/emailText";
import PhoneText from "components/phoneText";
import { ICustomerLedger } from "reducers/leases";
import { InvoiceQuestionsBox } from "./leaseSelectView";
import SearchField from "components/tableComponents/paginationSearchField";
import { usePaginationStore } from "utils/usePaginationStore";

export const headCells: THeadCell[] = [
    { id: "agreement_number", label: <LocalizeText tag="contractNumber" /> },
    { id: "ocr", label: <LocalizeText tag="ocr" /> },
    { id: "due_date", label: <LocalizeText tag="invoiceExpiringDate" /> },
    { id: "amount_total", label: <LocalizeText tag="priceAmount" />, alignCell: "right" },
    { id: "amount_paid", label: <LocalizeText tag="paidAmount" />, alignCell: "right" },
    { id: "lastPayDate", label: <LocalizeText tag="lastPayDate" /> },
    { id: "amount_remaining", label: <LocalizeText tag="remainingAmount" />, alignCell: "right" },
    { id: "amount_overdue", label: <LocalizeText tag="overdueAmount" />, alignCell: "right" },
];

const CustomerLedgers: React.FC = () => {
    const params = useParams<{ lease: string }>();
    const navigate = useNavigate();
    const pagination = usePaginationStore("customer-ledgers", undefined, {
        ordering: "-due_date",
        lease: params.lease,
    });

    const mapRows = (customerLedgers: ICustomerLedger[]): IRow[] => {
        return customerLedgers.map((customerLedger) => ({
            data: [
                customerLedger.agreement_number,
                customerLedger.ocr,
                format(parseISO(customerLedger.due_date ?? ""), dateFormats.WEBDATE),
                <PrettifyPrice
                    key={`${customerLedger.customer_ledger_uuid}_total`}
                    amount={parseFloat(customerLedger.amount_total)}
                    currency="SEK"
                />,
                <PrettifyPrice
                    key={`${customerLedger.customer_ledger_uuid}_paid`}
                    amount={parseFloat(customerLedger.amount_paid)}
                    currency="SEK"
                />,
                format(parseISO(customerLedger.due_date ?? ""), dateFormats.WEBDATE),
                <PrettifyPrice
                    key={`${customerLedger.customer_ledger_uuid}_remained`}
                    amount={parseFloat(customerLedger.amount_remaining)}
                    currency="SEK"
                />,
                <PrettifyPrice
                    key={`${customerLedger.customer_ledger_uuid}_overdue`}
                    amount={parseFloat(customerLedger.amount_overdue)}
                    currency="SEK"
                />,
            ],
            collapsibleContent: <CollapsibleCustomerLedgerTable customerLedger={customerLedger} />,
        }));
    };

    return (
        <div data-testid="customerLedgers-root" className="contentWrapper">
            <Banner
                title="customerLedgers"
                subTitle="bannerCustomerLedgers"
                customContent={
                    <InvoiceQuestionsBox container>
                        <Grid item xs={12}>
                            <LocalizeText type={fontType.BOLD} tag="invoiceQuestions" />
                        </Grid>
                        <Grid item xs={12}>
                            <EmailText email="ekonomi@vasakronan.se" />
                        </Grid>
                        <Grid item xs={12}>
                            <PhoneText phoneNr="08-56620500" />
                        </Grid>
                    </InvoiceQuestionsBox>
                }
                image={`${process.env.PUBLIC_URL}/assets/images/1024x683/customerLedgers-1024x683.jpg`}
            />
            <TopBar
                searchField={<SearchField pagination={pagination} tag="searchCustomerLedgers" />}
                buttons={
                    <VKButton
                        size="small"
                        template="primary"
                        variant="outlined"
                        onClick={() => navigate("/leases")}
                    >
                        <LocalizeText tag="allLeases" />
                    </VKButton>
                }
            />
            <TableComponent
                headCells={headCells}
                isLoading={pagination.query.isFetching}
                rows={mapRows(pagination.results)}
                pagination={pagination}
                collapsible
            />
        </div>
    );
};

export default CustomerLedgers;
