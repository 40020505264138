import React, { FC, useEffect, useState } from "react";
import { LocalizeText } from "components/localizer";
import { Grid, ListItemText, TextField } from "@mui/material";
import ListInlineEditWrapper from "../listComponents/listInlineEditWrapper";
import validator from "utils/validator";
import { IExternalMembership } from "reducers/externalMemberships";
import { usePermission } from "utils/hooks/usePermission";
import { IMembership } from "reducers/memberships";

interface IProps {
    membership: IExternalMembership | IMembership;
    onSubmitUpdate: (props: Record<string, unknown>) => void;
}

const ReferencePersonFields: FC<IProps> = ({ membership, onSubmitUpdate }) => {
    const { hasPermission } = usePermission();
    const [contactPersonFields, setContactPersonFields] = useState({
        name: membership.contract_contact_name || "",
        phone: membership.contract_contact_phone || "",
        email: membership.contract_contact_email || "",
    });

    const nameIsValid = validator.textFieldNotEmpty(contactPersonFields.name);
    const phoneIsValid = validator.phoneNumber(contactPersonFields.phone);
    const emailIsValid = validator.email(contactPersonFields.email);

    const isValid = nameIsValid && phoneIsValid && emailIsValid;

    useEffect(() => {
        setContactPersonFields({
            name: membership.contract_contact_name || "",
            phone: membership.contract_contact_phone || "",
            email: membership.contract_contact_email || "",
        });
    }, [membership]);

    return (
        <ListInlineEditWrapper
            onChange={(e) => {
                setContactPersonFields({ ...contactPersonFields, [e.target.name]: e.target.value });
            }}
            onSubmit={() =>
                onSubmitUpdate({
                    contract_contact_name: contactPersonFields.name,
                    contract_contact_phone: contactPersonFields.phone,
                    contract_contact_email: contactPersonFields.email,
                })
            }
            label={<LocalizeText tag="referencePerson" />}
            renderInput={(props) => (
                <Grid container gap={1}>
                    <TextField
                        {...props}
                        label={<LocalizeText tag="name" />}
                        value={contactPersonFields.name}
                        name="name"
                        error={!nameIsValid}
                    />
                    <TextField
                        {...props}
                        label={<LocalizeText tag="phoneNumber" />}
                        value={contactPersonFields.phone}
                        name="phone"
                        error={!phoneIsValid}
                        helperText={!phoneIsValid && <LocalizeText tag="errorMobile" />}
                    />
                    <TextField
                        {...props}
                        label={<LocalizeText tag="email" />}
                        value={contactPersonFields.email}
                        name="email"
                        error={!emailIsValid}
                        helperText={!emailIsValid && <LocalizeText tag="errorEmail" />}
                    />
                </Grid>
            )}
            inputIsValid={isValid}
            renderView={(props) => (
                <ListItemText
                    {...props}
                    secondary={
                        <span style={{ display: "flex", flexDirection: "column" }}>
                            <span>
                                <LocalizeText tag="name" />: {membership.contract_contact_name || "-"}
                            </span>
                            <span>
                                <LocalizeText tag="phoneNumber" />: {membership.contract_contact_phone || "-"}
                            </span>
                            <span>
                                <LocalizeText tag="email" />: {membership.contract_contact_email || "-"}
                            </span>
                        </span>
                    }
                />
            )}
            editDisabled={membership.is_archived || !hasPermission("change_membership")}
        />
    );
};

export default ReferencePersonFields;
