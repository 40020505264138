import React, { FC, ReactNode, Reducer, createContext, useCallback, useMemo, useReducer } from "react";
import { AxiosError } from "axios";
import reducer, { IAction, INotify } from "reducers/notification";
import { TNotificationTag } from "./language";

const initialState: INotify = {
    notifications: [],
    enqueNotification: () => {},
    dequeNotification: () => {},
};

export const NotificationContext = createContext<INotify>({
    ...initialState,
});

export const NotificationProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const [currentState, dispatch] = useReducer<Reducer<INotify, IAction>>(reducer, initialState);

    const enqueNotification = useCallback((tag: TNotificationTag, error?: AxiosError): boolean => {
        let useTag = tag;
        try {
            const parsedError = JSON.parse(JSON.stringify(error));
            if (parsedError.message.toLowerCase() === "network error") {
                useTag = "error_server";
            }
        } catch (error) {
            // Do nothing, just pass along
        }

        dispatch({
            type: "QUE_NOTIFICATION",
            payload: { tag: useTag, error },
        });
        return true;
    }, []);

    const dequeNotification = useCallback((uuid: number): boolean => {
        dispatch({
            type: "DEQUE_NOTIFICATION",
            payload: { uuid: uuid },
        });
        return true;
    }, []);

    const value = useMemo(() => {
        return {
            ...currentState,
            enqueNotification,
            dequeNotification,
        };
    }, [currentState, enqueNotification, dequeNotification]);

    return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};
