import React, { ReactNode } from "react";
import { LocalizeText } from "components/localizer";
import { Grid, styled } from "@mui/material";
import VKButton from "components/vkButton";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import colors from "styles/colors";
import If from "components/__helpers__/if";
import { TLanguageTag } from "contexts/language";

interface IPageProps {
    onBack?: () => void;
    onNext?: () => void;
    buttonNext?: ReactNode;
    children?: React.ReactNode;
}

export const WizardPage: React.FC<IPageProps> = ({ onBack, onNext, buttonNext, children }) => {
    const handleNext = async (e: React.FormEvent): Promise<void> => {
        e.preventDefault();
        onNext?.();
    };

    return (
        <form onSubmit={handleNext}>
            <Grid container direction="column" alignItems="center" wrap="nowrap">
                <Grid
                    item
                    sx={{
                        width: "100%",
                        "& svg": {
                            fill: colors.vkBlue,
                        },
                    }}
                >
                    {children}
                </Grid>
                <Grid container justifyContent="space-between" mt="10px">
                    <If truthy={onBack}>
                        <Grid item>
                            <VKButton variant="text" tag="back" onClick={() => onBack?.()} />
                        </Grid>
                    </If>
                    <If truthy={buttonNext}>
                        <Grid item sx={{ marginLeft: "auto" }}>
                            {buttonNext}
                        </Grid>
                    </If>
                </Grid>
            </Grid>
        </form>
    );
};

const CustomStepper = styled(Stepper)(() => ({
    backgroundColor: "transparent",
    width: "100%",
    paddingTop: 0,
}));

const CustomStep = styled(Step)(() => ({
    "& .MuiStepConnector-line": {
        borderColor: colors.vkBlue,
        borderWidth: "2px",
    },
    "& .MuiStepConnector-alternativeLabel": {
        top: "17px",
        left: "calc(-50% + 18px)",
        right: "calc(50% + 18px)",
    },
    "& .MuiStepIcon-root": {
        fill: "transparent",
        boxShadow: `0 0 0 2px ${colors.vkBlue}`,
        borderRadius: "50%",
        width: "36px",
        height: "36px",

        "& text": {
            fill: colors.vkBlue,
        },

        "&.MuiStepIcon-active, & .MuiStepIcon-completed": {
            "& circle": {
                fill: colors.vkBlue,
            },
            "& text": {
                fill: colors.white,
            },
        },

        "&.MuiStepIcon-completed": {
            fill: colors.vkBlue,
        },
    },
}));

interface IProps {
    steps: TLanguageTag[];
    step: number;
    pages: ReactNode[];
}
const Wizard: React.FC<IProps> = ({ steps, step, pages }) => {
    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            wrap="nowrap"
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                padding: "2rem 4rem",
                "@media (max-height:820px), (max-width:1024px)": {
                    padding: "2rem 2rem",
                },
                backgroundColor: colors.background,
            }}
        >
            <Grid item sx={{ width: "100%" }}>
                <CustomStepper activeStep={step} alternativeLabel>
                    {steps.map((label) => (
                        <CustomStep key={label} color={colors.vkBlue}>
                            <StepLabel>
                                <LocalizeText tag={label} />
                            </StepLabel>
                        </CustomStep>
                    ))}
                </CustomStepper>
            </Grid>

            {pages[step]}
        </Grid>
    );
};

export default Wizard;
