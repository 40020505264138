import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { ListItem, Grid, ListItemText } from "@mui/material";
import { IDiscount, TDiscountType, createDiscount, fetchDiscounts, updateDiscount } from "adapters/discountsAdapter";
import { DiscountListItem } from "components/discountListItem";
import { LocalizeText } from "components/localizer";
import { addDays, differenceInDays, parseISO } from "date-fns";
import { EditDiscountDrawer } from "./editDiscountDrawer";
import VKButton from "components/vkButton";
import { ModalContext } from "contexts/modals";
import HasPermission from "components/__helpers__/hasPermission";
import { ISubscription } from "reducers/subscriptions";

interface IProps {
    type: TDiscountType;
    type_id: number;
    max_amount_excl_vat: number;
    subscription: ISubscription;
}

export const ListItemDiscounts: FC<IProps> = ({ type, type_id, max_amount_excl_vat, subscription }) => {
    const { openDrawer, closeDrawers } = useContext(ModalContext);
    const [discounts, setDiscounts] = useState<IDiscount[]>([]);

    useEffect(() => {
        (async () => {
            const result = await fetchDiscounts(type, type_id);
            setDiscounts(result.data);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Splitting info historic and non-historic is just for sorting purposes
    const historicDiscounts: IDiscount[] = [];
    const nonHistoricDiscount: IDiscount[] = [];

    discounts.forEach((d) => {
        if (!d.discount_until) {
            nonHistoricDiscount.push(d);
        }

        const diff = differenceInDays(new Date(), parseISO(d.discount_until ?? ""));
        if (diff < 0) {
            nonHistoricDiscount.push(d);
        } else if (diff < 365) {
            historicDiscounts.push(d);
        }
    });
    historicDiscounts.sort(
        (a, b) => parseISO(b.discount_until ?? "").getTime() - parseISO(a.discount_until ?? "").getTime()
    );
    nonHistoricDiscount.sort(
        (a, b) => parseISO(a.discount_from ?? "").getTime() - parseISO(b.discount_from ?? "").getTime()
    );

    const onUpdate = useCallback(
        (discount: IDiscount, updated: Partial<IDiscount>) => {
            setDiscounts((list) => {
                const index = list.findIndex((d) => d.uuid === discount.uuid);
                return [...list.slice(0, index), { ...discount, ...updated }, ...list.slice(index + 1)];
            });
            updateDiscount(type, type_id, discount.id, updated);

            closeDrawers();
        },
        [closeDrawers, type, type_id]
    );

    const onDelete = useCallback(
        (discount: IDiscount) => {
            setDiscounts((list) => {
                const index = list.findIndex((d) => d.uuid === discount.uuid);
                list.splice(index, 1);
                return list;
            });
        },
        [setDiscounts]
    );

    return (
        <ListItem divider>
            <Grid container direction="column" flexWrap="nowrap" gap="10px">
                <Grid item container justifyContent="space-between">
                    <Grid item xs>
                        <ListItemText
                            primary={<LocalizeText tag="discountOffer" />}
                            secondary={<LocalizeText tag="fromOneYearAndForward" />}
                        />
                    </Grid>
                    <HasPermission requiredPerm="add_subscriptiondiscount">
                        <Grid item>
                            <VKButton
                                template="primary"
                                tag="createNew"
                                onClick={() =>
                                    openDrawer(
                                        EditDiscountDrawer,
                                        {
                                            subscription,
                                            discount: {
                                                id: -1,
                                                uuid: "000000",
                                                created: "",
                                                discount_from: subscription.next_invoice_date,
                                                discount_until: subscription.next_invoice_date
                                                    ? addDays(parseISO(subscription.next_invoice_date), 1).toISOString()
                                                    : null,
                                                amount_excl_vat: "0",
                                                description: "",
                                                category: "monthly",
                                                internal_note: "",
                                            },
                                            max_amount_excl_vat,
                                            onUpdate: async (data: Partial<IDiscount>) => {
                                                const created = await createDiscount(type, type_id, data);
                                                if (created) {
                                                    setDiscounts([...discounts, created.data]);
                                                }
                                                closeDrawers();
                                            },
                                            isCreatingNew: true,
                                        },
                                        "createDiscount"
                                    )
                                }
                            />
                        </Grid>
                    </HasPermission>
                </Grid>

                {nonHistoricDiscount.length === 0 && historicDiscounts.length === 0 ? "-" : null}
                {[...nonHistoricDiscount, ...historicDiscounts].map((discount) => (
                    <DiscountListItem
                        key={discount.uuid}
                        discount={discount}
                        subscription={subscription}
                        max_amount_excl_vat={max_amount_excl_vat}
                        onUpdate={(updated) => onUpdate(discount, updated)}
                        onDelete={() => onDelete(discount)}
                        type={type}
                    />
                ))}
            </Grid>
        </ListItem>
    );
};
