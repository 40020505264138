import React from "react";
import { LocalizeText } from "components/localizer";
import { IconButton, InputAdornment, Popover, TextField } from "@mui/material";
import {Search, Tune} from "@mui/icons-material";
import colors from "styles/colors";
import { IUsePaginationStore } from "utils/usePaginationStore";
interface ISearchFilterFieldProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pagination: IUsePaginationStore<any>;
    label: React.ReactNode;
    filterFields?: React.ReactNode;
}
const SearchFilterField: React.FC<ISearchFilterFieldProps> = ({ 
    pagination,
    label=<LocalizeText tag={"search"} />,
    filterFields
}) => {
    const [timeoutHandle, setTimeoutHandle] = React.useState<NodeJS.Timeout | null>(null);
    const [searchString, setSearchString] = React.useState("");
    const [filterOpen, setFilterPopover] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);

    const performSearch = (value: string): void => {
        if (timeoutHandle) {
            clearTimeout(timeoutHandle);
        }
        setTimeoutHandle(
            setTimeout(() => {
                pagination.setParam("search", value || null);
                pagination.getInitial();
            }, 400)
        );
    };

    const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        setSearchString(value);
        performSearch(value);
    };

    return (
        <>
            <TextField
                type="text"
                label={label}
                variant="outlined"
                value={searchString}
                size="small"
                sx={{width: "500px"}}
                InputProps={{
                    ref: anchorRef,
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    ),
                    endAdornment: filterFields && (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => setFilterPopover(!filterOpen)}
                                edge="end"
                            >
                                <Tune />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                onChange={handleSearchInput}
            />
            <Popover
                id={"searchFilter"}
                open={filterOpen}
                anchorEl={anchorRef.current}
                onClose={() => setFilterPopover(false)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                PaperProps={{
                    sx: {
                        minHeight: "300px",
                        width: "500px",
                        borderStyle: "solid",
                        borderWidth: "0px 2px 2px 2px",
                        borderColor: colors.vkBlue,
                        padding: "1em"
                    }
                }}
            >
                {filterFields} 
            </Popover>
        </>
    );
};
export default SearchFilterField;
