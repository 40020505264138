import React from "react";
import RoleManagementMembershipTable from "../tables/roleManagementMembershipTable";
import RoleManagementLeaseTable from "../tables/roleManagementLeaseTable";
import { ProfileContext } from "contexts/profile";
import { TParamValue } from "utils/paginationStore";

interface IMyContractsTabProps {
    showTenant: boolean;
    showArenaMember: boolean;
}

const MyContractsTab: React.FC<IMyContractsTabProps> = ({ showArenaMember, showTenant }) => {
    const { selectedOrganization, user } = React.useContext(ProfileContext);

    const leaseParams: Record<string, TParamValue> = {
        org_nr: selectedOrganization.org_nr,
        by_user_email: user?.email ?? "",
    };

    const membershipParams: Record<string, TParamValue> = {
        org_nr: selectedOrganization.org_nr,
    };

    return (
        <>
            {showArenaMember && <RoleManagementMembershipTable paginationParams={membershipParams} />}
            {showTenant && (
                <RoleManagementLeaseTable disableNonActive={false} showSearch paginationParams={leaseParams} />
            )}
        </>
    );
};

export default MyContractsTab;
