import React, { FC, useContext, useState } from "react";
import {
    Box,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    MenuItem,
    TextField,
} from "@mui/material";
import { RealestatesContext } from "contexts/realestates";
import { ProfileContext } from "contexts/profile";
import VKButton from "components/vkButton";
import { LocalizeText } from "components/localizer";
import { CenteredLoader } from "components/centeredLoader";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import colors from "styles/colors";
import validator from "utils/validator";
import { ModalContext } from "contexts/modals";
import FileUploadField from "components/fileUpload";
import { useFeatureToggle } from "utils/hooks/useFeatureToggle";
import { usePaginationStore } from "utils/usePaginationStore";

type TaskType = "" | "building" | "premise";

interface IFormState {
    uuid: string;
    description: string;
    sendCopyToMail: boolean;
    files: File[];
}

const initialState: IFormState = {
    description: "",
    uuid: "",
    sendCopyToMail: true,
    files: [],
};

const CreateTaskForm: FC = () => {
    const { popDialog } = useContext(ModalContext);
    const [taskType, setTaskType] = useState<TaskType>("premise");
    const [newTaskForm, setNewTaskForm] = useState(initialState);
    const [newTaskCreated, setNewTaskCreated] = useState(false);
    const isActive = useFeatureToggle();
    const { submitNewTask, isSubmitingTask } = useContext(RealestatesContext);
    const { selectedOrganization } = useContext(ProfileContext);

    const buildingPagination = usePaginationStore("buildings", undefined, { org_nr: selectedOrganization.org_nr });
    const premisePagination = usePaginationStore("premises", undefined, { org_nr: selectedOrganization.org_nr });

    const mapTaskAddress = (): JSX.Element[] => {
        switch (taskType) {
            case "building":
                return buildingPagination.results.map((building) => {
                    return (
                        <MenuItem key={building.building_uuid} value={building.building_uuid}>
                            {building.popular_name_tenant}
                        </MenuItem>
                    );
                });
            case "premise":
                return premisePagination.results.map((premise) => {
                    return (
                        <MenuItem key={premise.premise_uuid} value={premise.premise_uuid}>
                            {`${premise.address} (${premise.premise_group_name}, ${premise.agreement_number})`}
                        </MenuItem>
                    );
                });
            default:
                return [];
        }
    };

    const getNewTaskData = (): FormData => {
        const taskData = new FormData();

        taskData.append("description", newTaskForm.description);
        taskData.append("leasee_name", selectedOrganization.name);
        taskData.append("organization_number", selectedOrganization.org_nr);
        taskData.append("send_copy", newTaskForm.sendCopyToMail.toString());
        newTaskForm.files.forEach((file) => {
            taskData.append("files", file);
        });

        switch (taskType) {
            case "building": {
                const buildingOfTask = buildingPagination.results.find((b) => b.building_uuid === newTaskForm.uuid);
                if (!buildingOfTask) {
                    break;
                }
                taskData.append("structure_id", buildingOfTask.dedu_structure_id?.toString() ?? "0");
                taskData.append("popular_name_building", buildingOfTask.popular_name_tenant);
                taskData.append("cadastral_name", buildingOfTask.cadastral_name);
                taskData.append("city", buildingOfTask.city);
                taskData.append("address", buildingOfTask.visiting_address);
                break;
            }
            case "premise": {
                const premiseOfTask = premisePagination.results.find((p) => p.premise_uuid === newTaskForm.uuid);
                if (!premiseOfTask) {
                    break;
                }
                taskData.append("structure_id", premiseOfTask.dedu_structure_id?.toString() ?? "0");
                taskData.append("popular_name_building", premiseOfTask.popular_name_tenant);
                taskData.append("cadastral_name", premiseOfTask.cadastral_name);
                taskData.append("city", premiseOfTask.city);
                taskData.append("address", premiseOfTask.address);
                taskData.append("agreement_nr", premiseOfTask.agreement_number);
                break;
            }
            default:
                break;
        }
        return taskData;
    };

    const onSubmit = async (): Promise<void> => {
        const newTaskData = getNewTaskData();
        const success = await submitNewTask(newTaskData);
        if (success) {
            setNewTaskCreated(true);
        }
    };

    const onChangeTaskType = (newType: TaskType): void => {
        setTaskType(newType);
        setNewTaskForm(initialState);
    };

    const renderDialogContent = (): JSX.Element => {
        if (isSubmitingTask) {
            return <CenteredLoader size={32} />;
        }
        if (newTaskCreated) {
            return (
                <Grid container justifyContent="center" alignItems="center">
                    <DoneOutlineIcon
                        sx={{
                            fontSize: "100px",
                            color: colors.vkBlue,
                        }}
                    />
                    <LocalizeText tag="taskCreatedDescription" />
                </Grid>
            );
        }
        return (
            <>
                <TextField
                    onChange={(event) => onChangeTaskType(event.target.value as TaskType)}
                    sx={{
                        margin: "4px 0",
                        padding: "4px 0",
                    }}
                    variant="outlined"
                    select
                    fullWidth
                    value={taskType}
                    label={<LocalizeText tag="taskTypeSelect" />}
                >
                    <MenuItem key="building" value={"building"}>
                        <LocalizeText tag="building" />
                    </MenuItem>
                    <MenuItem key="premise" value={"premise"}>
                        <LocalizeText tag="premise" />
                    </MenuItem>
                </TextField>
                <TextField
                    onChange={(event) => setNewTaskForm({ ...newTaskForm, uuid: event.target.value })}
                    sx={{
                        margin: "4px 0",
                        padding: "4px 0",
                    }}
                    variant="outlined"
                    select
                    fullWidth
                    required
                    value={newTaskForm.uuid}
                    disabled={!taskType}
                    label={<LocalizeText tag="whichAddress" />}
                >
                    {mapTaskAddress()}
                </TextField>
                {isActive("task-files") && (
                    <Box sx={{ margin: "4px 0", padding: "4px 0" }}>
                        <FileUploadField
                            onFileUploadChange={(files) => setNewTaskForm({ ...newTaskForm, files: files })}
                            files={newTaskForm.files}
                            accept=".png,.jpeg,.jpg,.pdf,.doc,.docx"
                        />
                    </Box>
                )}
                <TextField
                    name="description"
                    required
                    sx={{
                        margin: "4px 0",
                        padding: "4px 0",
                    }}
                    value={newTaskForm.description}
                    disabled={!taskType}
                    variant="outlined"
                    onChange={(event) => setNewTaskForm({ ...newTaskForm, description: event.target.value })}
                    multiline
                    fullWidth
                    minRows={4}
                    label={<LocalizeText tag="describeTask" />}
                    error={!validator.textFieldLength(newTaskForm.description, 2500)}
                    helperText={
                        <>
                            <LocalizeText
                                tag="numberOfMaxChars"
                                replace={{
                                    number: `${newTaskForm.description.length}`,
                                    max: "2500",
                                }}
                            />
                            . <LocalizeText tag="describeTaskExplanation" />
                        </>
                    }
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={newTaskForm.sendCopyToMail}
                            onChange={(event) =>
                                setNewTaskForm({ ...newTaskForm, sendCopyToMail: event.target.checked })
                            }
                            name="sendCopyToMail"
                        />
                    }
                    label={<LocalizeText tag="sendCopyToMail" />}
                />
            </>
        );
    };

    return (
        <div style={{ minWidth: "600px", minHeight: "300px" }}>
            <DialogTitle>
                <LocalizeText tag={`${newTaskCreated ? "taskCreated" : "createTask"}`} />
            </DialogTitle>
            <Divider />
            <DialogContent>{renderDialogContent()}</DialogContent>
            <DialogActions>
                {!newTaskCreated && !isSubmitingTask && (
                    <>
                        <VKButton data-testid="create-task-close" tag="close" template="cancel" onClick={popDialog} />
                        <VKButton
                            data-testid="create-task-send"
                            tag="send"
                            template="primary"
                            disabled={newTaskForm.uuid.length < 1 || newTaskForm.description.length < 1}
                            onClick={() => onSubmit()}
                        />
                    </>
                )}
                {newTaskCreated && (
                    <VKButton data-testid="create-task-close" tag="ok" template="primary" onClick={popDialog} />
                )}
            </DialogActions>
        </div>
    );
};

export default CreateTaskForm;
