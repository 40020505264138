import React from "react";
import TopBar from "components/tableComponents/topBar";
import Banner from "components/banner";
import CommunityContext from "contexts/community";
import CostCalculationTable from "components/tableComponents/costCalculationTable";
import SearchField from "components/tableComponents/paginationSearchField";
import VKButton from "components/vkButton";
import { usePermission } from "utils/hooks/usePermission";
import { ModalContext } from "contexts/modals";
import CreateCostCalculationRunDialog from "components/dialogs/createCostCalculationDialog";

const CostCalculationRunsView: React.FC = () => {
    const { costCalculationPagination, isFetchingCostCalculations } = React.useContext(CommunityContext);
    const { pushDialog } = React.useContext(ModalContext);
    const { hasPermission } = usePermission();

    React.useEffect(() => {
        costCalculationPagination.setParam("ordering", "-created");
        costCalculationPagination.getInitial();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="contentWrapper">
            <Banner
                title="bannerCostCalculationsTitle"
                subTitle="bannerCostCalculations"
                image={`${process.env.PUBLIC_URL}/assets/images/1024x683/memberships-1024x683.jpg`}
            />
            <TopBar
                searchField={<SearchField pagination={costCalculationPagination} />}
                singleItem={
                    hasPermission("add_costcalculationrun") && (
                        <VKButton
                            size="small"
                            variant="outlined"
                            tag="newCostCalculation"
                            onClick={() => pushDialog(CreateCostCalculationRunDialog, {})}
                        />
                    )
                }
            />
            <CostCalculationTable pagination={costCalculationPagination} loading={isFetchingCostCalculations} />
        </div>
    );
};

export default CostCalculationRunsView;
