import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { LocalizeText } from "components/localizer";
import { Grid, Typography } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import VKButton from "components/vkButton";
import { LanguageContext } from "contexts/language";
import { StatsBarGraph } from "components/statistics/statsBarGraph";
import {
    calcKeysAndLabelsDaily,
    calcMonthKey,
    getDailySubscriptionUsage,
    getDatasetsMembership,
    ProcessedSubscriptionUsageData,
    TCommunityUuid,
    TMonthKey,
    tooltipCallback,
} from "components/statistics/statsUtils";
import { IMembership } from "reducers/memberships";
import { addMonths, differenceInMonths, format } from "date-fns";
import { getMonth } from "utils/helpers";
import { TCommunity } from "utils/ecommerseTypes";
import { useNavigate, useParams } from "react-router-dom";
import { dateFormats } from "utils/formats";
import CommunityContext from "contexts/community";

interface IProps {
    membership: IMembership;
}

export const MembershipStats: FC<IProps> = ({ membership }) => {
    const { localize, currentLanguage } = useContext(LanguageContext);
    const { fetchCommunity } = useContext(CommunityContext);
    const [initialized, setInitialized] = useState(false);
    const [fetchedMonths, setFetchedMonths] = useState<
        Record<TMonthKey, Record<TCommunityUuid, ProcessedSubscriptionUsageData[]>>
    >({});
    const [isFetchingMonths, setIsFetchingMonths] = useState<Record<TMonthKey, boolean>>({});
    const [monthOffset, setMonthOffset] = useState(0);
    const [labels, setLabels] = useState<string[]>([]);
    const [communities, setCommunities] = useState<Record<string, TCommunity>>({});

    const navigate = useNavigate();
    const params = useParams<{ period: string }>();

    const fetchNewCommunities = useCallback(
        async (newData: Record<TCommunityUuid, ProcessedSubscriptionUsageData[]>): Promise<void> => {
            const newCommunities: Record<string, TCommunity> = {};
            await Promise.all(
                Object.keys(newData).map(async (communityUuid) => {
                    if (communityUuid in communities) {
                        return;
                    }
                    newCommunities[communityUuid] = await fetchCommunity(communityUuid);
                }),
            );

            setCommunities((fetched) => {
                return { ...fetched, ...newCommunities };
            });
        },
        [fetchCommunity, communities],
    );

    const fetchMissingMonthData = useCallback(
        async (monthKey: TMonthKey) => {
            if (isFetchingMonths[monthKey]) {
                return;
            }

            setIsFetchingMonths((fetching) => {
                fetching[monthKey] = true;
                return fetching;
            });

            const newData = await getDailySubscriptionUsage(
                new Date(monthKey),
                membership.id,
                membership.default_community?.uuid || "",
            );

            fetchNewCommunities(newData);

            setIsFetchingMonths((fetching) => {
                delete fetching[monthKey];
                return fetching;
            });

            setFetchedMonths((fetched) => {
                const result = { ...fetched };
                result[monthKey] = {};
                Object.entries(newData).forEach(([communityUuid, data]) => {
                    result[monthKey][communityUuid] = data;
                });
                return result;
            });
        },
        [isFetchingMonths, membership.default_community?.uuid, fetchNewCommunities, membership.id],
    );

    useEffect(() => {
        let offset = 0;
        if (params.period) {
            const date = new Date(params.period);
            offset = differenceInMonths(date, new Date());
            setMonthOffset(offset);
        }

        const { monthKey, labels } = calcKeysAndLabelsDaily(offset);
        setLabels(labels);
        (async () => {
            await fetchMissingMonthData(monthKey);
            setInitialized(true);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.period]);

    const allDatasets = useMemo(() => {
        return getDatasetsMembership(
            calcMonthKey(addMonths(new Date(), monthOffset)),
            fetchedMonths,
            localize,
            membership.default_community?.uuid || "",
        );
    }, [fetchedMonths, localize, membership.default_community, monthOffset]);

    const updateData = useCallback(
        (monthOffset: number) => {
            const { monthKey, labels } = calcKeysAndLabelsDaily(monthOffset);
            setLabels(labels);
            fetchMissingMonthData(monthKey);
        },
        [fetchMissingMonthData],
    );

    const onChangeOffset = useCallback(
        async (dir: number): Promise<void> => {
            const newOffset = monthOffset + dir;
            setMonthOffset(newOffset);
            updateData(newOffset);
            const date = addMonths(new Date(), newOffset);
            navigate(`/staff-memberships/${membership.id}/stats/${format(date, dateFormats.YEAR_MONTH)}`);
        },
        [setMonthOffset, monthOffset, updateData, membership.id, navigate],
    );

    const labelsWithoutStars = useMemo(() => {
        return labels.map((l) => l.replace("*", ""));
    }, [labels]);

    const month = addMonths(new Date(), monthOffset);

    return (
        <Grid container direction="column" gap="20px">
            <Grid item container justifyContent="space-between" alignItems="center">
                <VKButton variant="outlined" size="small" onClick={() => onChangeOffset(-1)}>
                    <ChevronLeft />
                    <LocalizeText tag="previousMonth" />
                </VKButton>

                <Typography>
                    {getMonth(month.getMonth(), currentLanguage)}{" "}
                    {month.getFullYear() !== new Date().getFullYear() ? month.getFullYear() : ""}
                </Typography>

                <VKButton variant="outlined" size="small" onClick={() => onChangeOffset(1)}>
                    <LocalizeText tag="nextMonth" />
                    <ChevronRight />
                </VKButton>
            </Grid>
            {Object.entries(allDatasets).map(([communityUuid, datasets]) => {
                return (
                    <Grid key={communityUuid} item height="500px">
                        <StatsBarGraph
                            titleTag="subscriptionUsageGraphTitle"
                            subtitle={
                                <>
                                    <LocalizeText tag="arena" />: {communities[communityUuid]?.title || "-"}
                                </>
                            }
                            infoText="subscriptionUsageGraphExplanation"
                            data={{ datasets, labels: labelsWithoutStars }}
                            showSkeleton={!initialized}
                            tooltipCallback={(item) => tooltipCallback(item, datasets.length, "count", localize)}
                            tooltipTitleCallback={(items) => {
                                return `${items[0].dataIndex + 1} ${getMonth(month.getMonth(), currentLanguage)}`;
                            }}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
};
