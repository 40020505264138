import webAPIRequest from "api";
import { AxiosResponse } from "axios";
import PaginationStore from "utils/paginationStore";

export interface IStatSubscriptionCount {
    type: string;
    count: number;
}

export interface IStatExpectedRevenue {
    revenue_excl_vat: string;
    discount_excl_vat: string;
    total_excl_vat: string;
    community: string;
    community_uuid: string;
    subscription_type: "arena_subscription" | "arena_unbound_subscription";
    month: string;
    year: string;
}

export interface IStatTotalInvoiced {
    positive_excl_vat: string;
    credited_excl_vat: string;
    total_excl_vat: string;
    community: string;
    community_uuid: string;
    month: string;
    year: string;
    created_from_action: "file" | "auto";
}

export interface IStatSubscriptionUsage {
    created: string;
    community_uuid: string;
    membership_id: string;
    nbr_of_arena_subscription: number;
    nbr_of_arena_unbound_subscription: number;
    nbr_overused_unbound_subscription: number;
}

export const fetchStatExpectedRevenue = async (params?: {
    period_after?: string;
    period_before?: string;
    communities?: string;
    subscription_type?: "arena_subscription" | "arena_unbound_subscription";
}): Promise<AxiosResponse<IStatExpectedRevenue[]>> => {
    return webAPIRequest("get", "/statistics/expected-revenues/", { params });
};

export const fetchStatTotalInvoiced = async (params?: {
    period_after?: string;
    period_before?: string;
    communities?: string;
}): Promise<AxiosResponse<IStatTotalInvoiced[]>> => {
    return webAPIRequest("get", "/statistics/total-invoiced/", { params });
};

export const fetchStatSubscriptionCounts = async (params?: {
    community_uuid?: string;
    period_start: string;
}): Promise<AxiosResponse<IStatSubscriptionCount[]>> => {
    return webAPIRequest("get", "/subscriptions/statistics/count/", {
        params,
    });
};

export const fetchStatSubscriptionUsage = async (params?: {
    period_after?: string;
    period_before?: string;
    community_uuid?: string;
    membership_id?: string;
    page_size?: number;
}): Promise<AxiosResponse<PaginationStore<IStatSubscriptionUsage>>> => {
    return webAPIRequest("get", "/accessy/accessy-statistics/", {
        params,
    });
};
