import React from "react";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import ArrowRightIcon from "@mui/icons-material/NavigateNext";
import ArrowDownIcon from "@mui/icons-material/ExpandMore";
import Moreicon from "@mui/icons-material/MoreVert";
import ToggleButton from "@mui/material/ToggleButton";
import CircularProgress from "@mui/material/CircularProgress";
import { LocalizeText } from "components/localizer";
import If from "components/__helpers__/if";
import colors from "styles/colors";
import { Box, styled } from "@mui/material";
import { TLanguageTag } from "contexts/language";
import { CSSProperties } from "@mui/material/styles/createMixins";

export interface TDropdownItem extends MenuItemProps {
    label: string | React.ReactNode;
    canLoad?: boolean;
    iconLeft?: React.ReactNode;
    iconRight?: React.ReactNode;
    dontShow?: boolean;
    disabled?: boolean;
}

type DropDownToggleProps = {
    inRow: boolean;
};

const IN_ROW_STYLING = {
    padding: 0,
    borderRadius: 0,
    border: "none",
    "&.Mui-selected": {
        backgroundColor: colors.vkBlue,
        color: colors.white,
        "& svg": {
            color: colors.white,
        },
        "&:hover": {
            backgroundColor: colors.vkBlueHover,
        },
    },
};

const NORMAL_STYLING: CSSProperties = {
    color: colors.vkBlue,
    border: `2px solid ${colors.vkBlue}`,
    borderRadius: 0,
    backgroundColor: colors.white,
    paddingLeft: "10px",
    paddingRight: "10px",
    textTransform: "none",
    height: "100%",
    "&.Mui-selected": {
        backgroundColor: colors.vkBlue,
        color: colors.white,
        "& svg": {
            color: colors.white,
        },
        "&:hover": {
            backgroundColor: colors.vkBlueHover,
            border: `2px solid ${colors.vkBlueHover}`,
        },
    },
};

const DropdownToggleButton = styled(ToggleButton, {
    shouldForwardProp: (prop) => prop !== "inRow",
})<DropDownToggleProps>(({ inRow }) => ({
    "& svg": {
        color: colors.vkBlue,
    },
    "&:hover": {
        cursor: "pointer !important",
    },
    ...(inRow ? IN_ROW_STYLING : NORMAL_STYLING),
}));

interface IProps {
    items: TDropdownItem[];
    inRow?: boolean;
    tag?: TLanguageTag;
    size?: "medium" | "small";
}
const Dropdown: React.FC<IProps> = ({ items, inRow = false, tag = "more", size = "small" }) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const [loadingIndex, setLoadingIndex] = React.useState<number | null>(null);

    const onItem = async (index: number, event: React.MouseEvent<HTMLLIElement, MouseEvent>): Promise<void> => {
        if (anchorRef.current?.contains(event.target as HTMLButtonElement)) {
            setOpen(false);
        }
        const item = items[index];
        if (item.canLoad) {
            setLoadingIndex(index);
        }
        if (item.onClick) {
            item.onClick(event);
        }
    };

    const handleToggle = (): void => {
        setOpen((prevOpen) => !prevOpen);
    };

    const onClickAway = (event: Event | React.SyntheticEvent): void => {
        if (anchorRef.current?.contains(event.target as HTMLButtonElement)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current?.focus();
        }

        prevOpen.current = open;
    }, [open]);

    if (items.length === 0) {
        return null;
    }

    return (
        <DropdownToggleButton
            inRow={inRow}
            onClick={handleToggle}
            selected={open}
            value={open}
            size={size}
            ref={anchorRef}
        >
            {!inRow && (
                <Box mr={"20px"}>
                    <LocalizeText tag={tag} />{" "}
                </Box>
            )}
            {inRow ? <Moreicon /> : <>{open ? <ArrowDownIcon /> : <ArrowRightIcon />}</>}
            <Popper sx={{ zIndex: 9000 }} open={open} anchorEl={anchorRef.current} placement="bottom-start" transition>
                {({ TransitionProps }): JSX.Element => (
                    <Grow {...TransitionProps} style={{ transformOrigin: "right top" }}>
                        <Paper>
                            <ClickAwayListener onClickAway={onClickAway}>
                                <MenuList
                                    sx={{ border: `2px solid ${colors.vkBlue}`, padding: 0 }}
                                    autoFocusItem={open}
                                >
                                    {items.map((item: TDropdownItem, index: number) => (
                                        <If key={index} falsy={item.dontShow}>
                                            <>
                                                <MenuItem
                                                    divider
                                                    sx={{
                                                        height: "45px",
                                                    }}
                                                    value={index}
                                                    onClick={(event) => onItem(index, event)}
                                                    disabled={item.disabled || loadingIndex === index}
                                                >
                                                    <Box display={"flex"} mr={"10px"}>
                                                        {loadingIndex !== index ? (
                                                            item.iconLeft
                                                        ) : (
                                                            <CircularProgress size={30} sx={{ color: colors.black }} />
                                                        )}
                                                    </Box>
                                                    <Box mr={"10px"}>{item.label}</Box>
                                                    <Box display={"flex"}>
                                                        {loadingIndex !== index ? (
                                                            item.iconRight
                                                        ) : (
                                                            <CircularProgress size={30} sx={{ color: colors.black }} />
                                                        )}
                                                    </Box>
                                                </MenuItem>
                                            </>
                                        </If>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </DropdownToggleButton>
    );
};

export default Dropdown;
