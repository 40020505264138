import Ajv from "ajv";
import { getCountryCode } from "./countryCodes";

class Validator {
    ajv: Ajv;
    constructor() {
        this.ajv = new Ajv({ allErrors: true });
    }

    email(value: string, canBeEmpty?: boolean): boolean {
        if (canBeEmpty && !value) {
            return true;
        }
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(value);
    }

    date(value: Date | string): boolean {
        const schema = {
            format: "date",
        };
        return !!this.ajv.validate(schema, value);
    }

    peppol(value: string, canBeEmpty?: boolean): boolean {
        if (canBeEmpty && value === "") {
            return true;
        }
        const schema = {
            type: "string",
            pattern: "^(0007:\\d{10}|0088:\\d{13})$",
        };
        return !!this.ajv.validate(schema, value);
    }

    phoneNumber(value: string, canBeEmpty?: boolean): boolean {
        if (canBeEmpty && value === "") {
            return true;
        }
        const schema = {
            type: "string",
            pattern: "\\+(?:\\s*-*\\d){6,15}$",
        };
        return !!getCountryCode(value) && !!this.ajv.validate(schema, value);
    }

    textFieldLength(value: string, maxLength: number): boolean {
        return value.length <= maxLength;
    }

    textFieldNotEmpty(value: string | any[] | boolean): boolean {
        return typeof value === "boolean" || value.length > 0;
    }

    fileName(value: string, validExt: string[]): boolean {
        const ext = validExt.join("|");
        const pattern = new RegExp(`^[^._]{3,}.(?:${ext})$`);

        return pattern.test(value);
    }

    numberLargerThanZero(value: number): boolean {
        if (isNaN(value)) {
            return false;
        }
        return value > 0;
    }
}

const validator = new Validator();
export default validator;
