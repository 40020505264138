import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { InvoiceEventsTable } from "components/tableComponents/invoiceEventsTable";
import { InvoiceMembershipsTable } from "components/tableComponents/invoiceMembershipsTable";
import { PageWithTabs } from "components/pageWithTabs";
import VKButton from "components/vkButton";
import { usePaginationStore } from "utils/usePaginationStore";

const InvoicingView: FC = () => {
    const pagination = usePaginationStore("invoice-events", undefined);
    const navigate = useNavigate();

    return (
        <PageWithTabs
            titleTag="invoicing"
            rightSideContent={
                <VKButton
                    tag="appPurchases"
                    variant="outlined"
                    size="small"
                    onClick={() => navigate("/staff-invoicing/app-purchases")}
                />
            }
            tabs={[
                {
                    tag: "invoiceEvents",
                    handle: "events",
                    content: (
                        <InvoiceEventsTable
                            onClick={(invoiceEventId) => navigate(`/staff-invoicing/events/${invoiceEventId}`)}
                        />
                    ),
                },
                {
                    tag: "memberships",
                    handle: "memberships",
                    content: (
                        <InvoiceMembershipsTable
                            pagination={pagination}
                            onClick={(invoiceEventId) => navigate(`/staff-invoicing/memberships/${invoiceEventId}`)}
                        />
                    ),
                    requiredPerm: "temporarily_disabled",
                },
            ]}
        />
    );
};

export default InvoicingView;
