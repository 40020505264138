import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { isValid } from "date-fns";
import { Grid, IconButton, MenuItem, TextField } from "@mui/material";
import { CancelOutlined } from "@mui/icons-material";
import CommunityContext from "contexts/community";
import { useNavigate, useParams } from "react-router-dom";
import { LanguageContext } from "contexts/language";
import { MonthSelector } from "components/monthSelector";
import { IUsePaginationStore } from "utils/usePaginationStore";

// THIS FILE IS WORK-IN-PROGRESS

interface IInvoiceMembershipsTable {
    pagination: IUsePaginationStore<"invoice-events">;
    onClick: (invoiceEventId: string) => void;
}

export const InvoiceMembershipsTable: FC<IInvoiceMembershipsTable> = () => {
    const navigate = useNavigate();
    const [currentDate, setCurrentDate] = useState(new Date());
    const [community, setCommunity] = useState<string | null>(null);
    const [timeoutHandle, setTimeoutHandle] = useState<NodeJS.Timeout | null>(null);

    const params = useParams<{ period: string }>();

    useEffect(() => {
        if (params.period) {
            setCurrentDate(new Date(params.period));
        } else {
            navigate(`/staff-invoicing/memberships/${new Date().getFullYear()}-${new Date().getMonth() + 1}`, { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangePeriod = useCallback(
        (newDate: Date) => {
            if (!isValid(newDate)) {
                return;
            }

            setCurrentDate(newDate);
            navigate(`/staff-invoicing/memberships/${newDate.getFullYear()}-${newDate.getMonth() + 1}`);

            if (timeoutHandle) {
                clearTimeout(timeoutHandle);
            }
            setTimeoutHandle(
                setTimeout(() => {
                    console.log("FETCH NEW DATA AFTER DELAY");
                }, 500),
            );
        },
        [navigate, timeoutHandle],
    );

    const onChangeCommunity = useCallback((newCommunity: string) => {
        setCommunity(newCommunity);
    }, []);

    return (
        <>
            <Grid container alignItems="center" gap="10px" padding="20px">
                <MonthSelector
                    currentDate={currentDate}
                    onChange={onChangePeriod}
                    minDate={new Date("2020-01-01")}
                    maxDate={new Date()}
                />
                <Grid item xs={12} sm={4} marginLeft="auto">
                    <Grid container flexWrap="nowrap" alignItems="center">
                        <Grid item xs={12}>
                            <CommunitySelector currentCommunity={community} onSelect={onChangeCommunity} />
                        </Grid>
                        {community ? (
                            <Grid item>
                                <IconButton onClick={() => setCommunity(null)}>
                                    <CancelOutlined fontSize="small" />
                                </IconButton>
                            </Grid>
                        ) : null}
                    </Grid>
                </Grid>
            </Grid>
            {/* <InvoiceOrdersTable pagination={pagination} /> */}
        </>
    );
};

interface CommunitySelectorProps {
    currentCommunity: string | null;
    onSelect: (communityUuid: string) => void;
}

const CommunitySelector: FC<CommunitySelectorProps> = ({ currentCommunity, onSelect }) => {
    const { communitiesPagination } = useContext(CommunityContext);
    const { localize } = useContext(LanguageContext);

    useEffect(() => {
        communitiesPagination.getInitial();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <TextField
            label={`${localize("filterBy")} ${localize("community").toLowerCase()}`}
            onChange={(e) => onSelect(e.target.value)}
            select
            fullWidth
            value={currentCommunity ?? ""}
            size="small"
        >
            {communitiesPagination.results.map((community) => (
                <MenuItem key={community.uuid} value={community.uuid}>
                    {community.title}
                </MenuItem>
            ))}
        </TextField>
    );
};
