import React, { FC, useContext } from "react";
import { ProfileContext } from "contexts/profile";
import { THeadCell } from "utils/types";
import { deepGet } from "utils/helpers";
import { LocalizeText } from "components/localizer";
import TableComponent, { IRow } from "components/tableComponent";
import Banner from "components/banner";
import { getLeaseValidToDate } from "utils/ecommerseTypes";
import { LeaseCollapse } from "components/leases/leaseCollapse";
import { ILease } from "reducers/leases";
import { usePaginationStore } from "utils/usePaginationStore";

export const headCells: THeadCell[] = [
    { id: "address", sortId: "premise__address", label: <LocalizeText tag="address" /> },
    { id: "agreement_number", sortId: "premise__agreement_number", label: <LocalizeText tag="contractNumber" /> },
    { id: "premise_group_name", sortId: "premise__premise_group_name", label: <LocalizeText tag="property" /> },
    { id: "squareMeters", sortId: "lease_contracts__area", label: <LocalizeText tag="squareMeters" /> },
    { id: "valid_from", sortId: "valid_from_date", label: <LocalizeText tag="from" /> },
    { id: "valid_to", sortId: "valid_to_date", label: <LocalizeText tag="until" /> },
    { id: "manager_agent", sortId: "lease_manager__first_name", label: <LocalizeText tag="managerAgent" /> },
];

const LeasesViewV2: FC = () => {
    const { selectedOrganization } = useContext(ProfileContext);
    const pagination = usePaginationStore("leases", undefined, {
        org_nr: selectedOrganization.org_nr,
        has_manage: true,
    });

    const mapRows = (): IRow[] => {
        return pagination.results.map((lease: ILease) => ({
            data: [
                lease.address,
                lease.agreement_number,
                deepGet("active_lease_contract.premise_group_name", lease, "-"),
                Number(lease.active_lease_contract?.area).toFixed(0) || "-",
                lease.valid_from_date || "-",
                getLeaseValidToDate(lease.valid_to_date),
                lease.lease_manager?.full_name || "-",
            ],
            collapsibleContent: <LeaseCollapse lease={lease} />,
        }));
    };

    return (
        <div data-testid="leasesView-root" className="contentWrapper">
            <Banner
                title="leasesCategory"
                subTitle="bannerLeases"
                image={`${process.env.PUBLIC_URL}/assets/images/1024x683/memberships-1024x683.jpg`}
            />
            <TableComponent
                headCells={headCells}
                isLoading={pagination.query.isFetching}
                rows={mapRows()}
                skeletonVariant="regular"
                pagination={pagination}
                collapsible
            />
        </div>
    );
};

export default LeasesViewV2;
