import { TLanguage, TLanguageTag } from "contexts/language";
import {
    IStatExpectedRevenue,
    IStatSubscriptionCount,
    IStatTotalInvoiced,
    fetchStatExpectedRevenue,
    fetchStatTotalInvoiced,
    fetchStatSubscriptionCounts,
    fetchStatSubscriptionUsage,
} from "adapters/statsAdapter";
import { prettifyPriceText } from "components/prettifyPrice";
import { TCommunity } from "utils/ecommerseTypes";
import { addMonths, format, getDaysInMonth, lastDayOfMonth } from "date-fns";
import { dateFormats } from "utils/formats";
import colors from "styles/colors";
import { getMonth } from "utils/helpers";
import { TooltipItem } from "chart.js";
import { TMembershipId } from "reducers/memberships";

export type TMonthKey = string;
export type TCommunityUuid = string;

interface ProcessedExpectedRevenueData {
    total_excl_vat: number;
    discount_excl_vat: number;
    unbound_total_excl_vat: number;
    unbound_discount_excl_vat: number;
}

interface ProcessedTotalInvoicedData {
    positive_excl_vat: number;
    credited_excl_vat: number;
    app_positive_excl_vat: number;
    app_credited_excl_vat: number;
}

interface ProcessedSubscriptionCountData {
    normal: number;
    unbound: number;
    trial: number;
    trial_unbound: number;
}

export interface ProcessedSubscriptionUsageData {
    normal: number;
    unbound: number;
    overused: number;
}

export interface Dataset {
    label: string;
    data: number[];
    backgroundColor: string;
    stack?: string;
}

export interface FetchedMonth {
    counts: ProcessedSubscriptionCountData;
    expectedRevenue: ProcessedExpectedRevenueData;
    totalInvoiced: ProcessedTotalInvoicedData;
    usage: ProcessedSubscriptionUsageData;
}

const GRAPH_COLORS = {
    darkBlue: colors.vkBlue,
    lightBlue: "#7d6fff",
    darkGreen: "#086868",
    lightGreen: "#00b9b9",
    error: colors.errorRed,
};

export const calcMonthKey = (date: Date): TMonthKey => {
    return format(date, "yyyy-MM-01");
};

export const calcKeysAndLabels = (
    monthOffset: number,
    visibleMonths: number,
    currentLanguage: TLanguage
): { monthKeys: TMonthKey[]; labels: string[] } => {
    const labels: string[] = [];
    const monthKeys: TMonthKey[] = [];
    let latestYear = 0;
    // eslint-disable-next-line array-callback-return
    Array.from({ length: visibleMonths }, (_, i) => {
        const offset = -Math.floor(visibleMonths / 2) + i + monthOffset;
        const date = addMonths(new Date(), offset);
        const year = date.getFullYear();
        const future = offset > 0;
        let label = `${getMonth(date.getMonth(), currentLanguage)}${future ? " *" : ""}`;
        if (year !== latestYear) {
            latestYear = year;
            label = `${latestYear} ${label}`;
        }
        labels.push(label);
        monthKeys.push(calcMonthKey(date));
    });
    return { labels, monthKeys };
};

export const calcKeysAndLabelsDaily = (monthOffset: number): { monthKey: TMonthKey; labels: string[] } => {
    const labels: string[] = [];
    const date = addMonths(new Date(), monthOffset);
    Array.from(Array(getDaysInMonth(date))).forEach((_, index) => {
        labels.push(`${index + 1}`);
    });
    return { monthKey: calcMonthKey(date), labels };
};

export const EMPTY_MONTH_DATA: FetchedMonth = Object.freeze({
    counts: {
        normal: 0,
        trial: 0,
        unbound: 0,
        trial_unbound: 0,
    },
    expectedRevenue: {
        total_excl_vat: 0,
        discount_excl_vat: 0,
        unbound_total_excl_vat: 0,
        unbound_discount_excl_vat: 0,
    },
    totalInvoiced: {
        positive_excl_vat: 0,
        credited_excl_vat: 0,
        app_positive_excl_vat: 0,
        app_credited_excl_vat: 0,
    },
    usage: {
        normal: 0,
        unbound: 0,
        overused: 0,
    },
});

export const calcMonthMissingCommunities = (
    monthKeys: TMonthKey[],
    communities: TCommunity[],
    fetchedMonths: Record<TMonthKey, Record<TCommunityUuid, FetchedMonth>>,
    isFetchingMonths: Record<TMonthKey, TCommunityUuid[]>
): Record<TMonthKey, TCommunityUuid[]> => {
    const missingCommunities: Record<TMonthKey, TCommunityUuid[]> = {};
    monthKeys.forEach(async (monthKey) => {
        missingCommunities[monthKey] = communities
            .filter(({ uuid }) => {
                if (fetchedMonths[monthKey]?.[uuid] || isFetchingMonths[monthKey]?.includes(uuid)) {
                    return false;
                }
                return true;
            })
            .map(({ uuid }) => uuid);
    });
    return missingCommunities;
};

export const getMonthlyMonetaryStats = async (
    missingCommunities: Record<TMonthKey, TCommunityUuid[]>,
    revenueData: IStatExpectedRevenue[],
    invoicedData: IStatTotalInvoiced[]
): Promise<void> => {
    await Promise.all(
        Object.entries(missingCommunities).map(async ([monthKey, communities]) => {
            if (communities.length === 0) {
                return;
            }

            const period_before = format(lastDayOfMonth(new Date(monthKey)), dateFormats.WEBDATE);
            await getMonetaryStats(monthKey, period_before, communities.join(), revenueData, invoicedData);
        })
    );
};

export const getMonetaryStats = async (
    period_after: string,
    period_before: string,
    communities: string,
    revenueData: IStatExpectedRevenue[],
    invoicedData: IStatTotalInvoiced[]
): Promise<void> => {
    await Promise.all([
        (async () => {
            const newRevenueData = await fetchStatExpectedRevenue({
                period_after: period_after,
                period_before,
                communities: communities,
            });
            revenueData.push(...newRevenueData.data);
        })(),
        (async () => {
            const newInvoicedData = await fetchStatTotalInvoiced({
                period_after: period_after,
                period_before,
                communities: communities,
            });
            invoicedData.push(...newInvoicedData.data);
        })(),
    ]);
};

export const getSubscriptionCountStats = async (
    missingCommunities: Record<TMonthKey, TCommunityUuid[]>,
    newData: Record<TMonthKey, Record<TCommunityUuid, FetchedMonth>>
): Promise<void> => {
    await Promise.all(
        Object.entries(missingCommunities).map(async ([monthKey, communities]) => {
            await Promise.all(
                communities.map(async (uuid) => {
                    const newCountData = await fetchStatSubscriptionCounts({
                        period_start: monthKey,
                        community_uuid: uuid,
                    });
                    newData[monthKey][uuid].counts = processCountsData(newCountData.data);
                })
            );
        })
    );
};

const getEmptyDailSubscriptionUsageData = (month: Date): ProcessedSubscriptionUsageData[] => {
    return Array.from(Array(getDaysInMonth(month))).fill({
        normal: 0,
        unbound: 0,
        overused: 0,
    });
};

export const getDailySubscriptionUsage = async (
    month: Date,
    membershipId: number,
    defaultCommunity: string
): Promise<Record<TCommunityUuid, ProcessedSubscriptionUsageData[]>> => {
    const period_after = new Date(month);
    period_after.setDate(1);
    const params = {
        membership_id: membershipId.toString(),
        period_after: format(period_after, dateFormats.WEBDATE),
        period_before: format(lastDayOfMonth(month), dateFormats.WEBDATE),
        page_size: 1000,
        default_page_size: 1000,
    };
    const fetched = await fetchStatSubscriptionUsage(params);
    const result: Record<TCommunityUuid, ProcessedSubscriptionUsageData[]> = {
        [defaultCommunity]: getEmptyDailSubscriptionUsageData(month),
    };
    fetched.data.results.forEach((d) => {
        if (!result[d.community_uuid]) {
            result[d.community_uuid] = getEmptyDailSubscriptionUsageData(month);
        }

        const date = new Date(d.created);
        result[d.community_uuid][date.getDate() - 1] = {
            normal: d.nbr_of_arena_subscription,
            unbound: d.nbr_of_arena_unbound_subscription,
            overused: d.nbr_overused_unbound_subscription,
        };
    });
    return result;
};

export const getSubscriptionUsageStats = async (
    missingCommunities: Record<TMonthKey, TCommunityUuid[]>,
    newData: Record<TMonthKey, Record<TCommunityUuid, FetchedMonth>>,
    membershipOverusage: Record<TMonthKey, TMembershipId[]>
): Promise<void> => {
    await Promise.all(
        Object.entries(missingCommunities).map(async ([monthKey, communities]) => {
            const overusing = new Set<number>();
            await Promise.all(
                communities.map(async (uuid) => {
                    const newUsageData = await fetchStatSubscriptionUsage({
                        period_after: monthKey,
                        period_before: format(lastDayOfMonth(new Date(monthKey)), dateFormats.WEBDATE),
                        community_uuid: uuid,
                    });
                    const total: ProcessedSubscriptionUsageData = {
                        normal: 0,
                        unbound: 0,
                        overused: 0,
                    };
                    newUsageData.data.results.forEach((d) => {
                        if (d.nbr_overused_unbound_subscription > 0) {
                            overusing.add(parseInt(d.membership_id));
                        }
                        total.normal += d.nbr_of_arena_subscription;
                        total.unbound += d.nbr_of_arena_unbound_subscription;
                        total.overused += d.nbr_overused_unbound_subscription;
                    });
                    newData[monthKey][uuid].usage = total;
                })
            );
            membershipOverusage[monthKey] = Array.from(overusing);
        })
    );
};

export const processCountsData = (data: IStatSubscriptionCount[]): ProcessedSubscriptionCountData => {
    const ret = { ...EMPTY_MONTH_DATA.counts };
    data.forEach(({ type, count }) => {
        ret[type] = count;
    });
    return ret;
};

export const parseMoneyData = (
    keys: string[],
    communityUuids: string[],
    revenueData: IStatExpectedRevenue[],
    invoicedData: IStatTotalInvoiced[]
): Record<TMonthKey, Record<string, FetchedMonth>> => {
    const ret: Record<TMonthKey, Record<string, FetchedMonth>> = {};
    keys.forEach((key) => {
        const month = {};
        communityUuids.forEach((uuid) => (month[uuid] = getEmptyMonthData()));
        ret[key] = month;
    });
    revenueData.forEach((d) => {
        const monthKey = `${d.year}-${parseInt(d.month) < 10 ? "0" : ""}${d.month}-01`;
        const isUnbound = d.subscription_type === "arena_unbound_subscription";
        const revenue_excl_vat = parseFloat(d.revenue_excl_vat || "0");
        const discount_excl_vat = -parseFloat(d.discount_excl_vat || "0");
        if (isUnbound) {
            ret[monthKey][d.community_uuid].expectedRevenue.unbound_total_excl_vat = revenue_excl_vat;
            ret[monthKey][d.community_uuid].expectedRevenue.unbound_discount_excl_vat = discount_excl_vat;
        } else {
            ret[monthKey][d.community_uuid].expectedRevenue.total_excl_vat = revenue_excl_vat;
            ret[monthKey][d.community_uuid].expectedRevenue.discount_excl_vat = discount_excl_vat;
        }
    });
    invoicedData.forEach((d) => {
        const monthKey = `${d.year}-${parseInt(d.month) < 10 ? "0" : ""}${d.month}-01`;
        const isApp = d.created_from_action === "file";
        const positive_excl_vat = parseFloat(d.positive_excl_vat || "0");
        const credited_excl_vat = parseFloat(d.credited_excl_vat || "0");
        if (isApp) {
            ret[monthKey][d.community_uuid].totalInvoiced.app_positive_excl_vat = positive_excl_vat;
            ret[monthKey][d.community_uuid].totalInvoiced.app_credited_excl_vat = credited_excl_vat;
        } else {
            ret[monthKey][d.community_uuid].totalInvoiced.positive_excl_vat = positive_excl_vat;
            ret[monthKey][d.community_uuid].totalInvoiced.credited_excl_vat = credited_excl_vat;
        }
    });
    return ret;
};

export const getEmptyMonthData = (): FetchedMonth => {
    const emptyMonth: FetchedMonth = {} as FetchedMonth;
    Object.entries(EMPTY_MONTH_DATA).forEach(([key, data]) => {
        emptyMonth[key] = { ...data };
    });
    return emptyMonth;
};

export const getDatasetsMembership = (
    monthKey: TMonthKey,
    fetchedMonths: Record<TMonthKey, Record<TCommunityUuid, ProcessedSubscriptionUsageData[]>>,
    localize: (tag: TLanguageTag) => string,
    defaultCommunity: string
): Record<TCommunityUuid, Dataset[]> => {
    const result: Record<TCommunityUuid, Dataset[]> = {};
    const communitiesData = {
        ...fetchedMonths[monthKey],
    };
    Object.entries(communitiesData).forEach(([communityUuid, data]) => {
        const datasets: Dataset[] = [
            createDataset("1", localize("normal"), "darkBlue"),
            createDataset("1", localize("unbound"), "darkGreen"),
            createDataset("1", localize("overused"), "error"),
        ];
        data.forEach((d) => {
            datasets[0].data.push(d.normal);
            datasets[1].data.push(d.unbound);
            datasets[2].data.push(d.overused);
        });

        result[communityUuid] = datasets;
    });
    return result;
};

type TDataset = "revenue" | "invoiced" | "count" | "usage";
export const getDatasets = (
    monthKeys: TMonthKey[],
    fetchedMonths: Record<TMonthKey, Record<TCommunityUuid, FetchedMonth>>,
    selectedCommunities: { uuid: string; title: string }[],
    localize: (tag: TLanguageTag) => string
): Record<TDataset, Dataset[]> => {
    const result: Record<TDataset, Dataset[]> = {
        invoiced: [],
        revenue: [],
        count: [],
        usage: [],
    };
    const relevantData: Record<TCommunityUuid, FetchedMonth[]> = {};
    selectedCommunities.forEach(({ uuid }) => {
        relevantData[uuid] = [];
    });
    monthKeys.forEach((monthKey) => {
        selectedCommunities.forEach(({ uuid }) => {
            if (!fetchedMonths[monthKey]?.[uuid]) {
                relevantData[uuid].push(getEmptyMonthData());
            } else {
                relevantData[uuid].push(fetchedMonths[monthKey][uuid]);
            }
        });
    });
    selectedCommunities.forEach(({ uuid, title }) => {
        const dataSets = convertToDatasets(relevantData[uuid], localize, title);
        result.count.push(...dataSets.countDatasets);
        result.revenue.push(...dataSets.revenueDatasets);
        result.invoiced.push(...dataSets.invoiceDatasets);
        result.usage.push(...dataSets.usageDatasets);
    });

    return result;
};

export const convertToDatasets = (
    monthsData: FetchedMonth[],
    localize: (tag: TLanguageTag) => string,
    communityTitle: string
): { revenueDatasets: Dataset[]; invoiceDatasets: Dataset[]; countDatasets: Dataset[]; usageDatasets: Dataset[] } => {
    const revenueDatasets: Dataset[] = [
        createDataset(communityTitle, localize("boundPlural"), "darkBlue"),
        createDataset(
            communityTitle,
            `${localize("boundPlural")}, ${localize("discounted").toLocaleLowerCase()}`,
            "lightBlue"
        ),
        createDataset(communityTitle, localize("unboundPlural"), "darkGreen"),
        createDataset(
            communityTitle,
            `${localize("unboundPlural")}, ${localize("discounted").toLocaleLowerCase()}`,
            "lightGreen"
        ),
    ];
    const invoiceDatasets: Dataset[] = [
        createDataset(communityTitle, localize("invoiced"), "darkBlue"),
        createDataset(communityTitle, localize("credited"), "lightBlue"),
        createDataset(
            communityTitle,
            `${localize("appPurchases")}, ${localize("invoiced").toLocaleLowerCase()}`,
            "darkGreen"
        ),
        createDataset(
            communityTitle,
            `${localize("appPurchases")}, ${localize("credited").toLocaleLowerCase()}`,
            "lightGreen"
        ),
    ];
    const countDatasets: Dataset[] = [
        createDataset(communityTitle, localize("boundPlural"), "darkBlue"),
        createDataset(
            communityTitle,
            `${localize("boundPlural")}, ${localize("trialPeriodShort").toLocaleLowerCase()}`,
            "lightBlue"
        ),
        createDataset(communityTitle, localize("unboundPlural"), "darkGreen"),
        createDataset(
            communityTitle,
            `${localize("unboundPlural")}, ${localize("trialPeriodShort").toLocaleLowerCase()}`,
            "lightGreen"
        ),
    ];
    const usageDatasets: Dataset[] = [
        createDataset(communityTitle, localize("normal"), "darkBlue"),
        createDataset(communityTitle, localize("unbound"), "darkGreen"),
        createDataset(communityTitle, localize("overusage"), "error"),
    ];
    monthsData.forEach(({ counts, expectedRevenue, totalInvoiced, usage }) => {
        revenueDatasets[0].data.push(expectedRevenue.total_excl_vat);
        revenueDatasets[1].data.push(expectedRevenue.discount_excl_vat);
        revenueDatasets[2].data.push(expectedRevenue.unbound_total_excl_vat);
        revenueDatasets[3].data.push(expectedRevenue.unbound_discount_excl_vat);
        invoiceDatasets[0].data.push(totalInvoiced.positive_excl_vat);
        invoiceDatasets[1].data.push(totalInvoiced.credited_excl_vat);
        invoiceDatasets[2].data.push(totalInvoiced.app_positive_excl_vat);
        invoiceDatasets[3].data.push(totalInvoiced.app_credited_excl_vat);
        countDatasets[0].data.push(counts.normal);
        countDatasets[1].data.push(counts.trial);
        countDatasets[2].data.push(counts.unbound);
        countDatasets[3].data.push(counts.trial_unbound);
        usageDatasets[0].data.push(usage.normal);
        usageDatasets[1].data.push(usage.unbound);
        usageDatasets[2].data.push(usage.overused);
    });
    return { countDatasets, revenueDatasets, invoiceDatasets, usageDatasets };
};

const createDataset = (communityTitle: string, label: string, backgroundColor: keyof typeof GRAPH_COLORS): Dataset => {
    const barOptions = {
        stack: communityTitle,
        groupPercentage: 0.6,
    };
    return {
        label,
        data: [],
        backgroundColor: GRAPH_COLORS[backgroundColor],
        ...barOptions,
    };
};

export const getItemResult = (type: "money" | "count", label: string, value: number): string => {
    if (type === "money") {
        return `${label}: ${prettifyPriceText(value.toString(), 0)} kr`;
    } else {
        return `${label}: ${value} st`;
    }
};

export const tooltipCallback = (
    item: TooltipItem<"bar">,
    divider: number,
    type: "money" | "count",
    localize: (tag: TLanguageTag) => string
): string[] => {
    const result = [getItemResult(type, item.dataset.label as string, Math.abs(item.raw as number))];
    if (item.datasetIndex % divider === divider - 1) {
        let total = 0;
        const values = Object.values(item.parsed._stacks?.y?.["_visualValues"]);
        values.forEach((value) => (total += value as number));
        if (type === "money") {
            result.push(getItemResult(type, localize("result"), total));
        } else {
            result.push(getItemResult(type, localize("total"), total));
        }
    }
    return result;
};
