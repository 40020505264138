import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/EditSharp";
import SaveIcon from "@mui/icons-material/DoneSharp";
import CancelIcon from "@mui/icons-material/CloseSharp";
import DeleteIcon from "@mui/icons-material/DeleteForeverSharp";
import If from "./__helpers__/if";
import { TextField } from "@mui/material";
import colors from "styles/colors";

const SkeletonLoader: React.FC = () => (
    <>
        {[...Array(5)].map((key, index) => (
            <React.Fragment key={index}>
                <ListItem>
                    <Skeleton variant="rectangular" sx={{width: "300px"}} />
                    <ListItemSecondaryAction>
                        <Skeleton variant="circular" sx={{ width: "30px", height: "30px", margin: "auto",}} />
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider component="li" />
            </React.Fragment>
        ))}
    </>
);

interface IProps {
    items: string[];
    onRemove?: (index: number) => void;
    onSubmitEdit?: (index: number, newName: string) => void;
    isLoading?: boolean;
    emptyText?: string | React.ReactNode;
    icon?: React.ReactNode;
}
const DataList: React.FC<IProps> = ({
    items,
    onRemove,
    onSubmitEdit,
    isLoading,
    emptyText,
    icon
}) => {
    const [editText, setEditText] = React.useState<string>("");
    const [editIndex, setEditIndex] = React.useState<number>(-1);

    if (isLoading) {
        return <SkeletonLoader />;
    }

    if (items.length === 0) {
        return <ListItem>{emptyText}</ListItem>;
    }

    const onSave = async (index: number): Promise<void> => {
        onSubmitEdit && (await onSubmitEdit(index, editText));
        setEditIndex(-1);
    };

    return (
        <>
            {items.map((item: string, index: number) => (
                <React.Fragment key={index}>
                    <ListItem>
                        {icon}
                        <TextField
                            value={editIndex === index ? editText : item}
                            onChange={(e) => setEditText(e.target.value)}
                            variant="standard"
                            margin="dense"
                            sx={{ width: 330 }}
                            disabled={editIndex !== index}
                        />
                        <ListItemSecondaryAction sx={{
                            "& button": {
                                marginLeft: 5,
                            }
                        }}>
                            <If truthy={editIndex === -1}>
                                <>
                                    {onSubmitEdit && (
                                        <IconButton
                                            data-testid={`dataList-editButton-${index}`}
                                            size="small"
                                            edge="end"
                                            onClick={() => {
                                                setEditIndex(index);
                                                setEditText(item);
                                            }}
                                            sx={{ color: colors.vkBlue }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    )}
                                    {onRemove && (
                                        <IconButton
                                            data-testid={`dataList-removeButton-${index}`}
                                            size="small"
                                            edge="end"
                                            onClick={() => onRemove(index)}
                                            sx={{ color: colors.noButtonRegular }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    )}
                                </>
                            </If>
                            <If truthy={editIndex === index}>
                                <>
                                    <IconButton
                                        data-testid={`dataList-saveButton-${index}`}
                                        size="small"
                                        edge="end"
                                        onClick={() => onSave(index)}
                                        sx={{ color: colors.vkBlue }}
                                    >
                                        <SaveIcon />
                                    </IconButton>
                                    <IconButton
                                        data-testid={`dataList-cancelButton-${index}`}
                                        size="small"
                                        edge="end"
                                        onClick={() => setEditIndex(-1)}
                                        sx={{ color: colors.noButtonRegular }}
                                    >
                                        <CancelIcon />
                                    </IconButton>
                                </>
                            </If>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider component="li" />
                </React.Fragment>
            ))}
        </>
    );
};

export default DataList;
