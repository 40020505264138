import React from "react";
import { Chip, ListItem, ListItemText, styled, Typography } from "@mui/material";
import colors from "styles/colors";
import { IBuildingsText, IBuildingsTextDocument } from "reducers/realestates";
import { sortBuildingTexts } from "utils/buildingHelpers";
import { LocalizeText } from "components/localizer";
import AttachmentIcon from "@mui/icons-material/Attachment";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const InformationTab = styled("div")(() => ({
    padding: "40px 60px",
    backgroundColor: colors.vkBlue,
    "& *": {
        color: `${colors.white} !important`,
    },
    "& li": {
        paddingLeft: 0,
    },
}));

const Appendices = styled("div")(() => ({
    display: "flex",
    flex: "0 0 auto",
    gap: "1em",
    flexWrap: "wrap",
    flexDirection: "column",
    alignItems: "flex-start"
}));


interface IProps {
    texts: IBuildingsText[];
    parentType: string;
}
const BuildingTexts: React.FC<IProps> = ({ texts, parentType }) => {
    const sortedBuildingTexts = sortBuildingTexts(texts || [], parentType);
    return (
        <InformationTab>
            {sortedBuildingTexts.map((buildingText: IBuildingsText, index: number) => (
                <ListItem data-testid={`buildingInformation_${buildingText.text_uuid}`} key={buildingText.text_uuid}>
                    <ListItemText
                        disableTypography
                        primary={<Typography variant="h6">{buildingText.type_name}</Typography>}
                        secondary={
                            <>
                                <span dangerouslySetInnerHTML={{ __html: buildingText.content }} />
                                {(buildingText.building_text_documents.length > 0) && (
                                    <>
                                        <Typography variant="subtitle1"><LocalizeText tag="appendices" />:</Typography>
                                        <Appendices>
                                            {buildingText.building_text_documents.map((text_document: IBuildingsTextDocument) => (
                                                <Chip
                                                    sx={{
                                                        backgroundColor: colors.vkBlueHover
                                                    }}
                                                    data-testid={`appendix_${text_document.link_uuid}`}
                                                    key={text_document.link_uuid}
                                                    icon={text_document.extension === ".pdf" ? <PictureAsPdfIcon /> : <AttachmentIcon />}
                                                    label={`${text_document.name} (${text_document.extension})`}
                                                    clickable
                                                    onClick={() => window.open(text_document.url)}
                                                />
                                            ))}
                                        </Appendices>
                                    </>
                                )}
                            </>
                        }
                    />
                </ListItem>
            ))}
        </InformationTab>
    );
};

export default BuildingTexts;
