import { Grid, Typography, TypographyProps } from "@mui/material";
import React from "react";
import { fontType, LocalizeText } from "./localizer";
import { TLanguageTag } from "contexts/language";

export interface ITagValueListEntries {
    tag: TLanguageTag;
    value: string | number | React.ReactElement;
    tagReplace?: Record<string, string>;
    useColon?: boolean;
    align?: "left" | "right";
}

interface ITagValueListProps {
    entries: ITagValueListEntries[];
    typographyProps: TypographyProps;
    xsSize?: number;
}

const TagValueList: React.FC<ITagValueListProps> = ({ entries, typographyProps, xsSize = 3}) => {
    return (
        <>
            {entries.map((entry) => {
                const alignLeft = entry.align === "left";
                return (
                    <Grid container key={`${entry.tag}_${entry.value}`} width="100%" gap={2} marginTop="5px">
                        <Grid item xs={xsSize}>
                            <Typography {...typographyProps}>
                                <LocalizeText replace={entry.tagReplace} tag={entry.tag} type={fontType.BOLD} />
                                {entry.useColon && ":"}
                            </Typography>
                        </Grid>
                        <Grid item marginLeft={alignLeft ? "0" : "auto"} xs>
                            {typeof entry.value === "number" || typeof entry.value === "string" ? (
                                <Typography {...typographyProps} textAlign={alignLeft ? "left" : "right"}>
                                    {entry.value}
                                </Typography>
                            ) : (
                                entry.value
                            )}
                        </Grid>
                    </Grid>
                );
            })}
        </>
    );
};
export default TagValueList;
