import { ICountryCode } from "reducers/memberships";

// Country calling codes taken 26/1 from official ITU E.164 list
export const countryCodes = [
    "+93",
    "+1",
    "+355",
    "+213",
    "+376",
    "+244",
    "+54",
    "+374",
    "+297",
    "+247",
    "+61",
    "+672",
    "+43",
    "+994",
    "+973",
    "+880",
    "+375",
    "+32",
    "+501",
    "+229",
    "+975",
    "+591",
    "+387",
    "+267",
    "+55",
    "+246",
    "+673",
    "+359",
    "+226",
    "+257",
    "+238",
    "+855",
    "+237",
    "+236",
    "+235",
    "+64",
    "+56",
    "+86",
    "+57",
    "+269",
    "+242",
    "+243",
    "+682",
    "+506",
    "+225",
    "+385",
    "+53",
    "+357",
    "+420",
    "+45",
    "+246",
    "+253",
    "+56",
    "+593",
    "+20",
    "+503",
    "+240",
    "+291",
    "+372",
    "+268",
    "+251",
    "+500",
    "+298",
    "+679",
    "+358",
    "+33",
    "+596",
    "+594",
    "+689",
    "+241",
    "+220",
    "+995",
    "+49",
    "+233",
    "+350",
    "+881",
    "+30",
    "+299",
    "+590",
    "+502",
    "+224",
    "+245",
    "+592",
    "+509",
    "+504",
    "+852",
    "+36",
    "+354",
    "+91",
    "+62",
    "+870",
    "+800",
    "+882",
    "+883",
    "+979",
    "+808",
    "+98",
    "+964",
    "+353",
    "+972",
    "+39",
    "+81",
    "+962",
    "+254",
    "+686",
    "+850",
    "+82",
    "+383",
    "+965",
    "+996",
    "+856",
    "+371",
    "+961",
    "+266",
    "+231",
    "+218",
    "+423",
    "+370",
    "+352",
    "+853",
    "+261",
    "+265",
    "+60",
    "+960",
    "+223",
    "+356",
    "+692",
    "+596",
    "+222",
    "+230",
    "+52",
    "+691",
    "+373",
    "+377",
    "+976",
    "+382",
    "+212",
    "+258",
    "+95",
    "+264",
    "+674",
    "+977",
    "+31",
    "+687",
    "+64",
    "+505",
    "+227",
    "+234",
    "+683",
    "+389",
    "+47",
    "+968",
    "+92",
    "+680",
    "+970",
    "+507",
    "+675",
    "+595",
    "+51",
    "+63",
    "+64",
    "+48",
    "+351",
    "+974",
    "+262",
    "+40",
    "+7",
    "+250",
    "+599",
    "+590",
    "+290",
    "+590",
    "+508",
    "+685",
    "+378",
    "+239",
    "+966",
    "+221",
    "+381",
    "+248",
    "+232",
    "+65",
    "+421",
    "+386",
    "+677",
    "+252",
    "+27",
    "+500",
    "+211",
    "+34",
    "+94",
    "+249",
    "+597",
    "+46",
    "+41",
    "+963",
    "+886",
    "+992",
    "+255",
    "+888",
    "+66",
    "+670",
    "+228",
    "+690",
    "+676",
    "+216",
    "+90",
    "+993",
    "+688",
    "+256",
    "+380",
    "+971",
    "+44",
    "+878",
    "+598",
    "+998",
    "+678",
    "+379",
    "+58",
    "+84",
    "+681",
    "+967",
    "+260",
    "+263",
];

export const getCountryCode = (phoneNumber: string): string => {
    for (let i = 1; i <= 4; i++) {
        const subString = phoneNumber.slice(0, i);
        if (countryCodes.includes(subString)) {
            return subString;
        }
    }

    return "";
};

export const getCountryCodeString = (country_code: ICountryCode, currentLanguage: string): string => {
    const { verbose_sv, verbose_en, code } = country_code;

    return `(${code}) ${currentLanguage === "sv" ? verbose_sv : verbose_en}`;
};
