import React from "react";
import { LocalizeText } from "components/localizer";
import { Box, IconButton, Typography } from "@mui/material";
import { THeadCell } from "utils/types";
import TableComponent, { IRow } from "components/tableComponent";
import { prettifyPhoneNumber } from "utils/helpers";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ConfirmDialog from "components/confirmDialog";
import { IInvitation } from "reducers/leases";
import HasPermission from "components/__helpers__/hasPermission";
import { IPaginationStore } from "utils/paginationStore";

const invitationHeadCells: THeadCell[] = [
    { id: "name", sortId: "first_name", label: <LocalizeText tag="name" /> },
    { id: "email", sortId: "email", label: <LocalizeText tag="email" /> },
    { id: "phone", sortId: "phone", label: <LocalizeText tag="phoneNumber" /> },
    { id: "role", label: <LocalizeText tag="role" /> },
    { id: "", label: "", padding: "none" },
];

interface IContactPersonTableProps {
    loading: boolean;
    pagination: IPaginationStore<IInvitation>;
    handleRemove: (invitation: IInvitation) => void;
    permissions: Record<string, string>;
}

interface IBaseState {
    selectedInvitation?: IInvitation;
    isConfirmOpen: boolean;
}

const initialState = {
    isConfirmOpen: false,
} as IBaseState;

const InvitationsTable: React.FC<IContactPersonTableProps> = ({ loading, pagination, handleRemove, permissions }) => {
    const [state, setState] = React.useState<IBaseState>(initialState);

    const mapRows = (invitations: IInvitation[]): IRow[] => {
        return (
            invitations &&
            invitations.map((invitation) => ({
                key: invitation.uuid,
                data: [
                    `${invitation.first_name} ${invitation.last_name}`,
                    invitation.email,
                    invitation.phone ? prettifyPhoneNumber(invitation.phone) : "-",
                    invitation.role ? invitation.role.display_name : "-",
                    invitation.can_manage && (
                        <HasPermission requiredPerm={permissions["delete"]}>
                            <IconButton
                                size="small"
                                data-testid={`delete_invitation_${invitation.email}`}
                                key={`delete_button_${invitation.email}`}
                                onClick={() =>
                                    setState({
                                        ...state,
                                        selectedInvitation: invitation,
                                        isConfirmOpen: true,
                                    })
                                }
                            >
                                <DeleteOutlineIcon />
                            </IconButton>
                        </HasPermission>
                    ),
                ],
            }))
        );
    };

    const onDeleteInvitation = (): void => {
        if (state.selectedInvitation) {
            handleRemove(state.selectedInvitation);
            setState(initialState);
        }
    };

    return (
        <Box>
            <Typography variant="h6">
                <LocalizeText tag="invitations" />
            </Typography>
            <TableComponent
                headCells={invitationHeadCells}
                isLoading={loading}
                rows={mapRows(pagination?.results)}
                pagination={pagination}
            />
            <ConfirmDialog
                open={state.isConfirmOpen}
                title={<LocalizeText tag="removeInvitation" />}
                description={<LocalizeText tag="removePendingUserDescription" />}
                onReject={() => setState(initialState)}
                onAccept={onDeleteInvitation}
            />
        </Box>
    );
};

export default InvitationsTable;
