import { DescriptionOutlined, HistoryEduRounded } from "@mui/icons-material";
import { Grid, Tooltip } from "@mui/material";
import VKButton from "components/vkButton";
import { LocalizeText } from "components/localizer";
import { format, parseISO } from "date-fns";
import React from "react";
import colors from "styles/colors";
import { MembershipDocumentTypes } from "adapters/documents";
import { dateFormats } from "utils/formats";

interface DocumentButtonProps {
    document_file: string;
    document_type: MembershipDocumentTypes;
    document_nr?: string;
    created?: string;
    onDelete?: () => void;
}

export const DocumentButton: React.FC<DocumentButtonProps> = ({
    document_file,
    document_type,
    document_nr,
    created,
    onDelete,
}) => {
    const full = document_file;
    let endIndex = full.indexOf("?");
    if (endIndex === -1) {
        endIndex = full.length;
    }
    const partial = full.substring(0, endIndex);
    const startIndex = partial.lastIndexOf("/") + 1;
    const name = decodeURIComponent(partial.substring(startIndex));

    let tooltipContent = null;
    if (onDelete) {
        tooltipContent = <LocalizeText tag="remove" />;
    } else {
        tooltipContent = (
            <div style={{ display: "flex", flexDirection: "column" }}>
                <span>
                    <LocalizeText tag="type" />:{" "}
                    <LocalizeText tag={document_type === "agreement" ? "agreement" : "other"} />
                </span>
                {created ? (
                    <span>
                        <LocalizeText tag="uploaded" />: {format(parseISO(created ?? ""), dateFormats.WEBDATE)}
                    </span>
                ) : null}
                {document_nr ? (
                    <span>
                        <LocalizeText tag="documentNr" />: {document_nr}
                    </span>
                ) : null}
            </div>
        );
    }

    return (
        <Grid item>
            <Tooltip placement="left" arrow title={tooltipContent}>
                <div>
                    <VKButton
                        variant="outlined"
                        sx={{
                            padding: "3px 10px",
                            margin: "0",
                            fontSize: "1rem",
                            borderRadius: "5px",
                            flexGrow: 1,
                            "& div": {
                                padding: "0 !important",
                                gap: "15px",
                                justifyContent: "flex-start",
                            },
                            "&:hover": {
                                backgroundColor: onDelete ? colors.errorRed : "",
                                borderColor: onDelete ? colors.errorRed : "",
                            },
                        }}
                        onClick={() => {
                            if (onDelete) {
                                onDelete();
                            } else {
                                window.open(document_file);
                            }
                        }}
                        fullWidth
                    >
                        {document_type === "agreement" ? <HistoryEduRounded /> : <DescriptionOutlined />}
                        <span style={{ textAlign: "start" }}>{name}</span>
                    </VKButton>
                </div>
            </Tooltip>
        </Grid>
    );
};
