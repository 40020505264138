import React, { useEffect, useState } from "react";
import { LocalizeText } from "components/localizer";
import { ListItem, ListItemText, TextField } from "@mui/material";
import ListInlineEditWrapper from "./listComponents/listInlineEditWrapper";
import { TLanguageTag } from "contexts/language";
import { prettifyOrgNumber } from "utils/helpers";

interface IProps {
    tag: TLanguageTag;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    obj: any;
    prop: string;
    onSubmitUpdate: (props: Record<string, unknown>) => void;
    canEdit?: boolean;
    helperProp?: string;
    editDisabled?: boolean;
}

const EditableTextField: React.FC<IProps> = ({ tag, obj, helperProp = "", prop, onSubmitUpdate, canEdit, editDisabled = false }) => {
    const [text, setText] = React.useState<string>(
        helperProp ? (obj[helperProp][prop] as string) : (obj[prop] as string)
    );
    const [edited, setEdited] = useState<boolean>(false);

    useEffect(() => {
        if (tag === "orgNumber" && !edited) {
            setText(prettifyOrgNumber(obj[helperProp][prop]));
        }
    }, [text, tag, edited, obj, helperProp, prop]);

    useEffect(() => {
        setEdited(false);
        setText(helperProp ? (obj[helperProp][prop] as string) : (obj[prop] as string));
    }, [obj, helperProp, prop]);

    if (!canEdit) {
        return (
            <ListItem>
                <ListItemText primary={<LocalizeText tag={tag} />} secondary={obj[prop] || "-"} />
            </ListItem>
        );
    }

    const getParams = (): {
        [key: string]:
            | {
                  [key: string]: string;
              }
            | string;
    } => {
        if (helperProp) {
            return { [helperProp]: { [prop]: text } };
        }

        return { [prop]: text };
    };

    return (
        <ListInlineEditWrapper
            onChange={(e) => {
                if (!edited) {
                    setEdited(true);
                }
                setText(e.target.value);
            }}
            onSubmit={() => {
                onSubmitUpdate(getParams());
                setEdited(false);
            }}
            onCancel={() => setText((helperProp ? (obj[helperProp][prop] as string) : (obj[prop] as string)) ?? "")}
            label={<LocalizeText tag={tag} />}
            renderInput={(props) => <TextField {...props} value={text} />}
            renderView={(props) => <ListItemText {...props} secondary={text || "-"} />}
            inputIsValid={text.length > 0}
            editDisabled={editDisabled}
        />
    );
};

export default EditableTextField;
