import webAPIRequest from "api";
import { AxiosResponse } from "axios";

export const fetchLeasesContactsAdapter = async (
    lease_uuid: string,
    params?: Record<string, unknown>
): Promise<AxiosResponse> => {
    return webAPIRequest("get", `/vk_data/leases/${lease_uuid}/contact-persons/`, { params });
};

export const fetchDebitsAdapter = async (params?: Record<string, unknown>): Promise<AxiosResponse> => {
    return webAPIRequest("get", "/vk_data/debits/", { params });
};

export const sendLeaseQuestion = async (data: FormData): Promise<AxiosResponse> => {
    return webAPIRequest("post", "/vk_data/leases/mail-questions/", {
        data,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

export const fetchLeases = async (params?: Record<string, unknown>): Promise<AxiosResponse> => {
    return webAPIRequest("get", "/vk_data/leases/", { params });
};
