import React from "react";
import { DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";
import VKButton from "components/vkButton";
import { ModalContext } from "contexts/modals";
import { LocalizeText } from "components/localizer";
import { TLanguageTag } from "contexts/language";
interface ConfirmNewContactProps {
    onAccept: () => Promise<boolean>;
}

const ConfirmNewContactDialog: React.FC<ConfirmNewContactProps> = ({ onAccept }) => {
    const { popDialog, closeDrawers } = React.useContext(ModalContext);

    const onClickAccept = async (): Promise<void> => {
        const success = await onAccept();
        if (success) {
            popDialog();
            closeDrawers();
        }
    };

    return (
        <>
            <DialogTitle>
                <LocalizeText tag={"confirmNewContact" as TLanguageTag} />
            </DialogTitle>
            <Divider />
            <DialogContent>
                <LocalizeText tag={"confirmNewContactDescription" as TLanguageTag} />
            </DialogContent>
            <DialogActions>
                <VKButton tag="deny" template="cancel" onClick={() => popDialog()} />
                <VKButton tag="accept" template="save" onClick={onClickAccept} />
            </DialogActions>
        </>
    );
};

export default ConfirmNewContactDialog;
