import React, { FC, ReactNode, Reducer, createContext, useCallback, useContext, useMemo, useReducer } from "react";
import { format } from "date-fns";
import webAPIRequest from "api";
import reducer, { IArenas, IAction, IArenaBuilding, initialState, IArena } from "reducers/arenas";
import { NotificationContext } from "contexts/notification";
import { dateFormats } from "utils/formats";

export const ArenasContext = createContext<IArenas>({
    ...initialState,
});

export const ArenasProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const { ...notification } = useContext(NotificationContext);
    const [currentState, dispatch] = useReducer<Reducer<IArenas, IAction>>(reducer, initialState);

    const cancelArena = useCallback(
        async (officesubscription_uuid: string, endDate: Date): Promise<boolean> => {
            try {
                dispatch({ type: "CANCEL_ARENA" });
                const data = {
                    end_date: format(endDate, dateFormats.WEBDATE),
                };
                await webAPIRequest("patch", `/offices/${officesubscription_uuid}/cancel/`, { data });

                dispatch({ type: "CANCEL_ARENA_SUCCESS" });
                notification.enqueNotification("success_cancelArena");
                return true;
            } catch (error) {
                notification.enqueNotification("error_cancelArena", error);
                dispatch({ type: "CANCEL_ARENA_FAILURE" });
                return false;
            }
        },
        [notification]
    );

    const getBariumRequestMoreArenasURL = useCallback(
        async (membershipId: number): Promise<boolean> => {
            try {
                dispatch({ type: "SET_UP_BARIUM" });
                const params = {
                    membership_id: membershipId,
                };
                const returnData = await webAPIRequest("get", "/offices/form-url/", { params });
                if (returnData && returnData.data && returnData.data.form_url) {
                    window.open(returnData.data.form_url);
                    dispatch({ type: "SET_UP_BARIUM_SUCCESS" });
                    return true;
                }
                throw new Error("No valid url to barium.");
            } catch (error) {
                notification.enqueNotification("error_openBariumForm", error);
                dispatch({ type: "SET_UP_BARIUM_FAILURE" });
                return false;
            }
        },
        [notification]
    );

    const getArenaBuilding = useCallback(
        async (arenaBuildingUuid: string): Promise<IArenaBuilding> => {
            try {
                dispatch({ type: "FETCH_ARENA_BUILDING" });
                const url = `/memberships/buildings/${arenaBuildingUuid}`;
                const returnData = await webAPIRequest("get", url);
                dispatch({
                    type: "FETCH_ARENA_BUILDING_SUCCESS",
                    data: returnData.data,
                });
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_fetchBuildings", error);
                dispatch({ type: "FETCH_AREMA_BUILDING_FAILURE" });
                return {} as IArenaBuilding;
            }
        },
        [notification]
    );

    const updateArena = useCallback(
        async (uuid: string, data: Record<string, unknown>): Promise<IArena> => {
            try {
                dispatch({ type: "UPDATE_ARENA" });
                const returnData = await webAPIRequest("patch", `/offices/${uuid}/`, { data });
                dispatch({
                    type: "UPDATE_ARENA_SUCCESS",
                });
                notification.enqueNotification("success_updateArena");
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_updateSubscription", error);
                dispatch({ type: "UPDATE_ARENA_FAILURE" });
                return {} as IArena;
            }
        },
        [notification]
    );

    const value = useMemo(() => {
        return {
            ...currentState,
            cancelArena,
            getBariumRequestMoreArenasURL,
            getArenaBuilding,
            updateArena,
        };
    }, [currentState, cancelArena, getBariumRequestMoreArenasURL, getArenaBuilding, updateArena]);

    return <ArenasContext.Provider value={value}>{children}</ArenasContext.Provider>;
};

export default ArenasContext;
