import React from "react";
import {useNavigate } from "react-router-dom";
import Banner from "components/banner";
import BuildingList from "components/buildings/buildingList";
import { usePaginationStore } from "utils/usePaginationStore";

const ArenaBuildings: React.FC = () => {
    const pagination = usePaginationStore("arena-buildings", undefined);
    const navigate = useNavigate();

    const navigateToDetails = (arenaUuid: string): void => {
        navigate(`/arena-buildings/${arenaUuid}/`);
    };

    return (
        <div data-testid="buildings-root" className="contentWrapper">
            <Banner
                title="bannerArenaInformationTitle"
                subTitle="bannerArenaInformation"
                image={`${process.env.PUBLIC_URL}/assets/images/1024x683/memberships-1024x683.jpg`}
            />
            <BuildingList
                loading={pagination.query.isFetching}
                buildings={pagination.results}
                navigateToDetails={navigateToDetails}
                isArenaBuildings={true}
            />
        </div>
    );
};

export default ArenaBuildings;
