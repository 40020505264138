import React, { FC, FormEvent, useContext, useEffect, useState } from "react";
import VKButton from "components/vkButton";
import { LocalizeText } from "components/localizer";
import { AuthContext } from "contexts/auth";
import { ProfileContext } from "contexts/profile";
import Banner from "components/banner";
import { Box, Checkbox, Divider, FormControlLabel, Grid, styled, Typography } from "@mui/material";
import { getConfirmedSmsDevice, hasAConfirmedDevice } from "components/2FA/utils";
import ProfileViewDrawerGroup, { TDrawer } from "components/drawers/profileViewDrawerGroup";
import { ListTextField } from "components/drawers/drawerComponents";
import validator from "utils/validator";
import { LanguageContext } from "contexts/language";
import ChangePassWordFormDialog from "components/profile/changePasswordForm";
import Dropdown, { TDropdownItem } from "components/dropdown";
import { ModalContext } from "contexts/modals";
import colors from "styles/colors";
import { EventSubscriptionsSettings } from "components/events/eventSubscriptionsSettings";
import HasPermission from "components/__helpers__/hasPermission";

const ProfileDivider = styled(Divider)(({ theme }) => ({
    marginBottom: "10px",
}));

const SectionHeader = styled(Typography)(({ theme }) => ({
    marginTop: "30px",
    marginBottom: "10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
}));

const SectionSubHeader = styled(Typography)(({ theme }) => ({
    margin: "10px 0",
    fontWeight: "bold",
}));

const ActionRow = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 30,
    "& .MuiListItem-gutters": {
        paddingLeft: 0,
    },
}));

const RightFloatButton = styled(VKButton)(({ theme }) => ({
    width: 300,
    minWidth: 300,
    marginLeft: 30,
}));

interface IState {
    firstName: string;
    lastName: string;
    phone: string;
    isEditingInfo: boolean;
    drawerType: TDrawer;
    sendNewsByMail: boolean;
}

const ProfileView: FC = () => {
    const { isFetchingProfile, user, updateUserProfile } = useContext(ProfileContext);
    const {
        fetchTotpDevices,
        isFetchingTotpDevices,
        totpDevices,
        createTotpDevice,
        isCreatingTotpDevice,
        fetchSmsDevices,
        isFetchingSmsDevices,
        smsDevices,
        fetchBackupCodes,
        isFetchingBackupCodes,
        backupCodes,
    } = useContext(AuthContext);

    const { localize } = useContext(LanguageContext);
    const { pushDialog } = useContext(ModalContext);

    const [state, setState] = useState<IState>({
        firstName: "",
        lastName: "",
        phone: "",
        isEditingInfo: false,
        drawerType: "",
        sendNewsByMail: true,
    });

    useEffect(() => {
        if (user && Object.keys(user).length) {
            setState({
                ...state,
                firstName: user.first_name,
                lastName: user.last_name,
                phone: user.phone,
                sendNewsByMail: user.send_news_by_mail,
            });
        }
        if (!isFetchingTotpDevices && !totpDevices) {
            fetchTotpDevices();
        }
        if (!isFetchingSmsDevices && !smsDevices) {
            fetchSmsDevices();
        }
        if (!isFetchingBackupCodes && !backupCodes) {
            fetchBackupCodes();
        }
        // eslint-disable-next-line
    }, []);

    const onChange = (type: keyof IState, value: string): void => {
        setState({ ...state, [type]: value });
    };

    const setDrawerState = (newState: TDrawer): void => {
        setState({ ...state, drawerType: newState });
    };

    if (!user || isFetchingProfile || !totpDevices || !smsDevices || !backupCodes) {
        return null;
    }

    const onSubmitEditInfo = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();
        const toUpdate = {};
        if (state.firstName !== user.first_name) {
            toUpdate["first_name"] = state.firstName;
        }
        if (state.lastName !== user.last_name) {
            toUpdate["last_name"] = state.lastName;
        }
        if (state.phone !== user.phone) {
            toUpdate["phone"] = state.phone;
        }
        if (Object.keys(toUpdate).length) {
            await updateUserProfile(toUpdate);
        }
        setState({
            ...state,
            isEditingInfo: false,
        });
    };

    const setSendNewsByMail = async (sendNewsByMail: boolean): Promise<void> => {
        await updateUserProfile({ send_news_by_mail: sendNewsByMail });

        setState({
            ...state,
            sendNewsByMail: sendNewsByMail,
        });
    };

    const onCancelEditInfo = (): void => {
        setState({
            ...state,
            isEditingInfo: false,
            firstName: user.first_name,
            lastName: user.last_name,
            phone: user.phone,
        });
    };

    const getDropDownItems = (): TDropdownItem[] => {
        return [
            {
                onClick: () => setState({ ...state, isEditingInfo: true }),
                label: <LocalizeText tag="editProfile" />,
            },
            {
                onClick: () => pushDialog(ChangePassWordFormDialog, {}),
                label: <LocalizeText tag="changePassword" />,
            },
        ];
    };

    const { firstName, lastName, phone, isEditingInfo, drawerType } = state;
    const smsDevice = getConfirmedSmsDevice(smsDevices);
    const phoneNumberIsValid = validator.phoneNumber(phone);
    const hasConfirmedTotpDevice = hasAConfirmedDevice(totpDevices);
    let confirmedAppsCount = 0;
    totpDevices.forEach((d) => {
        if (d.confirmed) {
            confirmedAppsCount += 1;
        }
    });

    return (
        <div className="contentWrapper">
            <Banner title="profile" />
            <Box
                sx={{
                    backgroundColor: colors.white,
                    marginTop: "40px",
                    padding: "30px",
                    fontWeight: "normal",
                }}
            >
                <SectionHeader variant="h5">
                    <LocalizeText tag="contactInformation" />
                    <Dropdown items={getDropDownItems()} />
                </SectionHeader>
                <ProfileDivider />
                <form onSubmit={onSubmitEditInfo}>
                    <Grid
                        container
                        spacing={2}
                        sx={{
                            "& li": {
                                paddingLeft: 0,
                                paddingRight: 0,
                            },
                        }}
                    >
                        <Grid item xs={12} md={6} xl={4}>
                            <ListTextField
                                hasData
                                label={<LocalizeText tag="firstName" />}
                                data={user.first_name}
                                editingData={firstName}
                                isEditing={isEditingInfo}
                                onChange={(e) => onChange("firstName", e.target.value)}
                            />
                            <ListTextField
                                hasData
                                notValid={!phoneNumberIsValid}
                                label={<LocalizeText tag="phoneNumber" />}
                                data={user.phone}
                                editingData={phone}
                                isEditing={isEditingInfo}
                                onChange={(e) => onChange("phone", e.target.value)}
                                errorText={<LocalizeText tag="errorMobile" />}
                                placeholder={localize("mobileNumberHelperText")}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} xl={4}>
                            <ListTextField
                                hasData
                                label={<LocalizeText tag="lastName" />}
                                data={user.last_name}
                                editingData={lastName}
                                isEditing={isEditingInfo}
                                onChange={(e) => onChange("lastName", e.target.value)}
                            />
                            <ListTextField
                                notRequired
                                style={{ padding: 0 }}
                                label={<LocalizeText tag="email" />}
                                data={user.email}
                                hasData
                                isEditing={isEditingInfo}
                                disabled={isEditingInfo}
                            />
                        </Grid>
                        <Grid container item xs={12} xl={8} alignItems="center" justifyContent="flex-end" gap="8px">
                            {isEditingInfo && (
                                <>
                                    <VKButton template="save" tag="save" type="submit" disabled={!phoneNumberIsValid} />
                                    <VKButton template="cancel" tag="cancel" onClick={onCancelEditInfo} />
                                </>
                            )}
                        </Grid>
                    </Grid>
                </form>
                <SectionHeader variant="h5">
                    <LocalizeText tag="newsletter" />
                </SectionHeader>
                <ProfileDivider />
                <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={state.sendNewsByMail}
                            onChange={(e) => setSendNewsByMail(e.target.checked)}
                            name="newsletter"
                        />
                    }
                    label={<LocalizeText tag="newsletterYes" />}
                />
                <HasPermission requiredPerm="add_publiceventsubscription">
                    <SectionHeader variant="h5">
                        <LocalizeText tag="events" />
                    </SectionHeader>
                    <ProfileDivider />
                    <LocalizeText tag="eventNotificationsExplanation" />
                    <EventSubscriptionsSettings />
                </HasPermission>
                <SectionHeader variant="h5">
                    <LocalizeText tag="2fa" />
                </SectionHeader>
                <ProfileDivider />
                <LocalizeText tag="2faRequired" />
                <SectionSubHeader variant="subtitle1">
                    <LocalizeText tag="authenticationApp" />
                </SectionSubHeader>
                <ActionRow>
                    <LocalizeText tag="methodAppInfo" replace={{ count: confirmedAppsCount.toString() }} />
                    {hasConfirmedTotpDevice ? (
                        <RightFloatButton
                            tag="editAppDevices"
                            template="primary"
                            onClick={() => setState({ ...state, drawerType: "EDIT_TOTP_DEVICES" })}
                        />
                    ) : (
                        <RightFloatButton
                            tag="addMethodApp"
                            template="primary"
                            onClick={async (): Promise<void> => {
                                let success = true;
                                if (totpDevices.length === 0) {
                                    success = await createTotpDevice("My new device");
                                }
                                success && setState({ ...state, drawerType: "ADD_TOTP_DEVICE" });
                            }}
                            isLoading={isCreatingTotpDevice}
                        />
                    )}
                </ActionRow>

                <SectionSubHeader variant="subtitle1">
                    <LocalizeText tag="sms" />
                </SectionSubHeader>
                <ActionRow>
                    <LocalizeText tag="methodSMSInfo" replace={{ number: smsDevice?.number ?? "-" }} />
                    {smsDevice?.confirmed ? (
                        <Grid>
                            <RightFloatButton
                                tag="removeMethodSMS"
                                template="primary"
                                onClick={() => setState({ ...state, drawerType: "CONFIRM_REMOVE_SMS" })}
                                disabled={!hasConfirmedTotpDevice}
                            />
                            <RightFloatButton
                                sx={{ marginTop: 1 }}
                                tag="updateMethodSMS"
                                template="primary"
                                onClick={(): void => {
                                    setState({ ...state, drawerType: "ADD_SMS_DEVICE" });
                                }}
                            />
                        </Grid>
                    ) : (
                        <RightFloatButton
                            tag="addMethodSMS"
                            template="primary"
                            onClick={(): void => {
                                setState({ ...state, drawerType: "ADD_SMS_DEVICE" });
                            }}
                        />
                    )}
                </ActionRow>

                <SectionSubHeader variant="subtitle1">
                    <LocalizeText tag="backupWithCodes" />
                </SectionSubHeader>
                <ActionRow>
                    <LocalizeText
                        tag="methodBackupCodesInfo"
                        replace={{ remaining: backupCodes ? backupCodes.length.toString() : "?" }}
                    />
                    <RightFloatButton
                        tag="showBackupCodes"
                        template="primary"
                        onClick={() => setState({ ...state, drawerType: "BACKUP_CODES" })}
                    />
                </ActionRow>
            </Box>

            <ProfileViewDrawerGroup drawerType={drawerType} setDrawerState={setDrawerState} smsDevice={smsDevice} />
        </div>
    );
};

export default ProfileView;
