import React from "react";
import { LocalizeText } from "components/localizer";
import { THeadCell } from "utils/types";
import TableComponent, { IRow } from "components/tableComponent";
import { TParamValue } from "utils/paginationStore";
import { getLeaseValidToDate } from "utils/ecommerseTypes";
import Dropdown, { TDropdownItem } from "components/dropdown";
import { useNavigate } from "react-router-dom";
import SearchField from "components/tableComponents/paginationSearchField";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { ProfileContext } from "contexts/profile";
import { NotificationContext } from "contexts/notification";
import { ModalContext } from "contexts/modals";
import AddLeaseContactPerson from "components/drawers/contactPersons/addLeaseContact";
import RequestAccessToLease from "components/drawers/contactPersons/requestAccessToLease";
import { usePermission } from "utils/hooks/usePermission";
import { ILease } from "reducers/leases";
import LeasesContext from "contexts/leases";
import WarningIcon from "@mui/icons-material/Warning";
import colors from "styles/colors";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import { usePaginationStore } from "utils/usePaginationStore";

interface RoleManagementLeaseTableProps {
    paginationParams: Record<string, TParamValue>;
    showSearch: boolean;
    hideRole?: boolean;
    disableNonActive?: boolean;
}

const RoleManagementLeaseTable: React.FC<RoleManagementLeaseTableProps> = ({
    paginationParams,
    showSearch,
    hideRole = false,
    disableNonActive = true,
}) => {
    const { requestAccessToLease } = React.useContext(LeasesContext);
    const { user, selectedOrganization, fetchPremiseContracts } = React.useContext(ProfileContext);
    const { openDrawer } = React.useContext(ModalContext);
    const { ...notification } = React.useContext(NotificationContext);

    const navigate = useNavigate();
    const { hasPermission } = usePermission();

    const leasePagination = usePaginationStore("leases", undefined, paginationParams);

    const leaseHeadCells: THeadCell[] = [
        { id: "address", sortId: "premise__address", label: <LocalizeText tag="address" /> },
        { id: "agreement_number", sortId: "premise__agreement_number", label: <LocalizeText tag="contractNumber" /> },
        { id: "valid_from", sortId: "valid_from_date", label: <LocalizeText tag="from" /> },
        { id: "valid_to", sortId: "valid_to_date", label: <LocalizeText tag="until" /> },
        ...(hideRole ? [] : [{ id: "role", label: <LocalizeText tag="role" /> }]),
        { id: "", label: "" },
    ];

    const mapRows = (leases: ILease[]): IRow[] => {
        return leases.map((lease: ILease) => ({
            disabled: !lease.is_active && disableNonActive,
            data: [
                lease.address,
                lease.agreement_number ?? "-",
                lease.valid_from_date,
                getLeaseValidToDate(lease.valid_to_date),
                ...(hideRole ? [] : [lease.role_display_name]),
                <Dropdown inRow key={lease.lease_uuid} items={getDropdownItems(lease)} />,
            ],
            prependIcons: getPrependIcons(lease),
        }));
    };

    const getPrependIcons = (lease: ILease): JSX.Element[] => {
        const icons = [];
        if (!lease.is_active) {
            icons.push(
                <Tooltip key="noPremiseContract" placement="top" title={<LocalizeText tag="noPremiseContract" />}>
                    <WarningIcon sx={{ color: colors.warning }} />
                </Tooltip>
            );
        }
        if (lease.recently_added) {
            icons.push(
                <Tooltip key="recentlyAdded" placement="top" title={<LocalizeText tag="recentlyAddedLease" />}>
                    <NewReleasesIcon sx={{ color: colors.successGreen }} />
                </Tooltip>
            );
        }
        return icons;
    };

    const getDropdownItems = (lease: ILease): TDropdownItem[] => {
        const items = [
            {
                onClick: () => navigate(`/representatives/lease/${lease.lease_uuid}/`),
                label: <LocalizeText tag="showRepresentatives" />,
            },
        ];
        if (lease.has_access_permission) {
            if (lease.is_active) {
                items.push({
                    onClick: () => onOpenPremiseContractAgreement(lease.lease_uuid),
                    label: <LocalizeText tag="showPremiseContractAgreement" />,
                });
            }
            if (!user?.is_staff && hasPermission("add_leaseinvitation")) {
                items.push({
                    onClick: () => openDrawer(AddLeaseContactPerson, { lease }, "addNewRepresentative"),
                    label: <LocalizeText tag="addNewRepresentative" />,
                });
            }
        } else if (hasPermission("request_leaseinvitation")) {
            items.push({
                onClick: () =>
                    openDrawer(
                        RequestAccessToLease,
                        {
                            onSubmit: requestAccessToLease,
                            lease,
                        },
                        "requestAccess"
                    ),
                label: <LocalizeText tag="requestAccess" />,
            });
        }
        return items;
    };

    const onOpenPremiseContractAgreement = async (lease_uuid: string): Promise<void> => {
        const premisesWithLease = await fetchPremiseContracts({ lease_uuid: lease_uuid });
        const validPremises = premisesWithLease.results.filter((premise) => {
            return premise.organization.org_nr === selectedOrganization.org_nr && premise.agreement_document_file;
        });
        if (validPremises.length > 0) {
            validPremises.forEach((premise) => {
                window.open(premise.agreement_document_file);
            });
        } else {
            notification.enqueNotification("error_premise_contract_document_file_not_found");
        }
    };

    return (
        <Box mb="2em">
            <Grid container alignItems="center" style={{ marginBottom: "1em" }}>
                <Grid item xs={6}>
                    <Typography variant="h6">
                        <LocalizeText tag="lease" />
                    </Typography>
                </Grid>
                <Grid container item xs={6} justifyContent="flex-end">
                    {showSearch && <SearchField pagination={leasePagination} tag="searchLease" />}
                </Grid>
            </Grid>
            <TableComponent
                headCells={leaseHeadCells}
                isLoading={leasePagination.query.isFetching}
                rows={mapRows(leasePagination.results)}
                pagination={leasePagination}
                hasPrependIcons
            />
        </Box>
    );
};

export default RoleManagementLeaseTable;
