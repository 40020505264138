import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import VKButton from "components/vkButton";
import { LocalizeText } from "components/localizer";
import { TFormEvent } from "utils/types";
import { ListTextField, SelectBox, TItem } from "components/drawers/drawerComponents";
import { IContactPerson } from "reducers/memberships";
import { IRole } from "reducers/roleManagement";
import { TLanguageTag } from "contexts/language";

export interface IRequestAccessObject {
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
}

interface IProps {
    isLoading: boolean;
    onSubmit: (requestedContactPersonEmail: string, role?: IRole) => void;
    existing: IContactPerson[];
    titleTag: TLanguageTag;
    contractData: string;
    helperTextTag: TLanguageTag;
    loadMoreContacts: () => Promise<void>;
}
const RequestAccessBase: React.FC<IProps> = ({
    existing,
    isLoading,
    onSubmit,
    titleTag,
    contractData,
    helperTextTag,
    loadMoreContacts,
}) => {
    const [requestedContactPerson, setRequestedContactPerson] = React.useState<IContactPerson>();
    React.useEffect(() => {
        if (existing && existing.length === 1) {
            setRequestedContactPerson(existing[0]);
        }
    }, [existing]);

    const handleSubmit = (event: TFormEvent): void => {
        event.preventDefault();
        if (requestedContactPerson) {
            onSubmit(requestedContactPerson.email, requestedContactPerson.role);
        }
    };

    const validateData = (): boolean => {
        return !!requestedContactPerson;
    };

    const getContactPersonAsString = (person: IContactPerson): string => {
        if (!person) {
            return "-";
        }
        if (person.role) {
            return `${person.first_name} ${person.last_name} (${person.role.display_name})`;
        }
        return `${person.first_name} ${person.last_name}`;
    };

    const mapContactPersons = (): TItem[] => {
        return (existing || []).reduce((list: TItem[], person) => {
            list.push({
                name: getContactPersonAsString(person),
                value: person.email,
            });
            return list;
        }, []);
    };

    const loadMoreItems = async (event: React.BaseSyntheticEvent): Promise<void> => {
        const scrollLength = event.target.scrollHeight - Math.abs(event.target.scrollTop);
        if (scrollLength === event.target.clientHeight) {
            await loadMoreContacts();
        }
    };

    return (
        <div data-testid="requestAccessToAgreement-root">
            <List>
                <ListTextField
                    testid="requestAccess-header"
                    hasData
                    label={<LocalizeText tag={titleTag} />}
                    data={contractData}
                />
                <ListItem>
                    <LocalizeText tag={helperTextTag} />
                </ListItem>
                <form onSubmit={handleSubmit}>
                    {existing && existing.length === 1 ? (
                        <ListTextField
                            label={<LocalizeText tag="contactPerson" />}
                            testid="single-contact-field"
                            hasData
                            isEditing
                            disabled
                            data={getContactPersonAsString(existing[0])}
                        />
                    ) : (
                        <SelectBox
                            testid="select-contact"
                            onScroll={loadMoreItems}
                            label={<LocalizeText tag="contactPerson" />}
                            data={requestedContactPerson?.email ?? ""}
                            onEdit={(email: string) =>
                                setRequestedContactPerson(existing.find((u) => u.email === email))
                            }
                            items={mapContactPersons()}
                        />
                    )}
                    <ListItem>
                        <VKButton
                            data-testid="requestAccess-submitButton"
                            id="button"
                            tag="sendRequest"
                            type="submit"
                            template="save"
                            disabled={!validateData()}
                            isLoading={isLoading}
                        />
                    </ListItem>
                </form>
            </List>
        </div>
    );
};

export default RequestAccessBase;
