import React from "react";
import { DialogActions, DialogContent, DialogTitle, Divider, TextField } from "@mui/material";
import { LeaseQuestionType, LeaseSubQuestionType } from "utils/types";
import VKButton from "components/vkButton";
import { LocalizeText } from "components/localizer";
import FileUploadField from "components/fileUpload";
import { ModalContext } from "contexts/modals";
import CreateTaskForm from "components/tasks/createTaskForm";
import { sendLeaseQuestion } from "adapters/leasesAdapter";
import { NotificationContext } from "contexts/notification";
import { useIsLoading } from "utils/hooks/useLoading";
import QuestionHelpText from "./leaseQuestion/questionHelpText";
import SelectQuestionType, { SelectSubQuestionType } from "./leaseQuestion/selectQuestionType";

interface QuestionProps {
    lease_uuid: string;
    invoice_number?: string;
}
interface IFormState {
    mail_body: string;
    files: File[];
}

const initialState = {
    mail_body: "",
    files: [],
};

const SendLeaseQuestion: React.FC<QuestionProps> = ({ lease_uuid, invoice_number }) => {
    const { popDialog, pushDialog } = React.useContext(ModalContext);
    const { enqueNotification } = React.useContext(NotificationContext);

    const [questionType, setQuestionType] = React.useState<LeaseQuestionType>("LEASE_INVOICE_QUESTION");
    const [subQuestionType, setSubQuestionType] = React.useState<LeaseSubQuestionType>("GENERAL_INVOICE_QUESTION");
    const [formState, setFormState] = React.useState<IFormState>(initialState);
    const [isSendingQuestion, setIsSendingQuestion] = useIsLoading(false);

    const onChangeQuestionType = (newType: LeaseQuestionType): void => {
        if (newType === "ERROR_REPORT") {
            popDialog();
            pushDialog(CreateTaskForm, {});
        } else {
            setQuestionType(newType);
        }
    };

    const onFileUploadChange = (files: File[]): void => {
        setFormState({ ...formState, files: files });
    };

    const formatMailBody = (userInput: string): string => {
        if (!invoice_number) {
            return userInput;
        }
        return `Frågan gäller fakturanummer: ${invoice_number}. \n\n` + userInput;
    };

    const onSubmitQuestion = async (): Promise<void> => {
        setIsSendingQuestion(true);
        const data = new FormData();
        data.append("lease_uuid", lease_uuid);
        data.append("mail_body", formatMailBody(formState.mail_body));
        if (questionType === "LEASE_INVOICE_QUESTION") {
            data.append("action", subQuestionType);
        } else {
            data.append("action", questionType);
        }
        formState.files.forEach((file) => {
            data.append("attachments", file);
        });
        try {
            await sendLeaseQuestion(data);
            enqueNotification("success_sendQuestion");
            popDialog();
        } catch (error) {
            enqueNotification("error_sendQuestion", error);
        }
        setIsSendingQuestion(false);
    };

    return (
        <>
            <DialogTitle>
                <LocalizeText tag={"contactVasakronan"} />
            </DialogTitle>
            <Divider />
            <DialogContent>
                <SelectQuestionType
                    sx={{
                        margin: "1em 0 1em 0",
                        padding: "4px 0",
                    }}
                    disabled={!!invoice_number}
                    variant="outlined"
                    onChange={(event) => onChangeQuestionType(event.target.value as LeaseQuestionType)}
                    value={questionType}
                />
                {questionType === "LEASE_INVOICE_QUESTION" && (
                    <SelectSubQuestionType
                        sx={{
                            margin: "1em 0 1em 0",
                            padding: "4px 0",
                        }}
                        variant="outlined"
                        onChange={(event) => setSubQuestionType(event.target.value as LeaseSubQuestionType)}
                        value={subQuestionType}
                    />
                )}
                <QuestionHelpText questionType={questionType} invoiceNumber={invoice_number} />
                <TextField
                    required
                    multiline
                    fullWidth
                    minRows={4}
                    name="description"
                    sx={{
                        margin: "1em 0 1em 0",
                        padding: "4px 0",
                    }}
                    value={formState.mail_body}
                    disabled={!questionType}
                    variant="outlined"
                    onChange={(event) => setFormState({ ...formState, mail_body: event.target.value })}
                    label={<LocalizeText tag="describeQuestion" />}
                />
                <FileUploadField
                    onFileUploadChange={onFileUploadChange}
                    files={formState.files}
                    accept=".pdf,.doc,.docx,.png,.jpeg,.xlsx,.xls,.txt"
                />
            </DialogContent>
            <DialogActions>
                <VKButton tag="close" template="cancel" onClick={popDialog} />
                <VKButton
                    tag="send"
                    template="primary"
                    disabled={!formState.mail_body.length}
                    onClick={onSubmitQuestion}
                    isLoading={isSendingQuestion}
                />
            </DialogActions>
        </>
    );
};

export default SendLeaseQuestion;
