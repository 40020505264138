import React, { FC, useContext, useMemo } from "react";
import { LocalizeText } from "components/localizer";
import { THeadCell } from "utils/types";
import TableComponent, { IRow } from "components/tableComponent";
import { ModalContext } from "contexts/modals";
import { MemberInfo } from "components/drawers/members/memberInfo";
import { getMemberStatusText } from "utils/helpers";
import { IUsePaginationStore } from "utils/usePaginationStore";

const headCells = (): THeadCell[] => {
    return [
        { id: "email", label: <LocalizeText tag="email" /> },
        { id: "name", label: <LocalizeText tag="name" /> },
        { id: "status", label: <LocalizeText tag="status" /> },
    ];
};

interface IProps {
    pagination: IUsePaginationStore<"members">;
}

export const MembersTable: FC<IProps> = ({ pagination }) => {
    const { openDrawer } = useContext(ModalContext);

    const rows = useMemo((): IRow[] => {
        return pagination.results.map((member) => ({
            onClick: () => openDrawer(MemberInfo, { member }, "memberInformation"),
            data: [
                member.email,
                member.first_name ? `${member.first_name} ${member.last_name}` : "-",
                getMemberStatusText(member),
            ],
        }));
    }, [pagination.results, openDrawer]);

    return (
        <TableComponent
            headCells={headCells()}
            isLoading={pagination.query.isFetching}
            skeletonVariant="options"
            pagination={pagination}
            rows={rows}
        />
    );
};
