import React, { FC, SyntheticEvent, useCallback, useEffect, useState } from "react";
import { DomainContentType, IRole } from "reducers/roleManagement";
import { RoleManagementContext } from "contexts/roleManagement";
import { TPaginationActionData } from "utils/paginationStore";
import { ModalContext } from "contexts/modals";
import { Grid, List, ListItem, ListItemText, MenuItem, Skeleton, TextField } from "@mui/material";
import { LocalizeText } from "components/localizer";
import CommunityContext from "contexts/community";
import VKButton from "components/vkButton";
import { CommunityStaffRoles, ICommunityStaff, IEditCommunityStaff, INewCommunityStaff } from "reducers/community";
import { TCommunity } from "utils/ecommerseTypes";
import ConfirmDialog from "components/confirmDialog";

interface IProps {
    user: ICommunityStaff;
    onConfirmRemove: () => void;
}

const UpdateCommunityStaff: FC<IProps> = ({ user, onConfirmRemove }) => {
    const { rolesPagination, fetchRolesOfDomain, isFetchingRoles } = React.useContext(RoleManagementContext);
    const { closeDrawers } = React.useContext(ModalContext);
    const {
        communitiesPagination,
        isFetchingCommunities,
        editCommunityStaff,
        isEditingCommunityStaff,
        deactivateCommunityStaff,
        isDeactivatingCommunityStaff,
    } = React.useContext(CommunityContext);

    const [editedStaffUser, setEditedStaffUser] = useState<IEditCommunityStaff>({
        communities: user.communities.flatMap((community) => community.uuid),
        mainCommunity: user.main_community?.uuid ?? "",
        communityStaffRole: user.community_staff_role as CommunityStaffRoles,
        role: user.role.id,
    });
    const [confirmRemoveStaff, setConfirmRemoveStaff] = React.useState(false);

    useEffect(() => {
        communitiesPagination.getInitial();
        rolesPagination.initialize(rolesPaginationCallback);
        rolesPagination.appendResults = true;
        rolesPagination.getInitial();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const rolesPaginationCallback = async (params: Record<string, unknown>): Promise<TPaginationActionData<IRole>> => {
        return fetchRolesOfDomain(DomainContentType.Community, params);
    };

    const handleSubmit = async (event: SyntheticEvent): Promise<void> => {
        event.preventDefault();
        const success = await editCommunityStaff(user.id, editedStaffUser);
        if (success) {
            closeDrawers();
        }
    };

    const onChange = (type: keyof INewCommunityStaff, value: string[] | string | number): void => {
        setEditedStaffUser((prevState) => {
            const { communities, mainCommunity } = prevState;

            let updatedList: string[] = communities;
            let mainCommunityValue = mainCommunity;

            if (type === "mainCommunity") {
                if (!updatedList.includes(value as string)) {
                    updatedList.push(value as string);
                }

                mainCommunityValue = value as string;
            }

            if (type === "communities") {
                if (!(value as string[]).includes(mainCommunity)) {
                    mainCommunityValue = "";
                }

                updatedList = value as string[];
            }

            return {
                ...prevState,
                [type]: value,
                mainCommunity: mainCommunityValue,
                communities: updatedList,
            };
        });
    };

    const onRemoveCommunityStaff = useCallback(async (): Promise<void> => {
        await deactivateCommunityStaff(user.id);
        closeDrawers();
        onConfirmRemove();
    }, [closeDrawers, deactivateCommunityStaff, onConfirmRemove, user.id]);

    const validateData = (): boolean => {
        return editedStaffUser.role > 0 && !!editedStaffUser.communities.length && !!editedStaffUser.mainCommunity && !!editedStaffUser.communityStaffRole;
    };

    const getAllCommunities = (): TCommunity[] => {
        const combined = [...communitiesPagination.results, ...user.communities];
        const unique = new Map(combined.map((item) => [item["uuid"], item])).values();
        return Array.from(unique);
    };

    const communities = getAllCommunities();

    if (isFetchingRoles || isFetchingCommunities) {
        return (<Grid container justifyContent="center" marginTop={4} gap={2}>
            {Array.from(new Array(7)).map(item =>
                <Skeleton key={`skeleton-${item}`} variant="rectangular" width="100%" height={60} />,
            )}
        </Grid>);
    }
    return (
        <div data-testid="addContactPerson-root">
            <List>
                <ListItem divider>
                    <ListItemText primary={<LocalizeText tag="firstName" />} secondary={user.first_name} />
                </ListItem>
                <ListItem divider>
                    <ListItemText primary={<LocalizeText tag="lastName" />} secondary={user.last_name} />
                </ListItem>
                <ListItem divider>
                    <ListItemText primary={<LocalizeText tag="email" />} secondary={user.email} />
                </ListItem>
                <ListItem divider>
                    <ListItemText primary={<LocalizeText tag="phoneNumber" />} secondary={user.phone} />
                </ListItem>
                <form onSubmit={handleSubmit}>
                    <ListItem>
                        <TextField
                            select
                            label={<LocalizeText tag="role" />}
                            value={editedStaffUser.communityStaffRole}
                            required
                            fullWidth
                            onChange={(event) => onChange("communityStaffRole", event.target.value)}
                        >
                            <MenuItem value="Arenapersonal">Arenapersonal</MenuItem>
                            <MenuItem value="Sitemanager">Sitemanager</MenuItem>
                        </TextField>
                    </ListItem>
                    <ListItem>
                        <TextField
                            select
                            label={<LocalizeText tag="mainArena" />}
                            value={editedStaffUser.mainCommunity}
                            required
                            onChange={(event) => onChange("mainCommunity", event.target.value)}
                            fullWidth
                        >
                            {communities.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    value={item.uuid}
                                    disabled={!communitiesPagination.results.find((c) => c.uuid === item.uuid)}
                                >
                                    {item.title}
                                </MenuItem>
                            ))}
                        </TextField>
                    </ListItem>
                    <ListItem>
                        <TextField
                            select
                            label={<LocalizeText tag="showOtherArenas" />}
                            value={editedStaffUser.communities}
                            required
                            fullWidth
                            onChange={(event) => onChange("communities", event.target.value)}
                            SelectProps={{
                                multiple: true,
                            }}
                            InputLabelProps={{ shrink: editedStaffUser.communities.length > 0 }}
                        >
                            {communities.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    value={item.uuid}
                                    disabled={!communitiesPagination.results.find((c) => c.uuid === item.uuid)}
                                >
                                    {item.title}
                                </MenuItem>
                            ))}
                        </TextField>
                    </ListItem>
                    <ListItem>
                        <VKButton
                            isLoading={isEditingCommunityStaff}
                            disabled={!validateData()}
                            data-testid="addContactPerson-submitButton"
                            id="button"
                            tag="save"
                            type="submit"
                            template="save"
                        />
                    </ListItem>

                    <div
                        style={{
                            display: "flex",
                            marginTop: "130px",
                            marginBottom: "10px",
                        }}
                    >
                        <VKButton tag="removeStaff" variant="outlined" onClick={() => setConfirmRemoveStaff(true)} />
                    </div>
                </form>
            </List>
            <ConfirmDialog
                open={confirmRemoveStaff}
                title={<LocalizeText tag="removeStaff" />}
                description={<LocalizeText tag={"removeStaffSure"} />}
                onReject={() => setConfirmRemoveStaff(false)}
                onAccept={onRemoveCommunityStaff}
                isLoading={isDeactivatingCommunityStaff}
            />
        </div>
    );
};

export default UpdateCommunityStaff;
