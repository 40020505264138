import React, { useEffect } from "react";
import { LocalizeText } from "components/localizer";
import { IMembership } from "reducers/memberships";
import { Grid, ListItem, ListItemText } from "@mui/material";
import { MembershipDocument, fetchDocuments, uploadDocument } from "adapters/documents";
import VKButton from "components/vkButton";
import { UploadFileOutlined } from "@mui/icons-material";
import { DocumentUploadWizard } from "./documentUploadWizard";
import { DocumentButton } from "./documentButton";
import { usePermission } from "utils/hooks/usePermission";

interface IProps {
    membership: IMembership;
}

const DocumentsField: React.FC<IProps> = ({ membership }) => {
    const { hasPermission } = usePermission();

    const [documents, setDocuments] = React.useState<MembershipDocument[]>([]);
    const [isUploading, setIsUploading] = React.useState(false);

    useEffect(() => {
        const load = async (): Promise<void> => {
            let docs: MembershipDocument[] = [];
            if (membership.agreement_document_file) {
                docs.push({
                    created: membership.created,
                    document_file: membership.agreement_document_file,
                    document_nr: membership.agreement_document_nr,
                    document_type: "agreement",
                    membership: membership.id,
                    uuid: "1",
                });
            }
            const newDocs = await fetchDocuments("memberships", membership.id.toString());
            docs = [...docs, ...newDocs];
            setDocuments(docs);
        };

        load();
        // eslint-disable-next-line
    }, [membership]);


    return (
        <ListItem divider>
            <Grid container direction="column">
                <Grid item>
                    <ListItemText primary={<LocalizeText tag="documents" />} />
                </Grid>

                {isUploading ? (
                    <Grid item>
                        <DocumentUploadWizard
                            onUpload={async (file, type) => {
                                const formData = new FormData();
                                formData.append("document_file", file);
                                formData.append("document_type", type);
                                formData.append("membership", membership.id.toString());
                                const newDoc = await uploadDocument("memberships", formData);
                                if (newDoc?.uuid) {
                                    setDocuments([...documents, newDoc]);
                                }
                                setIsUploading(false);
                            }}
                            onCancel={() => setIsUploading(false)}
                        />
                    </Grid>
                ) : (
                    <Grid item container direction="column" gap="5px" marginBottom="10px">
                        {documents.map((doc) => (
                            <DocumentButton key={doc.uuid} {...doc} />
                        ))}
                        {documents.length === 0 ? <LocalizeText tag="noDocuments" /> : null}
                    </Grid>
                )}

                {hasPermission("add_membershipdocument") ? (
                    <Grid item sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <VKButton
                            tag="newDocument"
                            startIcon={<UploadFileOutlined />}
                            sx={{ fontSize: "1rem" }}
                            onClick={() => setIsUploading(true)}
                            disabled={!!membership.end_date || membership.is_archived}
                        />
                    </Grid>
                ) : null}
            </Grid>
        </ListItem>
    );
};

export default DocumentsField;
