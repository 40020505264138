import { Grid, styled, Typography } from "@mui/material";
import React from "react";
import { IBuilding, IBuildingResponsibility } from "reducers/realestates";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { LocalizeText } from "../localizer";
import ExternalImageWithDefault from "components/externalImageWithDefault";
import colors from "styles/colors";


const DefaultNoAgentImage = styled(PersonOutlineIcon)(() => ({
    backgroundColor: colors.avatarBackground,
    height: "100%",
    maxWidth: "250px",
    maxHeight: "250px",
    width: "100%",
    objectFit: "cover",
    aspectRatio: "3 / 2"
}));

interface IAgentProps {
    responsible: IBuildingResponsibility;
}

const AgentPerson: React.FC<IAgentProps> = ({ responsible }) => {

    return (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3} >
            <ExternalImageWithDefault
                style={{
                    backgroundColor: colors.avatarBackground,
                    height: "100%",
                    maxWidth: "250px",
                    maxHeight: "250px",
                    width: "100%",
                    objectFit: "cover",
                }}
                url={responsible.agent_person.image_url}
                defaultNode={<DefaultNoAgentImage />}
            />
            <Typography variant="body2" gutterBottom >
                {responsible.agent_person.full_name || "-"}
            </Typography >
            <Typography variant="body2" gutterBottom>
                {responsible.role_name || "-"}
            </Typography>
            <Typography
                sx={{
                    display: "flex",
                    verticalAlign: "middle",
                    wordBreak: "keep-all",
                }}
                variant="body2"
                gutterBottom>
                <PhoneIcon fontSize="small" sx={{color: colors.vkBlue, marginRight: "5px"}} /> {responsible.agent_person.direct_phone || "-"}
            </Typography>
            <Typography
                sx={{
                    textDecoration: "underline",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    color: colors.black,
                    display: "flex",
                    verticalAlign: "middle",
                    wordBreak: "keep-all",
                }}
                component={"a"}
                href={`mailto:${responsible.agent_person.email}`}
                variant="body2"
                gutterBottom>
                <MailOutlineIcon fontSize="small" sx={{color: colors.vkBlue, marginRight: "5px"}} /> {responsible.agent_person.email || "-"}
            </Typography>
        </Grid >
    );
};


interface IProps {
    building: IBuilding;
    isArenaResponsible: boolean;
}

const BuildingResponsible: React.FC<IProps> = ({ building, isArenaResponsible }) => {

    const filterBuildingResponsibility = (b: IBuildingResponsibility): boolean => {
        if (isArenaResponsible) {
            return b.parent_role_value === "ResponsibleArena";
        }
        return b.parent_role_value !== "ResponsibleArena";
    };

    const sortBuildingResponsibility = (r1: IBuildingResponsibility, r2: IBuildingResponsibility): number => {
        if (r1.sort_order && r2.sort_order) {
            return r1.sort_order - r2.sort_order;
        }
        return 0;
    };


    return (
        <Grid item xs={12} data-testid="building-info-responsible">
            <Typography gutterBottom variant="h5" mt={"1rem"}>
                <LocalizeText tag="buildingContactPersons" />
            </Typography>
            <Grid container spacing={4} alignItems="center" justifyContent="flex-start">
                {building?.building_responsibility && building?.building_responsibility
                    .filter(filterBuildingResponsibility)
                    .sort(sortBuildingResponsibility).map(responsible => (
                        responsible.agent_person && (
                            <AgentPerson
                                key={responsible.agent_person.agent_person_uuid}
                                responsible={responsible}
                            />
                        )
                    ))}
            </Grid>
        </Grid>
    );
};

export default BuildingResponsible;
