import React, { useContext, useState } from "react";
import List from "@mui/material/List";
import { LocalizeText } from "components/localizer";
import { Grid, ListItem, ListItemText } from "@mui/material";
import { CenteredLoader } from "components/centeredLoader";
import { ModalContext } from "contexts/modals";
import { IExternalMembership } from "reducers/externalMemberships";
import { ExternalMembershipsContext } from "contexts/externalMemberships";
import FreeFromTextField from "./membershipInformation/freeFormTextField";
import ReferencePersonFields from "./membershipInformation/referencePersonFields";
import CountryField from "./membershipInformation/countryField";
import DocumentsField from "./membershipInformation/documentsField";
import { IUsePaginationStore } from "utils/usePaginationStore";
import EditableTextField from "./editableTextField";
import { usePermission } from "utils/hooks/usePermission";
import OrganizationNameField from "./membershipInformation/organizationNameField";
import { TCommunityType } from "utils/ecommerseTypes";
import CommunitiesField from "./membershipInformation/communitiesField";
import InvoiceAddressFields from "./membershipInformation/invoiceAddresFields";
import InvoiceMethodFields from "./membershipInformation/invoiceMethodFields";
import VKButton from "components/vkButton";
import ConfirmDialog from "components/confirmDialog";

interface IProps {
    membership: IExternalMembership;
    community_type?: TCommunityType;
    pagination: IUsePaginationStore<"external-memberships">;
}

const ExternalMembershipInformation: React.FC<IProps> = ({ membership, community_type, pagination }) => {
    const { updateMembership, isUpdatingMembership } = React.useContext(ExternalMembershipsContext);
    const { dispatch } = React.useContext(ModalContext);
    const { hasPermission } = usePermission();
    const { updateDrawer } = useContext(ModalContext);
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

    const canArchiveCustomer =
        hasPermission("manage_contract") && hasPermission("is_community_user") && membership.is_possible_to_terminate;

    const onSubmitUpdate = async (params: Record<string, unknown>): Promise<void> => {
        const updated = await updateMembership(membership.id, params);
        setOpenConfirmDialog(false);

        if (updated && Object.keys(updated).length) {
            await pagination.query.refetch();

            dispatch({
                type: "UPDATE_DRAWER_PROPS",
                componentProps: { membership: updated },
            });

            updateDrawer<typeof ExternalMembershipInformation>({ membership: updated, pagination });
        }
    };

    if (pagination.query.isFetching || isUpdatingMembership) {
        return <CenteredLoader size={40} />;
    }

    if (!membership) {
        return (
            <List>
                <ListItemText secondary={<LocalizeText tag="noDataAvailable" />} style={{ textAlign: "center" }} />
            </List>
        );
    }

    return (
        <Grid container>
            <Grid
                item
                xs
                sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {canArchiveCustomer ? (
                    <VKButton
                        sx={{ marginTop: "1rem" }}
                        tag={membership.is_archived ? "activateMembership" : "inactivateMembership"}
                        template="primary"
                        disabled={!!membership.end_date}
                        onClick={() => setOpenConfirmDialog(!openConfirmDialog)}
                    />
                ) : null}
                <List>
                    <ListItem divider>
                        <OrganizationNameField onSubmitUpdate={onSubmitUpdate} membership={membership} />
                    </ListItem>
                    <ListItem divider>
                        <OrganizationNameField onSubmitUpdate={onSubmitUpdate} membership={membership} isLegalCompany />
                    </ListItem>
                    <ListItem divider>
                        <EditableTextField
                            tag="orgNumber"
                            obj={membership}
                            helperProp="organization"
                            prop="org_nr"
                            onSubmitUpdate={onSubmitUpdate}
                            canEdit={hasPermission("change_membership")}
                            editDisabled={membership.is_archived}
                        />
                    </ListItem>
                    <CountryField membership={membership} onSubmitUpdate={onSubmitUpdate} />
                    <ListItem divider>
                        <CommunitiesField
                            community_type={community_type}
                            membership={membership}
                            onSubmitUpdate={onSubmitUpdate}
                        />
                    </ListItem>
                    <ListItem divider>
                        <InvoiceAddressFields membership={membership} onSubmitUpdate={onSubmitUpdate} />
                    </ListItem>
                    <ListItem divider>
                        <InvoiceMethodFields membership={membership} onSubmitUpdate={onSubmitUpdate} />
                    </ListItem>
                    <ListItem divider>
                        <EditableTextField
                            obj={membership}
                            tag="invoiceReference"
                            prop="user_invoice_reference"
                            onSubmitUpdate={onSubmitUpdate}
                            canEdit={hasPermission("change_membership")}
                            editDisabled={membership.is_archived}
                        />
                    </ListItem>
                    <ListItem divider>
                        <ListItemText
                            primary={<LocalizeText tag="customerNumber" />}
                            secondary={membership.agreement_nr}
                        />
                    </ListItem>

                    <ReferencePersonFields membership={membership} onSubmitUpdate={onSubmitUpdate} />
                    <DocumentsField membership={membership} />
                    <FreeFromTextField membership={membership} onSubmitUpdate={onSubmitUpdate} />
                </List>
            </Grid>

            <ConfirmDialog
                open={openConfirmDialog}
                title={
                    <LocalizeText tag={membership.is_archived ? "confirmUnArchiveMember" : "confirmArchiveMember"} />
                }
                description={
                    <LocalizeText
                        tag={
                            membership.is_archived
                                ? "confirmUnArchiveMemberDescription"
                                : "confirmArchiveMemberDescription"
                        }
                    />
                }
                onReject={() => setOpenConfirmDialog(false)}
                onAccept={() => onSubmitUpdate({ is_archived: !membership.is_archived })}
            />
        </Grid>
    );
};

export default ExternalMembershipInformation;
