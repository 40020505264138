import dev from "json/config-dev.json";
import prod from "json/config-prod.json";
import test from "json/config-test.json";
import local from "json/config-local.json";
import staging from "json/config-staging.json";

export interface IConfig {
    apiURL: string;
    authStorage: string;
    mock?: boolean;
    useTimeout?: boolean;
}

let config: IConfig = dev;
switch (process.env.REACT_APP_CONFIG) {
    case "development":
        config = dev;
        break;
    case "production":
        config = prod;
        break;
    case "test":
        config = test;
        break;
    case "staging":
        config = staging;
        break;
    case "local":
    default:
        config = local;
        break;
}
export default config;
