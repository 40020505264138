/* eslint-disable @typescript-eslint/no-explicit-any */
import { format, parseISO, isPast, isToday, isFuture, Locale } from "date-fns";
import { enUS, sv } from "date-fns/locale";
import { ILeaseContract } from "reducers/leases";
import { dateFormats } from "./formats";
import { ISubscription } from "reducers/subscriptions";
import React, { ReactElement, ReactNode } from "react";
import { LocalizeText } from "components/localizer";
import { getCountryCode } from "./countryCodes";
import { IMember } from "reducers/members";
import colors from "styles/colors";
import { TLanguage } from "contexts/language";

export const sanitizePhoneNumber = (phoneNumber: string): string => {
    return phoneNumber.replace(/[^\d+]/g, "");
};

const formatPhoneNumber = (phoneNumber: string): string => {
    return [phoneNumber.substring(0, 3), phoneNumber.substring(3, 6), phoneNumber.substring(6)].join(" ");
};

export const prettifyPhoneNumber = (phone: string): string => {
    const number = sanitizePhoneNumber(phone);

    const countryCode = getCountryCode(number);
    const countryCodeIndex = countryCode.length;
    const plainPhoneNumber = number.substring(countryCodeIndex);

    return countryCode ? countryCode + " " + formatPhoneNumber(plainPhoneNumber) : formatPhoneNumber(plainPhoneNumber);
};

export const prettifyOrgNumber = (orgNr: string): string => {
    if (orgNr.length < 10) {
        return orgNr;
    }
    if (orgNr.match(/\D/)) {
        return orgNr;
    }
    return `${orgNr.slice(0, 6)}-${orgNr.slice(6)}`;
};

// Treaverse a object and check if it is safe to continue on every step
export const deepGet = (path: string, target?: Record<string, unknown> | any, defalutValue: any = ""): any => {
    if (target) {
        // split path into accessors
        const keys = path?.split(/[(.[\]]/);
        const source = keys?.reduce((prev, cur) => {
            if (prev) {
                return prev[cur];
            }
            return defalutValue;
        }, target || {});
        return source === undefined ? defalutValue : source;
    }
    return defalutValue;
};

// Delay function that can be used inside promises
export const delay = (ms: number): Promise<unknown> => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

export const dateIsPastOrToday = (date: Date): boolean => {
    return isPast(date) || isToday(date);
};

export const dateIsFutureOrToday = (date: Date): boolean => {
    return isFuture(date) || isToday(date);
};

export const showDevelopersFunctionality = (): boolean => {
    return process.env.REACT_APP_CONFIG === "development" || process.env.REACT_APP_CONFIG === "staging";
};

export const validatePasswordGetErrorTag = (password: string, rePassword: string): string => {
    if (password.length < 8) {
        return "tooShortPassword";
    } else if (/^\d*$/.test(password)) {
        return "notOnlyNumbers";
    } else if (password !== rePassword) {
        return "passwordsDontMatch";
    }
    return "";
};

export const invalidateLocalCookie = (nameOfCookie: string): void => {
    document.cookie = `${nameOfCookie}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const formatPossibleNullDate = (date: string | null, dateFormat: dateFormats): string => {
    if (date) {
        return format(parseISO(date ?? ""), dateFormat);
    }
    return "";
};

export const getLanguageLocale = (): Locale => {
    const stored = localStorage.getItem("lang");
    if (stored === "en") {
        return enUS;
    }
    return sv;
};

export const getInvoiceDestination = (contract: ILeaseContract): string => {
    if (contract.distribution_method_value === "email") {
        return `${contract.distribution_method_name} (${contract.distribution_email_address})`;
    }
    return contract.distribution_method_name;
};

export const getHexBrightness = (hex: string): number => {
    if (hex.startsWith("#")) {
        hex = hex.slice(1);
    }
    const [red, green, blue] = [0, 2, 4].map((p) => parseInt(hex.substring(p, p + 2), 16));
    return Math.round((red * 299 + green * 587 + blue * 114) / 1000);
};

export const downloadFile = (fileName: string, url: string): void => {
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;

    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
};

export const createFileDownloadFromBlob = (blob: Blob, fileName: string): void => {
    const url = window.URL.createObjectURL(blob);
    downloadFile(fileName, url);
};

export const sleep = (ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getSubscriptionType = (subscription: ISubscription): ReactNode => {
    if (subscription.max_active_associations === 1) {
        return <LocalizeText tag="bound" />;
    }

    return <LocalizeText tag="unbound" />;
};

export const getUserText = (subscription: ISubscription, isLargeScreen?: boolean): ReactElement => {
    const isPersonal = subscription.max_active_associations === 1;

    if (isPersonal) {
        return (
            <span key={`members_${subscription.id}`}>
                {" " + truncateText(subscription.personal_user, 20, isLargeScreen)}
            </span>
        );
    }

    return (
        <span key={`members_${subscription.id}`}>
            {subscription.total_associations} <LocalizeText tag="userLower" />
        </span>
    );
};

export const sanitizeOrgNumber = (search: string): string => {
    if (search.match("^(\\d{6}-).*$")) {
        return search.slice(0, 6) + search.slice(7);
    }
    return search;
};

export const getMemberStatusText = (member: IMember, noStyling = false): ReactNode => {
    const { status, created } = member;
    switch (status) {
        case "INVITED":
            if (noStyling) {
                return <LocalizeText tag="invitedNoDate" />;
            }

            return (
                <LocalizeText
                    tag="invited"
                    styling={{ color: colors.vkBlue }}
                    replace={{ date: format(parseISO(created ?? ""), dateFormats.WEBDATE) }}
                />
            );
        case "ACTIVE":
            return <LocalizeText tag="active" styling={{ color: noStyling ? "inherit" : colors.successGreen }} />;
        case "DEACTIVATED":
            return <LocalizeText tag="deactivated" styling={{ color: noStyling ? "inherit" : colors.greyText }} />;

        default:
            return <LocalizeText tag="invited" />;
    }
};

const MONTHS = {
    en: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ],
    sv: [
        "Januari",
        "Februari",
        "Mars",
        "April",
        "Maj",
        "Juni",
        "Juli",
        "Augusti",
        "September",
        "Oktober",
        "November",
        "December",
    ],
};
export const getMonthShort = (date: number | Date, lang: TLanguage): string => {
    const monthNumber = typeof date === "number" ? date : date.getMonth();
    return MONTHS[lang][monthNumber].substring(0, 3);
};
export const getMonth = (date: number | Date, lang: TLanguage): string => {
    const monthNumber = typeof date === "number" ? date : date.getMonth();
    return MONTHS[lang][monthNumber];
};

export const truncateText = (text: string, numberOfCharactersAllowed: number, isLargeScreen?: boolean): string => {
    if (isLargeScreen) {
        return text.length > 50 ? text.substring(0, 50).concat("...") : text;
    }

    return text.length > numberOfCharactersAllowed ? text.substring(0, numberOfCharactersAllowed).concat("...") : text;
};
