import React from "react";
import { styled, TableCell, TableRow } from "@mui/material";
import If from "components/__helpers__/if";
import Skeleton from "@mui/material/Skeleton";
import { EmptyCell } from "./row";

export type TSkeletonVariant = "regular" | "options";

export const SkeletonCell = styled(Skeleton)(() => ({
    minWidth: "20px",
}));

interface ISkeletonTable {
    variant: TSkeletonVariant;
    cellCount: number;
    perPage: number;
}
const SkeletonTable: React.FC<ISkeletonTable> = ({variant, cellCount, perPage}) => {
    return <>
        {[...Array(perPage)].map((key, index) => (
            <TableRow key={index} data-testid="tableComponent-skeletonRow">
                <If truthy={variant === "regular"}>
                    <>
                        <EmptyCell />
                        {[...Array(cellCount)].map((key2, index2) => (
                            <TableCell key={index2}>
                                <SkeletonCell variant="rectangular" />
                            </TableCell>
                        ))}
                        <EmptyCell />
                    </>
                </If>
                <If truthy={variant === "options"}>
                    <>
                        <EmptyCell />
                        {[...Array(cellCount)].map((key2, index2) => (
                            <TableCell key={index2}>
                                <SkeletonCell variant="rectangular" />
                            </TableCell>
                        ))}
                        <TableCell>
                            <SkeletonCell 
                                variant="rectangular"
                                sx={{
                                    width: "30px",
                                    height: "30px",
                                    margin: "auto",
                                }} />
                        </TableCell>
                        <EmptyCell />
                    </>
                </If>
            </TableRow>
        ))}
    </>;
};

export default SkeletonTable;
