import React from "react";
import Wizard, { WizardPage } from "./wizard";
import TextField from "@mui/material/TextField";
import BackupCodes from "./backupCodes";
import { IAuthorization, ITotpDevice } from "reducers/auth";
import { AuthContext } from "contexts/auth";
import { Grid, styled, Tooltip } from "@mui/material";
import QRCode from "qrcode";
import { findUnconfirmedTotp } from "./utils";
import { AppIcon } from "./icons";
import InnerPage from "./innerPage";
import VKButton from "components/vkButton";
import colors from "styles/colors";

const apps = [
    {
        name: "Google Authenticator",
        img: "google_authenticator.png",
        googlePlay: "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
        appStore: "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
    {
        name: "Twilio Authy",
        img: "twilio_authy.png",
        googlePlay: "https://play.google.com/store/apps/details?id=com.authy.authy",
        appStore: "https://apps.apple.com/us/app/twilio-authy/id494168017",
    },
    {
        name: "Microsoft Authenticator",
        img: "microsoft_authenticator.png",
        googlePlay: "https://play.google.com/store/apps/details?id=com.azure.authenticator",
        appStore: "https://apps.apple.com/us/app/microsoft-authenticator/id983156458",
    },
];

const ToolTipLink = styled("a")(() => ({
    padding: "5px 10px",
    textDecoration: "none",
    color: colors.black,
    display: "inline-flex",
    alignItems: "center",
    width: "100%",
}));

interface IRecommendedApp {
    name: string;
    img: string;
    googlePlay: string;
    appStore: string;
}
const RecommendedApp: React.FC<IRecommendedApp> = ({ name, img, googlePlay, appStore }) => {
    return (
        <Tooltip
            title={
                <Grid container direction="column">
                    <Grid item>
                        <ToolTipLink href={googlePlay} target="_blank" rel="noopener noreferrer">
                            <img
                                height={"24px"}
                                style={{ marginRight: "5px" }}
                                src={`${process.env.PUBLIC_URL}/assets/images/google_play.png`}
                                alt="Google Play"
                            />
                            <span>Google Play</span>
                        </ToolTipLink>
                    </Grid>
                    <Grid item>
                        <ToolTipLink href={appStore} target="_blank" rel="noopener noreferrer">
                            <img
                                height={"24px"}
                                style={{ marginRight: "5px" }}
                                src={`${process.env.PUBLIC_URL}/assets/images/app_store.png`}
                                alt="App Store"
                            />
                            <span>App Store</span>
                        </ToolTipLink>
                    </Grid>
                </Grid>
            }
            placement="top"
            PopperProps={{
                sx: {
                    "& .MuiTooltip-tooltip": {
                        backgroundColor: colors.white,
                        opacity: 1,
                        boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.6)",
                        padding: "8px",
                    },
                },
            }}
        >
            <Grid container direction="column" alignItems="center">
                <Grid item>
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/images/${img}`}
                        alt={name}
                        width={"50px"}
                        height={"50px"}
                    />
                </Grid>
                <Grid
                    item
                    sx={{
                        fontSize: "0.7rem",
                        textAlign: "center",
                    }}
                >
                    {name}
                </Grid>
            </Grid>
        </Tooltip>
    );
};

interface IProps {
    onCancel: () => void;
    onFinish?: () => void;
    authorizationObject?: IAuthorization;
}
const RegisterAppWizard: React.FC<IProps> = ({ onCancel, onFinish, authorizationObject }) => {
    const { totpDevices, verifyOtpCode, fetchBackupCodes, isFetchingBackupCodes, generateBackupCodes, backupCodes } =
        React.useContext(AuthContext);
    const [activeStep, setActiveStep] = React.useState<number>(0);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const [device, setDevice] = React.useState<ITotpDevice | null>(null);
    const [code, setCode] = React.useState<string>("");
    const [qr, setQr] = React.useState<string>("");
    React.useEffect(() => {
        if (totpDevices) {
            setDevice(findUnconfirmedTotp(totpDevices));
        }
        if (device?.confirmed) {
            fetchBackupCodes();
        }
        // eslint-disable-next-line
    }, []);

    if (!device) {
        return null;
    }

    const onChangeVerificationCode = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
        const value = e.target.value;
        setCode(value);
        value.length === 6 && validate(value);
    };

    const validate = async (token: string): Promise<void> => {
        const success = await verifyOtpCode({ token, key: authorizationObject?.key });
        if (success) {
            if (!backupCodes && !isFetchingBackupCodes) {
                setIsLoading(true);
                await fetchBackupCodes();
            }
            if (backupCodes && backupCodes?.length === 0) {
                setIsLoading(true);
                await generateBackupCodes();
            }
            setIsLoading(false);
            setActiveStep(activeStep + 1);
        }
    };

    const onClickFinish = (): void => {
        if (onFinish) {
            onFinish();
        } else {
            onCancel();
        }
    };

    return (
        <Wizard
            steps={["install", "register", "verify", "backup"]}
            step={activeStep}
            pages={[
                <WizardPage
                    key="pageInstall"
                    onBack={onCancel}
                    onNext={async (): Promise<void> => {
                        const q = await QRCode.toDataURL(device.config_url);
                        setQr(q);
                        setActiveStep(activeStep + 1);
                    }}
                    buttonNext={<VKButton tag="next" template="primary" type="submit" isLoading={isLoading} />}
                >
                    <InnerPage
                        page={{
                            icon: <AppIcon />,
                            title: "installApp",
                            info: "installAppInfo",
                            customContent: (
                                <Grid container justifyContent="center" wrap="nowrap" maxWidth={"500px"}>
                                    {apps.map((app) => (
                                        <RecommendedApp key={app.name} {...app} />
                                    ))}
                                </Grid>
                            ),
                        }}
                    />
                </WizardPage>,
                <WizardPage
                    key="pageRegister"
                    onBack={() => setActiveStep(activeStep - 1)}
                    onNext={() => setActiveStep(activeStep + 1)}
                    buttonNext={<VKButton tag="next" template="primary" type="submit" />}
                >
                    <InnerPage
                        page={{
                            icon: <AppIcon />,
                            title: "registerApp",
                            info: "registerAppInfo",
                            customContent: (
                                <Grid container direction="column" alignItems="center">
                                    <Grid item>
                                        <img
                                            src={qr}
                                            alt="QR code"
                                            style={{ width: "140px", height: "140px", margin: "-15px" }}
                                        />
                                    </Grid>
                                </Grid>
                            ),
                            bottomText: "registerAppBottom",
                        }}
                    />
                </WizardPage>,
                <WizardPage
                    key="pageVerify"
                    onBack={() => setActiveStep(activeStep - 1)}
                    onNext={() => validate(code)}
                    buttonNext={<VKButton tag="next" template="primary" type="submit" disabled={code.length !== 6} />}
                >
                    <InnerPage
                        page={{
                            icon: <AppIcon />,
                            title: "verifyAppRegistration",
                            info: "verifyAppRegistrationInfo",
                            customContent: (
                                <TextField
                                    variant="outlined"
                                    name="code"
                                    margin="dense"
                                    sx={{
                                        width: "280px",
                                        height: "40px",
                                    }}
                                    value={code}
                                    autoFocus
                                    onChange={onChangeVerificationCode}
                                />
                            ),
                        }}
                    />
                </WizardPage>,
                <WizardPage
                    key="pageBackup"
                    onNext={onClickFinish}
                    buttonNext={<VKButton tag="proceed" template="primary" type="submit" />}
                >
                    <InnerPage
                        page={{
                            icon: <AppIcon />,
                            title: "backupWithCodes",
                            info: "backupWithCodesInfo",
                            customContent: <BackupCodes />,
                        }}
                    />
                </WizardPage>,
            ]}
        />
    );
};

export default RegisterAppWizard;
