import { useCallback, useContext } from "react";
import { ProfileContext } from "contexts/profile";

export type RequiredPerm = Array<string | string[]>;

interface IUsePermission {
    hasPermission: (permId: string) => boolean;
    hasPermissions: (perms: RequiredPerm) => boolean;
}

export const usePermission = (): IUsePermission => {
    const { permissions } = useContext(ProfileContext);

    const hasPermission = (permId: string): boolean => {
        return permissions.includes(permId);
    };

    const hasPermissions = useCallback(
        (perms: RequiredPerm): boolean => {
            if (!perms) {
                return false;
            }
            return perms.every((perm) => {
                if (Array.isArray(perm)) {
                    return perm.some((id) => permissions.includes(id));
                }

                return permissions.includes(perm);
            });
        },
        [permissions]
    );

    return {
        hasPermission,
        hasPermissions,
    };
};
