import React, { ChangeEvent, FC, ReactElement } from "react";
import { Chip, Grid, styled, Tooltip } from "@mui/material";
import colors from "styles/colors";
import { Description, AttachFile, Image } from "@mui/icons-material";
import VKButton from "./vkButton";
import { LocalizeText } from "./localizer";
import { TLanguageTag } from "contexts/language";

const FileChip = styled(Chip)(() => ({
    whiteSpace: "nowrap",
    maxWidth: "160px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: "4px",
    border: `2px solid ${colors.vkBlue}`,
    "& .MuiChip-deleteIcon": {
        color: colors.vkBlue,
    },
}));

interface FileUploadFieldProps {
    onFileUploadChange: (files: File[]) => void;
    files: File[];
    accept: string;
    singleFile?: boolean;
    tag?: TLanguageTag;
    vertical?: boolean;
}

const FileUploadField: FC<FileUploadFieldProps> = ({
    onFileUploadChange,
    files,
    accept,
    singleFile = false,
    tag = "attachmentUpload",
    vertical,
}) => {
    const onChangeFileInput = (e: ChangeEvent<HTMLInputElement>): void => {
        const changedFiles = e.target.files ? Array.from(e.target.files) : [];
        onFileUploadChange(changedFiles);
        e.currentTarget.value = "";
    };

    const onRemoveFile = (file: File, e: ChangeEvent<HTMLInputElement>): void => {
        onFileUploadChange(files.filter((f) => f.name !== file.name));
    };

    const getFileIcon = (file: File): ReactElement => {
        switch (file.type) {
            case "application/pdf":
                return <Description />;
            case "image/png":
            case "image/jpeg":
            case "image/jpg":
                return <Image />;
            default:
                return <AttachFile />;
        }
    };

    return (
        <Grid container direction={vertical ? "column" : "row"} gap="10px">
            <Grid item>
                <input
                    accept={accept}
                    style={{ display: "none" }}
                    id="contained-button-file"
                    multiple={!singleFile}
                    type="file"
                    onChange={onChangeFileInput}
                />
                <label htmlFor="contained-button-file">
                    <VKButton
                        variant="outlined"
                        tag={tag}
                        component="span"
                        rightAddon={<AttachFile />}
                        fullWidth={vertical}
                        style={{ minWidth: "max-content" }}
                    />
                </label>
            </Grid>
            <Grid
                item
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    flex: "4 1 auto",
                }}
            >
                {files.length ? (
                    files.map((file, index) => (
                        <Tooltip arrow key={index} title={file.name} sx={{ maxWidth: "none" }}>
                            <FileChip
                                label={file.name}
                                variant="outlined"
                                color="primary"
                                icon={getFileIcon(file)}
                                onDelete={(event: ChangeEvent<HTMLInputElement>) => onRemoveFile(file, event)}
                            />
                        </Tooltip>
                    ))
                ) : (
                    <LocalizeText tag={singleFile ? "noFileSelected" : "noFilesSelected"} />
                )}
            </Grid>
        </Grid>
    );
};

export default FileUploadField;
