export interface IProduct {
    id: number;
    created: string;
    updated: string;
    product_key: string;
    name: string;
    description: string;
    slots?: number;
    community: string;
    community_title?: string;
    cost_center?: number;
    price?: IProductPrice;
    rules?: IProductRule;
    revenue_account: IBookkeepingAccount;
    revenue_account_code: number;
    vat_account: IBookkeepingAccount;
    vat_account_code: number;
    service_section: string;
    service_section_name: string;
    category: typeof productCategories[number];
    national_access: boolean;
    communities: string[];
}

export const productCategories = [
    "arena_subscription",
    "arena_office",
    "arena_daypass",
    "arena_meeting_room",
    "arena_catering",
    "arena_meeting_service",
    "arena_one_off_office_service",
    "arena_subscription_office_service",
    "arena_storage",
    "arena_event_area",
    "arena_other",
] as const;

export interface IProductPrice {
    uuid: string;
    created: string;
    updated: string;
    amount_excl_vat: string;
    vat_proportion: string;
}

export const unitTypes = ["hours", "days", "months"] as const;
export type Unit = typeof unitTypes[number];
export interface IProductRule {
    uuid: string;
    created: string;
    updated: string;
    binding_period: number;
    binding_period_value_type: Unit;
    trial_period: number;
    trial_period_value_type: Unit;
    notice_period: number;
    notice_period_value_type: Unit;
}

export interface IBookkeepingAccount {
    uuid: string;
    created: string;
    updated: string;
    account: number;
    description: string;
    account_type: "revenue" | "vat" | "other";
}

export interface IServiceSection {
    uuid: string;
    created: string;
    updated: string;
    name: string;
}

export interface IProductsQueryParams {
    search?: string;
}

export type IAction =
    | { type: "FETCH_PRODUCT" }
    | { type: "FETCH_PRODUCT_SUCCESS"; product: IProduct }
    | { type: "FETCH_PRODUCT_FAILURE" }
    | { type: "UPDATE_PRODUCT" }
    | { type: "UPDATE_PRODUCT_SUCCESS"; product: IProduct }
    | { type: "UPDATE_PRODUCT_FAILURE" }
    | { type: "CREATE_PRODUCT" }
    | { type: "CREATE_PRODUCT_SUCCESS"; product: IProduct }
    | { type: "CREATE_PRODUCT_FAILURE" }
    | { type: "FETCH_ACCOUNTS" }
    | { type: "FETCH_ACCOUNTS_SUCCESS"; accounts: IBookkeepingAccount[] }
    | { type: "FETCH_ACCOUNTS_FAILURE" }
    | { type: "FETCH_SERVICE_SECTIONS" }
    | { type: "FETCH_SERVICE_SECTIONS_SUCCESS"; serviceSections: IServiceSection[] }
    | { type: "FETCH_SERVICE_SECTIONS_FAILURE" };

export interface IProducts {
    product?: IProduct;
    accounts?: IBookkeepingAccount[];
    serviceSections?: IServiceSection[];
    isFetchingProduct: boolean;
    isUpdatingProduct: boolean;
    isCreatingProduct: boolean;
    isFetchingAccounts: boolean;
    isFetchingServiceSections: boolean;
    fetchProduct: (productKey: string, skipErrorNotification?: boolean) => Promise<IProduct>;
    updateProduct: (productKey: string, data: Record<string, unknown>) => Promise<IProduct>;
    createProduct: (data: Record<string, unknown>) => Promise<IProduct>;
    fetchAccounts: (params?: Record<string, unknown>) => Promise<IBookkeepingAccount[]>;
    fetchServiceSections: (params?: Record<string, unknown>) => Promise<IServiceSection[]>;
}

export const initialState: IProducts = {
    isFetchingProduct: false,
    isUpdatingProduct: false,
    isCreatingProduct: false,
    isFetchingAccounts: false,
    isFetchingServiceSections: false,
    fetchProduct: async (): Promise<IProduct> => ({} as IProduct),
    updateProduct: async (): Promise<IProduct> => ({} as IProduct),
    createProduct: async (): Promise<IProduct> => ({} as IProduct),
    fetchAccounts: async (): Promise<IBookkeepingAccount[]> => ({} as IBookkeepingAccount[]),
    fetchServiceSections: async (): Promise<IServiceSection[]> => ({} as IServiceSection[]),
};

function reducer(state: IProducts, action: IAction): IProducts {
    switch (action.type) {
        case "FETCH_PRODUCT":
            return { ...state, isFetchingProduct: true };
        case "FETCH_PRODUCT_SUCCESS":
            return { ...state, product: action.product, isFetchingProduct: false };
        case "FETCH_PRODUCT_FAILURE":
            return { ...state, product: undefined, isFetchingProduct: false };

        case "UPDATE_PRODUCT":
            return { ...state, isUpdatingProduct: true };
        case "UPDATE_PRODUCT_SUCCESS":
            return { ...state, isUpdatingProduct: false, product: action.product };
        case "UPDATE_PRODUCT_FAILURE":
            return { ...state, isUpdatingProduct: false };

        case "CREATE_PRODUCT":
            return { ...state, isCreatingProduct: true };
        case "CREATE_PRODUCT_SUCCESS":
            return { ...state, isCreatingProduct: false, product: action.product };
        case "CREATE_PRODUCT_FAILURE":
            return { ...state, isCreatingProduct: false };

        case "FETCH_ACCOUNTS":
            return { ...state, isFetchingAccounts: true };
        case "FETCH_ACCOUNTS_SUCCESS":
            return { ...state, accounts: action.accounts, isFetchingAccounts: false };
        case "FETCH_ACCOUNTS_FAILURE":
            return { ...state, accounts: undefined, isFetchingAccounts: false };

        case "FETCH_SERVICE_SECTIONS":
            return { ...state, isFetchingServiceSections: true };
        case "FETCH_SERVICE_SECTIONS_SUCCESS":
            return { ...state, serviceSections: action.serviceSections, isFetchingServiceSections: false };
        case "FETCH_SERVICE_SECTIONS_FAILURE":
            return { ...state, serviceSections: undefined, isFetchingServiceSections: false };

        default:
            return { ...state };
    }
}

export default reducer;
