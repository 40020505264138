import { ISmsDevice, ITotpDevice } from "reducers/auth";

export const findUnconfirmedSms = (devices: ISmsDevice[] | undefined): ISmsDevice | null => {
    if (typeof devices === "undefined" || devices.length === 0) {
        return null;
    }

    let found: ISmsDevice | null = null;
    devices.forEach((d) => {
        if (!(d as ISmsDevice).confirmed) {
            found = d;
        }
    });

    return found;
};

export const findUnconfirmedTotp = (devices: ITotpDevice[] | undefined): ITotpDevice | null => {
    if (typeof devices === "undefined" || devices.length === 0) {
        return null;
    }

    let found: ITotpDevice | ISmsDevice | null = null;
    devices.forEach((d) => {
        if (!(d as ITotpDevice).confirmed) {
            found = d;
        }
    });

    return found;
};

export const hasAConfirmedDevice = (devices: ITotpDevice[] | undefined): boolean => {
    if (typeof devices === "undefined" || devices.length === 0) {
        return false;
    }

    let foundConfirmed = false;
    devices.forEach((d) => {
        if (d.confirmed) {
            foundConfirmed = true;
        }
    });
    return foundConfirmed;
};

export const getConfirmedSmsDevice = (devices: ISmsDevice[] | undefined): ISmsDevice | null => {
    if (devices && devices.length >= 1 && devices[0].confirmed) {
        return devices[0];
    } else {
        return null;
    }
};