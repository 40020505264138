import { IMembership, TMembershipId } from "./memberships";

export interface IExternalMembership extends IMembership {
    contract_contact_name: string;
    contract_contact_phone: string;
    contract_contact_email: string;
    is_archived: boolean;
}

export interface IExternalMembershipQueryParams {
    start_date__gte?: string;
    start_date__lte?: string;
    end_date__gte?: string;
    end_date__lte?: string;
    by_user_email?: string;
    org_nr?: string;
    communities?: string;
    agreement_type?: "conference_member" | "external_member";
    search?: string;
    max_subscription_count?: number;
    min_subscription_count?: number;
    is_archived?: boolean;
}

export interface IExternalMemberships {
    membership?: IExternalMembership;
    isFetchingMembership: boolean;
    isUpdatingMembership: boolean;
    isCreatingMembership: boolean;
    fetchMembership: (membershipId: TMembershipId) => Promise<IExternalMembership>;
    updateMembership: (membershipId: TMembershipId, data: Record<string, unknown>) => Promise<IExternalMembership>;
    createMembership: (data: Record<string, unknown>) => Promise<IExternalMembership>;
}

export const initialState: IExternalMemberships = {
    isFetchingMembership: false,
    isUpdatingMembership: false,
    isCreatingMembership: false,
    fetchMembership: async (): Promise<IExternalMembership> => ({} as IExternalMembership),
    updateMembership: async (): Promise<IExternalMembership> => ({} as IExternalMembership),
    createMembership: async (): Promise<IExternalMembership> => ({} as IExternalMembership),
};

export type IAction =
    | { type: "FETCH_EXTERNAL_MEMBERSHIP" }
    | { type: "FETCH_EXTERNAL_MEMBERSHIP_SUCCESS"; membership: IExternalMembership }
    | { type: "FETCH_EXTERNAL_MEMBERSHIP_FAILURE" }
    | { type: "UPDATE_EXTERNAL_MEMBERSHIP" }
    | { type: "UPDATE_EXTERNAL_MEMBERSHIP_SUCCESS"; data: IExternalMembership }
    | { type: "UPDATE_EXTERNAL_MEMBERSHIP_FAILURE" }
    | { type: "CREATE_EXTERNAL_MEMBERSHIP" }
    | { type: "CREATE_EXTERNAL_MEMBERSHIP_SUCCESS"; membership: IExternalMembership }
    | { type: "CREATE_EXTERNAL_MEMBERSHIP_FAILURE" };

function reducer(state: IExternalMemberships, action: IAction): IExternalMemberships {
    switch (action.type) {
        case "FETCH_EXTERNAL_MEMBERSHIP":
            return { ...state, isFetchingMembership: true };
        case "FETCH_EXTERNAL_MEMBERSHIP_SUCCESS":
            return { ...state, membership: action.membership, isFetchingMembership: false };
        case "FETCH_EXTERNAL_MEMBERSHIP_FAILURE":
            return { ...state, isFetchingMembership: false, membership: undefined };

        case "UPDATE_EXTERNAL_MEMBERSHIP":
            return { ...state, isUpdatingMembership: true };
        case "UPDATE_EXTERNAL_MEMBERSHIP_SUCCESS":
            return { ...state, isUpdatingMembership: false, membership: action.data };
        case "UPDATE_EXTERNAL_MEMBERSHIP_FAILURE":
            return { ...state, isUpdatingMembership: false };

        case "CREATE_EXTERNAL_MEMBERSHIP":
            return { ...state, isCreatingMembership: true };
        case "CREATE_EXTERNAL_MEMBERSHIP_SUCCESS":
            return { ...state, isCreatingMembership: false, membership: action.membership };
        case "CREATE_EXTERNAL_MEMBERSHIP_FAILURE":
            return { ...state, isCreatingMembership: false };

        default:
            return { ...state };
    }
}

export default reducer;
