import React from "react";
import { CardMedia, Grid, styled } from "@mui/material";
import colors from "styles/colors";
import { IBuilding } from "reducers/realestates";
import { getCorrectBuildingImage } from "utils/buildingHelpers";
import BusinessIcon from "@mui/icons-material/Business";

const CardImage = styled(CardMedia)(() => ({
    "& svg": {
        color: colors.grey,
    },
    width: "100%",
    height: "100%"
}));

const NoUrlImageContainer = styled(Grid)(() => ({
    "& svg": {
        color: colors.grey,
    },
    width: "100%",
    height: "100%"
}));


interface IProps{
    building: IBuilding;
}
const BuildingCardImage: React.FC<IProps> = ({ building }) => {
    const url = building ? getCorrectBuildingImage(building).url : `${process.env.PUBLIC_URL}/assets/images/vasakronan_symbol_orange.png`;
    if (url) {
        return (
            <CardImage image={url} />
        );
    }
    return (
        <NoUrlImageContainer container justifyContent="center" alignItems="center">
            <BusinessIcon />
        </NoUrlImageContainer>
    );
};

export default BuildingCardImage;
