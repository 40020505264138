import { TLanguageTag } from "contexts/language";
import { IModalContextState, initialState } from "contexts/modals";

export interface IDrawer {
    component?: React.ElementType;
    componentProps?: Record<string, unknown>;
    titleTag?: TLanguageTag;
}

export interface IDialog {
    component: React.ElementType;
    componentProps?: Record<string, unknown>;
}

export type IModalAction =
    | {
          type: "OPEN_DRAWER";
          component: React.ElementType;
          componentProps?: Record<string, unknown>;
          titleTag: TLanguageTag;
      }
    | {
          type: "UPDATE_DRAWER_PROPS";
          componentProps?: Record<string, unknown>;
      }
    | { type: "CLOSE_DRAWERS" }
    | {
          type: "PUSH_DIALOG";
          component: React.ElementType;
          componentProps?: Record<string, unknown>;
      }
    | { type: "POP_DIALOG" };

const reducer = (state: IModalContextState, action: IModalAction): IModalContextState => {
    switch (action.type) {
        case "OPEN_DRAWER":
            return {
                ...state,
                drawer: {
                    component: action.component,
                    componentProps: action.componentProps,
                    titleTag: action.titleTag,
                },
            };
        case "UPDATE_DRAWER_PROPS":
            return {
                ...state,
                drawer: {
                    ...state.drawer,
                    componentProps: action.componentProps,
                },
            };
        case "CLOSE_DRAWERS":
            return initialState;
        case "PUSH_DIALOG":
            const dialogs = state.dialogs;
            dialogs.push({
                component: action.component,
                componentProps: action.componentProps,
            });
            return {
                ...state,
                dialogs: dialogs,
            };
        case "POP_DIALOG":
            const changedDialogs = state.dialogs;
            changedDialogs.pop();
            return {
                ...state,
                dialogs: changedDialogs,
            };
        default:
            return state;
    }
};

export default reducer;
