import React, { ComponentProps, JSXElementConstructor, useCallback, useMemo } from "react";
import Drawer from "components/drawer";
import { LocalizeText } from "components/localizer";
import DialogBase from "components/dialogs/dialogBase";
import reducer, { IDialog, IDrawer, IModalAction } from "reducers/modals";
import { TLanguageTag } from "./language";

export interface IModalContextState {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    openDrawer: <T extends JSXElementConstructor<any>>(
        component: T,
        componentProps: ComponentProps<T>,
        titleTag: TLanguageTag
    ) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateDrawer: <T extends JSXElementConstructor<any>>(
        componentProps: ComponentProps<T>,
    ) => void;
    closeDrawers: () => void;
    drawer?: IDrawer;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pushDialog: <T extends JSXElementConstructor<any>>(component: T, componentProps: ComponentProps<T>) => void;
    popDialog: () => void;
    dialogs: IDialog[];
    dispatch: React.Dispatch<IModalAction>;
}

export const initialState: IModalContextState = {
    openDrawer: (): void => {},
    updateDrawer: (): void => {},
    closeDrawers: (): void => {},
    pushDialog: (): void => {},
    popDialog: (): void => {},
    dialogs: [],
    dispatch: () => ({} as React.Dispatch<IModalAction>),
};

export const ModalContext = React.createContext<IModalContextState>({
    ...initialState,
});

export const ModalProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const [currentState, dispatch] = React.useReducer<React.Reducer<IModalContextState, IModalAction>>(
        reducer,
        initialState
    );

    const openDrawer = useCallback(
        (component: React.ElementType, componentProps: Record<string, unknown>, titletag: TLanguageTag): void => {
            dispatch({
                type: "OPEN_DRAWER",
                component: component,
                componentProps: componentProps,
                titleTag: titletag,
            });
        },
        []
    );

    const updateDrawer = useCallback(
        (componentProps: Record<string, unknown>): void => {
            dispatch({
                type: "UPDATE_DRAWER_PROPS",
                componentProps: componentProps,
            });
        },
        []
    );

    const closeDrawers = useCallback((): void => {
        dispatch({ type: "CLOSE_DRAWERS" });
    }, []);

    const pushDialog = useCallback((component: React.ElementType, componentProps: Record<string, unknown>): void => {
        dispatch({
            type: "PUSH_DIALOG",
            component: component,
            componentProps: componentProps,
        });
    }, []);

    const popDialog = useCallback((): void => {
        dispatch({ type: "POP_DIALOG" });
    }, []);

    const renderDrawer = (): React.ReactNode => {
        const drawerState = currentState.drawer;
        const DrawerComponent = drawerState?.component;
        return (
            <Drawer
                isOpen={!!DrawerComponent}
                onClose={() => closeDrawers()}
                title={drawerState?.titleTag && <LocalizeText tag={drawerState.titleTag} />}
            >
                {DrawerComponent && <DrawerComponent {...drawerState?.componentProps} />}
            </Drawer>
        );
    };

    const renderDialogs = (): React.ReactNode => {
        if (currentState.dialogs.length > 0) {
            return currentState.dialogs.map((dialogState: IDialog, index: number) => {
                const DialogComponent = dialogState?.component;
                if (DialogComponent) {
                    return (
                        <DialogBase key={index} open={true} handleClose={() => popDialog()}>
                            <DialogComponent {...dialogState?.componentProps} />
                        </DialogBase>
                    );
                }
                return null;
            });
        }
        return null;
    };

    const value = useMemo(() => {
        return {
            ...currentState,
            dispatch,
            openDrawer,
            closeDrawers,
            pushDialog,
            popDialog,
            updateDrawer
        };
    }, [currentState, dispatch, openDrawer, closeDrawers, pushDialog, popDialog, updateDrawer]);

    return (
        <ModalContext.Provider value={value}>
            {children}
            {renderDrawer()}
            {renderDialogs()}
        </ModalContext.Provider>
    );
};
