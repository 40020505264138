import React, { FC, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Banner from "components/banner";
import { ProfileContext } from "contexts/profile";
import BuildingList from "components/buildings/buildingList";
import { useIsObservable } from "utils/hooks/useIsObservable";
import { CenteredLoader } from "components/centeredLoader";
import { usePaginationStore } from "utils/usePaginationStore";

const BuildingsView: FC = () => {
    const { selectedOrganization } = useContext(ProfileContext);
    const pagination = usePaginationStore(
        "buildings",
        undefined,
        {
            is_published: true,
            org_nr: selectedOrganization.org_nr,
        },
        {
            appendResults: true,
        }
    );
    const navigate = useNavigate();
    const [containerRef, isObservable] = useIsObservable<HTMLDivElement>({
        rootMargin: "0px",
        threshold: 0.5,
    });

    const hasMorePages = pagination.results.length !== pagination.totalCount;

    useEffect(() => {
        if (isObservable && hasMorePages) {
            pagination.nextPage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isObservable]);

    const navigateToDetails = (buildingUuid: string): void => {
        navigate(`/realestates/${buildingUuid}`);
    };
    return (
        <div className="contentWrapper">
            <Banner
                title="bannerRealestatesTitle"
                subTitle="bannerRealestates"
                image={`${process.env.PUBLIC_URL}/assets/images/1024x683/memberships-1024x683.jpg`}
            />
            <BuildingList
                loading={!isObservable && pagination.query.isFetching}
                buildings={pagination.results}
                navigateToDetails={navigateToDetails}
            />
            {hasMorePages && (
                <div ref={containerRef} style={{ height: "4em" }}>
                    <CenteredLoader size={30} />
                </div>
            )}
        </div>
    );
};

export default BuildingsView;
