import React, { FC, useCallback, useContext, useMemo, useState } from "react";
import { LocalizeText } from "components/localizer";
import { ModalContext } from "contexts/modals";
import { Box, Checkbox, FormControlLabel, List, ListItem, TextField } from "@mui/material";
import VKButton from "components/vkButton";
import validator from "utils/validator";
import { MembersContext } from "contexts/members";
import { IUsePaginationStore } from "utils/usePaginationStore";
import { usePermission } from "utils/hooks/usePermission";
import { DatePicker } from "components/datePicker";
import { dateFormats } from "utils/formats";
import { addDays, format, isAfter, isBefore, isValid, max, parseISO } from "date-fns";
import { ISubscription } from "reducers/subscriptions";
import { TLanguageTag } from "contexts/language";

interface IProps {
    subscription: ISubscription;
    pagination: IUsePaginationStore<"member-subscription-associations">;
    updateSubscription: () => Promise<void>;
}

export const AddMember: FC<IProps> = ({ subscription, pagination, updateSubscription }) => {
    const { createAssociation, isCreatingAssociation } = useContext(MembersContext);
    const { closeDrawers } = useContext(ModalContext);
    const { hasPermission } = usePermission();

    const [email, setEmail] = useState("");
    const [sendEmail, setSendEmail] = useState<boolean>(true);

    const { earliestActivationDate } = useMemo(() => {
        const earliestActivationDate = parseISO(subscription.earliest_member_activation_date ?? "");
        return { earliestActivationDate };
    }, [subscription.earliest_member_activation_date]);

    const [activateDate, setActivateDate] = useState<Date>(earliestActivationDate);

    const { earliestDeactivationDate } = useMemo(() => {
        const officialEarliestDeactivationDate = parseISO(subscription.earliest_cancellation_date ?? "");
        const earliestDeactivationDate = hasPermission("can_override_earliest_subscription")
            ? max([new Date(), activateDate])
            : max([officialEarliestDeactivationDate, activateDate]);
        return { earliestDeactivationDate };
    }, [activateDate, hasPermission, subscription.earliest_cancellation_date]);

    const [deactivateDate, setDeactivateDate] = useState<Date | null>(null);

    const [activateValidation, setActivateValidation] = useState({
        isValid: true,
        reasonTag: "",
    });
    const [deactivateValidation, setDeactivateValidation] = useState({
        isValid: true,
        reasonTag: "",
    });

    const emailValid = useMemo(() => {
        return validator.email(email, true);
    }, [email]);

    const onConfirm = useCallback(async (): Promise<void> => {
        await createAssociation({
            email,
            subscription: subscription.uuid,
            should_send_mail: sendEmail,
            activates: format(activateDate, dateFormats.WEBDATE),
            deactivates: deactivateDate ? format(deactivateDate, dateFormats.WEBDATE) : null,
        });

        await updateSubscription();

        closeDrawers();
        await pagination.query.refetch();
    }, [
        createAssociation,
        email,
        subscription.uuid,
        sendEmail,
        activateDate,
        deactivateDate,
        updateSubscription,
        closeDrawers,
        pagination.query,
    ]);

    const validateActivateDateCallback = useCallback(
        async (date: Date) => {
            setDeactivateValidation({ isValid: true, reasonTag: "" });
            if (!isValid(date)) {
                return setActivateValidation({ isValid: false, reasonTag: "" });
            }
            if (isBefore(date, earliestActivationDate)) {
                return setActivateValidation({ isValid: false, reasonTag: "errorMinDate" });
            }
            if (deactivateDate && isAfter(date, deactivateDate)) {
                return setActivateValidation({ isValid: false, reasonTag: "errorActivatsDeactivatesDate" });
            }
            return setActivateValidation({ isValid: true, reasonTag: "" });
        },
        [deactivateDate, earliestActivationDate]
    );

    const validateDeactivateDateCallback = useCallback(
        async (date: Date) => {
            setActivateValidation({ isValid: true, reasonTag: "" });

            if (!date) {
                return setDeactivateValidation({ isValid: true, reasonTag: "" });
            }
            if (!isValid(date)) {
                return setDeactivateValidation({ isValid: false, reasonTag: "" });
            }
            if (isBefore(addDays(date, 1), earliestDeactivationDate)) {
                return setDeactivateValidation({ isValid: false, reasonTag: "errorMinDate" });
            }
            if (isBefore(date, activateDate)) {
                return setDeactivateValidation({ isValid: false, reasonTag: "errorActivatsDeactivatesDate" });
            }
            return setDeactivateValidation({ isValid: true, reasonTag: "" });
        },
        [activateDate, earliestDeactivationDate]
    );

    const onChangeActivationDate = useCallback(
        (date: unknown): void => {
            setActivateDate(date as Date);
            validateActivateDateCallback(date as Date);
        },
        [validateActivateDateCallback]
    );

    const onChangeDeactivationDate = useCallback(
        (date: unknown): void => {
            setDeactivateDate(date as Date);
            validateDeactivateDateCallback(date as Date);
        },
        [validateDeactivateDateCallback]
    );

    return (
        <Box>
            <List>
                <ListItem>
                    <TextField
                        label={<LocalizeText tag="email" />}
                        fullWidth
                        value={email}
                        error={!emailValid}
                        helperText={!emailValid ? <LocalizeText tag="errorEmail" /> : null}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </ListItem>
                {hasPermission("is_community_user") ? (
                    <ListItem>
                        <FormControlLabel
                            label={<LocalizeText tag="sendEmail" />}
                            control={<Checkbox checked={sendEmail} onChange={(e) => setSendEmail(e.target.checked)} />}
                        />
                    </ListItem>
                ) : null}

                <ListItem>
                    <LocalizeText tag="addMemberInfo" />
                </ListItem>
                <ListItem>
                    <LocalizeText tag="activatesDateInfo" />
                </ListItem>
                <ListItem>
                    <DatePicker
                        minDate={earliestActivationDate}
                        maxDate={subscription.end_date ? parseISO(subscription.end_date) : undefined}
                        format={dateFormats.WEBDATE}
                        label={<LocalizeText tag="activatesDate" />}
                        value={activateDate}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                error: !activateValidation.isValid,
                                helperText: activateValidation.reasonTag && (
                                    <LocalizeText tag={activateValidation.reasonTag as TLanguageTag} />
                                ),
                            },
                        }}
                        onChange={onChangeActivationDate}
                    />
                </ListItem>
                <ListItem>
                    <LocalizeText tag="deactivationDateInfo" />
                </ListItem>
                <ListItem>
                    <DatePicker
                        minDate={earliestDeactivationDate}
                        maxDate={subscription.end_date ? parseISO(subscription.end_date) : undefined}
                        format={dateFormats.WEBDATE}
                        label={<LocalizeText tag="deactivationDate" />}
                        value={deactivateDate}
                        autoFocus
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                error: !deactivateValidation.isValid,
                                helperText: deactivateValidation.reasonTag && (
                                    <LocalizeText tag={deactivateValidation.reasonTag as TLanguageTag} />
                                ),
                            },
                        }}
                        onChange={onChangeDeactivationDate}
                    />
                </ListItem>
                <ListItem>
                    <VKButton
                        template="primary"
                        tag="add"
                        sx={{ marginLeft: "auto" }}
                        disabled={
                            !email ||
                            !emailValid ||
                            !activateDate ||
                            !activateValidation.isValid ||
                            !deactivateValidation.isValid
                        }
                        onClick={onConfirm}
                        isLoading={isCreatingAssociation}
                    />
                </ListItem>
            </List>
        </Box>
    );
};
