import React, { FC, useState } from "react";
import { LocalizeText } from "components/localizer";
import ConfirmDialog from "components/confirmDialog";
import { EditArenaStartDatePicker } from "./editArenaStartDatePicker";

interface IProps {
    dateStart: string;
    dateEnd: string;
    onHandleSubmit: (newDate: string) => void;
}

export const EditArenaStartDate: FC<IProps> = ({ dateStart, dateEnd, onHandleSubmit }) => {
    const [startDate, setStartDate] = useState(dateStart);
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

    const onSubmitStartDate = async (newDate: string): Promise<void> => {
        setStartDate(newDate);
        setOpenConfirmDialog(true);
    };

    return (
        <>
            <EditArenaStartDatePicker
                onSubmit={(newDate: string) => onSubmitStartDate(newDate)}
                dateStart={dateStart}
                dateEnd={dateEnd}
            />

            <ConfirmDialog
                open={openConfirmDialog}
                title={<LocalizeText tag="editOccupancyStartDate" />}
                description={<LocalizeText tag="confirmEdittOccupancyDate" />}
                onReject={() => setOpenConfirmDialog(false)}
                onAccept={() => onHandleSubmit(startDate)}
            />
        </>
    );
};
