import React from "react";
import { Variant } from "@mui/material/styles/createTypography";
import { Typography } from "@mui/material";
import colors from "styles/colors";

interface IProps {
    email: string;
    spaceBefore?: boolean;
    variant?: Variant;
}
const EmailText: React.FC<IProps> = ({ email, spaceBefore, variant = "body2"}) => {
    return (
        <Typography 
            variant={variant}
            component={"a"}
            href={`mailto:${email}`}
            sx={{
                textDecoration: "underline",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                color: colors.black,
                ...spaceBefore ? {
                    marginLeft: "10px"
                } : {}
            }}>
            {email}
        </Typography>
    );
};

export default EmailText;
