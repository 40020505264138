import React, { useRef } from "react";
import { LocalizeText } from "components/localizer";
import { THeadCell } from "utils/types";
import TableComponent, { IRow } from "components/tableComponent";
import { getExpireDate } from "utils/ecommerseTypes";
import { IMembership } from "reducers/memberships";
import { format, parseISO } from "date-fns";
import { dateFormats } from "utils/formats";
import { MembershipsContext } from "contexts/memberships";
import Dropdown, { TDropdownItem } from "components/dropdown";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import PaginationStore, { TPaginationActionData, TParamValue } from "utils/paginationStore";
import { usePermission } from "utils/hooks/usePermission";
import AddMembershipContactPerson from "components/drawers/contactPersons/addMembershipContact";
import { ModalContext } from "contexts/modals";

interface RoleManagementMembershipTableProps {
    paginationParams: Record<string, TParamValue>;
}

const RoleManagementMembershipTable: React.FC<RoleManagementMembershipTableProps> = ({ paginationParams }) => {
    const { fetchMemberships } = React.useContext(MembershipsContext);

    const navigate = useNavigate();
    const mounted = useRef(false);
    const { hasPermission } = usePermission();
    const { openDrawer } = React.useContext(ModalContext);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [pagination, setPagination] = React.useState<PaginationStore<IMembership>>(
        new PaginationStore<IMembership>(),
    );

    React.useEffect(() => {
        mounted.current = true;
        const mPagination = pagination;
        mPagination.initialize(membershipPaginationCallback);
        mPagination.setParams(paginationParams);
        mPagination.getInitial();
        if (mounted.current) {
            setPagination(mPagination);
        }
        return () => {
            mounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const membershipPaginationCallback = async (
        params: Record<string, unknown>,
    ): Promise<TPaginationActionData<IMembership>> => {
        setLoading(true);
        const entries = await fetchMemberships(params);
        if (mounted.current) {
            setLoading(false);
        }
        return entries;
    };

    const membershipHeadCells: THeadCell[] = [
        { id: "name", label: <LocalizeText tag="commercialCompanyName" /> },
        { id: "agreement_nr", label: <LocalizeText tag="customerNumber" /> },
        { id: "start_date", label: <LocalizeText tag="from" /> },
        { id: "end_date", label: <LocalizeText tag="contractDuration" /> },
        { id: "role", label: <LocalizeText tag="role" /> },
        { id: "", label: "", alignCell: "right" },
    ];

    const mapRows = (memberships: IMembership[]): IRow[] => {
        return memberships.map((membership: IMembership) => ({
            data: [
                membership.organization.name,
                membership.agreement_nr || "-",
                membership.start_date ? format(parseISO(membership.start_date ?? ""), dateFormats.WEBDATE) : "",
                getExpireDate(membership.end_date),
                membership.role_display_name,
                <Dropdown inRow key={membership.agreement_nr} items={getDropdownItems(membership)} />,
            ],
        }));
    };

    const getDropdownItems = (membership: IMembership): TDropdownItem[] => {
        const items = [
            {
                onClick: () => navigate(`/representatives/membership/${membership.id}`),
                label: <LocalizeText tag="showRepresentatives" />,
            },
        ];
        if (hasPermission("manage_contract") && hasPermission("add_membership")) {
            items.push({
                onClick: () =>
                    openDrawer(AddMembershipContactPerson, { membership: membership }, "addNewRepresentative"),
                label: <LocalizeText tag="addNewRepresentative" />,
            });
        }
        return items;
    };

    return (
        <Box mb="2em">
            <Grid container alignItems="center" style={{ marginBottom: "1em" }}>
                <Grid item xs={6}>
                    <Typography variant="h6" data-testid="membership-title">
                        <LocalizeText tag="membership" />
                    </Typography>
                </Grid>
            </Grid>
            <TableComponent
                headCells={membershipHeadCells}
                isLoading={loading}
                rows={mapRows(pagination.results)}
                pagination={pagination}
            />
        </Box>
    );
};

export default RoleManagementMembershipTable;
