import React from "react";
import { usePermission } from "utils/hooks/usePermission";

interface IHasPermissionProps {
    requiredPerm: string;
    children?: React.ReactNode;
}

const HasPermission: React.FC<IHasPermissionProps> = ({ requiredPerm, children }) => {

    const { hasPermission } = usePermission();

    if (hasPermission(requiredPerm)) {
        return <>{children}</>;
    }
    return null;
};
export default HasPermission;
