import { INewContactObject } from "components/drawers/contactPersons/addContactPersonBase";
import { IAgent } from "./realestates";
import { IRole } from "./roleManagement";

export interface ILease {
    lease_uuid: string;
    city: string;
    address: string;
    building_name: string;
    popular_name_tenant: string;
    popular_name_leasee: string;
    agreement_number?: string;
    contract_agent?: string;
    community?: string;
    active_lease_contract: ILeaseContract | null;
    lease_manager: IAgent;
    valid_to_date: string;
    valid_from_date: string;
    is_active: boolean;
    has_list_permission: boolean;
    has_manage_permission: boolean;
    has_access_permission: boolean;
    content_type_id: number;
    role_display_name: string;
    id: number;
    imported_date_time: string;
    synced_date_time: string;
    recently_added: boolean;
}

export interface ILeaseContract {
    area: number;
    rent_per_square_meter: string;
    debit_period: string;
    valid_to_date: string;
    premise_group_name: string;
    premise_number: string;
    cadastral_name: string;
    popular_name_tenant: string;
    address: string;
    lease_contract_uuid: string;
    billing_address: string;
    full_billing_address: string;
    manager_agent_full_name: string;
    manager_agent_email: string;
    manager_agent_mobile_phone: string;
    is_vat: boolean;
    distribution_method_value: string;
    distribution_method_name: string;
    distribution_email_address: string;
}

export interface ICustomerLedger {
    customer_ledger_uuid: string;
    agreement_number: string;
    ocr: string;
    due_date: string;
    amount_gross: string;
    amount_vat: string;
    amount_total: string;
    amount_paid: string;
    amount_remaining: string;
    amount_overdue: string;
    latest_payment_date: string | null;
    invoice_number: string;
    invoice_period: number;
    number_of_payments: number;
    number_of_reminders: number;
    bank_giro_name: string;
    bank_giro_value: string;
    rental_period: string;
    rental_period_start_date: string;
    rental_period_stop_date: string;
    invoice_pdf_url: string;
}

export interface ICustomerLedgerEntry {
    customer_ledger_entry_uuid: string;
    customer_ledger_uuid: string;
    name: string;
    debit_period: string;
    debit_type_name: string;
    debit_type_value: string;
    notes: string;
    invoice_date: string;
    debit_start_date: string;
    debit_stop_date: string;
    amount_gross: string;
    amount_vat: string;
    amount_total: string;
}

export interface IDebit {
    debit_uuid: string;
    name: string;
    start_date: string;
    stop_date: string;
    amount: string;
    is_one_time_amount: boolean;
    base_amount: string;
    base_value: string;
    base_month: string;
    enumerated_value: string;
    enumeration_month: string;
    index_amount: string;
    is_indexed: boolean;
    is_vat: boolean;
    vat_proportion: number;
    debit_type_name: string;
}

export interface IInvitation {
    uuid: string;
    created: string;
    updated: string;
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
    role: IRole;
    can_manage: boolean;
}

export interface ILeaseInvitation extends IInvitation {
    lease: string;
    requesting_contact_person: string;
    requested_contact_person: string;
    lease_agreement_number: string;
}

export interface ILeaseRequest {
    uuid: string;
    lease: ILease;
    requesting_contact_person: string;
    requested_contact_person: string;
    created: string;
    updated: string;
    role: IRole;
}

export interface ICustomerLedgersQueryParams {
    org_nr?: string;
    lease_contract_uuid?: string;
    lease?: string;
    is_expired?: boolean;
    is_outstanding?: boolean;
    has_pdf_url?: boolean;
    due_date_after?: string;
    due_date_before?: string;
    search?: string;
    ordering?: string;
}

export interface ICustomerLedgerEntriesQueryParams {
    customer_ledger_uuid?: string;
    ordering?: string;
}

export interface ILeasesQueryParams {
    org_nr?: string;
    has_manage?: boolean;
    by_user_email?: string;
    by_not_user_email?: string;
    search?: string;
    ordering?: string;
}

export interface ILeaseInvitationsQueryParams {
    org_nr?: string;
    lease_uuid?: string;
    ordering?: string;
}

export interface ILeaseContactPersonsQueryParams {
    pass_manager_if_empty?: boolean;
}

export interface ILeases {
    lease?: ILease;
    isHandlingInvitation: boolean;
    isFetchingLeaseInvitation: boolean;
    isAddingContactPerson: boolean;
    isRequestingAccess: boolean;
    isRemovingContactPersonAccess: boolean;
    isFetchingLease: boolean;
    isEditingContactPerson: boolean;
    fetchLease: (leaseUuid: string) => void;
    setLease: (lease: ILease) => void;
    acceptInvitation: (invitation_uuid: string) => Promise<boolean>;
    denyInvitation: (invitation_uuid: string) => Promise<boolean>;
    fetchLeaseInvitation: (invitation_uuid: string) => Promise<ILeaseInvitation>;
    deleteInvitation: (invitation_uuid: string) => Promise<boolean>;
    requestAccessToLease: (lease_uuid: string, requested_contact_person_email: string) => Promise<boolean>;
    addLeaseContactPerson: (lease_uuid: string, newContact: INewContactObject) => Promise<boolean>;
    removeContactPersonAccess: (lease_uuid: string, id: number) => Promise<boolean>;
    editContactPerson: (
        lease_uuid: string,
        contact_person_id: number,
        data: Record<string, unknown>
    ) => Promise<boolean>;
}

export const initialState: ILeases = {
    isFetchingLease: false,
    isFetchingLeaseInvitation: false,
    isAddingContactPerson: false,
    isEditingContactPerson: false,
    isRequestingAccess: false,
    isRemovingContactPersonAccess: false,
    isHandlingInvitation: false,
    fetchLease: async () => false,
    requestAccessToLease: async () => false,
    addLeaseContactPerson: async () => false,
    editContactPerson: async () => false,
    removeContactPersonAccess: async () => false,
    setLease: async () => false,
    acceptInvitation: async () => false,
    denyInvitation: async () => false,
    deleteInvitation: async () => false,
    fetchLeaseInvitation: async () => ({} as ILeaseInvitation),
};

export type IAction =
    | { type: "FETCH_LEASE" }
    | {
          type: "FETCH_LEASE_SUCCESS";
          lease: ILease;
      }
    | { type: "FETCH_LEASE_FAILURE" }
    | { type: "SET_LEASE"; lease: ILease }
    | { type: "HANDLE_INVITATION" }
    | { type: "HANDLE_INVITATION_SUCCESS" }
    | { type: "HANDLE_INVITATION_FAILURE" }
    | { type: "FETCH_INVITATION" }
    | { type: "FETCH_INVITATION_SUCCESS" }
    | { type: "FETCH_INVITATION_FAILURE" }
    | { type: "ADD_LEASE_CONTACT_PERSON" }
    | { type: "ADD_LEASE_CONTACT_PERSON_SUCCESS" }
    | { type: "ADD_LEASE_CONTACT_PERSON_FAILURE" }
    | { type: "EDIT_LEASE_CONTACT_PERSON" }
    | { type: "EDIT_LEASE_CONTACT_PERSON_SUCCESS" }
    | { type: "EDIT_LEASE_CONTACT_PERSON_FAILURE" }
    | { type: "REQUEST_ACCESS" }
    | { type: "REQUEST_ACCESS_SUCCESS" }
    | { type: "REQUEST_ACCESS_FAILURE" }
    | { type: "REMOVE_CONTACT_PERSON" }
    | { type: "REMOVE_CONTACT_PERSON_SUCCESS" }
    | { type: "REMOVE_CONTACT_PERSON_FAILURE" };

function reducer(state: ILeases, action: IAction): ILeases {
    switch (action.type) {
        case "FETCH_LEASE":
            return { ...state, isFetchingLease: true };
        case "FETCH_LEASE_SUCCESS":
            return { ...state, isFetchingLease: false, lease: action.lease };
        case "FETCH_LEASE_FAILURE":
            return { ...state, isFetchingLease: false };

        case "SET_LEASE":
            return { ...state, lease: action.lease };

        case "HANDLE_INVITATION":
            return { ...state, isHandlingInvitation: true };
        case "HANDLE_INVITATION_SUCCESS":
        case "HANDLE_INVITATION_FAILURE":
            return { ...state, isHandlingInvitation: false };

        case "FETCH_INVITATION":
            return { ...state, isFetchingLeaseInvitation: true };
        case "FETCH_INVITATION_SUCCESS":
        case "FETCH_INVITATION_FAILURE":
            return { ...state, isFetchingLeaseInvitation: false };

        case "ADD_LEASE_CONTACT_PERSON":
            return { ...state, isAddingContactPerson: true };
        case "ADD_LEASE_CONTACT_PERSON_SUCCESS":
        case "ADD_LEASE_CONTACT_PERSON_FAILURE":
            return { ...state, isAddingContactPerson: false };

        case "EDIT_LEASE_CONTACT_PERSON":
            return { ...state, isEditingContactPerson: true };
        case "EDIT_LEASE_CONTACT_PERSON_SUCCESS":
        case "EDIT_LEASE_CONTACT_PERSON_FAILURE":
            return { ...state, isEditingContactPerson: false };

        case "REQUEST_ACCESS":
            return { ...state, isRequestingAccess: true };
        case "REQUEST_ACCESS_SUCCESS":
        case "REQUEST_ACCESS_FAILURE":
            return { ...state, isRequestingAccess: false };

        case "REMOVE_CONTACT_PERSON":
            return { ...state, isRemovingContactPersonAccess: true };
        case "REMOVE_CONTACT_PERSON_SUCCESS":
        case "REMOVE_CONTACT_PERSON_FAILURE":
            return { ...state, isRemovingContactPersonAccess: false };

        default:
            return state;
    }
}

export default reducer;
