import { AxiosError } from "axios";
import { TNotificationTag } from "contexts/language";

type TState = "DEQUE_NOTIFICATION" | "QUE_NOTIFICATION";

export interface IAction {
    type: TState;
    payload: {
        tag?: TNotificationTag;
        uuid?: number;
        error?: AxiosError;
    };
}

export interface INotification {
    uuid: number;
    tag: TNotificationTag;
    messages?: string;
}

export interface INotify {
    notifications: INotification[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    enqueNotification: (tag: TNotificationTag, error?: any) => void;
    dequeNotification: (uuid: number) => void;
}

const parseError = (error?: AxiosError): string => {
    if (error?.response) {
        let errorMessages = "";
        try {
            errorMessages = JSON.stringify(error.response.data);
        } catch (parseError) {
            errorMessages = String(error.response.data);
        }

        return errorMessages;
    }
    return "";
};

function reducer(state: INotify, action: IAction): INotify {
    const { notifications } = state;
    const { type, payload } = action;
    switch (type) {
        case "QUE_NOTIFICATION":
            if (payload.tag) {
                notifications.unshift({
                    uuid: +new Date(),
                    tag: payload.tag,
                    messages: parseError(payload.error),
                });
            }
            return {
                ...state,
            };
        case "DEQUE_NOTIFICATION": {
            const index = state.notifications.findIndex((n) => n.uuid === payload.uuid);
            state.notifications.splice(index, 1);
            return {
                ...state,
            };
        }
        default:
            return state;
    }
}

export default reducer;
