import React, { useEffect } from "react";
import { RouteProps, useLocation, useNavigate } from "react-router-dom";
import Navigation from "components/navigation/navigation";
import { AuthContext } from "contexts/auth";
import { MembershipsContext } from "contexts/memberships";
import ErrorBoundary from "./__helpers__/ErrorBoundary";

const PrivateLayout: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <AuthContext.Consumer>
            {(authContext) => (
                <MembershipsContext.Consumer>
                    {(membershipContext) => (
                        <Navigation
                            urlPath={location.pathname.substring(1)}
                            auth={authContext}
                            member={membershipContext.membership}
                            clearMembershipStorage={membershipContext.clearStorage}
                        >
                            <ErrorBoundary navigate={navigate}>{children}</ErrorBoundary>
                        </Navigation>
                    )}
                </MembershipsContext.Consumer>
            )}
        </AuthContext.Consumer>
    );
};

type IProps = Omit<RouteProps, "component"> & {
    component: React.ReactNode;
};

export const PrivateRoute: React.FC<IProps> = ({ component }) => {
    const { isLoggedIn } = React.useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    if (!isLoggedIn) {
        return null;
    }

    return (
        <PrivateLayout>{component}</PrivateLayout>
    );
};
export default PrivateRoute;
