import React from "react";
import TableComponent, { IRow } from "components/tableComponent";
import { THeadCell } from "utils/types";
import { LocalizeText, fontType } from "components/localizer";
import VKButton from "components/vkButton";
import { ProfileContext } from "contexts/profile";
import { Grid, Typography } from "@mui/material";
import ConfirmDialog from "components/confirmDialog";
import LeasesContext from "contexts/leases";
import { ILeaseRequest } from "reducers/leases";

interface IRequestsTabState {
    selected?: ILeaseRequest;
    isConfirmOpen: boolean;
}

const initialState = { isConfirmOpen: false };

const RequestsTab: React.FC = () => {
    const {
        selectedOrganization,
        isFetchingPendingLeaseRequests,
        fetchPendingLeaseRequests,
        pendingLeaseRequestsPagination,
    } = React.useContext(ProfileContext);

    const { acceptInvitation, denyInvitation, isHandlingInvitation } = React.useContext(LeasesContext);
    const { fetchProfileNotifications } = React.useContext(ProfileContext);
    const [state, setState] = React.useState<IRequestsTabState>(initialState);

    React.useEffect(() => {
        if (!pendingLeaseRequestsPagination.isInitialized()) {
            pendingLeaseRequestsPagination.initialize(fetchPendingLeaseRequests);
        }
        if (pendingLeaseRequestsPagination.getParam("org_nr") !== selectedOrganization.org_nr) {
            pendingLeaseRequestsPagination.setParam("org_nr", selectedOrganization.org_nr);
            pendingLeaseRequestsPagination.getInitial();
        }
        // eslint-disable-next-line
    }, []);

    const requestsHeadCells: THeadCell[] = [
        { id: "lease", label: <LocalizeText tag="lease" /> },
        { id: "role", label: <LocalizeText tag="role" /> },
        { id: "requested_by", label: <LocalizeText tag="requestedBy" /> },
        {
            id: "handle_request",
            label: <LocalizeText tag="handleRequest" />,
            padding: "none",
            alignCell: "center",
        },
    ];

    const mapRows = (leaseInvitations: ILeaseRequest[]): IRow[] => {
        return leaseInvitations.map((request: ILeaseRequest) => ({
            data: [
                request.lease.agreement_number,
                request.role ? request.role.display_name : "-",
                request.requesting_contact_person,
                <VKButton
                    size="small"
                    key={request.uuid}
                    tag="handleAction"
                    template="primary"
                    onClick={() =>
                        setState({
                            ...state,
                            selected: request,
                            isConfirmOpen: true,
                        })
                    }
                />,
            ],
        }));
    };

    const handleAcceptRequest = async (): Promise<void> => {
        if (state.selected) {
            const success = await acceptInvitation(state.selected.uuid);
            if (success) {
                setState(initialState);
                pendingLeaseRequestsPagination.reloadPage();
                fetchProfileNotifications(selectedOrganization.org_nr);
            }
        }
    };

    const handleDenyRequest = async (): Promise<void> => {
        if (state.selected) {
            const success = await denyInvitation(state.selected.uuid);
            if (success) {
                setState(initialState);
                pendingLeaseRequestsPagination.reloadPage();
                fetchProfileNotifications(selectedOrganization.org_nr);
            }
        }
    };

    return (
        <>
            <Grid container alignItems="center" mb="1em">
                <Grid item xs={6}>
                    <Typography variant="h6">
                        <LocalizeText tag="requests" />
                    </Typography>
                </Grid>
            </Grid>
            <TableComponent
                headCells={requestsHeadCells}
                isLoading={isFetchingPendingLeaseRequests}
                rows={mapRows(pendingLeaseRequestsPagination.results)}
                pagination={pendingLeaseRequestsPagination}
            />
            {state.isConfirmOpen && (
                <ConfirmDialog
                    open={state.isConfirmOpen}
                    hideIcon
                    title={<LocalizeText tag={"handleRequestTitle"} />}
                    description={
                        <>
                            <LocalizeText tag="accept" type={fontType.BOLD} />
                            <LocalizeText tag="acceptDescription" />
                            <LocalizeText spaceBefore tag="deny" type={fontType.BOLD} />
                            <LocalizeText tag="denyDescription" />
                        </>
                    }
                    acceptTag="accept"
                    rejectTag="deny"
                    onAccept={handleAcceptRequest}
                    onReject={handleDenyRequest}
                    onClose={() => setState(initialState)}
                    isLoading={isHandlingInvitation}
                />
            )}
        </>
    );
};

export default RequestsTab;
