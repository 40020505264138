import React from "react";
import Banner from "components/banner";
import { useParams } from "react-router-dom";
import BuildingDetails from "components/buildings/buildingDetails";
import { CenteredLoader } from "components/centeredLoader";
import If from "components/__helpers__/if";
import { Box, Tab } from "@mui/material";
import { LocalizeText } from "components/localizer";
import BuildingResponsible from "components/buildings/buildingResponsible";
import ArenasContext from "contexts/arenas";
import BuildingTexts from "components/buildings/buildingTexts";
import { BuildingTabs } from "views/lease/buildingInformation";

type VKArenaTextType = "ArenaInfo";

const ArenaInformation: React.FC = () => {
    const { isFetchingSingleArenaBuilding, arenaBuilding, getArenaBuilding } = React.useContext(ArenasContext);

    const params = useParams<{ arenaBuildingUuid: string }>();
    const [currentTab, setCurrentTab] = React.useState<VKArenaTextType>("ArenaInfo");

    React.useEffect(() => {
        getArenaBuilding(params.arenaBuildingUuid ?? "");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div data-testid="buildings-info-root" className="contentWrapper" style={{ paddingBottom: 0 }}>
                <Banner title="arenaInformation" />
                <If truthy={isFetchingSingleArenaBuilding}>
                    <Box sx={{ height: "70vh" }}>
                        <CenteredLoader size={30} />
                    </Box>
                </If>
                {!isFetchingSingleArenaBuilding && arenaBuilding && (
                    <>
                        <BuildingDetails
                            building={arenaBuilding}
                            showArenaName={true}
                            contactPersons={<BuildingResponsible building={arenaBuilding} isArenaResponsible={true} />}
                        />
                        <BuildingTabs
                            value={currentTab}
                            onChange={(event, newTab) => setCurrentTab(newTab)}
                            aria-label="change information tab"
                        >
                            <Tab label={<LocalizeText tag="arenaInfo" />} value={"ArenaInfo"} />
                        </BuildingTabs>
                    </>
                )}
            </div>
            {!isFetchingSingleArenaBuilding && arenaBuilding && (
                <BuildingTexts texts={arenaBuilding.building_texts} parentType={currentTab} />
            )}
        </>
    );
};

export default ArenaInformation;
