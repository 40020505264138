import React from "react";
import { isValid, startOfDay } from "date-fns";
import VKButton from "components/vkButton";
import { dateFormats } from "utils/formats";
import { LocalizeText } from "components/localizer";
import { TFormEvent } from "utils/types";
import { ListItemText, List, ListItem } from "@mui/material";
import ArrowIcon from "@mui/icons-material/ArrowForward";
import { TLanguageTag } from "contexts/language";
import { DatePicker } from "components/datePicker";

interface IProps {
    testid?: string;
    titleTag: TLanguageTag;
    helperTextTag?: TLanguageTag;
    datePickerLabel?: string | React.ReactNode;
    buttonLabel?: string | React.ReactNode;
    isLoading?: boolean;
    earliestEndDate?: Date | null;
    type?: "save" | "proceed" | null;
    onSubmit: (date: Date) => void;
    disabled?: boolean;
}

export const GeneralDatePicker: React.FC<IProps> = ({
    testid,
    titleTag,
    helperTextTag,
    isLoading,
    datePickerLabel,
    earliestEndDate,
    type,
    onSubmit,
    disabled,
}) => {
    const [startDate, setStartDate] = React.useState<Date | null>(earliestEndDate ?? startOfDay(new Date()));

    const handleSubmit = (event: TFormEvent): void => {
        event.preventDefault();
        if (startDate) {
            onSubmit(startDate);
        }
    };

    const validateData = (): boolean => {
        if (startDate && isValid(startDate)) {
            return earliestEndDate ? startDate >= earliestEndDate : true;
        }
        return false;
    };

    let submitButton = (
        <VKButton
            data-testid={`${testid ?? "generalDatePicker"}_saveButton`}
            type="submit"
            tag="save"
            template="save"
            disabled={!validateData() || disabled}
            isLoading={isLoading}
        />
    );
    if (type === "proceed") {
        submitButton = (
            <VKButton
                data-testid={`${testid ?? "generalDatePicker"}_proceedButton`}
                type="submit"
                tag="proceed"
                disabled={!validateData() || disabled}
                isLoading={isLoading}
                rightAddon={<ArrowIcon />}
                template="primary"
            />
        );
    }

    return (
        <div data-testid={`${testid ?? "generalDatePicker"}_root`}>
            <form onSubmit={handleSubmit}>
                <List>
                    <ListItem>
                        <ListItemText primary={<LocalizeText tag={titleTag} />} />
                    </ListItem>
                    {helperTextTag ? (
                        <ListItem>
                            <LocalizeText tag={helperTextTag} />
                        </ListItem>
                    ) : (
                        <></>
                    )}
                    <ListItem>
                        <DatePicker
                            minDate={earliestEndDate}
                            format={dateFormats.WEBDATE}
                            label={datePickerLabel ?? <LocalizeText tag="endDate" />}
                            value={startDate}
                            slotProps={{ textField: { fullWidth: true } }}
                            onChange={(date: Date) => setStartDate(date as Date)}
                        />
                    </ListItem>
                    <ListItem>{submitButton}</ListItem>
                </List>
            </form>
        </div>
    );
};
