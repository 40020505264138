import React, { FC, useState } from "react";
import { LocalizeText } from "components/localizer";
import Grid from "@mui/material/Grid";
import { styled, SxProps, Typography } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/NavigateNext";
import { Fade } from "@mui/material";
import colors from "styles/colors";
import { TLanguageTag } from "contexts/language";

const BannerBody = styled(Grid)(({ theme }) => ({
    alignItems: "center",
    padding: "10px 2rem",
    position: "absolute",
    bottom: 0,
    color: colors.white,
    backgroundColor: colors.vkBlue,
    [theme.breakpoints.down("md")]: {
        paddingLeft: "1rem",
        paddingRight: "1rem",
    },
}));

const BottomBannerText = styled(Grid)(({ theme }) => ({
    "& p": {
        fontSize: "1.3vw",
        lineHeight: "1.2vw",
        [theme.breakpoints.down("lg")]: {
            fontSize: "2vw",
            lineHeight: "1.9vw",
        },
        [theme.breakpoints.down("md")]: {
            fontSize: "1.1rem",
        },
    },
    overflow: "hidden",
}));

const BannerText = styled(Grid)(({ theme }) => ({
    fontSize: "0.8vw",
    height: "100%",
    [theme.breakpoints.down("lg")]: {
        fontSize: "1.2vw",
    },
    [theme.breakpoints.down("md")]: {
        fontSize: "1rem",
    },
    "& span": {
        width: "100%",
        overflow: "hidden",
        wordWrap: "break-word",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": "3",
        "-webkit-box-orient": "vertical",
    },
}));

interface ISmallBannerProps {
    title?: TLanguageTag;
    breadcrumbs?: TLanguageTag;
    image?: string;
    inactive?: boolean;
}
const SmallBanner: FC<ISmallBannerProps> = ({ title, image, breadcrumbs, inactive }) => {
    const [bannerIsHovered, setBannerHovered] = useState(false);
    const [timeoutHandle, setTimeoutHandle] = useState<NodeJS.Timeout | null>(null);

    const handleMouseEnter = (): void => {
        if (timeoutHandle) {
            clearTimeout(timeoutHandle);
        }
        setBannerHovered(true);
    };
    const handleMouseLeave = (): void => {
        setTimeoutHandle(
            setTimeout(() => {
                setBannerHovered(false);
            }, 200)
        );
    };

    const showTextExpand = bannerIsHovered && !!breadcrumbs;
    let extraStyling: SxProps = {
        opacity: 0.5,
    };
    if (!inactive) {
        extraStyling = {
            "& :hover": {
                cursor: "pointer",
                "& img": {
                    filter: "brightness(75%)",
                    transition: "0.2s ease",
                },
            },
        };
    }

    return (
        <Grid
            container
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{
                width: "100%",
                position: "relative",
                height: "100%",
                ...extraStyling,
            }}
        >
            <Grid item xs={12}>
                <img
                    style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                    }}
                    src={image}
                    alt="Page banner"
                />
            </Grid>
            {title && (
                <BannerBody container item xs={12}>
                    <BottomBannerText
                        container
                        item
                        xs={12}
                        flexWrap="nowrap"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item>
                            <Typography variant="body2">
                                <LocalizeText tag={title} />
                            </Typography>
                        </Grid>
                        <Grid item>
                            <ArrowRightIcon sx={{ fontSize: "42px", display: "flex" }} />
                        </Grid>
                    </BottomBannerText>
                    {showTextExpand && breadcrumbs && (
                        <Fade in={showTextExpand}>
                            <BannerText item md={12}>
                                <LocalizeText tag={breadcrumbs} />
                            </BannerText>
                        </Fade>
                    )}
                </BannerBody>
            )}
        </Grid>
    );
};

export default SmallBanner;
