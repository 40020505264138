import React, { FC, useCallback, useContext } from "react";
import { InvoicesContext } from "contexts/invoices";
import { Grid } from "@mui/material";
import { LocalizeText } from "./localizer";
import VKButton from "./vkButton";
import { useNavigate } from "react-router-dom";
import { addMonths, format, lastDayOfMonth } from "date-fns";
import { dateFormats } from "utils/formats";

interface IAdjustmentsContentProps {
    membershipId: number;
    disabled: boolean;
}
export const MembershipAdjustmentsContent: FC<IAdjustmentsContentProps> = ({ membershipId, disabled }) => {
    const { createFromMembership, isCreatingFromMembership } = useContext(InvoicesContext);
    const navigate = useNavigate();

    const onSend = useCallback(async () => {
        const newOrder = await createFromMembership({
            membership: membershipId,
            period_start: format(new Date(), "yyyy-MM-01"),
            period_end: format(lastDayOfMonth(addMonths(new Date(), 1)), dateFormats.WEBDATE),
        });

        if (!newOrder.uuid) {
            return;
        }

        navigate(`/staff-invoices/${newOrder.uuid}`);
    }, [createFromMembership, navigate, membershipId]);

    return (
        <Grid container direction="column" gap="20px">
            <Grid item>
                <LocalizeText tag="sendAdjustmentsExplanation" />
            </Grid>
            <Grid item>
                <VKButton
                    tag="send"
                    template="primary"
                    onClick={onSend}
                    isLoading={isCreatingFromMembership}
                    disabled={disabled}
                />
            </Grid>
        </Grid>
    );
};
