import React, { FC, ReactNode, Reducer, createContext, useCallback, useContext, useMemo, useReducer } from "react";
import reducer, { INewsArticles, IAction, INewsArticle, initialState } from "reducers/newsArticles";
import { NotificationContext } from "contexts/notification";
import webAPIRequest from "api";
import { TPaginationActionData, emptyPaginationActionData } from "utils/paginationStore";

export const NewsArticleContext = createContext<INewsArticles>({
    ...initialState,
});

export const NewsArticleProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const { ...notification } = useContext(NotificationContext);
    const [currentState, dispatch] = useReducer<Reducer<INewsArticles, IAction>>(reducer, initialState);

    const fetchNews = useCallback(
        async (params?: Record<string, unknown>): Promise<TPaginationActionData<INewsArticle>> => {
            try {
                dispatch({ type: "FETCH_NEWS_LIST" });
                const returnData = await webAPIRequest("get", "/news/", { params });
                dispatch({ type: "FETCH_NEWS_LIST_SUCCESS", data: returnData.data });
                return returnData.data;
            } catch (error) {
                notification.enqueNotification("error_fetchNewsList", error);
                dispatch({ type: "FETCH_NEWS_LIST_FAILURE" });
                return emptyPaginationActionData;
            }
        },
        [notification]
    );

    const value = useMemo(() => {
        return {
            ...currentState,
            fetchNews,
        };
    }, [currentState, fetchNews]);

    return <NewsArticleContext.Provider value={value}>{children}</NewsArticleContext.Provider>;
};
